import React from 'react'
import { Row, Col } from 'react-bootstrap';

import './Chart.css'
import { useTranslation } from 'react-i18next';

function InformationChart({ title, text, data, top }) {
  const { t, i18n } = useTranslation();

  const current = data["current"];
  const before = data["before"];

  const pourcentage = before == 0 ? 100 : Math.round((current - before) / before * 100);
  const positive = top ? current >= before : before >= current;
  const arrow = current >= before ? "up" : "down";

  return (
    <div className='information-div'>
      <Row className='information-title'>
        {title}
      </Row>
      <Row className='information-row'>
        <div className='information-current-div'>{current}</div>
      </Row>
      {
        current && current > 0 ?
          <Row className={"information-before-row"}>
            <p className={"information-" + (positive ? "positive" : "negative") + "-col"}>{pourcentage && pourcentage > 0 ? pourcentage + ' %' : null}<i class={"information-arrow fa-solid fa-arrow-" + arrow}></i>
              <em className='information-text'>{text}</em>
            </p>
          </Row>
          : null
      }
    </div>
  )
}

export default InformationChart;
