import axios from 'axios';
import {
    BANNER_LIST_REQUEST,
    BANNER_LIST_SUCCESS,
    BANNER_LIST_FAIL,

} from "../constants/bannerConstants";

export const listBanner = () => async (dispatch, getState) => {
    try {
        dispatch({ type: BANNER_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
            },
        })

        const { data } = await instance.get(
            'api/file/list/bannerimage/'
            )

        dispatch({ type: BANNER_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ 
            type: BANNER_LIST_FAIL, 
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message })
    }
}
