import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Card } from 'react-bootstrap'

import { removeDuplicates } from '../../../utils/utils'

import './FeatureSelector.css'

function FeatureSelector(props) {

    const featuresToDisplay = props.featuresToDisplay
    const featureName = props.featureName
    const [selected, setSelected] = useState({});


    useEffect(() => {
        if (featuresToDisplay.values && featuresToDisplay.values.length > 0) {
            handleSelection(featuresToDisplay.name, featuresToDisplay.values[0]);
            props.handleSelection(featuresToDisplay.name, featuresToDisplay.values[0]);
        }
    }, []);

    const handleSelection = (feature, value) => {
        setSelected({ ...selected, [feature]: value });
    };


    return (
        <>
            <Row>
                <h3 className="feature-title">{featuresToDisplay.name}:</h3>
                {featuresToDisplay.values.map((v, index) => (
                    <button
                        id={featuresToDisplay.name + index}
                        className={`btn-feature${selected[featuresToDisplay.name] === v
                            ? "-selected"
                            : ""
                            }`}
                        onClick={() => {
                            handleSelection(featuresToDisplay.name, v)
                            props.handleSelection(featuresToDisplay.name, v)
                        }}
                    >
                        {v}
                    </button>
                ))}
            </Row>
        </>
    )
}

export default FeatureSelector
