import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Container, Col, Row } from 'react-bootstrap'
import AreaCharts from '../../../../components/Charts/AreaChart'
import InformationChart from '../../../../components/Charts/InformationChart'
import LineCharts from '../../../../components/Charts/LineChart'
import PieChart from '../../../../components/Charts/PieChart.js'

import { addOneYear, minusOneYear, daysInMonth } from '../../../../utils/dateUtils'
import { getSavHistory, getOrderHistory } from '../../../../utils/productUtils'

import Loader from '../../../../components/Loader/Loader'

import { listFleet } from '../../../../actions/fleetActions';
import { getAllHistory } from '../../../../actions/historyActions';
import { useTranslation } from 'react-i18next';
import { format, setMonth, startOfYear } from 'date-fns';
import { fr, enUS } from 'date-fns/locale';

import './Dashboard.css'

function Dashboard() {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation();

  const fleetList = useSelector(state => state.fleetList)
  const { error, loading, fleet, page, pages } = fleetList

  const getAllHistoryList = useSelector(state => state.getAllHistoryList)
  const { error: errorGetAllHistory, loading: loadingGetAllHistory, historyAll } = getAllHistoryList

  const [qtyByBrand, setQtyByBrand] = useState([])
  const [qtyByType, setQtyByType] = useState([])

  const [infoMobiles, setInfoMobiles] = useState([])
  const [infoMobilesSav, setInfoMobilesSav] = useState([])

  const [mobileInfo, setMobileInfo] = useState([])
  const [averageMobileAge, setAverageMobileAge] = useState([])

  const currentYear = startOfYear(new Date());

  let locale = i18n.language == 'fr' ? fr : enUS;

  const labels = Array.from({ length: 12 }, (_, i) => format(setMonth(currentYear, i), 'MMMM', { locale }));
  const months = Object.fromEntries(labels.map((month, i) => [month, i]));

  useEffect(() => {
    dispatch(listFleet());
  }, [])

  useEffect(() => {
    if (fleet && fleet.length > 0) {
      let imeis = '';
      fleet.forEach((item) => {
        imeis += item.imei + ',';
      })
      dispatch(getAllHistory(imeis));
    }
  }, [fleet])

  useEffect(() => {
    if (historyAll && historyAll.length > 0) {
      setQtyByBrand(getQtyByBrand());
      setQtyByType(getQtyByType());
      setInfoMobiles(getInfoMobiles()); // Mobiles en circulation
      setInfoMobilesSav(getInfoMobilesSav());  // Mobiles en SAV
      setMobileInfo(getMobileInfo());
      setAverageMobileAge(getAverageMobileAge());
    }
  }, [historyAll])

  function getQtyByBrand() {
    const brands = fleet.reduce((acc, item) => {
      if (acc[item.fleet_product_details[0].brand]) {
        acc[item.fleet_product_details[0].brand] += 1;
      } else {
        acc[item.fleet_product_details[0].brand] = 1;
      }
      return acc;
    }, {});
    return brands
  }

  function getQtyByType() {
    const types = fleet.reduce((acc, item) => {
      if (acc[item.fleet_product_details[0].categorie]) {
        acc[item.fleet_product_details[0].categorie] += 1;
      } else {
        acc[item.fleet_product_details[0].categorie] = 1;
      }
      return acc;
    }, {});
    return types
  }

  function getInfoMobiles() {
    let nbMobiles = 0;
    let nbMobilesLastYear = 0;
    const date = new Date();

    let historyItems = historyAll.filter((history_item) => {
      return fleet.some((fleet_item) => fleet_item.imei === history_item.imei);
    });


    if (historyItems.length > 0) {
      nbMobiles = historyItems.filter((h) =>
        h.history.filter((item) => new Date(item.add_to_fleet) <= date
          &&
          (
            !item.remove_from_fleet ||
            new Date(item.remove_from_fleet) > date
          )
        ).length
      ).length;

      nbMobilesLastYear = historyItems.map((h) =>
        h.history.filter((item) => new Date(item.add_to_fleet) <= minusOneYear(new Date().toUTCString())
          && (
            !item.remove_from_fleet ||
            new Date(item.remove_from_fleet) >= minusOneYear(new Date().toUTCString())
          )).length
      ).reduce((a, b) => a + b, 0);
    }

    return {
      "current": nbMobiles,
      "before": nbMobilesLastYear
    }
  }

  function getInfoMobilesSav() {
    let nbMobilesSav = 0;
    let nbMobilesSavLastYear = 0;
    let historyItems = historyAll.filter((history_item) => {
      return fleet.some((fleet_item) => fleet_item.imei === history_item.imei && history_item.history.filter((item) => item.sav_history).length > 0);
    });


    historyItems.map((item) => {
      const sav = item.history.filter((item) => item.sav_history).length > 0 ? item.history.filter((item) => item.sav_history) : [];
      
      sav.map((s) => {
        let sav_item = s.sav_history
        
        if (new Date(sav_item.create_date) <= new Date() && sav_item.state != 'done') {
          nbMobilesSav++;
        }
        if (new Date(sav_item.create_date) <= minusOneYear(new Date().toUTCString())
          && new Date(sav_item.write_date) >= minusOneYear(new Date().toUTCString())) {
          nbMobilesSavLastYear++;
        }
      })
    });

    return {
      "current": nbMobilesSav,
      "before": nbMobilesSavLastYear
    }
  }

  function getMobileInfo() {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: t('Number of mobiles'),
        },
      },
    };

    const data = {
      labels,
      datasets: [
        {
          label: t('In circulation'),
          data: labels.map((label) => getNbMobilesByMonth(months[label])),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: t('In SAV'),
          data: labels.map((label) => getNbMobilesSavByMonth(months[label])),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    };

    function getNbMobilesByMonth(monthIndex) {
      const date = new Date();
      const monthDate = new Date(date.setMonth(monthIndex));

      let nbMobiles = 0;
      let nbMobilesLastYear = 0;

      let historyItems = historyAll.filter((history_item) => {
        return fleet.some((fleet_item) => fleet_item.imei === history_item.imei);
      });

      if (historyItems.length > 0) {
        nbMobiles = historyItems.filter((h) =>
          h.history.filter((item) => new Date(item.add_to_fleet) <= monthDate
            &&
            (
              !item.remove_from_fleet ||
              new Date(item.remove_from_fleet) > monthDate
            )
          ).length
        ).length;


        nbMobilesLastYear = historyItems.filter((h) =>
          h.history.filter((item) => new Date(item.add_to_fleet) <= minusOneYear(new Date().toUTCString())
            && (
              !item.remove_from_fleet ||
              new Date(item.remove_from_fleet) >= minusOneYear(new Date().toUTCString())
            )).length
        ).length;
      }

      return nbMobiles;
    }

    function getNbMobilesSavByMonth(monthIndex) {
      const date = new Date();
      const monthDate = new Date(date.setMonth(monthIndex));
      const maxDayInMonth = daysInMonth(monthDate.getFullYear(), monthIndex)
      let nbSav = 0;

      let historyItems = historyAll.filter((history_item) => {
        return fleet.some((fleet_item) => fleet_item.imei === history_item.imei && history_item.history.filter((item) => item.sav_history).length > 0);
      });


      if (historyItems.length > 0) {
        historyItems.map((item) => {
          const sav = item.history.filter((item) => item.sav_history).length > 0 ? item.history.filter((item) => item.sav_history) : [];
          sav.map((s) => {
            let sav_item = s.sav_history
            if (new Date(sav_item.create_date) <= new Date(monthDate.setDate(maxDayInMonth)) && new Date(sav_item.write_date) >= new Date(monthDate.setDate(1))) {
              nbSav++;
            }
          })
        });
        return nbSav;
      }
      else return 0;
    }




    return { "options": options, "data": data }
  }

  function getAverageMobileAge() {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          tooltip: {
            enabled: true,
          },
        },
        title: {
          display: true,
          text: t('Average fleet age'),
        },
      },
    };

    const data = {
      labels,
      datasets: [
        {
          fill: true,
          label: t('Average age'),
          data: labels.map((label) => getAverageMobileAgeByIndex(months[label])),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    };

    function getAverageMobileAgeByIndex(monthIndex) {
      const date = new Date();
      const monthDate = new Date(date.setMonth(monthIndex));
      let monthAges = [];

      let historyItems = historyAll.filter((history_item) => {
        return fleet.some((fleet_item) => fleet_item.imei === history_item.imei);
      });

      if (historyItems.length > 0) {
        historyItems.map((item) => {
          item.history.map((history) => {
            let add_to_fleet = new Date(history.add_to_fleet);
            if (add_to_fleet <= monthDate &&
              (!history.remove_from_fleet || (history.remove_from_fleet && new Date(history.remove_from_fleet) >= monthDate))
            ) {
              var age = monthDate.getFullYear() - add_to_fleet.getFullYear();
              var m = monthDate.getMonth() - add_to_fleet.getMonth();
              monthAges.push(age * 12 + m);
            }
          })
        });
      }
      const average = monthAges.length > 0 ? monthAges.reduce((a, b) => a + b) / monthAges.length : 0;

      return average;
    }

    return { "options": options, "data": data }
  }


  return (
    <>
      {loading || loadingGetAllHistory ? <Loader /> :
        fleet && fleet.length > 0 ?
          <Container fluid className="dashboard-container">
            <Row className="dashboard-row">
              <Col md={4} lg={4}>
                <div className="dashboard-card">
                  <div className="chart-wrapper">
                    <PieChart pieData={qtyByBrand} labelContent={t("Quantity")} title={t("Quantity by brand")} />
                  </div>
                  <div className="chart-wrapper">
                    {mobileInfo.data ? <LineCharts item={mobileInfo} /> : null}
                  </div>
                </div>
              </Col>
              <Col md={4} lg={3} className="dashboard-col-info">
                <div className="dashboard-card h-100">
                  <InformationChart title={t("Mobiles in circulation")} text={t("compared to last year")} top={true} data={infoMobiles} />
                  <InformationChart title={t("Mobiles in after-sales service")} text={t("compared to last year")} top={false} data={infoMobilesSav} />
                </div>
              </Col>
              <Col md={4} lg={4}>
                <div className="dashboard-card">
                  <div className="chart-wrapper">
                    <PieChart pieData={qtyByType} labelContent={t("Quantity")} title={t("Quantity by type")} />
                  </div>
                  <div className="chart-wrapper">
                    {averageMobileAge.data ? <AreaCharts item={averageMobileAge} /> : null}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          :
          <Container fluid className="dashboard-container">
            <Row className="dashboard-row">
              <Col md={12} className="dashboard-col-empty">
                <h1 className="dashboard-title">{t("No data available")}</h1>
              </Col>
            </Row>
          </Container>
      }
    </>
  );

}

export default Dashboard
