import {
    COUNTRY_LIST_REQUEST,
    COUNTRY_LIST_SUCCESS,
    COUNTRY_LIST_FAIL,
}
from '../constants/countryConstants'

import axios from 'axios'

export const listCountries = () => async (dispatch, getState) => {
    try {
        dispatch({ type: COUNTRY_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            'api/country/'
            )

        dispatch({ type: COUNTRY_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ 
            type: COUNTRY_LIST_FAIL, 
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message })
    }
}
