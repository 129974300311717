import {
    CLIENT_LIST_REQUEST,
    CLIENT_LIST_SUCCESS,
    CLIENT_LIST_FAIL,

    CLIENT_IMPERSONATED_REQUEST,
    CLIENT_IMPERSONATED_SUCCESS,
    CLIENT_IMPERSONATED_FAIL,
    CLIENT_IMPERSONATED_LOGOUT,

    CLIENT_IMPERSONATED_REVERT_REQUEST,
    CLIENT_IMPERSONATED_REVERT_SUCCESS,
    CLIENT_IMPERSONATED_REVERT_FAIL,
    RESET_CLIENT_IMPERSONATED_REVERT,

    RESET_CLIENT_IMPERSONATED_SUCCESS,
}
    from '../constants/clientConstants'

import axios from 'axios'

export const listClients = () => async (dispatch, getState) => {
    try {
        dispatch({ type: CLIENT_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })
        
        const { data } = await instance.get(
            '/api/client'
            )

        dispatch({
            type: CLIENT_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: CLIENT_LIST_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const impersonate = (user_id) => async (dispatch, getState) => {
    try {
        
        dispatch({
            type: CLIENT_IMPERSONATED_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        // Create an axios instance with headers
        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`,
            },
        });

        // Make a POST request to impersonate API
        const { data } = await instance.post('/api/impersonate/', { 'user_id': user_id })

        

        dispatch({
            type: CLIENT_IMPERSONATED_SUCCESS,
            payload: data,
        })

        localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: CLIENT_IMPERSONATED_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const adminRevertImpersonate = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: CLIENT_IMPERSONATED_REVERT_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        // Create an axios instance with headers
        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userInfo.impersonated_by_token}`,
            },
        });

        // Make a POST request to impersonate API
        const { data } = await instance.post('/api/impersonate/admin_revert_impersonate/', { 'impersonated_by': userInfo.impersonated_by })

        dispatch({
            type: CLIENT_IMPERSONATED_REVERT_SUCCESS,
            payload: data,
        })

        localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: CLIENT_IMPERSONATED_REVERT_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const logout = () => (dispatch) => {
    dispatch({ type: CLIENT_IMPERSONATED_LOGOUT })
}

export const resetImpersonateSuccess = () => {
    return { type: RESET_CLIENT_IMPERSONATED_SUCCESS };
}

export const resetImpersonateRevert = () => {
    return { type: RESET_CLIENT_IMPERSONATED_REVERT };
}