export const CATEGORIES_LIST_REQUEST = 'CATEGORIE_LIST_REQUEST'
export const CATEGORIES_LIST_SUCCESS = 'CATEGORIE_LIST_SUCCESS'
export const CATEGORIES_LIST_FAIL = 'CATEGORIE_LIST_FAIL'

export const CATEGORIES_CREATE_REQUEST = 'CATEGORIE_CREATE_REQUEST'
export const CATEGORIES_CREATE_SUCCESS = 'CATEGORIE_CREATE_SUCCESS'
export const CATEGORIES_CREATE_FAIL = 'CATEGORIE_CREATE_FAIL'

export const CATEGORIES_UPDATE_REQUEST = 'CATEGORIE_UPDATE_REQUEST'
export const CATEGORIES_UPDATE_SUCCESS = 'CATEGORIE_UPDATE_SUCCESS'
export const CATEGORIES_UPDATE_FAIL = 'CATEGORIE_UPDATE_FAIL'

export const CATEGORIES_DELETE_REQUEST = 'CATEGORIE_DELETE_REQUEST'
export const CATEGORIES_DELETE_SUCCESS = 'CATEGORIE_DELETE_SUCCESS'
export const CATEGORIES_DELETE_FAIL = 'CATEGORIE_DELETE_FAIL'