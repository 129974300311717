import{
    WB_LIST_REQUEST,
    WB_LIST_SUCCESS,
    WB_LIST_FAIL,

    WB_LIST_CREATE_REQUEST,
    WB_LIST_CREATE_SUCCESS,
    WB_LIST_CREATE_FAIL
}
from '../constants/wblistConstants'

import axios from 'axios'

export const getWBList = (id_user) => async (dispatch, getState) => {
    try {
        dispatch({ type: WB_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            `/api/wblist/?id_user=${id_user}`
            )

        dispatch({ type: WB_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: WB_LIST_FAIL, payload: error.response && error.response.data.message ? error.response.data.message : error.message })
    }
}

export const manageWBList = (id_user, id_categorie, type, all, detailWBLists, detailWBListsToRemove) => async (dispatch, getState) => {
    try {
        dispatch({
            type: WB_LIST_CREATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/wblist/manageWBLists/`,
            { 'id_user': id_user, 'id_categorie': id_categorie, 'type': type, 'all':all, 'detailWBLists': detailWBLists,
            'detailWBListsToRemove': detailWBListsToRemove }, //detailWBLists is an array of objects
            )

            dispatch({
            type: WB_LIST_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: WB_LIST_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}