import{
    CONFIG_LIST_REQUEST,
    CONFIG_LIST_SUCCESS,
    CONFIG_LIST_FAIL,

    CONFIG_GET_REQUEST,
    CONFIG_GET_SUCCESS,
    CONFIG_GET_FAIL,

    CONFIG_ADD_REQUEST,
    CONFIG_ADD_SUCCESS,
    CONFIG_ADD_FAIL,
}
from '../constants/configConstants.js'

export const configListReducer = (state = { configs: [] }, action) => {
    switch (action.type) {
        case CONFIG_LIST_REQUEST:
            return { loading: true, configs: [] }
        case CONFIG_LIST_SUCCESS:
            return { loading: false, configs: action.payload }
        case CONFIG_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const configGetReducer = (state = { config: {} }, action) => {
    switch (action.type) {
        case CONFIG_GET_REQUEST:
            return { loading: true, ...state }
        case CONFIG_GET_SUCCESS:
            return { loading: false, config: action.payload }
        case CONFIG_GET_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const configAddReducer = (state = { config: {} }, action) => {
    switch (action.type) {
        case CONFIG_ADD_REQUEST:
            return { loading: true, ...state }
        case CONFIG_ADD_SUCCESS:
            return { loading: false, config: action.payload, addSuccess: true }
        case CONFIG_ADD_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}