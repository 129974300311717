import { HashRouter as Router, Routes, Route } from "react-router-dom"
import { useSelector } from 'react-redux';
import { Container } from "react-bootstrap";


import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'

import Home from './screen/Home/Home'
import Products from './screen/Products/Products'
import ProductDetails from './screen/Products/ProductDetails'
import Cart from './screen/Cart/Cart'
import CartAdresses from './screen/CartAdresses/CartAdresses'
import Login from './screen/Login/Login'
import Register from './screen/Register/Register'
import Profile from './screen/Profile/Profile'
import Payment from './screen/Payment/Payment'
import Dashboard from './screen/Connected/Client/Dashboard/Dashboard'
import Fleet from './screen/Connected/Client/Fleet/Fleets'
import AddToFleet from './screen/Connected/Client/Fleet/AddToFleet'
import AftersaleNew from './screen/Connected/Client/AfterSale/AfterSaleNew'
import AftersaleDevices from './screen/Connected/Client/AfterSale/AfterSaleDevices'
import AftersaleTickets from './screen/Connected/Client/AfterSale/AfterSaleTickets'
import Quotations from './screen/Connected/Client/Quotations/Quotations'
import Orders from './screen/Connected/Client/Orders/Orders'
import PlaceOrder from './screen/PlaceOrder/PlaceOrder'
import Order from './screen/Order/Order'
import AddArticles from './screen/Connected/Admin/Articles/AddArticles/AddArticles'
import RemoveArticles from './screen/Connected/Admin/Articles/RemoveArticles/RemoveArticles'
import InfosStock from './screen/Connected/Admin/Articles/InfosStock/InfosStock'
import ArticleNewValues from './screen/Connected/Admin/Articles/ArticleNewValues/ArticleNewValues'
import ImageBanner from "./screen/Connected/Admin/Images/ImageBanner/ImageBanner";
import ImageTuiles from "./screen/Connected/Admin/Images/ImageTuiles/ImageTuiles";
import ImageProducts from "./screen/Connected/Admin/Images/ImageProducts/ImageProducts";
import Categories from "./screen/Connected/Admin/Categories/Categories";
import Societe from './screen/Profile/Societe'
import Collaborateur from './screen/Profile/Collaborateur'
import BlackList from './screen/Connected/Admin/List/BlackList'
import WhiteList from './screen/Connected/Admin/List/WhiteList'
import Message from './screen/Message/Message'
import Config from './screen/Connected/Admin/Config/Config'
import AddArticlesNonImei from './screen/Connected/Admin/ArticlesNonImei/AddArticlesNonImei/AddArticlesNonImei'
import RemoveArticlesNonImei from './screen/Connected/Admin/ArticlesNonImei/RemoveArticlesNonImei/RemoveArticlesNonImei'
import InfosStockNonImei from './screen/Connected/Admin/ArticlesNonImei/InfosStockNonImei/InfosStockNonImei'
import ProductsNonImei from './screen/Products/ProductsNonImei'
import ProductNonImeiDetails from './screen/Products/ProductNonImeiDetails'
import TypeAfterSale from './screen/Connected/Admin/AfterSale/TypeAfterSale'
import AfterSale from "./screen/Connected/Admin/AfterSale/AfterSale";
import HeaderBrandOrder from "./screen/Connected/Admin/Display/HeaderBrandOrder/HeaderBrandOrder";
import FleetDetails from "./screen/Connected/Client/Fleet/FleetDetails";
import FleetUsers from "./screen/Connected/Client/FleetUsers/FleetUsers";
import FleetUsersHistory from "./screen/Connected/Client/FleetUsers/FleetUsersHistory";
import FleetSims from "./screen/Connected/Client/FleetSims/FleetSims";
import FleetEntity from "./screen/Connected/Client/FleetEntity/FleetEntity";
import Catalog from "./screen/Connected/Client/Catalog/Catalog";
import Connect from "./screen/Connected/Admin/Connect/Connect";
import Partners from "./screen/Partners/Partners";
import Services from "./screen/Services/Services";
import Users from "./screen/Connected/Admin/Users/Users";
import Rental from "./screen/Connected/Common/Rental/Rental";
import Accessories from "./screen/Connected/Admin/Accessories/Accessories";

import SideBar from "./components/SideBar/SideBar"

import './App.css';
import Categorie from "./components/Tuiles/Categorie";
import axios from 'axios';

function App() {

  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  });


  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  return (
    <Router>
      <main>
        <Header />
        {userInfo ?
          <SideBar>
            <Routes>
              {/* Routes Client */}
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/fleet" element={<Fleet />} />
              <Route path="/fleet-details/:id" element={<FleetDetails />} />
              <Route path="/addtofleet" element={<AddToFleet />} />
              <Route path="/aftersale/new" element={<AftersaleNew />} />
              <Route path="/aftersale/devices" element={<AftersaleDevices />} />
              <Route path="/aftersale/tickets" element={<AftersaleTickets />} />
              <Route path="/quotations" element={<Quotations />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/fleet-user" element={<FleetUsers />} />
              <Route path="/fleet-user-history" element={<FleetUsersHistory />} />
              <Route path="/fleet-sim" element={<FleetSims />} />
              <Route path="/fleet-entity" element={<FleetEntity />} />
              <Route path="/catalog" element={<Catalog />} />

              {/* Routes Admin */}
              <Route path="/connect" element={<Connect />} />

              <Route path="/articles/add" element={<AddArticles />} />
              <Route path="/articles/remove" element={<RemoveArticles />} />
              <Route path="/articles/list" element={<InfosStock />} />

              <Route path="/articlesNonImei/add" element={<AddArticlesNonImei />} />
              <Route path="/articlesNonImei/remove" element={<RemoveArticlesNonImei />} />
              <Route path="/articlesNonImei/list" element={<InfosStockNonImei />} />

              <Route path="/articles/setNewValues" element={<ArticleNewValues />} />
              <Route path="/images/banner" element={<ImageBanner />} />
              <Route path="/images/tuiles" element={<ImageTuiles />} />
              <Route path="/images/products" element={<ImageProducts />} />
              <Route path="/display/header-brand-order" element={<HeaderBrandOrder />} />
              <Route path="/list/white" element={<WhiteList />} />
              <Route path="/list/black" element={<BlackList />} />
              <Route path="/aftersale/aftersale" element={<AfterSale />} />
              <Route path="/aftersale/type-aftersale" element={<TypeAfterSale />} />
              <Route path="/categories" element={<Categories />} />
              <Route path="/users" element={<Users />} />
              <Route path="/config" element={<Config />} />
              <Route path="/accessories" element={<Accessories />} />

              {/* Routes Communes */}
              <Route path="/" element={<Home />} exact />
              <Route path="/home" element={<Home />} />

              <Route path="/imei-products" element={<Products />} />
              <Route path="/imei-products/:id" element={<ProductDetails />} />

              <Route path="/products" element={<ProductsNonImei />} />
              <Route path="/products/:id" element={<ProductNonImeiDetails />} />

              <Route path="/cart" element={<Cart />} />
              <Route path="/cart/:id" element={<Cart />} />
              <Route path="/cart/adresses" element={<CartAdresses />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/profile/societe" element={<Societe />} />
              <Route path="/profile/collab" element={<Collaborateur />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/placeorder" element={<PlaceOrder />} />
              <Route path="/order/:id" element={<Order />} />
              <Route path="/message" element={<Message />} />
              <Route path="/articles/rental" element={<Rental />} />

              <Route path="/partners" element={<Partners />} /> 
              <Route path="/services" element={<Services />} /> 


              <Route path="*" element={<h1>404 Not Found</h1>} />
            </Routes>
          </SideBar>
          :
          <Container className="children"> {/* Dans SideBar */}
            <Routes>
              <Route path="/" element={<Login />} exact />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/home" element={<Home />} />

              <Route path="/imei-products" element={<Products />} />
              <Route path="/imei-product/:id" element={<ProductDetails />} />

              <Route path="/products" element={<ProductsNonImei />} />

              <Route path="/cart" element={<Cart />} />
              <Route path="/cart/:id" element={<Cart />} />
              <Route path="/cart/adresses" element={<CartAdresses />} />
              
              
              <Route path="/profile" element={<Profile />} />
              <Route path="/profile/societe" element={<Societe />} />
              <Route path="/profile/collab" element={<Collaborateur />} /> 


              <Route path="*" element={<h1>404 Not Found</h1>} />
            </Routes>
          </Container>
        }
        <Footer />
      </main>
    </Router>
  );
}

export default App;
