import{
    CONFIG_LIST_REQUEST,
    CONFIG_LIST_SUCCESS,
    CONFIG_LIST_FAIL,

    CONFIG_GET_REQUEST,
    CONFIG_GET_SUCCESS,
    CONFIG_GET_FAIL,

    CONFIG_ADD_REQUEST,
    CONFIG_ADD_SUCCESS,
    CONFIG_ADD_FAIL,
}
from '../constants/configConstants.js'

import axios from 'axios'

export const listConfigs = () => async (dispatch, getState) => {
    try {
        dispatch({ type: CONFIG_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            '/api/config/'
            )

        dispatch({
            type: CONFIG_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: CONFIG_LIST_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

export const getConfig = (key, section) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONFIG_GET_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            `/api/config/get/?key=${key}&section=${section}`
            )

        dispatch({
            type: CONFIG_GET_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: CONFIG_GET_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

export const addConfig = (key, value, section) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONFIG_ADD_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/config/add/`, { 'key': key, 'value': value, 'section': section }
            )

        dispatch({
            type: CONFIG_ADD_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: CONFIG_ADD_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}