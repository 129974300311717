import {
    FEATURE_LIST_REQUEST,
    FEATURE_LIST_SUCCESS,
    FEATURE_LIST_FAIL,

    ALL_FEATURE_REQUEST,
    ALL_FEATURE_SUCCESS,
    ALL_FEATURE_FAIL,

    FEATURE_FILTER_REQUEST,
    FEATURE_FILTER_SUCCESS,
    FEATURE_FILTER_FAIL,

    FEATURE_MAJ_FILTER_REQUEST,
    FEATURE_MAJ_FILTER_SUCCESS,
    FEATURE_MAJ_FILTER_FAIL,
    FEATURE_MAJ_FILTER_RESET,

    FEATURE_ADD_REQUEST,
    FEATURE_ADD_SUCCESS,
    FEATURE_ADD_FAIL,
    FEATURE_ADD_RESET,

    FEATURE_DELETE_REQUEST,
    FEATURE_DELETE_SUCCESS,
    FEATURE_DELETE_FAIL,
    PRODUCT_CREATE_IN_ODOO_RESET,
}
from '../constants/featureConstants'
import axios from 'axios'

export const listFeatures  = (imei) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FEATURE_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/features/`,
            { 'imei': imei },
            )

        dispatch({
            type: FEATURE_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: FEATURE_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
export const allFeatures = () => async (dispatch, getState) => {
    try {
        dispatch({ type: ALL_FEATURE_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            'api/features/all'
            )

        dispatch({ type: ALL_FEATURE_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ 
            type: ALL_FEATURE_FAIL, 
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message })
    }
}

export const getIsFilter = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: FEATURE_FILTER_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(`/api/features/get-maj-filter/`)

        dispatch({
            type: FEATURE_FILTER_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: FEATURE_FILTER_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const majIsFilter  = (id_odoo, name, is_filter) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FEATURE_MAJ_FILTER_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/features/maj-filter/`,
            { 'id_odoo': id_odoo, 'name': name, 'is_filter': is_filter },
            )

        dispatch({
            type: FEATURE_MAJ_FILTER_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: FEATURE_MAJ_FILTER_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const addFeatures  = (id_odoo, selectedFeature, is_filter, newFeature) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FEATURE_ADD_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/features/add/`,
            { 'id_odoo': id_odoo, 'selectedFeature': selectedFeature, 'is_filter': is_filter, 'newFeature': newFeature },
            )

        dispatch({
            type: FEATURE_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: FEATURE_ADD_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteFeature = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FEATURE_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/features/del/`,
            { 'id': id },
            )

        dispatch({
            type: FEATURE_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: FEATURE_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const majFeatureReset = () => (dispatch) => {
    dispatch({ type: FEATURE_MAJ_FILTER_RESET })
}

export const addFeatureReset = () => (dispatch) => {
    dispatch({ type: FEATURE_ADD_RESET })
}
