import {
    SKU_LIST_REQUEST,
    SKU_LIST_SUCCESS,
    SKU_LIST_FAIL,

    SKU_ADD_REQUEST,
    SKU_ADD_SUCCESS,
    SKU_ADD_FAIL,

    SKU_DELETE_REQUEST,
    SKU_DELETE_SUCCESS,
    SKU_DELETE_FAIL,

    SKU_CHECK_REQUEST,
    SKU_CHECK_SUCCESS,
    SKU_CHECK_FAIL,
}
from '../constants/skuConstants';


export const skuListReducer = (state = { skus: [] }, action) => {
    switch (action.type) {
        case SKU_LIST_REQUEST:
            return { loading: true, skus: [] }
        case SKU_LIST_SUCCESS:
            return { loading: false, skus: action.payload }
        case SKU_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const skuAddReducer = (state = {}, action) => {
    switch (action.type) {
        case SKU_ADD_REQUEST:
            return { loading: true }
        case SKU_ADD_SUCCESS:
            return { loading: false, success: true }
        case SKU_ADD_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const skuCheckReducer = (state = {}, action) => {
    switch (action.type) {
        case SKU_CHECK_REQUEST:
            return { loading: true }
        case SKU_CHECK_SUCCESS:
            return { loading: false, success: true, checked: action.payload }
        case SKU_CHECK_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const skuDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case SKU_DELETE_REQUEST:
            return { loading: true }
        case SKU_DELETE_SUCCESS:
            return { loading: false, success: true }
        case SKU_DELETE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
    