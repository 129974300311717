import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Container, Nav, Navbar, NavDropdown, Col, Row, ListGroup, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import Modal from "react-modal";

import { LinkContainer } from 'react-router-bootstrap'
import { logout } from '../../actions/userActions'
import './Header.css'
import '../Search/Search.css'
import { listProducts, listNonImeiProducts } from '../../actions/productActions';
import { listCategories } from '../../actions/categoriesActions';
import { getUnreadMessageCount } from '../../actions/messageActions';
import { listHeaderCategories } from '../../actions/headerCategoriesActions';
import { listHeaderBrandOrder } from '../../actions/headerBrandOrderActions'
import { adminRevertImpersonate, resetImpersonateRevert } from '../../actions/clientActions'
import { getUserSeller } from '../../actions/userSellerActions'

import { sendMail, resetMessageSendMail } from '../../actions/messageActions'

import { removeDuplicatesByProperties, checkProperties, pluralize } from '../../utils/utils';
import Loader from "../Loader/Loader";

function Header() {
  const dispatch = useDispatch()
  let navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const [productDisplayItems, setProductDisplayItems] = useState([]);
  const [productNonImeiDisplayItems, setProductNonImeiDisplayItems] = useState([]);
  const [itemsToDisplay, setItemsToDisplay] = useState(null);

  const [selectedHeaderCategorie, setSelectedHeaderCategorie] = useState({});

  const [categorieItems, setCategorieItems] = useState([]);
  const [productItems, setProductItems] = useState([]);
  const [productNonImeiItems, setProductNonImeiItems] = useState([]);

  const [brandItems, setBrandItems] = useState([]);
  const [osItems, setOsItems] = useState([]);

  const [tailleEcran, setTailleEcran] = useState(''); //TODO A gérer en ajoutant les écrans

  const [unreadMessageCount, setUnreadMessageCount] = useState(0);

  const impersonateRevert = useSelector((state) => state.impersonateRevert)
  const { success } = impersonateRevert

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productList = useSelector(state => state.productList)
  const { error, loading, products, page, pages } = productList

  const productNonImeiList = useSelector(state => state.productNonImeiList)
  const { productsNonImei } = productNonImeiList

  const categoriesList = useSelector(state => state.categoriesList)
  const { categories } = categoriesList

  const headerCategoriesList = useSelector((state) => state.headerCategoriesList);
  const { loading: loadingHeaderCategories, error: errorHeaderCategories, headerCategories } = headerCategoriesList;

  const markedAsUnreadCount = useSelector(state => state.markedAsUnreadCount)
  const { unreadCount } = markedAsUnreadCount

  const headerBrandOrder = useSelector(state => state.headerBrandOrder)
  const { headerBrandOrderList, loading: headerLoading } = headerBrandOrder

  const userSellerGet = useSelector(state => state.userSellerGet)
  const { userSeller } = userSellerGet

  const mailSend = useSelector(state => state.mailSend)
  const { success: successSendMail } = mailSend

  const [showList, setShowList] = useState(false);
  const [formValues, setFormValues] = useState({ subject: '', body: '' });

  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  useEffect(() => {
    dispatch(getUserSeller())
    dispatch(listCategories());
    dispatch(listHeaderCategories());
    dispatch(listProducts());
    dispatch(listNonImeiProducts(userInfo ? userInfo.id_odoo : -1));
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userInfo, dispatch])


  useEffect(() => {
    async function fetchUnreadCount() {
      dispatch(getUnreadMessageCount(userInfo ? userInfo._id : -1));
    }

    changeLanguage('fr')
    const intervalId = setInterval(fetchUnreadCount, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const redirectPath = localStorage.getItem('redirectPath');
    if (redirectPath) {
      navigate(redirectPath);
      localStorage.removeItem('redirectPath');  // clean up after navigation
    }
  }, [])

  useEffect(() => {
    if (success) {
      localStorage.setItem('redirectPath', '/');
      window.location.reload();
      dispatch(resetImpersonateRevert())
    }
  }, [success])

  useEffect(() => {
    if (unreadCount) {
      setUnreadMessageCount(unreadCount)
    }
  }, [unreadCount])

  useEffect(() => {
    if (!headerBrandOrderList || headerBrandOrderList.length === 0)
      dispatch(listHeaderBrandOrder())
  }, [categories])

  useEffect(() => {
    if (headerCategories && headerCategories.length > 0 && categories && categories.length > 0) {
      makeProductDisplayItems()
    }
  }, [products])

  useEffect(() => {
    if (categories && categories.length > 0) {
      makeProductNonImeiDisplayItems()
    }
  }, [productsNonImei])

  useEffect(() => {
    if (productDisplayItems.length > 0 && categories.length > 0) {
      let categorie = categories[0]

      let categorie_items = productDisplayItems.filter(p => p.categorie === categorie.id)
      if (categorie_items.length > 0) {
        setCategorieItems(categorie_items[0].items)
      }
    }
  }, [productDisplayItems])

  useEffect(() => {
    if (successSendMail) {
      setFormValues({ subject: '', body: '' })
      dispatch(resetMessageSendMail())
    }
  }, [successSendMail, reset, dispatch])

  function makeProductNonImeiDisplayItems() {
    let product_display_items = [];

    // On récupère les headerCategories à partir des catégories IMEI
    let categories_non_imei = categories.filter(c => !c.is_imei).map(c => c.id)
    let header_categories_non_imei = headerCategories.filter((hc) =>
      hc.categories.some((category) => categories_non_imei.includes(category))
    );

    header_categories_non_imei.forEach(hc => {
      let obj = {
        'header_categorie': hc.name,
        'categories': categories.filter(c => hc.categories.includes(c.id)).map(c => {
          return { // Ajout de l'instruction return ici
            'categorie': c,
            'filters':
              // Ici, on va loop sur les filtres pour récupérer les valeurs dans products
              c.filters && c.filters.length > 0 ? getValuesFromFilters(c, productsNonImei) : []
          };

        })
      };

      if (headerBrandOrderList && headerBrandOrderList != undefined && headerBrandOrderList.length > 0) {
        obj.categories.forEach(c => {
          if (c.filters.brand && c.filters.brand.length > 0) {
            let ordered_brand = headerBrandOrderList.filter(item => item.categorie === c.categorie.id).map(item => item.brand)

            c.filters.brand = c.filters.brand.sort(function (a, b) {
              return ordered_brand.indexOf(a) - ordered_brand.indexOf(b);
            })
          }
        })
      }

      product_display_items.push(obj);
    });

    setProductNonImeiDisplayItems(product_display_items)
  }

  function makeProductDisplayItems() {
    let product_display_items = [];

    // On récupère les headerCategories à partir des catégories IMEI
    let categories_imei = categories.filter(c => c.is_imei).map(c => c.id)
    let header_categories_imei = headerCategories.filter((hc) =>
      hc.categories.some((category) => categories_imei.includes(category))
    );

    header_categories_imei.forEach(hc => {
      let obj = {
        'header_categorie': hc.name,
        'categories': categories.filter(c => hc.categories.includes(c.id)).map(c => {
          return { // Ajout de l'instruction return ici
            'categorie': c,
            'filters':
              // Ici, on va loop sur les filtres pour récupérer les valeurs dans products
              c.filters && c.filters.length > 0 ? getValuesFromFilters(c, products) : []
          };

        })
      };

      if (headerBrandOrderList && headerBrandOrderList != undefined && headerBrandOrderList.length > 0) {
        console.log('headerBrandOrderList', headerBrandOrderList)
        obj.categories.forEach(c => {
          if (c.filters.brand && c.filters.brand.length > 0) {
            let ordered_brand = headerBrandOrderList.filter(item => item.categorie === c.categorie.id).map(item => item.brand)
            console.log('ordered_brand', ordered_brand)

            c.filters.brand = ordered_brand
          }
        })
      }

      console.log('obj', obj)

      product_display_items.push(obj);
    });
    setProductDisplayItems(product_display_items)
  }

  function getValuesFromFilters(c, values) {
    let filters = c.filters;
    let arr = values.filter(p => p.categorie_odoo === c.name || p.categories.includes(c.id))

    // On trie pour enlever les marques qui n'auraient pas été intégrées dans le header
    if (headerBrandOrderList && headerBrandOrderList != undefined && headerBrandOrderList.length > 0)
      arr = arr.filter(p => headerBrandOrderList.filter(item => item.categorie === c.id).map(item => item.brand).includes(p.brand))

    const extractNestedValues = (obj) => {
      let result = [];

      const helper = (currentObj, parentKey = null) => {
        for (const key in currentObj) {
          if (typeof currentObj[key] === 'object') {
            helper(currentObj[key], key);
          } else {
            result.push({ path: key || key, value: currentObj[key] });
          }
        }
      };

      helper(obj);

      return result;
    };

    const uniqueValues = arr.reduce((accumulator, item) => {
      const nestedValues = extractNestedValues(item);

      filters.forEach((filter) => {
        nestedValues.forEach(({ path, value }) => {
          if (path === filter) {
            if (!accumulator[path]) {
              accumulator[path] = new Set();
            }
            accumulator[path].add(value);
          }
        });
      });

      return accumulator;
    }, {});

    // Conversion des Set en tableaux
    const uniqueValuesArray = Object.fromEntries(
      Object.entries(uniqueValues).map(([key, value]) => [key, [...value]])
    );

    return uniqueValuesArray;
  }

  const logoutHandler = () => {
    dispatch(logout())
    navigate('/');
  }

  const handleClickOutside = (e) => {
    if (e.target.id !== "nosProduitsButton" && wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setShowList(false);
    }
  };

  const handleProductClick = (e) => {
    // Ici démarre la construction de la liste des catégories à afficher
    // SetShowList permet d'afficher ou non la liste des catégories

    e.preventDefault();
    e.stopPropagation();

    if (e.target.id == "nosProduitsButton")
      setShowList(!showList);
  };

  const HeaderCategorieSelected = (e) => {
    setItemsToDisplay(null)
    // Ici, il faut recharger la liste des composants de la partie de droite
    // On va donc chercher en premier les catégories associées
    if (productDisplayItems && productDisplayItems.length > 0) {
      productDisplayItems.forEach(p => {
        let hc = headerCategories.filter(hc => hc.name === p.header_categorie)[0]
        if (hc.name === e.target.innerText) {
          setSelectedHeaderCategorie(hc)
          setItemsToDisplay(DisplayItems(p))
        }
      })
    }

    if (productNonImeiDisplayItems && productNonImeiDisplayItems.length > 0) {
      productNonImeiDisplayItems.forEach(p => {
        let hc = headerCategories.filter(hc => hc.name === p.header_categorie)[0]
        if (hc.name === e.target.innerText) {
          setSelectedHeaderCategorie(hc)
          setItemsToDisplay(DisplayItems(p))
        }
      })
    }
  }

  function DisplayItems(item) {
    if (item.categories[0].categorie.is_imei)
      return (displayImeiItems(item));
    else
      return (displayNonImeiItems(item));
  }

  function Navigate(type, obj, imei) {

    let categorie = obj.categorie
    let headerCategorie = obj.headerCategorie
    let param = obj.param

    navigate({
      pathname: (imei ? "imei-products" : "products"),
      search: createSearchParams({
        headerCategorie: headerCategories.filter(hc => hc.name === headerCategorie)[0].id,
        categorie: categorie.id,
        paramType: type,
        param: param
      }).toString()
    });
  }

  function displayNonImeiItems(item) {
    return (
      <Row>
        <Row
          className={`header-all-list ${userInfo && userInfo.impersonated_by ? 'impersonatedLight' :
            userInfo && userInfo.isAdmin ? 'adminLight' : 'clientLight'
            }`}
          onClick={() => {
            setShowList(!showList);
            Navigate('all',
              { headerCategorie: item.header_categorie, categorie: { id: 0 }, param: 'all' },
              false)
          }}
        >
          <span>{t('All products')} {pluralize(item.header_categorie)}</span>
        </Row>
        {
          item.categories.map(c => (
            <>
              <Col xs={12 / item.categories.length} sm={12 / item.categories.length} md={12 / item.categories.length} lg={12 / item.categories.length}
                className="header-list-col"
              >
                <Row className='header-list-title'>
                  {c.categorie.name}
                </Row>
                <Row
                  className={`header-all-list ${userInfo && userInfo.impersonated_by ? 'impersonatedLight' :
                    userInfo && userInfo.isAdmin ? 'adminLight' : 'clientLight'
                    }`}
                  onClick={() => {
                    setShowList(!showList);
                    Navigate('all',
                      { headerCategorie: item.header_categorie, categorie: c.categorie, param: 'all' },
                      false)
                  }}
                >
                  <span>{t('All products')}</span>
                </Row>
                <Row className='filter-row'>
                  {Object.entries(c.filters).map(([filterKey, filterValues], j) => (
                    <Col xs={6 / c.filters.length} sm={6 / c.filters.length} md={6 / c.filters.length} lg={6 / c.filters.length}
                      className={`filter-col ${userInfo && userInfo.impersonated_by ? 'impersonatedLight' :
                        userInfo && userInfo.isAdmin ? 'adminLight' : 'clientLight'
                        }`}
                    >
                      <>
                        <span>{t('by')} {t(filterKey)} : </span>
                        <ul className='header-features-list' variant='flush' >
                          {filterValues.map((value, k) => (
                            <li key={k}
                              className={`border-0 header-features-item ${userInfo && userInfo.impersonated_by ? 'impersonatedHover' :
                                userInfo && userInfo.isAdmin ? 'adminHover' : 'clientHover'
                                }`}
                              onClick={() => {
                                setShowList(!showList);
                                Navigate(filterKey, { headerCategorie: item.header_categorie, categorie: c.categorie, param: value }, false)
                              }}
                            >
                              {value}
                            </li>
                          ))}
                        </ul>
                      </>
                    </Col>
                  ))}
                </Row>
              </Col>
            </>
          ))
        }
      </Row>
    )
  }

  function displayImeiItems(item) {

    return (
      <Row>
        <Row
          className={`header-all-list ${userInfo && userInfo.impersonated_by ? 'impersonatedLight' :
            userInfo && userInfo.isAdmin ? 'adminLight' : 'clientLight'
            }`}
          onClick={() => {
            setShowList(!showList);
            Navigate('all', { headerCategorie: item.header_categorie, categorie: { id: 0 }, param: 'all' }, true)
          }}
        >
          <span>{t('All products')} {pluralize(item.header_categorie)}</span>
        </Row>
        {
          item.categories.map(c => (
            <>
              <Col xs={12 / item.categories.length} sm={12 / item.categories.length} md={12 / item.categories.length} lg={12 / item.categories.length}
                className="header-list-col"
              >
                <Row className='header-list-title'>
                  {c.categorie.name}
                </Row>
                <Row
                  className={`header-all-list ${userInfo && userInfo.impersonated_by ? 'impersonatedLight' :
                    userInfo && userInfo.isAdmin ? 'adminLight' : 'clientLight'
                    }`}
                  onClick={() => {
                    setShowList(!showList);
                    Navigate('all', { headerCategorie: item.header_categorie, categorie: c.categorie, param: 'all' }, true)
                  }}
                >
                  <span>{t('All products')}</span>
                </Row>
                <Row className='filter-row'>
                  {Object.entries(c.filters).map(([filterKey, filterValues], j) => (
                    <Col xs={6 / c.filters.length} sm={6 / c.filters.length} md={6 / c.filters.length} lg={6 / c.filters.length}
                      className={`filter-col ${userInfo && userInfo.impersonated_by ? 'impersonatedLight' :
                        userInfo && userInfo.isAdmin ? 'adminLight' : 'clientLight'
                        }`}
                    >
                      <span>{t('by')} {t(filterKey)} : </span>
                      <ul className='header-features-list' variant='flush' >
                        {filterValues.map((value, k) => (
                          <li key={k}
                            className={`border-0 header-features-item ${userInfo && userInfo.impersonated_by ? 'impersonatedHover' :
                              userInfo && userInfo.isAdmin ? 'adminHover' : 'clientHover'
                              }`}
                            onClick={() => {
                              setShowList(!showList);
                              Navigate(filterKey, { headerCategorie: item.header_categorie, categorie: c.categorie, param: value }, true)
                            }}
                          >
                            {value}
                          </li>
                        ))}
                      </ul>
                    </Col>
                  ))}
                </Row>
              </Col>
            </>
          ))
        }
      </Row>
    )
  }

  function stopImpersonateHandler() {
    dispatch(adminRevertImpersonate())
  }

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    reset({ subject: '', body: '' }); // reset form when closing the modal
  };

  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = (data) => {
    dispatch(sendMail(data['subject'], data['body']))
    closeModal();
  };

  const sendMail = () => {
    const recipient = 'test@test.com'
    const mailtoLink = `mailto:${encodeURIComponent(userSeller.email)}`;
    window.location.href = mailtoLink;
  };

  const Textarea = styled.textarea`
  width: 100%;
  height: 25vh; 
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 1em;

  @media (max-width: 768px) {
    height: 30vh;
  }

  @media (max-width: 480px) {
    height: 40vh;
  }
`;


  return (
    <>
      <header className={`header ${userInfo && userInfo.impersonated_by ? 'impersonated' :
        userInfo && userInfo.isAdmin ? 'admin' : 'client'
        }`}>
        <Navbar className='navbar' variant="dark">
          <Col xs={12} sm={6} md={4} lg={2}>
            <Image className='header-logo' src={userInfo ? userInfo.logo : null} fluid rounded />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} className="header-links-col">
            <Container className='header-pages-link'>
              <LinkContainer className='header-page-link' to='/home'>
                <Nav.Link>{t('Home')}</Nav.Link>
              </LinkContainer>
              {userInfo ? (
                <div className="header-page-link">
                  <Nav.Link>
                    <i id="nosProduitsButton" onClick={handleProductClick} class="fa-solid fa-list"></i>
                    <span id="nosProduitsButton" onClick={handleProductClick} class="header-products-icon">{t('Our products')}</span>
                    {showList ? <i id="nosProduitsButton" onClick={handleProductClick} class="header-products-icon fa-solid fa-chevron-down fa-xs"></i>
                      : <i id="nosProduitsButton" onClick={handleProductClick} class="header-products-icon fa-solid fa-chevron-right fa-xs"></i>
                    }
                  </Nav.Link>
                </div>
              ) : null
              }
              <LinkContainer className='header-page-link' to='/services'>
                <Nav.Link>{t('Our services')}</Nav.Link>
              </LinkContainer>
              <LinkContainer className='header-page-link' to='/partners'>
                <Nav.Link>{t('Our partners')}</Nav.Link>
              </LinkContainer>
            </Container>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4}>
            <Container className='header-pages-falink'>
              {userInfo ? (
                <>
                  {
                    !userInfo.isAdmin ? (
                      <LinkContainer className='header-page-link' to='/cart'>
                        <Nav.Link><i className="fas fa-shopping-cart"></i></Nav.Link>
                      </LinkContainer>
                    ) : null
                  }

                  <NavDropdown className='header-page-link' title={userInfo.name} id='username'>
                    {
                      !userInfo.isAdmin ?
                        <LinkContainer to='/profile/'>
                          <NavDropdown.Item>{t('Profile')}</NavDropdown.Item>
                        </LinkContainer>
                        : null
                    }
                    < NavDropdown.Item onClick={logoutHandler}>{t('Logout')}</NavDropdown.Item>
                    {
                      userInfo && userInfo.impersonated_by_token ? (
                        <NavDropdown.Item onClick={stopImpersonateHandler}>{t('Stop Impersonate')}</NavDropdown.Item>
                      ) : null
                    }
                  </NavDropdown>
                </>
              ) : (
                <LinkContainer className='header-page-link' to='/login/'>
                  <Nav.Link><i className="fas fa-user"></i></Nav.Link>
                </LinkContainer>)}
              {userInfo && !userInfo.isAdmin ? (
                <div className='header-page-link'>
                  <Nav.Link onClick={sendMail}>
                    <i className="fas fa-message"></i>
                  </Nav.Link>
                </div>
              ) : null}
              <NavDropdown className='header-page-link' title={t('Language')} id='language'>
                <NavDropdown.Item onClick={() => changeLanguage('en')}>{t('English')}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('fr')}>{t('Français')}</NavDropdown.Item>
              </NavDropdown>
            </Container>
          </Col>
        </Navbar>
        {/* ici, on gère l'affichage des catégories et headerCategorie */}
        <TransitionGroup>
          {showList && (
            <CSSTransition
              timeout={500}
              classNames="fade"
            >
              <Row ref={wrapperRef} className={`header-category-row ${userInfo && userInfo.impersonated_by ? 'impersonated' :
                userInfo && userInfo.isAdmin ? 'admin' : 'client'
                }`}>
                <Col md={3}>
                  <ListGroup className='header-categorie-list' variant='flush' onMouseOver={(e) => HeaderCategorieSelected(e)}>
                    {
                      headerCategories ? headerCategories.map((headerCategorie) => (
                        <ListGroup.Item
                          className={`border-0 header-categorie-item ${userInfo && userInfo.impersonated_by ? 'impersonatedLight' :
                            userInfo && userInfo.isAdmin ? 'adminLight' : 'clientLight'
                            }`} key={headerCategorie.id}>
                          <span>{headerCategorie.name}</span>
                        </ListGroup.Item>
                      )) : <Nav.Link>{t('Loading...')}</Nav.Link>
                    }
                  </ListGroup>
                </Col>
                <Col md={9}>
                  {itemsToDisplay ? itemsToDisplay : <Loader />}
                </Col>
              </Row>
            </CSSTransition>
          )}
        </TransitionGroup>
      </header >
      <Modal
        isOpen={modalIsOpen}
        className="message-modal"
        onRequestClose={closeModal}
        contentLabel={t('Envoyer un message')}
        style={{ overlay: { zIndex: 9999 } }}
      >

        <h2>Envoyer un message</h2>
        <Row className="message-row">
          <input
            type="text"
            {...register("subject", { required: true })}
            placeholder={t('Subject')}
          />
        </Row>
        <Row className="message-row">
          <Textarea
            {...register("body", { required: true })}
          />
        </Row>
        <Row className="button-message-row">
          <Col>
            <button className="message-modal-send-btn" onClick={handleSubmit(onSubmit)}>Envoyer</button>
          </Col>
          <Col>
            <button className="message-modal-close-btn" onClick={closeModal}>Fermer</button>
          </Col>
        </Row>
      </Modal >
    </>
  )

}

export default Header