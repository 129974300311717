import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, ListGroup } from 'react-bootstrap'
import Switch from "react-switch";

import Loader from '../../../../../components/Loader/Loader'
import Select from '../../../../../components/Select/Select'

import { listCategories } from '../../../../../actions/categoriesActions'
import { listHeaderBrandOrder, addHeaderBrandOrder, delHeaderBrandOrder } from '../../../../../actions/headerBrandOrderActions'
import { listHeaderCategories } from '../../../../../actions/headerCategoriesActions'
import { listProducts, listNonImeiProducts } from '../../../../../actions/productActions'

import { removeDuplicatesByNestedProperty } from '../../../../../utils/utils'

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { ListItemAvatar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import './HeaderBrandOrder.css'

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function HeaderBrandOrder() {
    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const categoriesList = useSelector(state => state.categoriesList)
    const { categories } = categoriesList

    const headerBrandOrder = useSelector(state => state.headerBrandOrder)
    const { headerBrandOrderList, loading: headerLoading } = headerBrandOrder

    const headerBrandOrderAdd = useSelector(state => state.headerBrandOrderAdd)
    const { success: successHeaderBrandOrderAdd, loading : loadingHeaderBrandOrderAdd } = headerBrandOrderAdd

    const headerBrandOrderDel = useSelector(state => state.headerBrandOrderDel)
    const { success: successHeaderBrandOrderDel, loading : loadingHeaderBrandOrderDel } = headerBrandOrderDel

    const productList = useSelector(state => state.productList)
    const { error, loading, products, page, pages } = productList

    const productNonImeiList = useSelector(state => state.productNonImeiList)
    const { productsNonImei, loading: nonImeiLoading } = productNonImeiList

    const [brandsData, setBrandsData] = useState([]);
    const [notValid, setNotValid] = useState(false)

    const [brandList, setBrandlist] = useState([]);
    const [selectCategories, setSelectCategories] = useState([])
    const [categorie, setCategorie] = useState([])

    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const leftChecked = intersection(checked, [...left]);
    const rightChecked = intersection(checked, right);


    useEffect(() => {
        if (!headerBrandOrderList || headerBrandOrderList.length === 0)
            dispatch(listHeaderBrandOrder());
        if (!products || products.length === 0)
            dispatch(listProducts());
        if (!productsNonImei || productsNonImei.length === 0)
            dispatch(listNonImeiProducts(userInfo ? userInfo.id_odoo : -1));


    }, [])

    useEffect(() => {
        if (successHeaderBrandOrderAdd || successHeaderBrandOrderDel) {
            dispatch(listHeaderBrandOrder())
            dispatch(listProducts());
            dispatch(listNonImeiProducts(userInfo ? userInfo.id_odoo : -1));
            setLeft([])
            setRight([])
        }
    }, [successHeaderBrandOrderAdd, successHeaderBrandOrderDel])

    useEffect(() => {
        if (products && products.length > 0 && productsNonImei && productsNonImei.length > 0) {
            dispatch(listCategories())
        }
    }, [products, productsNonImei])

    useEffect(() => {
        if (categories && categories.length > 0) {
            
            let values = []
            categories
                .sort((a, b) => a.id - b.id).forEach(category => {
                    values.push({ value: category.id, label: category.name })
                })
            setSelectCategories(values)
        }
    }, [categories])

    useEffect(() => {
        let productArray = []
        if (categorie === undefined || categorie.length === 0)
            setNotValid(true)
        else {
            setNotValid(false)
            if (categorie && ((products != undefined && products.length > 0) || (productsNonImei != undefined && productsNonImei.length > 0))) {
                removeDuplicatesByNestedProperty(
                    (categorie.is_imei ? products : productsNonImei)
                        .filter(product => product.categorie_odoo === categorie.name), "brand")
                    .forEach(product => {
                        productArray.push(product.brand)
                    })
                setBrandsData([...productArray])
            }
        }
    }, [categorie])

    useEffect(() => {       
        if (categorie) {
            if (headerBrandOrderList && headerBrandOrderList.length > 0) {
                let headerList = headerBrandOrderList.filter(header => header.categorie === categorie.id)
                let rightArr = []
                headerList.forEach(header => {
                    rightArr.push(header.brand)
                })
                setRight([...rightArr])
                setLeft([...not(brandsData.map(item => item), rightArr)])
            }
            else {
                setLeft([...brandsData])
                setRight([])
            }
        }

    }, [brandsData])

    const onHandleCategorySelectChange = (value) => {
        setCategorie(categories.filter(category => category.id === value)[0])
    }

    const handleAllRight = () => {
        setRight(right.concat([...left]));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(brandsData.filter((d) => rightChecked.includes(d))));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(brandsData);
        setRight([]);
    };

    const reset = () => {
        setLeft(brandsData);
        setRight([]);
    };

    const handleChecked = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };


    const customList = (items) => (
        <List dense component="div" role="list">
            {items && items.length > 0 && items.map((value) => {
                const labelId = `transfer-header-brand-order-item-${value}-label`;

                return (
                    <ListItem
                        className='header-brand-order-header-brand-order-item'
                        key={value}
                        role="listitem"
                        onClick={handleChecked(value)}
                    >
                        <ListItemIcon>
                            <Checkbox
                                checked={checked.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                    "aria-labelledby": labelId
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${value}`} />
                    </ListItem>
                );
            })}
            <ListItem />
        </List>
    );

    const handleMoveUp = (index) => {
        const newItems = [...right];
        const currentItem = newItems.splice(index, 1)[0];
        newItems.splice(index - 1, 0, currentItem);
        setRight([...newItems]);
    };

    const handleMoveDown = (index) => {
        const newItems = [...right];
        const currentItem = newItems.splice(index, 1)[0];
        newItems.splice(index + 1, 0, currentItem);
        setRight([...newItems]);
    };

    const customRightList = (items) => (
        <List dense component="div" role="list">
            {items && items.length > 0 && items.map((value, index) => {
                const labelId = `transfer-header-brand-order-item-${value}-label`;

                return (
                    <ListItem
                        className='header-brand-order-header-brand-order-item'
                        key={value}
                        role="listitem"

                    >
                        <ListItemIcon>
                            <Checkbox
                                checked={checked.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                    "aria-labelledby": labelId
                                }}
                                onClick={handleChecked(value)}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${value}`} />
                        <IconButton
                            aria-label="Move up"
                            disabled={index === 0}
                            onClick={() => handleMoveUp(index)}
                        >
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton
                            aria-label="Move down"
                            disabled={index === items.length - 1}
                            onClick={() => handleMoveDown(index)}
                        >
                            <ArrowDownwardIcon />
                        </IconButton>
                    </ListItem>
                );
            })}
            <ListItem />
        </List>
    );

    const savebrandList = () => {
        setNotValid(true)
        if (right.length > 0) {
            let item = {
                categorie_id: categorie.id,
                brandOrder: [...right]
            }
            dispatch(addHeaderBrandOrder(item))
            setNotValid(false)
        }
        if (left.length > 0) {
            let item = {
                categorie_id: categorie.id,
                brandOrder: [...left]
            }
            dispatch(delHeaderBrandOrder(item))
            setNotValid(false)
        }

    }

    return (
        <div className='header-brand-order-container'>
            {loading || nonImeiLoading || headerLoading || loadingHeaderBrandOrderAdd || loadingHeaderBrandOrderDel ? <Loader /> :
                <>
                    <Row className='header-brand-order-title-row'>
                        <h1>Ordre des marques dans le header</h1>
                    </Row>
                    <Row className='header-brand-order-container-row'>
                        <Row className='header-brand-order-select-row'>
                            <Col md={{ span: 4, offset: 0 }}>
                                <Select
                                    className='categories-select'
                                    name="categories-select"
                                    title="Catégories"
                                    value={categorie}
                                    options={selectCategories}
                                    onChangeFunc={(e) => onHandleCategorySelectChange(e)}
                                />
                            </Col>
                        </Row>

                        <Row className='header-brand-order-row'>
                            <Col className='header-brand-order-col' md={4}>
                                <Row className='header-brand-order-item' item>{customList(left)}</Row>
                            </Col>
                            <Col className='header-brand-order-button-col' md={1}>
                                <Row className='header-brand-order-button-row'>
                                    <button
                                        className='header-brand-order-button-add'
                                        onClick={handleAllRight}
                                        disabled={brandList == undefined}
                                        aria-label="move all right"
                                    ><i class="fa-solid fa-angles-right"></i></button>
                                </Row>
                                <Row className='header-brand-order-button-row'>
                                    <button
                                        className='header-brand-order-button-add'
                                        onClick={handleCheckedRight}
                                        disabled={brandList == undefined}
                                        aria-label="move selected right"
                                    ><i class="fa-solid fa-angle-right"></i></button>
                                </Row>
                                <Row className='header-brand-order-button-row'>
                                    <button
                                        className='header-brand-order-button-remove'
                                        onClick={handleCheckedLeft}
                                        disabled={brandList == undefined}
                                        aria-label="move selected left"
                                    ><i class="fa-solid fa-angle-left"></i></button>
                                </Row>
                                <Row className='header-brand-order-button-row'>
                                    <button
                                        className='header-brand-order-button-remove'
                                        onClick={handleAllLeft}
                                        disabled={brandList == undefined}
                                        aria-label="move all left"
                                    ><i class="fa-solid fa-angles-left"></i></button>
                                </Row>
                            </Col>
                            <Col className='header-brand-order-col' md={4}>
                                <Row item>{customRightList(right)}</Row>
                            </Col>
                        </Row>

                        <Row className='header-brand-order-button'>
                            <Col md={3}>
                                <button
                                    className='header-brand-order-btn-cancel'
                                    type='button'
                                    disabled={false}
                                    onClick={reset}
                                >
                                    Reset
                                </button>
                            </Col>
                            <Col md={3}>
                                <button
                                    className='header-brand-order-btn-validate'
                                    type='button'
                                    disabled={notValid}
                                    onClick={savebrandList}
                                >
                                    Valider
                                </button>
                            </Col>
                        </Row>


                    </Row>
                </>
            }
        </div>
    )
}

export default HeaderBrandOrder