import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ListGroup, Form } from 'react-bootstrap'

import { listConfigs, getConfig, addConfig } from '../../../../actions/configActions'

import './Config.css'

function Config() {
    const dispatch = useDispatch()

    const configList = useSelector((state) => state.configList)
    const { configs, loading } = configList

    const configGet = useSelector((state) => state.configGet)
    const { config } = configGet

    const configAdd = useSelector((state) => state.configAdd)
    const { addSuccess } = configAdd

    const [selectedConfig, setSelectedConfig] = useState(null)
    const [newValue, setNewValue] = useState(null)

    useEffect(() => {
        dispatch(listConfigs())
    }, [dispatch])

    const handleSelectConfig = (item) => {
        setSelectedConfig(item)
        dispatch(listConfigs())
    }

    return (
        <Row className='config-container'>
            <Row className='config-title'>
                <span>Configuration</span>
            </Row>
            <Row>
                <ListGroup className='config-listgroup' variant='flush'>
                    {configs && configs.length > 0 ?
                        configs.map((item) => (
                            <>
                                <Row className='config-listgroup-title'>
                                    <span>{item.section}</span>
                                </Row>
                                {item.values && item.values.length > 0 ?
                                    item.values.map((value) => (
                                        <Row className='config-row' onClick={(e) => handleSelectConfig(item)}>
                                            <ListGroup.Item className='config-items' key={item.id}>
                                                <Row>
                                                    <Col md={2}>
                                                        {value.key}
                                                    </Col>
                                                    <Col md={8}>
                                                        <Form.Control
                                                            type='text'
                                                            value={newValue === null ? value.value : newValue}
                                                            onChange={(e) => setNewValue(e.target.value)}
                                                            autoFocus
                                                        />
                                                    </Col>
                                                    <Col md={1}>
                                                        <button
                                                            className="btn-config-update"
                                                            onClick={() => dispatch(addConfig(value.key, newValue, item.section))}>
                                                            <i class="fa-solid fa-pencil"></i>
                                                        </button>
                                                    </Col>
                                                    <Col md={1}>
                                                        <button
                                                            className="btn-config-reset"
                                                            onClick={() => {
                                                                setNewValue(null)
                                                                dispatch(listConfigs())
                                                            }}
                                                        >
                                                            <i class="fa-solid fa-rotate-left"></i>
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        </Row>
                                    ))
                                    : null
                                }
                            </>
                        ))
                        : null
                    }
                </ListGroup>
            </Row>
        </Row>
    )
}

export default Config