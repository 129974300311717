import { 
    BRAND_LIST_REQUEST,
    BRAND_LIST_SUCCESS,
    BRAND_LIST_FAIL,
} from "../constants/brandConstants";

export const BrandListReducer = (state ={brand: [] }, action) => {
    switch (action.type) {
        case BRAND_LIST_REQUEST:
            return { loading: true, brand: [] }
        case BRAND_LIST_SUCCESS:
            return { loading: false, brand: action.payload }
        case BRAND_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}