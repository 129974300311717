import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, ListGroup, input, Form } from 'react-bootstrap'
import Switch from "react-switch";
import Modal from 'react-modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Loader from '../../../../components/Loader/Loader'
import Select from '../../../../components/Select/Select'
import useFileUpload from 'react-use-file-upload'
import uuid from 'react-uuid';

import ConfirmationModal from '../../../../components/Modal/ConfirmationModal'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFilter,
    faTrash,
    faPen,
} from '@fortawesome/free-solid-svg-icons';


import { uploadImage, updateImage, deleteImage } from '../../../../actions/imageActions';
import { listProducts } from '../../../../actions/productActions';
import { listNonImeiProducts } from '../../../../actions/productActions';
import {
    listHeaderCategories,
    createHeaderCategorie,
    updateHeaderCategorie,
    deleteHeaderCategorie,
}
    from '../../../../actions/headerCategoriesActions';

import {
    listCategories,
    addCategorie,
    updateCategories,
    deleteCategories
}
    from '../../../../actions/categoriesActions';

import { getAllNestedKeys } from '../../../../utils/productUtils'
import { removeDuplicates, removeDuplicatesByNestedProperty, findVal } from "../../../../utils/utils";


import './Categories.css'

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const Categories = () => {
    const dispatch = useDispatch();
    const inputRef = useRef();

    const [confirmationHeaderCategorySuppressionModalOpen, setConfirmationHeaderCategorySuppressionModalOpen] = useState(false);
    const [confirmationCategorySuppressionModalOpen, setConfirmationCategorySuppressionModalOpen] = useState(false);

    const [categoryName, setCategoryName] = useState("");
    const [headerCategoryName, setHeaderCategoryName] = useState("");

    const [selectHeaderCategorie, setSelectHeaderCategorie] = useState([]);
    const [categorie, setCategorie] = useState({
        name: "",
        image_url: "",
        is_imei: false,
        is_linkable: false,
        description: "",
    });

    const [headerCategorieToSave, setHeaderCategorieToSave] = useState({
        name: "",
        image_url: "",
        categories: [],
    });

    const {
        files,
        fileNames,
        fileTypes,
        totalSize,
        totalSizeInBytes,
        handleDragDropEvent,
        clearAllFiles,
        createFormData,
        setFiles,
        removeFile
    } = useFileUpload();

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedHeaderCategoryTab1, setSelectedHeaderCategoryTab1] = useState(null);
    const [selectedHeaderCategoryTab2, setSelectedHeaderCategoryTab2] = useState(null);

    const [categorieToSave, setCategorieToSave] = useState({
        name: "",
        image_url: "",
        is_imei: false,
        is_linkable: false,
        description: "",
    });

    const [selectedCategoriesToAssociate, setSelectedCategoriesToAssociate] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);
    const [categoryList, setCategoryList] = useState([]);

    const [checked, setChecked] = useState([]);
    const [notValid, setNotValid] = useState(false)
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const leftChecked = intersection(checked, [...left]);
    const rightChecked = intersection(checked, right);

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const productList = useSelector(state => state.productList)
    const { error, loading, products, page, pages } = productList

    const productNonImeiList = useSelector(state => state.productNonImeiList)
    const { productsNonImei } = productNonImeiList

    const categoriesList = useSelector(state => state.categoriesList)
    const { loading: loadingCategories, error: errorCategories, categories } = categoriesList

    const categoriesDelete = useSelector(state => state.categoriesDelete)
    const { success: successDelete } = categoriesDelete

    const categoriesCreate = useSelector(state => state.categoriesCreate)
    const { success: successCreate } = categoriesCreate

    const categoriesUpdate = useSelector(state => state.categoriesUpdate)
    const { success: successUpdate } = categoriesUpdate


    const headerCategoriesList = useSelector((state) => state.headerCategoriesList);
    const { loading: loadingHeaderCategories, error: errorHeaderCategories, headerCategories } = headerCategoriesList;

    const headerCategorieCreate = useSelector((state) => state.headerCategorieCreate);
    const { success: successCreateHeaderCategorie, headerCategorie } = headerCategorieCreate;

    const headerCategorieUpdate = useSelector((state) => state.headerCategorieUpdate);
    const { success: successUpdateHeaderCategorie } = headerCategorieUpdate;

    const headerCategorieDelete = useSelector((state) => state.headerCategorieDelete);
    const { success: successDeleteHeaderCategorie } = headerCategorieDelete;

    const [modalFilterIsOpen, setModalFilterIsOpen] = useState(false);
    const [filters, setFilters] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState(null);

    const [headerCategoryToDelete, setHeaderCategoryToDelete] = useState(null);
    const [categoryToDelete, setCategoryToDelete] = useState(null);


    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
        },
    };

    let subtitle;


    useEffect(() => {
        if (!products || products.length === 0)
            dispatch(listProducts());
        if (!productsNonImei || productsNonImei.length === 0)
            dispatch(listNonImeiProducts(userInfo ? userInfo.id_odoo : -1));
        dispatch(listHeaderCategories());
        dispatch(listCategories());
    }, []);

    useEffect(() => {
        if (headerCategories && headerCategories.length > 0) {
            let values = []
            headerCategories
                .sort((a, b) => a.id - b.id).forEach(hc => {
                    values.push({ value: hc.id, label: hc.name })
                })
            setSelectHeaderCategorie(values)
        }
    }, [headerCategories])

    useEffect(() => {
        if (selectedHeaderCategoryTab1 && categories && categories.length > 0) {
            setLeft(categories.filter((category) => !selectedHeaderCategoryTab1.categories.includes(category.id)));
            setRight(categories.filter((category) => selectedHeaderCategoryTab1.categories.includes(category.id)));
        }
        else {
            setLeft([])
            setRight([])
        }
    }, [selectedHeaderCategoryTab1, categories])

    useEffect(() => {
        if (successCreateHeaderCategorie || successUpdateHeaderCategorie || successDeleteHeaderCategorie) {
            dispatch(listHeaderCategories());
            resetHeaderCategorieToSave();
            setLeft([])
            setRight([])
            dispatch(listProducts());
            dispatch(listNonImeiProducts(userInfo ? userInfo.id_odoo : -1));
        }
    }, [successCreateHeaderCategorie, successUpdateHeaderCategorie, successDeleteHeaderCategorie])

    useEffect(() => {
        if (successCreate || successUpdate || successDelete) {
            dispatch(listCategories());
            resetCategorieToSave();
        }
    }, [successCreate, successUpdate, successDelete])

    useEffect(() => {
        if (filters && filters.length > 0) {
            setModalFilterIsOpen(true);
        }
    }, [filters])

    useEffect(() => {
    }, [selectedFilters])

    useEffect(() => {
        if (selectedHeaderCategoryTab2 != undefined && selectedHeaderCategoryTab2 != null)
            setHeaderCategorieToSave({
                name: "",
                image_url: "",
                categories: [],
            })
    }, [selectedHeaderCategoryTab2])


    const handleCategorySubmit = (e) => {
        e.preventDefault();
        let name = selectedCategory ? selectedCategory.name : categorieToSave.name;
        let is_imei = selectedCategory ? selectedCategory.is_imei : categorieToSave.is_imei;
        let is_linkable = selectedCategory ? selectedCategory.is_linkable : categorieToSave.is_linkable;
        let description = selectedCategory ? selectedCategory.description : categorieToSave.description;
        let filters = selectedCategory ? selectedCategory.filters : categorieToSave.filters;

        if (selectedCategory) {
            dispatch(updateCategories(selectedCategory.id, name, is_imei, is_linkable, description, filters));
        } else {
            dispatch(addCategorie(name, is_imei, is_linkable, description, filters));
        }

        setSelectedCategory(null);
    };

    const handleCategoryDelete = (categoryId) => {
        dispatch(deleteCategories(categoryId));
    };

    const handleHeaderCategorySubmit = (e) => {
        e.preventDefault();
        let headerCat = selectedHeaderCategoryTab2 ? selectedHeaderCategoryTab2 : headerCategorieToSave;
        let name = headerCat.name;
        let image_url = handleFileUpload(headerCat);
        let categories = headerCategorieToSave.categories ? headerCategorieToSave.categories : [];


        if (selectedHeaderCategoryTab2) {
            dispatch(updateHeaderCategorie(headerCat.id, name, image_url, categories, headerCat.is_odoo,
                headerCat.is_imei, headerCat.is_product_category));
        } else {
            dispatch(createHeaderCategorie(name, image_url, categories, headerCat.is_odoo,
                headerCat.is_imei, headerCat.is_product_category));
        }
        setSelectedHeaderCategoryTab2(null);
    };

    const handleHeaderCategoryDelete = (headerCategoryId) => {
        dispatch(deleteHeaderCategorie(headerCategoryId));
    };

    const availableCategories = categories != undefined ? categories.filter(
        (category) =>
            !headerCategories.some((headerCategory) =>
                headerCategory.categories.includes(category.id)
            )
    ) : [];

    const handleAssociation = (headerCategoryId, categoryIds) => {
        if (!headerCategoryId) return;
        let name = headerCategories.filter(hc => hc.id === headerCategoryId)[0].name
        let url = headerCategories.filter(hc => hc.id === headerCategoryId)[0].url
        let categories = categoryIds
        dispatch(updateHeaderCategorie(headerCategoryId, name, url, categories));
        setSelectedCategoriesToAssociate([]);
    };

    const onHandleHeaderCategorySelectChange = (value) => {
        setSelectedHeaderCategoryTab1(headerCategories.filter(hc => hc.id === value)[0])
    }

    const handleAllRight = () => {
        setRight(right.concat([...left]));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(categories.filter((d) => rightChecked.includes(d))));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(categories);
        setRight([]);
    };

    const reset = () => {
        setLeft(categoriesData);
        setRight([]);
    };

    const handleChecked = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const customList = (items) => (
        <List dense component="div" role="categorie-list">
            {items && items.length > 0 && items.map((value) => {
                const labelId = `transfer-categorie-list-item-${value}-label`;

                return (
                    <ListItem
                        className='categorie-categorie-list-item'
                        key={value}
                        role="listitem"
                        onClick={handleChecked(value)}
                    >
                        <ListItemIcon>
                            <Checkbox
                                checked={checked.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                    "aria-labelledby": labelId
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${value.name}`} />
                    </ListItem>
                );
            })}
            <ListItem />
        </List>
    );

    const handleMoveUp = (index) => {
        const newItems = [...right];
        const currentItem = newItems.splice(index, 1)[0];
        newItems.splice(index - 1, 0, currentItem);
        setRight([...newItems]);
    };

    const handleMoveDown = (index) => {
        const newItems = [...right];
        const currentItem = newItems.splice(index, 1)[0];
        newItems.splice(index + 1, 0, currentItem);
        setRight([...newItems]);
    };

    const customRightList = (items) => (
        <List dense component="div" role="categorie-list">
            {items && items.length > 0 && items.map((value, index) => {
                const labelId = `transfer-categorie-list-item-${value}-label`;

                return (
                    <ListItem
                        className='categorie-categorie-list-item'
                        key={value}
                        role="listitem"

                    >
                        <ListItemIcon>
                            <Checkbox
                                checked={checked.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                    "aria-labelledby": labelId
                                }}
                                onClick={handleChecked(value)}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${value.name}`} />
                    </ListItem>
                );
            })}
            <ListItem />
        </List>
    );

    const saveCategoryList = () => {
        let categoryIds = right.map(c => c.id)
        handleAssociation(selectedHeaderCategoryTab1.id, categoryIds)
    }

    function removeHeaderCategory(id) {
        setConfirmationHeaderCategorySuppressionModalOpen(true)
        setHeaderCategoryToDelete(id)
    }

    function removeCategory(id) {
        setConfirmationCategorySuppressionModalOpen(true)
        setCategoryToDelete(id)
    }

    const handleCategorieToSave = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        if (selectedCategory)
            setSelectedCategory({ ...selectedCategory, [name]: newValue });
        else
            setCategorieToSave({ ...categorieToSave, [name]: newValue });
    }

    const handleHeaderCategorieToSave = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        if (selectedHeaderCategoryTab2)
            setSelectedHeaderCategoryTab2({ ...selectedHeaderCategoryTab2, [name]: newValue });
        else
            setHeaderCategorieToSave({ ...headerCategorieToSave, [name]: newValue });
    }

    const handleFileUpload = (headerCat) => {
        let category_url = null

        if (files.length > 0) {
            let folder = 'header-category'
            let image = files[0]
            let formData = new FormData();
            let extension = image.name.split('.')[image.name.split('.').length - 1]
            let image_name = uuid() + '.' + extension

            formData.append('image', image, image_name);
            formData.append('folder', folder);

            let subFolders = [headerCat.name]
            subFolders.forEach((element, index) => {
                formData.append(`subFolders[${index}]`, element);
            });

            category_url = folder + '/' + headerCat.name + '/' + image_name
            dispatch(uploadImage(formData))
        }
        clearAllFiles()
        return category_url;
    };

    function openFilterModal(category) {
        let keys = []
        let p = {}
        if (category.is_imei) {
            p = products.filter(product => product.categorie_odoo == category.name)[0]
        }

        else
            p = productsNonImei.filter(product => product.categorie_odoo == category.name)[0]

        let values = getAllNestedKeys(p, '')
        keys = values.map(key => key.split('.')[key.split('.').length - 1])
            .filter((value, index, self) => {
                // Vérifie si la valeur est un entier
                const isInteger = !isNaN(parseInt(value)) && value.trim() === parseInt(value).toString();

                // Enlève les doublons et les valeurs pouvant être parsées en int
                return !isInteger && self.indexOf(value) === index;
            })
            .filter((value) => !value.includes('id') && !value.includes('created_at') && !value.includes('updated_at')
                && !value.includes('isActive') && !value.includes('isSaleable') && !value.includes('imei') && !value.includes('value')
                && !value.includes('categorie_odoo') && !value.includes('price')
            )

        let filters = keys.map(key => {
            return {
                name: key,
                checked: category.filters && category.filters.includes(key)
            }
        })



        let newSelectedFilters = { ...selectedFilters };
        filters.filter(filter => filter.checked).forEach(filter => {
            newSelectedFilters[filter.name] = filter.checked;
        })

        setSelectedFilters(newSelectedFilters);
        setFilters(filters)
    }

    function afterOpenFilterModal() {
        subtitle.style.color = '#f00';
    }

    function closeFilterModal() {
        setFilters([])
        setSelectedFilters(null);
        setModalFilterIsOpen(false);
    }

    const handleFilterChange = (e) => {
        setSelectedFilters({
            ...selectedFilters,
            [e.target.name]: e.target.checked,
        });
    };

    const handleSubmit = () => {
        const selectedFilterArray = Object.keys(selectedFilters).filter(
            (key) => selectedFilters[key]
        );



        // Traitez le tableau de filtres sélectionnés ici
        // { 'id': id, 'name': name, 'image_url': image_url, is_odoo: isOdoo, is_imei:isImei, is_product_category: isProductCategory,
        // 'description': description, 'filters': filters },

        dispatch(updateCategories(selectedCategory.id, selectedCategory.name,
            selectedCategory.is_imei, selectedCategory.is_linkable, selectedCategory.description, selectedFilterArray))

        setModalFilterIsOpen(false);
    };

    const handleCancel = () => {
        setSelectedFilters({});
        setModalFilterIsOpen(false);
    };

    const TabAssociation = (
        <>
            <Row className='categorie-list-container-row'>
                <Row className='categorie-list-select-row'>
                    <Col md={{ span: 4, offset: 0 }}>
                        <Select
                            className='categories-select'
                            name="categories-select"
                            title="Catégories Header"
                            value={selectedHeaderCategoryTab1}
                            options={selectHeaderCategorie}
                            onChangeFunc={(e) => onHandleHeaderCategorySelectChange(e)}
                        />
                    </Col>
                </Row>

                <Row className='header-category-categorie-list-row'>
                    <Col className='categorie-categorie-list-col' md={4}>
                        <Row>{customList(left)}</Row>
                    </Col>
                    <Col className='categorie-list-button-col' md={1}>
                        <Row className='categorie-list-button-row'>
                            <button
                                className='categorie-list-button-add'
                                onClick={handleAllRight}
                                disabled={categoryList == undefined}
                                aria-label="move all right"
                            ><i class="fa-solid fa-angles-right"></i></button>
                        </Row>
                        <Row className='categorie-list-button-row'>
                            <button
                                className='categorie-list-button-add'
                                onClick={handleCheckedRight}
                                disabled={categoryList == undefined}
                                aria-label="move selected right"
                            ><i class="fa-solid fa-angle-right"></i></button>
                        </Row>
                        <Row className='categorie-list-button-row'>
                            <button
                                className='categorie-list-button-remove'
                                onClick={handleCheckedLeft}
                                disabled={categoryList == undefined}
                                aria-label="move selected left"
                            ><i class="fa-solid fa-angle-left"></i></button>
                        </Row>
                        <Row className='categorie-list-button-row'>
                            <button
                                className='categorie-list-button-remove'
                                onClick={handleAllLeft}
                                disabled={categoryList == undefined}
                                aria-label="move all left"
                            ><i class="fa-solid fa-angles-left"></i></button>
                        </Row>
                    </Col>
                    <Col className='categorie-categorie-list-col' md={4}>
                        <Row>{customRightList(right)}</Row>
                    </Col>
                </Row>

                <Row className='categorie-list-button'>
                    <Col md={2}>
                        <button
                            className='categorie-list-btn-cancel'
                            type='button'
                            disabled={false}
                            onClick={reset}
                        >
                            Reset
                        </button>
                    </Col>
                    <Col md={2}>
                        <button
                            className='categorie-list-btn-validate'
                            type='button'
                            disabled={notValid}
                            onClick={saveCategoryList}
                        >
                            Valider
                        </button>
                    </Col>
                </Row>
            </Row>
        </>
    )

    function resetHeaderCategorieToSave() {
        setHeaderCategorieToSave({
            name: "",
            image_url: "",
            categories: [],
        })
    }

    useEffect(() => {
        
        
    }, [headerCategorieToSave, selectedHeaderCategoryTab2])

    const TabHeaderCategory = (
        <>
            <Row className="header-category-main">
                <Col md={4}>
                    <ListGroup className="header-category-categorie-list">
                        {headerCategories != undefined &&
                            headerCategories.map((headerCategory) => (
                                <ListGroup.Item
                                    className="header-category-categorie-list-item"
                                    key={headerCategory.id}
                                    onClick={() => setSelectedHeaderCategoryTab2(headerCategory)}
                                >
                                    <Col md={10}>
                                        {headerCategory.name}
                                    </Col>
                                    <Col md={1}>
                                        <span onClick={() => removeHeaderCategory(headerCategory.id)}>
                                            <FontAwesomeIcon className="file-remove" icon={faTrash} />
                                        </span>
                                    </Col>
                                </ListGroup.Item>
                            ))}
                    </ListGroup>
                </Col>
                <Col md={8}>
                    <form onSubmit={(e) => handleHeaderCategorySubmit(e, { name: categoryName })}>
                        <Row className="category-form-row">
                            <input
                                className="header-category-input"
                                type="text"
                                name="name"
                                placeholder="Nom de la catégorie Header"
                                value={selectedHeaderCategoryTab2 ? selectedHeaderCategoryTab2.name :
                                    headerCategorieToSave ? headerCategorieToSave.name : ''}
                                onChange={(e) => handleHeaderCategorieToSave(e)}
                            />
                        </Row>
                        <Row className="header-category-form-row">
                            <Col md={4}>
                                <div>
                                    <input
                                        id='is_odoo'
                                        type="checkbox"
                                        name='is_odoo'
                                        onChange={(e) => handleHeaderCategorieToSave(e)}
                                        checked={selectedHeaderCategoryTab2 ? selectedHeaderCategoryTab2.is_odoo :
                                            headerCategorieToSave ? headerCategorieToSave.is_odoo : false}
                                    />
                                    <label className='imagestuiles-label-checkbox' htmlFor='is_odoo'>Catégorie Odoo</label>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div>
                                    <input
                                        id='is_product_category'
                                        type="checkbox"
                                        name='is_product_category'
                                        onChange={(e) => handleHeaderCategorieToSave(e)}
                                        checked={selectedHeaderCategoryTab2 ? selectedHeaderCategoryTab2.is_product_category :
                                            headerCategorieToSave ? headerCategorieToSave.is_product_category : false}
                                    />
                                    <label className='imagestuiles-label-checkbox' htmlFor='is_product_category'>Catégorie produit</label>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div>
                                    <input
                                        id='is_imei'
                                        type="checkbox"
                                        name='is_imei'
                                        onChange={(e) => handleHeaderCategorieToSave(e)}
                                        checked={selectedHeaderCategoryTab2 ? selectedHeaderCategoryTab2.is_imei :
                                            headerCategorieToSave ? headerCategorieToSave.is_imei : false}
                                    />
                                    <label className='imagestuiles-label-checkbox' htmlFor='is_imei'>Liée à un produit imei</label>
                                </div>
                            </Col>
                        </Row>
                        {
                            (headerCategorieToSave && headerCategorieToSave.name && headerCategorieToSave.name != '') ||
                                selectedHeaderCategoryTab2 ?
                                <Row className='imagestuiles-file-container'>
                                    <div
                                        className='imagestuiles-categorie-list-files-container'
                                    >
                                        <Row className='imagestuiles-categorie-list-files-row'>
                                            <ul>
                                                <p className='imagestuiles-categorie-list-files-title'>Images à envoyer</p>
                                                {fileNames.map((name) => (
                                                    <li key={name}>
                                                        <span>{name}</span>

                                                        <span onClick={() => removeFile(name)}>
                                                            <i className="fa-regular fa-trash-can file-remove"></i>
                                                        </span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </Row>
                                    </div>

                                    {/* Provide a drop zone and an alternative button inside it to upload files. */}
                                    <div
                                        className='imagestuiles-drop-zone'
                                        onDragEnter={handleDragDropEvent}
                                        onDragOver={handleDragDropEvent}
                                        onDrop={(e) => {
                                            handleDragDropEvent(e);
                                            setFiles(e, 'a');
                                        }}
                                    >
                                        <p>Déposez vos fichiers ici</p>

                                        <input
                                            ref={inputRef}
                                            type="file"
                                            multiple
                                            style={{ display: 'none' }}
                                            onChange={(e) => {
                                                setFiles(e, 'a');
                                                inputRef.current.value = null;
                                            }}
                                        />
                                    </div>
                                </Row>
                                : null
                        }
                        <Row className="category-form-row">
                            <Col md={8}>
                                {
                                    selectedHeaderCategoryTab2 ?
                                        <Row>
                                            <Col md={6}>
                                                <button
                                                    className="header-category-new-btn"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setSelectedHeaderCategoryTab2(null);
                                                        setHeaderCategorieToSave(null);
                                                    }}
                                                >Nouveau</button>
                                            </Col>
                                            <Col md={6}>
                                                <button className="header-category-submit-btn" type="submit">Modifier</button>
                                            </Col>
                                        </Row>
                                        :
                                        <Row>
                                            <Col md={6}>
                                                <button className="header-category-submit-btn" type="submit">Ajouter</button>
                                            </Col>
                                        </Row>
                                }
                            </Col>
                            <Col md={4}>
                                {
                                    selectedHeaderCategoryTab2 && selectedHeaderCategoryTab2.image_url != null ?
                                        <img className="header-category-img" src={selectedHeaderCategoryTab2.image_url} />
                                        : null
                                }
                            </Col>
                        </Row>

                    </form>
                </Col>
            </Row>
        </>
    );

    function resetCategorieToSave() {
        setCategorieToSave({
            name: "",
            image_url: "",
            is_imei: false,
            is_linkable: false,
            description: "",
        })
    }

    const TabCategory = (
        <>
            <Row className="header-category-main">
                <Col md={6}>
                    <ListGroup className="category-categorie-list">
                        {categories != undefined &&
                            categories.map((category) => (
                                <ListGroup.Item
                                    className="category-categorie-list-item"
                                    key={category.id}
                                    onClick={() => setSelectedCategory(category)}
                                ><Row>
                                        <Col md={{ span: 8, offset: 2 }}>
                                            {category.name}
                                        </Col>
                                        <Col md={1}>
                                            <span onClick={() => openFilterModal(category)}>
                                                <FontAwesomeIcon icon={faFilter} style={{ color: "#57e389", }} />
                                            </span>
                                        </Col>
                                        <Col md={1}>
                                            <span onClick={() => removeCategory(category.id)}>
                                                <i className="fa-regular fa-trash-can file-remove"></i>
                                            </span>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            ))}
                    </ListGroup>
                </Col>
                <Col md={6}>
                    <form onSubmit={(e) => handleCategorySubmit(e, { name: categoryName })}>
                        <Row className="category-form-row">
                            <input
                                className="category-input"
                                type="text"
                                placeholder="Nom de la catégorie"
                                name='name'
                                value={selectedCategory ? selectedCategory.name : categorieToSave.name}
                                onChange={(e) => handleCategorieToSave(e)}
                            />
                        </Row>
                        <Row className="header-category-form-row">
                            <Col md={4}>
                                <div>
                                    <input
                                        id='is_linkable'
                                        type="checkbox"
                                        name='is_linkable'
                                        onChange={(e) => handleCategorieToSave(e)}
                                        checked={selectedCategory ? selectedCategory.is_linkable : categorieToSave.is_linkable}
                                    />
                                    <label className='imagestuiles-label-checkbox' htmlFor='is_linkable'>produits liés à l'achat</label>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div>
                                    <input
                                        id='is_imei'
                                        type="checkbox"
                                        name='is_imei'
                                        onChange={(e) => handleCategorieToSave(e)}
                                        checked={selectedCategory ? selectedCategory.is_imei : categorieToSave.is_imei}
                                    />
                                    <label className='imagestuiles-label-checkbox' htmlFor='is_imei'>Liée à un produit imei</label>
                                </div>
                            </Col>
                        </Row>
                        <Row className="category-form-row">
                            <input
                                className="category-area-input"
                                type="textarea"
                                placeholder="Description"
                                name='description'
                                value={selectedCategory ? selectedCategory.description : categorieToSave.description}
                                onChange={(e) => handleCategorieToSave(e)}
                            />
                        </Row>
                        <Row className="category-form-row">
                            {
                                selectedCategory ?
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                className="header-category-new-btn"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setSelectedCategory(null);
                                                    resetCategorieToSave();
                                                }}
                                            >Nouveau</button>
                                        </Col>
                                        <Col md={6}>
                                            <button className="header-category-submit-btn" type="submit">Modifier</button>
                                        </Col>
                                    </Row>
                                    :
                                    <Row>
                                        <Col md={6}>
                                            <button className="header-category-submit-btn" type="submit">Ajouter</button>
                                        </Col>
                                    </Row>
                            }
                        </Row>
                    </form>
                </Col>
            </Row>
            <Modal
                isOpen={modalFilterIsOpen}
                onAfterOpen={afterOpenFilterModal}
                onRequestClose={closeFilterModal}
                style={customStyles}
            >
                <div className="container">
                    <h2 className="mb-3">Filtres</h2>
                    {filters.length > 0 && selectedFilters ? (
                        <Form>
                            {filters.map((filter, index) => (
                                <Row key={index} className="mb-2">
                                    <Col md={6}>
                                        <Form.Label>{filter.name}</Form.Label>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Check
                                            type="checkbox"
                                            name={filter.name}
                                            onChange={handleFilterChange}
                                            checked={selectedFilters ? selectedFilters[filter.name] : false}
                                        />
                                    </Col>
                                </Row>
                            ))}
                        </Form>
                    ) : (
                        <label>Aucun filtre</label>
                    )}
                    <div className="d-flex justify-content-end mt-3">
                        <Button variant="secondary" className="mr-2" onClick={handleCancel}>
                            Annuler
                        </Button>
                        <Button variant="primary" onClick={handleSubmit}>
                            Valider
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );

    const handleDeleteHeaderCategory = () => {
        if (headerCategoryToDelete) {
            dispatch(deleteHeaderCategorie(headerCategoryToDelete))
            setHeaderCategoryToDelete(null)
            setConfirmationHeaderCategorySuppressionModalOpen(false)
        }
    };

    const handleDeleteCategory = () => {
        if (categoryToDelete) {
            dispatch(deleteCategories(categoryToDelete))
            setCategoryToDelete(null)
            setConfirmationCategorySuppressionModalOpen(false)
        }
    };

    return (
        <>
            {loadingCategories || loadingHeaderCategories ? <Loader /> :
                <>
                    <Tabs
                        defaultActiveKey="association"
                        id="uncontrolled-tab-example"
                        className="header-category-tab"
                    >
                        <Tab eventKey="association" title="Association" selectedTabClassName="is-selected">
                            {TabAssociation}
                        </Tab>
                        <Tab eventKey="header-category" title="CategoriesHeader" >
                            {TabHeaderCategory}
                        </Tab>
                        <Tab eventKey="category" title="Catégories">
                            {TabCategory}
                        </Tab>
                    </Tabs>
                </>
            }
            <ConfirmationModal
                isOpen={confirmationHeaderCategorySuppressionModalOpen}
                operation={'suppression'}
                onRequestClose={() => setConfirmationHeaderCategorySuppressionModalOpen(false)}
                text={'Souhaitez vous vraiment supprimer cet ce header ?'}
                onConfirmDelete={handleDeleteHeaderCategory}
            />

            <ConfirmationModal
                isOpen={confirmationCategorySuppressionModalOpen}
                operation={'suppression'}
                onRequestClose={() => setConfirmationCategorySuppressionModalOpen(false)}
                text={'Souhaitez vous vraiment supprimer cette catégorie ?'}
                onConfirmDelete={handleDeleteCategory}
            />
        </>
    )
};

export default Categories;