import {
    IMEI_PRODUCT_REQUEST,
    IMEI_PRODUCT_SUCCESS,
    IMEI_PRODUCT_FAIL,

    IMEI_PRODUCTS_REQUEST,
    IMEI_PRODUCTS_SUCCESS,
    IMEI_PRODUCTS_FAIL,
}
    from "../constants/imeiConstants";

import axios from 'axios'

export const getProductByImei = (imei) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        dispatch({ type: IMEI_PRODUCT_REQUEST })

        const { data } = await instance.get(
            `api/imei/?imei=${imei}`
            , 
        )

        dispatch({ type: IMEI_PRODUCT_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: IMEI_PRODUCT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        })
    }
}

export const getProductsByImei = (imei) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        dispatch({ type: IMEI_PRODUCTS_REQUEST })

        const { data } = await instance.get(
            `api/imei/products/?imei=${imei}`
            , 
        )

        dispatch({ type: IMEI_PRODUCTS_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: IMEI_PRODUCTS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        })
    }
}