export const SIM_GET_REQUEST = 'SIM_GET_REQUEST'
export const SIM_GET_SUCCESS = 'SIM_GET_SUCCESS'
export const SIM_GET_FAILURE = 'SIM_GET_FAILURE'

export const SIM_ADD_REQUEST = 'SIM_ADD_REQUEST'
export const SIM_ADD_SUCCESS = 'SIM_ADD_SUCCESS'
export const SIM_ADD_FAILURE = 'SIM_ADD_FAILURE'

export const SIM_ADD_BY_FILE_REQUEST = 'SIM_ADD_BY_FILE_REQUEST'
export const SIM_ADD_BY_FILE_SUCCESS = 'SIM_ADD_BY_FILE_SUCCESS'
export const SIM_ADD_BY_FILE_FAILURE = 'SIM_ADD_BY_FILE_FAILURE'

export const PUK_ADD_REQUEST = 'PUK_ADD_REQUEST'
export const PUK_ADD_SUCCESS = 'PUK_ADD_SUCCESS'
export const PUK_ADD_FAILURE = 'PUK_ADD_FAILURE'

export const SIM_UPDATE_REQUEST = 'SIM_UPDATE_REQUEST'
export const SIM_UPDATE_SUCCESS = 'SIM_UPDATE_SUCCESS'
export const SIM_UPDATE_FAILURE = 'SIM_UPDATE_FAILURE'

export const SIM_DELETE_REQUEST = 'SIM_DELETE_REQUEST'
export const SIM_DELETE_SUCCESS = 'SIM_DELETE_SUCCESS'
export const SIM_DELETE_FAILURE = 'SIM_DELETE_FAILURE'

export const RESET_SIM_ADD_SUCCESS = 'RESET_SIM_ADD_SUCCESS'
export const RESET_SIM_ADD_BY_FILE_SUCCESS = 'RESET_SIM_ADD_BY_FILE_SUCCESS'
export const RESET_SIM_UPDATE_SUCCESS = 'RESET_SIM_UPDATE_SUCCESS'
export const RESET_SIM_DELETE_SUCCESS = 'RESET_SIM_DELETE_SUCCESS'
export const RESET_PUK_ADD_BY_FILE_SUCCESS = 'RESET_PUK_ADD_BY_FILE_SUCCESS'