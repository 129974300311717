import{
    HISTORY_GET_REQUEST,
    HISTORY_GET_SUCCESS,
    HISTORY_GET_FAIL,

    HISTORY_ALL_REQUEST,
    HISTORY_ALL_SUCCESS,
    HISTORY_ALL_FAIL,

    DELETED_IMEIS_REQUEST,
    DELETED_IMEIS_SUCCESS,
    DELETED_IMEIS_FAIL
}
from '../constants/history_constants';

export const historyReducer = (state = { history: [] }, action) => {
    switch (action.type) {
        case HISTORY_GET_REQUEST:
            return { loading: true, history: [] }
        case HISTORY_GET_SUCCESS:
            return { loading: false, history: action.payload }
        case HISTORY_GET_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const historyAllReducer = (state = { history: [] }, action) => {
    switch (action.type) {
        case HISTORY_ALL_REQUEST:
            return { loading: true, history: [] }
        case HISTORY_ALL_SUCCESS:
            return { loading: false, historyAll: action.payload }
        case HISTORY_ALL_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const deletedImeisReducer = (state = { history: [] }, action) => {
    switch (action.type) {
        case DELETED_IMEIS_REQUEST:
            return { loading: true, history: [] }
        case DELETED_IMEIS_SUCCESS:
            return { loading: false, deletedImeisList: action.payload }
        case DELETED_IMEIS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}