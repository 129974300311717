import React, { useState } from 'react';
import { PieChart as PieCharComponent, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';

const COLORS = [
    '#FF8E8E', // Light Red
    '#36A2EB', // Light Blue
    '#FFCE56', // Yellow
    '#FFA1B5', // Light Pink
    '#93FF93', // Light Green
    '#8470FF', // Light Slate Blue
];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
        return (
            <div style={{ backgroundColor: 'white', border: 'thin solid #ddd', margin: 0, padding: 10 }}>
                <p>{label}</p>
                <p><span>Value: </span>{payload[0].value}</p>
                <p><span>Percentage: </span>{`${(payload[0].percent * 100).toFixed(2)}%`}</p>
            </div>
        );
    }

    return null;
};

function PieChart({ pieData, labelContent, title }) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [tooltipActive, setTooltipActive] = useState(false); // New state

    const onPieEnter = (_, index) => {
        setActiveIndex(index);
        setTooltipActive(true); // Set tooltip as active when mouse enters
    };

    const onPieLeave = () => {
        setTooltipActive(false); // Set tooltip as inactive when mouse leaves
    };



    const data = Object.keys(pieData).map((key, index) => ({
        name: key,
        value: pieData[key],
        fill: COLORS[index % COLORS.length],
    }));

    return (
        <div className='chart-container'>
            <Row>
                {title}
            </Row>
            <Row>
                <Container width="100%" height={400}>
                    <PieCharComponent
                        width={400}
                        height={400}
                        margin={{
                            top: 0,
                            right: 30,
                            left: 20,
                            bottom: 70, // réduire la marge du bas
                        }}
                    >
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={100}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {
                                data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                            }
                        </Pie>
                        <Legend />
                    </PieCharComponent>
                </Container>
            </Row>
        </div>
    );
}

export default PieChart;
