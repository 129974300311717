import React from 'react'
import Table from 'react-bootstrap/Table'
import './Features.css'

function FeaturesNonImei({ product, header, table }) {
    return (
        <div>
            <Table className={table} striped="columns">
                <thead className={header}>
                    <tr>
                        <th>
                            <div>Caractéristiques détaillées</div>
                            <div className='product-brand-name'>{product.brand} {product.name}</div>
                        </th>
                    </tr>
                </thead>
                <tbody>

                    {product.specifications.map((spec, i) => (
                            <tr>
                                <td className='features-non-imei-name-col'>{spec.spec_name} : </td>
                                <td className='features-non-imei-value-col'>{spec.spec_value}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </div>
    )
}

export default FeaturesNonImei