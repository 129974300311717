export const AFTERSALE_LIST_REQUEST = 'AFTERSALE_LIST_REQUEST'
export const AFTERSALE_LIST_SUCCESS = 'AFTERSALE_LIST_SUCCESS'
export const AFTERSALE_LIST_FAIL = 'AFTERSALE_LIST_FAIL'

export const AFTERSALE_ADD_DEMAND = 'AFTERSALE_ADD_DEMAND'
export const AFTERSALE_REMOVE_DEMAND = 'AFTERSALE_REMOVE_DEMAND'
export const AFTERSALE_RESET = 'AFTERSALE_RESET'



// -------------------------------------
// constantes de configuration
// -------------------------------------

export const AFTERSALE_CONFIG_LIST_REQUEST = 'AFTERSALE_CONFIG_LIST_REQUEST'
export const AFTERSALE_CONFIG_LIST_SUCCESS = 'AFTERSALE_CONFIG_LIST_SUCCESS'
export const AFTERSALE_CONFIG_LIST_FAIL = 'AFTERSALE_CONFIG_LIST_FAIL'

export const AFTERSALE_CONFIG_ADD_REQUEST = 'AFTERSALE_CONFIG_ADD_REQUEST'
export const AFTERSALE_CONFIG_ADD_SUCCESS = 'AFTERSALE_CONFIG_ADD_SUCCESS'
export const AFTERSALE_CONFIG_ADD_FAIL = 'AFTERSALE_CONFIG_ADD_FAIL'

export const AFTERSALE_CONFIG_UPDATE_REQUEST = 'AFTERSALE_CONFIG_UPDATE_REQUEST'
export const AFTERSALE_CONFIG_UPDATE_SUCCESS = 'AFTERSALE_CONFIG_UPDATE_SUCCESS'
export const AFTERSALE_CONFIG_UPDATE_FAIL = 'AFTERSALE_CONFIG_UPDATE_FAIL'

export const AFTERSALE_CONFIG_DELETE_REQUEST = 'AFTERSALE_CONFIG_DELETE_REQUEST'
export const AFTERSALE_CONFIG_DELETE_SUCCESS = 'AFTERSALE_CONFIG_DELETE_SUCCESS'
export const AFTERSALE_CONFIG_DELETE_FAIL = 'AFTERSALE_CONFIG_DELETE_FAIL'

export const TYPE_AFTERSALE_CONFIG_LIST_REQUEST = 'TYPE_AFTERSALE_CONFIG_LIST_REQUEST'
export const TYPE_AFTERSALE_CONFIG_LIST_SUCCESS = 'TYPE_AFTERSALE_CONFIG_LIST_SUCCESS'
export const TYPE_AFTERSALE_CONFIG_LIST_FAIL = 'TYPE_AFTERSALE_CONFIG_LIST_FAIL'

export const TYPE_AFTERSALE_CONFIG_ADD_REQUEST = 'TYPE_AFTERSALE_CONFIG_ADD_REQUEST'
export const TYPE_AFTERSALE_CONFIG_ADD_SUCCESS = 'TYPE_AFTERSALE_CONFIG_ADD_SUCCESS'
export const TYPE_AFTERSALE_CONFIG_ADD_FAIL = 'TYPE_AFTERSALE_CONFIG_ADD_FAIL'

export const TYPE_AFTERSALE_CONFIG_UPDATE_REQUEST = 'TYPE_AFTERSALE_CONFIG_UPDATE_REQUEST'
export const TYPE_AFTERSALE_CONFIG_UPDATE_SUCCESS = 'TYPE_AFTERSALE_CONFIG_UPDATE_SUCCESS'
export const TYPE_AFTERSALE_CONFIG_UPDATE_FAIL = 'TYPE_AFTERSALE_CONFIG_UPDATE_FAIL'

export const TYPE_AFTERSALE_CONFIG_DELETE_REQUEST = 'TYPE_AFTERSALE_CONFIG_DELETE_REQUEST'
export const TYPE_AFTERSALE_CONFIG_DELETE_SUCCESS = 'TYPE_AFTERSALE_CONFIG_DELETE_SUCCESS'
export const TYPE_AFTERSALE_CONFIG_DELETE_FAIL = 'TYPE_AFTERSALE_CONFIG_DELETE_FAIL'
