import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, ListGroup, input, Form } from 'react-bootstrap'

import { listUsers, listUsersReset } from '../../../../actions/userActions'
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Loader from '../../../../components/Loader/Loader'
import Select from '../../../../components/Select/Select'
import useFileUpload from 'react-use-file-upload'
import uuid from 'react-uuid';

import { listUserSeller, majUserSeller, majUserSellerReset } from '../../../../actions/userSellerActions'

import './Users.css'


function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function Users() {
    const dispatch = useDispatch()
    const userList = useSelector(state => state.userList)
    const { users, loading: usersLoading, success: usersSuccess } = userList

    const userSellerList = useSelector(state => state.userSellerList)
    const { users: userSeller, loading: userSellerLoading, success: userSellerSuccess } = userSellerList

    const userSellerMaj = useSelector(state => state.userSellerMaj)
    const { user: usersSellerMaj, loading: userSellerMajLoading, success: userSellerMajSuccess } = userSellerMaj

    const [checked, setChecked] = useState([]);
    const [right, setRight] = useState([])
    const [left, setLeft] = useState([])
    const leftChecked = intersection(checked, [...left]);
    const rightChecked = intersection(checked, right);

    const [userData, setUserData] = useState([])
    const [usersList, setUsersList] = useState([])

    const [selectUsers, setSelectUsers] = useState([])
    const [selectedSeller, setSelectedSeller] = useState(null)

    const [sellers, setSellers] = useState([])
    const [clients, setClients] = useState([])

    const [notValid, setNotValid] = useState(false)
    const [selectedSellerId, setSelectedSellerId] = useState(null)

    useEffect(() => {
        dispatch(listUsers())
        dispatch(listUserSeller())
    }, [])

    useEffect(() => {
        if (usersSuccess && userSellerSuccess && users && users != undefined && users.length > 0) {
            setSellers(users.filter((user) => user.isAdmin))
            setClients(users.filter((user) => !user.isAdmin))
            fillSellerSelect()
        }
    }, [usersSuccess, userSellerSuccess])

    useEffect(() => {
        if (userSellerMajSuccess && usersSellerMaj && usersSellerMaj != undefined) {
            dispatch(majUserSellerReset())
            dispatch(listUserSeller())
            setLeft(clients)
            setRight([])
            setSelectedSeller(null)
        }
    }, [userSellerMajSuccess])

    useEffect(() => {
        if (clients && clients.length > 0) {
            setLeft(clients)
        }
    }, [clients])

    useEffect(() => {
        if (userSellerSuccess && selectedSeller && selectedSeller != undefined) {
            fillColumns()
        }
        else {
            setLeft(clients)
            setRight([])
        }
    }, [selectedSeller, userSellerSuccess])

    function fillSellerSelect() {
        let selectUsersArr = []

        users.filter((user) => user.isAdmin)
            .map((user) => {
                selectUsersArr.push({ label: user.name, value: user._id })
            })
        setSelectUsers(selectUsersArr)
    }

    function fillColumns() {
        if (selectedSeller && userSeller.length > 0) {
            let leftArr = []
            let rightArr = []

            clients.map((client) => {
                let found = userSeller.find((user) => user.user == client._id && user.seller == selectedSeller._id)
                if (found) {
                    rightArr.push(client)
                } else {
                    leftArr.push(client)
                }
            })

            setLeft(leftArr)
            setRight(rightArr)
        } else {
            setLeft(clients)
            setRight([])
        }
    }

    const handleAllRight = () => {
        setRight(right.concat([...left]));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(users.filter((d) => rightChecked.includes(d))));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(clients);
        setRight([]);
    };

    const reset = () => {
        fillColumns()
    };

    const handleChecked = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const onHandleSelectChange = (value) => {
        setSelectedSeller(sellers.filter(s => s.id == value)[0])
    }

    const customList = (items) => (
        <List dense component="div" role="user-list">
            {items && items.length > 0 && items.map((value) => {
                const labelId = `transfer-user-list-item-${value}-label`;

                return (
                    <ListItem
                        className='user-user-list-item'
                        key={value}
                        role="listitem"
                        onClick={handleChecked(value)}
                    >
                        <ListItemIcon>
                            <Checkbox
                                checked={checked.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                    "aria-labelledby": labelId
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${value.name}`} />
                    </ListItem>
                );
            })}
            <ListItem />
        </List>
    );

    const customRightList = (items) => (
        <List dense component="div" role="user-list">
            {items && items.length > 0 && items.map((value, index) => {
                const labelId = `transfer-user-list-item-${value}-label`;

                return (
                    <ListItem
                        className='user-user-list-item'
                        key={value}
                        role="listitem"

                    >
                        <ListItemIcon>
                            <Checkbox
                                checked={checked.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                    "aria-labelledby": labelId
                                }}
                                onClick={handleChecked(value)}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${value.name}`} />
                    </ListItem>
                );
            })}
            <ListItem />
        </List>
    );

    const saveUsersSellerList = () => {
        let usersIds = right.map(c => c.id)
        handleAssociation(usersIds)
    }

    const handleAssociation = (usersIds) => {
        if (usersIds && usersIds.length > 0) {
            let selectedClients = clients.filter(c => usersIds.includes(c.id))
            dispatch(majUserSeller(selectedSeller, selectedClients));
        }
    };

    return (
        <>
            {
                usersLoading || userSellerLoading || userSellerMajLoading ? <Loader /> :
                    <Row className='users-list-container-row'>
                        <Row className='users-list-select-row'>
                            <Col md={{ span: 4, offset: 0 }}>
                                <Select
                                    className='users-select'
                                    name="users-select"
                                    title="Commercial"
                                    value={selectedSeller}
                                    options={selectUsers}
                                    onChangeFunc={(e) => onHandleSelectChange(e)}
                                />
                            </Col>
                        </Row>

                        <Row className='users-users-list-row'>
                            <Col className='users-users-list-col' md={4}>
                                <Row>{customList(left)}</Row>
                            </Col>
                            <Col className='users-list-button-col' md={1}>
                                <Row className='users-list-button-row'>
                                    <button
                                        className='users-list-button-add'
                                        onClick={handleAllRight}
                                        disabled={usersList == undefined}
                                        aria-label="move all right"
                                    ><i class="fa-solid fa-angles-right"></i></button>
                                </Row>
                                <Row className='users-list-button-row'>
                                    <button
                                        className='users-list-button-add'
                                        onClick={handleCheckedRight}
                                        disabled={usersList == undefined}
                                        aria-label="move selected right"
                                    ><i class="fa-solid fa-angle-right"></i></button>
                                </Row>
                                <Row className='users-list-button-row'>
                                    <button
                                        className='users-list-button-remove'
                                        onClick={handleCheckedLeft}
                                        disabled={usersList == undefined}
                                        aria-label="move selected left"
                                    ><i class="fa-solid fa-angle-left"></i></button>
                                </Row>
                                <Row className='users-list-button-row'>
                                    <button
                                        className='users-list-button-remove'
                                        onClick={handleAllLeft}
                                        disabled={usersList == undefined}
                                        aria-label="move all left"
                                    ><i class="fa-solid fa-angles-left"></i></button>
                                </Row>
                            </Col>
                            <Col className='users-users-list-col' md={4}>
                                <Row>{customRightList(right)}</Row>
                            </Col>
                        </Row>

                        <Row className='users-list-button'>
                            <Col md={2}>
                                <button
                                    className='users-list-btn-cancel'
                                    type='button'
                                    disabled={false}
                                    onClick={reset}
                                >
                                    Reset
                                </button>
                            </Col>
                            <Col md={2}>
                                <button
                                    className='users-list-btn-validate'
                                    type='button'
                                    disabled={notValid}
                                    onClick={saveUsersSellerList}
                                >
                                    Valider
                                </button>
                            </Col>
                        </Row>
                    </Row>
            }
        </>
    )
}

export default Users