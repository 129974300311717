import{
    WARRANTY_LIST_REQUEST,
    WARRANTY_LIST_SUCCESS,
    WARRANTY_LIST_FAIL,

    WARRANTY_BY_ID_REQUEST,
    WARRANTY_BY_ID_SUCCESS,
    WARRANTY_BY_ID_FAIL,

    WARRANTY_ADD_REQUEST,
    WARRANTY_ADD_SUCCESS,
    WARRANTY_ADD_FAIL,

    WARRANTY_GET_BY_IMEI_REQUEST,
    WARRANTY_GET_BY_IMEI_SUCCESS,
    WARRANTY_GET_BY_IMEI_FAIL
}
from '../constants/warrantyConstants'

export const warrantyListReducer = (state = {warranties: []}, action) => {
    switch(action.type){
        case WARRANTY_LIST_REQUEST:
            return {loading: true, warranties: []}
        case WARRANTY_LIST_SUCCESS:
            return {loading: false, warranties: action.payload}
        case WARRANTY_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const warrantyGetByImeiReducer = (state = {warranty: {}}, action) => {
    switch(action.type){
        case WARRANTY_GET_BY_IMEI_REQUEST:
            return {loading: true, ...state}
        case WARRANTY_GET_BY_IMEI_SUCCESS:
            return {loading: false, warranty: action.payload}
        case WARRANTY_GET_BY_IMEI_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const warrantyByIdReducer = (state = {warranty: {}}, action) => {
    switch(action.type){
        case WARRANTY_BY_ID_REQUEST:
            return {loading: true, ...state}
        case WARRANTY_BY_ID_SUCCESS:
            return {loading: false, warranty: action.payload}
        case WARRANTY_BY_ID_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const warrantyAddReducer = (state = {}, action) => {
    switch(action.type){
        case WARRANTY_ADD_REQUEST:
            return {loading: true}
        case WARRANTY_ADD_SUCCESS:
            return {loading: false, success: true}
        case WARRANTY_ADD_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}