import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Row, Col, ListGroup, Image, Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import useFileUpload from 'react-use-file-upload'
import uuid from 'react-uuid';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Loader from '../../components/Loader/Loader'
import CountrySelector from '../../components/Selector/CountrySelector/CountrySelector';
import Message from '../../components/Message/Message'
import { listCountries } from '../../actions/countryActions'
import { listContactsOdoo, getContactOdoo, updateContactOdoo, deleteContactOdoo } from '../../actions/contactOdooActions'
import { deleteImage, uploadImage, updateImage } from '../../actions/imageActions';
import { updateUserProfile, refreshUser, updateUserProfileReset } from '../../actions/userActions'
import { login } from '../../actions/userActions'

import { useTranslation } from 'react-i18next';

import './Profile.css';

function Profile() {
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation();


    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [street, setStreet] = useState('')
    const [street2, setStreet2] = useState('')
    const [zip, setZip] = useState('')
    const [city, setCity] = useState('')
    const [country_id, setCountry_id] = useState(75)
    const [phone, setPhone] = useState('')
    const [mobile, setMobile] = useState('')
    const [vat, setVat] = useState('')
    const [siret, setSiret] = useState('')
    const [is_company, setIs_company] = useState(true)
    const [parent_id, setParent_id] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const [logo, setLogo] = useState(null)

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        street: '',
        street2: '',
        zip: '',
        city: '',
        country_id: 75,
        phone: '',
        mobile: '',
        vat: '',
        siret: '',
        is_company: true,
        parent_id: '',
    });

    const {
        files,
        fileNames,
        fileTypes,
        totalSize,
        totalSizeInBytes,
        handleDragDropEvent,
        clearAllFiles,
        createFormData,
        setFiles,
        removeFile
    } = useFileUpload();

    const inputRef = useRef();

    let navigate = useNavigate();

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const countryList = useSelector((state) => state.countryList)
    const { countries } = countryList

    const contactOdooList = useSelector((state) => state.contactOdooList)
    const { contactsOdoo, error } = contactOdooList

    const contactOdooGet = useSelector((state) => state.contactOdooGet)
    const { contactOdoo, loading } = contactOdooGet

    const contactOdooUpdate = useSelector((state) => state.contactOdooUpdate)
    const { success: successUpdate, loading: loadingUpdate } = contactOdooUpdate

    const contactOdooDel = useSelector((state) => state.contactOdooDelete)
    const { success } = contactOdooDel

    const imageUpload = useSelector((state) => state.imageUpload)
    const { image, success: succesUpload } = imageUpload

    const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
    const { success: successUpdateProfile, loading: loadingUpdateProfile } = userUpdateProfile

    useEffect(() => {
        dispatch(listCountries());
        if (userInfo && userInfo.id_odoo) {
            dispatch(listContactsOdoo(userInfo.id_odoo));
            dispatch(getContactOdoo(userInfo.id_odoo));
        }
    }, [])

    useEffect(() => {
        if (succesUpload) {
            window.location.reload();
        }
    }, [succesUpload])

    useEffect(() => {
        if (userInfo && userInfo.id_odoo) {
            dispatch(listContactsOdoo(userInfo.id_odoo));
            dispatch(getContactOdoo(userInfo.id_odoo));
        }
    }, [userInfo])

    useEffect(() => {
        if (contactOdoo) {
            setName(contactOdoo.name)
            setEmail(contactOdoo.email)
            setStreet(contactOdoo.street)
            setStreet2(contactOdoo.street2)
            setZip(contactOdoo.zip)
            setCity(contactOdoo.city)
            setCountry_id(contactOdoo.country_id)
            setPhone(contactOdoo.phone)
            setMobile(contactOdoo.mobile)
            setVat(contactOdoo.vat)
            setSiret(contactOdoo.siret)
            setIs_company(contactOdoo.is_company)
            setParent_id(contactOdoo.parent_id)
        }
    }, [contactOdoo])

    useEffect(() => {
        if (success)
            dispatch(listContactsOdoo(userInfo.id_odoo));
    }, [success])

    const addInputCountry = (value) => {
        // TODO Récupérer les pays odoo et filtrer sur la liste pour récupérer l'id              
        const country_id = countries.filter(country => country.name === value.label)[0].id;
        setFormData({
            ...formData,
            ['country_id']: country_id,
        });
    };

    const validateVAT = (value) => {
        const VATRegex = /^FR[0-9A-Z]{2}[0-9]{9}$/;
        return VATRegex.test(value);
    };

    const validateSIRET = (value) => {
        if (value.length !== 14) {
            return false;
        }

        let sum = 0;
        for (let i = 0; i < 14; i++) {
            let digit = parseInt(value[i], 10);
            if (i % 2 === 0) {
                digit *= 2;
                if (digit > 9) {
                    digit -= 9;
                }
            }
            sum += digit;
        }

        return sum % 10 === 0;
    };

    function changePassword() {
        const formData = {
            email: userInfo.email,
            name: userInfo.name,
            password: password,
        }

        dispatch(updateUserProfile(formData))
    }

    function updateContact() {
        const formData = {
            id_odoo: userInfo.id_odoo,
            name: name,
            email: email,
            street: street,
            street2: street2,
            zip: zip,
            city: city,
            country_id: country_id,
            phone: phone,
            mobile: mobile,
            vat: vat,
            siret: siret,
            is_company: is_company,
            parent_id: parent_id,
        }

        dispatch(updateContactOdoo(formData))
    }

    const TabInformations = (
        <Row className='profile-infos-row'>
            <Col md={6}>
                <Row className='profile-first-row'>
                    <Form.Group className='form-group-profile' controlId='name'>
                        <Form.Label>{t('Name')}</Form.Label>
                        <Form.Control
                            required
                            name="name"
                            type='text'
                            placeholder={t('Enter name')}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group className='form-group-profile' controlId='email'>
                        <Form.Label>{t('Email Address')}</Form.Label>
                        <Form.Control
                            required
                            name="email"
                            type='email'
                            placeholder={t('Enter email')}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        ></Form.Control>
                    </Form.Group>
                </Row>
                <Row className='profile-second-row'>
                    <Col>
                        <Form.Group className='form-group-profile' controlId='street'>
                            <Form.Label>{t('Street')}</Form.Label>
                            <Form.Control
                                required
                                name="street"
                                type='text'
                                placeholder={t('Street')}
                                value={street}
                                onChange={(e) => setStreet(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group className='form-group-profile' controlId='street2'>
                            <Form.Label>{t('Street 2')}</Form.Label>
                            <Form.Control
                                name="street2"
                                type='text'
                                placeholder={t('Street 2')}
                                value={street2}
                                onChange={(e) => setStreet2(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group className='form-group-profile' controlId='zip'>
                            <Form.Label>{t('Postal code')}</Form.Label>
                            <Form.Control
                                required
                                name="zip"
                                type='text'
                                placeholder={t('Postal code')}
                                value={zip}
                                onChange={(e) => setZip(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group className='form-group-profile' controlId='city'>
                            <Form.Label>{t('City')}</Form.Label>
                            <Form.Control
                                required
                                name="city"
                                type='text'
                                placeholder={t('City')}
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='form-group-profile' controlId='country'>
                            <Form.Label>{t('Country')}</Form.Label>
                            <CountrySelector addInputCountry={addInputCountry} />
                        </Form.Group>
                        <Form.Group className='form-group-profile' controlId='vat'>
                            <Form.Label>{t('VAT')}</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="vat"
                                placeholder={t('VAT')}
                                value={vat}
                                onChange={(e) => setVat(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group className='form-group-profile' controlId='siret'>
                            <Form.Label>{t('SIRET')}</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="siret"
                                placeholder={t('SIRET')}
                                value={siret}
                                onChange={(e) => setSiret(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='row-btn-profile justify-content-end'>
                    <Button className='btn-profile-validate' onClick={() => updateContact()}>
                        {t('Validate')}
                    </Button>
                </Row>
            </Col>
        </Row>
    );

    const TabLogo = (
        <Row className='profile-infos-row'>
            <Col md={6}>
                {
                    userInfo && userInfo.logo ?
                        <Row className='profile-first-row'>
                            <Form.Group className='form-group-profile' controlId='logo'>
                                <Image className='images-detail-img' src={userInfo.logo} fluid rounded />
                            </Form.Group>
                        </Row>
                        :
                        null
                }

                <Row className='file-upload-row'>
                    <div className='file-upload-container'>
                        <Row className='file-list-row'>
                            <h3 className='file-list-title'>{t('Images to send')}</h3>
                            <ul className='file-list'>
                                {fileNames.map((name) => (
                                    <li key={name} className='file-item'>
                                        <span>{name}</span>
                                        <span onClick={() => removeFile(name)}>
                                            <i className="fa-regular fa-trash-can file-remove"></i>
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </Row>
                        <Row className='file-upload-btn-row'>
                            <Button
                                className='file-upload-btn'
                                onClick={() => handleFileUpload()}
                            >{t('Add')}</Button>
                        </Row>
                    </div>

                    <div
                        className='drop-zone'
                        onDragEnter={handleDragDropEvent}
                        onDragOver={handleDragDropEvent}
                        onDrop={(e) => {
                            handleDragDropEvent(e);
                            setFiles(e, 'a');
                        }}
                    >
                        <p>{t('Drop your files here')}</p>
                        <Button className='select-file-btn' onClick={() => inputRef.current.click()}>{t('Select a file')}</Button>
                        <input
                            ref={inputRef}
                            type="file"
                            multiple
                            style={{ display: 'none' }}
                            onChange={(e) => {
                                setFiles(e, 'a');
                                inputRef.current.value = null;
                            }}
                        />
                    </div>
                </Row>
                <Row className='row-btn-profile justify-content-end'>
                    <Button className='btn-profile-validate' onClick={() => updateContact()} variant='primary'>
                        {t('Validate')}
                    </Button>
                </Row>
            </Col>
        </Row>
    );

    const TabPassword = (
        <Row className='profile-infos-row'>
            <Col md={6}>
                <Row className='profile-first-row'>
                    <Form.Group className='form-group-profile' controlId='password'>
                        <Form.Label>{t('Password')}</Form.Label>
                        <Form.Control
                            required
                            name="password"
                            type='password'
                            placeholder={t('Enter password')}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group className='form-group-profile' controlId='confirmPassword'>
                        <Form.Label>{t('Confirm Password')}</Form.Label>
                        <Form.Control
                            required
                            type='password'
                            placeholder={t('Confirm password')}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        ></Form.Control>
                    </Form.Group>
                </Row>
                <Row className='row-btn-profile'>
                    <button className='btn-profile-validate' onClick={changePassword} variant='primary'>
                        {t('Validate')}
                    </button>
                </Row>
            </Col>
        </Row>
    )

    const TabSocietes = (
        <Row className='profile-infos-row'>
            <Row className='profile-button-row'>
                <Button className='btn-profile-add' onClick={() => addSociete()}>
                    <i className="fa-solid fa-plus fa-lg"></i>
                    {` ${t('Add a subsidiary')}`}
                </Button>
            </Row>
            {contactsOdoo && contactsOdoo.filter((item) => item.is_company == true).length === 0 ? (
                <Message variant='info'>
                    {t('Your list of companies is empty')}
                </Message>
            ) : (
                <ListGroup variant='flush'>
                    {contactsOdoo && contactsOdoo.filter(item => item.is_company == true).map((item) => (
                        <ListGroup.Item className='profile-item' key={item.product}>
                            <Row className='profile-item-row'>
                                <Col className='profile-item-detail-col' md={8}>
                                    <Row className='profile-item-name'>{item.name}</Row>
                                    <Row className='profile-item-detail'>{t('siret')} : {item.siret}</Row>
                                    <Row className='profile-item-detail'>{t('phone')} : {item.phone}</Row>
                                    <Row className='profile-item-detail'>{t('mobile')} : {item.mobile}</Row>
                                    <Row className='profile-item-detail'>{t('address')} : {item.street} {item.street2} {item.zip} {item.city} {item.country_id[1]}</Row>
                                </Col>
                                <Col className='profile-item-remove-col' md={4}>
                                    <Button className="btn-item-remove" onClick={() => dispatch(deleteContactOdoo(item.id))}>
                                        <i className='fas fa-trash'></i>
                                    </Button>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            )}
        </Row>
    );

    const TabCollaborateurs = (
        <Row className='profile-infos-row'>
            <Row className='profile-button-row'>
                <Button className='btn-profile-add' onClick={() => addCollaborateur()}>
                    <i className="fa-solid fa-plus fa-lg"></i>
                    {` ${t('Add a collaborator')}`}
                </Button>
            </Row>
            {contactsOdoo && contactsOdoo.length === 0 ? (
                <Message variant='info'>
                    {t('Your list of collaborators is empty')}
                </Message>
            ) : (
                <ListGroup variant='flush'>
                    {contactsOdoo && contactsOdoo.filter(item => item.is_company == false).map((item) => (
                        <ListGroup.Item className='profile-item' key={item.product}>
                            <Row className='profile-item-row'>
                                <Col className='profile-item-detail-col' md={8}>
                                    <Row className='profile-item-name'>{item.name}</Row>
                                    <Row className='profile-item-detail'>{t('email')} : {item.email}</Row>
                                    <Row className='profile-item-detail'>{t('phone')} : {item.phone}</Row>
                                    <Row className='profile-item-detail'>{t('mobile')} : {item.mobile}</Row>
                                    <Row className='profile-item-detail'>{t('address')} : {item.street} {item.street2} {item.zip} {item.city} {item.country_id[1]}</Row>
                                </Col>
                                <Col className='profile-item-remove-col' md={4}>
                                    <Button className="btn-item-remove" onClick={() => dispatch(deleteContactOdoo(item.id))}>
                                        <i className='fas fa-trash'></i>
                                    </Button>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            )}
        </Row>
    );

    const handleFileUpload = (e) => {

        let i = 0
        let folder = 'logo'
        files.forEach(image => {
            let formData = new FormData();
            let extension = image.name.split('.')[image.name.split('.').length - 1]

            let imageName = 'logo_' + userInfo.name + '.' + extension

            formData.append('image', image, imageName);
            formData.append('folder', folder);

            let subFolders = [userInfo._id]
            subFolders.forEach((element, index) => {
                formData.append(`subFolders[${index}]`, element);
            });
            dispatch(updateImage(formData))
            //dispatch(uploadImage(formData))
            i++
        });
        clearAllFiles()
    };

    function addSociete() {
        navigate('/profile/societe')
    }

    function addCollaborateur() {

        navigate('/profile/collab')
    }

    return (
        <Row className='profile-main-row'>
            <div className='profile-title'>{t('My account')}</div>
            {message && <Message variant='danger'>{message}</Message>}
            {error && <Message variant='danger'>{error}</Message>}
            {success && <Message variant='success'>{t('Profile Updated')}</Message>}
            <Row className='profile-tab-row'>
                {loading || loadingUpdate || loadingUpdateProfile ? <Loader /> :
                    <Tabs
                        defaultActiveKey="all"
                        id="uncontrolled-tab-example"
                        className="profile-tab"
                    >
                        <Tab eventKey="all" title={t('My information')} selectedTabClassName="is-selected" >
                            {TabInformations}
                        </Tab>
                        <Tab eventKey="logo" title={t('Logo')} >
                            {TabLogo}
                        </Tab>
                        <Tab eventKey="received" title={t('Password')} >
                            {TabPassword}
                        </Tab>
                        <Tab eventKey="inprogress" title={t('My companies')}>
                            {TabSocietes}
                        </Tab>
                        <Tab eventKey="over" title={t('My collaborators')}>
                            {TabCollaborateurs}
                        </Tab>
                    </Tabs>
                }
            </Row>
        </Row>
    )

}

export default Profile