export const MESSAGE_LIST_REQUEST = 'MESSAGE_LIST_REQUEST'
export const MESSAGE_LIST_SUCCESS = 'MESSAGE_LIST_SUCCESS'
export const MESSAGE_LIST_FAIL = 'MESSAGE_LIST_FAIL'

export const MESSAGE_CREATE_REQUEST = 'MESSAGE_CREATE_REQUEST'
export const MESSAGE_CREATE_SUCCESS = 'MESSAGE_CREATE_SUCCESS'
export const MESSAGE_CREATE_FAIL = 'MESSAGE_CREATE_FAIL'
export const MESSAGE_CREATE_RESET = 'MESSAGE_CREATE_RESET'

export const MESSAGE_DELETE_REQUEST = 'MESSAGE_DELETE_REQUEST'
export const MESSAGE_DELETE_SUCCESS = 'MESSAGE_DELETE_SUCCESS'
export const MESSAGE_DELETE_FAIL = 'MESSAGE_DELETE_FAIL'
export const MESSAGE_DELETE_RESET = 'MESSAGE_DELETE_RESET'

export const MESSAGE_UPDATE_REQUEST = 'MESSAGE_UPDATE_REQUEST'
export const MESSAGE_UPDATE_SUCCESS = 'MESSAGE_UPDATE_SUCCESS'
export const MESSAGE_UPDATE_FAIL = 'MESSAGE_UPDATE_FAIL'
export const MESSAGE_UPDATE_RESET = 'MESSAGE_UPDATE_RESET'

export const MESSAGE_AS_READ_REQUEST = 'MESSAGE_AS_READ_REQUEST'
export const MESSAGE_AS_READ_SUCCESS = 'MESSAGE_AS_READ_SUCCESS'
export const MESSAGE_AS_READ_FAIL = 'MESSAGE_AS_READ_FAIL'
export const MESSAGE_AS_READ_RESET = 'MESSAGE_AS_READ_RESET'

export const MESSAGE_SEND_MAIL_REQUEST = 'MESSAGE_SEND_MAIL_REQUEST'
export const MESSAGE_SEND_MAIL_SUCCESS = 'MESSAGE_SEND_MAIL_SUCCESS'
export const MESSAGE_SEND_MAIL_FAIL = 'MESSAGE_SEND_MAIL_FAIL'
export const MESSAGE_SEND_MAIL_RESET = 'MESSAGE_SEND_MAIL_RESET'

export const MESSAGE_AS_UNREAD_COUNT_REQUEST = 'MESSAGE_AS_UNREAD_COUNT_REQUEST'
export const MESSAGE_AS_UNREAD_COUNT_SUCCESS = 'MESSAGE_AS_UNREAD_COUNT_SUCCESS'
export const MESSAGE_AS_UNREAD_COUNT_FAIL = 'MESSAGE_AS_UNREAD_COUNT_FAIL'