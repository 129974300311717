import{
    DOCUMENTS_GET_REQUEST,
    DOCUMENTS_GET_SUCCESS,
    DOCUMENTS_GET_FAIL,
}
from '../constants/documentConstants'

export const documentsReducer = (state = { documents: [] }, action) => {
    switch (action.type) {
        case DOCUMENTS_GET_REQUEST:
            return { loading: true, documents: [] }
        case DOCUMENTS_GET_SUCCESS:
            return { loading: false, documents: action.payload }
        case DOCUMENTS_GET_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}