import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'



import { listClients, impersonate, resetImpersonateSuccess } from '../../../../actions/clientActions'

import './Connect.css'

function Connect() {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const clientList = useSelector((state) => state.clientList)
    const { clients } = clientList

    const impersonated = useSelector((state) => state.impersonated)
    const { success } = impersonated

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const [filteredClient, setFilteredClient] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [resetSelect, setResetSelect] = useState(false);

    const searchKeys = ['id', 'username', 'name']
    const fuseOptions = {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: searchKeys
    };

    useEffect(() => {
        dispatch(listClients())

        const redirectPath = localStorage.getItem('redirectPath');

        if (redirectPath) {
            navigate(redirectPath);
            localStorage.removeItem('redirectPath');  // clean up after navigation
        }
    }, [])


    useEffect(() => {
        if (success) {

            localStorage.setItem('redirectPath', '/');
            window.location.reload();
            dispatch(resetImpersonateSuccess())
        }
    }, [success])

    useEffect(() => {
        setFilteredClient(clients)
    }, [clients])

    const handleImpersonate = (user_id) => {

        dispatch(impersonate(user_id))
    }

    const formatResult = (item) => {
        return (
            <>
                {
                    item && item != undefined ? (
                        <span>{item.name} --- {item.username != null && item.username != undefined ? item.username : null}</span>
                    ) : null
                }

            </>
        )
    };

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        if (string.length === 0 || string === "") {
            setFilteredClient(clients);
        }
        setSearchTerm(string);
    };

    const handleOnSelect = (item) => {
        // the item selected
        setFilteredClient(filteredClient.filter((client) => client.id === item.id));
        setSearchTerm(item.id);
    };

    const handleOnFocus = () => {

    };

    return (
        <>
            <h1 className='text-center'>Connect</h1>
            <Row className='connect-search-row'>
                <Col md={6}>
                    <ReactSearchAutocomplete
                        items={filteredClient && filteredClient != undefined && filteredClient.length > 0 ?
                            filteredClient
                            : []}
                        fuseOptions={fuseOptions}
                        resultStringKeyName="username"
                        onSearch={handleOnSearch}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        onClear={() => { setSearchTerm(''); setFilteredClient(clients); setResetSelect(!resetSelect); }}
                        autoFocus
                        placeholder="Rechercher"
                        formatResult={formatResult}
                        threshold={0.6} // 0 = exact match, 1 = everything matches
                        ignoreLocation={true}
                        maxResults={8}
                        styling={{
                            width: "100%",
                            border: "1px solid #dfe1e5",
                            borderRadius: "5px",
                            backgroundColor: "white",
                            boxShadow: "rgba(58, 53, 53, 0.2) 0px 1px 6px 0px",
                            hoverBackgroundColor: "#eee",
                            color: "#212121",
                            fontSize: "12px",
                            fontFamily: "Arial",
                            iconColor: "grey",
                            lineColor: "rgb(232, 234, 237)",
                            placeholderColor: "grey",
                            clearIconMargin: '0 5px 0 0',
                            searchIconMargin: '0 0 0 5px',
                            zIndex: 5,
                        }}
                    />
                </Col>
            </Row>

            <Row className='justify-content-start'>
                {filteredClient && filteredClient.length > 0 ? (
                    filteredClient.map((client) => (
                        <Col className='connect-client-col' xs={12} sm={6} md={4} lg={3}>
                            <Card className='mb-4'>
                                <Card.Body>
                                    <Card.Title>{client.name}</Card.Title>
                                    <Card.Text>{client.username}</Card.Text>
                                    <button className='connect-btn' onClick={() => handleImpersonate(client.id)}>Connect</button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                )
                    : (
                        <div className='text-center mt-5'>There are no clients</div>
                    )
                }
            </Row>
        </>
    )
}

export default Connect