import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Row, Col, Image } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux'

import Switch from "react-switch";

import Message from '../../../../../components/Message/Message';
import Loader from '../../../../../components/Loader/Loader';
import Select from '../../../../../components/Select/Select'


import { listSkus, addSku, checkSkuInDbCircle, deleteSku } from '../../../../../actions/skuActions';

import { listOrdersBC, createOrderBC } from '../../../../../actions/orderActions';
import { validImei } from '../../../../../utils/luhnUtils';

import '../ArticlesNonImei.css'
import './RemoveArticlesNonImei.css';

function RemoveArticles() {
  const searchRef = useRef(null);
  const [form, setForm] = useState({});

  const [toggleChecked, setToggleChecked] = useState(false);
  const [messageVariant, setMessageVariant] = useState('')
  const [error, setError] = useState(false);
  const [Skus, setSkus] = useState('');
  const [inputSkus, setInputSkus] = useState('');
  const [search, setSearch] = useState(false);
  const [message, setMessage] = useState('')
  const [newScan, setNewScan] = useState(false)
  const [bcListForSelect, setBcListForSelect] = useState([])
  const [selectedBc, setSelectedBc] = useState({})
  const [BC_id, setBC_id] = useState('')

  const [productToDisplay, setProductToDisplay] = useState({})

  const orderBCList = useSelector(state => state.orderBCList)
  const { ordersBC, success: successOrderCreated } = orderBCList

  const productNonImeiList = useSelector(state => state.productNonImeiList)
  const { loading, productsNonImei } = productNonImeiList

  const skuList = useSelector(state => state.skuList)
  const { skus } = skuList
  const skuDelete = useSelector(state => state.skuDelete)
  const { success } = skuDelete
  const skuCheck = useSelector(state => state.skuCheck)
  const { checked } = skuCheck

  const dispatch = useDispatch()

  useEffect(() => {
    searchRef.current.focus()
    dispatch(listOrdersBC())
  }, [])

  useEffect(() => {
    let bc_list_for_select = []
    if (ordersBC && ordersBC.length > 0) {
      ordersBC.forEach(element => {
        bc_list_for_select.push({
          value: element.id,
          label: element.name + ' - ' + element.date_order
        })
      });
      setBcListForSelect(bc_list_for_select)
    }
  }, [ordersBC])

  useEffect(() => {
    
    if ((toggleChecked || (!toggleChecked && search))) {
      dispatch(createOrderBC(Skus, BC_id))

      let skus = Skus.split('\n')
      
      dispatch(deleteSku(skus))
      resetConst()
    }

    setSearch(false)
    setNewScan(false)
  }, [Skus, search, newScan])

  useEffect(() => {
    if (success) {
      setMessage('Les article Skus ' + Skus.length > 1 ?
        'Les article Skus ' + Skus + 'ont été retirés ' + 'avec succès.'
        : 'L\'article Skus ' + Skus + ' a été retiré avec succès.'
      )
      setMessageVariant('success')
    }
  }, [success])


  function resetConst() {
    if (Skus.length > 0) {
      setSearch(false)
      setError(false)
      setInputSkus('')
    }
    searchRef.current.focus()
  }

  useEffect(() => {
    
    
    let skus = Skus.split('\n')
    if (checked) {
      dispatch(deleteSku(skus))
      setMessage('L\'article Skus ' + Skus + ' a été retiré avec succès.')
      setMessageVariant('success')
      resetConst()
    }
  }, [checked])

  function manageSkuInput(value) {
    setInputSkus(value)
    setNewScan(true)
    setSkus(value)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    setSearch(true)
  }

  const handleToggleChange = (checked) => {
    setToggleChecked(checked);
  };

  const onHandleSelectChange = (value, key) => {
    setBC_id(ordersBC.filter(order => order.id === value)[0].name);
    setSelectedBc(value)
  };

  return (
    <div className='articles-nonimei-container'>
      <Row className='articles-nonimei-title-row'>
        <h1>Retirer un article</h1>
      </Row>
      <Row className='articles-nonimei-main-row'>
        <Col md={8}>
          <Row className='articles-nonimei-switch-row'>
            <Col md={2}>
              <Switch
                className='addarticles-switch'

                onChange={handleToggleChange}
                checked={toggleChecked}
                offColor="#2f86ca"
                boxShadow="0px 5px 10px rgba(0, 0, 0, 0.6)"
              />
            </Col>
            <Col md={8}>
              <span>{!toggleChecked ? 'Sortie automatique désactivé' : 'Sortie automatique activé'}</span>
            </Col>
          </Row>
          <Row className='articles-nonimei-manage-row'>
            <Col md={3}>
              <Form.Control
                as='textarea'
                type="text"
                id="Skus-input"
                aria-describedby="passwordHelpBlock"
                placeholder="Sku"
                ref={searchRef}
                value={inputSkus}
                onChange={(e) => manageSkuInput(e.target.value)}
              />
            </Col>
            <Col md={6}>
              <Select
                name="bc"
                title="Bon de commande"
                value={selectedBc}
                options={bcListForSelect}
                onChangeFunc={onHandleSelectChange}
              />
            </Col>
            <Col md={3}>
              {
                !toggleChecked ?
                  <button className='removeArticlesNonImeiBtn' type='submit' onClick={submitHandler} disabled={!selectedBc}>Retirer</button>
                  : null
              }
            </Col>
          </Row>
          <Row className='articles-nonimei-manage-row'>
            <Col md={10}>
              {message && message.length > 0 ? <Message variant={messageVariant}>{message}</Message> : null}
            </Col>
          </Row>
        </Col>
        <Col className='articles-nonimei-detail-col' md={4}>
          {loading ? <Loader /> :
            productToDisplay && productToDisplay.name ?
              (
                <>
                  <Row className='articles-nonimei-detail-title'>
                    {productToDisplay.brand} {productToDisplay.name}
                  </Row>
                  <Row className='articles-nonimei-detail-img-row'>
                    <Image className='articles-nonimei-detail-img' src={productToDisplay.device_image} alt={productToDisplay.name} fluid rounded />
                  </Row>
                  <Row className='articles-nonimei-detail-rows'>
                    <Row className=''>
                      Marque : {productToDisplay.brand}
                    </Row>
                    <Row className=''>
                      Modèle : {productToDisplay.model}
                    </Row>
                    <Row className=''>
                      Sku : {Skus}
                    </Row>
                  </Row>
                </>
              )
              : null
          }
        </Col>
      </Row>
    </div>
  )
}

export default RemoveArticles
