import {
    ACCESSORIES_LIST_REQUEST,
    ACCESSORIES_LIST_SUCCESS,
    ACCESSORIES_LIST_FAIL,
    
    ACCESSORIES_MAJ_REQUEST,
    ACCESSORIES_MAJ_SUCCESS,
    ACCESSORIES_MAJ_FAIL,
    ACCESSORIES_MAJ_RESET
}
    from '../constants/accessoriesConstants';

export const accessoriesListReducer = (state = { accessories: [] }, action) => {
    switch (action.type) {
        case ACCESSORIES_LIST_REQUEST:
            return { loading: true, accessories: [] }
        case ACCESSORIES_LIST_SUCCESS:
            return { loading: false, accessories: action.payload, success: true }
        case ACCESSORIES_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const accessoriesMajReducer = (state = { accessories: {} }, action) => {
    switch (action.type) {
        case ACCESSORIES_MAJ_REQUEST:
            return { loading: true }
        case ACCESSORIES_MAJ_SUCCESS:
            return { loading: false, success: true, accessories: action.payload }
        case ACCESSORIES_MAJ_FAIL:
            return { loading: false, error: action.payload }
        case ACCESSORIES_MAJ_RESET:
            return { accessories: {} }
        default:
            return state
    }
}