import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Row, Col, ListGroup, Image, Card, Container } from 'react-bootstrap'

import './ProductColorSelector.css'

function ProductColorSelector(props) {
  const listImage = props.listImage

  const onProductSelectorClick = (e) => {
    props.onClick({ src: e.target.src });
  };

  return (
    <Row>
      {listImage.map((item) => (
        <Col className='productcolorselector-col' onClick={onProductSelectorClick}>
          <Image className='productcolorselector-img' src={item.url} alt={item.url} fluid rounded />
          <span>{item.color}</span>
        </Col>
      ))}
    </Row>
  )
}

export default ProductColorSelector