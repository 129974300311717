import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Row, Col, ListGroup, Image, Card, Container } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux'
import uuid from 'react-uuid';

import useFileUpload from 'react-use-file-upload'

import Message from '../../../../../components/Message/Message';
import Loader from '../../../../../components/Loader/Loader';
import Select from '../../../../../components/Select/Select';

import { ProductByImeiFromAPI, listProducts } from '../../../../../actions/productActions';
import { getCircleProductByIMEI, ProductsFromCircle, createProduct, createReset, checkProductInDbCircle } from '../../../../../actions/productActions';
import { listFeatures } from '../../../../../actions/featureActions';
import { listImageByImei, uploadImage, deleteImage } from '../../../../../actions/imageActions';
import { validImei } from '../../../../../utils/luhnUtils';
import { createDefaultImage, updateDefaultImage, listDefaultImages } from '../../../../../actions/defaultImagesActions';
import { useTranslation } from 'react-i18next';

import '../Images.css'
import './ImageProducts.css';

function ImageProducts() {
  const searchRef = useRef(null);
  const [form, setForm] = useState({});
  const { t, i18n } = useTranslation();

  const [toggleChecked, setToggleChecked] = useState(false);
  const [messageVariant, setMessageVariant] = useState('')
  const [error, setError] = useState(false);
  const [imei, setImei] = useState('');
  const [warranty, setWarranty] = useState({});
  const [inputImei, setInputImei] = useState('');
  const [message, setMessage] = useState('')

  const [selectedColor, setSelectedColor] = useState('')
  const [selectedImage, setSelectedImage] = useState('')
  const [selectedImages, setSelectedImages] = useState([])

  const [colorListForSelect, setColorListForSelect] = useState([])

  const [productToDisplay, setProductToDisplay] = useState(null)

  const productInDbCircle = useSelector(state => state.productInDbCircle)
  const { productInCircle } = productInDbCircle

  const productFromCircle = useSelector(state => state.productFromCircleByImei)
  const { circleProductByImei, loading } = productFromCircle

  const listImage = useSelector(state => state.imageList)
  const { images } = listImage

  const productFeatures = useSelector(state => state.featureList)
  const { features } = productFeatures

  const listDefaultImage = useSelector(state => state.defaultImageList)
  const { defaultImages } = listDefaultImage

  const imageUpload = useSelector(state => state.imageUpload)
  const { success: successUpload } = imageUpload

  const warrantyFromDb = useSelector(state => state.warranty)

  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile
  } = useFileUpload();

  const inputRef = useRef();

  const dispatch = useDispatch()

  useEffect(() => {
    if (validImei(imei))
      dispatch(getCircleProductByIMEI(imei))
    else
      setProductToDisplay(null)
  }, [imei])

  useEffect(() => {
    if (successUpload) {
      dispatch(listImageByImei(imei.substring(0, 8), 'product', selectedColor))
      dispatch(listProducts())
    }
  }, [successUpload])

  useEffect(() => {
    let colorList = []

    if (features && features.length > 0) {
      
      features.map((feature) => {
        if (feature.name === 'COLOR') {
          colorList.push({ label: feature.value, value: feature.value })
        }
      })
    }
    setColorListForSelect(colorList)
  }, [features])

  useEffect(() => {
    dispatch(listImageByImei(imei.substring(0, 8), 'product', selectedColor))
  }, [selectedColor])

  useEffect(() => {
    if (circleProductByImei && circleProductByImei.id_odoo) {
      if (imei.length === 15)
        dispatch(listFeatures(imei))
      setProductToDisplay(circleProductByImei)
    }
  }, [circleProductByImei])

  useEffect(() => {
    setErrorMessages()
  }, [inputImei])

  function resetConst() {
    if (imei.length > 0) {
      setError(false)
      setInputImei('')
    }
  }

  function setErrorMessages() {
    setError(false)
    if (inputImei.length > 15) {
      setMessage('Le numéro IMEI doit comporter 15 chiffres.')
      setError(true)
      setMessageVariant('danger')
    }
    else if (inputImei.length === 15 && !validImei(inputImei)) {
      setMessage('Le numero IMEI ' + inputImei + ' n\'est pas valide.')
      setError(true)
      setMessageVariant('danger')
    }
    else if (!productInCircle.productInDbCircle) {
      setMessage('')
    }
  }

  function manageImeiInput(value) {
    setInputImei(value)
    if (value.length >= 15) {
      setImei(value)
      dispatch(listDefaultImages(value.substring(0, 8)))
    }
  }

  const onHandleSelectColorChange = useCallback((value, name) => {


    setSelectedColor(value)
  });

  const removeFromImagesHandler = (name) => {
    // on supprime une image produit grâce au nom
    let folder = 'product'
    let formData = new FormData();

    formData.append('name', name);
    formData.append('folder', folder);

    let subFolders = [imei.substring(0, 8), selectedColor]
    subFolders.forEach((element, index) => {
      formData.append(`subFolders[${index}]`, element);
    });

    dispatch(deleteImage(formData)).then(() => {
      dispatch(listImageByImei(imei.substring(0, 8), 'product', selectedColor))
    })
  }

  const handleFileUpload = (e) => {

    let i = 0
    let folder = 'product'

    files.forEach(image => {
      let formData = new FormData();
      let extension = image.name.split('.')[image.name.split('.').length - 1]

      let imageName = uuid() + '.' + extension

      formData.append('image', image, imageName);
      formData.append('folder', folder);

      let subFolders = [imei.substring(0, 8), selectedColor]
      subFolders.forEach((element, index) => {
        formData.append(`subFolders[${index}]`, element);
      });

      dispatch(uploadImage(formData)).then(() => {
        if (image === files[0]) {
          let default_already_exist = defaultImages.filter(defaultImage =>
            defaultImage.tac === imei.substring(0, 8) &&
            defaultImage.color === selectedColor
          ).length > 0
          if (!default_already_exist)
            dispatch(createDefaultImage(imei.substring(0, 8), selectedColor, imageName))
        }

        dispatch(listImageByImei(imei.substring(0, 8), 'product', selectedColor))
      })
      i++

      clearAllFiles()
    });
  };

  const onDefaultImageChangeValue = (event) => {
    dispatch(updateDefaultImage(imei.substring(0, 8), selectedColor, event.target.value))
  }


  return (
    <div className='images-container'>
      <Row className='images-title-row'>
        <h1>Images Articles</h1>
      </Row>
      <Row className='images-main-row'>
        <Col md={6}>
          <Row className='images-manage-row'>
            <Col md={6}>
              <Form.Control
                type="text"
                id="imei-input"
                aria-describedby="passwordHelpBlock"
                ref={searchRef}
                value={inputImei}
                onChange={(e) => manageImeiInput(e.target.value)}
                placeholder={t('IMEI number')}
                ReactTooltip={t('The IMEI number must be 15 digits.')}
              />
            </Col>
          </Row>
          <Row className='images-manage-row'>
            <Col md={10}>
              {message && message.length > 0 ? <Message variant={messageVariant}>{message}</Message> : null}
            </Col>
          </Row>
          <Row className='images-features-row'>
            <Row className='images-features-detail-row'>
              <span>Color</span>
              <Col md={10}>
                <Select
                  className='images-color-select'
                  name="color"
                  title="Color"
                  value={selectedColor}
                  options={colorListForSelect}
                  onChangeFunc={onHandleSelectColorChange}
                />
              </Col>
              {/* <Col md={2}>
                <div id="imageproduct-preview-select" style={{ background: selectedColor }}></div>
              </Col> */}
            </Row>
          </Row>
          {images && images.length > 0 && selectedColor ?
            <Row className='imageproduct-products-row'>
              <ListGroup className='imageproduct-products' variant='flush'>
                {images.map((item, index) => {
                  const defaultImage = defaultImages.find((di) => di.image === item.name);
                  const isDefaultImage = defaultImage != null;
                  return (
                    <ListGroup.Item className='imageproduct-items' key={item.product}>
                      <Row className='imageproduct-row-item'>
                        <Col className='vertical-center' md={2}>
                          <Image className='imageproduct-img' src={item.url} alt={item.url} fluid rounded />
                        </Col>
                        <Col className='' md={6}>
                          <span>{item.name}</span>
                        </Col>
                        <Col className='' md={1}>
                          <input
                            id={`reverse-radio-1`}
                            className="form-check-input"
                            name="flexRadioDefault"
                            type='radio'
                            value={item.name}
                            defaultChecked={isDefaultImage}
                            // checked={state.selectedOption === "Male"}
                            onClick={(event) => onDefaultImageChangeValue(event)}
                          />
                        </Col>
                        <Col className='imageproduct-item-input' md={3}>
                          <button
                            className="btn-imageproduct-remove"
                            onClick={() => dispatch(removeFromImagesHandler(item.name))}>
                            <i className='fas fa-trash'></i>
                          </button>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Row>
            : null
          }
        </Col>
        <Col className='images-detail-col' md={5}>
          {loading ? <Loader /> :
            productToDisplay ?
              (
                <>
                  <Row className='images-detail-row'>
                    <Row className='images-detail-title'>
                      {productToDisplay.brand} {productToDisplay.name}
                    </Row>
                    <Row className='images-detail-img-row'>
                      <Image className='images-detail-img' src={productToDisplay.device_image} alt={productToDisplay.name} fluid rounded />
                    </Row>
                    <Row className='images-detail-rows'>
                      <Row className=''>
                        {t('brand')} : {productToDisplay.brand}
                      </Row>
                      <Row className=''>
                        {t('model')} : {productToDisplay.model}
                      </Row>
                      <Row className=''>
                        IMEI : {imei}
                      </Row>
                    </Row>
                  </Row>
                  <Row className='imageproducts-file-container'>
                    {
                      selectedColor && selectedColor !== '' ?
                        <div>
                          <div
                            className='imageproducts-list-files-container'
                          >
                            <Row className='imageproducts-list-files-row'>
                              <ul>
                                <p className='imageproducts-list-files-title'>Images à envoyer</p>
                                {fileNames.map((name) => (
                                  <li key={name}>
                                    <span>{name}</span>

                                    <span onClick={() => removeFile(name)}>
                                      <i className="fa-regular fa-trash-can file-remove"></i>
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </Row>
                            <Row className='imageproducts-btn_addfiles-row'>
                              <button
                                className='imageproducts-btn-add-file'
                                onClick={() => handleFileUpload()}
                              >Ajouter</button>
                            </Row>

                          </div>

                          {/* Provide a drop zone and an alternative button inside it to upload files. */}
                          <div
                            className='image-product-drop-zone'
                            onDragEnter={handleDragDropEvent}
                            onDragOver={handleDragDropEvent}
                            onDrop={(e) => {
                              handleDragDropEvent(e);
                              setFiles(e, 'a');
                            }}
                          >
                            <p>Déposez vos fichiers ici</p>

                            <button className='imageproducts-btn-select-file' onClick={() => inputRef.current.click()}>Sélectionner un fichier</button>

                            <input
                              ref={inputRef}
                              type="file"
                              multiple
                              style={{ display: 'none' }}
                              onChange={(e) => {
                                setFiles(e, 'a');
                                inputRef.current.value = null;
                              }}
                            />
                          </div>
                        </div>
                        : null
                    }

                  </Row>
                </>
              )
              : null
          }
        </Col>
      </Row>
    </div>
  )
}

export default ImageProducts