import {
    SKU_LIST_REQUEST,
    SKU_LIST_SUCCESS,
    SKU_LIST_FAIL,

    SKU_ADD_REQUEST,
    SKU_ADD_SUCCESS,
    SKU_ADD_FAIL,

    SKU_DELETE_REQUEST,
    SKU_DELETE_SUCCESS,
    SKU_DELETE_FAIL,

    SKU_CHECK_REQUEST,
    SKU_CHECK_SUCCESS,
    SKU_CHECK_FAIL,
}
    from '../constants/skuConstants';

import axios from 'axios';

export const listSkus = (product_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: SKU_LIST_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            `/api/sku/?product_id=${product_id}`
            );

        dispatch({
            type: SKU_LIST_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: SKU_LIST_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message :
                error.message,
        });
    }
}

export const addSku = (product_id, skus) => async (dispatch, getState) => {
    try {
        dispatch({ type: SKU_ADD_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            '/api/sku/add/',
            { 'product_id': product_id, 'skus': skus },
            
        );

        dispatch({
            type: SKU_ADD_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: SKU_ADD_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message :
                error.message,
        });
    }
}

export const checkSkuInDbCircle = (sku) => async (dispatch, getState) => {
    try {
        dispatch({ type: SKU_CHECK_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            `/api/sku/check/?sku=${sku}`
            );

        dispatch({
            type: SKU_CHECK_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: SKU_CHECK_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message :
                error.message,
        });
    }
}


export const deleteSku = (skus) => async (dispatch, getState) => {
    try {
        dispatch({ type: SKU_DELETE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/sku/del/`,
            { 'skus': skus },
            )

        dispatch({
            type: SKU_DELETE_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: SKU_DELETE_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message :
                error.message,
        });
    }
}
