export const PURCHASE_LIST_REQUEST = 'PURCHASE_LIST_REQUEST'
export const PURCHASE_LIST_SUCCESS = 'PURCHASE_LIST_SUCCESS'
export const PURCHASE_LIST_FAIL = 'PURCHASE_LIST_FAIL'
export const PURCHASE_LIST_RESET = 'PURCHASE_LIST_RESET'

export const PURCHASE_ADD_REQUEST = 'PURCHASE_ADD_REQUEST'
export const PURCHASE_ADD_SUCCESS = 'PURCHASE_ADD_SUCCESS'
export const PURCHASE_ADD_FAIL = 'PURCHASE_ADD_FAIL'

export const PURCHASE_UPDATE_REQUEST = 'PURCHASE_UPDATE_REQUEST'
export const PURCHASE_UPDATE_SUCCESS = 'PURCHASE_UPDATE_SUCCESS'
export const PURCHASE_UPDATE_FAIL = 'PURCHASE_UPDATE_FAIL'

export const PURCHASE_DELETE_REQUEST = 'PURCHASE_DELETE_REQUEST'
export const PURCHASE_DELETE_SUCCESS = 'PURCHASE_DELETE_SUCCESS'
export const PURCHASE_DELETE_FAIL = 'PURCHASE_DELETE_FAIL'
