import {
    FLEET_ENTITY_LIST_REQUEST,
    FLEET_ENTITY_LIST_SUCCESS,
    FLEET_ENTITY_LIST_FAIL,

    FLEET_ENTITY_GET_REQUEST,
    FLEET_ENTITY_GET_SUCCESS,
    FLEET_ENTITY_GET_FAIL,

    FLEET_ENTITY_ADD_REQUEST,
    FLEET_ENTITY_ADD_SUCCESS,
    FLEET_ENTITY_ADD_FAIL,

    FLEET_ENTITY_ADD_BY_FILE_REQUEST,
    FLEET_ENTITY_ADD_BY_FILE_SUCCESS,
    FLEET_ENTITY_ADD_BY_FILE_FAIL,

    FLEET_ENTITY_DELETE_REQUEST,
    FLEET_ENTITY_DELETE_SUCCESS,
    FLEET_ENTITY_DELETE_FAIL,

    FLEET_ENTITY_UPDATE_REQUEST,
    FLEET_ENTITY_UPDATE_SUCCESS,
    FLEET_ENTITY_UPDATE_FAIL,

    RESET_FLEET_ENTITY_ADD_SUCCESS,
    RESET_FLEET_ENTITY_DELETE_SUCCESS,
    RESET_FLEET_ENTITY_UPDATE_SUCCESS,
    RESET_FLEET_ENTITY_ADD_BY_FILE_SUCCESS
}
    from '../constants/fleetEntityConstants'

import axios from 'axios'


export const getFleetEntities = () => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        dispatch({
            type: FLEET_ENTITY_LIST_REQUEST
        })

        const { data } = await instance.get(
            `/api/fleet-entity/?user_id=${userInfo.id}`
            , 
        )

        dispatch({
            type: FLEET_ENTITY_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: FLEET_ENTITY_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const getFleetEntity = (fleet_user_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FLEET_ENTITY_GET_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            `/api/fleet-entity/get/?fleet_user_id=${fleet_user_id}`
            , 
        )

        dispatch({
            type: FLEET_ENTITY_GET_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: FLEET_ENTITY_GET_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const addFleetEntity = (fleetEntity) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FLEET_ENTITY_ADD_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/fleet-entity/add/`,
            { 'fleetEntity': fleetEntity, 'user_id': userInfo.id }
            , 
        )

        dispatch({
            type: FLEET_ENTITY_ADD_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: FLEET_ENTITY_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const addFleetEntityByFile = (fleetEntities) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FLEET_ENTITY_ADD_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/fleet-entity/addByFile/`,
            { 'fleetEntities': fleetEntities, 'user_id': userInfo.id }
            , 
        )

        dispatch({
            type: FLEET_ENTITY_ADD_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: FLEET_ENTITY_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const deleteFleetEntity = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FLEET_ENTITY_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        await instance.delete(
            `/api/fleet-entity/del/?id=${id}`
            )

        dispatch({
            type: FLEET_ENTITY_DELETE_SUCCESS,
        })

    } catch (error) {
        dispatch({
            type: FLEET_ENTITY_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const updateFleetEntity = (fleetEntity) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FLEET_ENTITY_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/fleet-entity/update/`, { 'fleetEntity': fleetEntity, 'user_id': userInfo.id }
            )

        dispatch({
            type: FLEET_ENTITY_UPDATE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: FLEET_ENTITY_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const resetFleetEntityAddSuccess = () => async (dispatch, getState) => {
    dispatch({
        type: RESET_FLEET_ENTITY_ADD_SUCCESS
    })
}

export const resetFleetEntityDeleteSuccess = () => async (dispatch, getState) => {
    dispatch({
        type: RESET_FLEET_ENTITY_DELETE_SUCCESS
    })
}

export const resetFleetEntityUpdateSuccess = () => async (dispatch, getState) => {
    dispatch({
        type: RESET_FLEET_ENTITY_UPDATE_SUCCESS
    })
}

export const resetFleetEntityAddByFileSuccess = () => async (dispatch, getState) => {
    dispatch({
        type: RESET_FLEET_ENTITY_ADD_BY_FILE_SUCCESS
    })
}