import axios from 'axios';
import {
    STOCK_REQUEST,
    STOCK_SUCCESS,
    STOCK_FAIL,

    STOCK_ALL_REQUEST,
    STOCK_ALL_SUCCESS,
    STOCK_ALL_FAIL,

    UPDATE_STOCK_REQUEST,
    UPDATE_STOCK_SUCCESS,
    UPDATE_STOCK_FAIL,
}
    from '../constants/stockConstants'

export const getOdooStock = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: STOCK_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/stock/`,
            _id,
            )

        dispatch({
            type: STOCK_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: STOCK_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const getAllOdooStock = () => async (dispatch, getState) => {
    try {
        dispatch({ type: STOCK_ALL_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            'api/stock/all'
            )
        
        dispatch({ type: STOCK_ALL_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: STOCK_ALL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        })
    }
}

export const updateOdooStock = (_id, value) => async (dispatch, getState) => {
    try {
        dispatch({ type: UPDATE_STOCK_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/stock/update`,
            { id: _id, value: value },
            )

        dispatch({
            type: UPDATE_STOCK_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: UPDATE_STOCK_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}