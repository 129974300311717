import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, ListGroup } from 'react-bootstrap';

import {
    getMessages,
    createMessage,
    updateMessage,
    deleteMessage,
    markMessageAsRead,
    resetMessageCreate,
    resetMessageUpdate,
    resetMessageDelete,
} from '../../actions/messageActions';
import { listContactsOdoo } from '../../actions/contactOdooActions'

import {
    listConversations,
    getReceivers,
    addConversation,
    deleteConversation
} from '../../actions/conversationActions';

import Select from '../../components/Select/Select'
import { useTranslation } from 'react-i18next';

import './Message.css';

function Message() {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const conversationsList = useSelector(state => state.conversationsList)
    const { conversations } = conversationsList

    const conversationAdd = useSelector(state => state.conversationAdd)
    const { addSuccess } = conversationAdd

    const markedAsRead = useSelector(state => state.markedAsRead)
    const { successAsRead } = markedAsRead

    const conversationDel = useSelector(state => state.conversationDel)
    const { delSuccess } = conversationDel

    const listMessages = useSelector(state => state.listMessages)
    const { messages, loading, error } = listMessages

    const conversationsReceivers = useSelector(state => state.conversationsReceivers)
    const { receivers } = conversationsReceivers

    const createdMessage = useSelector(state => state.createdMessage)
    const { successCreate } = createdMessage

    const updatedMessage = useSelector(state => state.updatedMessage)
    const { successUpdate } = updatedMessage

    const deletedMessage = useSelector(state => state.deletedMessage)
    const { successDelete } = deletedMessage

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const contactOdooList = useSelector((state) => state.contactOdooList)
    const { contactsOdoo } = contactOdooList

    const [selectedConversation, setSelectedConversation] = useState(null);
    const [destinataire, setDestinataire] = useState(null);
    const [destinatairesForSelect, setDestinatairesForSelect] = useState([]);
    const [conversationSubject, setConversationSubject] = useState('');
    const [conversationMessages, setConversationMessages] = useState([]);

    const [messageData, setMessageData] = useState({ id: null, sender: null, receiver: null, subject: '', body: '' });

    useEffect(() => {
        dispatch(listConversations())
        dispatch(getReceivers())
    }, []);

    useEffect(() => {
        
    }, [messageData])

    useEffect(() => {
        if (contactsOdoo && contactsOdoo.length > 0) {
            let destinataires = [];
            contactsOdoo.forEach(element => {
                destinataires.push({ label: element.name, value: element.id_circle })
            });
            setDestinatairesForSelect([...destinatairesForSelect, ...destinataires]);
        }
    }, [contactsOdoo])

    useEffect(() => {
        if (receivers && receivers.length > 0) {
            let destinataires = [];
            receivers.forEach(element => {
                destinataires.push({ label: element.name, value: element.id })
            });

            setDestinatairesForSelect([...destinatairesForSelect, ...destinataires]);

            if (userInfo && userInfo.id_odoo) {
                dispatch(listContactsOdoo(userInfo.id_odoo));
            }
        }
    }, [receivers])

    useEffect(() => {
        if (addSuccess || delSuccess) {
            dispatch(listConversations())
        }
    }, [addSuccess, delSuccess])

    useEffect(() => {
        if (selectedConversation != 0) {
            dispatch(getMessages(selectedConversation))
            conversations.forEach(conversation => {
                if (conversation.id == selectedConversation) {
                    conversation.unreadCount = 0;
                }
            })
        }
    }, [selectedConversation]);

    useEffect(() => {
        if (conversations && conversations.length > 0 && selectedConversation == null) {
            setSelectedConversation(conversations[conversations.length - 1].id)
        }
    }, [conversations]);

    useEffect(() => {
        if (messages && messages != undefined) {
            setConversationMessages(messages)
            messages.forEach(message => {
                if (message.user != userInfo._id && !message.read) {
                    dispatch(markMessageAsRead(message.id));
                }
            });
        }
    }, [messages]);

    useEffect(() => {
        if (successCreate || successUpdate || successDelete) {
            dispatch(getMessages(selectedConversation))

            if (successCreate)
                dispatch(resetMessageCreate())
            if (successUpdate)
                dispatch(resetMessageUpdate())
            if (successDelete)
                dispatch(resetMessageDelete())
        }
    }, [successCreate, successUpdate, successDelete]);

    const handleEdit = (message) => {
        
        setMessageData({
            ...messageData,
            id: message.id,
            sender: null,
            receiver: null,
            subject: message.subject,
            body: message.body
        });
    };

    useEffect(() => {
        if (successAsRead) {
            dispatch(listConversations())
        }
    }, [successAsRead]);

    const handleDelete = (message) => {
        if (window.confirm('Are you sure you want to delete this message?')) {
            dispatch(deleteMessage(message.id));
        }
    };

    const handleChange = (e) => {
        setMessageData({ ...messageData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (messageData.id) {
            dispatch(updateMessage(messageData.id, messageData.subject, messageData.body, selectedConversation));
            conversationMessages.find(message => message.id === messageData.id).body = messageData.body;
            conversationMessages.find(message => message.id === messageData.id).subject = messageData.subject;
        } else {
            dispatch(createMessage(messageData.subject, messageData.body, selectedConversation));
        }
        setMessageData({...messageData, id: null, sender: null, receiver: null, subject: '', body: '' });
    };

    const getClassName = (message) => {
        if (message.type === 'sent') {
            return 'message-item sent-message';
        } else {
            return 'message-item received-message';
        }
    };

    const handleSelectConversation = (conversation) => {
        setSelectedConversation(conversation.id);
    };

    const onHandleSelectConversationChange = (id_receiver) => {
        setDestinataire(id_receiver);
    };

    const newConversationHandler = (value) => {
        dispatch(addConversation(destinataire, conversationSubject))
    }

    const handleConversationSubject = (e) => {
        setConversationSubject(e.target.value);
    }

    const handleDeleteConversation = (id) => {
        setConversationMessages([]);
        dispatch(deleteConversation(id))
    }

    return (
        <Row className="message-container">
          <h1>{t('Messages')}</h1>
      
          <Col md={8} className="message-list-col">
            <div className="message-list">
              {conversationMessages && conversationMessages.length > 0 ? conversationMessages.map(message => (
                <div key={message.id} className={getClassName(message)}>
                  <p className="message-sender">{message.name}</p>
                  <p className="message-subject">{message.subject}</p>
                  <p className="message-body">{message.body}</p>
                  {
                    message.type === 'sent' ? (
                      <div className="message-actions">
                        <button className="message-action-button" onClick={() => handleEdit(message)}>{t('Edit')}</button>
                        <button className="message-delete-button" onClick={() => handleDelete(message)}>{t('Delete')}</button>
                      </div>
                    ) : null
                  }
                </div>
              ))
                : <p className="message-empty-list">{t('No messages to display.')}</p>
              }
            </div>
          </Col>
          <Col md={4} className="conversation-col">
            <div className="conversation-controls">
              <Select
                className='conversation-user-select'
                name="user"
                title={t('Recipient')}
                value={destinataire}
                options={destinatairesForSelect}
                onChangeFunc={(e) => onHandleSelectConversationChange(e)}
              />
              <input type="text"
                name="subject"
                className='conversation-subject-input'
                value={conversationSubject}
                onChange={handleConversationSubject}
                placeholder={t('Subject')} />
              <button
                className='conversation-new-button'
                type='submit'
                disabled={!destinataire}
                onClick={newConversationHandler}>
                <i className="fa-solid fa-plus fa-lg"></i>
              </button>
            </div>
            <div className="conversation-list">
              {conversations && conversations.length > 0 ?
                conversations.map((item) => (
                  <div className='conversation-item' onClick={(e) => handleSelectConversation(item)}>
                    <button className="conversation-item-remove" onClick={() => handleDeleteConversation(item.id)}>
                      <i className='fas fa-trash'></i>
                    </button>
                    <p className="conversation-item-receiver">{item.receiver}</p>
                    <p className="conversation-item-subject">{item.sujet}</p>
                  </div>
                ))
                : <p className="conversation-empty-list">{t('No conversation to display.')}</p>
              }
            </div>
      
            {selectedConversation ? (
              <div className="message-form">
                <h2>{messageData.id ? t('Edit Message') : t('Create Message')}</h2>
                <form onSubmit={handleSubmit}>
                  <input type="text" name="subject" value={messageData.subject} onChange={handleChange} placeholder={t('Subject')} />
                  <textarea name="body" value={messageData.body} onChange={handleChange} placeholder={t('Message Body')}></textarea>
                  <button type="submit">{messageData.id && messageData.body != '' ? t('Update') : t('Create')}</button>
                </form>
              </div>
            ) : null
            }
          </Col>
        </Row>
      );
      

}

export default Message;