import{
    HISTORY_GET_REQUEST,
    HISTORY_GET_SUCCESS,
    HISTORY_GET_FAIL,

    HISTORY_ALL_REQUEST,
    HISTORY_ALL_SUCCESS,
    HISTORY_ALL_FAIL,

    DELETED_IMEIS_REQUEST,
    DELETED_IMEIS_SUCCESS,
    DELETED_IMEIS_FAIL
}
from '../constants/history_constants';

import axios from 'axios';

export const getHistory = (imei) => async (dispatch, getState) => {
    try {
        dispatch({ type: HISTORY_GET_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            `/api/history/?imei=${imei}`
            , 
        )

        dispatch({
            type: HISTORY_GET_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: HISTORY_GET_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const getAllHistory = (imeis) => async (dispatch, getState) => {
    try {
        // imei de la forme 'xxxxxx,xxxxxx,xxxxxx'
        // Doit être séparé par des virgules pour en faire un tableau
        dispatch({ type: HISTORY_ALL_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            `/api/history/all/?imeis=${imeis}`
            )

        dispatch({
            type: HISTORY_ALL_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: HISTORY_ALL_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const getDeletedImeis = (imeis) => async (dispatch, getState) => {
    try {
        dispatch({ type: DELETED_IMEIS_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            `/api/history/deletedImei/?imeis=${imeis}`
            )

        dispatch({
            type: DELETED_IMEIS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: DELETED_IMEIS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}