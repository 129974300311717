export const CONVERSATION_LIST_REQUEST = 'CONVERSATION_LIST_REQUEST';
export const CONVERSATION_LIST_SUCCESS = 'CONVERSATION_LIST_SUCCESS';
export const CONVERSATION_LIST_FAIL = 'CONVERSATION_LIST_FAIL';

export const CONVERSATION_ADD_REQUEST = 'CONVERSATION_ADD_REQUEST';
export const CONVERSATION_ADD_SUCCESS = 'CONVERSATION_ADD_SUCCESS';
export const CONVERSATION_ADD_FAIL = 'CONVERSATION_ADD_FAIL';

export const CONVERSATION_RECEIVERS_GET_REQUEST = 'CONVERSATION_RECEIVERS_GET_REQUEST';
export const CONVERSATION_RECEIVERS_GET_SUCCESS = 'CONVERSATION_RECEIVERS_GET_SUCCESS';
export const CONVERSATION_RECEIVERS_GET_FAIL = 'CONVERSATION_RECEIVERS_GET_FAIL';

export const CONVERSATION_DEL_REQUEST = 'CONVERSATION_DEL_REQUEST';
export const CONVERSATION_DEL_SUCCESS = 'CONVERSATION_DEL_SUCCESS';
export const CONVERSATION_DEL_FAIL = 'CONVERSATION_DEL_FAIL';