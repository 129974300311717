import { Container, Row, Col } from 'react-bootstrap'

import { NavLink } from "react-router-dom";
import { useSelector } from 'react-redux';
import { FaBars } from "react-icons/fa";
import { AiFillDashboard } from "react-icons/ai";
import { GiSmartphone } from "react-icons/gi";
import { BiCategoryAlt } from "react-icons/bi";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { BiImageAdd } from "react-icons/bi";
import { GiAutoRepair } from "react-icons/gi";
import { FaFileContract } from "react-icons/fa";
import { BsCartCheck } from "react-icons/bs";
import { TbChecklist } from "react-icons/tb";
import { FiTool, FiMonitor } from "react-icons/fi";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { CgDisplayGrid } from "react-icons/cg";
import { FiUsers } from "react-icons/fi";
import { BsSim } from "react-icons/bs";
import { RiDeviceLine } from "react-icons/ri";
import { RiBuilding4Line } from "react-icons/ri";
import { BiBookReader } from "react-icons/bi";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { MdProductionQuantityLimits } from "react-icons/md";
import SideBarMenu from "./SideBarMenu";

import './SideBar.css';
import { useTranslation } from 'react-i18next';

function SideBar({ children }) {
  const { t, i18n } = useTranslation();

  const routes_client = [
    {
      path: "/dashboard",
      name: t('DashBoard'),
      icon: <GiSmartphone />,
    },
    {
      path: "",
      name: t('My Fleet'),
      icon: <HiOutlineDevicePhoneMobile />,
      subRoutes: [
        {
          path: "/fleet",
          name: t('See my fleet'),
          icon: <RiDeviceLine />,
        },
        {
          path: "/fleet-user",
          name: t('My users'),
          icon: <FiUsers />,
        },
        {
          path: "/fleet-sim",
          name: t('My lines'),
          icon: <BsSim />,
        },
        {
          path: "/fleet-entity",
          name: t('My entities'),
          icon: <RiBuilding4Line />,
        },
      ]
    },
    {
      path: "/catalog",
      name: t('My catalog'),
      icon: <BiBookReader />,
    },
    {
      path: "/orders",
      name: t('My orders'),
      icon: <BsCartCheck />,
    },
    // {
    //   path: "/quotations",
    //   name: "Mes devis",
    //   icon: <FaFileContract />,
    // },
    {
      path: "/aftersale",
      name: t('AfterSales'),
      icon: <GiAutoRepair />,
      subRoutes: [
        {
          path: "/aftersale/new",
          name: t('New demand'),
        },
        {
          path: "/aftersale/devices",
          name: t('Aftersale Devices'),
        },
        {
          path: "/aftersale/tickets",
          name: t('My Tickets'),
        }
      ]
    },
  ];

  const routes_olinn = [
    {
      path: "/connect",
      name: t('Impersonate'),
      icon: <AiOutlineUserSwitch />,
    },
    {
      path: "/articles",
      name: "Articles (via IMEI)",
      icon: <GiSmartphone />,
      subRoutes: [
        {
          path: "/articles/add",
          name: "Ajouter un article",
        },
        {
          path: "/articles/remove",
          name: "Sortir un article",
        },
        {
          path: "/articles/rental",
          name: "Tarif location",
        },
        {
          path: "/images/products",
          name: "images articles",
        },
        {
          path: "/articles/list",
          name: "Infos stock",
        },
        {
          path: "/articles/setNewValues",
          name: "Caractéristiques",
        }
      ]
    },
    {
      path: "/articlesNonImei",
      name: "Articles (non IMEI)",
      icon: <FiMonitor />,
      subRoutes: [
        {
          path: "/articlesNonImei/add",
          name: "Ajouter un article",
        },
        {
          path: "/articlesNonImei/remove",
          name: "Sortir un article",
        },
        {
          path: "/articles/rental",
          name: "Tarif location",
        }
        // {
        //   path: "/articlesNonImei/list",
        //   name: "Infos stock",
        // }
      ]
    },
    {
      path: "/accessories",
      name: "Articles liés",
      icon: <MdProductionQuantityLimits />,
    },
    {
      path: "/users",
      name: "Gestion utilisateurs",
      icon: <AiOutlineUser />,
    },
    {
      path: "/categories",
      name: "Gestion catégories",
      icon: <BiCategoryAlt />,
    },
    {
      path: "/images",
      name: "Gestion d'images",
      icon: <BiImageAdd />,
      subRoutes: [
        {
          path: "/images/banner",
          name: "Home - bannière",
        },
        // {
        //   path: "/images/tuiles",
        //   name: "Home - tuiles",
        // },
      ]
    },
    {
      path: "/display",
      name: "Gestion de contenu",
      icon: <CgDisplayGrid />,
      subRoutes: [
        {
          path: "/display/header-brand-order",
          name: "Afichage des marques",
        }
      ]
    },
    {
      path: "/list",
      name: "Listes catalogue",
      icon: <TbChecklist />,
      subRoutes: [
        {
          path: "/list/white",
          name: "Liste blanche",
        },
        {
          path: "/list/black",
          name: "Liste noire",
        }
      ]
    },
    {
      path: "/aftersale",
      name: "Gestion SAV",
      icon: <GiAutoRepair />,
      subRoutes: [
        {
          path: "/aftersale/aftersale",
          name: "Prestation SAV",
        },
        {
          path: "/aftersale/type-aftersale",
          name: "Categories Prestations",
        }
      ]
    },
    {
      path: "/config",
      name: "Configuration",
      icon: <FiTool />,
    },
  ]

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  // const inputAnimation = {
  //   hidden: {
  //     width: 0,
  //     padding: 0,
  //     transition: {
  //       duration: 0.2,
  //     },
  //   },
  //   show: {
  //     width: "140px",
  //     padding: "5px 15px",
  //     transition: {
  //       duration: 0.2,
  //     },
  //   },
  // };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div className="main-container">
      <div
        className={`sidebar ${isOpen ? 'sidebar-open' : 'sidebar-closed'} ${userInfo && userInfo.impersonated_by ? 'sidebar-impersonated' :
          userInfo && userInfo.isAdmin ? 'sidebar-admin' : 'sidebar-client'
          }`}
      >
        <div className="top_section">
          <AnimatePresence>
            {isOpen && (
              <motion.span
                className="sidebar-name"
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
              >
                {t('Welcome')} {userInfo['name']}
              </motion.span>
            )}
          </AnimatePresence>

          <div className="bars">
            <FaBars onClick={toggle} />
          </div>
        </div>
        <section className="routes">
          {(userInfo && userInfo.groups && userInfo.groups.find(group => group === "Client") ? routes_client : routes_olinn).map((route, index) => {

            if (route.subRoutes) {
              return (
                <SideBarMenu
                  setIsOpen={setIsOpen}
                  route={route}
                  showAnimation={showAnimation}
                  isOpen={isOpen}
                />
              );
            }

            return (
              <NavLink
                to={route.path}
                key={index}
                className="link"
                activeClassName="active"
              >
                <div className="sidebar-icon">{route.icon}</div>
                <AnimatePresence>
                  {isOpen && (
                    <motion.div
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className="link_text"
                    >
                      {route.name}
                    </motion.div>
                  )}
                </AnimatePresence>
              </NavLink>
            );
          })
          }
        </section>
      </div>
      <Container className={`children-main ${isOpen ? "children-shifted" : ""}`}>
          {children}
      </Container>
    </div>
  );
};

export default SideBar;
