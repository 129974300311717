import {
    RENTAL_RATE_MAJ_REQUEST,
    RENTAL_RATE_MAJ_SUCCESS,
    RENTAL_RATE_MAJ_FAIL,
    RENTAL_RATE_MAJ_RESET,

    RENTAL_RATE_DELETE_REQUEST,
    RENTAL_RATE_DELETE_SUCCESS,
    RENTAL_RATE_DELETE_FAIL
}
    from '../constants/rentalRateConstants'

export const rentalRateMajReducer = (state = {}, action) => {
    switch (action.type) {
        case RENTAL_RATE_MAJ_REQUEST:
            return { loading: true }
        case RENTAL_RATE_MAJ_SUCCESS:
            return { loading: false, success: true, rentalRate: action.payload }
        case RENTAL_RATE_MAJ_FAIL:
            return { loading: false, error: action.payload }
        case RENTAL_RATE_MAJ_RESET:
            return {}
        default:
            return state
    }
}

export const rentalRateDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case RENTAL_RATE_DELETE_REQUEST:
            return { loading: true }
        case RENTAL_RATE_DELETE_SUCCESS:
            return { loading: false, success: true }
        case RENTAL_RATE_DELETE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}