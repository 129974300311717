import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { Row, Col, ListGroup, Image, Card, Container } from 'react-bootstrap'
import StripeCheckout from "react-stripe-checkout";
import Modal from 'react-modal';


import { addToCart, emptyCart, removeFromCart, saveShippingAddress } from '../../actions/cartActions'
import { createOrder } from '../../actions/orderActions'

import { listProducts } from '../../actions/productActions';
import { listNonImeiProducts } from '../../actions/productActions';
import { paymentCharge } from '../../actions/paymentActions';

import Message from '../../components/Message/Message'
import CheckoutSteps from '../../components/CheckoutSteps/CheckoutSteps'


import './PlaceOrder.css'

function PlaceOrder() {
    const orderCreate = useSelector((state) => state.orderCreate)
    const { order, success, error } = orderCreate

    const dispatch = useDispatch()
    const productList = useSelector(state => state.productList)
    const { products } = productList

    const paymentResponse = useSelector(state => state.paymentResponse)
    const { paymentDetails, success: paymentSuccess, error: paymentError } = paymentResponse

    const productNonImeiList = useSelector(state => state.productNonImeiList)
    const { productsNonImei } = productNonImeiList

    const navigate = useNavigate()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const cart = useSelector((state) => state.cart)
    const { cartItems } = cart

    const [modalFinaleIsOpen, setFinaleIsOpen] = React.useState(false);

    const [payment, setPayment] = useState({})

    const sentText = "Nous vous remercions pour votre commande."
    const followText = "Vous pouvez suivre l'avancement de votre commande dans votre espace : mes commandes."

    const itemsPrice = cartItems.reduce((acc, item) => acc + item.product.price * item.qty, 0).toFixed(2)
    const taxPrice = cartItems.reduce((acc, item) => acc + item.product.price * item.qty * 0.2, 0).toFixed(2)
    const totalPrice = (Number(itemsPrice) + Number(taxPrice)).toFixed(2)

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
        },
    };

    if (!cart.paymentMethod) {
        navigate('/payment')
    }

    if (!cart.shippingPrice) {
        navigate('/cart')
    }

    useEffect(() => {
        if (!products || products.length === 0)
            dispatch(listProducts());
        if (!productsNonImei || productsNonImei.length === 0)
            dispatch(listNonImeiProducts(userInfo ? userInfo.id_odoo : -1));
    }, [])

    let subtitle;

    function afterOpenFinaleModal() {
        subtitle.style.color = '#f00';
    }

    function closeFinaleModal() {
        setFinaleIsOpen(false);
        navigate('/orders')
    }

    function afterOpenResumeModal() {
        subtitle.style.color = '#f00';
    }


    function CreateOrder() {
        let partner_id = userInfo.id_odoo;

        let order_lines = [];
        cart.cartItems.map((item, index) => (
            
            
            order_lines.push({
                "product_id": item.imei ?
                    products.filter(product => product.id === item.product.id)[0].id_odoo
                    : productsNonImei.filter(product => product.id === item.product.id)[0].id_odoo,
                "name": item.product.brand + " " + item.product.name,
                "product_uom_qty": item.qty,
                "price_unit": item.product.price,
            })
        ))

        dispatch(createOrder({
            "partner_id": partner_id,
            "state": "sale",
            "order_lines": order_lines
        }))
    }

    useEffect(() => {
        if (paymentSuccess) {
            setFinaleIsOpen(true);
            dispatch(emptyCart())
        }
        else if (paymentError) {
            showErrorMessage('Erreur de paiement')
        }
    }, [paymentSuccess, paymentError])

    useEffect(() => {
        if (success) {
            
            
            dispatch(paymentCharge(payment, order.name))
        }
        else if (error)
            showErrorMessage('Une erreur est survenue à la création de la commande')
    }, [success, error])

    function showErrorMessage(message) {
        alert(message)
    }

    const handleToken = async (token, addresses) => {
        let payment = {
            token: token,
            amount: totalPrice * 100, // Montant à payer en centimes
            billing_address: addresses.billing_address,
            shipping_address: addresses.shipping_address
        }

        setPayment(payment)
        CreateOrder()
    };

    return (
        <>
            <CheckoutSteps step1 step2 step3 step4 />
            <Row>
                <Col md={8}>
                    <ListGroup variant='flush'>
                        <ListGroup.Item>
                            <h2>Livraison</h2>
                            <p>
                                <strong>Adresse: </strong>
                                {cart.shippingAddress.voie}{' '}, {cart.shippingAddress.voie2}{' '}
                                {cart.shippingAddress.codePostal},{' '}
                                {cart.shippingAddress.ville},{' '}
                                {cart.shippingAddress.pays[1]}
                            </p>
                        </ListGroup.Item>
                        {/* <ListGroup.Item>
                            <h2>Méthode de paiement</h2>
                            <strong>Méthode: </strong>
                            {cart.paymentMethod}
                        </ListGroup.Item> */}
                        <ListGroup.Item>
                            <h2>Vos articles</h2>
                            {cartItems && cartItems.length === 0 ? (
                                <Message>Votre panier est vide</Message>
                            ) : (
                                <ListGroup variant='flush'>
                                    {cartItems.map((item, index) => (
                                        <ListGroup.Item key={index}>
                                            <Row>
                                                <Col md={1}>
                                                    <Image src={item.product.device_image
                                                    } alt={item.product.name} fluid rounded />
                                                </Col>
                                                <Col>
                                                    <Link
                                                        to={item.imei ? `/imei-products/${item.product.id}/` : `/products/${item.product.id}/`}
                                                        state={{
                                                            p: item.imei ?
                                                                products.filter(product => product.id === item.product.id)[0] :
                                                                productsNonImei.filter(product => product.id === item.product.id)[0]
                                                        }}
                                                    >
                                                        {item.product.name}
                                                    </Link>
                                                </Col>
                                                <Col md={4}>
                                                    {item.qty} x {item.product.price} € = {item.qty * item.product.price} €
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    ))}

                                </ListGroup>
                            )}
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col md={4}>
                    <Card>
                        <ListGroup variant='flush'>
                            <ListGroup.Item>
                                <h2>Récapitulatif de la commande</h2>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col>Articles</Col>
                                    <Col>{itemsPrice} €</Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col>Frais de livraison</Col>
                                    <Col>{cart.shippingPrice} €</Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col>Taxes</Col>
                                    <Col>{taxPrice} €</Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col>Total</Col>
                                    <Col>{totalPrice} €</Col>
                                </Row>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                {error && <Message variant='danger'>{error}</Message>}
                            </ListGroup.Item>
                            {
                                products && productsNonImei && (products.length > 0 || productsNonImei.length > 0) ?
                                    <ListGroup.Item>
                                        <Row className='placeorder-btn-row'>
                                            <Col className='placeorder-btn-col' md={{ span: 8, offset: 6 }}>
                                                <StripeCheckout
                                                    stripeKey="pk_test_51MqGSbGctuY4OITZCdwC6OQ1RXYsm0zzeqUrlLb0mq9mc9VHMbCVIWpGm3QnZQNy4bdHiSgdm4twSh0B19bE5nlg00YE7Bvmv9"
                                                    token={handleToken}
                                                    amount={totalPrice * 100}
                                                    label="Passer la commande"
                                                    name="Olinn Mobile"
                                                />
                                            </Col>
                                        </Row>

                                    </ListGroup.Item>
                                    : null
                            }

                        </ListGroup>
                    </Card>
                </Col>
            </Row>
            <Modal
                isOpen={modalFinaleIsOpen}
                onAfterOpen={afterOpenFinaleModal}
                onRequestClose={closeFinaleModal}
                style={customStyles}
            >
                <Row className='aftersalenew-finalmodal-title'>
                    <Col className='aftersalenew-finalmodal-title-col' md={1}>
                        <i class="fa-regular fa-paper-plane fa-4x"></i>
                    </Col>
                </Row>
                <Row className='aftersalenew-modal-conditions'>
                    <span>{sentText}</span>
                </Row>
                <Row className='aftersalenew-modal-conditions'>
                    <span>{followText}</span>
                </Row>
                <Row className='aftersalenew-modal-button'>
                    <Col>
                        <button
                            className='aftersale-modal-btn-close'
                            type='button'
                            disabled={false}
                            onClick={closeFinaleModal}
                        >
                            Fermer
                        </button>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default PlaceOrder