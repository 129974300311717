import React, { useState } from "react";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import { Modal } from 'react-bootstrap'

import 'moment/locale/fr'
import originalMoment from "moment";
import { extendMoment } from "moment-range";

import { Row, Col, Container, ListGroup } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faRotate,
    faCalendar,
}
    from '@fortawesome/free-solid-svg-icons';

import './DateRange.css';
import { useTranslation } from 'react-i18next';

const moment = extendMoment(originalMoment);

function DateRangeComponent(props) {
    const today = moment();
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState(moment.range(today.clone().subtract(7, "days"), today.clone()));
    const { t, i18n } = useTranslation();

    const onSelect = (value) => {
        setValue(value);
        setIsOpen(false); // Close the modal after a date range is selected
        props.onEvent({ type: props.type, value: value });
    };

    const onToggle = () => {
        setIsOpen(!isOpen);
    };

    const Reset = () => {
        let newValue = { start: moment(props.range[0]), end: moment(props.range[1]) }
        setValue(newValue)
        props.onEvent({ type: props.type, value: newValue });
    };

    const renderSelectionValue = () => {
        return (
            <Row className='daterange-selection-row'>
                <Col md={6}>
                    {t('from') + ' ' + value.start.format("DD/MM/YYYY")}
                </Col>
                <Col md={6}>
                    {t('to') + ' ' + value.end.format("DD/MM/YYYY")}
                </Col>
            </Row>
        );
    };

    return (
        <>
            {renderSelectionValue()}

            <Row className="daterange-btn-row">
                <Col md={2}>
                    <button
                        id='btnToggle'
                        className="daterange-btn-toggle"
                        onClick={onToggle}>
                        <FontAwesomeIcon icon={faCalendar} />
                    </button>
                </Col>

                <Col md={2}>
                    <button
                        id='btnReset'
                        className='daterange-btn-reset'
                        onClick={Reset}>
                        <FontAwesomeIcon icon={faRotate} />
                    </button>
                </Col>
            </Row>

            <Modal show={isOpen} onHide={onToggle} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Choisissez une plage de dates</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DateRangePicker
                        className='daterange-picker'
                        value={props.value}
                        onSelect={onSelect}
                        singleDateRange={true}
                        firstOfWeek={1}
                        locale="fr"
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}


export default DateRangeComponent;