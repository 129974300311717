import React from 'react';
import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

const UserRoles = ({ history }) => {
  const HighlightedTableRow = styled(TableRow)({
    backgroundColor: '#CCFAD2', // Couleur de fond de votre choix pour mettre en valeur la ligne
  });

  const { t, i18n } = useTranslation();

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{t('Function information')}</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('Date')}</TableCell>
                <TableCell>{t('Role')}</TableCell>
                <TableCell>{t('Previous role')}</TableCell>
                <TableCell>{t('Updated by')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((h, index) => (
                h === history[history.length - 1] ?
                  <HighlightedTableRow key={index}>
                    <TableCell>{h.created_at ? new Date(h.created_at).toLocaleDateString("fr-FR") : null}</TableCell>
                    <TableCell>{h.new_value ? h.new_value : null}</TableCell>
                    <TableCell>{h.old_value ? h.old_value : null}</TableCell>
                    <TableCell>{h.updated_by ? h.updated_by.name : null}</TableCell>
                  </HighlightedTableRow>
                  :
                  <TableRow key={index}>
                    <TableCell>{h.created_at ? new Date(h.created_at).toLocaleDateString("fr-FR") : null}</TableCell>
                    <TableCell>{h.new_value ? h.new_value : null}</TableCell>
                    <TableCell>{h.old_value ? h.old_value : null}</TableCell>
                    <TableCell>{h.updated_by ? h.updated_by.name : null}</TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default UserRoles;

