import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, ListGroup } from 'react-bootstrap'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import ReactTooltip from 'react-tooltip';

import Loader from '../../components/Loader/Loader'
import { listNonImeiProducts, createNonImeiProduct, updateNonImeiProduct } from '../../actions/productActions'
import { listCategories } from '../../actions/categoriesActions'
import { listHeaderCategories } from '../../actions/headerCategoriesActions';

import { toArrayKeys, toArrayValues, findVal, removeDuplicatesByProperties } from "../../utils/utils";

import Product from '../../components/Product/Product';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import './Products.css';
import { useTranslation } from 'react-i18next';

function ProductsNonImei() {
    const dispatch = useDispatch()
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const [priceOpen, setPriceOpen] = useState(false);
    const [brandOpen, setBrandOpen] = useState(false);
    const [specOpen, setSpecOpen] = useState([]);

    const searchParams = new URLSearchParams(location.search);
    const categorieParam = searchParams.get('categorie');
    const headerCategorieParam = searchParams.get('headerCategorie');
    const param = searchParams.get('param');
    const paramType = searchParams.get('paramType');

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const productNonImeiList = useSelector(state => state.productNonImeiList)
    const { loading, productsNonImei } = productNonImeiList

    const categoriesList = useSelector(state => state.categoriesList)
    const { categories } = categoriesList

    const headerCategoriesList = useSelector((state) => state.headerCategoriesList);
    const { loading: loadingHeaderCategories, error: errorHeaderCategories, headerCategories } = headerCategoriesList;

    const [categorie, setCategorie] = useState(null);
    const [headerCategorie, setHeaderCategorie] = useState(null);
    const [categorieId, setCategorieId] = useState(0);
    const [headerCategorieId, setHeaderCategorieId] = useState(0);

    const [productsData, setProductsData] = useState([]);
    const [filteredProductData, setFilteredProductData] = useState([]);

    const [filterItemObj, setFilterItemObj] = useState({});
    const [specFilters, setSpecFilters] = useState([]);

    const [filters, setFilters] = useState([]);

    const searchKeys = ['brand', 'model', 'name',]

    const [priceRangeObj, setPriceRangeObj] = useState([]); // à voir pour mettre les prix min et max des produits
    const [priceRange, setPriceRange] = useState([]);

    const [downloadCatalog, setDownloadCatalog] = useState(false);


    useEffect(() => {
        setProductsData([])
        setFilteredProductData([])
        setCategorie(null)
        setHeaderCategorie(null)
        if (!productsNonImei || productsNonImei.length === 0)
            dispatch(listNonImeiProducts(userInfo ? userInfo.id_odoo : -1));
        if (!loadingHeaderCategories && (!headerCategories || headerCategories.length === 0))
            dispatch(listHeaderCategories());

        dispatch(listCategories());
    }, [])

    useEffect(() => {
        if (categorieParam || headerCategorieParam) {
            const urlSearchParams = new URLSearchParams(location.search);
            const categorieId = urlSearchParams.get("categorie");
            const headerCategorieId = urlSearchParams.get("headerCategorie");
            setCategorieId(categorieId);
            setHeaderCategorieId(headerCategorieId);
        }
    }, [categorieParam, headerCategorieParam, categories])

    useEffect(() => {
        if (categorieId && categories && categories.length > 0) {
            let categorie = categories.find(categorie => categorie.id === parseInt(categorieId))
            setCategorie(categorie)
        }

        if (headerCategorieId && headerCategories && headerCategories.length > 0) {
            let headerCategorie = headerCategories.find(headerCategorie => headerCategorie.id === parseInt(headerCategorieId))
            setHeaderCategorie(headerCategorie)
        }
    }, [categorieId, headerCategorieId, categories])


    useEffect(() => {
        if (categorie || headerCategorie) {
            let productArr = getProductData();
            setProductsData(productArr);
        }
    }, [location.search, categorie, headerCategorie]);

    useEffect(() => {
        if (specFilters && specFilters.length > 0) {
            setFilters([
                ...specFilters.map(item => ({ name: item.spec_name, values: [] })),
                { name: 'brand', values: [] }
            ]);
        }
    }, [specFilters])

    useEffect(() => {
        let arr = []
        let _filters = []

        if (filters && filters.length > 0)
            _filters = [...filters.filter(item => item.values.length > 0)]

        let productsDataFromRange = []
        let productsDataFromBrand = []

        if (priceRangeObj[0] > priceRange[0] || priceRangeObj[1] < priceRange[1]) {
            productsDataFromRange = (filteredProductData.length > 0 ? filteredProductData : productsData)
                .filter((item) => {
                    return item.price >= priceRangeObj[0] && item.price <= priceRangeObj[1];
                });
        }
        else
            productsDataFromRange = productsData


        let filterBrand = _filters.filter((f) => f.name === 'brand').length > 0 ? _filters.filter((f) => f.name === 'brand')[0].values : []
        if (filterBrand.length > 0) {
            productsDataFromBrand = productsDataFromRange.filter((item) => {
                return filterBrand.filter((brand) => brand === item.brand).length > 0;
            });
        }
        else
            productsDataFromBrand = productsDataFromRange

        productsDataFromBrand.forEach((product) => {
            const specsMatched = product.specifications.filter((spec) => {
                const filter = _filters.filter((f) => f.name === spec.spec_name)
                if (filter && filter.length > 0) {
                    return filter[0].values.includes(spec.spec_value)
                }
                else
                    return true
            })
            if (specsMatched.length === product.specifications.length) {
                arr.push(product)
            }
        })

        setFilteredProductData([...arr])

    }, [filters, priceRangeObj])

    useEffect(() => {
        if (productsData && productsData.length > 0) {
            setFilteredProductData(productsData)
            getSpecFilters();
            let arr = [
                Math.min(...productsData.map(x => x.price)),
                Math.max(...productsData.map(x => x.price))
            ]
            setPriceRange([...arr])
            setDownloadCatalog(t('Download catalog') + ` ${productsData[0].categorie_odoo} ` + t('in excel format'))
        }
    }, [productsData])

    useEffect(() => {
        setPriceRangeObj(priceRange)
    }, [priceRange])

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        if (string.length === 0) {
            setFilteredProductData(productsData);
        }
    };

    const handleOnSelect = (item) => {
        // the item selected
        setFilteredProductData(productsData.filter((product) => product.name === item.name));
    };

    const handleOnFocus = () => {

    };

    const formatResult = (item) => {
        return (item.brand + ' ' + item.name);
    };

    const SortProducts = (event) => {
        const sortType = event.target.value;
        if (sortType === "relevance") {
            const sortedProductData = productsData;
            setFilteredProductData(sortedProductData);
        }
        else {
            const sortedProductData = [...filteredProductData];
            if (sortType === "price_asc") {
                sortedProductData.sort((a, b) => a.price - b.price);
            } else if (sortType === "price_desc") {
                sortedProductData.sort((a, b) => b.price - a.price);
            } else if (sortType === "name_asc") {
                sortedProductData.sort((a, b) => ((a.brand + a.name).localeCompare(b.brand + b.name)));
            }
            setFilteredProductData(sortedProductData);
        }
    };

    const onChangeHandler = (event, label) => {
        const { value, checked } = event.target;
        

        let _filters = filters && filters.length > 0 ? [...filters] : [];

        if (_filters && _filters.length > 0) {
            const filterIndex = _filters.findIndex(item => item.name === label);
            if (filterIndex > -1) {
                if (checked) {
                    _filters[filterIndex].values.push(value);
                } else {
                    const valueIndex = _filters[filterIndex].values.findIndex(item => item === value);
                    if (valueIndex > -1) {
                        _filters[filterIndex].values.splice(valueIndex, 1);
                    }
                }
            }
        }
        else {
            _filters.push({ name: label, values: [value] })
        }
        
        setFilters([..._filters]);
    };

    const callCheckboxData = (label, data, index) => {
        return (
            <Row key={index}>
                <Col className="col-sm-2">
                    <input
                        type="checkbox"
                        id={data}
                        name={data}
                        value={data}
                        onClick={(event) => event.stopPropagation()} // Stop propagation on click event
                        onChange={(event) => onChangeHandler(event, label)}
                    />
                </Col>
                <Col className="col-sm-10">
                    <label className="filter-checkbox-label" htmlFor={data}>{data}</label>
                </Col>
            </Row>
        );
    };

    const callPriceRange = (value) => {
        const min = value[0];
        const max = value[1];
        setPriceRangeObj([min, max]);
    };

    const callBrand = () => {
        let label = "brand";
        let brandObj = removeDuplicatesByProperties(productsData, label).map((product) => product.brand);

        return toArrayValues(brandObj).map((data, index) =>
            callCheckboxData(label, data, index)
        );
    };

    const callFilterGenerator = (value) => {
        const label = value.spec_name;

        return toArrayValues(value.spec_values).map((data, index) =>
            callCheckboxData(label, data, index)
        );
    }

    const onHandleCategorySelectChange = (value) => {
        setCategorie(categories.filter(category => category.id === value)[0])
    }

    function getSpecFilters() {
        let specFiltersNames = [];
        let specFitlersWithValues = [];
        let _specFilters = [];

        productsData.map(product => {
            product.specifications.map(spec => {
                if (spec.is_filter) {
                    specFiltersNames.push(spec.spec_name)
                    specFitlersWithValues.push({ spec_name: spec.spec_name, spec_value: spec.spec_value })
                }
            })
        })

        const uniqueSpecFilters = specFitlersWithValues.reduce((acc, cur) => {
            if (!acc.find(item => item.spec_name === cur.spec_name && item.spec_value === cur.spec_value)) {
                acc.push(cur);
            }
            return acc;
        }, []);

        let sortedSpecFilters = uniqueSpecFilters.sort((a, b) => {
            if (a.spec_name < b.spec_name) {
                return -1;
            }
            if (a.spec_name > b.spec_name) {
                return 1;
            }
            return 0;
        });

        let odl_spec_name = '';
        let newObj = {};
        let tabObj = [];
        sortedSpecFilters.map(spec => {
            if (odl_spec_name !== spec.spec_name || spec === sortedSpecFilters[0]) {
                if (spec !== sortedSpecFilters[0])
                    _specFilters.push(newObj)

                newObj = {
                    spec_name: spec.spec_name,
                    spec_values: [spec.spec_value]
                }
            } else {
                newObj['spec_values'].push(spec.spec_value)
            }

            odl_spec_name = spec.spec_name
        })

        let _specOpen = [];
        _specFilters.forEach(element => {
            _specOpen.push(false)
        });

        setSpecOpen([..._specOpen]);

        setSpecFilters(_specFilters);
    }

    function getProductData() {
        let arr = [];

        //TODO : Gérer la question des garanties
        productsNonImei.forEach(product => {
            if (categorie) {
                if (product.categorie_odoo === categorie.name) {

                    if (paramType === 'brand') {
                        if (product.brand === param) {
                            arr.push(product)
                        }
                    }
                    else {
                        arr.push(product)
                    }
                }
            }
            else if (headerCategorie) {
                let categorie_list = headerCategorie.categories.map(category => categories.find(categorie => categorie.id === category))
                if (categorie_list.length > 0) {

                    categorie_list.forEach(category => {
                        if (category != undefined && product.categorie_odoo === category.name) {
                            arr.push(product)
                        }
                    })
                }
            }
        });

        return arr;
    }

    function togglePriceOpen() {
        setPriceOpen(!priceOpen);
    }

    function toggleBrandOpen() {
        setBrandOpen(!brandOpen);
    }

    function toggleSpecOpen(index) {
        let _specOpen = [...specOpen];
        _specOpen[index] = !_specOpen[index];
        setSpecOpen([..._specOpen]);
    }

    function DownloadCatalogue() {
        // Liste des champs que vous voulez inclure dans le catalogue Excel
        const fieldsToInclude = ['brand', 'model', 'name'];
        const lastFieldsToInclude = ['device_image', 'price'];

        // Charger la bibliothèque xlsx
        const XLSX = require('xlsx');

        // Filtrer les données pour inclure uniquement les champs spécifiés
        const filteredData = filteredProductData.map(item => {
            const filteredItem = {};
            for (const field of fieldsToInclude) {
                filteredItem[field] = item[field];
            }
            for (const field of item.specifications.sort((a, b) => a.spec_name.localeCompare(b.spec_name))) {
                filteredItem[field.spec_name] = field.spec_value;
            }
            for (const field of lastFieldsToInclude) {
                filteredItem[field] = item[field];
            }
            return filteredItem;
        });

        // Créer un nouveau classeur
        const wb = XLSX.utils.book_new();

        // Convertir les données filtrées en un tableau de feuilles de calcul
        const ws = XLSX.utils.json_to_sheet(filteredData);

        // Ajouter la feuille de calcul au classeur
        XLSX.utils.book_append_sheet(wb, ws, "Produits");

        // Générer un fichier Excel binaire à partir du classeur
        const excelFile = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        // Convertir le fichier Excel binaire en un objet Blob
        const excelBlob = new Blob([s2ab(excelFile)], { type: 'application/octet-stream' });

        // Créer un lien de téléchargement pour le fichier Excel
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(excelBlob);
        downloadLink.download = `catalogue_${filteredProductData[0].categorie_odoo}.xlsx`;
        downloadLink.click();
    }

    // Fonction pour convertir une chaîne binaire en tableau d'octets
    function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }

    return (
        <>
            {loading ? <Loader /> :
                <Container className='products-catalogue'>
                    <Row className='products-main-row'>
                        <Col md={3}>
                            <ListGroup className="product-filters">
                                <ListGroup.Item onClick={togglePriceOpen}>
                                    <Row>
                                        <Col md={8}>
                                            <h5>{t('Price')}</h5>
                                        </Col>
                                        <Col md={4}>
                                            <i className={`fa-solid fa-chevron-right ${priceOpen ? 'rotate-90' : ''}`}></i>
                                        </Col>
                                    </Row>
                                    <Row className={`${!priceOpen ? 'filter-collapsed' : 'filter-expanded'}`}>
                                        {
                                            <div className="range-slider-block">
                                                <Row>
                                                    <Col className="col-sm-6">
                                                        <div className="priceRangeMin">{priceRangeObj[0] ? priceRangeObj[0] : priceRange[0]} €</div>
                                                    </Col>
                                                    <Col className="col-sm-6">
                                                        <div className="priceRangeMax">{priceRangeObj[1] ? priceRangeObj[1] : priceRange[1]} €</div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <RangeSlider
                                                        className="range-slider"
                                                        min={priceRange[0]}
                                                        max={priceRange[1]}
                                                        step={1}
                                                        value={[priceRangeObj[0], priceRangeObj[1]]}
                                                        onInput={value => callPriceRange(value)}
                                                    />
                                                </Row>
                                            </div>
                                        }
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item className='filter-brand' onClick={toggleBrandOpen}>
                                    <Row>
                                        <Col md={8}>
                                            <h5>{t('brand')}</h5>
                                        </Col>
                                        <Col md={4}>
                                            <i className={`fa-solid fa-chevron-right ${brandOpen ? 'rotate-90' : ''}`}></i>
                                        </Col>
                                    </Row>
                                    <Row className={`${!brandOpen ? 'filter-collapsed' : 'filter-expanded'}`}>
                                        {callBrand()}
                                    </Row>
                                </ListGroup.Item>
                                {
                                    specFilters && specFilters.length > 0 ?
                                        specFilters.map((spec, index) => {
                                            return (
                                                <ListGroup.Item className='filter-brand' onClick={() => toggleSpecOpen(index)}>
                                                    <Row>
                                                        <Col md={8}>
                                                            <h5>{spec.spec_name}</h5>
                                                        </Col>
                                                        <Col md={4}>
                                                            <i className={`fa-solid fa-chevron-right ${specOpen[index] ? 'rotate-90' : ''}`}></i>
                                                        </Col>
                                                    </Row>
                                                    <Row className={`${!specOpen[index] ? 'filter-collapsed' : 'filter-expanded'}`}>
                                                        {callFilterGenerator(spec)}
                                                    </Row>
                                                </ListGroup.Item>
                                            )
                                        }
                                        ) : null
                                }
                            </ListGroup>

                        </Col>
                        <Col md={9}>
                            <Row className="sort-row">
                                <Col md={4}>
                                    <ReactSearchAutocomplete
                                        items={removeDuplicatesByProperties(productsData, 'name')}
                                        fuseOptions={{ keys: searchKeys }}
                                        onSearch={handleOnSearch}
                                        onSelect={handleOnSelect}
                                        onFocus={handleOnFocus}
                                        autoFocus
                                        formatResult={formatResult}
                                        threshold={0.3} // 0 = exact match, 1 = everything matches
                                        ignoreLocation={true}
                                        styling={{
                                            height: "20%",
                                            border: "1px solid #dfe1e5",
                                            borderRadius: "5px",
                                            backgroundColor: "white",
                                            boxShadow: "rgba(58, 53, 53, 0.2) 0px 1px 6px 0px",
                                            hoverBackgroundColor: "#eee",
                                            color: "#212121",
                                            fontSize: "16px",
                                            fontFamily: "Arial",
                                            iconColor: "grey",
                                            lineColor: "rgb(232, 234, 237)",
                                            placeholderColor: "grey",
                                            clearIconMargin: '0 5px 0 0',
                                            searchIconMargin: '0 0 0 5px',
                                            zIndex: 2,
                                        }}
                                    />
                                </Col>
                                <Col md={{ span: 2, offset: 2 }}>
                                    <h3 className="sort-title">Trier par :</h3>
                                </Col>
                                <Col md={3}>
                                    <select className="form-select" onChange={(event) => SortProducts(event)}>
                                        <option value="relevance">{t('Relevance')}</option>
                                        <option value="price_asc">{t('Ascending price')} 🔺</option>
                                        <option value="price_desc">{t('Decreasing price')} 🔻</option>
                                        <option value="name_asc">{t('Name')} A-Z 🔺</option>
                                        <option value="name_desc">{t('Name')} Z-A 🔻</option>
                                    </select>
                                </Col>
                                <Col md={1}>
                                    <button
                                        className='products-squared-btn products-excel-btn'
                                        onClick={DownloadCatalogue}><i data-tip="React-tooltip" data-for={'tooltip'}
                                            class="fa-solid fa-file-excel fa-2x"></i></button>
                                    <ReactTooltip id={'tooltip'}
                                        type="dark"
                                        place="bottom"
                                        effect="solid"
                                        clickable={true}
                                        getContent={function () {
                                            return (
                                                <div>
                                                    <Row>{downloadCatalog}</Row>
                                                </div>
                                            )
                                        }} />
                                </Col>
                            </Row>
                            <Row className="product-row" lg='auto' md='auto' sm='auto' xl='auto' xs='auto' xxl='auto'>
                                {(filteredProductData).map(
                                    (product, index) => (
                                        <Col className='product-col' key={product._id} >
                                            <Product product={product} />
                                        </Col>
                                    )
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    )
}

export default ProductsNonImei