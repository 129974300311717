import axios from "axios";

import {
    DEFAULT_IMAGE_LIST_REQUEST,
    DEFAULT_IMAGE_LIST_SUCCESS,
    DEFAULT_IMAGE_LIST_FAIL,

    DEFAULT_IMAGE_CREATE_REQUEST,
    DEFAULT_IMAGE_CREATE_SUCCESS,
    DEFAULT_IMAGE_CREATE_FAIL,

    DEFAULT_IMAGE_UPDATE_REQUEST,
    DEFAULT_IMAGE_UPDATE_SUCCESS,
    DEFAULT_IMAGE_UPDATE_FAIL,

    DEFAULT_NON_IMEI_IMAGE_LIST_REQUEST,
    DEFAULT_NON_IMEI_IMAGE_LIST_SUCCESS,
    DEFAULT_NON_IMEI_IMAGE_LIST_FAIL,

    DEFAULT_NON_IMEI_IMAGE_CREATE_REQUEST,
    DEFAULT_NON_IMEI_IMAGE_CREATE_SUCCESS,
    DEFAULT_NON_IMEI_IMAGE_CREATE_FAIL,

    DEFAULT_NON_IMEI_IMAGE_UPDATE_REQUEST,
    DEFAULT_NON_IMEI_IMAGE_UPDATE_SUCCESS,
    DEFAULT_NON_IMEI_IMAGE_UPDATE_FAIL,

    DEFAULT_ALL_NON_IMEI_IMAGE_LIST_REQUEST,
    DEFAULT_ALL_NON_IMEI_IMAGE_LIST_SUCCESS,
    DEFAULT_ALL_NON_IMEI_IMAGE_LIST_FAIL,
}
    from '../constants/defaultImagesConstants.js'

export const listDefaultImages = (tac) => async (dispatch, getState) => {
    try {
        dispatch({ type: DEFAULT_IMAGE_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const params = {
            tac: tac,
        };

        const { data } = await instance.get(
            '/api/default-images', { params }
            )

        dispatch({ type: DEFAULT_IMAGE_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: DEFAULT_IMAGE_LIST_FAIL, payload: error.response && error.response.data.message ? error.response.data.message : error.message })
    }
}

export const createDefaultImage = (tac, color, image) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DEFAULT_IMAGE_CREATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/default-images/add/`,
            { 'tac': tac, 'color': color, 'image': image },
            )

        dispatch({
            type: DEFAULT_IMAGE_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: DEFAULT_IMAGE_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateDefaultImage = (tac, color, image) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DEFAULT_IMAGE_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.put(
            `/api/default-images/update/`,
            { 'tac': tac, 'color': color, 'image': image },
            )

        dispatch({
            type: DEFAULT_IMAGE_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: DEFAULT_IMAGE_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}


export const listAllDefaultNonImeiImages = () => async (dispatch, getState) => {
    try {
        dispatch({ type: DEFAULT_NON_IMEI_IMAGE_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            '/api/default-images/all-non-imei/'
            )

        dispatch({ type: DEFAULT_NON_IMEI_IMAGE_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: DEFAULT_NON_IMEI_IMAGE_LIST_FAIL, payload: error.response && error.response.data.message ? error.response.data.message : error.message })
    }
}



export const listDefaultNonImeiImages = (brand, model, name) => async (dispatch, getState) => {
    try {
        dispatch({ type: DEFAULT_NON_IMEI_IMAGE_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const params = {
            brand: brand,
            model: model,
            name: name,
        };

        const { data } = await instance.get(
            '/api/default-images/non-imei/', { params }
            )

        dispatch({ type: DEFAULT_NON_IMEI_IMAGE_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: DEFAULT_NON_IMEI_IMAGE_LIST_FAIL, payload: error.response && error.response.data.message ? error.response.data.message : error.message })
    }
}

export const createDefaultNonImeiImage = (brand, model, name, image) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DEFAULT_NON_IMEI_IMAGE_CREATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/default-images/non-imei/add/`,
            { 'brand': brand, 'model': model, 'name': name, 'image': image },
            )

        dispatch({
            type: DEFAULT_NON_IMEI_IMAGE_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: DEFAULT_NON_IMEI_IMAGE_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateDefaultNonImeiImage = (brand, model, name, image) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DEFAULT_NON_IMEI_IMAGE_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.put(
            `/api/default-images/non-imei/update/`,
            { 'brand': brand, 'model': model, 'name': name, 'image': image },
            )

        dispatch({
            type: DEFAULT_NON_IMEI_IMAGE_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: DEFAULT_NON_IMEI_IMAGE_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}