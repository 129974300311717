import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';

import { Row, Col, Container, Card, Button, Form } from 'react-bootstrap'

import { addToCart } from '../../actions/cartActions';
import Gallery from '../../components/Gallery/Gallery';
import Stock from '../../components/Stock/Stock';
import "react-image-gallery/styles/css/image-gallery.css";
import { removeDuplicates, removeDuplicatesByProperties } from '../../utils/utils'

import './ProductDetails.css'
import Loader from '../../components/Loader/Loader'
import FeaturesNonImei from '../../components/Features/FeaturesNonImei';
import { detailsProduct, detailsNonImeiProduct } from '../../actions/productActions'

import { listNonImeiImage } from '../../actions/imageActions';
import { listDefaultNonImeiImages } from '../../actions/defaultImagesActions';
import { listPurchases } from '../../actions/purchaseActions';
import { listAllNonImeiImage } from '../../actions/imageActions';
import { listAllDefaultNonImeiImages } from '../../actions/defaultImagesActions';
import { listCategories } from '../../actions/categoriesActions';
import { listAccessories } from '../../actions/accessoriesActions';

import { getOdooStock } from '../../actions/stockActions';
import { getWarranty } from '../../actions/warrantyActions';
import { useTranslation } from 'react-i18next';

function ProductNonImeiDetails(props) {

  const location = useLocation();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const imei = false;

  const product = location.state.p;

  const [qty, setQty] = useState(1);
  const [imagesUrl, setImagesUrl] = useState([])
  const [galleryUrls, setGalleryUrls] = useState([])

  const [modalIsOpen, setIsOpen] = useState(false);
  const [purchaseModalIsOpen, setPurchaseModalIsOpen] = useState(false);

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const purchaseList = useSelector(state => state.purchaseList)
  const { purchases, success: successPurchaseList, error: errorPurchaseList } = purchaseList

  const imageNonImeiList = useSelector(state => state.imageNonImeiList)
  const { imagesNonImei, loading } = imageNonImeiList

  const defaultImageList = useSelector(state => state.defaultImageList)
  const { defaultImages } = defaultImageList

  const odooStock = useSelector(state => state.odooStock)
  const { stock } = odooStock

  const warrantyGet = useSelector(state => state.warrantyGet)
  const { warranty } = warrantyGet

  const productNonImeiDetails = useSelector((state) => state.productNonImeiDetails)
  const { nonImeiProduct } = productNonImeiDetails

  const [purchaseProducts, setPurchaseProducts] = useState([])
  const [purchaseProductsNonImei, setPurchaseProductsNonImei] = useState([])
  const [productCategorie, setProductCategorie] = useState(null)

  const imageAllNonImeiList = useSelector(state => state.imageAllNonImeiList)
  const { allImagesNonImei } = imageAllNonImeiList

  const defaultNonImeiImageList = useSelector(state => state.defaultNonImeiImageList)
  const { defaultNonImeiImages } = defaultNonImeiImageList

  const categoriesList = useSelector(state => state.categoriesList)
  const { loading: loadingCategories, error: errorCategories, categories } = categoriesList

  const accessoriesList = useSelector(state => state.accessoriesList)
  const { loading: loadingAccessories, error: errorAccessories, accessories, success: successAccessoriesList } = accessoriesList

  const [selectedProducts, setSelectedProducts] = useState([]);

  const alertQty = t('The quantity requested is greater than our current inventory. As a result, the processing of your request could take longer than the announced deadlines.');


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      height: '50%',
      zIndex: 100,
      position: 'fixed'
    },
  };

  useEffect(() => {
    dispatch(listAllNonImeiImage())
    setPurchaseProducts([])

    if (product) {
      dispatch(listAccessories(product.id, product.is_imei))
      dispatch(listPurchases(product.id))
      dispatch(listNonImeiImage(product.brand, product.model, product.name))
      dispatch(getOdooStock(product.id_odoo))
      dispatch(listCategories())
      //dispatch(getWarranty(product.id_odoo))
    }
  }, [])

  useEffect(() => {
    if (categories && categories.length > 0) {

      setProductCategorie(categories.filter(c => product.categories.includes(c.id))[0])
    }

  }, [categories])

  useEffect(() => {
    if (allImagesNonImei && allImagesNonImei.length > 0) {
      dispatch(listAllDefaultNonImeiImages())
    }
  }, [allImagesNonImei])

  useEffect(() => {
    dispatch(listDefaultNonImeiImages(product.brand, product.model, product.name))
  }, [imagesNonImei])

  useEffect(() => {
    if (imagesNonImei && imagesNonImei.length > 0) {


      setGalleryUrls(imagesNonImei.map(i => i.url))
    }
    else {


      setGalleryUrls([product.device_image])
    }
  }, [imagesUrl])

  useEffect(() => {
    let products_to_recommend = []
    
    
    if (successPurchaseList && successAccessoriesList) {
      let products = purchases.products
      let accessories_to_recommend = accessories ? accessories.productsNonImei : []

      
      

      let productsNonImei = [...accessories_to_recommend, ...purchases.productsNonImei]

      

      products = products.filter(p => p.id != product.id)

      if (productsNonImei.length > 5) {
        productsNonImei = productsNonImei.slice(0, 5)
        products_to_recommend = productsNonImei
      }
      else if (productsNonImei.length < 5 && products.length > 0) {
        products_to_recommend = [...productsNonImei, ...products.slice(0, 5 - productsNonImei.length)]
      }
      else if (productsNonImei.length === 0 && products.length > 0) {
        products_to_recommend = products.slice(0, 5)
      }
      else
        products_to_recommend = productsNonImei

      

      setPurchaseProducts(products_to_recommend)
    }
  }, [successPurchaseList, successAccessoriesList])

  let navigate = useNavigate();
  let subtitle;

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const returnToProducts = () => {
    navigate('/products');
  }

  const listPurchaseHandler = () => {
    if (purchaseProducts && purchaseProducts.length > 0) {
      setPurchaseModalIsOpen(true)
    }
    else {
      addToCartHandler()
    }
  }

  const addToCartHandler = () => {
    selectedProducts.map(p => {
      dispatch(addToCart(p, p.imei, qty))
    })
    dispatch(addToCart(product, imei, [], qty))
    navigate(`/cart`);
  }

  function afterOpenPurchaseModal() {
    subtitle.style.color = '#f00';
  }

  function closePurchaseModal() {
    setPurchaseModalIsOpen(false);
  }

  const handleCheckboxChange = (product, checked, imei) => {
    product.imei = imei;
    if (checked) {
      setSelectedProducts(prevProducts => [...prevProducts, product]);
    } else {
      setSelectedProducts(prevProducts => prevProducts.filter(p => p.id !== product.id))
    }
  };

  function getProductNonImeiImage(product) {
    let device_image = product.device_image

    if (defaultNonImeiImages && defaultNonImeiImages.length > 0) {
      let defaultImage = defaultNonImeiImages.filter(e => e.brand == product.brand && e.model == product.model && e.name == product.name).length > 0 ?
        defaultNonImeiImages.filter(e => e.brand == product.brand && e.model == product.model && e.name == product.name)[0].image
        : null

      product.device_image = defaultImage !== null && allImagesNonImei && allImagesNonImei.length > 0 ?
        allImagesNonImei.filter(d => d.name == defaultImage).length > 0 ? allImagesNonImei.filter(d => d.name == defaultImage)[0].url : ''
        : product.device_image;
    }

    return device_image;
  }

  return (
    <>
      {loading ? <Loader /> :
        <Row className='detail-main-row'>
          <Container className="detailproduct-wrapper">
            <Row className="product-denomination">
              {/* <Col md={2}>
              <button className='btn-return' onClick={returnToProducts}>Retour</button>
            </Col> */}
              <Col className='col-product-title'>
                <h5 className="product-title"><b>{product.brand} {product.name}</b></h5>
              </Col>
            </Row>
            <Row className="product-information">
              <Col className="card-image">
                {<Gallery urls={galleryUrls} />}
              </Col>
              <Col className="card-center">
                <Row className='references'>
                  {
                    product.refProduit && product.refProduit != '' ?
                      <div>{t('Manufacturer reference')} : {product.name}</div>
                      : null
                  }
                  {
                    product.name && product.name != '' ?
                      <div>{t('Reference Model')} : {product.model}</div>
                      : null
                  }
                </Row>
                {
                  warranty && (warranty.years || warranty.months) ? (
                    <Row className="warranty">
                      <div className='warranty-detail'><i className="fa-solid fa-check"></i>{t('Warranty')} {warranty.years} {t('years')}
                        {warranty.months > 0 ? <span> - {warranty.months} {t('months')}</span> : null}
                      </div>
                    </Row>
                  )
                    : null
                }
              </Col>
              <Col>
                <Card style={{ width: '18rem' }} className="cart-card">
                  <Row className="product-stock">
                    <Stock stock={stock.value} />
                  </Row>
                  <Card.Body>
                    <Card.Text>
                      <Row className='etat'>
                        <span>{t('State')} : {product.etat}</span>
                      </Row>
                      {
                        product.public_price != product.price ?
                          <Row className='public_price'>
                            <span>{t('public price')} : {product.public_price} € HT</span>
                          </Row>
                          : null
                      }
                      <Row className='price'>
                        <span>{product.price} € HT</span>
                      </Row>
                      {
                        product.rental_price && product.rental_price > 0 && product.is_leasable ?
                          <Row className='lease_price'>
                            <span>{t('or rental at')} {product.rental_price} {t('€ / month')}</span>
                          </Row>
                          : null
                      }
                      <Row>
                        <Col className='card-input'>
                          {!userInfo.isAdmin ?
                            <>
                              <input
                                disabled={product.stock == 0}
                                className='cart-quantity'
                                type="number"
                                defaultValue={product.stock == 0 ? 0 : 1}
                                min="1"
                                value={qty}
                                onChange={(e) => setQty(e.target.value)}
                              />


                              <button
                                className="btn-add-to-cart"
                                disabled={stock == 0}
                                onClick={listPurchaseHandler}><b>{t('ADD TO CART')}</b></button>
                            </>
                            :
                            null
                          }
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className='product-details-alert-qty'>
              {qty > product.stock ?
                <p className="p-rupture">{alertQty}</p>
                :
                null
              }
            </Row>
          </Container>
          <Container className="features-table">
            <FeaturesNonImei product={product} />
          </Container>
        </Row>
      }
      <Modal
        isOpen={purchaseModalIsOpen}
        onAfterOpen={afterOpenPurchaseModal}
        onRequestClose={closePurchaseModal}
        style={customStyles}
      >
        <h2 ref={_subtitle => (subtitle = _subtitle)}>{t('Our customers bought this with this item')}</h2>
        <Row className='purchase-card-row'>
          {
            purchaseProducts && purchaseProducts.length > 0 ?
              purchaseProducts.map((p, index) =>
                <Col md={2}>
                  <Card className='purchase-card'>
                    {
                      p.device_image ?
                        <Card.Img className='purchase-card-img'
                          src={p.device_image ? p.device_image : p.device_images[0]} variant='top' />
                        : null
                    }
                    <Card.Body>
                      <Card.Title as='div'>
                        <strong>{p.brand} {p.name}</strong>
                      </Card.Title>
                    </Card.Body>
                  </Card>
                  <Form.Check type="checkbox" label="Ajouter au panier" onChange={(e) => handleCheckboxChange(p, e.target.checked, true)} />
                </Col>
              )
              : null
          } 
        </Row>
        <Row className='purchase-continue-btn-row'>
          <Button className="purchase-continue-btn" onClick={() => { closePurchaseModal(); addToCartHandler(); }}>
            {t('Continue')}
          </Button>
        </Row>
      </Modal>
    </>
  )
}

export default ProductNonImeiDetails