import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { Row, Col, ListGroup, Image, Card, Container } from 'react-bootstrap'
import { addToCart, updateCartItemQty, removeFromCart, saveShippingAddress, saveShippingPrice, emptyCart } from '../../actions/cartActions'
import { createOrder, resetOrderCreate } from '../../actions/orderActions'

import Loader from '../../components/Loader/Loader'
import Modal from 'react-modal';

import useFileUpload from 'react-use-file-upload'
import ReactTooltip from 'react-tooltip';

import { listContactsOdoo, getContactOdoo } from '../../actions/contactOdooActions'
import { listProducts } from '../../actions/productActions';
import { listNonImeiProducts } from '../../actions/productActions';

import Message from '../../components/Message/Message'
import Select from '../../components/Select/Select'

import ups from '../../assets/images/ups.png';
import chronopost from '../../assets/images/chronopost.png';
import colissimo from '../../assets/images/colissimo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCartArrowDown
} from '@fortawesome/free-solid-svg-icons';

import './Cart.css';



function Cart() {

  const location = useLocation();
  const { t, i18n } = useTranslation();

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, success, loading: loadinOrderCreation } = orderCreate

  const productList = useSelector(state => state.productList)
  const { products } = productList

  const productNonImeiList = useSelector(state => state.productNonImeiList)
  const { productsNonImei } = productNonImeiList


  const [adressListForSelect, setAdressListForSelect] = useState([]);
  const productId = location.search ? Number(location.pathname.split('/')[location.pathname.split('/').length - 1]) : null;
  const imei = location.search ? location.search.split('=')[1] : null;
  const qty = location.search ? Number(location.search.split('=')[1]) : 1;

  const [deliveryEntity, setDeliveryEntity] = useState(null);
  const [billingEntity, setBillingEntity] = useState(null);

  const alertQty = 'La quantité demandée est supérieure à nos stocks actuels. En conséquence, le traitement de votre demande pourrait être plus long que les délais annoncés.';
  const notConnected = 'Vous devez être connecté pour effectuer cette action';
  const noAdress = 'Vous devez renseigner une adresse de livraison';
  const sentText = "Nous vous remercions pour votre commande. Veuillez noter que le tarif est indicatif et que des frais de livraison peuvent s'ajouter."
  const followText = "Un de nos collaborateurs prendra contact avec vous pour finaliser votre commande."
  const finalText = "Vous pouvez suivre l'avancement de votre commande dans votre espace : mes commandes."

  const sentQuotationText = "Nous vous remercions pour votre demande de devis."
  const followQuotationText = "Un de nos collaborateurs prendra contact avec vous pour finaliser votre devis."
  const finalQuotationText = "Vous pouvez suivre l'avancement de votre devis dans votre espace : mes devis."

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [modalIsOpen, setIsOpen] = useState(false);

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const { shippingAddress } = cart;
  const [modalFinaleIsOpen, setFinaleIsOpen] = React.useState(false);

  const [emptyAdress, setEmptyAdress] = useState(true);
  const [isQuotation, setIsQuotation] = useState(false);

  const [adress, setAdress] = useState(shippingAddress.adress);
  const [voie, setVoie] = useState(shippingAddress.voie);
  const [voie2, setVoie2] = useState(shippingAddress.voie2);
  const [codePostal, setCodePostal] = useState(shippingAddress.codePostal);
  const [ville, setVille] = useState(shippingAddress.ville);
  const [pays, setPays] = useState(shippingAddress.pays);
  const [shippingPrice, setShippingPrice] = useState(0);

  const [form, setForm] = useState({});

  const contactOdooList = useSelector((state) => state.contactOdooList)
  const { contactsOdoo, error } = contactOdooList

  const contactOdooGet = useSelector((state) => state.contactOdooGet)
  const { contactOdoo, loading } = contactOdooGet

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '30%',
    },
  };

  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile
  } = useFileUpload();

  const inputRef = useRef();

  useEffect(() => {
    if (!products || products.length === 0)
      dispatch(listProducts());
    if (!productsNonImei || productsNonImei.length === 0)
      dispatch(listNonImeiProducts(userInfo ? userInfo.id_odoo : -1));

    if (userInfo && userInfo.id_odoo) {
      dispatch(listContactsOdoo(userInfo.id_odoo));
      dispatch(getContactOdoo(userInfo.id_odoo));
    }
  }, [])

  useEffect(() => {
    if (userInfo && userInfo.id_odoo) {
      dispatch(listContactsOdoo(userInfo.id_odoo));
      dispatch(getContactOdoo(userInfo.id_odoo));
    }
  }, [userInfo])

  useEffect(() => {
    if (contactOdoo && contactsOdoo) {
      setDeliveryEntity(contactOdoo);
      fillAdressSelect();
    }

  }, [contactOdoo, contactsOdoo])

  useEffect(() => {
    if (success)
      dispatch(emptyCart())
  }, [success])

  useEffect(() => {
    if (success) {
      setFinaleIsOpen(true);
      dispatch(emptyCart())
      dispatch(resetOrderCreate())
    }
    else if (error) {
      showErrorMessage('Erreur de paiement')
    }
  }, [success, error])

  function showErrorMessage(message) {
    alert(message)
  }

  function fillAdressSelect() {
    var adresses = [];

    let odoo_id = contactOdoo.id;
    let odoo_name = contactOdoo.name;
    let odoo_voie = contactOdoo.street;
    let odoo_voie2 = contactOdoo.street2;
    let odoo_ville = contactOdoo.city;
    let odoo_codePostal = contactOdoo.zip;
    let odoo_pays = contactOdoo.country_id;

    adresses.push({
      value: odoo_id, label: odoo_name + ' - ' + odoo_voie + ' ' + odoo_voie2 + ' '
        + odoo_codePostal + ' ' + odoo_ville + ' - ' +
        odoo_pays
    });

    contactsOdoo.forEach(contact => {
      let odoo_id = contact.id;
      let odoo_name = contact.name;
      let odoo_voie = contact.street;
      let odoo_voie2 = contact.street2;
      let odoo_ville = contact.city;
      let odoo_codePostal = contact.zip;
      let odoo_pays = contact.country_id;

      adresses.push({
        value: odoo_id, label: odoo_name + ' - ' + odoo_voie + ' ' + odoo_voie2 + ' '
          + odoo_codePostal + ' ' + odoo_ville + ' - ' +
          odoo_pays
      });
    });

    setAdressListForSelect(adresses);
    setVoie(contactOdoo.street);
    setVoie2(contactOdoo.street2);
    setCodePostal(contactOdoo.zip);
    setVille(contactOdoo.city);
    setPays(contactOdoo.country_id);
  }

  const [, forceUpdate] = useState(0);

  useEffect(() => {
    forceUpdate(n => !n)
  }, [emptyAdress])

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, imei, qty))
    }
  }, [dispatch, productId, imei, qty])

  let subtitle;

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const removeFromCartHandler = (id, imei) => {
    dispatch(removeFromCart(id, imei));
  }

  const checkoutHandler = (e) => {
    e.preventDefault();
    dispatch(saveShippingAddress({ adress, voie, voie2, codePostal, ville, pays }));
    dispatch(saveShippingPrice(shippingPrice));

    navigate('/placeorder');
  }

  const quotationHandler = () => {
    navigate('/login?redirect=shipping');
  }

  const addCollab = () => {
    closeModal();
    navigate('/profile/collab');
  }

  const addSociete = () => {
    closeModal();
    navigate('/profile/societe');
  }

  const onChangeDeliveryAddress = (event) => {
    let entity = contactsOdoo.filter(item => item.id == event.target.value).length > 0 ?
      contactsOdoo.filter(item => item.id == event.target.value)[0]
      : contactOdoo;
    setDeliveryEntity(entity)
  }

  const onChangeBillingAddress = (event) => {
    let entity = contactsOdoo.filter(item => item.id == event.target.value).length > 0 ?
      contactsOdoo.filter(item => item.id == event.target.value)[0]
      : contactOdoo;
    setBillingEntity(entity)
    setDeliveryEntity(entity)
  }

  const onHandleSelectChange = useCallback((value, name) => {
    setAdress(adressListForSelect[value - 1])
    setEmptyAdress(value === null);
    setForm(prev => ({
      ...prev,
      [name]: value
    }));
  }, []);

  const UpdateCartHandler = (id, is_imei, qty) => {
    dispatch(updateCartItemQty(id, is_imei, qty))
  }

  function CreateOrder(quotation = false) {
    let partner_id = userInfo.id_odoo;
    let user_id = userInfo.id;
    let features_to_send = '';
    let feature_to_send = '';

    setIsQuotation(quotation);

    let order_lines = [];
    cart.cartItems.forEach((item, index) => {
      feature_to_send = '';
      if (item.features && item.features.length > 0) {
        item.features.forEach(feature => {
          if (feature.selectedFeatures && feature.selectedFeatures.length > 0) {
            feature.selectedFeatures.forEach(selectedFeature => {
              feature_to_send += selectedFeature.name + ' ' + selectedFeature.value + ' - ';
              if (selectedFeature === feature.selectedFeatures[feature.selectedFeatures.length - 1]) {
                feature_to_send += ' Qty : ' + feature.qty + '\n';
              }
            });
            if (feature === item.features[item.features.length - 1]) {
              features_to_send += feature_to_send + '\n';
            }
          }
        });
      } else {
        features_to_send = '';
      }

      order_lines.push({
        "product_id": item.product.id_odoo,
        "name": features_to_send,
        "product_uom_qty": item.qty,
        "price_unit": item.product.price,
        "product_circle_id": item.is_imei ?
          products.filter(product => product.id === item.product.id)[0].id
          : productsNonImei.filter(product => product.id === item.product.id)[0].id,
        "is_imei": item.is_imei ? true : false
      });
    });

    dispatch(createOrder({
      "partner_id": billingEntity ? billingEntity.id : partner_id,
      "partner_shipping_id": deliveryEntity ? deliveryEntity.id : partner_id,
      "user_id": user_id,
      "state": quotation ? 'draft' : "sale",
      "order_lines": order_lines
    }));
  }

  function afterOpenFinaleModal() {
    subtitle.style.color = '#f00';
  }

  function closeFinaleModal() {
    setFinaleIsOpen(false);
    setIsQuotation(false);
    navigate('/orders')
  }

  return (
    <div className='cart-container'>
      <Row className='cart-title-row'>
        <div className='cart-title'>{t('Your cart')}</div>
      </Row>
      {
        loadinOrderCreation ? <Loader /> :
          <Row className='cart-first-line'>
            <div className='cart-tab-title'>{t('My items')}</div>
            <Col className='cart-products-col' md={6}>
              {cartItems.length === 0 ? (
                <Message variant='info'>
                  {t('Your cart is empty')}
                </Message>
              ) : (
                <>
                  <ListGroup className='cart-products' variant='flush'>
                    {cartItems && cartItems.length > 0 ? cartItems.map((item) => (
                      <ListGroup.Item className='cart-items' key={item.product}>
                        <Row className='cart-row-item'>
                          <Col md={2}>
                            <Image className='cart-img' src={item.product.device_image} alt={item.product.name} fluid rounded />
                          </Col>
                          <Col className='' md={6}>
                            <Row className='cart-center-row'>
                              <Link
                                to={item.is_imei ? `/imei-products/${item.product.id}/` : `/products/${item.product.id}/`}
                                state={{ p: item.product }}
                              >{item.product.brand} {item.product.name}</Link>
                            </Row>
                            <Row className='cart-center-row'>
                              {item.product.price} € HT
                            </Row>
                          </Col>
                          <Col className='cart-item-input' md={2}>
                            <input
                              className='cart-qty'
                              type="text"
                              min="1"
                              value={item.qty}
                            // onChange={(e) => UpdateCartHandler(item.product.id, item.is_imei, Number(e.target.value))}
                            />
                          </Col>
                          <Col className='cart-item-input' md={2}>
                            <button
                              className="btn-cart-remove"
                              onClick={() => dispatch(removeFromCartHandler(item.product.id, item.is_imei))}>
                              <i className='fas fa-trash'></i>
                            </button>
                            {item.qty > item.stock ?
                              <div className="cart-alert-qty">
                                <i className="fa-solid fa-triangle-exclamation fa-lg" data-tip="React-tooltip" data-for={'tooltip'}></i>
                                <ReactTooltip id={'tooltip'}
                                  type="dark"
                                  effect="solid"
                                  clickable={true}
                                  getContent={function () {
                                    return (
                                      <div>
                                        <Row>{t('alertQty')}</Row>
                                      </div>
                                    )
                                  }} />
                              </div>
                              :
                              null
                            }
                          </Col>
                          {
                            item && item.features && item.features.length > 0 ?
                              item.features.map((feature) => (
                                <Row>
                                  {
                                    feature.selectedFeatures && feature.selectedFeatures.length > 0 ?
                                      feature.selectedFeatures.map((selectedFeature) => (
                                        <Col>
                                          {selectedFeature.name} : {selectedFeature.value}
                                        </Col>
                                      ))
                                      :
                                      null
                                  }
                                  <Col>
                                    {t('Quantity')} : {feature.qty}
                                  </Col>
                                </Row>
                              ))
                              :
                              null
                          }
                        </Row>
                      </ListGroup.Item>
                    )) : null}
                  </ListGroup>
                  <Row className='cart-empty-row'>
                    <button
                      className='btn-empty-cart'
                      type='button'
                      disabled={cartItems.length == 0}
                      onClick={() => dispatch(emptyCart())}
                    >
                      <FontAwesomeIcon icon={faCartArrowDown} />
                      {' '}{t('Empty the cart')}
                    </button>
                  </Row>

                </>
              )}
            </Col>
            <Col className='' md={{ span: 5, offset: 1 }}>
                <>
                  <ListGroup className='cart-entity' variant='flush'>
                    <ListGroup.Item className='border-0 profile-items'>
                      <Row className='profile-row-item'>
                        <Col className='vertical-center cart-adress-label' md={{ span: 2, offset: 8 }}>
                          <span>Adresse de facturation</span>
                        </Col>
                        <Col className='vertical-center cart-adress-label' md={2}>
                          <span>Adresse de livraison</span>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    {
                      contactOdoo && contactOdoo.country_id && contactOdoo.country_id.length > 0 ?

                        <ListGroup.Item className='border-0 profile-items'>
                          <Row className='profile-row-item'>
                            <Col className='profile-detail-col' md={8}>
                              <Row className='profile-center-row profile-item-name'>
                                {contactOdoo.name}
                              </Row>
                              <Row className='profile-center-row profile-item-detail'>
                                siret : {contactOdoo.siret}
                              </Row>
                              <Row className='profile-center-row profile-item-detail'>
                                adresse : {contactOdoo.street} {contactOdoo.street2} {contactOdoo.zip} {contactOdoo.city} {contactOdoo.country_id[1]}
                              </Row>
                            </Col>
                            <Col className='vertical-center' md={2}>
                              {['radio'].map((type) => (
                                <div>
                                  <input
                                    id={`reverse-${type}-1`}
                                    className="form-check-input"
                                    name="billingAddress"
                                    type={type}
                                    value={contactOdoo.id}
                                    defaultChecked={true}
                                    // checked={state.selectedOption === "Male"}
                                    onChange={(event) => onChangeBillingAddress(event)}
                                  />
                                </div>
                              ))}
                            </Col>
                            <Col className='vertical-center' md={2}>
                              <div>
                                <label>
                                  <input
                                    className="form-check-input"
                                    name="deliveryAddress"
                                    type="radio"
                                    value={contactOdoo.id}
                                    defaultChecked={true}
                                    onChange={(event) => onChangeDeliveryAddress(event)}
                                  />
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                        : null
                    }

                    {contactsOdoo && contactsOdoo.filter((item) => item.is_company == true).map((item) => (
                      <ListGroup.Item className='border-0 profile-items' key={item.product}>
                        <Row className='profile-row-item'>
                          <Col className='profile-detail-col' md={8}>
                            <Row className='profile-center-row profile-item-name'>
                              {item.name}
                            </Row>
                            <Row className='profile-center-row profile-item-detail'>
                              {t('siret')} : {item.siret}
                            </Row>
                            <Row className='profile-center-row profile-item-detail'>
                              {t('address')} : {item.street} {item.street2} {item.zip} {item.city} {item.country_id[1]}
                            </Row>
                          </Col>
                          <Col className='vertical-center' md={2}>
                            <div>
                              <label>
                                <input
                                  className="form-check-input"
                                  name="billingAddress"
                                  type="radio"
                                  value={item.id}
                                  onChange={(event) => onChangeBillingAddress(event)}
                                />
                              </label>
                            </div>
                          </Col>
                          <Col className='vertical-center' md={2}>
                            <div>
                              <label>
                                <input
                                  className="form-check-input"
                                  name="deliveryAddress"
                                  type="radio"
                                  value={item.id}
                                  onChange={(event) => onChangeDeliveryAddress(event)}
                                />
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </>
              <Card className='sub-total'>
                <ListGroup variant='flush'>
                  <ListGroup.Item>
                    <h2>
                      {t('Subtotal')} ({
                        cartItems && cartItems.length > 0 ?
                          cartItems.reduce((acc, item) => +acc + +item.qty, 0)
                          : 0
                      }) {t('items')}
                    </h2>
                    {cartItems
                      .reduce((acc, item) => acc + item.qty * item.product.price, 0)
                      .toFixed(2)}{' '}
                    € HT
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row className='sub-total-buttons'>
                      <Col md={5}>
                        <button
                          className='btn-quotation'
                          type='button'
                          disabled={cartItems.length === 0}
                          onClick={() => CreateOrder(true)}
                        >
                          {t('Create a quote')}
                        </button>
                      </Col>
                      <Col md={5}>
                        <button
                          className='btn-order'
                          type='button'
                          disabled={cartItems.length === 0 || userInfo === undefined || userInfo === null}
                          onClick={() => CreateOrder(false)}
                          data-tip="React-tooltip"
                          data-for={'tooltip-order-not-connected'}
                        >
                          {t('Order')}
                        </button>
                        {
                          userInfo === undefined || userInfo === null || emptyAdress === true ?
                            <ReactTooltip id={'tooltip-order-not-connected'}
                              type="dark"
                              effect="solid"
                              clickable={true}
                              getContent={function () {
                                return (
                                  <div>
                                    {
                                      (userInfo === undefined || userInfo === null) ?
                                        <>
                                          <Row>{t('notConnected')}</Row>
                                          <Row>{t('and')}</Row>
                                          <Row>{t('noAdress')}</Row>
                                        </>
                                        :
                                        userInfo === undefined || userInfo === null ?
                                          <Row>{t('notConnected')}</Row>
                                          :
                                          emptyAdress === true ?
                                            <Row>{t('noAdress')}</Row>
                                            :
                                            null
                                    }
                                  </div>
                                )
                              }}
                            />
                            :
                            null
                        }
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
      }

      {/* <Row className='cart-second-line'>
        <div className='cart-tab-title'>Livraison</div>
        <Col className='' md={6}>
          <ListGroup className='cart-delivery' variant='flush'>
            <ListGroup.Item className='cart-delivery-items'>
              <Col md={8}>
                <div className='cart-select-form'>
                  <Select
                    className='cart-adresse-select'
                    name="adresse"
                    title="Adresses"
                    value={form.adresse}
                    options={adressListForSelect}
                    onChangeFunc={onHandleSelectChange}
                  />
                </div>
              </Col>
              <Col className='align-self-center' md={4}>
                <button
                  className='btn-add-adress'
                  type='button'
                  disabled={cartItems.length === 0 || userInfo === undefined || userInfo === null}
                  onClick={openModal}
                  data-tip="React-tooltip"
                  data-for={'tooltip-not-connected'}
                > Ajouter</button>
                {
                  userInfo === undefined || userInfo === null ?
                    <ReactTooltip id={'tooltip-not-connected'}
                      type="dark"
                      effect="solid"
                      clickable={true}
                      getContent={function () {
                        return (
                          <div>
                            <Row>{notConnected}</Row>
                          </div>
                        )
                      }} />
                    :
                    null
                }
              </Col>
            </ListGroup.Item>
            {DeliveryMethods.map((item) => (
              <ListGroup.Item className='cart-delivery-items' key={item.id}>
                <Row className='cart-delivery-row'>
                  <Col className='vertical-center' md={2}>
                    <Image
                      className='delivery-img'
                      src={item.name === 'UPS' ? ups : item.name === 'Chronopost' ? chronopost : colissimo}
                      alt={item.name}
                      fluid rounded />
                  </Col>
                  <Col className='vertical-center' md={6}>
                    <div className='delivery-time-row'>Livraison sous {item.time} {item.time > 1 ? "jours" : "jour"}</div>
                  </Col>
                  <Col className='vertical-center' md={{ span: 2, offset: 1 }}>
                    <Row>
                      {item.price} € HT
                    </Row>
                  </Col>
                  <Col className='vertical-center' md={1}>
                    {['radio'].map((type) => (
                      <div>
                        <input
                          id={`reverse-${type}-1`}
                          className="form-check-input"
                          name="flexRadioDefault"
                          type={type}
                          value={item.id}
                          defaultChecked={item === DeliveryMethods[0]}
                          // checked={state.selectedOption === "Male"}
                          onChange={(event) => onChangeValue(event)}
                        />
                      </div>
                    ))}
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col className='' md={{ span: 4, offset: 1 }}>
          <Row className='file-container'>
            <div>
              <div
                className='list-files-container'
              >
                <ul>
                  <p className='list-files-title'>Documents à envoyer</p>
                  {fileNames.map((name) => (
                    <li key={name}>
                      <span>{name}</span>

                      <span onClick={() => removeFile(name)}>
                        <i className="fa-regular fa-trash-can file-remove"></i>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Provide a drop zone and an alternative button inside it to upload files. */}
      {/* <div
                className='drop-zone'
                onDragEnter={handleDragDropEvent}
                onDragOver={handleDragDropEvent}
                onDrop={(e) => {
                  handleDragDropEvent(e);
                  setFiles(e, 'a');
                }}
              >
                <p>Déposez vos fichiers ici</p>

                <button className='btn-select-file' onClick={() => inputRef.current.click()}>Ou sélectionner un fichier</button>

                <input
                  ref={inputRef}
                  type="file"
                  multiple
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    setFiles(e, 'a');
                    inputRef.current.value = null;
                  }}
                />
              </div>
            </div>
          </Row>

        </Col>
      </Row> */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <Row className='order-modal-title'>
          <span>{t('Add an address')}</span>
        </Row>

        <Row className='order-modal-button'>
          <Col>
            <button
              className='order-modal-btn-cancel'
              type='button'
              onClick={addSociete}
            >
              {t('Company')}
            </button>
          </Col>
          <Col>
            <button
              className='order-modal-btn-validate'
              type='button'
              onClick={addCollab}
            >
              {t('Collaborator')}
            </button>
          </Col>
        </Row>
      </Modal>
      <Modal
        isOpen={modalFinaleIsOpen}
        onAfterOpen={afterOpenFinaleModal}
        onRequestClose={closeFinaleModal}
        style={customStyles}
      >
        <Row className='aftersalenew-finalmodal-title'>
          <Col className='aftersalenew-finalmodal-title-col' md={1}>
            <i class="fa-regular fa-paper-plane fa-4x"></i>
          </Col>
        </Row>
        <Row className='aftersalenew-modal-conditions'>
          <span>{isQuotation ? t(sentQuotationText) : t(sentText)}</span>
        </Row>
        <Row className='aftersalenew-modal-conditions'>
          <span>{isQuotation ? t(followQuotationText) : t(followText)}</span>
        </Row>
        <Row className='aftersalenew-modal-conditions'>
          <span>{isQuotation ? t(finalQuotationText) : t(finalText)}</span>
        </Row>
        <Row className='aftersalenew-modal-button'>
          <Col>
            <button
              className='aftersale-modal-btn-close'
              type='button'
              disabled={false}
              onClick={closeFinaleModal}
            >
              {t('Close')}
            </button>
          </Col>
        </Row>
      </Modal>
    </div>

  )
}

export default Cart;
