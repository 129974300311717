export const IMAGE_LIST_REQUEST = 'IMAGE_LIST_REQUEST'
export const IMAGE_LIST_SUCCESS = 'IMAGE_LIST_SUCCESS'
export const IMAGE_LIST_FAIL = 'IMAGE_LIST_FAIL'

export const IMAGE_UPLOAD_REQUEST = 'IMAGE_UPLOAD_REQUEST'
export const IMAGE_UPLOAD_SUCCESS = 'IMAGE_UPLOAD_SUCCESS'
export const IMAGE_UPLOAD_FAIL = 'IMAGE_UPLOAD_FAIL'

export const IMAGE_UPDATE_REQUEST = 'IMAGE_UPDATE_REQUEST'
export const IMAGE_UPDATE_SUCCESS = 'IMAGE_UPDATE_SUCCESS'
export const IMAGE_UPDATE_FAIL = 'IMAGE_UPDATE_FAIL'

export const IMAGE_DELETE_REQUEST = 'IMAGE_DELETE_REQUEST'
export const IMAGE_DELETE_SUCCESS = 'IMAGE_DELETE_SUCCESS'
export const IMAGE_DELETE_FAIL = 'IMAGE_DELETE_FAIL'

export const IMAGE_NON_IMEI_LIST_REQUEST = 'IMAGE_NON_IMEI_LIST_REQUEST'
export const IMAGE_NON_IMEI_LIST_SUCCESS = 'IMAGE_NON_IMEI_LIST_SUCCESS'
export const IMAGE_NON_IMEI_LIST_FAIL = 'IMAGE_NON_IMEI_LIST_FAIL'

export const IMAGE_ALL_NON_IMEI_LIST_REQUEST = 'IMAGE_ALL_NON_IMEI_LIST_REQUEST'
export const IMAGE_ALL_NON_IMEI_LIST_SUCCESS = 'IMAGE_ALL_NON_IMEI_LIST_SUCCESS'
export const IMAGE_ALL_NON_IMEI_LIST_FAIL = 'IMAGE_ALL_NON_IMEI_LIST_FAIL'