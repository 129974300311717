// Commande 
 
export const ORDER_DRAFT         = 'draft'       // devis                   
export const ORDER_SENT          = 'sent'        // devis envoyé         => En attente
export const ORDER_SALE          = 'sale'        // bon de commande      => Reçue
export const ORDER_DONE          = 'done'        // bloqué               => Fait
export const ORDER_CANCEL        = 'cancel'      // annulé
 
// Facture 
 
export const INVOICE_NO          = 'no'          // non facturée
export const INVOICE_INVOICED    = 'invoiced'    // facturée             => Facture disponible
 
// Livraison 
 
export const DELIVERY_DRAFT      = 'draft'       // brouillon
export const DELIVERY_WAITING    = 'waiting'     // en attente
export const DELIVERY_CONFIRMED  = 'confirmed'   // confirmé
export const DELIVERY_ASSIGNED   = 'assigned'    // prêt
export const DELIVERY_DONE       = 'done'        // fait
export const DELIVERY_CANCEL     = 'cancel'      // annulé
 
 
// Paiement 
 
export const PAYMENT_PAID        = 'paid'        // payé                => Payé
export const PAYMENT_IN_PAYMENT  = 'in_payment'  // en cours            => A payer
export const PAYMENT_REVERSE     = 'reversed'    // remboursé           => Remboursé
 
 
// Réparation 
export const REPAIR_DRAFT        = 'draft'       // brouillon
export const REPAIR_CONFIRMED    = 'confirmed'   // confirmé
export const REPAIR_READY        = 'ready'       // prêt
export const REPAIR_UNDER_REPAIR = 'under_repair'// en réparation
export const REPAIR_DONE         = 'done'        // fait