import{
    DEFAULT_IMAGE_LIST_REQUEST,
    DEFAULT_IMAGE_LIST_SUCCESS,
    DEFAULT_IMAGE_LIST_FAIL,

    DEFAULT_IMAGE_CREATE_REQUEST,
    DEFAULT_IMAGE_CREATE_SUCCESS,
    DEFAULT_IMAGE_CREATE_FAIL,

    DEFAULT_IMAGE_UPDATE_REQUEST,
    DEFAULT_IMAGE_UPDATE_SUCCESS,
    DEFAULT_IMAGE_UPDATE_FAIL,

    DEFAULT_NON_IMEI_IMAGE_LIST_REQUEST,
    DEFAULT_NON_IMEI_IMAGE_LIST_SUCCESS,
    DEFAULT_NON_IMEI_IMAGE_LIST_FAIL,

    DEFAULT_NON_IMEI_IMAGE_CREATE_REQUEST,
    DEFAULT_NON_IMEI_IMAGE_CREATE_SUCCESS,
    DEFAULT_NON_IMEI_IMAGE_CREATE_FAIL,

    DEFAULT_NON_IMEI_IMAGE_UPDATE_REQUEST,
    DEFAULT_NON_IMEI_IMAGE_UPDATE_SUCCESS,
    DEFAULT_NON_IMEI_IMAGE_UPDATE_FAIL,

    DEFAULT_ALL_NON_IMEI_IMAGE_LIST_REQUEST,
    DEFAULT_ALL_NON_IMEI_IMAGE_LIST_SUCCESS,
    DEFAULT_ALL_NON_IMEI_IMAGE_LIST_FAIL,
}
from '../constants/defaultImagesConstants.js'

export const defaultAllNonImeiImageListReducer = (state = { defaultAllNonImeiImages: [] }, action) => {
    switch (action.type) {
        case DEFAULT_ALL_NON_IMEI_IMAGE_LIST_REQUEST:
            return { loading: true, defaultAllNonImeiImages: [] }
        case DEFAULT_ALL_NON_IMEI_IMAGE_LIST_SUCCESS:
            return { loading: false, defaultAllNonImeiImages: action.payload }
        case DEFAULT_ALL_NON_IMEI_IMAGE_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const defaultImageListReducer = (state = { defaultImages: [] }, action) => {
    switch (action.type) {
        case DEFAULT_IMAGE_LIST_REQUEST:
            return { loading: true, defaultImages: [] }
        case DEFAULT_IMAGE_LIST_SUCCESS:
            return { loading: false, defaultImages: action.payload }
        case DEFAULT_IMAGE_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const defaultImageCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case DEFAULT_IMAGE_CREATE_REQUEST:
            return { loading: true }
        case DEFAULT_IMAGE_CREATE_SUCCESS:
            return { loading: false, success: true, defaultImage: action.payload }
        case DEFAULT_IMAGE_CREATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const defaultImageUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case DEFAULT_IMAGE_UPDATE_REQUEST:
            return { loading: true }
        case DEFAULT_IMAGE_UPDATE_SUCCESS:
            return { loading: false, success: true, defaultImage: action.payload }
        case DEFAULT_IMAGE_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const defaultNonImeiImageListReducer = (state = { defaultNonImeiImages: [] }, action) => {
    switch (action.type) {
        case DEFAULT_NON_IMEI_IMAGE_LIST_REQUEST:
            return { loading: true, defaultNonImeiImages: [] }
        case DEFAULT_NON_IMEI_IMAGE_LIST_SUCCESS:
            return { loading: false, defaultNonImeiImages: action.payload }
        case DEFAULT_NON_IMEI_IMAGE_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const defaultNonImeiImageCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case DEFAULT_NON_IMEI_IMAGE_CREATE_REQUEST:
            return { loading: true }
        case DEFAULT_NON_IMEI_IMAGE_CREATE_SUCCESS:
            return { loading: false, success: true, defaultNonImeiImage: action.payload }
        case DEFAULT_NON_IMEI_IMAGE_CREATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const defaultNonImeiImageUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case DEFAULT_NON_IMEI_IMAGE_UPDATE_REQUEST:
            return { loading: true }
        case DEFAULT_NON_IMEI_IMAGE_UPDATE_SUCCESS:
            return { loading: false, success: true, defaultNonImeiImage: action.payload }
        case DEFAULT_NON_IMEI_IMAGE_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}