import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, ListGroup, Image, Button } from 'react-bootstrap'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { Container, Grid, Typography } from '@mui/material';

import { Link } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom';

import { removeDuplicates, removeDuplicatesByNestedProperty, findVal } from "../../../../utils/utils";
import { getSavHistory } from "../../../../utils/productUtils";
import Select from '../../../../components/Select/Select'
import Message from '../../../../components/Message/Message'

import Loader from '../../../../components/Loader/Loader'

import { listFleet } from '../../../../actions/fleetActions';
import { getHistory, getAllHistory } from '../../../../actions/historyActions';
import { listContactsOdoo } from '../../../../actions/contactOdooActions';
import { removeFromFleet } from '../../../../actions/fleetActions';

import DeviceHistory from '../../../../components/Fleet/DeviceHistory';
import DeviceSavHistory from '../../../../components/Fleet/DeviceSavHistory';

import './Fleets.css'


function FleetDetails() {
  const dispatch = useDispatch()
  const location = useLocation();

  const fleetDetail = location.state.f;

  const getHistoryList = useSelector(state => state.getHistoryList)
  const { error: errorGetHistory, loading: loadingGetHistory, history } = getHistoryList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const contactOdooList = useSelector((state) => state.contactOdooList)
  const { contactsOdoo, error: errorContactOdooList } = contactOdooList

  const fleetDelete = useSelector(state => state.fleetDelete)
  const { success: successDelete } = fleetDelete

  const [deviceHistory, setDeviceHistory] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    dispatch(getHistory(fleetDetail.imei))
    dispatch(listContactsOdoo(userInfo.id_odoo))
  }, [fleetDetail])

  useEffect(() => {
    
    if (history) {
      setDeviceHistory(history)
    }
  }, [history])

  return (
    <div className='fleet-details-main'>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Historique des appareils
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container direction="column">
            {
              history != undefined && history.history != undefined ?
                <DeviceSavHistory history={history.history} />
                :
                <Message variant="info">Aucun historique</Message>
            }
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container direction="column">
            <Grid item>
              {
                history != undefined && history.history != undefined ?
                  <DeviceHistory history={history.history} />
                  :
                  <Message variant="info">Aucun historique</Message>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default FleetDetails