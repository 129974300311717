import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row } from 'react-bootstrap'
import './Product.css';
import ColorSelector from '../Selector/ColorSelector/ColorSelector'
import { getOdooStock } from '../../actions/stockActions';
import { listAllNonImeiImage } from '../../actions/imageActions';
import { listAllDefaultNonImeiImages } from '../../actions/defaultImagesActions';
import { FaEye } from 'react-icons/fa'; // assurez-vous d'installer react-icons


function Product({ product }) {
    const dispatch = useDispatch()

    const [nonimei, setNonimei] = useState(false)

    const odooStock = useSelector(state => state.odooStock)
    const { stock } = odooStock

    const imageAllNonImeiList = useSelector(state => state.imageAllNonImeiList)
    const { allImagesNonImei } = imageAllNonImeiList

    const defaultNonImeiImageList = useSelector(state => state.defaultNonImeiImageList)
    const { defaultNonImeiImages } = defaultNonImeiImageList

    useEffect(() => {
        dispatch(getOdooStock(product.id_odoo))

        if (product.device_image == '') {
            setNonimei(true)
            dispatch(listAllNonImeiImage())
        }
    }, [])

    useEffect(() => {
        if (allImagesNonImei && allImagesNonImei.length > 0) {
            dispatch(listAllDefaultNonImeiImages())
        }
    }, [allImagesNonImei])

    useEffect(() => {
        if (defaultNonImeiImages && defaultNonImeiImages.length > 0) {
            let defaultImage = defaultNonImeiImages.filter(e => e.brand == product.brand && e.model == product.model && e.name == product.name).length > 0 ?
                defaultNonImeiImages.filter(e => e.brand == product.brand && e.model == product.model && e.name == product.name)[0].image
                : null

            product.device_image = defaultImage !== null && allImagesNonImei && allImagesNonImei.length > 0 ?
                allImagesNonImei.filter(d => d.name == defaultImage).length > 0 ? allImagesNonImei.filter(d => d.name == defaultImage)[0].url : ''
                : product.device_image;
        }
    }, [defaultNonImeiImages])


    return (
                    <Link
                        to={product.is_imei ? `/imei-products/${product.id}/` : `/products/${product.id}/` }
                        state={{ p: product }}
                        className="product-details"
                        style={{ textDecoration: 'none' }}
                    >
                        <div className="el-wrapper">
                            <div className="box-up">
                                <img className="img-products" src={product.device_image} alt={product.name} />
                                <div className="img-info">
                                    <div className="info-inner">
                                        <div className="p-brand">{product.brand}</div>
                                        <div className="p-name">{product.name}</div>
                                        <div className="product-tarif">{product.price.toFixed(2)} € HT</div>
                                    </div>
                                </div>
                            </div>
    
                            <div className="box-down">
                                <FaEye className="eye-icon" size={50} /> {/* L'icône oeil */}
                            </div>
                        </div>
                    </Link>
    )
    
}

export default Product