export const FLEET_USERS_LIST_REQUEST = 'FLEET_USERS_LIST_REQUEST'
export const FLEET_USERS_LIST_SUCCESS = 'FLEET_USERS_LIST_SUCCESS'
export const FLEET_USERS_LIST_FAIL = 'FLEET_USERS_LIST_FAIL'

export const FLEET_USERS_ADD_REQUEST = 'FLEET_USERS_ADD_REQUEST'
export const FLEET_USERS_ADD_SUCCESS = 'FLEET_USERS_ADD_SUCCESS'
export const FLEET_USERS_ADD_FAIL = 'FLEET_USERS_ADD_FAIL'

export const FLEET_USERS_ADD_BY_FILE_REQUEST = 'FLEET_USERS_ADD_BY_FILE_REQUEST'
export const FLEET_USERS_ADD_BY_FILE_SUCCESS = 'FLEET_USERS_ADD_BY_FILE_SUCCESS'
export const FLEET_USERS_ADD_BY_FILE_FAIL = 'FLEET_USERS_ADD_BY_FILE_FAIL'

export const FLEET_USERS_DELETE_REQUEST = 'FLEET_USERS_DELETE_REQUEST'
export const FLEET_USERS_DELETE_SUCCESS = 'FLEET_USERS_DELETE_SUCCESS'
export const FLEET_USERS_DELETE_FAIL = 'FLEET_USERS_DELETE_FAIL'

export const FLEET_USERS_UPDATE_REQUEST = 'FLEET_USERS_UPDATE_REQUEST'
export const FLEET_USERS_UPDATE_SUCCESS = 'FLEET_USERS_UPDATE_SUCCESS'
export const FLEET_USERS_UPDATE_FAIL = 'FLEET_USERS_UPDATE_FAIL'

export const RESET_FLEET_USER_ADD_SUCCESS = 'RESET_FLEET_USER_ADD_SUCCESS';
export const RESET_FLEET_USER_ADD_BY_FILE_SUCCESS = 'RESET_FLEET_USER_ADD_BY_FILE_SUCCESS';
export const RESET_FLEET_USER_DELETE_SUCCESS = 'RESET_FLEET_USER_DELETE_SUCCESS';
export const RESET_FLEET_USER_UPDATE_SUCCESS = 'RESET_FLEET_USER_UPDATE_SUCCESS';