export const addOneYear = (string) => {
    return new Date(new Date(string).setFullYear(new Date(string).getFullYear() + 1));
}

export const minusOneYear = (string) => {
    return new Date(new Date(string).setFullYear(new Date(string).getFullYear() - 1));
}

export const daysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
}