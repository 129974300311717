import{
    CONVERSATION_LIST_REQUEST,
    CONVERSATION_LIST_SUCCESS,
    CONVERSATION_LIST_FAIL,

    CONVERSATION_ADD_REQUEST,
    CONVERSATION_ADD_SUCCESS,
    CONVERSATION_ADD_FAIL,

    CONVERSATION_RECEIVERS_GET_REQUEST,
    CONVERSATION_RECEIVERS_GET_SUCCESS,
    CONVERSATION_RECEIVERS_GET_FAIL,

    CONVERSATION_DEL_REQUEST,
    CONVERSATION_DEL_SUCCESS,
    CONVERSATION_DEL_FAIL,
}
from '../constants/conversationConstants'

import axios from 'axios'

export const listConversations = () => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`,
            }
        });

        dispatch({ type: CONVERSATION_LIST_REQUEST })

        const { data } = await instance.get(`/api/conv/?user_id=${userInfo._id}`)

        dispatch({
            type: CONVERSATION_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: CONVERSATION_LIST_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const getReceivers = () => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`,
            }
        });

        dispatch({ type: CONVERSATION_RECEIVERS_GET_REQUEST })

        const { data } = await instance.get(`/api/conv/admin-receivers/?user_id=${userInfo._id}`)

        dispatch({
            type: CONVERSATION_RECEIVERS_GET_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: CONVERSATION_RECEIVERS_GET_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const addConversation = (receiver, sujet) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONVERSATION_ADD_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        
        

        const { data } = await instance.post(
            `/api/conv/add/`,
            { 'participants': [userInfo._id, receiver], 'sujet': sujet },
            )

            dispatch({
            type: CONVERSATION_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CONVERSATION_ADD_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteConversation = (conversation_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONVERSATION_DEL_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/conv/del/`,
            { 'id': conversation_id },
            )

        dispatch({
            type: CONVERSATION_DEL_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CONVERSATION_DEL_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}