import {
    FEATURE_LIST_REQUEST,
    FEATURE_LIST_SUCCESS,
    FEATURE_LIST_FAIL,

    ALL_FEATURE_REQUEST,
    ALL_FEATURE_SUCCESS,
    ALL_FEATURE_FAIL,

    FEATURE_FILTER_REQUEST,
    FEATURE_FILTER_SUCCESS,
    FEATURE_FILTER_FAIL,

    FEATURE_MAJ_FILTER_REQUEST,
    FEATURE_MAJ_FILTER_SUCCESS,
    FEATURE_MAJ_FILTER_FAIL,
    FEATURE_MAJ_FILTER_RESET,

    FEATURE_ADD_REQUEST,
    FEATURE_ADD_SUCCESS,
    FEATURE_ADD_FAIL,
    FEATURE_ADD_RESET,

    FEATURE_DELETE_REQUEST,
    FEATURE_DELETE_SUCCESS,
    FEATURE_DELETE_FAIL,
}
    from '../constants/featureConstants'

export const featureListReducer = (state = { features: [] }, action) => {
    switch (action.type) {
        case FEATURE_LIST_REQUEST:
            return { loading: true, features: [] }
        case FEATURE_LIST_SUCCESS:
            return { loading: false, features: action.payload }
        case FEATURE_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const allFeaturesReducer = (state = { featuresList: [] }, action) => {
    switch (action.type) {
        case ALL_FEATURE_REQUEST:
            return { loading: true, featuresList: [] }
        case ALL_FEATURE_SUCCESS:
            return { loading: false, featuresList: action.payload }
        case ALL_FEATURE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const featureFilterReducer = (state = { feature: {} }, action) => {
    switch (action.type) {
        case FEATURE_FILTER_REQUEST:
            return { loading: true, ...state }
        case FEATURE_FILTER_SUCCESS:
            return { loading: false, success: true, filters: action.payload }
        case FEATURE_FILTER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

//Maj du paramètre filter pour faire apparaitre ou non les features dans la liste des features
export const featureMajReducer = (state = { feature: {} }, action) => {
    switch (action.type) {
        case FEATURE_MAJ_FILTER_REQUEST:
            return { loading: true, ...state }
        case FEATURE_MAJ_FILTER_SUCCESS:
            return { loading: false, success: true, feature: action.payload }
        case FEATURE_MAJ_FILTER_FAIL:
            return { loading: false, error: action.payload }
        case FEATURE_MAJ_FILTER_RESET:
            return { feature: {} }
        default:
            return state
    }
}

export const featureAddReducer = (state = {}, action) => {
    switch (action.type) {
        case FEATURE_ADD_REQUEST:
            return { loading: true }
        case FEATURE_ADD_SUCCESS:
            return { loading: false, success: true }
        case FEATURE_ADD_FAIL:
            return { loading: false, error: action.payload }
        case FEATURE_ADD_RESET:
            return {}
        default:
            return state
    }
}

export const featureDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case FEATURE_DELETE_REQUEST:
            return { loading: true }
        case FEATURE_DELETE_SUCCESS:
            return { loading: false, success: true }
        case FEATURE_DELETE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}