export const FLEET_LIST_REQUEST = 'FLEET_LIST_REQUEST'
export const FLEET_LIST_SUCCESS = 'FLEET_LIST_SUCCESS'
export const FLEET_LIST_FAIL = 'FLEET_LIST_FAIL'

export const FLEET_CREATE_REQUEST = 'FLEET_CREATE_REQUEST'
export const FLEET_CREATE_SUCCESS = 'FLEET_CREATE_SUCCESS'
export const FLEET_CREATE_FAIL = 'FLEET_CREATE_FAIL'

export const FLEET_GLOBAL_IMPORT_REQUEST = 'FLEET_GLOBAL_IMPORT_REQUEST'
export const FLEET_GLOBAL_IMPORT_SUCCESS = 'FLEET_GLOBAL_IMPORT_SUCCESS'
export const FLEET_GLOBAL_IMPORT_FAIL = 'FLEET_GLOBAL_IMPORT_FAIL'

export const FLEET_UPDATE_REQUEST = 'FLEET_UPDATE_REQUEST'
export const FLEET_UPDATE_SUCCESS = 'FLEET_UPDATE_SUCCESS'
export const FLEET_UPDATE_FAIL = 'FLEET_UPDATE_FAIL'

export const FLEET_DELETE_REQUEST = 'FLEET_DELETE_REQUEST'
export const FLEET_DELETE_SUCCESS = 'FLEET_DELETE_SUCCESS'
export const FLEET_DELETE_FAIL = 'FLEET_DELETE_FAIL'

export const FLEET_GET_REQUEST = 'FLEET_GET_REQUEST'
export const FLEET_GET_SUCCESS = 'FLEET_GET_SUCCESS'
export const FLEET_GET_FAIL = 'FLEET_GET_FAIL'

export const FLEET_GET_PRODUCTS_REQUEST = 'FLEET_GET_PRODUCTS_REQUEST'
export const FLEET_GET_PRODUCTS_SUCCESS = 'FLEET_GET_PRODUCTS_SUCCESS'
export const FLEET_GET_PRODUCTS_FAIL = 'FLEET_GET_PRODUCTS_FAIL'

export const RESET_FLEET_UPDATE_SUCCESS = 'RESET_FLEET_UPDATE_SUCCESS'
export const RESET_FLEET_DELETE_SUCCESS = 'RESET_FLEET_DELETE_SUCCESS'
export const RESET_GLOBAL_IMPORT_SUCCESS = 'RESET_GLOBAL_IMPORT_SUCCESS'