import React from 'react';
import Modal from 'react-modal';
import { Row, Col, ListGroup, Image, Button, Container, Form, Card } from 'react-bootstrap'

import './ConfirmationModal.css'
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

Modal.setAppElement('#root');

const ConfirmationModal = ({
    isOpen,
    onRequestClose,
    operation,
    text,
    onConfirmDelete,

}) => {
    const { t, i18n } = useTranslation();

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="confirmation-user-modal">
            <Container>
                <Row className="confirmation-modal-header">
                    <Col md={10}>
                        <h2>{t('confirmation of ')}{operation ? operation : t('dissociation')}</h2>
                    </Col>
                </Row>
                <Row className="confirmation-modal-text">
                    <p>
                        {text}
                    </p>
                </Row>
                <Row className="confirmation-modal-btn">
                    <Col md={4}>
                        <button className="confirmation-modal-delete-btn" onClick={onConfirmDelete}>
                            {t('Delete')}
                        </button>
                    </Col>
                    <Col md={4}>
                        <button className="confirmation-modal-cancel-btn" onClick={onRequestClose}>
                            {t('Cancel')}
                        </button>
                    </Col>
                </Row>
            </Container>
        </Modal>
    );
};

export default ConfirmationModal;
