import {
    FLEET_USERS_LIST_REQUEST,
    FLEET_USERS_LIST_SUCCESS,
    FLEET_USERS_LIST_FAIL,

    FLEET_USERS_ADD_REQUEST,
    FLEET_USERS_ADD_SUCCESS,
    FLEET_USERS_ADD_FAIL,

    FLEET_USERS_ADD_BY_FILE_REQUEST,
    FLEET_USERS_ADD_BY_FILE_SUCCESS,
    FLEET_USERS_ADD_BY_FILE_FAIL,

    FLEET_USERS_DELETE_REQUEST,
    FLEET_USERS_DELETE_SUCCESS,
    FLEET_USERS_DELETE_FAIL,

    FLEET_USERS_UPDATE_REQUEST,
    FLEET_USERS_UPDATE_SUCCESS,
    FLEET_USERS_UPDATE_FAIL,

    RESET_FLEET_USER_ADD_SUCCESS,
    RESET_FLEET_USER_ADD_BY_FILE_SUCCESS,
    RESET_FLEET_USER_DELETE_SUCCESS,
    RESET_FLEET_USER_UPDATE_SUCCESS,
}
    from '../constants/fleetUsersConstants.js'

import axios from 'axios'

export const listFleetUsers = () => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        dispatch({ type: FLEET_USERS_LIST_REQUEST })

        const { data } = await instance.get(
            `/api/fleet-user/?user_id=${userInfo._id}`
            )

        dispatch({
            type: FLEET_USERS_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: FLEET_USERS_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const addFleetUser = (row) => async (dispatch, getState) => {
    try {
        dispatch({ type: FLEET_USERS_ADD_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/fleet-user/add/`,
            { 'parent_id': userInfo._id, 'fleetUser': row, 'updated_by': userInfo.impersonated_by ? userInfo.impersonated_by : userInfo._id },
            )

        dispatch({
            type: FLEET_USERS_ADD_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: FLEET_USERS_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const addFleetUsersByFile = (fleetUsers) => async (dispatch, getState) => {
    try {
        dispatch({ type: FLEET_USERS_ADD_BY_FILE_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/fleet-user/add-by-file/`,
            { 'parent_id': userInfo._id, 'fleetUsers': fleetUsers, 'updated_by': userInfo.impersonated_by ? userInfo.impersonated_by : userInfo._id },
            )

        dispatch({
            type: FLEET_USERS_ADD_BY_FILE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: FLEET_USERS_ADD_BY_FILE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const deleteFleetUser = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: FLEET_USERS_DELETE_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        await instance.delete(
            `/api/fleet-user/del/?id=${id}`
            )

        dispatch({
            type: FLEET_USERS_DELETE_SUCCESS
        })
    } catch (error) {
        dispatch({
            type: FLEET_USERS_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const updateFleetUser = (row) => async (dispatch, getState) => {
    try {
        dispatch({ type: FLEET_USERS_UPDATE_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/fleet-user/update/`,
            {
                'id': row.id, 'lastname': row.lastname, 'firstname': row.firstname,
                'entity': row.entity, 'role': row.role, 'fleet_sim': row.fleet_sim, 
                'updated_by': userInfo.impersonated_by ? userInfo.impersonated_by : userInfo._id
            },
            )

        dispatch({
            type: FLEET_USERS_UPDATE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: FLEET_USERS_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const resetFleetUserAddSuccess = () => {
    return { type: RESET_FLEET_USER_ADD_SUCCESS };
}

export const resetFleetUserAddByFileSuccess = () => {
    return { type: RESET_FLEET_USER_ADD_BY_FILE_SUCCESS };
}

export const resetFleetUserDeleteSuccess = () => {
    return { type: RESET_FLEET_USER_DELETE_SUCCESS };
}

export const resetFleetUserUpdateSuccess = () => {
    return { type: RESET_FLEET_USER_UPDATE_SUCCESS };
}