export const CONFIG_LIST_REQUEST = 'CONFIG_LIST_REQUEST'
export const CONFIG_LIST_SUCCESS = 'CONFIG_LIST_SUCCESS'
export const CONFIG_LIST_FAIL = 'CONFIG_LIST_FAIL'

export const CONFIG_GET_REQUEST = 'CONFIG_GET_REQUEST'
export const CONFIG_GET_SUCCESS = 'CONFIG_GET_SUCCESS'
export const CONFIG_GET_FAIL = 'CONFIG_GET_FAIL'

export const CONFIG_ADD_REQUEST = 'CONFIG_ADD_REQUEST'
export const CONFIG_ADD_SUCCESS = 'CONFIG_ADD_SUCCESS'
export const CONFIG_ADD_FAIL = 'CONFIG_ADD_FAIL'