import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Container, ListGroup } from 'react-bootstrap'
import Modal from 'react-modal';
import Table from 'react-bootstrap/Table';
import RangeSlider from 'react-range-slider-input';

import DateRange from '../../../../components/DateRange/DateRange';
import Loader from '../../../../components/Loader/Loader'

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import { toArrayKeys, removeDuplicates, removeDuplicatesByProperties, findVal } from "../../../../utils/utils";

import { listQuotations } from '../../../../actions/quotationActions';

import './Quotations.css'

function Quotations() {
  const dispatch = useDispatch()

  const quotationList = useSelector(state => state.quotationList)
  const { error, loading, quotations, page, pages } = quotationList

  const [quotationData, setQuotationData] = useState([]);
  const [filteredQuotationData, setFilteredQuotationData] = useState([]);
  const [modalQuotation, setModalQuotation] = useState({});
  const [priceRangeObj, setPriceRangeObj] = useState([]); // à voir pour mettre les prix min et max des produits

  const [priceRange, setPriceRange] = useState([]);

  const demandeur = {};
  const statut = {};

  const [demandeurObj, setDemandeurObj] = useState(demandeur);
  const [statutObj, setStatutObj] = useState(statut);

  const [validDateRange, setValidDateRange] = useState([]);
  const [quotationDateRange, setQuotationDateRange] = useState([]);

  const [validDateRangeObj, setValidDateRangeObj] = useState([]);
  const [quotationDateRangeObj, setQuotationDateRangeObj] = useState([]);

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const header = [
    "ID", "Demandeur", "Devis", "Date du devis", "Date de validité", "Tarif", "Détails", "Commander", "", ""
  ]

  const headerdetail = [
    "", "Quantité", "Article", "Prix unitaire", "Prix total"
  ]

  const searchKeys = ['demandeur', 'name', 'start'];

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '30%',
    },
  };

  let subtitle;

  const detailHandler = (q) => {
    setModalQuotation(q)
    openModal();
  }

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  function GetActionFormat(cell, row) {
    return (
      <div>
        <button
          type="button"
          className="btn btn-primary btn-sm ts-buttom"
          size="sm"
          onClick={() => this.handleModelEdit(row)}
        >
          Edit
        </button>
      </div>
    );
  };

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string.length === 0) {
      // setFilterObj({ ...filterObj });
      // setFilteredFleetData(fleetData);
      // setDetailData(fleetData[0]);
      // setDetailDataHistory(getSavHistory(fleetData[0]));

      
    }
  };

  const handleModelEdit = (row) => {

  }

  const handleOnSelect = (item) => {
    // the item selected
    // setFilteredFleetData(filteredFleetData.filter((product) => (product.name === item.name
    //   && product.current_user === item.current_user
    //   && product.brand === item.brand)));
    // setDetailData(item);
    // setDetailDataHistory(getSavHistory(item));
    
  };

  const handleOnFocus = () => {

  };

  const formatResult = (item) => {
    return (
      <>
        <Container>
          <Row className='fleet-search-result-row'>
            <Col md={4} className='fleet-search-result-col'>
              Marque :
            </Col>
            <Col className='fleet-search-result-col'>
              {item.brand}
            </Col>
          </Row>
          <Row className='fleet-search-result-row'>
            <Col md={4} className='fleet-search-result-col'>
              Modèle :
            </Col>
            <Col className='fleet-search-result-col'>
              {item.name}
            </Col>
          </Row>
          <Row className='fleet-search-result-row'>
            <Col md={4} className='fleet-search-result-col'>
              utilisateur :
            </Col>
            <Col className='fleet-search-result-col'>
              {item.current_user}
            </Col>
          </Row>
        </Container>
      </>
    )
  };

  useEffect(() => {
    dispatch(listQuotations());
  }, [])

  useEffect(() => {
    setFilteredQuotationData(quotationList.quotations);
    setQuotationData(quotationList.quotations);
    FillFilters(quotationList.quotations);

    let arr = [
      Math.min(...quotationList.quotations.map(x => x.price)),
      Math.max(...quotationList.quotations.map(x => x.price))
    ]
    setPriceRange([...arr])


    setValidDateRange([new Date(Math.min.apply(null, quotationList.quotations.map(x => new Date(x.start)))),
    new Date(Math.max.apply(null, quotationList.quotations.map(x => new Date(x.end))))])

    setQuotationDateRange([new Date(Math.min.apply(null, quotationList.quotations.map(x => new Date(x.start)))),
    new Date(Math.max.apply(null, quotationList.quotations.map(x => new Date(x.end))))])
  }, [quotationList])

  const quotationHandler = (q) => {
    
  }

  function FillFilters(data) {
    const demandeurList = Array.from(new Set(data.map((item) => item.demandeur)));

    demandeurList.map((b, i) => {
      const newObj = {};
      newObj[b] = 'false';
      Object.assign(demandeur, newObj)
    });
    setDemandeurObj(demandeur);
  }

  const onChangeHandler = (event, label) => {
    const { value, checked } = event.target;
    switch (label) {
      case "DEMANDEUR":
        for (let demandeurKey in demandeurObj) {
          if (checked) {
            if (demandeurKey === value) {
              setDemandeurObj({
                ...demandeurObj,
                [demandeurKey]: true
              });
            }
          } else {
            if (demandeurKey === value) {
              setDemandeurObj({
                ...demandeurObj,
                [demandeurKey]: false
              });
            }
          }
        }
        break;

      default:
        
    }
  };

  const callPriceRange = (value) => {
    
    
    const min = value[0];
    const max = value[1];
    setPriceRangeObj([min, max]);
  };

  const callDemandeur = () => {
    const label = "DEMANDEUR";
    return toArrayKeys(demandeurObj).map((data, index) =>
      callCheckboxData(label, data, index)
    );
  };


  const callCheckboxData = (label, data, index) => {
    return (
      <Row key={index}>
        <Col className="col-sm-2">
          <input
            type="checkbox"
            id={data}
            name={data}
            value={data}
            onChange={(event) => onChangeHandler(event, label)}
          />
        </Col>
        <Col className="col-sm-10">
          <label className="filter-checkbox-label" htmlFor={data}>{data}</label>
        </Col>
      </Row>
    );
  };

  const [filterItemObj, setFilterItemObj] = useState({});

  useEffect(() => {
    const filteredItems = {
      price_range: [],
      demandeur: [],
      start: [],
      end: [],
    };

    
    

    const filterPriceRangeArr = Array.from(new Set(quotationData.filter((item) => {
      return item.price >= priceRangeObj[0] && item.price <= priceRangeObj[1];
    }).map((item) => item.price)));

    const filterDemandeurArr = toArrayKeys(demandeurObj).filter(
      (data) => demandeurObj[data] === true
    );

    const filterDateValidArr = Array.from(new Set(quotationData.filter((item) => {
      return new Date(item.start) >= validDateRangeObj[0] && new Date(item.start) <= validDateRangeObj[1];
    }).map((item) => item.start)));

    const filterDateQuotationArr = Array.from(new Set(quotationData.filter((item) => {
      return new Date(item.end) >= quotationDateRangeObj[0] && new Date(item.end) <= quotationDateRangeObj[1];
    }).map((item) => item.end)));

    filteredItems["price"] = [...filterPriceRangeArr];
    filteredItems["demandeur"] = [...filterDemandeurArr];
    filteredItems["start"] = [...filterDateValidArr];
    filteredItems["end"] = [...filterDateQuotationArr];

    setFilterItemObj(filteredItems);

  }, [priceRangeObj, demandeurObj, validDateRangeObj, quotationDateRangeObj]);

  useEffect(() => {
    const filterKeys = Object.keys(filterItemObj);
    const filteredTicketsDataArr = quotationData.filter((p) => {
      return filterKeys.every((key) => {

        if (!filterItemObj[key].length) {
          if (key === "price_range" && priceRangeObj[1] < Math.min(...quotationData.map(x => x.price)))
            return false;
          return true;
        }

        const dataOut = filterItemObj[key].includes(findVal(p, key));
        return dataOut;
      });
    });

    
    setFilteredQuotationData(filteredTicketsDataArr);
  }, [filterItemObj]);

  const handleDateRangeEvent = (data) => {
    const start = data.value.start
    const end = data.value.end

    
    
    
    

    switch (data.type) {
      case 'quotation':
        setValidDateRangeObj([start.toDate(), end.toDate()])
        break;
      case 'valid':
        setQuotationDateRangeObj([start.toDate(), end.toDate()])
        break;
      default:
        break;
    };
  }

  return (
    <>
      {loading ? <Loader /> :
        <>
          <Row className='quotations-row'>
            <Col className='quotations-filters-col' md={3}>
              <ListGroup className="product-filters">
                <ListGroup.Item>
                  <h5>Prix</h5>
                  <div className="range-slider-block">
                    <Row>
                      <Col className="col-sm-6">
                        <div className="priceRangeMin">{priceRangeObj[0] ? priceRangeObj[0] : priceRange[0]} €</div>
                      </Col>
                      <Col className="col-sm-6">
                        <div className="priceRangeMax">{priceRangeObj[1] ? priceRangeObj[1] : priceRange[1]} €</div>
                      </Col>
                    </Row>
                    <Row>
                      <RangeSlider
                        className="range-slider"
                        min={priceRange[0]}
                        max={priceRange[1]}
                        step={1}
                        defaultValue={[priceRange]}
                        onInput={value => callPriceRange(value)}
                      />
                    </Row>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h5>Date devis</h5>
                  <DateRange onEvent={handleDateRangeEvent} type='quotation' range={quotationDateRange} />
                </ListGroup.Item>
                <ListGroup.Item>
                  <h5>Date de validité</h5>
                  <DateRange onEvent={handleDateRangeEvent} type='valid' range={validDateRange} />
                </ListGroup.Item>
                <ListGroup.Item className="aftersaledevices-filter-demandeur">
                  <h5>Demandeur</h5>
                  {callDemandeur()}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col className='quotations-table-col' md={9}>
              <Row className='quotations-table-search'>
                <ReactSearchAutocomplete
                  items={removeDuplicates(filteredQuotationData)}
                  fuseOptions={{ keys: searchKeys }}
                  onSearch={handleOnSearch}
                  onSelect={handleOnSelect}
                  onFocus={handleOnFocus}
                  placeholder="Rechercher dans mes devis..."
                  autoFocus
                  formatResult={formatResult}
                  threshold={0} // 0 = exact match, 1 = everything matches
                  ignoreLocation={true}
                  styling={{
                    height: "20%",
                    width: "100%",
                    bquotation: "1px solid #dfe1e5",
                    bquotationRadius: "5px",
                    backgroundColor: "white",
                    boxShadow: "rgba(58, 53, 53, 0.2) 0px 1px 6px 0px",
                    hoverBackgroundColor: "#eee",
                    color: "#212121",
                    fontSize: "12px",
                    fontFamily: "Arial",
                    iconColor: "grey",
                    lineColor: "rgb(232, 234, 237)",
                    placeholderColor: "grey",
                    clearIconMargin: '0 5px 0 0',
                    searchIconMargin: '0 0 0 5px',
                    zIndex: 2,
                  }}
                />
              </Row>
              <Row>
                <Table responsive>
                  <thead className='quotations-header'>
                    <tr>
                      {header.map((item, index) => (
                        <th key={index}>{item}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className='quotations-body'>
                    {filteredQuotationData.length > 0 ? (
                      filteredQuotationData.map((quotation, index) => (
                        <tr className='quotations-row-detail' key={index}>
                          <td className='quotation-td'>{quotation.id}</td>
                          <td className='quotation-td'>{quotation.demandeur}</td>
                          <td className='quotation-td'>{quotation.name}</td>
                          <td className='quotation-td'>{new Date(quotation.start).toLocaleDateString("fr-FR")}</td>
                          <td className='quotation-td'>{new Date(quotation.end).toLocaleDateString("fr-FR")}</td>
                          <td className='quotation-td'>{quotation.price} €</td>
                          <td className='quotation-td'>
                            <button className='quotation-detail-btn' type='submit' onClick={() => detailHandler(quotation)}>Détails</button>
                          </td>
                          <td className='quotation-td'>
                            <button className='quotation-order-btn' type='submit' onClick={quotationHandler}>Commander</button>
                          </td>
                          <td>
                            <button className='quotation-squared-btn quotation-excel-btn'><i class="fa-solid fa-file-excel fa-2x"></i></button>
                          </td>
                          <td>
                            <button className='quotation-squared-btn quotation-pdf-btn'><i class="fa-solid fa-file-pdf fa-2x"></i></button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      null
                    )}
                  </tbody>
                </Table>
              </Row>
            </Col>
          </Row>

          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
          >
            <Table responsive>
              <thead className='quotations-header'>
                <tr>
                  {headerdetail.map((item, index) => (
                    <th key={index}>{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody className='quotations-body'>
                {modalQuotation.articles && modalQuotation.articles.map((article, index) => (
                  <tr className='quotations-row-detail' key={index}>
                    <td className='quotation-td'></td>
                    <td className='quotation-td'>{article.qty}</td>
                    <td className='quotation-td'>{article.name}</td>
                    <td className='quotation-td'>{article.tarif} €</td>
                    <td className='quotation-td'>{article.tarif * article.qty} €</td>
                  </tr>
                ))
                }

                <tr className='quotations-row-detail'>
                  <td className='quotation-td-total'>Total</td>
                  <td className='quotation-td-total'>{modalQuotation.articles && modalQuotation.articles.reduce((acc, item) => acc + item.qty, 0)}</td>
                  <td className='quotation-td-total'></td>
                  <td className='quotation-td-total'>{modalQuotation.articles && modalQuotation.articles.reduce((acc, item) => acc + item.tarif, 0)} €</td>
                  <td className='quotation-td-total'>{modalQuotation.articles && modalQuotation.articles.reduce((acc, item) => acc + (item.tarif * item.qty), 0)} €</td>
                </tr>
              </tbody>
            </Table>
            <Row className='modal-close-row'>
              <button className='quotation-modal-close-btn' onClick={closeModal}>Fermer</button>
            </Row>
          </Modal>
        </>
      }
    </>
  )
}

export default Quotations