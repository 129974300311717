import React, { useState } from "react";
import Stripe from "stripe";

import "./Payment.css";

const stripe = new Stripe("VOTRE_CLE_PUBLIQUE_STRIPE");

function PaymentForm() {
  const [email, setEmail] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expMonth, setExpMonth] = useState("");
  const [expYear, setExpYear] = useState("");
  const [cvc, setCvc] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: {
        number: cardNumber,
        exp_month: expMonth,
        exp_year: expYear,
        cvc: cvc,
      },
      billing_details: {
        email: email,
      },
    });

    if (error) {
      
    } else {
      
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Adresse e-mail :
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </label>
      <br />
      <label>
        Numéro de carte :
        <input
          type="text"
          value={cardNumber}
          onChange={(e) => setCardNumber(e.target.value)}
        />
      </label>
      <br />
      <label>
        Mois d'expiration :
        <input
          type="text"
          value={expMonth}
          onChange={(e) => setExpMonth(e.target.value)}
        />
      </label>
      <label>
        Année d'expiration :
        <input
          type="text"
          value={expYear}
          onChange={(e) => setExpYear(e.target.value)}
        />
      </label>
      <br />
      <label>
        CVC :
        <input
          type="text"
          value={cvc}
          onChange={(e) => setCvc(e.target.value)}
        />
      </label>
      <br />
      <button type="submit">Payer</button>
    </form>
  );
}

export default PaymentForm;
