import {
    CLIENT_LIST_REQUEST,
    CLIENT_LIST_SUCCESS,
    CLIENT_LIST_FAIL,

    CLIENT_IMPERSONATED_REQUEST,
    CLIENT_IMPERSONATED_SUCCESS,
    CLIENT_IMPERSONATED_FAIL,
    CLIENT_IMPERSONATED_LOGOUT,

    CLIENT_IMPERSONATED_REVERT_REQUEST,
    CLIENT_IMPERSONATED_REVERT_SUCCESS,
    CLIENT_IMPERSONATED_REVERT_FAIL,
    RESET_CLIENT_IMPERSONATED_REVERT,

    RESET_CLIENT_IMPERSONATED_SUCCESS, Z
}
    from '../constants/clientConstants'

export const ClientListReducer = (state = { clients: [] }, action) => {
    switch (action.type) {
        case CLIENT_LIST_REQUEST:
            return { loading: true, clients: [] }
        case CLIENT_LIST_SUCCESS:
            return { loading: false, clients: action.payload }
        case CLIENT_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const impersonateRevertReducer = (state = {}, action) => {
    switch (action.type) {
        case CLIENT_IMPERSONATED_REVERT_REQUEST:
            return { loading: true };
        case CLIENT_IMPERSONATED_REVERT_SUCCESS:
            return { loading: false, userInfo: action.payload, success: true };
        case CLIENT_IMPERSONATED_REVERT_FAIL:
            return { loading: false, error: action.payload };
        case RESET_CLIENT_IMPERSONATED_REVERT:
            return { success: false }
        default:
            return state;
    }
};

export const impersonatedReducer = (state = {}, action) => {
    switch (action.type) {
        case CLIENT_IMPERSONATED_REQUEST:
            return { loading: true };
        case CLIENT_IMPERSONATED_SUCCESS:
            return { loading: false, userInfo: action.payload, success: true };
        case CLIENT_IMPERSONATED_FAIL:
            return { loading: false, error: action.payload };
        case CLIENT_IMPERSONATED_LOGOUT:
            return {};
        case RESET_CLIENT_IMPERSONATED_SUCCESS:
            return { success: false }
        default:
            return state;
    }
};