import {
    FLEET_LIST_REQUEST,
    FLEET_LIST_SUCCESS,
    FLEET_LIST_FAIL,

    FLEET_CREATE_REQUEST,
    FLEET_CREATE_SUCCESS,
    FLEET_CREATE_FAIL,

    FLEET_GLOBAL_IMPORT_REQUEST,
    FLEET_GLOBAL_IMPORT_SUCCESS,
    FLEET_GLOBAL_IMPORT_FAIL,

    FLEET_UPDATE_REQUEST,
    FLEET_UPDATE_SUCCESS,
    FLEET_UPDATE_FAIL,

    FLEET_DELETE_REQUEST,
    FLEET_DELETE_SUCCESS,
    FLEET_DELETE_FAIL,

    FLEET_GET_REQUEST,
    FLEET_GET_SUCCESS,
    FLEET_GET_FAIL,

    FLEET_GET_PRODUCTS_REQUEST,
    FLEET_GET_PRODUCTS_SUCCESS,
    FLEET_GET_PRODUCTS_FAIL,

    RESET_FLEET_UPDATE_SUCCESS,
    RESET_FLEET_DELETE_SUCCESS,
    RESET_GLOBAL_IMPORT_SUCCESS
} from "../constants/fleetConstants";

export const FleetListReducer = (state = { fleet: [] }, action) => {
    switch (action.type) {
        case FLEET_LIST_REQUEST:
            return { loading: true, fleet: [] }
        case FLEET_LIST_SUCCESS:
            return { loading: false, fleet: action.payload }
        case FLEET_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const FleetGetReducer = (state = { product: [] }, action) => {
    switch (action.type) {
        case FLEET_GET_REQUEST:
            return { loading: true }
        case FLEET_GET_SUCCESS:
            return { loading: false, success: true, productFromFleet: action.payload }
        case FLEET_GET_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const FleetGlobalImportReducer = (state = {}, action) => {
    switch (action.type) {
        case FLEET_GLOBAL_IMPORT_REQUEST:
            return { loading: true }
        case FLEET_GLOBAL_IMPORT_SUCCESS:
            return { loading: false, success: true }
        case FLEET_GLOBAL_IMPORT_FAIL:
            return { loading: false, error: action.payload }
        case RESET_GLOBAL_IMPORT_SUCCESS:
            return {}
        default:
            return state
    }
}

export const FleetGetProductsReducer = (state = { product: [] }, action) => {
    switch (action.type) {
        case FLEET_GET_PRODUCTS_REQUEST:
            return { loading: true }
        case FLEET_GET_PRODUCTS_SUCCESS:
            return { loading: false, success: true, productFromFleet: action.payload }
        case FLEET_GET_PRODUCTS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const FleetCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case FLEET_CREATE_REQUEST:
            return { loading: true }
        case FLEET_CREATE_SUCCESS:
            return { loading: false, success: true }
        case FLEET_CREATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const FleetUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case FLEET_UPDATE_REQUEST:
            
            return { loading: true }
        case FLEET_UPDATE_SUCCESS:
            
            return { loading: false, success: true }
        case FLEET_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        case RESET_FLEET_UPDATE_SUCCESS:
            return { success: false }
        default:
            return state
    }
}

export const FleetDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case FLEET_DELETE_REQUEST:
            return { loading: true }
        case FLEET_DELETE_SUCCESS:
            return { loading: false, success: true }
        case FLEET_DELETE_FAIL:
            return { loading: false, error: action.payload }
        case RESET_FLEET_DELETE_SUCCESS:
            return { success: false }
        default:
            return state
    }
}