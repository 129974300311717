export const HEADER_CATEGORIES_LIST_REQUEST = 'HEADER_CATEGORIES_LIST_REQUEST'
export const HEADER_CATEGORIES_LIST_SUCCESS = 'HEADER_CATEGORIES_LIST_SUCCESS'
export const HEADER_CATEGORIES_LIST_FAIL = 'HEADER_CATEGORIES_LIST_FAIL'

export const HEADER_CATEGORIES_CREATE_REQUEST = 'HEADER_CATEGORIE_CREATE_REQUEST'
export const HEADER_CATEGORIES_CREATE_SUCCESS = 'HEADER_CATEGORIE_CREATE_SUCCESS'
export const HEADER_CATEGORIES_CREATE_FAIL = 'HEADER_CATEGORIE_CREATE_FAIL'

export const HEADER_CATEGORIES_UPDATE_REQUEST = 'HEADER_CATEGORIE_UPDATE_REQUEST'
export const HEADER_CATEGORIES_UPDATE_SUCCESS = 'HEADER_CATEGORIE_UPDATE_SUCCESS'
export const HEADER_CATEGORIES_UPDATE_FAIL = 'HEADER_CATEGORIE_UPDATE_FAIL'

export const HEADER_CATEGORIES_DELETE_REQUEST = 'HEADER_CATEGORIE_DELETE_REQUEST'
export const HEADER_CATEGORIES_DELETE_SUCCESS = 'HEADER_CATEGORIE_DELETE_SUCCESS'
export const HEADER_CATEGORIES_DELETE_FAIL = 'HEADER_CATEGORIE_DELETE_FAIL'
