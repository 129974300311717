import React from 'react'
import './Stock.css'
import { useTranslation } from 'react-i18next';

function Stock({ stock }) {
    const { t, i18n } = useTranslation();

    return (
        <div className='stock'>
            {
                (stock && stock > 0) ?
                    <div className='en-stock'>{t('In stock')}</div> :
                    <div className='rupture'>{t('Out of stock')}</div>
            }
        </div>
    )
}

export default Stock