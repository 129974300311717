import axios from 'axios';
import { 
    AFTERSALE_ADD_DEMAND,
    AFTERSALE_REMOVE_DEMAND,
    AFTERSALE_RESET,


    // constantes de configuration

    AFTERSALE_CONFIG_LIST_REQUEST,
    AFTERSALE_CONFIG_LIST_SUCCESS,
    AFTERSALE_CONFIG_LIST_FAIL,

    AFTERSALE_CONFIG_ADD_REQUEST,
    AFTERSALE_CONFIG_ADD_SUCCESS,
    AFTERSALE_CONFIG_ADD_FAIL,

    AFTERSALE_CONFIG_UPDATE_REQUEST,
    AFTERSALE_CONFIG_UPDATE_SUCCESS,
    AFTERSALE_CONFIG_UPDATE_FAIL,

    AFTERSALE_CONFIG_DELETE_REQUEST,
    AFTERSALE_CONFIG_DELETE_SUCCESS,
    AFTERSALE_CONFIG_DELETE_FAIL,

    TYPE_AFTERSALE_CONFIG_LIST_REQUEST,
    TYPE_AFTERSALE_CONFIG_LIST_SUCCESS,
    TYPE_AFTERSALE_CONFIG_LIST_FAIL,

    TYPE_AFTERSALE_CONFIG_ADD_REQUEST,
    TYPE_AFTERSALE_CONFIG_ADD_SUCCESS,
    TYPE_AFTERSALE_CONFIG_ADD_FAIL,

    TYPE_AFTERSALE_CONFIG_UPDATE_REQUEST,
    TYPE_AFTERSALE_CONFIG_UPDATE_SUCCESS,
    TYPE_AFTERSALE_CONFIG_UPDATE_FAIL,

    TYPE_AFTERSALE_CONFIG_DELETE_REQUEST,
    TYPE_AFTERSALE_CONFIG_DELETE_SUCCESS,
    TYPE_AFTERSALE_CONFIG_DELETE_FAIL,
} from "../constants/afterSaleConstants";


export const addToAfterSale = (item, currentStep, chosenProtections, chosenReparations) => async (dispatch, getState) => {
    dispatch({
        type: AFTERSALE_ADD_DEMAND,
        payload: {
            item: item,
            currentStep, currentStep,
            chosenProtections: chosenProtections,
            chosenReparations: chosenReparations
        },
    });
    localStorage.setItem('afterSale', JSON.stringify(getState().afterSale));   
}

export const removeAfterSale = (id) => (dispatch, getState) => {
    dispatch({
        type: AFTERSALE_REMOVE_DEMAND,
        payload: id,
    });
    localStorage.setItem('afterSale', JSON.stringify(getState().afterSale));
}

export const clearAfterSale = () => (dispatch, getState) => {
    dispatch({
        type: AFTERSALE_RESET,
    });
    localStorage.setItem('afterSale', JSON.stringify({}));
}



// --------------------------------
// méthodes de configuration
// --------------------------------

export const listAfterSaleConfig = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: AFTERSALE_CONFIG_LIST_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            '/api/aftersale'
            );

        dispatch({
            type: AFTERSALE_CONFIG_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: AFTERSALE_CONFIG_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const addAfterSaleConfig = (name, description, tarif, days_to_do, type_after_sale, is_warranty) => async (dispatch, getState) => {
    try {
        dispatch({
            type: AFTERSALE_CONFIG_ADD_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/aftersale/add/`,
            { name: name, description: description, tarif: tarif, days_to_do: days_to_do, type_after_sale: type_after_sale, is_warranty: is_warranty },
            )

        dispatch({
            type: AFTERSALE_CONFIG_ADD_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: AFTERSALE_CONFIG_ADD_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const updateAfterSaleConfig = (id, name, description, tarif, days_to_do, type_after_sale, is_warranty) => async (dispatch, getState) => {
    try {
        dispatch({
            type: AFTERSALE_CONFIG_UPDATE_REQUEST,
        });
        
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.put(
            `/api/aftersale/update/`,
            { id: id, name: name, description: description, tarif: tarif, days_to_do: days_to_do, type_after_sale: type_after_sale, is_warranty: is_warranty },
            )

        dispatch({
            type: AFTERSALE_CONFIG_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: AFTERSALE_CONFIG_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const deleteAfterSaleConfig = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: AFTERSALE_CONFIG_DELETE_REQUEST,
        });
        
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/aftersale/del/`,
            { 'id': id },
            )

        dispatch({
            type: AFTERSALE_CONFIG_DELETE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: AFTERSALE_CONFIG_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const listTypeAfterSaleConfig = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: TYPE_AFTERSALE_CONFIG_LIST_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            '/api/type-aftersale'
            );
            
        dispatch({
            type: TYPE_AFTERSALE_CONFIG_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: TYPE_AFTERSALE_CONFIG_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const addTypeAfterSaleConfig = (name, description) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TYPE_AFTERSALE_CONFIG_ADD_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/type-aftersale/add/`,
            { 'name': name, 'description': description },
            )

        dispatch({
            type: TYPE_AFTERSALE_CONFIG_ADD_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: TYPE_AFTERSALE_CONFIG_ADD_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const updateTypeAfterSaleConfig = (id, name, description) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TYPE_AFTERSALE_CONFIG_UPDATE_REQUEST,
        });
        
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.put(
            `/api/type-aftersale/update/`,
            { 'id': id, 'name': name, 'description': description },
            )

        dispatch({
            type: TYPE_AFTERSALE_CONFIG_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: TYPE_AFTERSALE_CONFIG_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const deleteTypeAfterSaleConfig = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TYPE_AFTERSALE_CONFIG_DELETE_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/type-aftersale/del/`,
            { 'id': id },
            )


        dispatch({
            type: TYPE_AFTERSALE_CONFIG_DELETE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: TYPE_AFTERSALE_CONFIG_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}