
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import DeviceHistoryItem from './DeviceHistoryItem';

const DeviceHistory = ({ history }) => {
  const [operationName, setOperationName] = useState('');
  const [historique, setHistorique] = useState([]);
  const [date, setDate] = useState(null);

  

  useEffect(() => {
      setHistorique(history.filter((item) => !item.sav_history))
      
  }, []);

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">Historique</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Opération</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>N° Commande</TableCell>
                <TableCell>Utilisateur</TableCell>
                <TableCell>MAJ par</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historique.map((h, index) => (
                <DeviceHistoryItem historyItem={h} key={index} isCurrentData={h===historique[historique.length -1]} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default DeviceHistory;

