import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, ListGroup } from 'react-bootstrap'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Switch from "react-switch";
import { useTranslation } from 'react-i18next';


import Loader from '../../../../components/Loader/Loader'
import Select from '../../../../components/Select/Select'

import { listProducts, listNonImeiProducts } from '../../../../actions/productActions'
import { listCategories } from '../../../../actions/categoriesActions'
import { listUsers } from '../../../../actions/userActions'
import { getWBList, manageWBList } from '../../../../actions/wblistActions'

import './List.css'

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { ListItemAvatar } from '@mui/material';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function BlackList() {
    const { t, i18n } = useTranslation();

    const [blacklist, setBlacklist] = useState([]);

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const productList = useSelector(state => state.productList)
    const { error, loading, products, page, pages } = productList

    const productNonImeiList = useSelector(state => state.productNonImeiList)
    const { productsNonImei } = productNonImeiList

    const categoriesList = useSelector(state => state.categoriesList)
    const { categories } = categoriesList

    const userList = useSelector(state => state.userList)
    const { users } = userList

    const wBList = useSelector(state => state.wBList)
    const { wblist } = wBList

    const wBListCreate = useSelector(state => state.wBListCreate)
    const { wblistmaj, success: successWBListCreate, loading: loadingWBListCreate } = wBListCreate

    const [updateLoading, setUpdateLoading] = useState(false)

    const [productsData, setProductsData] = useState([])
    const [selectCategories, setSelectCategories] = useState([])
    const [selectUsers, setSelectUsers] = useState([])
    const [blackWbList, setBlackWbList] = useState([])

    const [categorie, setCategorie] = useState([])
    const [user, setUser] = useState({})
    const [userForSelect, setUserForSelect] = useState(null)
    const [categorieForSelect, setCategorieForSelect] = useState(null)
    const [toggleChecked, setToggleChecked] = useState(false)
    const [notValid, setNotValid] = useState(false)

    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const leftChecked = intersection(checked, [...left]);
    const rightChecked = intersection(checked, right);

    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [resetSelect, setResetSelect] = useState(false);

    const dispatch = useDispatch();

    const searchKeys = ['brand', 'name']
    const fuseOptions = {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: searchKeys
    };

    useEffect(() => {
        if (!products || products.length === 0)
            dispatch(listProducts());
        if (!productsNonImei || productsNonImei.length === 0)
            dispatch(listNonImeiProducts(userInfo ? userInfo.id_odoo : -1));
        dispatch(listCategories())
        dispatch(listUsers())
    }, [])

    useEffect(() => {
        if (successWBListCreate) {
            dispatch(getWBList(user.id))
            setUserForSelect(user.id)
            setCategorieForSelect(categorie.id)
        }
    }, [successWBListCreate])

    useEffect(() => {
        if (categories) {

            let values = []
            categories.sort((a, b) => a.id - b.id).forEach(category => {
                values.push({ value: category.id, label: category.name })
            })
            setSelectCategories(values)
        }
    }, [categories])

    useEffect(() => {
        if (users) {
            let values = []
            users.sort((a, b) => a.id - b.id).forEach(user => {
                if (user._id !== userInfo._id)
                    values.push({ value: user.id, label: user.name })
            })
            setSelectUsers(values)
        }
    }, [users])

    useEffect(() => {
        if (productsData && productsData.length > 0)
            setFilteredData(productsData)
    }, [productsData])

    useEffect(() => {
        if (blackWbList && blackWbList.length > 0 && !toggleChecked) {
            let wb = blackWbList.filter(wb => wb.type_list === 'white' && wb.user === user.id && wb.categorie === categorie.id).length > 0 ?
                blackWbList.filter(wb => wb.type_list === 'white' && wb.user === user.id && wb.categorie === categorie.id)[0] :
                null

            if (wb && wb !== null) {

                if (wb.categorie === categorie.id && wb.user === user.id && wb.type_list === 'white') {

                    let rightArr = []
                    if (wb.all) {
                        filteredData.forEach(product => {

                            rightArr.push(product)
                        })
                    }
                    else {
                        wb.wb_detail_list.forEach(wb_detail => {
                            let product = filteredData.filter(product => product.id_odoo === wb_detail.product_id_odoo)[0]
                            if (product && product !== undefined)
                                rightArr.push(filteredData.filter(product => product.id_odoo === wb_detail.product_id_odoo)[0])
                        })
                    }

                    setRight(rightArr)
                    setLeft(not(filteredData, rightArr))
                }
                else {
                    setLeft(filteredData)
                    setRight([])
                }
            }
        }
        else {
            setLeft(filteredData)
            setRight([])
        }

    }, [filteredData, blackWbList, categorie])

    useEffect(() => {
        if (wblist && wblist.length > 0 && !toggleChecked) {
            setBlackWbList(wblist.filter(wb => wb.type_list === 'black'))
        }
    }, [wblist])

    useEffect(() => {
        let productArray = []
        if (categorie === undefined || categorie.length === 0 || !user || user.username === undefined)
            setNotValid(true)
        else {
            setNotValid(false)
            if (categorie && products != undefined && products.length > 0 && productsNonImei != undefined && productsNonImei.length > 0) {
                (categorie.is_imei ? products : productsNonImei).forEach(product => {
                    if (product.categorie_odoo === categorie.name) {
                        productArray.push(product)
                    }
                })
                setProductsData(productArray)
            }
        }
    }, [categorie, user])

    useEffect(() => {
        if (!toggleChecked) {
            if (wblist && wblist.length > 0 && user && user.username !== undefined && categorie && categorie.is_product_category) {
                // filtrer ici sur la categorie pour sélectionner la liste products ou nonimeiproducts
                wblist.forEach(wb => {
                    if (wb.categorie === categorie.id && wb.user === user.id) {
                        let rightArr = []
                        wb.wb_detail_list.forEach(wb_detail => {
                            rightArr.push(filteredData.filter(product => product.id_odoo === wb_detail.product_id_odoo)[0])
                        })
                        setRight(rightArr)
                        setLeft(not(filteredData, rightArr))
                    }
                })
            }
        }
    }, [filteredData, toggleChecked])

    useEffect(() => {
        if (searchTerm !== '') {
            let list = filteredData && filteredData.length > 0 ? filteredData : productsData;
            setFilteredData(list.filter((f) =>
                f.brand.toLowerCase().includes(searchTerm.toLowerCase()) ||
                f.name.toLowerCase().includes(searchTerm.toLowerCase())
            ));
        } else {
            setFilteredData(productsData);
            setResetSelect(!resetSelect);
        }
    }, [searchTerm]);

    const onHandleCategorySelectChange = (value) => {
        setCategorieForSelect(value)
        setCategorie(categories.filter(category => category.id === value)[0])
    }

    const onHandleUserSelectChange = (value) => {
        let user = users.filter(user => user.id === value)[0]
        setUserForSelect(user.id)
        setUser(user)
        if (!toggleChecked)
            dispatch(getWBList(user.id))
    }

    const handleToggleChange = (checked) => {
        setToggleChecked(checked);
    };


    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat([...left]));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(productsData.filter((d) => rightChecked.includes(d))));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(productsData);
        setRight([]);
    };

    const reset = () => {
        setLeft(productsData);
        setRight([]);
    };

    const formatResult = (item) => {
        return (
            <>
                {
                    item && item != undefined ? (
                        <span>{item.brand} --- {item.name}</span>
                    ) : null
                }

            </>
        )
    };

    const customList = (items) => (
        <List dense component="div" role="list">
            {items && items.length > 0 && items.map((value) => {
                const labelId = `transfer-list-item-${value}-label`;

                return (
                    <ListItem
                        className='list-list-item'
                        key={value}
                        role="listitem"
                        button
                        onClick={handleToggle(value)}
                    >
                        <ListItemIcon>
                            <Checkbox
                                checked={checked.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                    "aria-labelledby": labelId
                                }}
                            />
                        </ListItemIcon>
                        <ListItemIcon>
                            <img className="list-img" src={value.device_image} alt="" />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${value.brand} ${value.name}`} />
                    </ListItem>
                );
            })}
            <ListItem />
        </List>
    );

    const saveBlackList = () => {
        let wb_detail_list = []
        let wb_detail_list_to_remove = []
        right.forEach(product => {
            wb_detail_list.push({ 'product_id_odoo': product.id_odoo })
        })
        left.forEach(product => {
            wb_detail_list_to_remove.push({ 'product_id_odoo': product.id_odoo })
        })

        dispatch(manageWBList(user.id, categorie.id, 'black', toggleChecked, wb_detail_list, wb_detail_list_to_remove))
    }

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        if (string.length === 0 || string === "") {
            setSearchTerm('');
            setFilteredData(productsData);
        }
        //setImeiInput(string);
        setSearchTerm(string);
    };

    const handleOnSelect = (item) => {
        // the item selected
        setFilteredData(filteredData.filter((product) => product.imei === item.imei));
        setSearchTerm(item.brand);
    };

    const handleOnFocus = () => {

    };

    return (
        <div className='list-container'>
            {loading || updateLoading || loadingWBListCreate ? <Loader /> :
                <>
                    <Row className='list-title-row'>
                        <h1>Liste noires</h1>
                    </Row>
                    <Row className='list-container-row'>
                        <Row className='list-switch-row'>
                            <Col>
                                <Switch
                                    className='list-switch'
                                    onChange={handleToggleChange}
                                    checked={toggleChecked}
                                    offColor="#2f86ca"
                                    boxShadow="0px 5px 10px rgba(0, 0, 0, 0.6)"
                                />
                                <span>{toggleChecked ? 'Catégorie entière' : 'Sélection des articles'}</span>
                            </Col>
                        </Row>
                        <Row className='list-select-row'>
                            <Col md={{ span: 4, offset: 0 }}>
                                <Select
                                    className='categories-select'
                                    name="categories-select"
                                    title="Utilisateur"
                                    value={userForSelect}
                                    options={selectUsers}
                                    onChangeFunc={(e) => onHandleUserSelectChange(e)}
                                />
                            </Col>
                            <Col md={{ span: 4, offset: 0 }}>
                                <Select
                                    className='categories-select'
                                    name="categories-select"
                                    title="Catégories"
                                    value={categorieForSelect}
                                    options={selectCategories}
                                    onChangeFunc={(e) => onHandleCategorySelectChange(e)}
                                />
                            </Col>
                        </Row>
                        <Row className='list-select-row'>
                            <Col md={{ span: 8, offset: 0 }}>
                                <ReactSearchAutocomplete
                                    items={filteredData && filteredData != undefined && filteredData.length > 0 ?
                                        filteredData
                                        : []}
                                    fuseOptions={fuseOptions}
                                    resultStringKeyName="name"
                                    onSearch={handleOnSearch}
                                    onSelect={handleOnSelect}
                                    onFocus={handleOnFocus}
                                    onClear={() => { setSearchTerm(''); setFilteredData(productsData); setResetSelect(!resetSelect); }}
                                    autoFocus
                                    placeholder={t("Search")}
                                    formatResult={formatResult}
                                    threshold={0.6} // 0 = exact match, 1 = everything matches
                                    ignoreLocation={true}
                                    maxResults={8}
                                    styling={{
                                        width: "100%",
                                        border: "1px solid #dfe1e5",
                                        borderRadius: "5px",
                                        backgroundColor: "white",
                                        boxShadow: "rgba(58, 53, 53, 0.2) 0px 1px 6px 0px",
                                        hoverBackgroundColor: "#eee",
                                        color: "#212121",
                                        fontSize: "12px",
                                        fontFamily: "Arial",
                                        iconColor: "grey",
                                        lineColor: "rgb(232, 234, 237)",
                                        placeholderColor: "grey",
                                        clearIconMargin: '0 5px 0 0',
                                        searchIconMargin: '0 0 0 5px',
                                        zIndex: 1,
                                    }}
                                />
                            </Col>
                        </Row>
                        {!toggleChecked ?
                            <Row className='list-list-row'>
                                <Col className='list-col' md={4}>
                                    <Row className='list-item' item>{customList(left)}</Row>
                                </Col>
                                <Col className='list-button-col' md={1}>
                                    <Row className='list-button-row'>
                                        <button
                                            className='list-button-add'
                                            onClick={handleAllRight}
                                            disabled={blacklist == undefined || blacklist.is_odoo}
                                            aria-label="move all right"
                                        ><i class="fa-solid fa-angles-right"></i></button>
                                    </Row>
                                    <Row className='list-button-row'>
                                        <button
                                            className='list-button-add'
                                            onClick={handleCheckedRight}
                                            disabled={blacklist == undefined || blacklist.is_odoo}
                                            aria-label="move selected right"
                                        ><i class="fa-solid fa-angle-right"></i></button>
                                    </Row>
                                    <Row className='list-button-row'>
                                        <button
                                            className='list-button-remove'
                                            onClick={handleCheckedLeft}
                                            disabled={blacklist == undefined || blacklist.is_odoo}
                                            aria-label="move selected left"
                                        ><i class="fa-solid fa-angle-left"></i></button>
                                    </Row>
                                    <Row className='list-button-row'>
                                        <button
                                            className='list-button-remove'
                                            onClick={handleAllLeft}
                                            disabled={blacklist == undefined || blacklist.is_odoo}
                                            aria-label="move all left"
                                        ><i class="fa-solid fa-angles-left"></i></button>
                                    </Row>
                                </Col>
                                <Col className='list-col' md={4}>
                                    <Row item>{customList(right)}</Row>
                                </Col>
                            </Row>
                            : null}
                        <Row className='list-button'>
                            <button
                                className='list-btn-cancel'
                                type='button'
                                disabled={false}
                                onClick={reset}
                            >
                                Reset
                            </button>
                            <button
                                className='list-btn-validate'
                                type='button'
                                disabled={notValid}
                                onClick={saveBlackList}
                            >
                                Valider
                            </button>
                        </Row>


                    </Row>
                </>
            }
        </div>
    )
}

export default BlackList