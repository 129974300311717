import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ListGroup, Image } from 'react-bootstrap'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link } from 'react-router-dom';

import { getAllWarranty } from '../../actions/warrantyActions';

import './SAV.css'
import { useTranslation } from 'react-i18next';

function SAV({ ticket }) {
    const [progressStatus, setProgressStatus] = useState("")
    const [statut, setStatut] = useState(0)
    const [isUnderWarranty, setIsUnderWarranty] = useState(false)

    const warrantyAll = useSelector(state => state.warrantyAll)
    const { loading: loadingWarranty, error: errorWarranty, warranties } = warrantyAll
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setProgressStatus(getProgressStatus(ticket.state))
    }, [])

    useEffect(() => {
        if (warranties && warranties.length > 0 && ticket.product) {
            const warranty = warranties.find(w => w.id_odoo === ticket.product.id_odoo);
            if (warranty) {
                const today = new Date();
                const year = today.getFullYear() - warranty.years;
                const month = today.getMonth() - warranty.months;
                const day = today.getDate();
                const warrantyDeadline = new Date(year, month, day);

            }
        }
    }, [warranties]);


    function getProgressStatus(statut) {
        switch (statut) {
            case 'draft':
                setStatut(0)
                return t('In process of validation')
            case 'confirmed':
                setStatut(1)
                return t('Validated')
            case 'ready':
                setStatut(2)
                return t('Ready')
            case 'under_repair':
                setStatut(3)
                return t('Being processed')
            case 'done':
                setStatut(4)
                return t('Complete')
            default:
                setStatut(0)
                return t('In process of validation')
        }
    }

    return (
        <>
            <Row className='sav-row'>
                <Col md={2}>
                    <img className="sav-device-img" src={ticket.fleet ? ticket.fleet.fleet_product_details[0].device_image : null} alt="" />
                </Col>
                <Col md={4}>
                    <Row>
                        <div className='sav-device-name'>{ticket.name}</div>
                    </Row>
                    <Row>
                        <div className='sav-device-imei'>IMEI : {ticket.imei}</div>
                    </Row>
                    <Row>
                        {ticket.warranty ?
                            <div className="sav-device-warranty">
                                <span>{t('Out of warranty')}</span>
                            </div>
                            :
                            <div className="sav-device-not-warranty">
                                <span>{t('Under warranty')}</span>
                            </div>
                        }
                    </Row>
                </Col>
                <Col md={4}>
                    <Row>
                        <div>{t('Application Status')}</div>
                    </Row>
                    <Row>
                        <ProgressBar label='node'>
                            <ProgressBar striped variant="success" now={25 * statut} key={1} />
                            <ProgressBar striped variant="warning" now={100 - (25 * statut)} key={2} />
                        </ProgressBar>
                    </Row>
                    <Row className='sav-device-progress-status-row'>
                        <div className='sav-device-progress-status'>{progressStatus}</div>
                    </Row>
                </Col>
                <Col md={2}>
                    <Row>
                        <Link
                            to={'/aftersale/tickets'}
                            state={{ data: ticket }}
                        >
                            <button
                                className='sav-device-btn-ticket'
                                type='button'
                                disabled={false}
                            >
                                {t('View ticket')}
                            </button>
                        </Link>
                    </Row>
                    <Row>
                        <div className='sav-device-ticket-date'>{t('Ticket opened on') + ' ' + new Date(ticket.create_date).toLocaleDateString("fr-FR")}</div>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default SAV