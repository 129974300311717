import {
    FLEET_USERS_LIST_REQUEST,
    FLEET_USERS_LIST_SUCCESS,
    FLEET_USERS_LIST_FAIL,

    FLEET_USERS_ADD_REQUEST,
    FLEET_USERS_ADD_SUCCESS,
    FLEET_USERS_ADD_FAIL,

    FLEET_USERS_ADD_BY_FILE_REQUEST,
    FLEET_USERS_ADD_BY_FILE_SUCCESS,
    FLEET_USERS_ADD_BY_FILE_FAIL,

    FLEET_USERS_DELETE_REQUEST,
    FLEET_USERS_DELETE_SUCCESS,
    FLEET_USERS_DELETE_FAIL,

    FLEET_USERS_UPDATE_REQUEST,
    FLEET_USERS_UPDATE_SUCCESS,
    FLEET_USERS_UPDATE_FAIL,

    RESET_FLEET_USER_ADD_SUCCESS,
    RESET_FLEET_USER_ADD_BY_FILE_SUCCESS,
    RESET_FLEET_USER_DELETE_SUCCESS,
    RESET_FLEET_USER_UPDATE_SUCCESS
}
    from '../constants/fleetUsersConstants.js'

export const fleetUsersListReducer = (state = { fleetUsers: [] }, action) => {
    switch (action.type) {
        case FLEET_USERS_LIST_REQUEST:
            return { loading: true, fleetUsers: [] }
        case FLEET_USERS_LIST_SUCCESS:
            return { loading: false, fleetUsers: action.payload }
        case FLEET_USERS_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const fleetUsersAddReducer = (state = {}, action) => {
    switch (action.type) {
        case FLEET_USERS_ADD_REQUEST:
            return { loading: true }
        case FLEET_USERS_ADD_SUCCESS:
            return { loading: false, success: true, fleetUser: action.payload }
        case FLEET_USERS_ADD_FAIL:
            return { loading: false, error: action.payload }
        case RESET_FLEET_USER_ADD_SUCCESS:
            return { ...state, success: false };
        default:
            return state
    }
}

export const fleetUsersAddByFileReducer = (state = {}, action) => {
    switch (action.type) {
        case FLEET_USERS_ADD_BY_FILE_REQUEST:
            return { loading: true }
        case FLEET_USERS_ADD_BY_FILE_SUCCESS:
            return { loading: false, success: true }
        case FLEET_USERS_ADD_BY_FILE_FAIL:
            return { loading: false, error: action.payload }
        case RESET_FLEET_USER_ADD_BY_FILE_SUCCESS:
            return { ...state, success: false };
        default:
            return state
    }
}

export const fleetUsersDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case FLEET_USERS_DELETE_REQUEST:
            return { loading: true }
        case FLEET_USERS_DELETE_SUCCESS:
            return { loading: false, success: true }
        case FLEET_USERS_DELETE_FAIL:
            return { loading: false, error: action.payload }
        case RESET_FLEET_USER_DELETE_SUCCESS:
            return { ...state, success: false };
        default:
            return state
    }
}

export const fleetUsersUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case FLEET_USERS_UPDATE_REQUEST:
            return { loading: true }
        case FLEET_USERS_UPDATE_SUCCESS:
            return { loading: false, success: true }
        case FLEET_USERS_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        case RESET_FLEET_USER_UPDATE_SUCCESS:
            return { ...state, success: false };
        default:
            return state
    }
}
