import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap'
import Circle from 'react-circle';
import Form from 'react-bootstrap/Form';
import Modal from 'react-modal';

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-enterprise/index.css";

import { validImei } from '../../../../utils/luhnUtils';
import { getTableHistory } from '../../../../utils/productUtils';


import './AfterSaleNew.css'
import './AfterSale.css'
import {
  addToAfterSale,
  clearAfterSale,

  listAfterSaleConfig,
  addAfterSaleConfig,
  listTypeAfterSaleConfig
} from '../../../../actions/afterSaleActions';

import { createTicket, resetTicketCreate } from '../../../../actions/ticketActions';
import { getFromFleet } from '../../../../actions/fleetActions';
import { getHistory } from '../../../../actions/historyActions';
import { getWarrantyByImei } from '../../../../actions/warrantyActions';

import Loader from '../../../../components/Loader/Loader';
import { useTranslation } from 'react-i18next';


function AftersaleNew() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const fleetDetail = location.state && location.state.f ? location.state.f : null;

  const afterSale = useSelector((state) => state.afterSale);
  const { demand } = afterSale;

  const fleetGet = useSelector((state) => state.fleetGet);
  const { productFromFleet } = fleetGet;

  const afterSaleConfigList = useSelector(state => state.afterSaleConfigList);
  const { error, afterSaleConfig, loading: loadingAfterSaleConfigList } = afterSaleConfigList;

  const typeAfterSaleConfigList = useSelector(state => state.typeAfterSaleConfigList);
  const { typeAfterSaleConfig, loading: loadingTypeAfterSaleConfig } = typeAfterSaleConfigList;

  const getHistoryList = useSelector((state) => state.getHistoryList);
  const { history } = getHistoryList;

  const warrantyGetByImei = useSelector((state) => state.warrantyGetByImei);
  const { warranty } = warrantyGetByImei;

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const ticketCreate = useSelector((state) => state.ticketCreate)
  const { loading: loadingTicketCreate, success: successTicketCreate, error: errorTicketCreate, ticket } = ticketCreate

  const [imei, setImei] = useState("");
  const [isValidImei, setIsValidImei] = useState();
  const [item, setItem] = useState();
  const [imeiMessage, setImeiMessage] = useState("");
  const [tableHistory, setTableHistory] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [prestaSav, setPrestaSav] = useState();
  const [estimatedPrice, setEstimatedPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [chosenReparations, setChosenReparations] = useState([]);
  const [chosenProtections, setChosenProtections] = useState([]);
  const [information, setInformation] = useState("");

  const [stepContent, setStepContent] = useState();
  const [btnNextDisabled, setBtnNextDisabled] = useState(true);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalFinaleIsOpen, setFinaleIsOpen] = React.useState(false);
  const [modalResumeIsOpen, setResumeIsOpen] = React.useState(false);

  const [circleTerminal, setCircleTerminal] = useState(0);
  const [circleDevis, setCircleDevis] = useState(0);
  const [circleTicket, setCircleTicket] = useState(0);

  const [ticketName, setTicketName] = useState("");
  const [conditionsValidated, setConditionsValidated] = useState(false);
  const [ticketQuoteUrl, setTicketQuoteUrl] = useState("")

  const gridStyle = { minHeight: 200 };

  const conditions = t('I acknowledge that the price indicated is an estimate subject to change. In this case, I will be informed as soon as possible in order to validate the management of my device.');
  const sentText = t('Thank you for creating your ticket, it will be taken care of as soon as possible.')
  const followText = t('You can follow the progress of your ticket in your personal space.')
  const resumeText = t('A ticket was being created, do you want to resume where you left off ?')

  const columns = [
    { name: "operation", header: t('operation'), minWidth: 50, defaultFlex: 2 },
    { name: "reference", header: t('reference'), maxWidth: 1000, defaultFlex: 1 },
    { name: "demandeur", header: t('applicant'), maxWidth: 1000, defaultFlex: 1 },
    { name: "date", header: t('date'), maxWidth: 1000, defaultFlex: 1 }
  ];

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '30%',
    },
  };

  let subtitle;

  useEffect(() => {
    dispatch(listTypeAfterSaleConfig());

    if (checkDemand()) {
      setResumeIsOpen(true);
    }

    if (fleetDetail) {
      setImei(fleetDetail.imei);
      checkImei(fleetDetail.imei);
    }
  }, []);

  useEffect(() => {
    if (successTicketCreate && !modalFinaleIsOpen) {
      dispatch(resetTicketCreate());
      navigate('/aftersale/devices');
    }
  }, [successTicketCreate, modalFinaleIsOpen]);

  useEffect(() => {
    if (typeAfterSaleConfig && typeAfterSaleConfig.length > 0) {
      dispatch(listAfterSaleConfig());
    }
  }, [typeAfterSaleConfig]);

  //TODO Probablement à supprimer
  useEffect(() => {
    if (afterSaleConfig && afterSaleConfig.length > 0) {
      afterSaleConfig.forEach(config => {
        if (config.typeAfterSale === 1) {
          setPrestaSav(config);
        }
        else if (config.typeAfterSale === 2) {
          setPrestaSav(config);
        }
      })
    }
  }, [afterSaleConfig]);

  useEffect(() => {
    setStepContent(displayStepContent())
  }, [price])


  useEffect(() => {
    if (chosenProtections.length > 0 || chosenReparations.length > 0) {
      dispatch(addToAfterSale(item, currentStep, chosenProtections, chosenReparations))

      let protectionPrice = chosenProtections.reduce(
        (acc, protection) => acc + protection.tarif, 0
      )
      let reparationPrice = chosenReparations.reduce(
        (acc, reparation) => acc +
          warranty && warranty.valid && reparation.is_warranty ? 0 : reparation.tarif,
        0
      )
      setPrice(reparationPrice + protectionPrice)
    }
    else
      setPrice(0)
  }, [dispatch, chosenProtections, chosenReparations])


  useEffect(() => {
    if (checkDemand())
      dispatch(addToAfterSale(demand.item, demand.currentStep, demand.chosenProtections, demand.chosenReparations))
    else
      dispatch(addToAfterSale(item, currentStep, chosenProtections, chosenReparations))
    UpdateCircles();
    setStepContent(displayStepContent())
    disableBtnNext();
  }, [item, currentStep])

  useEffect(() => {
    if (productFromFleet) {
      setItem({ ...productFromFleet, imei: imei });

      dispatch(getWarrantyByImei(productFromFleet.id_odoo, imei));
      setCircleTerminal(33);
    }
  }, [productFromFleet])

  useEffect(() => {
    if (imei)
      dispatch(getHistory(imei))
  }, [imei])

  useEffect(() => {
    if (history) {
      setTableHistory(getTableHistory(history));
    }
  }, [history])

  useEffect(() => {
    if(ticket)
      setTicketQuoteUrl(ticket.pdf_url)
  }, [ticket])

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openFinaleModal() {
    setFinaleIsOpen(true);
  }

  function afterOpenFinaleModal() {
    subtitle.style.color = '#f00';
  }

  function closeFinaleModal() {
    setFinaleIsOpen(false);
  }

  function afterOpenResumeModal() {
    subtitle.style.color = '#f00';
  }

  function closeResumeModal() {
    dispatch(addToAfterSale(item, currentStep, chosenProtections, chosenReparations))
    setResumeIsOpen(false);
  }

  function validateTicket() {
    setIsOpen(false);
    dispatch(clearAfterSale)
    setFinaleIsOpen(true);
    setCircleTicket(100);

    // Créer le ticket côté back
    let lines = [];
    chosenReparations.forEach(reparation => {
      lines.push({
        name: reparation.name,
        product_uom_qty: 1
      })
    })

    chosenProtections.forEach(protection => {
      lines.push({
        name: protection.name,
        product_uom_qty: 1
      })
    })

    let ticket = {
      imei: item.imei,
      description: information,
      product_id: item.id_odoo,
      product_qty: 1,
      partner_id: userInfo.id_odoo,
      schedule_date: false,
      guarantee_limit: false,
      state: 'draft',
      lines: lines
    }

    dispatch(createTicket(ticket))
  }

  const checkImei = (value) => {
    setIsValidImei(validImei(value));
    setImei(value);
  }

  function checkDemand() {
    if (demand.item && demand.item.id && demand.currentStep) {
      return true;
    }
    return false;
  }

  function resumeDemand() {
    setItem(demand.item);
    setCurrentStep(demand.currentStep);
    setChosenProtections(demand.chosenProtections);
    setChosenReparations(demand.chosenReparations);

    closeResumeModal();
    setEstimatedPrice(displayStepContent())
  }

  function UpdateCircles() {
    switch (currentStep) {
      case 2:
        setCircleTerminal(66);
        setCircleDevis(0);
        setCircleTicket(0);
        break;
      case 3:
        setCircleTerminal(100);
        setCircleDevis(33);
        setCircleTicket(0);
        break;
      case 4:
        setCircleTerminal(100);
        setCircleDevis(100);
        setCircleTicket(50);
        break;
      case 5:
        setCircleTerminal(100);
        setCircleTicket(100);
        break;
    }
  }

  function disableBtnNext() {
    switch (currentStep) {
      case 1:
        setBtnNextDisabled(!(item));
        break;
      case 2:
        //TODO A Gérer
        //setBtnNextDisabled(!(prestaSav.reparations.filter((presta) => (presta.chosen ? presta.chosen === true : false)).length > 0));
        setBtnNextDisabled(false);
        break;
      case 3:
        setBtnNextDisabled(false);
        break;
      case 4:
        setBtnNextDisabled(false);
        break;
    }
  }

  const displayStepContent = () => {
    switch (currentStep) {
      case 1:
        return (firstStep());
      case 2:
        return (secondStep());
      case 3:
        return (thirdStep());
      case 4:
        return (fourthStep());
      // case 5:
      //   return(fifthStep());
    }
  };

  const firstStep = () => {
    if (item) {
      let fleet_product_details = item.fleet_product_details && item.fleet_product_details.length > 0 ? item.fleet_product_details[0] : null;
      //TODO : gestion historique 
      if (history && tableHistory && tableHistory.length > 0) {
        return (
          <>
            <Row className='aftersalenew-img-row'>
              <img className="aftersalenew-device-first-img" src={fleet_product_details.device_image} alt="" />
            </Row>
            <Row>
              <div className='aftersalenew-item-name'>{fleet_product_details.name}</div>
            </Row>
            <Row className='aftersalenew-history-row'>
              <ReactDataGrid
                idProperty="id"
                columns={columns}
                dataSource={tableHistory}
                style={gridStyle}
              />
            </Row>
          </>
        )
      }
      else {
        return (
          <Col md={6}>
            <span className="historyMessage">{t('No history found, the IMEI entered is not part of your fleet')}</span>
          </Col>
        )
      }
    }
    else return null;
  }

  const secondStep = () => {
    return (
      <>
        {
          loadingAfterSaleConfigList ? <Loader /> :
            <Row className='aftersalenew-row'>
              <Col className='aftersalenew-col' md={3}>
                <Row className='aftersalenew-device-title'>
                  <div>
                    <span>{item.fleet_product_details[0].brand} {item.fleet_product_details[0].name}</span>
                  </div>
                </Row>
                <Row className='aftersalenew-device-img-row'>
                  <img className="aftersalenew-device-img" src={item.fleet_product_details[0].device_image} alt="" />
                </Row>

                <Row>
                  {warranty && warranty.valid ?
                    <div className="aftersalenew-warranty">
                      <span>{t('Under warranty')}</span>
                    </div>
                    :
                    <div className="aftersalenew-not-warranty">
                      <span>{t('Out of Warranty')}</span>
                    </div>
                  }
                </Row>
              </Col>
              <Col className='aftersalenew-presta' md={{ span: 4, offset: 2 }}>
                {afterSaleConfig && afterSaleConfig.length > 0 ?
                  afterSaleConfig.filter((config) => config.typeAfterSale === 1).map((reparation) => {
                    return (
                      <Row key={reparation.id}>
                        <Col>
                          <input
                            type="checkbox"
                            id={reparation.id}
                            name={reparation.name}
                            value={reparation.id}
                            defaultChecked={chosenReparations.find((chosenReparation) => chosenReparation.id === reparation.id) ? true : false}
                            onChange={(event) => onChangeReparationHandler(event, reparation.id)}
                          />
                          <label className="filter-checkbox-label" htmlFor={reparation.name}>{reparation.name}</label>
                        </Col>
                      </Row>
                    )
                  })
                  : null}
              </Col>
              <Col className='aftersalenew-col' md={4}>
                <Row className='aftersalenew-total'>
                  <span>{t('Total')} : {warranty && warranty.valid ? 0 : price.toFixed(2)} {' '}
                    € HT</span>
                </Row>
              </Col>
            </Row>

        }
      </>
    );
  }

  const thirdStep = () => {
    let fleet_product_details = item.fleet_product_details && item.fleet_product_details.length > 0 ? item.fleet_product_details[0] : null;
    return (
      <>
        <Row className='aftersalenew-row'>
          <Col className='aftersalenew-col' md={3}>
            <Row className='aftersalenew-device-title'>
              <div>
                <span>{fleet_product_details.brand} {fleet_product_details.name}</span>
              </div>
            </Row>
            <Row className='aftersalenew-device-img-row'>
              <img className="aftersalenew-device-img" src={fleet_product_details.device_image} alt="" />
            </Row>
            {/* TODO : gestion historique 
            <Row className='aftersalenew-device-warranty-row'>
              {new Date(item.history.order.purchase_date)
                .setFullYear(new Date(item.history.order.purchase_date)
                  .getFullYear() + item.warranty)
                > new Date() ?
                <Row className="aftersalenew-warranty">
                  <span>Garantie</span>
                </Row>
                :
                <Row className="aftersalenew-not-warranty">
                  <span>Hors Garantie</span>
                </Row>
              }
            </Row>
            */}
          </Col>
          <Col className='aftersalenew-col' md={4}>
            {chosenReparations.length > 0 ?
              <>
                <Row className='aftersale-row-name'>
                  <span>{t('Repairs')}</span>
                </Row>
                <Row className='aftersale-row-presta'>

                  {chosenReparations.map((cr) => {
                    return (<div className='aftersalenew-reparation-row'>{cr.name}</div>)
                  })}

                </Row>
              </>
              : null
            }
            <Row className='aftersale-row-name'>
              <span>{t('Benefits')}</span>
            </Row>
            <Row className='aftersale-row-protections'>
              {afterSaleConfig && afterSaleConfig.length > 0 ?
                afterSaleConfig.filter((config) => config.typeAfterSale === 2).map((protection) => {
                  return (
                    <>
                      <Row key={protection.id}>
                        <Col className='aftersale-row-protections-col'>
                          <input
                            type="checkbox"
                            id={protection.id}
                            name={protection.name}
                            value={protection.id}
                            defaultChecked={chosenProtections.find((chosenProtection) => chosenProtection.id === protection.id) ? true : false}
                            onChange={(event) => onChangeProtectionHandler(event, protection.id)}
                          />
                        </Col>
                        <Col className='aftersale-row-protections-col'>
                          <label className="filter-checkbox-label" htmlFor={protection.name}>{protection.name}</label>
                        </Col>
                      </Row>
                    </>
                  )
                })
                : null}
            </Row>
          </Col>
          <Col className='aftersalenew-col' md={4}>
            <Row className='aftersalenew-total'>
              <span>{t('Total')} : {warranty && warranty.valid ? 0 : price.toFixed(2)} {' '}
                € HT</span>
            </Row>
            <Row className='aftersale-new-informations'>
              <span>{t('Add information')}</span>
              <textarea name="body"
                onChange={() => setInformation(document.querySelector('textarea').value)}
                defaultValue={information}
              />
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  const fourthStep = () => {
    let fleet_product_details = item.fleet_product_details && item.fleet_product_details.length > 0 ? item.fleet_product_details[0] : null;
    return (
      <>
        <Row className='aftersalenew-row'>
          <Col className='aftersalenew-col' md={3}>
            <Row className='aftersalenew-device-title'>
              <div>
                <span>{fleet_product_details.brand} {fleet_product_details.name}</span>
              </div>
            </Row>
            <Row className='aftersalenew-device-img-row'>
              <img className="aftersalenew-device-img" src={fleet_product_details.device_image} alt="" />
            </Row>
            {/* TODO : gestion historique 
            <Row className='aftersalenew-device-warrant-row'>
              {new Date(item.history.order.purchase_date)
                .setFullYear(new Date(item.history.order.purchase_date)
                  .getFullYear() + item.warranty)
                > new Date() ?
                <Row className="aftersalenew-warranty">
                  <span>Garantie</span>
                </Row>
                :
                <Row className="aftersalenew-not-warranty">
                  <span>Hors Garantie</span>
                </Row>
              }
            </Row>
             */}
          </Col>
          <Col className='aftersalenew-col' md={4}>
            <Row>
              <span>{t('Summary')}</span>
            </Row>
            <Row className='aftersale-row-recap'>
              {chosenReparations.length > 0 ?
                chosenReparations.map((cr) => {
                  return (<div className='aftersalenew-reparation-row'>{cr.name}</div>)
                })
                : null
              }
            </Row>
            <Row className='aftersale-row-recap'>
              {chosenProtections.length > 0 ?
                chosenProtections.map((cp) => {
                  return (<div className='aftersalenew-reparation-row'>{cp.name}</div>)
                })
                : null
              }
            </Row>
          </Col>
          <Col md={4} className='aftersalenew-col'>
            <Row className='aftersalenew-total'>
              <span>{t('Total')} : {warranty && warranty.valid ? 0 : price.toFixed(2)} {' '}
                € HT</span>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 8, offset: 4 }}>
            <Row className='aftersalenew-backupinfo-row'>
              <Col md={2}>
                <i class="fa-solid fa-triangle-exclamation fa-4x aftersalenew-triangle"></i>
              </Col>
              <Col>
                <span>{t('Backup-message')}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  const onChangeReparationHandler = (event, id) => {
    let arr = [];

    if (event.target.checked) {
      chosenReparations.map((cr) => {
        if (cr.id != id)
          arr.push(cr);
      })

      if (afterSaleConfig && afterSaleConfig.length > 0) {
        afterSaleConfig.filter((config) => config.typeAfterSale === 1).map((reparation) => {
          if (reparation.id == id) {
            arr.push(reparation);
          }
        })
      }
    }
    else {
      chosenReparations.map((cr) => {
        if (cr.id != id) {
          arr.push(cr);
        }
      })
    }

    disableBtnNext();
    setChosenReparations([...arr]);
  }

  const onChangeProtectionHandler = (event, id) => {
    let arr = [];

    if (event.target.checked) {
      chosenProtections.map((cr) => {
        if (cr.id != id)
          arr.push(cr);
      })

      if (afterSaleConfig && afterSaleConfig.length > 0) {
        afterSaleConfig.filter((config) => config.typeAfterSale === 2).map((protection) => {
          
          if (protection.id == id) {
            arr.push(protection);
          }
        })
      }
    }
    else {
      chosenProtections.map((cp) => {
        if (cp.id != id) {
          arr.push(cp);
        }
      })
    }

    setCircleDevis(66);
    disableBtnNext();
    setChosenProtections([...arr]);
  }

  const submitHandler = (e) => {
    if (validImei(imei)) {
      setIsValidImei(true);
      dispatch(getFromFleet(imei));
    }
    else {
      setIsValidImei(false);
      setImeiMessage(t('The IMEI number') + ' ' + imei + ' ' + t('is not valid'))
    }
  }

  const nextHandler = () => {
    if (currentStep === 4)
      openModal();
    else
      setCurrentStep(currentStep + 1);
  }

  const previousHandler = () => {
    setCurrentStep(currentStep - 1);
  }

  const downloadRepairQuote = () => {
    window.open(ticketQuoteUrl, '_blank');
  }

  return (
    <>
      <Row className='aftersale-state-row'>
        <Col className='aftersale-state-col' md={3}>
          <Row>
            <Circle
              progress={circleTerminal}
            />
          </Row>
          <Row>
            {t('device')}
          </Row>
        </Col>
        <Col className='aftersale-state-col' md={1}>
          <i class="fa-solid fa-arrow-right-long fa-3x aftersale-arrow"></i>
        </Col>
        <Col className='aftersale-state-col' md={3}>
          <Row>
            <Circle
              progress={circleDevis}
            />
          </Row>
          <Row>
            {t('My quote')}
          </Row>
        </Col>
        <Col className='aftersale-state-col' md={1}>
          <i class="fa-solid fa-arrow-right-long fa-3x aftersale-arrow"></i>
        </Col>
        <Col className='aftersale-state-col' md={3}>
          <Row>
            <Circle
              progress={circleTicket}
            />
          </Row>
          <Row>
            {t('Create my ticket')}
          </Row>
        </Col>
      </Row>
      <Row className='aftersale-content-row'>
        {currentStep === 1 ?
          <Row className='aftersale-imei-row'>
            <Row>
              <Col md={{ span: 4, offset: 3 }}>
                <Form.Control
                  type="text"
                  id="imei-input"
                  aria-describedby="imei"
                  value={imei}
                  placeholder={t('IMEI number')}
                  onChange={(e) => checkImei(e.target.value)}
                />
              </Col>
              <Col md={2}>
                <button className='aftersale-imei-search' type='submit' onClick={submitHandler} disabled={!isValidImei || !tableHistory || tableHistory.length === 0}>
                  {t('Search')}
                </button>
              </Col>
            </Row>
            <Row className='aftersale-imei-message'>
              <Col md={{ span: 4, offset: 4 }}>
                {(!isValidImei && imei.length === 15) ? <span className="imeiMessage">{t('The IMEI entered is not valid.')}</span> : null}
                {(imei && imei.length > 15) ? <span className="imeiMessage">{t('The IMEI number must be 15 digits.')}</span> : null}
              </Col>
            </Row>
          </Row>
          : null
        }
        <Row className='aftersale-stepcontent'>
          {stepContent}
        </Row>
        <Row className='aftersale-btn-row'>
          <Col md={{ span: 2, offset: 1 }}>
            <button
              className='aftersale-btn-previous'
              type='button'
              hidden={currentStep === 1}
              disabled={false}
              onClick={previousHandler}
            >
              {t('Previous')}
            </button>
          </Col>
          <Col md={{ span: 2, offset: 6 }}>
            {
              (isValidImei && currentStep > 1) || (item && currentStep == 1) ?
                <button
                  className='aftersale-btn-next'
                  type='button'
                  disabled={btnNextDisabled}
                  onClick={nextHandler}
                >
                  {currentStep < 4 ? t('Next') : t('Send')}
                </button>
                : null
            }
          </Col>
        </Row>
      </Row>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <Row className='aftersalenew-modal-title'>
          <span>{t('Ticket creation')}</span>
        </Row>
        <Row className='aftersalenew-modal-conditions'>
          <Col md={1}>
            <input
              type="checkbox"
              id={'conditions'}
              name={'conditions'}
              defaultChecked={false}
              onChange={(event) => setConditionsValidated(event.target.checked)}
            />
          </Col>
          <Col>
            {conditions}
          </Col>
        </Row>
        <Row className='aftersalenew-modal-button'>
          <Col>
            <button
              className='aftersale-modal-btn-cancel'
              type='button'
              hidden={currentStep === 1}
              disabled={false}
              onClick={closeModal}
            >
              {t('Cancel')}
            </button>
          </Col>
          <Col>
            <button
              className='aftersale-modal-btn-validate'
              type='button'
              hidden={currentStep === 1}
              disabled={!conditionsValidated}
              onClick={validateTicket}
            >
              {t('Validate')}
            </button>
          </Col>
        </Row>
      </Modal>

      <Modal
        isOpen={modalFinaleIsOpen}
        onAfterOpen={afterOpenFinaleModal}
        onRequestClose={closeFinaleModal}
        style={customStyles}
      >
        <Row className='aftersalenew-finalmodal-title'>
          <Col className='aftersalenew-finalmodal-title-col' md={1}>
            <i class="fa-regular fa-paper-plane fa-4x"></i>
          </Col>
        </Row>
        <Row className='aftersalenew-modal-conditions'>
          <span>{sentText}</span>
        </Row>
        <Row className='aftersalenew-modal-conditions'>
          <span>{followText}</span>
        </Row>
        <Row className='aftersalenew-modal-button'>
          <Col>
            <button
              className='aftersale-modal-btn-close'
              type='button'
              disabled={false}
              onClick={closeFinaleModal}
            >
              {t('Close')}
            </button>
          </Col>
          <Col>
            <button
              className='aftersale-new-modal-btn-download'
              type='button'
              disabled={false}
              onClick={downloadRepairQuote}
            >
              {t('Download')}
            </button>
          </Col>
        </Row>
      </Modal>

      <Modal
        isOpen={modalResumeIsOpen}
        onRequestClose={closeResumeModal}
        style={customStyles}
        appElement={document.getElementById("root")}
      >
        <Row className='aftersalenew-resumemodal-title'>
          <Col className='aftersalenew-resumemodal-title-col' md={1}>
            <span>{('Resume')}</span>
          </Col>
        </Row>
        <Row className='aftersalenew-modal-conditions'>
          <span>{resumeText}</span>
        </Row>
        <Row className='aftersalenew-modal-button'>
          <Col>
            <button
              className='aftersale-modal-btn-cancel'
              type='button'
              onClick={closeResumeModal}
            >
              {t('New ticket')}
            </button>
          </Col>
          <Col>
            <button
              className='aftersale-modal-btn-validate'
              type='button'
              onClick={resumeDemand}
            >
              {t('Continue')}
            </button>
          </Col>
        </Row>
      </Modal>


    </>
  )
}

export default AftersaleNew;