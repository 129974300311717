import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import DataGrid, { Row as DataGridRow, Column, textEditor } from 'react-data-grid';
import { Row, Col, ListGroup, Image, Container, Form } from 'react-bootstrap'

import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import ReactTooltip from 'react-tooltip';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import Modal from 'react-modal';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import 'react-data-grid/lib/styles.css';
import {
  Menu,
  MenuItem,
  List,
  ListItem,
  Collapse,
  ListItemIcon,
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserPlus,
  faUserEdit,
  faUserMinus,
  faEllipsisVertical,
  faFileExcel,
  faXmark,
  faFileCirclePlus,
  faUser,
  faSimCard,
  faLink,
  faLinkSlash,
  faBook,
  faLock
} from '@fortawesome/free-solid-svg-icons';

import {
  listSims,
  addSim,
  addSimByFile,
  addPukByFile,
  deleteSim,
  updateSim,
  resetAddSim,
  resetUpdateSim,
  resetDeleteSim,
  resetPukSucess
} from '../../../../actions/simActions';

import excellogo from '../../../../assets/images/excel-logo.png';
import UserModal from '../Fleet/UserModal/UserModal';
import ConfirmationModal from '../../../../components/Modal/ConfirmationModal';

import './FleetSims.css';
import {
  listFleetUsers,
  addFleetUser,
  updateFleetUser,
  resetFleetUserAddSuccess,
  resetFleetUserUpdateSuccess
} from '../../../../actions/fleetUsersActions';

import { t } from 'i18next';
import { useTranslation } from 'react-i18next';



function FleetSims() {

  const dispatch = useDispatch()
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [simCardForm, setSimCardForm] = useState({ id: null, number: '', phone_number: '', puk: '' });
  const [simCard, setSimCard] = useState({ id: null, number: '', phone_number: '', puk: '' });

  const simList = useSelector(state => state.simList)
  const { loading, error, sims } = simList

  const simAdd = useSelector(state => state.simAdd)
  const { loading: loadingAdd, error: errorAdd, sim: simAddResponse, success: successAdd } = simAdd

  const simUpdate = useSelector(state => state.simUpdate)
  const { loading: loadingUpdate, error: errorUpdate, sim: simUpdateResponse, success: successUpdate } = simUpdate

  const simDelete = useSelector(state => state.simDelete)
  const { loading: loadingDelete, error: errorDelete, sim: simDeleteResponse, success: successDelete } = simDelete

  const fleetUsersList = useSelector(state => state.fleetUsersList)
  const { error: errorFleetUsersList, loading: loadingFleetUsersList, fleetUsers } = fleetUsersList

  const fleetUsersAdd = useSelector(state => state.fleetUsersAdd)
  const { error: errorAddUser, loading: loadingAddUser, success: successAddUser } = fleetUsersAdd

  const fleetUsersUpdate = useSelector(state => state.fleetUsersUpdate)
  const { error: errorUpdateUser, loading: loadingUpdateUser, success: successUpdateUser } = fleetUsersUpdate

  const pukAdd = useSelector(state => state.pukAdd)
  const { loading: loadingPukAdd, error: errorPukAdd, sim: simPukAddResponse, success: successPukAdd } = pukAdd

  const [rows, setRows] = useState([]);
  const [informationMessage, setInformationMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortedRows, setSortedRows] = useState(rows);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [pukIsOpen, setPukIsOpen] = React.useState(false);
  const [userModalIsOpen, setUserModalIsOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openInformationDialog, setOpenInformationDialog] = useState(false);
  const [fleetUsersModalOpen, setFleetUsersModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedSim, setSelectedSim] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const msgAddSim = t('Add a SIM card');
  const msgNoSim = t('No SIM card is available');
  const msgAdduserbyfile = t('Add lines via file');
  const msgAddPukbyfile = t('Add PUK codes via file');
  const msgExcelDownload = t('Download data to excel file');
  const msgFileExample = t('Download sample file');

  const excelUrl = "http://localhost:8000/media/example-files/importFlotteSims.csv";
  const excelPukUrl = "http://localhost:8000/media/example-files/importFlotteSimsPuk.csv";

  const searchKeys = ['number', 'phone_number']
  const fuseOptions = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: searchKeys
  };

  const [pukModalOpen, setPukModalOpen] = useState(false);
  const [puk, setPuk] = useState(null);
  const [modalPuk, setModalPuk] = useState(null)



  useEffect(() => {
    dispatch(listSims())
    if (fleetUsers && fleetUsers.length === 0)
      dispatch(listFleetUsers())
  }, []);

  useEffect(() => {
    if (successAdd || successDelete || successUpdate || successAddUser || successUpdateUser) {
      handleSuccessDialogOpen();
      setSelectedUser(null);
      dispatch(listSims())

      dispatch(resetAddSim());
      dispatch(resetUpdateSim());
      dispatch(resetDeleteSim());
      dispatch(resetFleetUserAddSuccess());
      dispatch(resetFleetUserUpdateSuccess());
    }
  }, [successAdd, successDelete, successUpdate, successAddUser, successUpdateUser]);

  useEffect(() => {
    if (sims && sims != undefined && sims.length > 0) {
      setRows(getSimRowsFromFilteredSimsData(sims))
    }
  }, [sims])

  useEffect(() => {
    if (rows && rows != undefined && rows.length > 0) {
      setSortedRows(rows);
    }
    else if (rows && rows != undefined && rows.length === 0)
      setSortedRows([]);
  }, [rows]);

  useEffect(() => {
    if (searchTerm !== '') {
      let list = sortedRows && sortedRows.length > 0 ? sortedRows : rows;
      setSortedRows(list.filter((f) =>
        f.number.toLowerCase().includes(searchTerm.toLowerCase()) ||
        f.phone_number.toLowerCase().includes(searchTerm.toLowerCase())
      ));
    } else {
      setSortedRows(rows);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (selectedUser && selectedSim) {


      let fleet_user = {
        id: selectedUser.id,
        lastname: selectedUser.lastname,
        firstname: selectedUser.firstname,
        entity: selectedUser.entity,
        role: selectedUser.role,
        fleet_sim: selectedSim,
      }
      dispatch(updateFleetUser(fleet_user))
    }
  }, [selectedUser, selectedSim])

  useEffect(() => {

  }, [sortedRows])

  useEffect(() => {
    if (successPukAdd)
      dispatch(listSims())
    dispatch(resetPukSucess())
  }, [successPukAdd])

  const columns = [
    {
      key: 'number', name: t('sim card number'), editable: true,
      editor: textEditor, cellClass: 'cell-align-center',
      headerRenderer: (props) => (
        <HeaderCellRenderer
          {...props}
          onHeaderClick={onHeaderClick}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
      ),
    },
    {
      key: 'phone_number', name: t('Line number'), editable: true,
      editor: textEditor, cellClass: 'cell-align-center',
      headerRenderer: (props) => (
        <HeaderCellRenderer
          {...props}
          onHeaderClick={onHeaderClick}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
      ),
    },
    {
      key: 'fleet_user',
      name: t('user'),
      headerRenderer: (props) => (
        <HeaderCellRenderer
          {...props}
          onHeaderClick={onHeaderClick}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
      ),
      formatter: ({ row }) => <UserCell row={row} />,
      cellClass: 'cell-align-center',
      sortable: true
    },
    {
      key: 'actions',
      name: t('actions'),
      headerRenderer: () => <div style={{ textAlign: 'center' }}>Actions</div>,
      formatter: ({ row }) => <ActionsCell row={row} />,
      cellClass: 'cell-align-center',
      width: 50,
    },
  ];

  const handleSuccessDialogOpen = () => {
    setOpenSuccessDialog(true);
  };

  const handleSuccessDialogClose = () => {

    setOpenSuccessDialog(false);
  };

  function getSimRowsFromFilteredSimsData(data) {
    let simRows = [];

    data.forEach((item) => {
      let row = {};
      row.id = item.id;
      row.number = item.number;
      row.phone_number = item.phone_number;
      row.puk = item.puk;
      row.fleet_user = item.fleet_user ? item.fleet_user.lastname + ' ' + item.fleet_user.firstname : '';
      row.fleet_user_to_update = item.fleet_user ? item.fleet_user : null;
      row.date = new Date(item.created_at).toLocaleDateString('fr-FR');

      simRows.push(row);
    });
    return simRows;
  }

  function HeaderCellRenderer({
    column,
    onHeaderClick,
    sortColumn,
    sortDirection,
  }) {
    const handleClick = () => {
      onHeaderClick(column);
    };

    const isSorted = sortColumn === column.key;

    return (
      <div
        onClick={handleClick}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          cursor: 'pointer',
        }}
      >
        {column.name}
        {isSorted && (sortDirection === 'ASC' ? '▲' : '▼')}
      </div>
    );
  }

  function UserCell({ row }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userAnchorEl, setUserAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      setSelectedSim(row.id);
      setSelectedRow(row.fleet_user_to_update);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setUserAnchorEl(null);
    };

    const handleMenuItemClick = (event, setAnchorFunction, currentAnchorEl) => {
      if (currentAnchorEl === event.currentTarget) {
        // Si l'élément de la liste est déjà ouvert, le fermer
        setAnchorFunction(null);
      } else {
        // Sinon, ouvrir le sous-menu pour cet élément de la liste
        setAnchorFunction(event.currentTarget);
      }
    };

    const handleSubMenuItemClick = (event, setSubAnchorFunction, currentAnchorEl) => {
      if (currentAnchorEl === event.currentTarget) {
        // Si l'élément de la liste est déjà ouvert, le fermer
        setSubAnchorFunction(null);
      } else {
        // Sinon, ouvrir le sous-menu pour cet élément de la liste
        setSubAnchorFunction(event.currentTarget);
      }
    };

    const viewHistory = () => {
      handleClose();

      //navigate(`/fleet-details/${row.id}/`, { state: { f: fleetData.filter((item) => item.id === row.id)[0] } });
    };

    return (
      <div className='clickable-cell'>
        <span onClick={handleClick} style={{ cursor: 'pointer', display: 'inline-block', width: '100%', height: '100%' }}>
          {row.fleet_user || t('Unaffected')}
        </span>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          getContentAnchorEl={null}
          PaperProps={{
            style: {
              minWidth: '200px',
            },
          }}
        >
          <List>
            <ListItem button onClick={(event) => handleMenuItemClick(event, setUserAnchorEl, userAnchorEl)}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faUser} />
              </ListItemIcon>
              {t('user')}
            </ListItem>
            <Collapse
              in={Boolean(userAnchorEl)}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem button onClick={() => setUserModalIsOpen(true)} style={{ paddingLeft: '40px' }}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faLink} />
                  </ListItemIcon>
                  {t('Associate')}
                </ListItem>
                {
                  row.fleet_user ? (
                    <>
                      <ListItem button onClick={() => setConfirmationModalOpen(true)} style={{ paddingLeft: '40px' }}>
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faLinkSlash} />
                        </ListItemIcon>
                        {t('Dissociate')}
                      </ListItem>
                      <ListItem button onClick={viewHistory} style={{ paddingLeft: '40px' }}>
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faBook} />
                        </ListItemIcon>
                        {t('See history')}
                      </ListItem>
                    </>
                  ) : null
                }
              </List>
            </Collapse>
          </List>
        </Menu>
      </div>
    );
  }

  function ActionsCell({ row }) {
    const rowIdx = sortedRows.findIndex((r) => r.id === row.id);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userAnchorEl, setUserAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleMenuItemClick = (event, setAnchorFunction, currentAnchorEl) => {
      if (currentAnchorEl === event.currentTarget) {
        // Si l'élément de la liste est déjà ouvert, le fermer
        setAnchorFunction(null);
      } else {
        // Sinon, ouvrir le sous-menu pour cet élément de la liste
        setAnchorFunction(event.currentTarget);
      }
    };

    const viewPukCode = () => {

      setSimCardForm({
        id: row.id,
        number: row.number,
        phone_number: row.phone_number,
        puk: row.puk,
      });
      setSimCard({
        id: row.id,
        number: row.number,
        phone_number: row.phone_number,
        puk: row.puk,
      })
      setPukModalOpen(true);
    };

    return (
      <div className='clickable-cell'>
        <div onClick={handleClick} style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </div>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          getContentAnchorEl={null}
          PaperProps={{
            style: {
              minWidth: '200px',
            },
          }}
        >

          <List component="div" disablePadding>
            {row.isNew ? (
              <>
                {
                  row.number != undefined && row.number != '' ? (
                    <ListItem button onClick={() => {
                      handleAddSim(rowIdx);
                      handleClose();
                    }} style={{ paddingLeft: '40px' }}>
                      <ListItemIcon>
                        <FontAwesomeIcon icon={faUserPlus} />
                      </ListItemIcon>
                      {t('Add')}
                    </ListItem>
                  ) : null
                }

                <ListItem
                  button
                  onClick={() => {
                    handleCancelChanges(rowIdx);
                    handleClose();
                  }}
                  style={{ paddingLeft: '40px' }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faXmark} />
                  </ListItemIcon>
                  {t('Discard changes')}
                </ListItem>
              </>
            ) : (
              <>
                <ListItem button onClick={() => {
                  handleUpdateSim(rowIdx);
                  handleClose();
                }} style={{ paddingLeft: '40px' }}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faUserEdit} />
                  </ListItemIcon>
                  {t('Update')}
                </ListItem>
                <ListItem button onClick={viewPukCode} style={{ paddingLeft: '40px' }}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faLock} />
                  </ListItemIcon>
                  {t('PUK Code')}
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    handleCancelChanges(rowIdx);
                    handleClose();
                  }}
                  style={{ paddingLeft: '40px' }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faXmark} />
                  </ListItemIcon>
                  {t('Discard changes')}
                </ListItem>
                <ListItem button onClick={() => {
                  handleDeleteSim(rowIdx);
                  handleClose();
                }} style={{ paddingLeft: '40px' }}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faUserMinus} />
                  </ListItemIcon>
                  {t('Delete')}
                </ListItem>
              </>
            )}
          </List>
        </Menu>
      </div>
    );

  }

  const onHeaderClick = (column) => {
    let newSortDirection = sortDirection === 'ASC' ? 'DESC' : 'ASC';
    if (sortColumn !== column.key) {
      newSortDirection = 'ASC';
    }
    setSortColumn(column.key);
    setSortDirection(newSortDirection);
    handleSort(column.key, newSortDirection);
  };

  const handleSort = (columnKey, direction) => {
    const sortedData = [...sortedRows].sort((a, b) => {
      if (direction === 'ASC') {
        return a[columnKey].localeCompare(b[columnKey]);
      } else {
        return b[columnKey].localeCompare(a[columnKey]);
      }
    });

    setSortedRows(sortedData);
  };

  function handleCancelChanges(rowIdx) {


    const newRowData = [...sortedRows];


    if (newRowData[rowIdx].isNew) {

      newRowData.splice(rowIdx, 1);
    } else {

      // Remettre les valeurs d'origine pour la ligne modifiée
      newRowData[rowIdx] = { ...rows[rowIdx] };
    }

    setSortedRows(newRowData);
  }

  function handleAddRow() {
    const newRow = { id: getNewId(), lastname: '', firstname: '', entity: '', role: '', isNew: true };
    setSortedRows([...sortedRows, newRow]);
  }

  const handleAddSim = (rowIdx) => {
    let row = sortedRows[rowIdx];
    if (row.number === '')
      setInformationMessage(t('Veuillez remplir le champs numéro de la carte SIM'));
    else
      dispatch(addSim(row.number, row.phone_number));
  };

  const handleUpdateSim = (rowIdx) => {
    let row = sortedRows[rowIdx];

    if (row.number === '')
      setInformationMessage(t('Veuillez remplir le champs numéro de la carte SIM'));
    else
      dispatch(updateSim(row));
  };

  const handleDeleteSim = (rowIdx) => {
    let row = sortedRows[rowIdx];
    dispatch(deleteSim(row.id));
  }

  const handleFileImport = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const newRows = rows.slice(1).map((row) => {
        // Remplacer les cellules non remplies par des chaînes vides
        const sanitizedRow = row.map((cell) => (cell === undefined || cell === null ? '' : cell));

        return {
          number: sanitizedRow[0],
          phone_number: sanitizedRow[1],
          isNew: true,
        };
      });

      dispatch(addSimByFile(newRows));
      setIsOpen(false);
    };
    reader.readAsArrayBuffer(file);
  };

  const handlePukFileImport = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const newRows = rows.slice(1).map((row) => {
        // Remplacer les cellules non remplies par des chaînes vides
        const sanitizedRow = row.map((cell) => (cell === undefined || cell === null ? '' : cell));

        return {
          sim: sanitizedRow[0],
          puk: sanitizedRow[1],
        };
      });

      dispatch(addPukByFile(newRows));
      setPukIsOpen(false);
    };
    reader.readAsArrayBuffer(file);
  };

  function getNewId() {
    const ids = sortedRows.map((row) => row.id);
    const maxId = Math.max(...ids);
    return maxId + 1;
  }

  function openModal() {
    setIsOpen(true);
  }

  function openPukFileModal() {
    setPukIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closePukFileModal() {
    setPukIsOpen(false);
  }

  function closeUserModalModal() {
    setUserModalIsOpen(false);
  }

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string.length === 0 || string === "") {
      setSearchTerm('');
      dispatch(listSims());
    }
    //setImeiInput(string);
    setSearchTerm(string);
  };

  const handleOnSelect = (item) => {
    // the item selected
    setSortedRows(sortedRows.filter((sim) => sim.id === item.id));
  };

  const handleOnFocus = () => {

  };

  const formatResult = (item) => {
    return (
      <>
        {
          item && item != undefined ? (
            <span>{item.number}    ---    {item.phone_number}</span>
          ) : null
        }

      </>
    )
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(sortedRows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    };

    saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), 'flotte-sim.xlsx');
  };

  const handleDissociateUser = (row) => {
    let fleet_sim_row = {
      id: selectedRow.id,
      lastname: selectedRow.lastname,
      firstname: selectedRow.firstname,
      entity: selectedRow.entity,
      role: selectedRow.role,
      fleet_sim: null,
    }
    dispatch(updateFleetUser(fleet_sim_row));
    setConfirmationModalOpen(false);
  }

  const addUser = (user) => {
    let fleet_user = {
      id: user.id,
      lastname: user.lastname,
      firstname: user.firstname,
      entity: user.entity,
      role: user.role,
      fleet_sim: selectedSim,
    }
    dispatch(addFleetUser(fleet_user))
    closeUserModalModal();
  };

  const selectUser = (user) => {
    setSelectedUser(user);
    closeUserModalModal();
  };

  const closePukModal = () => {
    setPuk(null)
    setPukModalOpen(false);
  };

  const handlePukSubmit = (event) => {
    event.preventDefault();

    dispatch(updateSim(simCardForm));
    setSimCardForm({ id: null, number: '', phone_number: '', puk: '' })
    setSimCard({ id: null, number: '', phone_number: '', puk: '' })
    closePukModal();
  };

  const handleModalPukChange = (event) => {
    const { name, value } = event.target;
    setSimCardForm({ ...simCardForm, [name]: value });
  };


  return (
    <div className='fleet-sims-main'>
      <Row className=''>
        <Col md={1}>
          <button
            className='fleet-add-btn'
            onClick={handleAddRow}
            data-tip="React-tooltip"
            data-for={'tooltip-adduser'}
          ><FontAwesomeIcon icon={faSimCard} /></button>
          <ReactTooltip id={'tooltip-adduser'}
            type="dark"
            effect="solid"
            clickable={true}
            place='bottom'
            getContent={function () {
              return (
                <div>
                  <Row>{msgAddSim}</Row>
                </div>
              )
            }} />
        </Col>

        <Col md={1}>
          <button
            className='fleet-add-btn'
            onClick={openModal}
            data-tip="React-tooltip"
            data-for={'tooltip-adduserbyfile'}
          ><FontAwesomeIcon icon={faFileCirclePlus} /></button>
          <ReactTooltip id={'tooltip-adduserbyfile'}
            type="dark"
            effect="solid"
            clickable={true}
            place='bottom'
            getContent={function () {
              return (
                <div>
                  <Row>{msgAdduserbyfile}</Row>
                </div>
              )
            }} />
        </Col>
        <Col md={1}>
          <button
            className='fleet-add-btn'
            onClick={openPukFileModal}
            data-tip="React-tooltip"
            data-for={'tooltip-msgAddPukbyfile'}
          ><FontAwesomeIcon icon={faLock} /></button>
          <ReactTooltip id={'tooltip-msgAddPukbyfile'}
            type="dark"
            effect="solid"
            clickable={true}
            place='bottom'
            getContent={function () {
              return (
                <div>
                  <Row>{msgAddPukbyfile}</Row>
                </div>
              )
            }} />
        </Col>
        <Col md={6}>
          <ReactSearchAutocomplete
            items={sortedRows && sortedRows != undefined && sortedRows.length > 0 ? sortedRows : []}
            fuseOptions={fuseOptions}
            resultStringKeyName="number"
            onSearch={handleOnSearch}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            onClear={() => { setSearchTerm(''); dispatch(listSims()); }}
            autoFocus
            placeholder={t('Search')}
            formatResult={formatResult}
            threshold={0} // 0 = exact match, 1 = everything matches
            ignoreLocation={true}
            maxResults={8}
            styling={{
              width: "100%",
              border: "1px solid #dfe1e5",
              borderRadius: "5px",
              backgroundColor: "white",
              boxShadow: "rgba(58, 53, 53, 0.2) 0px 1px 6px 0px",
              hoverBackgroundColor: "#eee",
              color: "#212121",
              fontSize: "12px",
              fontFamily: "Arial",
              iconColor: "grey",
              lineColor: "rgb(232, 234, 237)",
              placeholderColor: "grey",
              clearIconMargin: '0 5px 0 0',
              searchIconMargin: '0 0 0 5px',
              zIndex: 10,
            }}
          />
        </Col>
        <Col md={1}>
          <button
            onClick={exportToExcel}
            className='btn-excel-export'
            data-tip="React-tooltip" data-for={'tooltip-exceldownload'}
          >
            <FontAwesomeIcon icon={faFileExcel} size="xl" />
          </button>
          <ReactTooltip id={'tooltip-exceldownload'}
            type="dark"
            effect="solid"
            clickable={true}
            place='bottom'
            getContent={function () {
              return (
                <div>
                  <Row>{msgExcelDownload}</Row>
                </div>
              )
            }} />
        </Col>
      </Row>
      <Row className='fleet-data-row'>
        {
          sortedRows && sortedRows.length > 0 ? (
            <DataGrid
              className="rdg-light fleet-sims-datagrid"
              columns={columns}
              rows={sortedRows}
              rowGetter={(i) => sortedRows[i]}
              rowsCount={sortedRows.length}
              onRowsChange={setSortedRows}
              onHeaderClick={onHeaderClick}
              onGridRowsUpdated={setSortedRows}
            />

          ) : (
            <div className='fleet-sims-no-data'>
              {msgNoSim}
            </div>
          )
        }

      </Row>
      <Dialog
        open={openSuccessDialog}
        onClose={handleSuccessDialogClose}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
      >
        <DialogTitle id="success-dialog-title">{t('Successful operation')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="success-dialog-description">
            {t('The operation was successfully completed.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessDialogClose} color="primary">
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        className="fleet-sims-file-modal"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel={t('Import csv file')}
      >
        <div className="modal-content">
          <Row className='fleet-users-modal-row'>
            <Col md={8}>
              <div className='fleet-users-modal-title'>{t('Import csv file')}</div>
            </Col>
            <Col className='fleet-users-modal-file-example' md={2}>
              {/* TODO : A gérer depuis les fichiers statiques de Django */}
              <a href={excelUrl} download>
                <img className='fleet-users-excel-example'
                  src={excellogo}
                  alt="example"
                  data-tip="React-tooltip"
                  data-for={'tooltip-file-example'}
                />

                <ReactTooltip id={'tooltip-file-example'}
                  type="dark"
                  effect="solid"
                  clickable={true}
                  place='bottom'
                  getContent={function () {
                    return (
                      <div>
                        <Row>{msgFileExample}</Row>
                      </div>
                    )
                  }} />
              </a>
            </Col>
          </Row>
          <Row className='fleet-users-modal-row'>
            <Col md={6}>
              <input className='fleet-users-modal-input' type="file" accept=".xls,.xlsx,.csv" onChange={handleFileImport} />
            </Col>
          </Row>
          <Row className='fleet-users-modal-row'>
            <Col md={6}>
              <button className='fleet-users-modal-close-btn' onClick={closeModal}>Fermer</button>
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        className="fleet-users-file-modal"
        isOpen={pukIsOpen}
        onRequestClose={closePukFileModal}
        contentLabel={t('Import csv file')}
      >
        <div className="modal-content">
          <Row className='fleet-users-modal-row'>
            <Col md={8}>
              <div className='fleet-users-modal-title'>{t('Import csv file')}</div>
            </Col>
            <Col className='fleet-users-modal-file-example' md={2}>
              {/* TODO : A gérer depuis les fichiers statiques de Django */}
              <a href={excelPukUrl} download>
                <img className='fleet-users-excel-example'
                  src={excellogo}
                  alt="example"
                  data-tip="React-tooltip"
                  data-for={'tooltip-file-example'}
                />

                <ReactTooltip id={'tooltip-file-example'}
                  type="dark"
                  effect="solid"
                  clickable={true}
                  place='bottom'
                  getContent={function () {
                    return (
                      <div>
                        <Row>{msgFileExample}</Row>
                      </div>
                    )
                  }} />
              </a>
            </Col>
          </Row>
          <Row className='fleet-users-modal-row'>
            <Col md={6}>
              <input className='fleet-users-modal-input' type="file" accept=".xls,.xlsx,.csv" onChange={handlePukFileImport} />
            </Col>
          </Row>
          <Row className='fleet-users-modal-row'>
            <Col md={6}>
              <button className='fleet-users-modal-close-btn' onClick={closePukFileModal}>Fermer</button>
            </Col>
          </Row>

        </div>
      </Modal>
      <UserModal
        isOpen={userModalIsOpen}
        onRequestClose={closeUserModalModal}
        fleetUsers={fleetUsers}
        onAddUser={addUser}
        onSelectUser={selectUser}
      />

      <ConfirmationModal
        isOpen={confirmationModalOpen}
        onRequestClose={() => setConfirmationModalOpen(false)}
        text={t('Are you sure you want to unlink this user?')}
        onConfirmDelete={handleDissociateUser}
      />

      <Modal
        className="fleet-file-modal"
        isOpen={pukModalOpen}
        onRequestClose={closePukModal}
        contentLabel={t('My PUK code')}
        style={{ overlay: { zIndex: 9999 } }}
      >
        <Container>
          <Row className="modal-header">
            <h2>{t('My PUK code')}</h2>
          </Row>
          <Form onSubmit={handlePukSubmit}>
            <Row>
              {
                (!simCardForm || !simCardForm.puk || simCardForm.puk != simCard.puk) ? (
                  <>
                    <span>{t('Add a PUK code')}</span>
                    <Form.Group>
                      <Form.Control
                        className='modal-input'
                        type="text"
                        name="puk"
                        placeholder="Code PUK"
                        value={simCardForm.puk}
                        onChange={handleModalPukChange}
                        required
                      />
                    </Form.Group>
                    <Row className="fleet-modal-add-btn-row">
                      <Col>
                        <button className='fleet-modal-cancel-btn' onClick={closePukModal}>{t('Cancel')}</button>
                      </Col>
                      <Col>
                        <button className='fleet-modal-add-btn' type="submit">{t('Add')}</button>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <span>
                      <h3>{simCardForm.puk}</h3>
                    </span>
                    <Row className="fleet-modal-add-btn-row">
                      <button className='fleet-modal-cancel-btn' onClick={closePukModal}>{t('Close')}</button>
                    </Row>
                  </>
                )
              }

            </Row>

          </Form>
        </Container>
      </Modal>
    </div>
  )
}

export default FleetSims