import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom';

import Loader from '../../components/Loader/Loader'
import Message from '../../components/Message/Message'
import CountrySelector from '../../components/Selector/CountrySelector/CountrySelector';
import { register } from '../../actions/userActions'
import { listCountries } from '../../actions/countryActions'

import './Register.css'
import { useTranslation } from 'react-i18next';

function Register() {
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        street: '',
        street2: '',
        zip: '',
        city: '',
        country_id: 75,
        phone: '',
        mobile: '',
        vat: '',
        siret: '',
        is_company: true,
        parent_id: '',
    });

    const [confirmPassword, setConfirmPassword] = useState('')

    const [message, setMessage] = useState('')

    const location = useLocation();
    let navigate = useNavigate();

    const redirect = location.search ? location.search.split('=')[1] : '/'

    const userRegister = useSelector((state) => state.userRegister)
    const { loading, error, userInfo } = userRegister

    const countryList = useSelector((state) => state.countryList)
    const { countries } = countryList

    useEffect(() => {
        dispatch(listCountries());
    }, [])

    useEffect(() => {
        if (userInfo) {
            navigate(redirect);
        }
    }, [userInfo, redirect])

    const handleInputChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const addInputCountry = (value) => {
        // TODO Récupérer les pays odoo et filtrer sur la liste pour récupérer l'id
        const country_id = countries.filter(country => country.name === value.label)[0].id;

        setFormData({
            ...formData,
            ['country_id']: country_id,
        });
    };

    const submitHandler = (e) => {
        e.preventDefault()

        const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/
        const postalCodePattern = /^[0-9]{5}$/

        let messages = []

        if (formData.password !== confirmPassword) {
            messages.push(t('Passwords do not match'))
        }
        if (!emailPattern.test(formData.email)) {
            messages.push(t('Email address is not valid'))
        }
        if (!postalCodePattern.test(formData.zip)) {
            messages.push(t('Postal code is not valid'))
        }
        if (!validateSIRET(formData.siret)) {
            messages.push(t('SIRET number is not valid'))
        }
        if (!validateVAT(formData.vat)) {
            messages.push(t('VAT number is not valid'))
        }

        if (messages.length === 0) {
            dispatch(register(formData)) // TODO Modifier la méthode pour recevoir formData
        } else {
            let formattedMessages = "Les champs suivants ne sont pas valide : <br/>- " + messages.join('<br/>- ')
            setMessage(formattedMessages)
        }
    }


    const validateVAT = (value) => {
        const VATRegex = /^FR[0-9A-Z]{2}[0-9]{9}$/;
        
        return VATRegex.test(value);
    };

    const validateSIRET = (value) => {
        if (value.length !== 14) {
            return false;
        }

        let sum = 0;
        for (let i = 0; i < 14; i++) {
            let digit = parseInt(value[i], 10);
            if (i % 2 === 0) {
                digit *= 2;
                if (digit > 9) {
                    digit -= 9;
                }
            }
            sum += digit;
        }

        return sum % 10 === 0;
    };

    return (
        <div>
            <div className='register-title'>{t('Company account registration')}</div>
            {error && <Message variant='danger'>{error}</Message>}
            {loading && <Loader />}
            <Form onSubmit={submitHandler} className='register-form'>
                <div className='register-main-div'>
                    <Row className='register-first-row'>
                        <Form.Group className='form-group-register' controlId='name'>
                            <Form.Label>{t('Name')}</Form.Label>
                            <Form.Control
                                required
                                name="name"
                                type='text'
                                placeholder={t('Name')}
                                value={formData.name}
                                onChange={handleInputChange}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group className='form-group-register' controlId='email'>
                            <Form.Label>{t('E-mail address')}</Form.Label>
                            <Form.Control
                                required
                                name="email"
                                type='email'
                                placeholder={t('E-mail address')}
                                value={formData.email}
                                onChange={handleInputChange}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group className='form-group-register' controlId='password'>
                            <Form.Label>{t('Password')}</Form.Label>
                            <Form.Control
                                required
                                name="password"
                                type='password'
                                placeholder={t('Password')}
                                value={formData.password}
                                onChange={handleInputChange}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group className='form-group-register' controlId='confirmPassword'>
                            <Form.Label>{t('Confirm Password')}</Form.Label>
                            <Form.Control
                                required
                                type='password'
                                placeholder={t('Confirm Password')}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                    </Row>
                    <Row className='register-second-row'>
                        <Col>
                            <Form.Group className='form-group-register' controlId='street'>
                                <Form.Label>{t('Street')}</Form.Label>
                                <Form.Control
                                    required
                                    name="street"
                                    type='text'
                                    placeholder={t('Street')}
                                    value={formData.street}
                                    onChange={handleInputChange}
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group className='form-group-register' controlId='street2'>
                                <Form.Label>{t('Street 2')}</Form.Label>
                                <Form.Control
                                    name="street2"
                                    type='text'
                                    placeholder={t('Street 2')}
                                    value={formData.street2}
                                    onChange={handleInputChange}
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group className='form-group-register' controlId='zip'>
                                <Form.Label>{t('Postal code')}</Form.Label>
                                <Form.Control
                                    required
                                    name="zip"
                                    type='text'
                                    placeholder={t('Postal code')}
                                    value={formData.zip}
                                    onChange={handleInputChange}
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group className='form-group-register' controlId='city'>
                                <Form.Label>{t('City')}</Form.Label>
                                <Form.Control
                                    required
                                    name="city"
                                    type='text'
                                    placeholder={t('City')}
                                    value={formData.city}
                                    onChange={handleInputChange}
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group className='form-group-register' controlId='country'>
                                <Form.Label>{t('Country')}</Form.Label>
                                <CountrySelector addInputCountry={addInputCountry} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='form-group-register' controlId='vat'>
                                <Form.Label>{t('Intra-community VAT')}</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="vat"
                                    placeholder={t('Intra-community VAT')}
                                    value={formData.vat}
                                    onChange={handleInputChange}
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group className='form-group-register' controlId='siret'>
                                <Form.Label>{t('SIRET number')}</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="siret"
                                    placeholder={t('SIRET number')}
                                    value={formData.siret}
                                    onChange={handleInputChange}
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='row-btn-register'>
                        <button className='btn-register' type='submit' variant='primary'>
                            {t('I\'m registering')}
                        </button>
                    </Row>
                </div>
            </Form>
            {message && <Message variant='danger'>{message}</Message>}
            <Row className='py-3'>
                <Col>
                    {t('Already registered ? Connect!')}{' '}
                    <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
                        {t('Login')}
                    </Link>
                </Col>
            </Row>
        </div >
    )
}

export default Register