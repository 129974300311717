import {
    USER_SELLER_LIST_REQUEST,
    USER_SELLER_LIST_SUCCESS,
    USER_SELLER_LIST_FAIL,

    USER_SELLER_GET_REQUEST,
    USER_SELLER_GET_SUCCESS,
    USER_SELLER_GET_FAIL,

    USER_SELLER_MAJ_REQUEST,
    USER_SELLER_MAJ_SUCCESS,
    USER_SELLER_MAJ_FAIL,
    USER_SELLER_MAJ_RESET
}
    from '../constants/userSellerConstants'

export const userSellerListReducer = (state = { users: [] }, action) => {
    switch (action.type) {
        case USER_SELLER_LIST_REQUEST:
            return { loading: true };
        case USER_SELLER_LIST_SUCCESS:
            return { loading: false, users: action.payload, success: true };
        case USER_SELLER_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const userSellerGetReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case USER_SELLER_GET_REQUEST:
            return { loading: true };
        case USER_SELLER_GET_SUCCESS:
            return { loading: false, userSeller: action.payload, success: true };
        case USER_SELLER_GET_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const userSellerMajReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case USER_SELLER_MAJ_REQUEST:
            return { loading: true };
        case USER_SELLER_MAJ_SUCCESS:
            return { loading: false, user: action.payload, success: true };
        case USER_SELLER_MAJ_FAIL:
            return { loading: false, error: action.payload };
        case USER_SELLER_MAJ_RESET:
            return { user: {} };
        default:
            return state;
    }
}