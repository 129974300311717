import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';

import Select from '../../../../components/Select/Select'

import { Container, Row, Col } from "react-bootstrap";
import ReactTooltip from 'react-tooltip';
import CSVReader from "react-csv-reader";

import { getProductByImei, getProductsByImei } from '../../../../actions/imeiActions';
import { createFleet } from '../../../../actions/fleetActions';
import { listContactsOdoo } from '../../../../actions/contactOdooActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

import { 
  listFleetUsers, 
  addFleetUser, 
  addFleetUsersByFile, 
  updateFleetUser, 
  deleteFleetUser,
  resetFleetUserAddSuccess,
  resetFleetUserAddByFileSuccess,
  resetFleetUserDeleteSuccess,
  resetFleetUserUpdateSuccess,
} from '../../../../actions/fleetUsersActions'
import { listUsers } from '../../../../actions/userActions'

import { validImei } from '../../../../utils/luhnUtils';

import excellogo from '../../../../assets/images/excel-logo.png';
import './AddToFleet.css'
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

function AddToFleet() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  //TODO à modifier pour livraison
  const excelUrl = "http://localhost:8000/media/example-files/importFlotte.csv";

  const [form, setForm] = useState({});

  const imeiProduct = useSelector(state => state.imeiProduct)
  const { productByImei } = imeiProduct

  const imeiProducts = useSelector(state => state.imeiProducts)
  const { productsByImei } = imeiProducts

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const fleetUsersList = useSelector(state => state.fleetUsersList)
  const { error, loading, fleetUsers } = fleetUsersList

  const [importFichier, setImportFichier] = useState();
  const [imei, setImei] = useState("");
  const [ignoredImei, setIgnoredImei] = useState([]);
  const [isValidImei, setIsValidImei] = useState();
  const [imeiMessage, setImeiMessage] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();

  const products = [];
  const addToFleetText = "Ajouter à ma flotte"

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_")
  };

  const inputRef = useRef();

  const format = 'imei, utilisateur';

  useEffect(() => {
    dispatch(listFleetUsers())
  }, [dispatch])

  useEffect(() => {
    if (fleetUsers && fleetUsers.length > 0) {
      setUsers(fleetUsers)
    }
  }, [fleetUsers])

  useEffect(() => {
    if (selectedUser) {
      
    }
  }, [selectedUser])

  useEffect(() => {
    
  }, [productsByImei])

  const onChangeValue = (event) => {

    switch (event.target.value) {
      case "import":
        
        setImportFichier(true);
        break;
      case "saisie":
        
        setImportFichier(false);
        break;
      default:
        break;
    }
    
  }

  const submitHandler = (e) => {
    if (validImei(imei)) {
      setIsValidImei(true);
      dispatch(getProductByImei(imei));
    }
    else {
      setIsValidImei(false);
      setImeiMessage("Le numéro IMEI " + imei + " n'est pas valide")
    }
  }

  // Ici, on gère l'import de la flotte par fichier
  const handleFile = (data, fileInfo) => {  
    let ignoredImei = [];
    const imeiList = [];

    // Parcourir chaque ligne de données (en excluant la première ligne)
    for (let i = 0; i < data.length; i++) {
      
      const item = data[i];
      // Récupérer les colonnes "imei" et "email"
      const imei = item.imei;
      const email = item.email_collaborateur;

      // Ignorer les lignes avec un IMEI invalide
      if (imei && validImei(imei)) {
        // Construire l'objet IMEI
        const imeiObj = {
          imei: imei,
          user_id: null,
          user_email: email
        };

        // Ajouter l'objet IMEI à la liste
        imeiList.push(imeiObj);
      } else {
        ignoredImei.push(imei);
      }
    }

    setIgnoredImei(ignoredImei);
    
    
    dispatch(createFleet(imeiList));
  };


  const checkImei = (value) => {
    
    setIsValidImei(validImei(value));
    setImei(value);
  }


  const addToFleetHandler = (value) => {
    
    
    let imeis = [{
      imei: imei,
      user_id: selectedUser != undefined ? selectedUser : null,
      user_email: null
    }]
    dispatch(createFleet(imeis));
  }

  const importToFleetBtnHandler = () => {
    
  }

  const cancelFleetBtnHandler = () => {
    
  }

  const onHandleSelectChange = (value, key) => {
    setSelectedUser(value);
  };

  return (
    <>
      <Container>
        <Row className='addtofleet-title'>
          {t('Adding terminals')}
        </Row>
        <Row className='addToFleet-radio-row'>
          <Col className='addToFleet-radio-col' md={{ span: 2, offset: 3 }}>
            <input
              id={`reverse-radio-1`}
              className="form-check-input"
              name="flexRadioDefault"
              type='radio'
              value='saisie'
              // checked={state.selectedOption === "Male"}
              onChange={(event) => onChangeValue(event)}
              defaultChecked
            />
            <i>{t('IMEI entry')}</i>
          </Col>
          <Col className='addToFleet-radio-col' md={2}>
            <input
              id={`reverse-radio-1`}
              className="form-check-input"
              name="flexRadioDefault"
              type='radio'
              value='import'
              // checked={state.selectedOption === "Male"}
              onChange={(event) => onChangeValue(event)}
            />
            <a data-tip="React-tooltip" data-for={'tooltip'}>{t('Import File')}</a>
            <ReactTooltip id={'tooltip'}
              type="dark"
              effect="solid"
              clickable={true}
              getContent={function () {
                return (
                  <div>
                    <Row>{t('Import your fleet from a csv file')}</Row>
                    <Row>{t('File format :')}{format}</Row>
                  </div>
                )
              }} />
          </Col>
          <Col className='addToFleet-radio-col' md={2}>
            {/* TODO : A gérer depuis les fichiers statiques de Django */}
            <a href={excelUrl} download>
              <img className='addToFleetExcelBtn'
                src={excellogo}
                alt="example"
              />
            </a>
          </Col>
        </Row>
        {!importFichier ?
          <Row>
            <Row className='addfleet-imei-row'>
              <Col md={{ span: 3, offset: 0 }}>
                <Select
                  className='fleet-select'
                  name="user"
                  title="User"
                  value={form.user}
                  options={users.map((user) => { return { value: user.id, label: user.firstname + ' ' + user.lastname } })}
                  onChangeFunc={onHandleSelectChange}
                />
              </Col>
              <Col md={{ span: 3, offset: 0 }}>
                <Form.Control
                  type="text"
                  id="imei-input"
                  placeholder="IMEI"
                  aria-describedby="passwordHelpBlock"
                  value={imei}
                  onChange={(e) => checkImei(e.target.value)}
                  ReactTooltip={t('The IMEI number must be 15 digits.')}
                />
              </Col>
              <Col md={{ span: 1, offset: 0 }}>
                <button className='addToFleetSearchBtn' type='submit' onClick={submitHandler} disabled={!isValidImei}>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
              </Col>
            </Row>
            {(!isValidImei && imei.length === 15) ?
              <Row className='addfleet-imei-notvalid'>
                <Col md={{ span: 4, offset: 0 }} className='addfleet-imei-notvalid-col'>
                  <span className="imeiMessage">{t('The IMEI entered is not valid')}</span>
                </Col>
              </Row>
              : null}
            {productByImei ?
              <Row className="addfleet-product-information" lg='auto' md='auto' sm='auto' xl='auto' xs='auto' xxl='auto'>
                <Col className='addfleet-product-col' key={productByImei.imei}>
                  <Row className="addfleet-product-detail">
                    {productByImei.device_image ? <img className="addfleet-img-product" src={productByImei.device_image} alt="" />
                      : <span>{t('Image not available for this device')}</span>}
                    <span className="p-brand">{productByImei.brand}</span>
                    <span className="p-name">{productByImei.name}</span>
                    <span className="p-imei">{productByImei.imei}</span>
                  </Row>
                  <Row>
                    {isValidImei && productByImei ?
                      <button className='addToFleetBtn' type='submit' onClick={addToFleetHandler} visible={isValidImei}>
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </button>
                      : null}
                  </Row>
                </Col>

              </Row>
              : null}
          </Row>
          :
          <Row className='addToFleet-file-container'>
            <Row className='addToFleet-file'>
              <Col md={{ span: 4, offset: 3 }}>
                {/* Provide a drop zone and an alternative button inside it to upload files. */}
                <div className='addToFleet-import-zone'>
                  <p>{t('Import File')}</p>

                  <CSVReader
                    onFileLoaded={handleFile}
                    onError={(error) => console.error(error)}
                    parserOptions={papaparseOptions}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 4, offset: 4 }}>
                {ignoredImei.length > 0 ?
                  <div>
                    <span className="imeiMessage">{t('The following IMEIs are invalid:')}</span>
                    <ul>
                      {ignoredImei.map((imei) => {
                        return (
                          <li>{imei}</li>
                        )
                      })}
                    </ul>
                  </div>
                  : null}
              </Col>
            </Row>
            <Row className="addfleet-product-row" lg='auto' md='auto' sm='auto' xl='auto' xs='auto' xxl='auto'>
              {productsByImei && productsByImei.length > 0 ? productsByImei.map((item) => (
                <Col className='addfleet-product-col-file' key={item.imei}>
                  <Row className="addfleet-product-detail">
                    <img className="addfleet-img-product" src={item.device_image} alt="" />
                    <span className="p-brand">{item.brand}</span>
                    <span className="p-name">{item.name}</span>
                    <span className="p-imei">{item.imei}</span>
                  </Row>
                </Col>
              ))
                : null
              }
            </Row>
            {productsByImei && productsByImei.length > 0 ?
              <Row>
                <Col md={{ span: 2, offset: 4 }}>
                  <button className='importToFleetBtnHandler' type='submit' onClick={importToFleetBtnHandler} visible={isValidImei}>{t('Import')}</button>
                </Col>
                <Col md={2}>
                  <button className='cancelFleetBtnHandler' type='submit' onClick={cancelFleetBtnHandler} visible={isValidImei}>{t('Cancel')}</button>
                </Col>
              </Row> : null}
          </Row>
        }
      </Container>
    </>
  )
}

export default AddToFleet;