export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL'

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL'

export const PRODUCT_BY_IMEI_FROM_API_REQUEST = 'PRODUCT_BY_IMEI_FROM_API_REQUEST'
export const PRODUCT_BY_IMEI_FROM_API_SUCCESS = 'PRODUCT_BY_IMEI_FROM_API_SUCCESS'
export const PRODUCT_BY_IMEI_FROM_API_FAIL = 'PRODUCT_BY_IMEI_FROM_API_FAIL'

export const PRODUCT_FROM_CIRCLE_REQUEST = 'PRODUCT_FROM_CIRCLE_REQUEST'
export const PRODUCT_FROM_CIRCLE_SUCCESS = 'PRODUCT_FROM_CIRCLE_SUCCESS'
export const PRODUCT_FROM_CIRCLE_FAIL = 'PRODUCT_FROM_CIRCLE_FAIL'

export const CHECK_PRODUCT_FROM_CIRCLE_REQUEST = 'CHECK_PRODUCT_FROM_CIRCLE_REQUEST'
export const CHECK_PRODUCT_FROM_CIRCLE_SUCCESS = 'CHECK_PRODUCT_FROM_CIRCLE_SUCCESS'
export const CHECK_PRODUCT_FROM_CIRCLE_FAIL = 'CHECK_PRODUCT_FROM_CIRCLE_FAIL'

export const PRODUCT_FROM_CIRCLE_BY_IMEI_REQUEST = 'PRODUCT_FROM_CIRCLE_BY_IMEI_REQUEST'
export const PRODUCT_FROM_CIRCLE_BY_IMEI_SUCCESS = 'PRODUCT_FROM_CIRCLE_BY_IMEI_SUCCESS'
export const PRODUCT_FROM_CIRCLE_BY_IMEI_FAIL = 'PRODUCT_FROM_CIRCLE_BY_IMEI_FAIL'
export const RESET_PRODUCT_FROM_CIRCLE_BY_IMEI = 'RESET_PRODUCT_FROM_CIRCLE_BY_IMEI';

export const PRODUCT_FROM_CIRCLE_BY_TAC_REQUEST = 'PRODUCT_FROM_CIRCLE_BY_TAC_REQUEST'
export const PRODUCT_FROM_CIRCLE_BY_TAC_SUCCESS = 'PRODUCT_FROM_CIRCLE_BY_TAC_SUCCESS'
export const PRODUCT_FROM_CIRCLE_BY_TAC_FAIL = 'PRODUCT_FROM_CIRCLE_BY_TAC_FAIL'
export const RESET_PRODUCT_FROM_CIRCLE_BY_TAC = 'RESET_PRODUCT_FROM_CIRCLE_BY_TAC';

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST'
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS'
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL'
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET'

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST'
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS'
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL'
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET'

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST'
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS'
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL'
export const PRODUCT_DELETE_RESET = 'PRODUCT_DELETE_RESET'

export const PRODUCT_NON_IMEI_LIST_REQUEST = 'PRODUCT_NON_IMEI_LIST_REQUEST'
export const PRODUCT_NON_IMEI_LIST_SUCCESS = 'PRODUCT_NON_IMEI_LIST_SUCCESS'
export const PRODUCT_NON_IMEI_LIST_FAIL = 'PRODUCT_NON_IMEI_LIST_FAIL'

export const PRODUCT_NON_IMEI_DETAILS_REQUEST = 'PRODUCT_NON_IMEI_DETAILS_REQUEST'
export const PRODUCT_NON_IMEI_DETAILS_SUCCESS = 'PRODUCT_NON_IMEI_DETAILS_SUCCESS'
export const PRODUCT_NON_IMEI_DETAILS_FAIL = 'PRODUCT_NON_IMEI_DETAILS_FAIL'

export const PRODUCT_NON_IMEI_CREATE_REQUEST = 'PRODUCT_NON_IMEI_CREATE_REQUEST'
export const PRODUCT_NON_IMEI_CREATE_SUCCESS = 'PRODUCT_NON_IMEI_CREATE_SUCCESS'
export const PRODUCT_NON_IMEI_CREATE_FAIL = 'PRODUCT_NON_IMEI_CREATE_FAIL'

export const PRODUCT_NON_IMEI_UPDATE_REQUEST = 'PRODUCT_NON_IMEI_UPDATE_REQUEST'
export const PRODUCT_NON_IMEI_UPDATE_SUCCESS = 'PRODUCT_NON_IMEI_UPDATE_SUCCESS'
export const PRODUCT_NON_IMEI_UPDATE_FAIL = 'PRODUCT_NON_IMEI_UPDATE_FAIL'

export const PRODUCT_NON_IMEI_DELETE_REQUEST = 'PRODUCT_NON_IMEI_DELETE_REQUEST'
export const PRODUCT_NON_IMEI_DELETE_SUCCESS = 'PRODUCT_NON_IMEI_DELETE_SUCCESS'
export const PRODUCT_NON_IMEI_DELETE_FAIL = 'PRODUCT_NON_IMEI_DELETE_FAIL'

export const PRODUCT_CREATE_IN_ODOO_REQUEST = 'PRODUCT_CREATE_IN_ODOO_REQUEST'
export const PRODUCT_CREATE_IN_ODOO_SUCCESS = 'PRODUCT_CREATE_IN_ODOO_SUCCESS'
export const PRODUCT_CREATE_IN_ODOO_FAIL = 'PRODUCT_CREATE_IN_ODOO_FAIL'
export const PRODUCT_CREATE_IN_ODOO_RESET = 'PRODUCT_CREATE_IN_ODOO_RESET'
