import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip';

import { Row, Col, ListGroup, Image } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';

import Loader from '../../../../components/Loader/Loader'

import './AfterSaleTickets.css'
import Message from '../../../../components/Message/Message'
import { SettingsRemote } from '@material-ui/icons'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileArrowDown,
} from '@fortawesome/free-solid-svg-icons';

import { listTickets } from '../../../../actions/ticketActions';
import { listFleet } from '../../../../actions/fleetActions';
import { useTranslation } from 'react-i18next';

function AfterSaleTickets() {
  const dispatch = useDispatch()
  const location = useLocation()
  const { t, i18n } = useTranslation();

  const [ticket, setTicket] = useState({})
  const [fleet, setFleet] = useState([])
  const [statut, setStatut] = useState(0)
  const [progressStatus, setProgressStatus] = useState("")

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const ticketList = useSelector(state => state.ticketList)
  const { error, loading, tickets, page, pages } = ticketList

  const fleetList = useSelector(state => state.fleetList)
  const { errorf, loadingf, fleetf, pagef, pagesf } = fleetList

  const ticketSelected = location.state ? location.state.data : null

  function goToAfterSaleNew() {

  }

  useEffect(() => {
    dispatch(listTickets(userInfo.id_odoo));
    dispatch(listFleet());
  }, [])

  useEffect(() => {
    if (tickets && tickets != undefined && tickets.length > 0) {
      setTicket(ticketSelected ? ticketSelected : tickets[0])
    }
  }, [ticketList])

  useEffect(() => {
    if (ticket) {
      setProgressStatus(getProgressStatus(ticket.state))
    }
  }, [ticket])

  useEffect(() => {
    setFleet(fleetList.fleet)
  }, [fleetList])

  function handleSelectTicket(e, item) {
    setTicket(item)
    setProgressStatus(getProgressStatus(item.state))
  }

  function getProgressStatus(statut) {
    switch (statut) {
      case 'draft':
        setStatut(0)
        return t('In process of validation')
      case 'confirmed':
        setStatut(1)
        return t('Validated')
      case 'ready':
        setStatut(2)
        return t('Ready')
      case 'under_repair':
        setStatut(3)
        return t('Being processed')
      case 'done':
        setStatut(4)
        return t('Complete')
      default:
        setStatut(0)
        return t('In process of validation')
    }
  }

  const downloadRepairQuote = (url) => {
    window.open(url, '_blank');
  }

  return (
    <>
      {loading ? <Loader /> :
        <>
          <Row className='aftersaleticket-btn-row'>
            <Col md={3}>
              <Link to="/aftersale/new"><button
                className='aftersaleticket-btn-new'
                type='button'
                disabled={false}
                onClick={goToAfterSaleNew}
              >
                {t('New ticket')}
              </button>
              </Link>
            </Col>
          </Row>
          <Row className='aftersaleticket-content-row'>
            <Col md={4} className='aftersaleticket-tickets-col'>
              <Row className='aftersaleticket-tickets-title'>
                <span>{t('Tickets')}</span>
              </Row>
              <Row className='aftersaleticket-tickets-list'>

                {tickets && tickets.length === 0 ? (
                  <Message variant='info'>
                    {t('You have no open tickets')}
                  </Message>
                ) : (
                  <ListGroup className='aftersaleticket-tickets' variant='flush'>
                    {tickets ? tickets.map((item) => (
                      <Row className='aftersaleticket-row-item' onClick={(e) => handleSelectTicket(e, item)}>
                        <ListGroup.Item className='border-0 aftersaleticket-items' key={item.product}>
                          <Row className='aftersaleticket-item'>
                            <Col md={3}>
                              <Image className='aftersaleticket-img' src={item.product ? item.product.device_image : null} alt={item.name} fluid rounded />
                            </Col>
                            <Col className='aftersaleticket-start-col' md={8}>
                              <Row className='aftersaleticket-center-row aftersaleticket-item-name'>
                                {t('ticket opened on')} {' '} {new Date(item.create_date).toLocaleDateString("fr-FR")}
                              </Row>
                              <Row className='fleet-center-row fleet-item-detail'>
                                {t('device')} : {item.name}
                              </Row>
                              <Row className='fleet-center-row fleet-item-detail'>
                                IMEI : {item.imei}
                              </Row>
                              <Row className='fleet-center-row fleet-item-detail'>
                                {item.state !== "done" ? t('in progress') : t('Resolved')}
                              </Row>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      </Row>
                    )) : null}
                  </ListGroup>
                )}
              </Row>
            </Col>
            <Col md={6} className='aftersaleticket-tickets-col'>
              {
                tickets && tickets.length > 0 ?
                  <>
                    <Row className='aftersaleticket-tickets-title'>
                      <span>{t('Details')}</span>
                    </Row>
                    <Row className='aftersaleticket-tickets-details'>
                      <Row className='aftersaleticket-device-name'>
                        <Col md={10}>
                          {ticket.name}
                        </Col>
                        <Col md={2}>
                          <button
                            className='aftersale-ticket-modal-btn-download'
                            data-tip="React-tooltip"
                            data-for={'tooltip-download-ticket'}
                            type='button'
                            disabled={false}
                            onClick={() => downloadRepairQuote(ticket.pdf_url)}
                          >
                            <i data-tip="" data-for='tooltip'></i>
                            <FontAwesomeIcon icon={faFileArrowDown} />
                          </button>
                          <ReactTooltip id={'tooltip-download-ticket'}
                            type="dark"
                            place="bottom"
                            effect="solid"
                            clickable={true}
                            getContent={() => (
                              <div>
                                <Row>{t('Download documents')}</Row>
                              </div>
                            )}
                          />
                       </Col>
                      </Row>
                      <Row className='aftersaleticket-circles'>
                        <ProgressBar label='node'>
                          <ProgressBar striped variant="success" now={25 * statut} key={1} />
                          <ProgressBar striped variant="warning" now={100 - (25 * statut)} key={2} />
                        </ProgressBar>
                      </Row>
                      <Row className='aftersaleticket-progress-status-row'>
                        <div className='aftersaleticket-progress-status'>{progressStatus}</div>
                      </Row>

                      <Row className='aftersaleticket-detail-prestation'>
                        <div className='aftersaleticket-detail-prestation-title'>{t('Summary')} : </div>
                        {ticket && ticket.lines && ticket.lines.length > 0 ?
                          ticket.lines.map((item) => (
                            <div>
                              {item.name} : {item.price_unit} €
                            </div>
                          ))

                          : null
                        }
                      </Row>
                      <Row className='aftersaleticket-detail-row'>
                        <Col md={8}>
                          <Row className='aftersaleticket-tickets-demandeur'>
                            <div>{t('Applicant')} : {ticket.partner_id ? ticket.partner_id[1] : null}</div>
                          </Row>
                          <Row className='aftersaleticket-tickets-demandeur'>
                            <div>IMEI : {ticket.imei}</div>
                          </Row>
                          <Row className='aftersaleticket-tickets-commentaire'>
                            <div>
                              {t('Comment')} :
                            </div>
                            <div className='aftersaleticket-tickets-commentaire-col'>
                              {ticket.description}
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </Row>
                  </>
                  : null
              }

            </Col>
          </Row>
        </>
      }
    </>
  )
}

export default AfterSaleTickets