import{
    DOCUMENTS_GET_REQUEST,
    DOCUMENTS_GET_SUCCESS,
    DOCUMENTS_GET_FAIL,
}
from '../constants/documentConstants'

import axios from 'axios'

export const getDocuments = (partner_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: DOCUMENTS_GET_REQUEST })
        
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })
        
        const { data } = await instance.get(
            `/api/pdf/?partner_id=${partner_id}`
            )
        
        dispatch({
            type: DOCUMENTS_GET_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: DOCUMENTS_GET_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}