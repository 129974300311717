import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Row, Col, Image, Container, ListGroup } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Switch from "react-switch";

import uuid from 'react-uuid';

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import useFileUpload from 'react-use-file-upload'

import Message from '../../../../../components/Message/Message';
import Loader from '../../../../../components/Loader/Loader';
import Select from '../../../../../components/Select/Select';

import { listNonImeiProducts, createNonImeiProduct, updateNonImeiProduct } from '../../../../../actions/productActions';
import { uploadImage, deleteImage, listNonImeiImage } from '../../../../../actions/imageActions';
import { listDefaultNonImeiImages, createDefaultNonImeiImage, updateDefaultNonImeiImage } from '../../../../../actions/defaultImagesActions';

import { listCategories } from '../../../../../actions/categoriesActions';
import { listSkus, addSku, deleteSku } from '../../../../../actions/skuActions';
import { removeDuplicatesByProperties, removeDuplicates } from '../../../../../utils/utils';

import '../ArticlesNonImei.css'
import './AddArticlesNonImei.css';

function AddArticlesNonImei() {
  const searchRef = useRef(null);
  const [form, setForm] = useState({});
  const { t } = useTranslation();

  const [toggleChecked, setToggleChecked] = useState(false);


  const [categorie, setCategorie] = useState(null)
  const [selectCategories, setSelectCategories] = useState([])
  const [selectBrands, setSelectBrands] = useState([])
  const [selectModels, setSelectModels] = useState([])
  const [selectNames, setSelectNames] = useState([])

  const [selectedBrand, setSelectedBrand] = useState(null)
  const [selectedModel, setSelectedModel] = useState(null)
  const [selectedName, setSelectedName] = useState(null)

  const [filterBrand, setFilterBrand] = useState({});
  const [filterModel, setFilterModel] = useState({});
  const [filterName, setFilterName] = useState({});

  const [brand, setBrand] = useState('')
  const [model, setModel] = useState('')
  const [name, setName] = useState('')
  const [skus, setSkus] = useState([])

  const [newSpecName, setNewSpecName] = useState({})
  const [newSpecValue, setNewSpecValue] = useState({})

  const [newArticle, setNewArticle] = useState({})

  const [disableButton, setDisableButton] = useState(true)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productNonImeiList = useSelector(state => state.productNonImeiList)
  const { loading, productsNonImei } = productNonImeiList

  const categoriesList = useSelector(state => state.categoriesList)
  const { categories } = categoriesList

  const imageNonImeiList = useSelector(state => state.imageNonImeiList)
  const { imagesNonImei } = imageNonImeiList

  const defaultNonImeiImageList = useSelector(state => state.defaultNonImeiImageList)
  const { defaultNonImeiImages } = defaultNonImeiImageList

  const productNonImeiCreate = useSelector(state => state.productNonImeiCreate)
  const { success: successCreate, loading: loadingCreate } = productNonImeiCreate

  const productNonImeiUpdate = useSelector(state => state.productNonImeiUpdate)
  const { success: successUpdate, loading: loadingUpdate } = productNonImeiUpdate

  const [filteredProducts, setFilteredProducts] = useState([])

  const dispatch = useDispatch()
  const inputRef = useRef();

  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile
  } = useFileUpload();

  const [inputSku, setInputSku] = useState('');

  useEffect(() => {
    if (!productsNonImei || productsNonImei.length === 0)
      dispatch(listNonImeiProducts(userInfo ? userInfo.id_odoo : -1));
    dispatch(listCategories())
  }, [])

  useEffect(() => {
    if (productsNonImei && productsNonImei.length > 0) {
      setFilteredProducts(productsNonImei)
    }
  }, [productsNonImei])

  useEffect(() => {
    if (categories) {
      let values = []
      categories.sort((a, b) => a.id - b.id).filter(category => !category.is_imei).forEach(category => {
        values.push({ value: category.id, label: category.name })
      })
      setSelectCategories(values)
      fillSelect()
    }
  }, [categories])

  useEffect(() => {
    if (categorie) {
      setNewArticle({ ...newArticle, categorie: categorie.name })
    }
  }, [categorie])

  useEffect(() => {
    if (categorie && categorie.id && filteredProducts.length == 1)
      setDisableButton(false)
    else if (newArticle.categorie && newArticle.brand != '' && newArticle.model != '' && newArticle.name != ''
      && newArticle.specifications && newArticle.specifications.length > 0)
      setDisableButton(false)
    else
      setDisableButton(false)

  }, [categorie, filteredProducts, newArticle])

  useEffect(() => {
    if (selectedBrand && selectedBrand != '' && selectedModel && selectedModel != '' && selectedName && selectedName != '') {
      setFilteredProducts(filteredProducts.filter(product => product.brand === selectedBrand && product.model === selectedModel && product.name === selectedName))
    }
    else if (selectedBrand && selectedBrand != '' && selectedModel && selectedModel != '') {
      setFilteredProducts(filteredProducts.filter(product => product.brand === selectedBrand && product.model === selectedModel))
    } else if (selectedBrand && selectedBrand != '' && selectedName && selectedName != '') {
      setFilteredProducts(filteredProducts.filter(product => product.brand === selectedBrand && product.name === selectedName))
    } else if (selectedModel && selectedModel != '' && selectedName && selectedName != '') {
      setFilteredProducts(filteredProducts.filter(product => product.model === selectedModel && product.name === selectedName))
    } else if (selectedBrand && selectedBrand != '') {
      setFilteredProducts(filteredProducts.filter(product => product.brand === selectedBrand))
    } else if (selectedModel && selectedModel != '') {
      setFilteredProducts(filteredProducts.filter(product => product.model === selectedModel))
    } else if (selectedName && selectedName != '') {
      setFilteredProducts(filteredProducts.filter(product => product.name === selectedName))
    }
    else
      setFilteredProducts(categorie ? productsNonImei.filter(product => product.categories.includes(categorie.id)) : productsNonImei)

  }, [selectedBrand, selectedModel, selectedName])

  function fillSelect() {
    let brand_values = []
    let model_values = []
    let name_values = []

    let brands = filteredProducts.map(product => product.brand)
    let models = filteredProducts.map(product => product.model)
    let names = filteredProducts.map(product => product.name)

    removeDuplicates(brands).sort((a, b) => a.id - b.id).forEach(brand => {
      brand_values.push({ value: brand, label: brand })
    })
    setSelectBrands(brand_values)

    removeDuplicates(models).sort((a, b) => a.id - b.id).forEach(model => {
      model_values.push({ value: model, label: model })
    })
    setSelectModels(model_values)

    removeDuplicates(names).sort((a, b) => a.id - b.id).forEach(name => {
      name_values.push({ value: name, label: name })
    })
    setSelectNames(name_values)
  }

  useEffect(() => {
    if (filteredProducts && filteredProducts.length === 1) {
      dispatch(listNonImeiImage(filteredProducts[0].brand, filteredProducts[0].model, filteredProducts[0].name))
      dispatch(listDefaultNonImeiImages(filteredProducts[0].brand, filteredProducts[0].model, filteredProducts[0].name))
    }
    fillSelect()
  }, [filteredProducts])

  useEffect(() => {
    if (successCreate || successUpdate) {
      dispatch(listNonImeiProducts(userInfo ? userInfo.id_odoo : -1));
      setNewArticle({})
      resetSelects()
    }
  }, [successCreate, successUpdate])

  const [forceUpdate, setForceUpdate] = useState(0);

  function resetSelects() {
    setSelectBrands([])
    setSelectModels([])
    setSelectNames([])
    setSelectedBrand(null)
    setSelectedModel(null)
    setSelectedName(null)
    setDisableButton(true)
  }

  const onHandleCategorySelectChange = (value) => {
    if (value === null) {
      setFilteredProducts(productsNonImei)
      setCategorie(null)
      setSelectBrands([])
      setSelectModels([])
      setSelectNames([])
      setSelectedBrand(null)
      setSelectedModel(null)
      setSelectedName(null)
      setDisableButton(true)
      return
    }
    setCategorie(categories.filter(category => category.id === value)[0])
    setFilteredProducts(productsNonImei.filter(product => product.categories[0] === value))
  }

  const onHandleBrandSelectChange = (value) => {
    if (value === null) {
      setFilteredProducts(productsNonImei)
      fillSelect()
      setSelectModels([])
      setSelectNames([])
      setSelectedModel(null)
      setSelectedName(null)
      setDisableButton(true)
      setSelectedBrand(value)
      return
    }

    setSelectedBrand(value)
    setFilteredProducts(productsNonImei.filter(product => product.brand === value))
  }

  const onHandleModelSelectChange = (value) => {
    if (value === null) {
      setFilteredProducts(productsNonImei)
      fillSelect()
      setSelectNames([])
      setName(null)
      setDisableButton(true)
      setSelectedModel(value)
      return
    }

    setSelectedModel(value)
    setFilteredProducts(productsNonImei.filter(product => product.model === value))
  }

  const onHandleNameSelectChange = (value) => {
    if (value === null) {
      setFilteredProducts(productsNonImei)
      fillSelect()
      setName(null)
      setDisableButton(true)
      setName(value)
      return
    }

    setName(value)
    setFilteredProducts(productsNonImei.filter(product => product.name === value))
  }

  const handleBrandOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string.length === 0) {
      setFilterBrand(string);
    }
    setNewArticle({ ...newArticle, brand: string })
  };

  const handleBrandOnSelect = (item) => {
    // the item selected
    // on met à jour la liste globale des articles jusqu'à trouver ou non le bon
    if (!item)
      setFilteredProducts(productsNonImei)

    setSelectedBrand(item.brand)
  };

  const handleModelOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string.length === 0) {
      setFilterModel(string);
    }
    setNewArticle({ ...newArticle, model: string })
  };

  const handleModelOnSelect = (item) => {
    // the item selected
    // on met à jour la liste globale des articles jusqu'à trouver ou non le bon
    if (!item)
      setFilteredProducts(productsNonImei)

    setSelectedModel(item.model)
    setSelectedName(item.model)
    setSelectedBrand(item.brand)

    // Forcer la mise à jour du composant
    setForceUpdate(forceUpdate + 1);
  };

  const handleNameOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string.length === 0) {
      setFilterName(string);
    }

    setNewArticle({ ...newArticle, name: string })
  };

  const handleNameOnSelect = (item) => {
    // the item selected
    // on met à jour la liste globale des articles jusqu'à trouver ou non le bon
    if (!item)
      setFilteredProducts(productsNonImei)

    setSelectedName(item.name)
    setSelectedModel(item.model)
    setSelectedBrand(item.brand)

    // Forcer la mise à jour du composant
    setForceUpdate(forceUpdate + 1);
  };

  const handleOnFocus = () => {

  };

  const handleOnBrandClear = () => {
    setFilteredProducts(productsNonImei)
    setSelectModels([])
    setSelectNames([])
    setSelectBrands([])
    setSelectedBrand(null)
    setSelectedModel(null)
    setSelectedName(null)
    setDisableButton(true)
  };

  const handleOnModelClear = () => {
    setFilteredProducts(productsNonImei)
    setSelectNames([])
    setSelectModels([])
    setSelectedModel(null)
    setSelectedName(null)
    setDisableButton(true)
  };

  const handleOnNameClear = () => {
    setFilteredProducts(productsNonImei)
    setSelectModels([])
    setSelectNames([])
    setSelectedModel(null)
    setSelectedName(null)
    setDisableButton(true)
  };

  const handleFileUpload = (e) => {
    let i = 0
    let folder = 'product'
    if (filteredProducts && filteredProducts.length === 1) {
      files.forEach(image => {
        let formData = new FormData();
        let extension = image.name.split('.')[image.name.split('.').length - 1]

        let imageName = uuid() + '.' + extension

        formData.append('image', image, imageName);
        formData.append('folder', folder);

        let subFolders = [filteredProducts[0].brand.replace(/\s+/g, '-'),
        filteredProducts[0].model.replace(/\s+/g, '-'),
        filteredProducts[0].name.replace(/\s+/g, '-')]

        subFolders.forEach((element, index) => {
          formData.append(`subFolders[${index}]`, element);
        });

        dispatch(uploadImage(formData)).then(() => {
          if (image === files[0])
            dispatch(createDefaultNonImeiImage(filteredProducts[0].brand, filteredProducts[0].model, filteredProducts[0].name, imageName))
          //Création ici
          dispatch(listNonImeiImage(filteredProducts[0].brand, filteredProducts[0].model, filteredProducts[0].name))
        })
        i++
      });
      clearAllFiles()
    }
  };

  const removeFromImagesHandler = (name) => {
    // on supprime une image produit grâce au nom
    let folder = 'product'
    let formData = new FormData();

    formData.append('name', name);
    formData.append('folder', folder);

    let subFolders = [filteredProducts[0].brand.replace(/\s+/g, '-'),
    filteredProducts[0].model.replace(/\s+/g, '-'),
    filteredProducts[0].name.replace(/\s+/g, '-')]

    subFolders.forEach((element, index) => {
      formData.append(`subFolders[${index}]`, element);
    });

    dispatch(deleteImage(formData)).then(() => {
      dispatch(listNonImeiImage(filteredProducts[0].brand, filteredProducts[0].model, filteredProducts[0].name))
    })
  }

  const onDefaultImageChangeValue = (event) => {
    if (filteredProducts && filteredProducts.length === 1) {
      dispatch(updateDefaultNonImeiImage(filteredProducts[0].brand, filteredProducts[0].model, filteredProducts[0].name, event.target.value))
    }
  }

  const formatBrandResult = (item) => {
    return (
      <>
        {
          item ? (
            <Container className=''>
              <Row className=''>
                <Col md={4} className=''>
                  Marque : {item.brand}
                </Col>
              </Row>
            </Container>
          ) : null
        }

      </>
    )
  };

  const formatModelResult = (item) => {
    return (
      <>
        {
          item ? (
            <Container className=''>
              <Row className=''>
                <Col md={4} className=''>
                  Modèle : {item.model}
                </Col>
              </Row>
            </Container>
          ) : null
        }

      </>
    )
  };

  const formatNameResult = (item) => {
    return (
      <>
        {
          item ? (
            <Container className=''>
              <Row className=''>
                <Col md={4} className=''>
                  Nom : {item.name}
                </Col>
              </Row>
            </Container>
          ) : null
        }

      </>
    )
  };

  const removeSpec = (index) => {
    const updatedSpecs = [...filteredProducts[0].specifications];
    updatedSpecs.splice(index, 1);
    // Mettre à jour filteredProducts avec la nouvelle liste de spécifications:
    setFilteredProducts([{ ...filteredProducts[0], specifications: updatedSpecs }]);
  }

  const addSpec = () => {
    if (filteredProducts && filteredProducts.length == 1) {
      const updatedSpecs = filteredProducts[0].specifications.concat(
        {
          brand: filteredProducts[0].brand,
          model: filteredProducts[0].model,
          name: filteredProducts[0].name,
          spec_name: newSpecName,
          spec_value: newSpecValue
        }
      );
      // Mettre à jour filteredProducts avec la nouvelle liste de spécifications:
      setFilteredProducts([{ ...filteredProducts[0], specifications: updatedSpecs }]);
    }
    else {
      if (!newArticle.specifications) {
        newArticle.specifications = []
      }
      newArticle.specifications.push(
        {
          brand: newArticle.brand,
          model: newArticle.model,
          name: newArticle.name,
          spec_name: newSpecName,
          spec_value: newSpecValue
        })
    }

    setNewSpecName("");
    setNewSpecValue("");
  }

  function Cancel() {

  }

  // Fonction servant à enregistrer un nouvel article ou à mettre à jour un article existant
  function Validate() {
    if (toggleChecked && productsNonImei && productsNonImei.length > 0) {
      filteredProducts[0].categorie = categorie.name
      dispatch(updateNonImeiProduct(filteredProducts[0]))

    }
    else if (newArticle && newArticle.name && newArticle.brand && newArticle.model && newArticle.categorie) {
      //create article
      dispatch(createNonImeiProduct(newArticle))
    }
    dispatch(listNonImeiProducts(userInfo ? userInfo.id_odoo : -1));
    if (inputSku && inputSku.length > 0)
      dispatch(addSku(filteredProducts[0].id, inputSku))
  }

  function manageSkuInput(value) {
    setInputSku(value.split('\n'));
  }

  const handleToggleChange = (checked) => {
    setToggleChecked(checked);
  };

  return (
    <>
      {loading || loadingCreate || loadingUpdate ? <Loader /> :
        <div className='articlesNonImei-container'>
          <Row className='articlesNonImei-title-row'>
            <h1>Ajouter un article</h1>
          </Row>
          <Row className='articlesNonImei-main-row'>
            <Col md={4}>
              <Row className='articles-switch-row'>
                <Col md={2}>
                  <Switch
                    className='addarticles-switch'
                    onChange={handleToggleChange}
                    checked={toggleChecked}
                    offColor="#2f86ca"
                    boxShadow="0px 5px 10px rgba(0, 0, 0, 0.6)"
                  />
                </Col>
                <Col md={8}>
                  <span>{!toggleChecked ? 'Ajout' : 'Modification'}</span>
                </Col>
              </Row>
              <Row className='add-nonimei-search-row'>
                <Select
                  className='addnonimei-categories-select'
                  name="addnonimei-categories-select"
                  title="Catégories"
                  value={categorie}
                  options={selectCategories}
                  onChangeFunc={(e) => onHandleCategorySelectChange(e)}
                />
              </Row>
              {
                toggleChecked ?
                  <Row className='add-nonimei-search-row'>
                    {
                      categorie && categorie.name ?
                        <Select
                          className='addnonimei-brand-select'
                          name="addnonimei-brand-select"
                          title="Marques"
                          value={selectedBrand}
                          options={selectBrands}
                          onChangeFunc={(e) => onHandleBrandSelectChange(e)}
                        />
                        : null
                    }
                    {
                      selectedBrand && selectedBrand != '' ?
                        <Select
                          className='addnonimei-model-select'
                          name="addnonimei-model-select"
                          title="Modèles"
                          value={selectedModel}
                          options={selectModels}
                          onChangeFunc={(e) => onHandleModelSelectChange(e)}
                        />
                        : null
                    }
                    {
                      selectedBrand && selectedBrand != '' ?
                        <Select
                          className='addnonimei-name-select'
                          name="addnonimei-name-select"
                          title="Noms"
                          value={selectedName}
                          options={selectNames}
                          onChangeFunc={(e) => onHandleNameSelectChange(e)}
                        />
                        : null
                    }
                  </Row>
                  :
                  <>
                    <Row className='add-nonimei-search-row'>
                      <ReactSearchAutocomplete
                        key={`brandSearch-${forceUpdate}`}
                        items={removeDuplicatesByProperties(filteredProducts, 'brand')}
                        showIcon={false}
                        fuseOptions={{ keys: ['brand'] }}
                        resultStringKeyName="brand"
                        onSearch={handleBrandOnSearch}
                        onSelect={handleBrandOnSelect}
                        onFocus={handleOnFocus}
                        onClear={handleOnBrandClear}
                        value={selectedBrand}
                        autoFocus
                        placeholder="Marque"
                        formatResult={formatBrandResult}
                        threshold={0.5} // 0 = exact match, 1 = everything matches
                        ignoreLocation={true}
                        showNoResults={false}
                        styling={{
                          border: "1px solid #dfe1e5",
                          borderRadius: "5px",
                          backgroundColor: "white",
                          boxShadow: "rgba(58, 53, 53, 0.2) 0px 1px 6px 0px",
                          hoverBackgroundColor: "#eee",
                          color: "#212121",
                          fontSize: "12px",
                          fontFamily: "Arial",
                          iconColor: "grey",
                          lineColor: "rgb(232, 234, 237)",
                          placeholderColor: "grey",
                          clearIconMargin: '0 5px 0 0',
                          searchIconMargin: '0 0 0 5px',
                          zIndex: 9,
                        }}
                      />
                    </Row>
                    <Row className='add-nonimei-search-row'>
                      <ReactSearchAutocomplete
                        items={removeDuplicatesByProperties(filteredProducts, 'model')}
                        showIcon={false}
                        fuseOptions={{ keys: ['model'] }}
                        resultStringKeyName="model"
                        onSearch={handleModelOnSearch}
                        onSelect={handleModelOnSelect}
                        onFocus={handleOnFocus}
                        onClear={handleOnModelClear}
                        value={selectedModel}
                        autoFocus
                        placeholder="Modèle"
                        formatResult={formatModelResult}
                        threshold={0.5} // 0 = exact match, 1 = everything matches
                        ignoreLocation={true}
                        showNoResults={false}
                        styling={{
                          border: "1px solid #dfe1e5",
                          borderRadius: "5px",
                          backgroundColor: "white",
                          boxShadow: "rgba(58, 53, 53, 0.2) 0px 1px 6px 0px",
                          hoverBackgroundColor: "#eee",
                          color: "#212121",
                          fontSize: "12px",
                          fontFamily: "Arial",
                          iconColor: "grey",
                          lineColor: "rgb(232, 234, 237)",
                          placeholderColor: "grey",
                          clearIconMargin: '0 5px 0 0',
                          searchIconMargin: '0 0 0 5px',
                          zIndex: 8,
                        }}
                      />
                    </Row>
                    <Row className='add-nonimei-search-row'>
                      <ReactSearchAutocomplete
                        items={removeDuplicatesByProperties(filteredProducts, 'name')}
                        showIcon={false}
                        fuseOptions={{ keys: ['name'] }}
                        resultStringKeyName="name"
                        onSearch={handleNameOnSearch}
                        onSelect={handleNameOnSelect}
                        onFocus={handleOnFocus}
                        onClear={handleOnNameClear}
                        value={selectedName}
                        autoFocus
                        placeholder="Nom"
                        formatResult={formatNameResult}
                        threshold={0.5} // 0 = exact match, 1 = everything matches
                        ignoreLocation={true}
                        showNoResults={false}
                        styling={{
                          border: "1px solid #dfe1e5",
                          borderRadius: "5px",
                          backgroundColor: "white",
                          boxShadow: "rgba(58, 53, 53, 0.2) 0px 1px 6px 0px",
                          hoverBackgroundColor: "#eee",
                          color: "#212121",
                          fontSize: "12px",
                          fontFamily: "Arial",
                          iconColor: "grey",
                          lineColor: "rgb(232, 234, 237)",
                          placeholderColor: "grey",
                          clearIconMargin: '0 5px 0 0',
                          searchIconMargin: '0 0 0 5px',
                          zIndex: 7,
                        }}
                      />
                    </Row>
                  </>
              }
              {
                toggleChecked && filteredProducts && filteredProducts.length === 1 ?
                  <Row className='add-nonimei-sku-row'>
                    {/* <Col md={5}>
                      {
                        filteredProducts[0].skus.map((sku, index) => {
                          return (
                            <div>
                              <span>
                                {sku}
                              </span>
                            </div>
                          )
                        })
                      }
                    </Col> */}
                    <Col md={5}>
                      <span>Skus en base</span>
                      <textarea
                        className='add-nonimei-sku'
                        value={filteredProducts[0].skus.join("\n")}
                      // ReactTooltip={"Le numéro IMEI doit comporter 15 chiffres."}
                      />
                    </Col>
                    <Col md={5}>
                      <span>Skus à ajouter</span>
                      <textarea
                        className='add-nonimei-sku'
                        id="sku-input"
                        aria-describedby="passwordHelpBlock"
                        placeholder="sku"
                        ref={searchRef}
                        onChange={(e) => manageSkuInput(e.target.value)}
                      // ReactTooltip={"Le numéro IMEI doit comporter 15 chiffres."}
                      />
                    </Col>

                  </Row>
                  : null
              }

              {toggleChecked && filteredProducts && filteredProducts.length === 1 && imagesNonImei && imagesNonImei.length > 0 ?
                <>
                  <Row className='imageproducts-nonimei-products-row'>
                    <ListGroup className='imageproducts-nonimei-products' variant='flush'>
                      {imagesNonImei.map((item, index) => {
                        const defaultImage = defaultNonImeiImages.find((di) => di.image === item.name);
                        const isDefaultImage = defaultImage != null;
                        return (
                          <ListGroup.Item className='imageproducts-nonimei-items' key={item.product}>
                            <Row className='imageproducts-nonimei-row-item'>
                              <Col className='vertical-center' md={2}>
                                <Image className='imageproducts-nonimei-img' src={item.url} alt={item.url} fluid rounded />
                              </Col>
                              <Col className='imageproducts-nonimei-name' md={6}>
                                <span>{item.name}</span>
                              </Col>
                              <Col className='' md={1}>
                                <input
                                  id={`reverse-radio-1`}
                                  className="form-check-input"
                                  name="flexRadioDefault"
                                  type='radio'
                                  value={item.name}
                                  defaultChecked={isDefaultImage}
                                  // checked={state.selectedOption === "Male"}
                                  onClick={(event) => onDefaultImageChangeValue(event)}
                                />
                              </Col>
                              <Col className='imageproducts-nonimei-item-input' md={3}>
                                <button
                                  className="btn-imageproducts-nonimei-remove"
                                  onClick={() => dispatch(removeFromImagesHandler(item.name))}>
                                  <i className='fas fa-trash'></i>
                                </button>
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </Row>
                </>
                : null
              }
            </Col>
            {toggleChecked && filteredProducts && filteredProducts.length === 1 ?
              <Col className='articlesNonImei-specifications-col' md={8}>
                <Row>
                  <h2>{filteredProducts[0].brand} {filteredProducts[0].name}</h2>
                </Row>
                <Row className='imageproducts-nonimei-file-container'>
                  <Col className='imageproducts-nonimei-drop-zone-col' md={4}>
                    {/* Provide a drop zone and an alternative button inside it to upload files. */}
                    <div
                      className='imageproducts-nonimei-drop-zone'
                      onDragEnter={handleDragDropEvent}
                      onDragOver={handleDragDropEvent}
                      onDrop={(e) => {
                        handleDragDropEvent(e);
                        setFiles(e, 'a');
                      }}
                    >
                      <p>Déposez vos fichiers ici</p>
                    </div>
                    <Row className='imageproducts-nonimei-btn_addfiles-row'>
                      <button
                        className='imageproducts-nonimei-btn-select-file'
                        onClick={() => inputRef.current.click()}
                      >Sélectionner un fichier</button>
                      <input
                        ref={inputRef}
                        type="file"
                        multiple
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          setFiles(e, 'a');
                          inputRef.current.value = null;
                        }}
                      />
                    </Row>
                  </Col>
                  <Col className='imageproducts-nonimei-list-col' md={6}>
                    <Row className='imageproducts-nonimei-list-files-row'>
                      <ul>
                        <p className='imageproducts-nonimei-list-files-title'>Images à envoyer</p>
                        {fileNames.map((name) => (
                          <li key={name}>
                            <span>{name}</span>

                            <span onClick={() => removeFile(name)}>
                              <i className="fa-regular fa-trash-can file-remove"></i>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </Row>
                    <Row className='imageproducts-nonimei-btn_addfiles-row'>
                      <button
                        className='imageproducts-nonimei-btn-add-file'
                        onClick={() => handleFileUpload()}
                      >Ajouter</button>
                    </Row>
                  </Col>
                </Row>
                <Row className='articlesNonImei-specifications-main-row'>
                  <Row>
                    <h2>{t('Caractéristiques de l\'article')}</h2>
                  </Row>
                  {
                    filteredProducts && filteredProducts.length == 1 && filteredProducts[0].specifications.length > 0 ?
                      filteredProducts[0].specifications.map((specification, index) => (
                        <Row className='articlesNonImei-specifications-row'>
                          <Col md={4}>
                            <Form.Control
                              type='text'
                              value={specification.spec_name}
                              onChange={(e) => setNewSpecName(e.target.value)}
                              autoFocus={false}
                            />
                          </Col>
                          <Col md={4}>
                            <Form.Control
                              type='text'
                              value={specification.spec_value}
                              onChange={(e) => setNewSpecValue(e.target.value)}
                              autoFocus={false}
                            />
                          </Col>
                          <Col className='nonimei-button-col' md={4}>
                            <button className='btn-nonimei-remove' onClick={() => removeSpec(index)}><i className="fa-solid fa-trash fa-lg"></i></button>
                          </Col>
                        </Row>
                      )

                      ) :

                      newArticle && newArticle.specifications && newArticle.specifications.length > 0 ?
                        newArticle.specifications.map((specification, index) => (
                          <Row className='articlesNonImei-specifications-row'>
                            <Col md={4}>
                              <Form.Control
                                type='text'
                                value={specification.spec_name}
                                onChange={(e) => setNewSpecName(e.target.value)}
                                autoFocus={false}
                              />
                            </Col>
                            <Col md={4}>
                              <Form.Control
                                type='text'
                                value={specification.spec_value}
                                onChange={(e) => setNewSpecValue(e.target.value)}
                                autoFocus={false}
                              />
                            </Col>
                            <Col className='nonimei-button-col' md={4}>
                              <button className='btn-nonimei-remove' onClick={() => removeSpec(index)}><i className="fa-solid fa-trash fa-lg"></i></button>
                            </Col>
                          </Row>
                        )) : null
                  }
                  {
                    (toggleChecked && newArticle.brand != '' && newArticle.model != '' && newArticle.name != '') ||
                      (filteredProducts && filteredProducts.length == 1 && filteredProducts[0].specifications.length > 0) ?
                      <Row className='articlesNonImei-specifications-row' >
                        <Col md={4}>
                          <Form.Control
                            type='text'
                            value={typeof (newSpecName) == "string" ? newSpecName : ''}
                            onChange={(e) => setNewSpecName(e.target.value)}
                            autoFocus={false}
                          />
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type='text'
                            value={typeof (newSpecValue) == "string" ? newSpecValue : ''}
                            onChange={(e) => setNewSpecValue(e.target.value)}
                            autoFocus={false}
                          />
                        </Col>
                        <Col className='nonimei-button-col' md={4}>
                          <button className='btn-nonimei-add' onClick={(e) => addSpec(e)}><i className="fa-solid fa-add fa-lg"></i></button>
                        </Col>
                      </Row>
                      : null
                  }
                </Row>
              </Col>
              :
              null
            }

          </Row >
          <Row className='nonimei-btn-row'>
            <Col>
              <button
                className='btn-nonimei-cancel'
                type='button'
                disabled={disableButton}
                onClick={Cancel}
              >
                Annuler
              </button>
            </Col>
            <Col>
              <button
                className='btn-nonimei-validate'
                type='button'
                disabled={disableButton}
                onClick={Validate}
              >
                Valider
              </button>
            </Col>
          </Row>
        </div >
      }
    </>)
}

export default AddArticlesNonImei