import{
    PURCHASE_LIST_REQUEST,
    PURCHASE_LIST_SUCCESS,
    PURCHASE_LIST_FAIL,
    PURCHASE_LIST_RESET,

    PURCHASE_ADD_REQUEST,
    PURCHASE_ADD_SUCCESS,
    PURCHASE_ADD_FAIL,

    PURCHASE_UPDATE_REQUEST,
    PURCHASE_UPDATE_SUCCESS,
    PURCHASE_UPDATE_FAIL,

    PURCHASE_DELETE_REQUEST,
    PURCHASE_DELETE_SUCCESS,
    PURCHASE_DELETE_FAIL,
}
from "../constants/purchaseConstants";

import axios from 'axios';

export const listPurchases = (product_id = 0, productNonImei_id=0) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PURCHASE_LIST_REQUEST
        })

        const { userLogin: { userInfo } } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
        })

        const { data } = await instance.get(`/api/purchase/?product_id=${product_id}&productNonImei_id=${productNonImei_id}`)

        dispatch({
            type: PURCHASE_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: PURCHASE_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const addPurchase = (purchase) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PURCHASE_ADD_REQUEST
        })

        const { userLogin: { userInfo } } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json', 
                Authorization: `Bearer ${userInfo.token}`
            },
        })

        const { data } = await instance.post(`/api/purchase/add/`, purchase)
        dispatch({
            type: PURCHASE_ADD_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: PURCHASE_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const updatePurchase = (purchase) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PURCHASE_UPDATE_REQUEST
        })

        const { userLogin: { userInfo } } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json', 
                Authorization: `Bearer ${userInfo.token}`
            },
        })

        const { data } = await instance.post(`/api/purchase/update/`, purchase)

        dispatch({
            type: PURCHASE_UPDATE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: PURCHASE_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const deletePurchase = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PURCHASE_DELETE_REQUEST
        })

        const { userLogin: { userInfo } } = getState()

        const instance = axios.create({
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            },
        })

        await instance.delete(`/api/purchase/delete/${id}`)

        dispatch({
            type: PURCHASE_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: PURCHASE_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}