import React, { useEffect, useState } from 'react';
import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/system';

import {
  REPAIR_DRAFT,
  REPAIR_CONFIRMED,
  REPAIR_READY,
  REPAIR_UNDER_REPAIR,
  REPAIR_DONE
}
  from '../../constants/statusConstants';

const DeviceSavHistory = ({ history }) => {
  const [savHistory, setSavHistory] = useState([]);

  const HighlightedTableRow = styled(TableRow)({
    backgroundColor: '#CCFAD2', // Couleur de fond de votre choix pour mettre en valeur la ligne
  });

  useEffect(() => {
    setSavHistory(history.filter(h => h.sav_history));
  }, []);

  useEffect(() => {
    
  }, [savHistory]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois sont indexés à partir de 0
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  function getStatus(state) {
    switch (state) {
      case REPAIR_DRAFT:
        return 'Brouillon';
      case REPAIR_CONFIRMED:
        return 'Confirmé';
      case REPAIR_READY:
        return 'Prêt';
      case REPAIR_UNDER_REPAIR:
        return 'En réparation';
      case REPAIR_DONE:
        return 'Réparé';
      default:
        return 'État inconnu';
    }
  }


  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h6">Historique des tickets SAV</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>N° de ticket</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>État du ticket</TableCell>
                  <TableCell>Prestations</TableCell>
                  <TableCell>MAJ par</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history.map((h, index) => {
                  if (h.sav_history) {
                    const { name, create_date, description, state, lines } = h.sav_history;
                    if(h===history[history.length -1]){
                      return (
                      <HighlightedTableRow key={index}>
                        <TableCell>{name}</TableCell>
                        <TableCell>{formatDate(create_date)}</TableCell>
                        <TableCell>{getStatus(state)}</TableCell>
                        <TableCell>
                          {lines.map((line, lineIndex) => (
                            <div key={lineIndex}>
                              {line.name} - {line.price_unit}€
                            </div>
                          ))}
                        </TableCell>
                        <TableCell>{h.updated_by ? h.updated_by.name : ''}</TableCell>
                      </HighlightedTableRow>
                    );
                    }else{
                      return (
                        <TableRow key={index}>
                          <TableCell>{name}</TableCell>
                          <TableCell>{formatDate(create_date)}</TableCell>
                          <TableCell>{getStatus(state)}</TableCell>
                          <TableCell>
                            {lines.map((line, lineIndex) => (
                              <div key={lineIndex}>
                                {line.name} - {line.price_unit}€
                              </div>
                            ))}
                          </TableCell>
                          <TableCell>{h.updated_by.name}</TableCell>
                        </TableRow>
                      );
                    }
                    
                  } else {
                    return null;
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
};

export default DeviceSavHistory;

