import {
    MESSAGE_LIST_REQUEST,
    MESSAGE_LIST_SUCCESS,
    MESSAGE_LIST_FAIL,

    MESSAGE_CREATE_REQUEST,
    MESSAGE_CREATE_SUCCESS,
    MESSAGE_CREATE_FAIL,
    MESSAGE_CREATE_RESET,

    MESSAGE_DELETE_REQUEST,
    MESSAGE_DELETE_SUCCESS,
    MESSAGE_DELETE_FAIL,
    MESSAGE_DELETE_RESET,

    MESSAGE_UPDATE_REQUEST,
    MESSAGE_UPDATE_SUCCESS,
    MESSAGE_UPDATE_FAIL,
    MESSAGE_UPDATE_RESET,

    MESSAGE_AS_READ_REQUEST,
    MESSAGE_AS_READ_SUCCESS,
    MESSAGE_AS_READ_FAIL,

    MESSAGE_AS_UNREAD_COUNT_REQUEST,
    MESSAGE_AS_UNREAD_COUNT_SUCCESS,
    MESSAGE_AS_UNREAD_COUNT_FAIL,

    MESSAGE_SEND_MAIL_REQUEST,
    MESSAGE_SEND_MAIL_SUCCESS,
    MESSAGE_SEND_MAIL_FAIL,
    MESSAGE_SEND_MAIL_RESET,
}
    from '../constants/messageConstants.js'

export const messageListReducer = (state = { messages: [] }, action) => {
    switch (action.type) {
        case MESSAGE_LIST_REQUEST:
            return { loading: true, messages: [] }
        case MESSAGE_LIST_SUCCESS:
            return { loading: false, messages: action.payload }
        case MESSAGE_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const messageCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case MESSAGE_CREATE_REQUEST:
            return { loading: true }
        case MESSAGE_CREATE_SUCCESS:
            return { loading: false, successCreate: true, message: action.payload }
        case MESSAGE_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case MESSAGE_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const messageDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case MESSAGE_DELETE_REQUEST:
            return { loading: true }
        case MESSAGE_DELETE_SUCCESS:
            return { loading: false, successDelete: true }
        case MESSAGE_DELETE_FAIL:
            return { loading: false, error: action.payload }
        case MESSAGE_DELETE_RESET:
            return {}
        default:
            return state
    }
}

export const messageUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case MESSAGE_UPDATE_REQUEST:
            return { loading: true }
        case MESSAGE_UPDATE_SUCCESS:
            return { loading: false, successDelete: true }
        case MESSAGE_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        case MESSAGE_UPDATE_RESET:
            return {}
        default:
            return state
    }
}

export const messageAsReadReducer = (state = {}, action) => {
    switch (action.type) {
        case MESSAGE_AS_READ_REQUEST:
            return { loading: true }
        case MESSAGE_AS_READ_SUCCESS:
            return { loading: false, successAsRead: true }
        case MESSAGE_AS_READ_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const messageAsUnreadCountReducer = (state = {}, action) => {
    switch (action.type) {
        case MESSAGE_AS_UNREAD_COUNT_REQUEST:
            return { loading: true }
        case MESSAGE_AS_UNREAD_COUNT_SUCCESS:
            return { loading: false, successAsUnreadCount: true, unreadCount: action.payload }
        case MESSAGE_AS_UNREAD_COUNT_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const sendMailReducer = (state = {}, action) => {
    switch (action.type) {
        case MESSAGE_SEND_MAIL_REQUEST:
            return { loading: true }
        case MESSAGE_SEND_MAIL_SUCCESS:
            return { loading: false, success: true }
        case MESSAGE_SEND_MAIL_FAIL:
            return { loading: false, error: action.payload }
        case MESSAGE_SEND_MAIL_RESET:
            return {}
        default:
            return state
    }
}
