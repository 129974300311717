import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, ListGroup, Image, Form, Container } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom';

import Loader from '../../components/Loader/Loader'
import CountrySelector from '../../components/Selector/CountrySelector/CountrySelector';
import Message from '../../components/Message/Message'
import { listContactsOdoo, createContactOdoo, resetContactOdoo } from '../../actions/contactOdooActions'

import { useTranslation } from 'react-i18next';

function Societe() {

  const dispatch = useDispatch()
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    street: '',
    street2: '',
    zip: '',
    city: '',
    country_id: 75,
    phone: '',
    mobile: '',
    vat: '',
    siret: '',
    is_company: true,
    parent_id: '',
  });

  let navigate = useNavigate();

  const countryList = useSelector((state) => state.countryList)
  const { countries } = countryList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const contactOdooCreate = useSelector((state) => state.contactOdooCreate)
  const { loading, error, success } = contactOdooCreate

  const [message, setMessage] = useState(null)
  const [confirmPassword, setConfirmPassword] = useState('')

  useEffect(() => {
    if (userInfo && userInfo.id_odoo) {
      setFormData({
        ...formData,
        ['parent_id']: userInfo.id_odoo,
      });
    }
  }, [userInfo])

  useEffect(() => {
    if (success) {
      dispatch(listContactsOdoo(userInfo.id_odoo));
      dispatch(resetContactOdoo());
      navigate('/profile')
    }
  }, [success])

  const addInputCountry = (value) => {
    // TODO Récupérer les pays odoo et filtrer sur la liste pour récupérer l'id

    const country_id = countries.filter(country => country.name === value.label)[0].id;

    setFormData({
      ...formData,
      ['country_id']: country_id,
    });
  };

  function addSociete() {
    const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/
    const postalCodePattern = /^[0-9]{5}$/

    let messages = []

    if (formData.password !== confirmPassword) {
      messages.push(t('Passwords do not match'))
    }
    if (!emailPattern.test(formData.email)) {
      messages.push(t('Email address is not valid'))
    }
    if (!postalCodePattern.test(formData.zip)) {
      messages.push(t('Postal code is not valid'))
    }
    if (!validateSIRET(formData.siret)) {
      messages.push(t('SIRET number is not valid'))
    }
    if (!validateVAT(formData.vat)) {
      messages.push(t('VAT number is not valid'))
    }

    if (messages.length === 0) {
      dispatch(createContactOdoo(formData))
    } else {
      let formattedMessages = "Les champs suivants ne sont pas valide : <br/>- " + messages.join('<br/>- ')
      setMessage(formattedMessages)
    }
  }

  const validateVAT = (value) => {
    const VATRegex = /^FR[0-9A-Z]{2}[0-9]{9}$/;

    return VATRegex.test(value);
  };

  const validateSIRET = (value) => {
    if (value.length !== 14) {
      return false;
    }

    let sum = 0;
    for (let i = 0; i < 14; i++) {
      let digit = parseInt(value[i], 10);
      if (i % 2 === 0) {
        digit *= 2;
        if (digit > 9) {
          digit -= 9;
        }
      }
      sum += digit;
    }

    return sum % 10 === 0;
  };

  function cancel() {
    navigate('/profile')
  }

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  return (
    <>
      <div className='profile-title'>{t('Add a company')}</div>
      {
        loading ? <Loader /> :
          <Row className='profile-infos-row'>
            <Col md={6}>
              <Row className='profile-first-row'>
                <Form.Group className='form-group-profile' controlId='name'>
                  <Form.Label>{t('Name')}</Form.Label>
                  <Form.Control
                    required
                    name="name"
                    type='text'
                    placeholder={t('Enter name')}
                    value={formData.name}
                    onChange={handleInputChange}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className='form-group-profile' controlId='email'>
                  <Form.Label>{t('Email Address')}</Form.Label>
                  <Form.Control
                    required
                    name="email"
                    type='email'
                    placeholder={t('Enter email')}
                    value={formData.email}
                    onChange={handleInputChange}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className='form-group-profile' controlId='password'>
                  <Form.Label>{t('Password')}</Form.Label>
                  <Form.Control
                    required
                    name="password"
                    type='password'
                    placeholder={t('Enter password')}
                    value={formData.password}
                    onChange={handleInputChange}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className='form-group-profile' controlId='confirmPassword'>
                  <Form.Label>{t('Confirm Password')}</Form.Label>
                  <Form.Control
                    required
                    type='password'
                    placeholder={t('Confirm password')}
                    value={formData.confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Row>
              <Row className='profile-second-row'>
                <Col>
                  <Form.Group className='form-group-profile' controlId='street'>
                    <Form.Label>{t('Street')}</Form.Label>
                    <Form.Control
                      required
                      name="street"
                      type='text'
                      placeholder={t('Street')}
                      value={formData.street}
                      onChange={handleInputChange}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className='form-group-profile' controlId='street2'>
                    <Form.Label>{t('Street 2')}</Form.Label>
                    <Form.Control
                      name="street2"
                      type='text'
                      placeholder={t('Street2')}
                      value={formData.street2}
                      onChange={handleInputChange}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className='form-group-profile' controlId='zip'>
                    <Form.Label>{t('Postal Code')}</Form.Label>
                    <Form.Control
                      required
                      name="zip"
                      type='text'
                      placeholder={t('Postal Code')}
                      value={formData.zip}
                      onChange={handleInputChange}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className='form-group-profile' controlId='city'>
                    <Form.Label>{t('City')}</Form.Label>
                    <Form.Control
                      required
                      name="city"
                      type='text'
                      placeholder={t('City')}
                      value={formData.city}
                      onChange={handleInputChange}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='form-group-profile' controlId='country'>
                    <Form.Label>{t('Country')}</Form.Label>
                    <CountrySelector addInputCountry={addInputCountry} />
                  </Form.Group>
                  <Form.Group className='form-group-profile' controlId='vat'>
                    <Form.Label>{t('Intra-community VAT')}</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="vat"
                      placeholder={t('Intra VAT')}
                      value={formData.vat}
                      onChange={handleInputChange}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className='form-group-profile' controlId='siret'>
                    <Form.Label>{t('SIRET')}</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="siret"
                      placeholder='SIRET'
                      value={formData.siret}
                      onChange={handleInputChange}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              {message && <Message variant='danger'>{message}</Message>}
              <Row className='row-btn-profile'>
                <Col md={3}>
                  <button className='btn-profile-cancel' onClick={() => cancel()} type='submit' variant='primary'>
                    {t('Cancel')}
                  </button>
                </Col>
                <Col md={3}>
                  <button className='btn-profile-validate' onClick={() => addSociete()} variant='primary'>
                    {t('Validate')}
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
      }
    </>
  );

}

export default Societe