export const FEATURE_LIST_REQUEST = 'FEATURE_LIST_REQUEST'
export const FEATURE_LIST_SUCCESS = 'FEATURE_LIST_SUCCESS'
export const FEATURE_LIST_FAIL = 'FEATURE_LIST_FAIL'

export const ALL_FEATURE_REQUEST = 'ALL_FEATURE_REQUEST'
export const ALL_FEATURE_SUCCESS = 'ALL_FEATURE_SUCCESS'
export const ALL_FEATURE_FAIL = 'ALL_FEATURE_FAIL'

export const FEATURE_FILTER_REQUEST = 'FEATURE_FILTER_REQUEST'
export const FEATURE_FILTER_SUCCESS = 'FEATURE_FILTER_SUCCESS'
export const FEATURE_FILTER_FAIL = 'FEATURE_FILTER_FAIL'

export const FEATURE_MAJ_FILTER_REQUEST = 'FEATURE_MAJ_FILTER_REQUEST'
export const FEATURE_MAJ_FILTER_SUCCESS = 'FEATURE_MAJ_FILTER_SUCCESS'
export const FEATURE_MAJ_FILTER_FAIL = 'FEATURE_MAJ_FILTER_FAIL'
export const FEATURE_MAJ_FILTER_RESET = 'FEATURE_MAJ_FILTER_RESET'

export const FEATURE_ADD_REQUEST = 'FEATURE_ADD_REQUEST'
export const FEATURE_ADD_SUCCESS = 'FEATURE_ADD_SUCCESS'
export const FEATURE_ADD_FAIL = 'FEATURE_ADD_FAIL'
export const FEATURE_ADD_RESET = 'FEATURE_ADD_RESET'

export const FEATURE_DELETE_REQUEST = 'FEATURE_DELETE_REQUEST'
export const FEATURE_DELETE_SUCCESS = 'FEATURE_DELETE_SUCCESS'
export const FEATURE_DELETE_FAIL = 'FEATURE_DELETE_FAIL'