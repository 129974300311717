import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { Row, Col, Container, ListGroup, Placeholder } from 'react-bootstrap'
import Modal from 'react-modal';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';

import { addNewOrderToCart } from '../../../../actions/cartActions';

import Loader from '../../../../components/Loader/Loader'
import DateRange from '../../../../components/DateRange/DateRange';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCartShopping
}
  from '@fortawesome/free-solid-svg-icons';

import RangeSlider from 'react-range-slider-input';

import {
  ORDER_DRAFT, ORDER_SENT, ORDER_SALE, ORDER_DONE, ORDER_CANCEL,
  INVOICE_NO, INVOICE_INVOICED,
  DELIVERY_DRAFT, DELIVERY_WAITING, DELIVERY_CONFIRMED, DELIVERY_ASSIGNED, DELIVERY_DONE, DELIVERY_CANCEL,
  PAYMENT_PAID, PAYMENT_IN_PAYMENT, PAYMENT_REVERSE
}
  from '../../../../constants/statusConstants';

import { toArrayKeys, removeDuplicates, removeDuplicatesByProperties, findVal } from "../../../../utils/utils";

import { listOrders } from '../../../../actions/orderActions';
import { listNonImeiProducts, listProducts } from '../../../../actions/productActions'
import { getDocuments } from '../../../../actions/documentActions';

import { getInvoice } from '../../../../actions/documentActions';
import { createOrder } from '../../../../actions/orderActions';

import './Orders.css'
import { useTranslation } from 'react-i18next';

function Order() {
  const dispatch = useDispatch()
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [priceOpen, setPriceOpen] = useState(false);
  const [brandOpen, setBrandOpen] = useState(false);
  const [dateAchatOpen, setDateAchatOpen] = useState(false);
  const [dateLivraisonOpen, setDateLivraisonOpen] = useState(false);
  const [demandeurOpen, setDemandeurOpen] = useState(false);
  const [statutCommandeOpen, setStatutCommandeOpen] = useState(false);
  const [statutLivraisonOpen, setStatutLivraisonOpen] = useState(false);

  const [newOrder, setNewOrder] = useState(false);
  const demandeur = {};
  const statut = {};

  const cart = useSelector((state) => state.cart)
  const { cartItems, success: successNewOrder } = cart

  const orderList = useSelector(state => state.orderList)
  const { error, loading, orders, page, pages } = orderList

  const productList = useSelector(state => state.productList)
  const { products } = productList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productNonImeiList = useSelector(state => state.productNonImeiList)
  const { productsNonImei } = productNonImeiList

  const documentList = useSelector(state => state.documentList)
  const { documents } = documentList


  const [orderData, setOrderData] = useState([]);
  const [order, setOrder] = useState();
  const [filteredOrderData, setFilteredOrderData] = useState([]);

  const [amount_totalRangeObj, setamount_totalRangeObj] = useState([]); // à voir pour mettre les prix min et max des produits
  const [demandeurObj, setDemandeurObj] = useState(demandeur);
  const [statutCommandeObj, setStatutCommandeObj] = useState(statut);
  const [statutLivraisonObj, setStatutLivraisonObj] = useState(statut);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalOrder, setModalOrder] = useState({});
  const [checkedItems, setCheckedItems] = useState([]);

  const [amount_totalRange, setamount_totalRange] = useState([]);

  const [achatDateRange, setAchatDateRange] = useState([]);
  const [livraisonDateRange, setLivraisonDateRange] = useState([]);

  const [achatDateRangeObj, setAchatDateRangeObj] = useState([]);
  const [livraisonDateRangeObj, setLivraisonDateRangeObj] = useState([]);

  const header = i18n.language == 'fr' ?
    [
      "ID", "Demandeur", "Ref. client", "Date d'achat", "Date de livraison", "Prix", "Statut commande", "Statut livraison", "",
      ""
    ]
    :
    [
      "ID", "Requester", "Customer ref.", "Purchase date", "Delivery date", "Price", "Order status", "Delivery status", "",
      ""
    ]

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '30%',
    },
  };

  useEffect(() => {
    dispatch(listOrders());
    dispatch(getDocuments(userInfo ? userInfo.id_odoo : -1));
    if (!productsNonImei || productsNonImei.length === 0)
      dispatch(listNonImeiProducts(userInfo ? userInfo.id_odoo : -1));
    if (!products || products.length === 0)
      dispatch(listProducts());
  }, [])

  useEffect(() => {
    if (orders && orders.length > 0) {
      setFilteredOrderData(orders);
      setOrderData(orders);
      FillFilters();

      let arr = [
        Math.min(...orders.map(x => x.amount_total)),
        Math.max(...orders.map(x => x.amount_total))
      ]
      
      setamount_totalRange([...arr])

      setAchatDateRange([new Date(Math.min.apply(null, orders.map(x => new Date(x.date_order)))),
      new Date(Math.max.apply(null, orders.map(x => new Date(x.date_order))))])

      setLivraisonDateRange([new Date(Math.min.apply(null, orders.map(x => new Date(x.date_order)))),
      new Date(Math.max.apply(null, orders.map(x => new Date(x.date_order))))])
    }

  }, [orders])

  const [filterItemObj, setFilterItemObj] = useState({});

  useEffect(() => {
    const filteredItems = {
      amount_total_range: [],
      date_order: [],
      delivery_date: [],
    };

    const filteramount_totalRangeArr = Array.from(new Set(orderData.filter((item) => {
      return item.amount_total >= amount_totalRangeObj[0] && item.amount_total <= amount_totalRangeObj[1];
    }).map((item) => item.amount_total)));

    const filterDateAchatArr = Array.from(new Set(orderData.filter((item) => {
      const itemDate = new Date(item.date_order);
    
      // Créer une nouvelle date de fin incluant toute la journée
      const endDate = new Date(achatDateRangeObj[1]);
      endDate.setHours(23, 59, 59, 999);
    
      return itemDate >= achatDateRangeObj[0] && itemDate <= endDate;
    }).map((item) => item.date_order)));
    

    const filterDateLivraisonArr = Array.from(new Set(orderData.filter((item) => {
      const itemDate = new Date(item.delivery_date);
    
      // Créer une nouvelle date de fin incluant toute la journée
      const endDate = new Date(achatDateRangeObj[1]);
      endDate.setHours(23, 59, 59, 999);

      return itemDate >= livraisonDateRangeObj[0] && itemDate <= endDate;
    }).map((item) => item.delivery_date)));

    filteredItems["amount_total"] = [...filteramount_totalRangeArr];
    filteredItems["date_order"] = [...filterDateAchatArr];
    filteredItems["delivery_date"] = [...filterDateLivraisonArr];


    setFilterItemObj(filteredItems);

  }, [amount_totalRangeObj, achatDateRangeObj, livraisonDateRangeObj]);

  useEffect(() => {
    const filterKeys = Object.keys(filterItemObj);
    const filteredTicketsDataArr = orderData.filter((p) => {
      return filterKeys.every((key) => {

        if (!filterItemObj[key].length) {
          if (key === "amount_total_range" && amount_totalRangeObj[1] < Math.min(...orderData.map(x => x.amount_total)))
            return false;
          return true;
        }

        const dataOut = filterItemObj[key].includes(findVal(p, key));
        return dataOut;
      });
    });

    setFilteredOrderData(filteredTicketsDataArr);
  }, [filterItemObj]);

  useEffect(() => {
    setamount_totalRangeObj(amount_totalRange);
  }, [amount_totalRange])

  useEffect(() => {
    if (modalOrder && modalOrder.id && !modalIsOpen) {
      setIsOpen(true);
    }

  }, [modalOrder])

  let subtitle;

  function openModal(order) {
    setModalOrder(order);
  }

  function afterOpenModal() {
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
    setModalOrder({});
  }

  function FillFilters() {
    if (orders && orders.length > 0) {
      const demandeurList = Array.from(new Set(orders.map((item) => item.create_uid[1])));
      const statutCommande = Array.from(new Set(orders.filter((item) => item.invoice_status != false).map((item) => item.invoice_status)));
      const statutLivraison = Array.from(new Set(orders.map((item) => item.delivery_status)));
      demandeurList.map((b, i) => {
        let newObj = {};
        newObj[b] = 'false';
        Object.assign(demandeur, newObj)
      });
      setDemandeurObj(demandeur);
    }
  }

  function getOrderamount_totals(data) {
    const orderamount_totals = [];
    data.map((order) => {
      order.articles.map((article) => {
        orderamount_totals.push(data.reduce((a, v) => a = a + v.tarif, 0));
      })
    });
    return orderamount_totals;
  }

  const onChangeHandler = (event, label) => {
    const { value, checked } = event.target;
    switch (label) {
      case "DEMANDEUR":
        for (let demandeurKey in demandeurObj) {
          if (checked) {
            if (demandeurKey === value) {
              setDemandeurObj({
                ...demandeurObj,
                [demandeurKey]: true
              });
            }
          } else {
            if (demandeurKey === value) {
              setDemandeurObj({
                ...demandeurObj,
                [demandeurKey]: false
              });
            }
          }
        }
        break;
      default:

    }
  };

  const callamount_totalRange = (value) => {
    const min = value[0];
    const max = value[1];
    setamount_totalRangeObj([min, max]);
  };

  const callDemandeur = () => {
    const label = "DEMANDEUR";
    return toArrayKeys(demandeurObj).map((data, index) =>
      callCheckboxData(label, data, index)
    );
  };

  const callCheckboxData = (label, data, index) => {
    return (
      <Row key={index}>
        <Col className="col-sm-2">
          <input
            type="checkbox"
            id={data}
            name={data}
            value={data}
            onChange={(event) => onChangeHandler(event, label)}
          />
        </Col>
        <Col className="col-sm-10">
          <label className="filter-checkbox-label" htmlFor={data}>{data}</label>
        </Col>
      </Row>
    );
  };

  const getOrderStatut = (statut, invoiceStatus, invoice) => {
    let PaymentStatus = invoice.payment_state
    switch (statut) {
      case ORDER_DRAFT:
        return t('Quote');
      case ORDER_SENT:
        return t('Sent');
      case ORDER_SALE:
        if (invoice.state == 'posted') {
          switch (invoiceStatus) {
            case INVOICE_INVOICED:
              switch (PaymentStatus) {
                case PAYMENT_PAID:
                  return t('Paid');
                case PAYMENT_IN_PAYMENT:
                  return t('Paid');
                case PAYMENT_REVERSE:
                  return t('Refunded');
                default:
                  return t('Billed');
              }
            default:
              return t('Validated');
          }
        }
        else
          return t('Validated');
      case ORDER_DONE:
        return t('Complete');
      case ORDER_CANCEL:
        return t('Canceled');
      default:
        return t('On hold');
    }
  }

  const getDeliveryStatut = (statut) => {
    switch (statut) {
      case DELIVERY_WAITING:
        return t('On hold');
      case DELIVERY_CONFIRMED:
        return t('Confirmed');
      case DELIVERY_ASSIGNED:
        return t('In progress');
      case DELIVERY_DONE:
        return t('Delivered');
      case DELIVERY_CANCEL:
        return t('Canceled');
      default:
        return t('On hold');
    }
  }

  const getOrderStatutClassName = (statut, invoiceStatus, PaymentStatus) => {
    let info = "order-btn-info"
    let inprogress = "order-btn-inprogress"
    let over = "order-btn-over"
    let issue = "order-btn-issue"

    switch (statut) {
      // case ORDER_DRAFT || ORDER_SENT:
      //   return info;
      case ORDER_SALE:
        switch (invoiceStatus) {
          case INVOICE_INVOICED:
            switch (PaymentStatus) {
              case PAYMENT_PAID:
                return over;
              case PAYMENT_IN_PAYMENT:
                return over;
              case PAYMENT_REVERSE:
                return over;
              default:
                return over;
            }
          default:
            return inprogress;
        }
      case ORDER_DONE:
        return over;
      case ORDER_CANCEL:
        return issue;
      default:
        return issue;
    }
  }

  const getDeliveryStatutClassName = (statut) => {
    let info = "order-btn-info"
    let inprogress = "order-btn-inprogress"
    let over = "order-btn-over"
    let issue = "order-btn-issue"

    switch (statut) {
      case DELIVERY_WAITING:
        return info;
      case DELIVERY_CONFIRMED || DELIVERY_ASSIGNED:
        return inprogress;
      case DELIVERY_DONE:
        return over;
      case DELIVERY_CANCEL:
        return issue;
      default:
        return issue;
    }
  }

  const placeOrderHandler = (order) => {
    let productsToCart = getProductsFromOrder(order)
    if (productsToCart && productsToCart.length > 0)
      dispatch(addNewOrderToCart(productsToCart))
    navigate('/cart');
  }

  function getProductsFromOrder(order) {
    let items = []
    order && order.order_line && order.order_line.length > 0 && order.order_line.map((line) => {
      let item = products.filter((product) => product.id_odoo === line.product_id[0]).length > 0 ?
        products.filter((product) => product.id_odoo === line.product_id[0])[0] :
        productsNonImei.filter((product) => product.id_odoo === line.product_id[0]).length > 0 ?
          productsNonImei.filter((product) => product.id_odoo === line.product_id[0])[0] : {}

      if (item.id) {
        let newItem = {}
        newItem.product = item
        newItem.qty = order.order_line[0].product_uom_qty
        newItem.imei = item.tac ? true : false
        items.push(newItem)
      }
    })

    return items
  }

  //order.state, order.invoice_status, order.invoice ? order.invoice[0].payment_state : ''
  //order.delivery ? order.delivery[0].state : ''

  const TabAll = getTabContent(filteredOrderData)

  const TabDraft = getTabContent(filteredOrderData.filter((order) => order.state === ORDER_DRAFT))

  const TabInProgress = getTabContent(filteredOrderData.filter((order) => order.state != ORDER_DONE &&
    (!order.invoice || (order.invoice[0].payment_state !== PAYMENT_IN_PAYMENT && order.invoice[0].payment_state !== PAYMENT_PAID))
    && order.delivery && order.delivery.length > 0 ?
    order.delivery[0].state !== DELIVERY_DONE : false))

  const TabLate = getTabContent(filteredOrderData.filter((order) => order.delivery && order.delivery.length > 0 ?
    order.delivery[0].state === DELIVERY_WAITING : false))

  const TabPaid = getTabContent(filteredOrderData.filter((order) => order.invoice &&
    (order.invoice[0].payment_state === PAYMENT_IN_PAYMENT || order.invoice[0].payment_state === PAYMENT_PAID)
    && order.delivery && order.delivery.length > 0 && order.delivery[0].state !== DELIVERY_DONE))

  const TabOver = getTabContent(filteredOrderData.filter((order) => order.delivery && order.delivery.length > 0 ?
    order.delivery[0].state === DELIVERY_DONE : false))

  function DownloadModal(order) {
    setOrder(order);
    setIsOpen(true);
  }

  function downloadFiles() {
    checkedItems.forEach((url) => {
      
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          // Créer un lien et l'attacher au document
          const downloadUrl = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = downloadUrl;
          a.download = url.split('/').pop(); // Obtenez le nom du fichier à partir de l'URL
          document.body.appendChild(a);
          a.click();

          // Nettoyer et supprimer l'URL créée
          window.URL.revokeObjectURL(downloadUrl);
          a.remove();
        })
        .catch(e => console.error(`Erreur lors du téléchargement du fichier: ${e}`));
    });

    setIsOpen(false);
    setModalOrder({});
    setCheckedItems([]);
  }

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setCheckedItems((prevItems) => {
      const items = [...prevItems]; // Copier le tableau précédent
      const index = items.indexOf(value); // Trouver l'index de l'élément à mettre à jour
      if (checked && index === -1) {
        items.push(value); // Ajouter l'élément s'il n'existe pas déjà
      } else if (!checked && index !== -1) {
        items.splice(index, 1); // Supprimer l'élément s'il existe et n'est plus coché
      }
      return items; // Retourner le nouveau tableau mis à jour
    });
  };

  const handleDateRangeEvent = (data) => {
    const start = data.value.start
    const end = data.value.end

    switch (data.type) {
      case 'achat':
        setAchatDateRangeObj([start.toDate(), end.toDate()])
        break;
      case 'livraison':
        setLivraisonDateRangeObj([start.toDate(), end.toDate()])
        break;
      default:
        break;
    };
  }

  function getTabContent(data) {
    return (
      <div className='orders-tab-content'>
        <Table responsive>
          <thead className='orders-header'>
            <tr>
              {header.map((item, index) => (
                <th key={index}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody className='orders-body'>
            {data.length > 0 ? (
              data.map((order, index) => (
                <tr className='orders-row-detail' key={index}>
                  <td style={{ width: '5%' }}>{order.id}</td>
                  <td style={{ width: '10%' }}>{order.create_uid[1]}</td>
                  <td style={{ width: '15%' }}>{order.name}</td>
                  <td style={{ width: '10%' }}>{new Date(order.date_order).toLocaleDateString("fr-FR")}</td>
                  <td style={{ width: '10%' }}>{new Date(order.date_order).toLocaleDateString("fr-FR")}</td>
                  <td style={{ width: '15%' }}>{order.amount_total} €</td>
                  <td style={{ width: '10%' }} className='order-td-statut'>
                    <button
                      className={getOrderStatutClassName(order.state, order.invoice_status, order.invoice ? order.invoice[0].payment_state : '')}
                      disabled={order.invoice_status != PAYMENT_IN_PAYMENT}>{getOrderStatut(
                        order.state, order.invoice_status, order.invoice ? order.invoice[0] : '')}</button>
                  </td>
                  <td style={{ width: '10%' }} className='order-td-statut'>
                    <button className={getDeliveryStatutClassName(order.delivery ? order.delivery[0].state : '')}>{getDeliveryStatut(
                      order.delivery ? order.delivery[0].state : '')}</button>
                  </td>
                  <td style={{ width: '10%' }}>
                    <button className='order-order-btn' value={order} type='submit' onClick={() => placeOrderHandler(order)}>
                      <FontAwesomeIcon icon={faCartShopping} />
                    </button>
                  </td>
                  <td style={{ width: '8%' }}>
                    <i
                      className="order-download fa-solid fa-download fa-lg"
                      disable={!documents || documents.length === 0}
                      data-tip="React-tooltip"
                      data-for={'tooltip-download-order'}
                      onClick={() => {
                        openModal(order)
                      }}></i>
                    <ReactTooltip id={'tooltip-download-order'}
                      type="dark"
                      place="bottom"
                      effect="solid"
                      clickable={true}
                      getContent={() => (
                        <div>
                          <Row>{t('Download documents')}</Row>
                        </div>
                      )}
                    />
                  </td>
                </tr>
              ))
            ) : (
              null
            )}
          </tbody>
        </Table>
      </div>
    )
  }

  function togglePriceOpen() {
    setPriceOpen(!priceOpen);
  }

  function toggleDateAchatOpen() {
    setDateAchatOpen(!dateAchatOpen);
  }

  function toggleDateLivraisonOpen() {
    setDateLivraisonOpen(!dateLivraisonOpen);
  }

  function toggleDemandeurOpen() {
    setDemandeurOpen(!demandeurOpen);
  }


  return (
    <div className='orders-page'>
      {loading ? <Loader /> :
        <div className='orders-container'>
          <Row className='orders-row'>
            <Col md={3} className='orders-filters-col'>
              <ListGroup className="product-filters">
                <ListGroup.Item>
                  <Row>
                    <Col md={8}>
                      <h5>{t('Price')}</h5>
                    </Col>
                    <Col md={4} onClick={togglePriceOpen}>
                      <i className={`fa-solid fa-chevron-right ${priceOpen ? 'rotate-90' : ''}`}></i>
                    </Col>
                  </Row>
                  <Row className={`${!priceOpen ? 'filter-collapsed' : 'filter-expanded'}`}>
                    {
                      <div className="range-slider-block">
                        <Row>
                          <Col className="col-sm-6">
                            <div className="amount_totalRangeMin">{amount_totalRangeObj[0] ? amount_totalRangeObj[0] : amount_totalRange[0]} €</div>
                          </Col>
                          <Col className="col-sm-6">
                            <div className="amount_totalRangeMax">{amount_totalRangeObj[1] ? amount_totalRangeObj[1] : amount_totalRange[1]} €</div>
                          </Col>
                        </Row>
                        <Row>
                          <RangeSlider
                            className="range-slider"
                            min={amount_totalRange[0]}
                            max={amount_totalRange[1]}
                            step={1}
                            value={[amount_totalRangeObj[0], amount_totalRangeObj[1]]}
                            onInput={value => callamount_totalRange(value)}
                          />
                        </Row>
                      </div>
                    }
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item className='filter-brand'>
                  <Row>
                    <Col md={8}>
                      <h5>{t('Purchase date')}</h5>
                    </Col>
                    <Col md={4} onClick={toggleDateAchatOpen}>
                      <i className={`fa-solid fa-chevron-right ${dateAchatOpen ? 'rotate-90' : ''}`}></i>
                    </Col>
                  </Row>
                  <Row className={`${!dateAchatOpen ? 'filter-collapsed' : 'filter-expanded'}`}>
                    <DateRange onEvent={handleDateRangeEvent} type='achat' range={achatDateRange} />
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item className='filter-brand'>
                  <Row>
                    <Col md={8}>
                      <h5>{t('Delivery date')}</h5>
                    </Col>
                    <Col md={4} onClick={toggleDateLivraisonOpen}>
                      <i className={`fa-solid fa-chevron-right ${dateLivraisonOpen ? 'rotate-90' : ''}`}></i>
                    </Col>
                  </Row>
                  <Row className={`${!dateLivraisonOpen ? 'filter-collapsed' : 'filter-expanded'}`}>
                    <DateRange onEvent={handleDateRangeEvent} type='livraison' range={livraisonDateRange} />
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={9} className='order-tab-col'>
              <Tabs
                defaultActiveKey="all"
                id="uncontrolled-tab-example"
                className="mb-3 order-tab"
              >
                <Tab eventKey="all" title={t('My orders')} selectedTabClassName="is-selected" >
                  {TabAll}
                </Tab>
                <Tab eventKey="draft" title={t('My quotes')} >
                  {TabDraft}
                </Tab>
                <Tab eventKey="inprogress" title={t('In progress')}>
                  {TabInProgress}
                </Tab>
                <Tab eventKey="paid" title={t('Paid')}>
                  {TabPaid}
                </Tab>
                <Tab eventKey="over" title={t('Completed')}>
                  {TabOver}
                </Tab>
                <Tab eventKey="late" title={t('Delayed')}>
                  {TabLate}
                </Tab>
              </Tabs>
            </Col>
          </Row>


          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
          >
            <Row className='order-modal-title'>
              <span>Sélectionner les fichiers à télécharger</span>
            </Row>
            {
              modalOrder && modalOrder.pdfs_url && modalOrder.pdfs_url.length > 0 ?
                modalOrder.pdfs_url.map((url, index) => (
                  <Row className='order-modal-checkbox' key={index}>
                    <Col md={{ span: 2, offset: 2 }}>
                      <input
                        type='checkbox'
                        id={index}
                        name={url.name}
                        value={url.url}
                        onChange={handleCheckboxChange}
                      />
                    </Col>
                    <Col>

                      <label htmlFor={index}>{t(url.name)}</label>
                    </Col>
                  </Row>
                ))
                : null
            }
            <Row className='order-modal-button'>
              <Col>
                <button
                  className='order-modal-btn-cancel'
                  type='button'
                  onClick={closeModal}
                >
                  Annuler
                </button>
              </Col>
              <Col>
                <button
                  className='order-modal-btn-validate'
                  type='button'
                  onClick={downloadFiles}
                >
                  Télécharger
                </button>
              </Col>
            </Row>
          </Modal>
        </div>
      }
    </div>
  )
}

export default Order