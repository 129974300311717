export const HEADER_BRAND_ORDER_REQUEST = 'HEADER_BRAND_ORDER_REQUEST'
export const HEADER_BRAND_ORDER_SUCCESS = 'HEADER_BRAND_ORDER_SUCCESS'
export const HEADER_BRAND_ORDER_FAIL = 'HEADER_BRAND_ORDER_FAIL'

export const HEADER_BRAND_ORDER_ADD_REQUEST = 'HEADER_BRAND_ORDER_ADD_REQUEST'
export const HEADER_BRAND_ORDER_ADD_SUCCESS = 'HEADER_BRAND_ORDER_ADD_SUCCESS'
export const HEADER_BRAND_ORDER_ADD_FAIL = 'HEADER_BRAND_ORDER_ADD_FAIL'

export const HEADER_BRAND_ORDER_DEL_REQUEST = 'HEADER_BRAND_ORDER_DEL_REQUEST'
export const HEADER_BRAND_ORDER_DEL_SUCCESS = 'HEADER_BRAND_ORDER_DEL_SUCCESS'
export const HEADER_BRAND_ORDER_DEL_FAIL = 'HEADER_BRAND_ORDER_DEL_FAIL'
