import {
    SIM_GET_REQUEST,
    SIM_GET_SUCCESS,
    SIM_GET_FAILURE,

    SIM_ADD_REQUEST,
    SIM_ADD_SUCCESS,
    SIM_ADD_FAILURE,

    SIM_ADD_BY_FILE_REQUEST,
    SIM_ADD_BY_FILE_SUCCESS,
    SIM_ADD_BY_FILE_FAILURE,

    PUK_ADD_REQUEST,
    PUK_ADD_SUCCESS,
    PUK_ADD_FAILURE,

    SIM_UPDATE_REQUEST,
    SIM_UPDATE_SUCCESS,
    SIM_UPDATE_FAILURE,

    SIM_DELETE_REQUEST,
    SIM_DELETE_SUCCESS,
    SIM_DELETE_FAILURE,

    RESET_SIM_ADD_SUCCESS,
    RESET_SIM_ADD_BY_FILE_SUCCESS,
    RESET_SIM_UPDATE_SUCCESS,
    RESET_SIM_DELETE_SUCCESS,
    RESET_PUK_ADD_BY_FILE_SUCCESS
}
    from '../constants/simConstants'

import axios from 'axios'

export const listSims = () => async (dispatch, getState) => {
    try {
        dispatch({ type: SIM_GET_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            `/api/sim/?ste_id=${userInfo._id}`
            )

        dispatch({
            type: SIM_GET_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: SIM_GET_FAILURE,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const addSim = (number, phone_number) => async (dispatch, getState) => {
    try {
        dispatch({ type: SIM_ADD_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/sim/add/`,
            { 'number': number, 'phone_number': phone_number, 'ste_id': userInfo._id },
            )


        dispatch({
            type: SIM_ADD_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: SIM_ADD_FAILURE,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const addSimByFile = (sims) => async (dispatch, getState) => {
    try {
        dispatch({ type: SIM_ADD_BY_FILE_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/sim/addByFile/`,
            { 'sims': sims, 'ste_id': userInfo._id },
            )


        dispatch({
            type: SIM_ADD_BY_FILE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: SIM_ADD_BY_FILE_FAILURE,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const updateSim = (sim) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        dispatch({
            type: SIM_UPDATE_REQUEST
        })

        const { data } = await instance.post(
            `/api/sim/update/`,
            { 'id': sim.id, 'number': sim.number, 'phone_number': sim.phone_number, 'puk': sim.puk },
            )

        dispatch({ type: SIM_UPDATE_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: SIM_UPDATE_FAILURE,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        })
    }
}

export const addPukByFile = (puks) => async (dispatch, getState) => {
    try {
        dispatch({ type: PUK_ADD_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/sim/addPukByFile/`,
            { 'puks': puks },
            )


        dispatch({
            type: PUK_ADD_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: PUK_ADD_FAILURE,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const deleteSim = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: SIM_DELETE_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        await instance.delete(
            `/api/sim/del/?id=${id}`
            )

        dispatch({
            type: SIM_DELETE_SUCCESS
        })
    } catch (error) {
        dispatch({
            type: SIM_DELETE_FAILURE,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const resetAddSim = () => (dispatch) => {
    dispatch({ type: RESET_SIM_ADD_SUCCESS, payload: {} })
}

export const resetUpdateSim = () => (dispatch) => {
    dispatch({ type: RESET_SIM_UPDATE_SUCCESS, payload: {} })
}

export const resetDeleteSim = () => (dispatch) => {
    dispatch({ type: RESET_SIM_DELETE_SUCCESS, payload: {} })
}

export const resetPukSucess = () => (dispatch) => {
    dispatch({ type: RESET_PUK_ADD_BY_FILE_SUCCESS, payload: {} })
}