export const CLIENT_LIST_REQUEST = 'CLIENT_LIST_REQUEST'
export const CLIENT_LIST_SUCCESS = 'CLIENT_LIST_SUCCESS'
export const CLIENT_LIST_FAIL = 'CLIENT_LIST_FAIL'

export const CLIENT_IMPERSONATED_REQUEST = 'CLIENT_IMPERSONATED_REQUEST'
export const CLIENT_IMPERSONATED_SUCCESS = 'CLIENT_IMPERSONATED_SUCCESS'
export const CLIENT_IMPERSONATED_FAIL = 'CLIENT_IMPERSONATED_FAIL'
export const CLIENT_IMPERSONATED_LOGOUT = 'CLIENT_IMPERSONATED_LOGOUT'

export const CLIENT_IMPERSONATED_REVERT_REQUEST = 'CLIENT_IMPERSONATED_REVERT_REQUEST'
export const CLIENT_IMPERSONATED_REVERT_SUCCESS = 'CLIENT_IMPERSONATED_REVERT_SUCCESS'
export const CLIENT_IMPERSONATED_REVERT_FAIL = 'CLIENT_IMPERSONATED_REVERT_FAIL'
export const RESET_CLIENT_IMPERSONATED_REVERT = 'RESET_CLIENT_IMPERSONATED_REVERT'

export const RESET_CLIENT_IMPERSONATED_SUCCESS = 'RESET_CLIENT_IMPERSONATED_SUCCESS'
