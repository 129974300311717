import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, ListGroup, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import SAV from '../../../../components/SAV/SAV'
import { toArrayKeys, findVal, removeDuplicatesByNestedProperty } from "../../../../utils/utils";

import DateRange from '../../../../components/DateRange/DateRange';
import Loader from '../../../../components/Loader/Loader'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';

import { listTickets } from '../../../../actions/ticketActions';
import { getAllWarranty } from '../../../../actions/warrantyActions';

import './AfterSaleDevices.css'
import { useTranslation } from 'react-i18next';

function AfterSaleDevices() {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation();

  const searchImeiKeys = ['imei'];

  const [dateOuvertureTicketOpen, setDateAchatOpen] = useState(false);
  const [statutOpen, setBrandOpen] = useState(false);

  const [filteredTickets, setFilteredTickets] = useState([])

  const ticketList = useSelector(state => state.ticketList)
  const { error, loading, tickets, page, pages } = ticketList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const demandeur = {};
  const statut = {};

  const [demandeurObj, setDemandeurObj] = useState(demandeur);
  const [statutObj, setStatutObj] = useState(statut);

  const [priceRange, setPriceRange] = useState([]);

  const [ouvertureTicketDateRange, setOuvertureTicketDateRange] = useState([]);
  const [fermetureTicketDateRange, setFermetureTicketDateRange] = useState([]);

  const [ouvertureTicketDateRangeObj, setOuvertureTicketDateRangeObj] = useState([]);
  const [fermetureTicketDateRangeObj, setFermetureTicketDateRangeObj] = useState([]);

  const [tabAll, setTabAll] = useState([]);
  const [tabInValidation, setTabInValidation] = useState([]);
  const [tabValidated, setTabValidated] = useState([]);
  const [tabReady, setTabReady] = useState([]);
  const [tabInProgress, setTabInProgress] = useState([]);
  const [tabOver, setTabOver] = useState([]);

  const [imeiInputLength, setImeiInputLength] = useState(0);

  useEffect(() => {
    dispatch(listTickets(userInfo.id_odoo));
    //dispatch(getAllWarranty())
  }, [])

  useEffect(() => {
    if (tickets && tickets != undefined && tickets.length > 0) {
      FillFilters(tickets);
    }
  }, [ticketList])

  useEffect(() => {
    if (tickets && tickets != undefined && tickets.length > 0) {
      setFilteredTickets(tickets);
    }
  }, [tickets])

  useEffect(() => {
    if (tickets && tickets != undefined && tickets.length > 0 && filteredTickets && filteredTickets != undefined && filteredTickets.length > 0) {
      const all = filteredTickets && filteredTickets.length > 0 ? filteredTickets : tickets

      setTabAll(getTabContent(all))

      setTabInValidation(getTabContent(all.filter((ticket) => ticket.state === 'draft')))

      setTabValidated(getTabContent(all.filter((ticket) => ticket.state === 'confirmed')))

      setTabReady(getTabContent(all.filter((ticket) => ticket.state === 'ready')))

      setTabInProgress(getTabContent(all.filter((ticket) => ticket.state === 'under_repair')))

      setTabOver(getTabContent(all.filter((ticket) => ticket.state === 'done')))
    }
  }, [filteredTickets])

  const handleDateRangeEvent = (data) => {
    const start = data.value.start
    const end = data.value.end

    switch (data.type) {
      case 'ouverture':
        setOuvertureTicketDateRangeObj([start.toDate(), end.toDate()])
        break;
      case 'fermeture':
        setFermetureTicketDateRangeObj([start.toDate(), end.toDate()])
        break;
      default:
        break;
    };
  }

  function FillFilters(data) {
    const demandeurList = Array.from(new Set(data.map((item) => item.partner_id[1])));
    const statutList = Array.from(new Set(data.map((item) => item.state)));

    demandeurList.map((b, i) => {
      const newObj = {};
      newObj[b] = 'false';
      Object.assign(demandeur, newObj)
    });
    setDemandeurObj(demandeur);

    statutList.map((b, i) => {
      const newObj = {};
      newObj[b] = 'false';
      Object.assign(statut, newObj)
    });
    setStatutObj(statut);
  }

  const onChangeHandler = (event, label) => {
    const { value, checked } = event.target;
    switch (label) {
      case "DEMANDEUR":
        for (let demandeurKey in demandeurObj) {
          if (checked) {
            if (demandeurKey === value) {
              setDemandeurObj({
                ...demandeurObj,
                [demandeurKey]: true
              });
            }
          } else {
            if (demandeurKey === value) {
              setDemandeurObj({
                ...demandeurObj,
                [demandeurKey]: false
              });
            }
          }
        }
        break;
      case "STATUT":
        for (let statutKey in statutObj) {
          if (checked) {
            if (statutKey === value) {
              setStatutObj({
                ...statutObj,
                [statutKey]: true
              });
            }
          } else {
            if (statutKey === value) {
              setStatutObj({
                ...statutObj,
                [statutKey]: false
              });
            }
          }
        }
        break;

      default:

    }
  };

  const callDemandeur = () => {
    const label = "DEMANDEUR";
    return toArrayKeys(demandeurObj).map((data, index) =>
      callCheckboxData(label, data, index)
    );
  };

  const callStatut = () => {
    const label = "STATUT";
    return toArrayKeys(statutObj).map((data, index) =>
      callCheckboxData(label, data, index)
    );
  };

  const callCheckboxData = (label, data, index) => {
    return (
      <Row key={index}>
        <Col sm={2}>
          <input
            type="checkbox"
            id={data}
            name={data}
            value={data}
            onChange={(event) => onChangeHandler(event, label)}
          />
        </Col>
        <Col sm={10}>
          <label className="filter-checkbox-label" htmlFor={data}>{getLabelFromData(data)}</label>
        </Col>
      </Row>
    );
  };

  function getLabelFromData(statut) {
    switch (statut) {
      case 'draft':
        return t('In process of validation')
      case 'confirmed':
        return t('Validated')
      case 'ready':  
        return t('Ready')
      case 'under_repair':
        return t('Being processed')
      case 'done':
        return t('Complete')
      default:
        return t('In process of validation')
    }
  }

  const [filterItemObj, setFilterItemObj] = useState({});

  useEffect(() => {
    const filteredItems = {
      // demandeur: [],
      state: [],
      create_date: [],
      // end: [],
    };

    // const filterDemandeurArr = toArrayKeys(demandeurObj).filter(
    //   (data) => demandeurObj[data] === true
    // );

    const filterStatutArr = toArrayKeys(statutObj).filter(
      (data) => statutObj[data] === true
    );

    const filterDateOuvertureArr = Array.from(new Set((tickets && tickets.length > 0 ? tickets : []).filter((item) => {
      return new Date(item.create_date) >= ouvertureTicketDateRangeObj[0] && new Date(item.create_date) <= ouvertureTicketDateRangeObj[1];
    }).map((item) => item.create_date)));

    // const filterDateFermetureArr = Array.from(new Set(tickets.filter((item) => {
    //   return new Date(item.end) >= fermetureTicketDateRangeObj[0] && new Date(item.end) <= fermetureTicketDateRangeObj[1];
    // }).map((item) => item.end)));

    // filteredItems["demandeur"] = [...filterDemandeurArr];
    filteredItems["state"] = [...filterStatutArr];
    filteredItems["create_date"] = [...filterDateOuvertureArr];
    // filteredItems["end"] = [...filterDateFermetureArr];

    setFilterItemObj(filteredItems);


  }, [demandeurObj, statutObj, ouvertureTicketDateRangeObj, fermetureTicketDateRangeObj]);

  useEffect(() => {
    const filterKeys = Object.keys(filterItemObj);
    const filteredTicketsDataArr = tickets && tickets != undefined && tickets.length > 0 ? tickets.filter((p) => {
      return filterKeys.every((key) => {

        if (!filterItemObj[key].length) return true;
        const dataOut = filterItemObj[key].includes(findVal(p, key));
        return dataOut;
      });
    }) : [];

    setFilteredTickets(filteredTicketsDataArr);
  }, [filterItemObj]);

  function toggleDateOuvertureTicketOpen() {
    setDateAchatOpen(!dateOuvertureTicketOpen);
  }

  function toggleStatutOpen() {
    setBrandOpen(!statutOpen);
  }

  function getTabContent(data) {
    return (
      data.length > 0 ?
        data.map((item) => (
          <Row className='aftersaledevices-sav-row'>
            <SAV ticket={item} />
          </Row>
        ))
        : <p>{t('No tickets found')}</p>
    )
  }

  const handleOnSearchImei = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string.length === 0) {
      setFilteredTickets(tickets);
      //setDetailDataHistory(getSavHistory(fleetData[0]));
    }
    setImeiInputLength(10 - string.length)
  };

  const handleOnSelectImei = (item) => {
    // the item selected
    setFilteredTickets(filteredTickets.filter((ticket) => ticket.imei === item.imei));
  };

  const handleOnFocusImei = () => {

  };

  const formatResultImei = (item) => {
    return (
      <>
        {item.imei}
      </>
    )
  };

  return (
    <>
      {loading ? <Loader /> :
        <>
          <Row>
            <Col className='aftersaledevices-savfilters-col' md={3}>
              <ReactSearchAutocomplete
                className='react-search-autocomplete'
                items={removeDuplicatesByNestedProperty(filteredTickets, 'imei')}
                fuseOptions={{ keys: searchImeiKeys }}
                onSearch={handleOnSearchImei}
                onSelect={handleOnSelectImei}
                onFocus={handleOnFocusImei}
                autoFocus
                placeholder={t('Enter an IMEI number')}
                formatResult={formatResultImei}
                includeMatches={true}
                distance={0.0}
                threshold={0.0} // 0 = exact match, 1 = everything matches
                maxResults={imeiInputLength}
                ignoreLocation={true}
                styling={{
                  border: "1px solid #dfe1e5",
                  borderRadius: "5px",
                  backgroundColor: "white",
                  boxShadow: "rgba(58, 53, 53, 0.2) 0px 1px 6px 0px",
                  hoverBackgroundColor: "#eee",
                  color: "#212121",
                  fontSize: "12px",
                  fontFamily: "Arial",
                  iconColor: "grey",
                  lineColor: "rgb(232, 234, 237)",
                  placeholderColor: "grey",
                  clearIconMargin: '0 5px 0 0',
                  searchIconMargin: '0 0 0 5px',
                  zIndex: 3,
                  position: "relative", /* assurez-vous que la position est relative ou absolue pour que la propriété left fonctionne */
                  left: "-200px",
                }}
              />
              <ListGroup className="product-filters">
                {/* <ListGroup.Item className="aftersaledevices-filter-demandeur">
                  <h5>Demandeur</h5>
                  {callDemandeur()}
                </ListGroup.Item> */}

                <ListGroup.Item className='filter-brand' onClick={toggleStatutOpen}>
                  <Row>
                    <Col md={8}>
                      <h5>{t('Status')}</h5>
                    </Col>
                    <Col md={4}>
                      {!statutOpen && <i class="fa-solid fa-chevron-down"></i>}
                      {statutOpen && <i class="fa-solid fa-chevron-up"></i>}
                    </Col>
                  </Row>
                  <Row className={`${!statutOpen ? 'filter-collapsed' : 'filter-expanded'}`}>
                    {callStatut()}
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item className='filter-brand'>
                  <Row>
                    <Col md={8}>
                      <h5>{t('Ticket open date')}</h5>
                    </Col>
                    <Col md={4} onClick={toggleDateOuvertureTicketOpen}>
                      {!dateOuvertureTicketOpen && <i class="fa-solid fa-chevron-down"></i>}
                      {dateOuvertureTicketOpen && <i class="fa-solid fa-chevron-up"></i>}
                    </Col>
                  </Row>
                  <Row className={`${!dateOuvertureTicketOpen ? 'filter-collapsed' : 'filter-expanded'}`}>
                    <DateRange onEvent={handleDateRangeEvent} type='ouverture' range={ouvertureTicketDateRange} />
                  </Row>
                </ListGroup.Item>
                {/* <ListGroup.Item>
                  <h5>Date de livraison</h5>
                  <DateRange onEvent={handleDateRangeEvent} type='fermeture' range={fermetureTicketDateRange} />
                </ListGroup.Item> */}
              </ListGroup>
            </Col>
            <Col className='aftersaledevices-sav-col' md={9}>
              <Tabs
                defaultActiveKey="all"
                id="uncontrolled-tab-example"
                className="mb-3 order-tab"
              >
                <Tab eventKey="all" title={t('My requests')} selectedTabClassName="is-selected" >
                  {tabAll}
                </Tab>
                <Tab eventKey="in-validation" title={t('In process of validation')} >
                  {tabInValidation}
                </Tab>
                <Tab eventKey="validated" title={t('Validated')}>
                  {tabValidated}
                </Tab>
                <Tab eventKey="ready" title={t('Ready')}>
                  {tabReady}
                </Tab>
                <Tab eventKey="inprogress" title={t('Repair in progress')}>
                  {tabInProgress}
                </Tab>
                <Tab eventKey="over" title={t('Completed')}>
                  {tabOver}
                </Tab>
              </Tabs>
            </Col>

          </Row>
        </>
      }
    </>
  )
}

export default AfterSaleDevices;