import {
    USER_SELLER_LIST_REQUEST,
    USER_SELLER_LIST_SUCCESS,
    USER_SELLER_LIST_FAIL,

    USER_SELLER_GET_REQUEST,
    USER_SELLER_GET_SUCCESS,
    USER_SELLER_GET_FAIL,

    USER_SELLER_MAJ_REQUEST,
    USER_SELLER_MAJ_SUCCESS,
    USER_SELLER_MAJ_FAIL,
    USER_SELLER_MAJ_RESET
}
    from '../constants/userSellerConstants'

import axios from 'axios'

export const listUserSeller = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_SELLER_LIST_REQUEST
        })

        const { userLogin: { userInfo } } = getState()

        const instance = axios.create({
            headers: {
                'Authorization': `Bearer ${userInfo.token}`
            }
        })

        const { data } = await instance.get('/api/users-seller/')

        dispatch({
            type: USER_SELLER_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: USER_SELLER_LIST_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message :
                error
        })
    }
}

export const getUserSeller = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_SELLER_GET_REQUEST
        })

        const { userLogin: { userInfo } } = getState()

        const instance = axios.create({
            headers: {
                'Authorization': `Bearer ${userInfo.token}`
            }
        })

        const { data } = await instance.get(`/api/users-seller/get/?user_id=${userInfo._id}`)

        dispatch({
            type: USER_SELLER_GET_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: USER_SELLER_GET_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message :
                error
        })
    }
}
export const majUserSeller = (seller, clients) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_SELLER_MAJ_REQUEST
        })

        const { userLogin: { userInfo } } = getState()

        const instance = axios.create({
            headers: {
                'Authorization': `Bearer ${userInfo.token}`
            }
        })

        const { data } = await instance.post(
            `/api/users-seller/maj/`,
            { 'seller': seller, 'clients': clients },
            )


        dispatch({
            type: USER_SELLER_MAJ_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: USER_SELLER_MAJ_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message :
                error
        })
    }
}

export const majUserSellerReset = () => (dispatch) => {
    dispatch({
        type: USER_SELLER_MAJ_RESET
    })
}

