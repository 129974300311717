import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Row, Col, Image } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, Link } from 'react-router-dom'

import {
    listAfterSaleConfig,
    addAfterSaleConfig,
    updateAfterSaleConfig,
    deleteAfterSaleConfig,
    listTypeAfterSaleConfig
} from '../../../../actions/afterSaleActions';

import Loader from '../../../../components/Loader/Loader';
import Select from '../../../../components/Select/Select';

import './AfterSale.css';

function AfterSale() {
    const dispatch = useDispatch();

    const header = [
        {
            name: "Nom",
            md: 2
        },
        {
            name: "Description",
            md: 2
        },
        {
            name: "Tarif",
            md: 1
        },
        {
            name: "Nb jours estimé",
            md: 1
        },
        {
            name: "Type de Prestation",
            md: 2
        },
        {
            name: "Garantie",
            md: 1
        },
        {
            name: "Actions",
            md: 1
        }
    ];

    const afterSaleConfigList = useSelector(state => state.afterSaleConfigList);
    const { loading, error, afterSaleConfig } = afterSaleConfigList;

    const typeAfterSaleConfigList = useSelector(state => state.typeAfterSaleConfigList);
    const { typeAfterSaleConfig } = typeAfterSaleConfigList;

    const afterSaleConfigAdd = useSelector(state => state.afterSaleConfigAdd);
    const { successAdd } = afterSaleConfigAdd;

    const afterSaleConfigUpdate = useSelector(state => state.afterSaleConfigUpdate);
    const { successUpdate } = afterSaleConfigUpdate;

    const afterSaleConfigDelete = useSelector(state => state.afterSaleConfigDelete);
    const { successDelete } = afterSaleConfigDelete;

    const [afterSaleFormValues, setAfterSaleFormValues] = useState([]);

    const [newAftersaleName, setNewAftersaleName] = useState('');
    const [newAftersaleDescription, setNewAftersaleDescription] = useState('');
    const [newAftersaleTarif, setNewAftersaleTarif] = useState('');
    const [newAftersaleDaysToDo, setNewAftersaleDaysToDo] = useState('');
    const [newTypeAfterSaleConfigId, setNewTypeAfterSaleConfigId] = useState(null);
    const [newAftersaleIsGuaranteed, setNewAftersaleIsGuaranteed] = useState(false);

    const [selectTypeAfterSaleConfig, setSelectTypeAfterSaleConfig] = useState([])

    const [selectKey, setSelectKey] = useState(Math.random());

    useEffect(() => {
        dispatch(listAfterSaleConfig());
        dispatch(listTypeAfterSaleConfig());
    }, [dispatch]);

    useEffect(() => {
        if (typeAfterSaleConfig && typeAfterSaleConfig.length > 0) {
            let values = []
            typeAfterSaleConfig.sort((a, b) => a.id - b.id).forEach(typeAfterSale => {
                values.push({ value: typeAfterSale.id, label: typeAfterSale.name })
            })
            setSelectTypeAfterSaleConfig(values)
        }
    }, [typeAfterSaleConfig]);

    useEffect(() => {
        if (afterSaleConfig) {
            setAfterSaleFormValues(afterSaleConfig.map(item => ({
                aftersaleName: item.name,
                aftersaleDescription: item.description,
                aftersaleTarif: item.tarif,
                aftersaleDaysToDo: item.days_to_do,
                typeAfterSaleConfigId: item.typeAfterSale,
                aftersaleIsGuaranteed: item.is_warranty
            })));
        }
    }, [afterSaleConfig]);

    useEffect(() => {
        if (successAdd || successUpdate || successDelete) {
            emptyNewObjects()
            dispatch(listAfterSaleConfig());
        }
    }, [dispatch, successAdd, successUpdate, successDelete]);

    const addAfterSale = (e) => {
        e.preventDefault();
        dispatch(addAfterSaleConfig(
            newAftersaleName,
            newAftersaleDescription,
            newAftersaleTarif,
            newAftersaleDaysToDo,
            newTypeAfterSaleConfigId,
            newAftersaleIsGuaranteed,
            newAftersaleIsGuaranteed
        ))
        setSelectKey(Math.random());
        setNewTypeAfterSaleConfigId(null);
    }

    const updateAfterSale = (id, index) => {
        const updatedFormValues = afterSaleFormValues[index];
        dispatch(updateAfterSaleConfig(
            id, updatedFormValues.aftersaleName,
            updatedFormValues.aftersaleDescription,
            updatedFormValues.aftersaleTarif,
            updatedFormValues.aftersaleDaysToDo,
            updatedFormValues.typeAfterSaleConfigId,
            updatedFormValues.aftersaleIsGuaranteed
        ))
    }

    const removeAfterSale = (id) => {
        dispatch(deleteAfterSaleConfig(id))
    }

    const eraseAfterSale = (e) => {
        emptyNewObjects()
    }

    const onHandleTypeAfterSaleSelectChange = (value, index) => {
        const updatedFormValues = [...afterSaleFormValues];
        updatedFormValues[index].typeAfterSaleConfigId = value;
        setAfterSaleFormValues(updatedFormValues);
    }

    const onHandleNewTypeAfterSaleSelectChange = (value) => {
        setNewTypeAfterSaleConfigId(value)
    }

    const onFormValueChange = (e, index, field) => {
        const { value, checked } = e.target;

        if (field == 'aftersaleIsGuaranteed') {
            // Pour tous les autres champs, aucune restriction
            const updatedFormValues = [...afterSaleFormValues];
            updatedFormValues[index][field] = checked;
            setAfterSaleFormValues(updatedFormValues);
        }
        else if (field === 'aftersaleTarif' && (value === "" || /^\d*\.?\d*$/.test(value))) {
            // Autoriser uniquement les nombres flottants pour aftersaleTarif
            const updatedFormValues = [...afterSaleFormValues];
            updatedFormValues[index][field] = value;
            setAfterSaleFormValues(updatedFormValues);
        }
        else if (field === 'aftersaleDaysToDo' && (value === "" || /^\d+$/.test(value))) {
            // Autoriser uniquement les nombres entiers pour aftersaleDaysToDo
            const updatedFormValues = [...afterSaleFormValues];
            updatedFormValues[index][field] = value;
            setAfterSaleFormValues(updatedFormValues);
        }
        else if (field !== 'aftersaleTarif' && field !== 'aftersaleDaysToDo') {
            // Pour tous les autres champs, aucune restriction
            const updatedFormValues = [...afterSaleFormValues];
            updatedFormValues[index][field] = e.target.value;
            setAfterSaleFormValues(updatedFormValues);
        }
    }

    function emptyNewObjects() {
        setNewAftersaleName('')
        setNewAftersaleDescription('')
        setNewAftersaleTarif('')
        setNewAftersaleDaysToDo('')
        setNewTypeAfterSaleConfigId(null)
    }

    return (
        <div className='aftersale-config-main'>
            <Row className='aftersale-title'>
                <span>Configuration des prestations SAV</span>
            </Row>
            <Row className='aftersale-items-row'>
                <Row className='aftersale-item-row'>
                    {header.map((item, index) => (
                        <Col md={item.md} key={index}>
                            <span>{item.name}</span>
                        </Col>
                    ))}
                </Row>
                {
                    loading ? <Loader /> :
                        (afterSaleConfig && afterSaleConfig.length > 0 && selectTypeAfterSaleConfig && selectTypeAfterSaleConfig.length > 0
                            && afterSaleFormValues && afterSaleFormValues.length > 0) ?
                            afterSaleConfig.map((item, index) => (
                                <Row className='aftersale-item-row' key={item.id}>
                                    <Col md={2}>
                                        <Form.Control
                                            type='text'
                                            value={afterSaleFormValues[index].aftersaleName}
                                            onChange={(e) => onFormValueChange(e, index, 'aftersaleName')}
                                            autoFocus={false}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <Form.Control
                                            type='text'
                                            value={afterSaleFormValues[index].aftersaleDescription}
                                            onChange={(e) => onFormValueChange(e, index, 'aftersaleDescription')}
                                            autoFocus={false}
                                        />
                                    </Col>
                                    <Col md={1}>
                                        <Form.Control
                                            type='text'
                                            value={afterSaleFormValues[index].aftersaleTarif}
                                            onChange={(e) => onFormValueChange(e, index, 'aftersaleTarif')}
                                            autoFocus={false}
                                        />
                                    </Col>
                                    <Col md={1}>
                                        <Form.Control
                                            type='text'
                                            value={afterSaleFormValues[index].aftersaleDaysToDo}
                                            onChange={(e) => onFormValueChange(e, index, 'aftersaleDaysToDo')}
                                            autoFocus={false}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <Select
                                            options={selectTypeAfterSaleConfig}
                                            value={afterSaleFormValues[index].typeAfterSaleConfigId}
                                            onChangeFunc={(value) => onHandleTypeAfterSaleSelectChange(value, index)}
                                        />
                                    </Col>
                                    <Col md={1}>
                                        <Form.Check
                                            type="checkbox"
                                            checked={afterSaleFormValues[index].aftersaleIsGuaranteed}
                                            onChange={(e) => onFormValueChange(e, index, 'aftersaleIsGuaranteed')}
                                        />
                                    </Col>
                                    <Col className='aftersale-button-col' md={1}>
                                        <button className='btn-aftersale-update' value={item.id} onClick={() => updateAfterSale(item.id, index)}><i className="fa-solid fa-rotate fa-lg"></i></button>
                                        <button className='btn-aftersale-remove' value={item.id} onClick={() => removeAfterSale(item.id)}><i className="fa-solid fa-trash fa-lg"></i></button>
                                    </Col>
                                </Row>
                            ))
                            : null
                }
                < Row className='aftersale-item-row'>
                    <Col md={2}>
                        <Form.Control
                            type='text'
                            value={newAftersaleName}
                            onChange={(e) => setNewAftersaleName(e.target.value)}
                            placeholder="Nom de l'après-vente"
                            autoFocus={false}
                        />
                    </Col>
                    <Col md={2}>
                        <Form.Control
                            type='text'
                            value={newAftersaleDescription}
                            onChange={(e) => setNewAftersaleDescription(e.target.value)}
                            placeholder="Description"
                            autoFocus={false}
                        />
                    </Col>
                    <Col md={1}>
                        <Form.Control
                            type='text'
                            value={newAftersaleTarif}
                            onChange={(e) => {
                                // Autoriser uniquement les nombres flottants
                                const value = e.target.value;
                                if (value === "" || /^\d*\.?\d*$/.test(value)) {
                                    setNewAftersaleTarif(value);
                                }
                            }}
                            placeholder="Tarif"
                            autoFocus={false}
                        />
                    </Col>
                    <Col md={1}>
                        <Form.Control
                            type='text'
                            value={newAftersaleDaysToDo}
                            onChange={(e) => {
                                // Autoriser uniquement les nombres entiers
                                const value = e.target.value;
                                if (value === "" || /^\d+$/.test(value)) {
                                    setNewAftersaleDaysToDo(value);
                                }
                            }}
                            placeholder="Délai"
                            autoFocus={false}
                        />
                    </Col>
                    <Col md={2}>
                        <Select
                            key={selectKey}
                            options={selectTypeAfterSaleConfig}
                            value={newTypeAfterSaleConfigId}
                            onChangeFunc={(e) => onHandleNewTypeAfterSaleSelectChange(e)}
                            placeholder="Type prestation"
                        />
                    </Col>
                    <Col md={1}>
                        <Form.Check
                            type="checkbox"
                            onChange={(e) => setNewAftersaleIsGuaranteed(e.target.checked)}
                        />
                    </Col>
                    <Col className='aftersale-button-col' md={1}>
                        <button className='btn-aftersale-add' onClick={(e) => addAfterSale(e)}><i className="fa-solid fa-add fa-lg"></i></button>
                        <button className='btn-aftersale-erase' onClick={(e) => eraseAfterSale(e)}><i className="fa-solid fa-eraser fa-lg"></i></button>
                    </Col>
                </Row>
            </Row>

        </div >
    );
}

export default AfterSale;

