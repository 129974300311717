export const CONTACT_ODOO_LIST_REQUEST = 'CONTACT_ODOO_LIST_REQUEST'
export const CONTACT_ODOO_LIST_SUCCESS = 'CONTACT_ODOO_LIST_SUCCESS'
export const CONTACT_ODOO_LIST_FAIL = 'CONTACT_ODOO_LIST_FAIL'

export const CONTACT_ODOO_GET_REQUEST = 'CONTACT_ODOO_GET_REQUEST'
export const CONTACT_ODOO_GET_SUCCESS = 'CONTACT_ODOO_GET_SUCCESS'
export const CONTACT_ODOO_GET_FAIL = 'CONTACT_ODOO_GET_FAIL'

export const CONTACT_ODOO_CREATE_REQUEST = 'CONTACT_ODOO_CREATE_REQUEST'
export const CONTACT_ODOO_CREATE_SUCCESS = 'CONTACT_ODOO_CREATE_SUCCESS'
export const CONTACT_ODOO_CREATE_FAIL = 'CONTACT_ODOO_CREATE_FAIL'
export const CONTACT_ODOO_CREATE_RESET = 'CONTACT_ODOO_CREATE_RESET'

export const CONTACT_ODOO_UPDATE_REQUEST = 'CONTACT_ODOO_UPDATE_REQUEST'
export const CONTACT_ODOO_UPDATE_SUCCESS = 'CONTACT_ODOO_UPDATE_SUCCESS'
export const CONTACT_ODOO_UPDATE_FAIL = 'CONTACT_ODOO_UPDATE_FAIL'

export const CONTACT_ODOO_DELETE_REQUEST = 'CONTACT_ODOO_DELETE_REQUEST'
export const CONTACT_ODOO_DELETE_SUCCESS = 'CONTACT_ODOO_DELETE_SUCCESS'
export const CONTACT_ODOO_DELETE_FAIL = 'CONTACT_ODOO_DELETE_FAIL'

export const CONTACT_BY_ODOO_ID_REQUEST = 'CONTACT_BY_ODOO_ID_REQUEST'
export const CONTACT_BY_ODOO_ID_SUCCESS = 'CONTACT_BY_ODOO_ID_SUCCESS'
export const CONTACT_BY_ODOO_ID_FAIL = 'CONTACT_BY_ODOO_ID_FAIL'