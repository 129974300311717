import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'

import './Tuiles.css';
import { useTranslation } from 'react-i18next';

function Categorie({ categorie }) {
    const [url, setUrl] = React.useState('')
    const { t, i18n } = useTranslation();

    useEffect(() => {
        //construire l'URL de l'image
        
        let endUrl = '&categorie=0&paramType=all&param=all'
        if(categorie.is_product_category){
            if(categorie.is_imei)
                setUrl(`#/imei-products?headerCategorie=${categorie.id}${endUrl}`)
            else
                setUrl(`#/products?headerCategorie=${categorie.id}${endUrl}`)
        }
        else
            setUrl(`#/categorie/${categorie.id}`)
    }, [])

    return (
        <Card className='tuile rounded text-center'>
            <a href={`/categorie/${categorie.id}`}>
                <Card.Img className='card-img w-50' src={categorie.image_url} variant='top' />
            </a>
            <Card.Body>
                <a href={url}>
                    <Card.Title as='div'>
                        <strong>{t(categorie.name)}</strong>
                    </Card.Title>
                </a>
            </Card.Body>
        </Card>
    )
}

export default Categorie