export const SKU_LIST_REQUEST = 'SKU_LIST_REQUEST';
export const SKU_LIST_SUCCESS = 'SKU_LIST_SUCCESS';
export const SKU_LIST_FAIL = 'SKU_LIST_FAIL';

export const SKU_ADD_REQUEST = 'SKU_ADD_REQUEST';
export const SKU_ADD_SUCCESS = 'SKU_ADD_SUCCESS';
export const SKU_ADD_FAIL = 'SKU_ADD_FAIL';

export const SKU_DELETE_REQUEST = 'SKU_DELETE_REQUEST';
export const SKU_DELETE_SUCCESS = 'SKU_DELETE_SUCCESS';
export const SKU_DELETE_FAIL = 'SKU_DELETE_FAIL';

export const SKU_CHECK_REQUEST = 'SKU_CHECK_REQUEST';
export const SKU_CHECK_SUCCESS = 'SKU_CHECK_SUCCESS';
export const SKU_CHECK_FAIL = 'SKU_CHECK_FAIL';