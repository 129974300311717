import React from 'react'
import { Card } from 'react-bootstrap'

import './Tuiles.css';

function Brand({ brand }) {
  return (
    <Card className='tuile rounded text-center'>
            <a href={`/brand/${brand.id}`}>
                <Card.Img className='card-img w-50' src={brand.image} variant='top' />
            </a>
            <Card.Body>
                <a href={`/brand/${brand.id}`}>
                    <Card.Title as='div'>
                        <strong>{brand.name}</strong>
                    </Card.Title>
                </a>
            </Card.Body>
        </Card>
  )
}

export default Brand