import axios from 'axios';
import {
    TICKET_LIST_REQUEST,
    TICKET_LIST_SUCCESS,
    TICKET_LIST_FAIL,

    TICKET_CREATE_REQUEST,
    TICKET_CREATE_SUCCESS,
    TICKET_CREATE_FAIL,
    TICKET_CREATE_RESET

} from "../constants/ticketConstants";

export const listTickets = (partner_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: TICKET_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            `api/tickets/?partner_id=${partner_id}`
            )

        dispatch({ type: TICKET_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ 
            type: TICKET_LIST_FAIL, 
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message })
    }
}

export const createTicket = (ticket) => async (dispatch, getState) => {
    try {
        
        dispatch({
            type: TICKET_CREATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/tickets/add/`,
            { 'ticket': ticket, 'updated_by': userInfo.impersonated_by ? userInfo.impersonated_by : userInfo._id },
            )

        dispatch({
            type: TICKET_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: TICKET_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const resetTicketCreate = () => (dispatch) => {
    dispatch({ type: TICKET_CREATE_RESET })
}
