import { 
    CATEGORIES_LIST_REQUEST,
    CATEGORIES_LIST_SUCCESS,
    CATEGORIES_LIST_FAIL,

    CATEGORIES_CREATE_REQUEST,
    CATEGORIES_CREATE_SUCCESS,
    CATEGORIES_CREATE_FAIL,

    CATEGORIES_UPDATE_REQUEST,
    CATEGORIES_UPDATE_SUCCESS,
    CATEGORIES_UPDATE_FAIL,

    CATEGORIES_DELETE_REQUEST,
    CATEGORIES_DELETE_SUCCESS,
    CATEGORIES_DELETE_FAIL,
} from "../constants/categoriesConstants";

export const CategoriesListReducer = (state ={categories: [] }, action) => {
    switch (action.type) {
        case CATEGORIES_LIST_REQUEST:
            return { loading: true, categories: [] }
        case CATEGORIES_LIST_SUCCESS:
            return { loading: false, categories: action.payload }
        case CATEGORIES_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const CategoriesCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CATEGORIES_CREATE_REQUEST:
            return { loading: true }
        case CATEGORIES_CREATE_SUCCESS:
            return { loading: false, success: true }
        case CATEGORIES_CREATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const CategoriesUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case CATEGORIES_UPDATE_REQUEST:
            return { loading: true }
        case CATEGORIES_UPDATE_SUCCESS:
            return { loading: false, success: true }
        case CATEGORIES_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const CategoriesDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CATEGORIES_DELETE_REQUEST:
            return { loading: true }
        case CATEGORIES_DELETE_SUCCESS:
            return { loading: false, success: true }
        case CATEGORIES_DELETE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}