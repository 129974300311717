import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { Row, Col, ListGroup, Image, Card, Container } from 'react-bootstrap'
import { addToCart, removeFromCart, saveShippingAddress } from '../../actions/cartActions'
import { getOrderDetails, createOrder, payOrder } from '../../actions/orderActions'
import { PayPalButton } from 'react-paypal-button-v2'
import { ORDER_PAY_RESET } from '../../constants/orderConstants'

import Message from '../../components/Message/Message'
import Loader from '../../components/Loader/Loader'

import {
    ORDER_DRAFT, ORDER_SENT, ORDER_SALE, ORDER_DONE, ORDER_CANCEL,
    INVOICE_NO, INVOICE_INVOICED,
    DELIVERY_DRAFT, DELIVERY_WAITING, DELIVERY_CONFIRMED, DELIVERY_ASSIGNED, DELIVERY_DONE, DELIVERY_CANCEL,
    PAYMENT_PAID, PAYMENT_IN_PAYMENT, PAYMENT_REVERSE
}
    from '../../constants/orderConstants'

import './Order.css'

function Order() {

    const location = useLocation();

    const orderId = location.pathname ? Number(location.pathname.split('/')[location.pathname.split('/').length - 1]) : null;
    const dispatch = useDispatch()

    const [sdkReady, setSdkReady] = useState(false)

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const orderCreate = useSelector((state) => state.orderCreate)
    const { order, loading, error } = orderCreate
    //const orderDetails = useSelector((state) => state.orderDetails)
    //const { order, loading, error } = orderDetails

    const orderPay = useSelector((state) => state.orderPay)
    const { loading: loadingPay, success: successPay } = orderPay

    const cart = useSelector((state) => state.cart)

    const navigate = useNavigate()

    if (!loading && !error) {
        order.itemsPrice = order.articles.reduce((acc, item) => acc + item.price * item.qty, 0).toFixed(2)
        order.taxPrice = Number((0.2 * order.articles).toFixed(2))
        order.totalPrice = (Number(order.articles) + Number(order.shippingPrice) + Number(order.taxPrice)).toFixed(2)
    }

    //sandbox key
    //Aa4GROosT-tVRJABs85DfI76mct7v4gKxVu00Zl7qXUcBsk8OZRwLrcjrR2x97W-xrvHbOBq3EUaO7Io

    const addPayPalScript = () => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = 'https://www.paypal.com/sdk/js?client-id=Aa4GROosT-tVRJABs85DfI76mct7v4gKxVu00Zl7qXUcBsk8OZRwLrcjrR2x97W-xrvHbOBq3EUaO7Io'
        script.async = true
        script.onload = () => {
            setSdkReady(true)
        }
        document.body.appendChild(script)
    }

    useEffect(() => {
        if (!order || order._id !== Number(orderId)) {
            dispatch({ type: ORDER_PAY_RESET })
            //TODO Temporaire avec données statiques
            //dispatch(getOrderDetails(orderId))

            dispatch(createOrder({
                "_id": 1,
                "id": "CMD123",
                "demandeur": "john snow",
                "refClient": "commande john snow",
                "order_date": "2022-11-01T00:00:00.000Z",
                "delivery_date": "2022-12-01T00:00:00.000Z",
                "price": cart.itemsPrice,
                "paymentMethod": cart.paymentMethod,
                "articles": cart.cartItems,
                "shippingAddress": cart.shippingAddress,
                "shippingPrice": cart.shippingPrice,
                "taxPrice": cart.taxPrice,
                "totalPrice": cart.totalPrice
            }))
        }
        else if (!order.isPaid) {
            if (!window.paypal) {
                addPayPalScript()
            } else {
                setSdkReady(true)
            }
        }
    }, [dispatch, order, orderId, successPay])

    const successPaymentHandler = (paymentResult) => {
        
        dispatch(payOrder(orderId, paymentResult))
    }

    return loading ? (<Loader />) : error ? (<Message variant='danger'>{error}</Message>) :
        (
            <>
                <h1>Commande {order._id}</h1>
                <Row>
                    <Col md={8}>
                        <ListGroup variant='flush'>
                            <ListGroup.Item>
                                <h2>Livraison</h2>
                                <p><strong>Nom: {userInfo.name} </strong></p>
                                <p><strong>Email: <a href={`mailto:${userInfo.email}`}>{userInfo.email}</a></strong></p>
                                <p>
                                    <strong>Adresse: </strong>
                                    {cart.shippingAddress.voie}{' '}, {cart.shippingAddress.voie2}{' '}
                                    {cart.shippingAddress.codePostal},{' '}
                                    {cart.shippingAddress.ville},{' '}
                                    {cart.shippingAddress.pays}
                                </p>
                                {order.delivery_status ? (
                                    <Message variant='success'>
                                        Livrée le {order.paidAt}
                                    </Message>
                                ) : (
                                    <Message variant='warning'>Non livrée</Message>
                                )}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <h2>Méthode de paiement</h2>
                                <p>
                                    <strong>Méthode: </strong>
                                    {cart.paymentMethod}
                                </p>
                                {order.isPaid ? (
                                    <Message variant='success'>
                                        Payée le {order.paidAt}
                                    </Message>
                                ) : (
                                    <Message variant='warning'>Non payée</Message>
                                )}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <h2>Vos articles</h2>
                                {cart.cartItems.length === 0 ? (
                                    <Message>Votre panier est vide</Message>
                                ) : (
                                    <ListGroup variant='flush'>
                                        {cart.cartItems.map((item, index) => (
                                            <ListGroup.Item key={index}>
                                                <Row>
                                                    <Col md={1}>
                                                        <Image src={item.image
                                                        } alt={item.name} fluid rounded />
                                                    </Col>
                                                    <Col>
                                                        <Link
                                                            to={`/product/${item.product}/`}
                                                        >
                                                            {item.name}
                                                        </Link>
                                                    </Col>
                                                    <Col md={4}>
                                                        {item.qty} x {item.price} € = {item.qty * item.price} €
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        ))}

                                    </ListGroup>
                                )}
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <ListGroup variant='flush'>
                                <ListGroup.Item>
                                    <h2>Récapitulatif de la commande</h2>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Row>
                                        <Col>Articles</Col>
                                        <Col>{cart.itemsPrice} €</Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Row>
                                        <Col>Frais de livraison</Col>
                                        <Col>{cart.shippingPrice} €</Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Row>
                                        <Col>Taxes</Col>
                                        <Col>{cart.taxPrice} €</Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Row>
                                        <Col>Total</Col>
                                        <Col>{cart.totalPrice} €</Col>
                                    </Row>
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    {error && <Message variant='danger'>{error}</Message>}
                                </ListGroup.Item>

                                {!order.isPaid && (
                                    <ListGroup.Item>
                                        {loadingPay && <Loader />}
                                        {!sdkReady ? (
                                            <Loader />
                                        ) : (
                                            <PayPalButton
                                                amount={cart.totalPrice}
                                                onSuccess={successPaymentHandler}
                                            />
                                        )}
                                    </ListGroup.Item>
                                )}
                            </ListGroup>
                        </Card>
                    </Col>
                </Row>
            </>
        )
}

export default Order