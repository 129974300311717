import React, { Component } from "react";
import ReactDOM from "react-dom";
import Select from "react-select";
import ReactCountriesInput from "react-countries-input";
import countryList from "react-select-country-list";

import "./CountrySelector.css";


export default class CountrySelector extends Component {
    constructor(props) {
      super(props);
        
      this.options = countryList().getData();

      this.state = {
        options: this.options,
        value: { label: "France", value: "FR" }
      };
    }
  
    changeHandler = value => {
      this.setState({ value });
    };
  
    render() {
      return (
        <Select
        className="country-selector"
          options={this.state.options}
          defaultValue={this.state.value}
          onChange={this.props.addInputCountry}
        />
      );
    }
  }