import React from 'react'
import Table from 'react-bootstrap/Table'
import './Features.css'

function Features({ product, header, table }) {
    return (
        <div>
            <Table className={table} striped="columns">
                <thead className={header}>
                    <tr>
                        <th>
                            <div>Caractéristiques détaillées</div>
                            <div className='product-brand-name'>{product.brand} {product.name}</div>
                        </th>
                    </tr>
                </thead>
                <tbody>

                    {
                        Object.keys(product.device_spec).map((key, i) => (
                            <tr>
                                <td>{key.replace('_', ' ')} : </td>
                                <td>{
                                    Array.isArray(product.device_spec[key]) ?
                                        Object.keys(product.device_spec[key]).map((k, index) => (
                                            <div>
                                                {product.device_spec[key][k]}
                                            </div>
                                        ))
                                        :
                                        product.device_spec[key]
                                }</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </div>
    )
}

export default Features