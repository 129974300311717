import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, ListGroup } from 'react-bootstrap'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import ReactTooltip from 'react-tooltip';


import Loader from '../../components/Loader/Loader'
//import productsData from '../../Test/products';
import { listProducts } from '../../actions/productActions';
import { allFeatures, getIsFilter } from '../../actions/featureActions';
import { getAllWarranty } from '../../actions/warrantyActions';
import { listCategories } from '../../actions/categoriesActions';
import { listHeaderCategories } from '../../actions/headerCategoriesActions';
import { getAllOdooStock } from '../../actions/stockActions';

import Select from '../../components/Select/Select';

import { toArrayKeys, toArrayValues, findVal, removeDuplicatesByProperties } from "../../utils/utils";

import Product from '../../components/Product/Product';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import './Products.css';
import { useTranslation } from 'react-i18next';

function Products({ product }) {
    const dispatch = useDispatch()
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const [priceOpen, setPriceOpen] = useState(false);
    const [brandOpen, setBrandOpen] = useState(false);
    const [osOpen, setOsOpen] = useState(false);
    const [versionOsOpen, setVersionOsOpen] = useState(false);
    const [simSlotsOpen, setSimSlotsOpen] = useState(false);

    const searchParams = new URLSearchParams(location.search);
    const headerCategorieParam = searchParams.get('headerCategorie');
    const categorieParam = searchParams.get('categorie');
    const param = searchParams.get('param');
    const paramType = searchParams.get('paramType');

    const [specOpen, setSpecOpen] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);

    const [productsData, setProductsData] = useState([]);
    const [categorie, setCategorie] = useState({});
    const [headerCategorie, setHeaderCategorie] = useState(null);
    const [categorieId, setCategorieId] = useState(0);
    const [headerCategorieId, setHeaderCategorieId] = useState(0);
    const [selectCategories, setSelectCategories] = useState([])

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const productList = useSelector(state => state.productList)
    const { error, loading, products, page, pages } = productList

    const wty = useSelector(state => state.warrantyAll)
    const { warranties } = wty

    const allProductFeatures = useSelector(state => state.allFeaturesList)
    const { featuresList } = allProductFeatures

    const allStock = useSelector(state => state.allStock)
    const { allOdooStock } = allStock

    const searchKeys = ['brand', 'name', 'type']

    const brand = {};
    const os = {};
    const version_os = {};
    const simSlots = {};
    const stock = {};
    const type = {};

    const [priceRangeObj, setPriceRangeObj] = useState([]); // à voir pour mettre les prix min et max des produits
    const [brandObj, setBrandObj] = useState(brand);
    const [osObj, setOsObj] = useState(os);
    const [versionOsObj, setVersionOsObj] = useState(version_os);
    const [simSlotsObj, setSimSlotsObj] = useState(simSlots);
    const [stockObj, setStockObj] = useState(stock);
    const [typeObj, setTypeObj] = useState(stock);

    const [priceRange, setPriceRange] = useState([]);

    const [filteredProductData, setFilteredProductData] = useState([]);

    const [filterItemObj, setFilterItemObj] = useState({});

    const categoriesList = useSelector(state => state.categoriesList)
    const { categories } = categoriesList

    const headerCategoriesList = useSelector((state) => state.headerCategoriesList);
    const { loading: loadingHeaderCategories, error: errorHeaderCategories, headerCategories } = headerCategoriesList;

    const featureFilter = useSelector((state) => state.featureFilter);
    const { loading: loadingFeatureFilter, error: errorFeatureFilter, filters } = featureFilter;

    const [downloadCatalog, setDownloadCatalog] = useState(false);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(location.search);
        const categorieId = urlSearchParams.get("categorie");
        setCategorieId(categorieId);

        if (!loading && (!products || products.length === 0))
            dispatch(listProducts());
        else {
            dispatch(allFeatures());
        }
        if (!loadingHeaderCategories && (!headerCategories || headerCategories.length === 0))
            dispatch(listHeaderCategories());
        if (!categories || categories.length === 0)
            dispatch(listCategories());

        dispatch(getAllOdooStock());
        dispatch(getIsFilter());
    }, [])

    useEffect(() => {
        if (categorieParam) {
            const urlSearchParams = new URLSearchParams(location.search);
            const categorieId = urlSearchParams.get("categorie");
            const headerCategorieId = urlSearchParams.get("headerCategorie");
            setCategorieId(categorieId);
            setHeaderCategorieId(headerCategorieId);
        }
    }, [categorieParam, categories])

    useEffect(() => {
        if (categorieId && categories && categories.length > 0) {
            let categorie = categories.find(categorie => categorie.id === parseInt(categorieId))
            setCategorie(categorie)
        }

        if (headerCategorieId && headerCategories && headerCategories.length > 0) {
            let headerCategorie = headerCategories.find(headerCategorie => headerCategorie.id === parseInt(headerCategorieId))
            setHeaderCategorie(headerCategorie)
        }
    }, [categorieId, headerCategorieId, categories])

    useEffect(() => {
        if (categorie || headerCategorie) {
            let productArr = getProductData();
            setProductsData(productArr);
        }
    }, [location.search, categorie, headerCategorie]);

    useEffect(() => {
        dispatch(allFeatures());
    }, [products])

    useEffect(() => {
        dispatch(getAllWarranty());
    }, [featuresList])

    useEffect(() => {
        let productArr = getProductData();
        setProductsData(productArr);
    }, [warranties])

    useEffect(() => {
        if (productsData && productsData.length > 0) {
            FillFilters();
            let arr = [
                Math.min(...productsData.map(x => x.price)),
                Math.max(...productsData.map(x => x.price))
            ]
            setPriceRange([...arr])
            setDownloadCatalog(t('Download catalog') + ` ${productsData[0].categorie_odoo} ` + t('in excel format'))
        }

    }, [productsData])

    useEffect(() => {
        setPriceRangeObj(priceRange)
    }, [priceRange])

    useEffect(() => {
        if (categories) {
            let values = []
            categories.sort((a, b) => a.id - b.id).forEach(category => {
                values.push({ value: category.id, label: category.name })
            })
            setSelectCategories(values)
        }
    }, [categories])

    useEffect(() => {
        if (categorie && categorie.id && headerCategorie && headerCategorie.id) {
            let productArr = getProductData();
            setProductsData(productArr);
        }
    }, [categorie])

    useEffect(() => {
        if (productsData && productsData.length > 0 && categorie) {
            let product_list = productsData
                .filter(product => product.categorie_odoo === categorie.name)
                .sort((a, b) => a.id - b.id).map((product, index) => product)
            setFilteredProductData(product_list)
        }
    }, [productsData])

    //#region useEffect pour les checkbox
    useEffect(() => {
        const filteredItems = {
            price_range: [],
            brand: [],
            os: [],
            version_os: [],
            sim_slots: [],
            stock: []
        };

        const filterPriceRangeArr = Array.from(new Set(productsData.filter((item) => {
            return item.price >= priceRangeObj[0] && item.price <= priceRangeObj[1];
        }).map((item) => item.price)));

        const filterBrandArr = toArrayKeys(brandObj).filter(
            (data) => brandObj[data] === true
        );

        const filterOsArr = toArrayKeys(osObj).filter(
            (data) => osObj[data] === true
        );

        const filterVersionOsArr = toArrayKeys(versionOsObj).filter(
            (data) => versionOsObj[data] === true
        );

        const filterSimSlotsArr = toArrayKeys(simSlotsObj)
            .filter(
                (data) => simSlotsObj[data] === true
            )
            .map((data) => { return parseInt(data) })
            ;

        const filterStockArr = toArrayKeys(stockObj).filter(
            (data) => stockObj[data] === true
        );

        const filterTypeArr = toArrayKeys(typeObj).filter(
            (data) => typeObj[data] === true
        );

        filteredItems["price"] = [...filterPriceRangeArr];
        filteredItems["brand"] = [...filterBrandArr];
        filteredItems["os_family"] = [...filterOsArr];
        filteredItems["os"] = [...filterVersionOsArr];
        filteredItems["sim_slots"] = [...filterSimSlotsArr];
        filteredItems["stock"] = [...filterStockArr];
        filteredItems["type"] = [...filterTypeArr];

        setFilterItemObj(filteredItems);

    }, [priceRangeObj, brandObj, versionOsObj, osObj, simSlotsObj, stockObj, typeObj, productsData]);

    useEffect(() => {
        if (selectedFilters && selectedFilters.length > 0) {
            
        }
    }, [selectedFilters]);

    // useEffect pour les filtres
    useEffect(() => {
        const filterKeys = Object.keys(filterItemObj);
        let finalFilteredProductDataArr = [];
        const filteredProductDataArr = (productsData).filter((p) => {
            return filterKeys.every((key) => {
                if (!filterItemObj[key].length) {
                    if (key === "price_range" && priceRangeObj[1] < Math.min(...productsData.map(x => x.price)))
                        return false;
                    return true;
                }

                if (key === "stock")
                    return filterItemObj[key].includes(p[key] > 0 ? t('In stock') : t('Out of stock'));

                return filterItemObj[key].includes(findVal(p, key));
            });
        });
        
        if (selectedFilters && selectedFilters.length > 0 && selectedFilters.filter((filter) => filter.checked).length > 0) {
            finalFilteredProductDataArr = filteredProductDataArr.filter((product) => {
                return selectedFilters
                    .filter((filter) => filter.checked)
                    .every((filter) => {
                        
                        
                        return product.features.some((feature) => feature.name === filter.name && feature.value === filter.value);
                    });
            });
        }
        else 
            finalFilteredProductDataArr = filteredProductDataArr;
        

        
        setFilteredProductData(finalFilteredProductDataArr);
    }, [filterItemObj, selectedFilters, productsData]);
    //#endregion

    function getProductData() {
        let arr = [];
        let features = [];
        let warranty = [];

        //TODO : Gérer la question des garanties
        products.forEach(product => {
            if (categorie) {
                if (product.categorie_odoo === categorie.name) {
                    if (paramType === 'brand') {
                        if (product.brand === param) {
                            arr.push(product)
                        }
                    }
                    else if (paramType === 'os') {
                        if (product.device_spec.os_family === param) {
                            arr.push(product)
                        }
                    }
                    else {
                        arr.push(product)
                    }
                }

            }
            else if (headerCategorie) {
                let categorie_list = headerCategorie.categories.map(category => categories.find(categorie => categorie.id === category))
                if (categorie_list.length > 0) {
                    categorie_list.forEach(category => {
                        if (product.categorie_odoo === category.name) {
                            arr.push(product)
                        }
                    })
                }

            }
        });

        return arr;
    }
    //#region Filter
    function FillFilters() {
        const brandList = Array.from(new Set(productsData
            .filter((item) => item.brand !== null)
            .map((item) => item.brand)));
        const osList = Array.from(new Set(productsData
            .filter((item) => item.device_spec.os_family !== null)
            .map((item) => item.device_spec.os_family)));
        const versionOsList = Array.from(new Set(productsData
            .filter((item) => item.device_spec.device_spec !== null)
            .map((item) => item.device_spec.os)));
        const simSlotsList = Array.from(new Set(productsData
            .filter((item) => item.device_spec.sim_slots !== null)
            .map((item) => item.device_spec.sim_slots)));
        // 
        // 
        // const stockList = Array.from(new Set(allOdooStock
        //     .map((item) => item.qty > 0 ? "En Stock" : "Rupture")));

        const typeList = Array.from(new Set(productsData
            .filter((item) => item.type == null)
            .map((item) => item.type)));

        //#region List Filter Map
        brandList.map((b, i) => {
            const newObj = {};
            newObj[b] = 'false';
            Object.assign(brand, newObj)
        });
        setBrandObj(brand);

        osList.map((o, i) => {
            const newObj = {};
            newObj[o] = 'false';
            Object.assign(os, newObj)
        });
        setOsObj(os);

        versionOsList.map((o, i) => {
            const newObj = {};
            newObj[o] = 'false';
            Object.assign(version_os, newObj)
        });
        setVersionOsObj(version_os);

        simSlotsList.map((o, i) => {
            const newObj = {};
            newObj[o] = 'false';
            Object.assign(simSlots, newObj)
        });
        setSimSlotsObj(simSlots);

        // stockList.map((o, i) => {
        //     const newObj = {};
        //     newObj[o] = 'false';
        //     Object.assign(stock, newObj)
        // });
        // setStockObj(stock);
        //#endregion

    }

    const onChangeFiltersHandler = (event, label) => {
        const { value, checked } = event.target;

        // Chercher un filtre existant avec le même nom et la même valeur
        let existingFilterIndex = selectedFilters.findIndex(
            filter => filter.name === label && filter.value === value
        );
    
        if (existingFilterIndex !== -1) {
            // Le filtre existe déjà, mettez à jour sa valeur checked
            setSelectedFilters(selectedFilters.map((filter, index) =>
                index === existingFilterIndex
                    ? { ...filter, checked: checked }
                    : filter
            ));
        } else {
            // Le filtre n'existe pas encore, créez-en un nouveau
            setSelectedFilters([...selectedFilters, { name: label, value: value, checked: checked }]);
        }
    };
    
    const onChangeHandler = (event, label) => {
        const { value, checked, name } = event.target;

        switch (label) {
            case "BRAND":
                for (let brandKey in brandObj) {
                    if (checked) {
                        if (brandKey === value) {
                            setBrandObj({
                                ...brandObj,
                                [brandKey]: true
                            });
                        }
                    } else {
                        if (brandKey === value) {
                            setBrandObj({
                                ...brandObj,
                                [brandKey]: false
                            });
                        }
                    }
                }

                break;
            case "VERSION_OS":
                for (let versionOsKey in versionOsObj) {
                    if (checked) {
                        if (versionOsKey === value) {
                            setVersionOsObj({
                                ...versionOsObj,
                                [versionOsKey]: true
                            });
                        }
                    } else {
                        if (versionOsKey === value) {
                            setVersionOsObj({
                                ...versionOsObj,
                                [versionOsKey]: false
                            });
                        }
                    }
                }

                break;
            case "OS":
                for (let osKey in osObj) {
                    if (checked) {
                        if (osKey === value) {
                            setOsObj({
                                ...osObj,
                                [osKey]: true
                            });
                        }
                    } else {
                        if (osKey === value) {
                            setOsObj({
                                ...osObj,
                                [osKey]: false
                            });
                        }
                    }
                }

                break;
            case "SIM_SLOTS":
                for (let simSlotsKey in simSlotsObj) {
                    if (checked) {
                        if (simSlotsKey === value) {
                            setSimSlotsObj({
                                ...simSlotsObj,
                                [simSlotsKey]: true
                            });
                        }
                    } else {
                        if (simSlotsKey === value) {
                            setSimSlotsObj({
                                ...simSlotsObj,
                                [simSlotsKey]: false
                            });
                        }
                    }
                }
                break;
            // case "STOCK":
            //     for (let stockKey in stockObj) {
            //         if (checked) {
            //             if (stockKey === value) {
            //                 setStockObj({
            //                     ...stockObj,
            //                     [stockKey]: true
            //                 });
            //             }
            //         } else {
            //             if (stockKey === value) {
            //                 setStockObj({
            //                     ...stockObj,
            //                     [stockKey]: false
            //                 });
            //             }
            //         }
            //     }
            //     break;

            default:
                break;
        }
    };

    const SortProducts = (event) => {
        const sortType = event.target.value;
        if (sortType === "relevance") {
            const sortedProductData = productsData;
            setFilteredProductData(sortedProductData);
        }
        else {
            const sortedProductData = [...filteredProductData];
            if (sortType === "price_asc") {
                sortedProductData.sort((a, b) => a.price - b.price);
            } else if (sortType === "price_desc") {
                sortedProductData.sort((a, b) => b.price - a.price);
            } else if (sortType === "name_asc") {
                sortedProductData.sort((a, b) => ((a.brand + a.name).localeCompare(b.brand + b.name)));
            }
            setFilteredProductData(sortedProductData);
        }
    };

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        if (string.length === 0) {
            setFilteredProductData(productsData);
        }
    };

    const handleOnSelect = (item) => {
        // the item selected
        setFilteredProductData(productsData.filter((product) => product.name === item.name));
    };

    const handleOnFocus = () => {

    };

    const formatResult = (item) => {
        return (item.brand + ' ' + item.name);
    };
    //#endregion

    //#region checkboxes
    const callCheckboxData = (label, data, index) => {
        return (
            <Row key={index}>
                <Col className="col-sm-2">
                    <input
                        type="checkbox"
                        id={data}
                        name={data}
                        value={data}
                        onClick={(event) => event.stopPropagation()} // Stop propagation on click event
                        onChange={(event) => onChangeHandler(event, label)}
                    />
                </Col>
                <Col className="col-sm-10">
                    <label className="filter-checkbox-label" htmlFor={data}>{data}</label>
                </Col>
            </Row>
        );
    };

    const callFilterCheckboxData = (label, data, index) => {
        return (
            <Row key={index}>
                <Col className="col-sm-2">
                    <input
                        type="checkbox"
                        id={data.value}
                        name={data.value}
                        value={data.value}
                        onClick={(event) => event.stopPropagation()} // Stop propagation on click event
                        onChange={(event) => onChangeFiltersHandler(event, label)}
                    />
                </Col>
                <Col className="col-sm-10">
                    <label className="filter-checkbox-label" htmlFor={data.value}>{data.value}</label>
                </Col>
            </Row>
        );
    };

    const callPriceRange = (value) => {
        const min = value[0];
        const max = value[1];
        setPriceRangeObj([min, max]);
    };

    const callBrand = () => {
        const label = "BRAND";
        return toArrayKeys(brandObj).map((data, index) =>
            callCheckboxData(label, data, index)
        );
    };

    const callOs = () => {
        const label = "OS";
        return toArrayKeys(osObj).map((data, index) =>
            callCheckboxData(label, data, index)
        );
    };

    const callVersionOs = () => {
        const label = "VERSION_OS";
        return toArrayKeys(versionOsObj).map((data, index) =>
            callCheckboxData(label, data, index)
        );
    };

    const callSimSlots = () => {
        const label = "SIM_SLOTS";
        return toArrayKeys(simSlotsObj).map((data, index) =>
            callCheckboxData(label, data, index)
        );
    };
    //#endregion

    const onHandleCategorySelectChange = (value) => {
        setCategorie(categories.filter(category => category.id === value)[0])
    }

    function togglePriceOpen() {
        setPriceOpen(!priceOpen);
    }

    function toggleBrandOpen() {
        setBrandOpen(!brandOpen);
    }

    function toggleOsOpen() {
        setOsOpen(!osOpen);
    }

    function toggleVersionOsOpen() {
        setVersionOsOpen(!versionOsOpen);
    }

    function toggleSimSlotsOpen() {
        setSimSlotsOpen(!simSlotsOpen);
    }

    function DownloadCatalogue() {
        // Liste des champs que vous voulez inclure dans le catalogue Excel
        const fieldsToInclude = ['manufacturer', 'brand', 'model', 'name', 'device_image', 'price'];

        // Charger la bibliothèque xlsx
        const XLSX = require('xlsx');

        // Filtrer les données pour inclure uniquement les champs spécifiés
        const filteredData = filteredProductData.map(item => {
            const filteredItem = {};
            for (const field of fieldsToInclude) {
                filteredItem[field] = item[field];
            }
            return filteredItem;
        });

        // Créer un nouveau classeur
        const wb = XLSX.utils.book_new();

        // Convertir les données filtrées en un tableau de feuilles de calcul
        const ws = XLSX.utils.json_to_sheet(filteredData);

        // Ajouter la feuille de calcul au classeur
        XLSX.utils.book_append_sheet(wb, ws, "Produits");

        // Générer un fichier Excel binaire à partir du classeur
        const excelFile = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        // Convertir le fichier Excel binaire en un objet Blob
        const excelBlob = new Blob([s2ab(excelFile)], { type: 'application/octet-stream' });

        // Créer un lien de téléchargement pour le fichier Excel
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(excelBlob);
        downloadLink.download = `catalogue_${filteredProductData[0].categorie_odoo}.xlsx`;
        downloadLink.click();
    }

    // Fonction pour convertir une chaîne binaire en tableau d'octets
    function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }

    const callFilterGenerator = (filter) => {
        let label = filter.name;
        let filters_to_display = filters.filter(f => f.name === filter.name);

        return filters_to_display.map((data, index) =>
            callFilterCheckboxData(label, data, index)
        );
    }

    function toggleSpecOpen(index) {
        let _specOpen = [...specOpen];
        _specOpen[index] = !_specOpen[index];
        setSpecOpen([..._specOpen]);
    }

    return (
        <>
            {loading ? <Loader /> :
                <div className='products-catalogue'>
                    <Row className='products-main-row'>
                        <Col md={3}>
                            <ListGroup className="product-filters">
                                <ListGroup.Item onClick={togglePriceOpen}>
                                    <Row>
                                        <Col md={8}>
                                            <h5>{t('Price')}</h5>
                                        </Col>
                                        <Col md={4}>
                                            <i className={`fa-solid fa-chevron-right ${priceOpen ? 'rotate-90' : ''}`}></i>
                                        </Col>
                                    </Row>
                                    <Row className={`${!priceOpen ? 'filter-collapsed' : 'filter-expanded'}`}>
                                        {
                                            <div className="range-slider-block">
                                                <Row>
                                                    <Col className="col-sm-6">
                                                        <div className="priceRangeMin">{priceRangeObj[0] ? priceRangeObj[0] : priceRange[0]} €</div>
                                                    </Col>
                                                    <Col className="col-sm-6">
                                                        <div className="priceRangeMax">{priceRangeObj[1] ? priceRangeObj[1] : priceRange[1]} €</div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <RangeSlider
                                                        className="range-slider"
                                                        min={priceRange[0]}
                                                        max={priceRange[1]}
                                                        step={1}
                                                        value={[priceRangeObj[0], priceRangeObj[1]]}
                                                        onInput={value => callPriceRange(value)}
                                                    />
                                                </Row>
                                            </div>
                                        }
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item className='filter-brand' onClick={toggleBrandOpen}>
                                    <Row>
                                        <Col md={8}>
                                            <h5>{t('brand')}</h5>
                                        </Col>
                                        <Col md={4}>
                                            <i className={`fa-solid fa-chevron-right ${brandOpen ? 'rotate-90' : ''}`}></i>
                                        </Col>
                                    </Row>
                                    <Row className={`${!brandOpen ? 'filter-collapsed' : 'filter-expanded'}`}>
                                        {callBrand()}
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item className="filter-os" onClick={toggleOsOpen}>
                                    <Row>
                                        <Col md={8}>
                                            <h5>OS</h5>
                                        </Col>
                                        <Col md={4}>
                                            <i className={`fa-solid fa-chevron-right ${osOpen ? 'rotate-90' : ''}`}></i>
                                        </Col>
                                    </Row>
                                    <Row className={`${!osOpen ? 'filter-collapsed' : 'filter-expanded'}`}>
                                        {callOs()}
                                    </Row>
                                </ListGroup.Item>
                                {
                                    filters && filters.length > 0 ?
                                        removeDuplicatesByProperties(filters, 'name').map((filter, index) => {
                                            return (
                                                <ListGroup.Item className='filter-brand' onClick={() => toggleSpecOpen(index)}>
                                                    <Row>
                                                        <Col md={8}>
                                                            <h5>{filter.name}</h5>
                                                        </Col>
                                                        <Col md={4}>
                                                            <i className={`fa-solid fa-chevron-right ${specOpen[index] ? 'rotate-90' : ''}`}></i>
                                                        </Col>
                                                    </Row>
                                                    <Row className={`${!specOpen[index] ? 'filter-collapsed' : 'filter-expanded'}`}>
                                                        {callFilterGenerator(filter)}
                                                    </Row>
                                                </ListGroup.Item>
                                            )
                                        }) : null
                                }
                                {/* <ListGroup.Item className="filter-version-os" onClick={toggleVersionOsOpen}>
                                    <Row>
                                        <Col md={8}>
                                            <h5>{t('OS version')}</h5>
                                        </Col>
                                        <Col md={4}>
                                            <i className={`fa-solid fa-chevron-right ${versionOsOpen ? 'rotate-90' : ''}`}></i>
                                        </Col>
                                    </Row>
                                    <Row className={`${!versionOsOpen ? 'filter-collapsed' : 'filter-expanded'}`}>
                                        {callVersionOs()}
                                    </Row>
                                </ListGroup.Item> */}
                                {/* <ListGroup.Item className="filter-nb-sim" onClick={toggleSimSlotsOpen}>
                                    <Row>
                                        <Col md={8}>
                                            <h5>{t('number of sims')}</h5>
                                        </Col>
                                        <Col md={4}>
                                            <i className={`fa-solid fa-chevron-right ${simSlotsOpen ? 'rotate-90' : ''}`}></i>
                                        </Col>
                                    </Row>
                                    <Row className={`${!simSlotsOpen ? 'filter-collapsed' : 'filter-expanded'}`}>
                                        {callSimSlots()}
                                    </Row>
                                </ListGroup.Item> */}
                            </ListGroup>
                        </Col>
                        <Col md={9}>
                            <Row className="sort-row">
                                <Col md={4}>
                                    <ReactSearchAutocomplete
                                        items={removeDuplicatesByProperties(filteredProductData.length > 0 ? filteredProductData : productsData, 'name')}
                                        fuseOptions={{ keys: searchKeys }}
                                        onSearch={handleOnSearch}
                                        onSelect={handleOnSelect}
                                        onFocus={handleOnFocus}
                                        resultStringKeyName="name"
                                        onClear={() => { setFilteredProductData(productsData); }}
                                        autoFocus
                                        maxResults={8}
                                        formatResult={formatResult}
                                        threshold={0.3} // 0 = exact match, 1 = everything matches
                                        ignoreLocation={true}
                                        styling={{
                                            height: "20%",
                                            border: "1px solid #dfe1e5",
                                            borderRadius: "5px",
                                            backgroundColor: "white",
                                            boxShadow: "rgba(58, 53, 53, 0.2) 0px 1px 6px 0px",
                                            hoverBackgroundColor: "#eee",
                                            color: "#212121",
                                            fontSize: "16px",
                                            fontFamily: "Arial",
                                            iconColor: "grey",
                                            lineColor: "rgb(232, 234, 237)",
                                            placeholderColor: "grey",
                                            clearIconMargin: '0 5px 0 0',
                                            searchIconMargin: '0 0 0 5px',
                                            zIndex: 2,
                                        }}
                                    />
                                </Col>
                                <Col md={{ span: 2, offset: 1 }}>
                                    <h3 className="sort-title">{t('Sort by')} :</h3>
                                </Col>
                                <Col md={3}>
                                    <select className="form-select" onChange={(event) => SortProducts(event)}>
                                        <option value="relevance">{t('Relevance')}</option>
                                        <option value="price_asc">{t('Ascending price')} 🔺</option>
                                        <option value="price_desc">{t('Decreasing price')} 🔻</option>
                                        <option value="name_asc">{t('Name')} A-Z 🔺</option>
                                        <option value="name_desc">{t('Name')} Z-A 🔻</option>
                                    </select>
                                </Col>
                                <Col md={1}>
                                    <button
                                        className='products-squared-btn products-excel-btn'
                                        onClick={DownloadCatalogue}><i data-tip="React-tooltip" data-for={'tooltip'}
                                            class="fa-solid fa-file-excel fa-2x"></i></button>
                                    <ReactTooltip id={'tooltip'}
                                        type="dark"
                                        place="bottom"
                                        effect="solid"
                                        clickable={true}
                                        getContent={function () {
                                            return (
                                                <div>
                                                    <Row>{downloadCatalog}</Row>
                                                </div>
                                            )
                                        }} />
                                </Col>
                            </Row>
                            {
                                filteredProductData && filteredProductData.length > 0 ?
                                    <Row className="product-row">
                                        {(filteredProductData).map((product, index) => (
                                            <Col className='product-col' key={product._id} xs={12} sm={6} md={4} lg={3}>
                                                <Product product={product} />
                                            </Col>
                                        ))}
                                    </Row>
                                    : null
                            }
                        </Col>
                    </Row>
                </div>
            }
        </>
    )
}

export default Products