import {
    CART_ADD_ITEM,
    CART_UPDATE_ITEM_QTY,
    CART_ADD_NEW_ORDER_ITEM,
    CART_REMOVE_ITEM,
    CART_SAVE_SHIPPING_ADDRESS,
    CART_SAVE_PAYMENT_METHOD,
    CART_SHIPPING_PRICE,
    CART_EMPTY
} from "../constants/cartConstants";


export const cartReducer = (state = { cartItems: [], shippingAddress: {}, paymentMethod: {}, shippingPrice: {} }, action) => {
    switch (action.type) {
        case CART_ADD_ITEM:
            const item = action.payload;

            // Trouver l'élément existant dans le panier
            const existItem = state.cartItems.find((x) => x.product.id === item.product.id);

            if (existItem) {
                // Si l'élément existe déjà, créer/mettre à jour la propriété "features"
                existItem.features = existItem.features || []; // créer un tableau vide si "features" n'existe pas
                existItem.features.push({
                    qty: item.qty,
                    selectedFeatures: item.selectedFeatures,
                });
                existItem.qty = +item.qty + +existItem.qty

                return {
                    ...state,
                    cartItems: state.cartItems.map((x) =>
                        x.product.id === existItem.product.id ? existItem : x
                    ),
                    success: true,
                };
            } else {
                // Si l'élément n'existe pas encore, ajouter la propriété "features"
                item.features = [{
                    qty: item.qty,
                    selectedFeatures: item.selectedFeatures,
                }];

                return { ...state, cartItems: [...state.cartItems, item], success: false };
            }
        case CART_UPDATE_ITEM_QTY:
            const updatedCartItems = action.payload;
            return {
                ...state,
                cartItems: updatedCartItems.cartItems,
            };
        case CART_ADD_NEW_ORDER_ITEM:
            const items = action.payload;
            const newItems = items.orders.filter(item => !state.cartItems.some(cartItem => cartItem.product === item.product));
            if (newItems.length > 0) {
                return {
                    ...state,
                    cartItems: state.cartItems.concat(newItems),
                    success: true,
                };
            }
            else
                return { ...state, success: false, };
        case CART_REMOVE_ITEM:
            return {
                ...state,
                cartItems: state.cartItems.filter((x) => !(x.product.id === action.payload['id'] && x.is_imei === action.payload['is_imei'])),
            };
        case CART_SAVE_SHIPPING_ADDRESS:
            return { ...state, shippingAddress: action.payload };
        case CART_SAVE_PAYMENT_METHOD:
            return { ...state, paymentMethod: action.payload };
        case CART_SHIPPING_PRICE:
            return { ...state, shippingPrice: action.payload };
        case CART_EMPTY:
            return { ...state, cartItems: [] };
        default:
            return state;
    }
};

