import React from 'react';
import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

const UserEntity = ({ history }) => {
  const HighlightedTableRow = styled(TableRow)({
    backgroundColor: '#CCFAD2', // Couleur de fond de votre choix pour mettre en valeur la ligne
  });
  
  const { t, i18n } = useTranslation();

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{t('Entities information')}</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('Site')}</TableCell>
                <TableCell>{('Arrival date')}</TableCell>
                <TableCell>{t('Date of departure')}</TableCell>
                <TableCell>{t('Updated by')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((h, index) => (
                h === history[history.length - 1] ?
                <HighlightedTableRow key={index}>
                  <TableCell>{h.new_value ? h.new_value : null}</TableCell>
                  <TableCell>{h.created_at ? new Date(h.created_at).toLocaleDateString("fr-FR") : null}</TableCell>
                  <TableCell>{history[index + 1] ? new Date(history[index + 1].created_at).toLocaleDateString("fr-FR") : null}</TableCell>
                  <TableCell>{h.updated_by ? h.updated_by.name : null}</TableCell>
                </HighlightedTableRow>
                :
                <TableRow key={index}>
                  <TableCell>{h.new_value ? h.new_value : null}</TableCell>
                  <TableCell>{h.created_at ? new Date(h.created_at).toLocaleDateString("fr-FR") : null}</TableCell>
                  <TableCell>{history[index + 1] ? new Date(history[index + 1].created_at).toLocaleDateString("fr-FR") : null}</TableCell>
                  <TableCell>{h.updated_by ? h.updated_by.name : null}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default UserEntity;

