export const USER_SELLER_LIST_REQUEST = 'USER_SELLER_LIST_REQUEST'
export const USER_SELLER_LIST_SUCCESS = 'USER_SELLER_LIST_SUCCESS'
export const USER_SELLER_LIST_FAIL = 'USER_SELLER_LIST_FAIL'

export const USER_SELLER_GET_REQUEST = 'USER_SELLER_GET_REQUEST'
export const USER_SELLER_GET_SUCCESS = 'USER_SELLER_GET_SUCCESS'
export const USER_SELLER_GET_FAIL = 'USER_SELLER_GET_FAIL'

export const USER_SELLER_MAJ_REQUEST = 'USER_SELLER_MAJ_REQUEST'
export const USER_SELLER_MAJ_SUCCESS = 'USER_SELLER_MAJ_SUCCESS'
export const USER_SELLER_MAJ_FAIL = 'USER_SELLER_MAJ_FAIL'
export const USER_SELLER_MAJ_RESET = 'USER_SELLER_MAJ_RESET'