import axios from 'axios';
import {
    CART_ADD_ITEM,
    CART_UPDATE_ITEM_QTY,
    CART_ADD_NEW_ORDER_ITEM,
    CART_REMOVE_ITEM,
    CART_SAVE_SHIPPING_ADDRESS,
    CART_SAVE_PAYMENT_METHOD,
    CART_SHIPPING_PRICE,
    CART_EMPTY
} from "../constants/cartConstants";


export const addToCart = (product, is_imei, selectedFeatures, qty) => async (dispatch, getState) => {
    dispatch({
        type: CART_ADD_ITEM,
        payload: {
            product: product,
            qty,
            is_imei,
            selectedFeatures,
        },
    });
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
}

export const updateCartItemQty = (id, is_imei, qty) => async (dispatch, getState) => {
    const updatedCartItems = getState().cart.cartItems.map((item) =>
        item.product.id === id && item.is_imei === is_imei ? { ...item, qty } : item
    );
    dispatch({
        type: CART_UPDATE_ITEM_QTY,
        payload: {
            cartItems: updatedCartItems,
        },
    });
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

export const addNewOrderToCart = (orders) => async (dispatch, getState) => {
    dispatch({
        type: CART_ADD_NEW_ORDER_ITEM,
        payload: {
            orders: orders,
        },
    });
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
}


export const removeFromCart = (id, is_imei) => (dispatch, getState) => {
    dispatch({
        type: CART_REMOVE_ITEM,
        payload: { id: id, is_imei: is_imei },
    });
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
}

export const saveShippingAddress = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_SHIPPING_ADDRESS,
        payload: data,
    });
    localStorage.setItem('shippingAddress', JSON.stringify(data));
}

export const saveShippingPrice = (data) => (dispatch) => {
    dispatch({
        type: CART_SHIPPING_PRICE,
        payload: data,
    });
    localStorage.setItem('shippingPrice', JSON.stringify(data));
}

export const savePaymentMethod = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_PAYMENT_METHOD,
        payload: data,
    });
    localStorage.setItem('paymentMethod', JSON.stringify(data));
}

export const emptyCart = () => (dispatch, getState) => {
    dispatch({
        type: CART_EMPTY,
    });
    localStorage.removeItem('cartItems');
};