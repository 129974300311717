import { findVal } from './utils';

export const getEtablissementAdresses = (object, key = "etablissement") => {
    var etablissements = findVal(object, key);
    var adresses = [];
    etablissements.forEach((etablissement) => {
        var adresse = {
            "id": etablissement.id,
            "numVoie": etablissement.numVoie,
            "voie": etablissement.voie,
            "codePostal": etablissement.codePostal,
            "ville": etablissement.ville,
            "pays": etablissement.pays,
        }
        adresses.push(adresse);
    })
    return adresses;
};

export const getEtablissementAdressesForSelect = (object, key = "etablissement") => {
    var etablissements = findVal(object, key);
    var adresses = [];
    etablissements.forEach((etablissement) => {
        var adresse = {
            "id": etablissement.id,
            "numVoie": etablissement.numVoie,
            "voie": etablissement.voie,
            "codePostal": etablissement.codePostal,
            "ville": etablissement.ville,
            "pays": etablissement.pays,
        }
        adresses.push({
            value: adresse.id, label: adresse.numVoie + ' ' +
                adresse.voie + ' ' + adresse.codePostal + ' ' + adresse.ville + ' - ' +
                adresse.pays
        });
    })
    return adresses;
};

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
}