import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
    ProductListReducer,
    ProductDetailsReducer,
    ProductByImeiFromAPIReducer,
    productsFromCircleReducer,
    productCreateReducer,
    productInDbCircleReducer,
    productFromCircleByImeiReducer,
    productFromCircleByTacReducer,
    productUpdateReducer,
    productDeleteReducer,
    productNonImeiListReducer,
    ProductNonImeiDetailsReducer,
    productNonImeiCreateReducer,
    productNonImeiUpdateReducer,
    productNonImeiDeleteReducer,
    productCreateInOdooReducer,
} from './reducers/productReducers';
import { cartReducer } from './reducers/cartReducers';
import {
    userLoginReducer,
    userRegisterReducer,
    userDetailsReducer,
    userUpdateProfileReducer,
    userListReducer,
    userByIdReducer,
    userRefreshReducer,
} from './reducers/userReducers';
import { OrderCreateReducer, OrderListReducer, OrderDetailsReducer, OrderPayReducer, OrderBCListReducer, OrderBCCreateReducer } from './reducers/orderReducers';
import { QuotationListReducer } from './reducers/quotationReducers';
import {
    FleetListReducer,
    FleetGetReducer,
    FleetCreateReducer,
    FleetUpdateReducer,
    FleetDeleteReducer,
    FleetGetProductsReducer,
    FleetGlobalImportReducer
} from './reducers/fleetReducers';
import { TicketListReducer, TicketCreateReducer } from './reducers/ticketReducers';
import { BannerListReducer } from './reducers/bannerReducers';
import { CategoriesListReducer, CategoriesDeleteReducer, CategoriesCreateReducer, CategoriesUpdateReducer } from './reducers/categoriesReducers';
import { BrandListReducer } from './reducers/brandReducers';
import {
    afterSaleReducer,
    afterSaleConfigReducer,
    afterSaleConfigAddReducer,
    afterSaleConfigUpdateReducer,
    afterSaleConfigDeleteReducer,
    typeAfterSaleConfigReducer,
    typeAfterSaleConfigAddReducer,
    typeAfterSaleConfigUpdateReducer,
    typeAfterSaleConfigDeleteReducer
} from './reducers/afterSaleReducers';
import { stockReducer, getAllStockReducer } from './reducers/stockReducers';
import { featureListReducer, allFeaturesReducer, featureFilterReducer, featureMajReducer, featureAddReducer, featureDeleteReducer } from './reducers/featureReducers';
import { warrantyByIdReducer, warrantyAddReducer, warrantyListReducer, warrantyGetByImeiReducer } from './reducers/warrantyReducers';
import { imageListReducer, imageUploadReducer, imageNonImeiListReducer, imageAllNonImeiListReducer } from './reducers/imageReducers';
import {
    defaultImageCreateReducer,
    defaultImageUpdateReducer,
    defaultImageListReducer,
    defaultNonImeiImageListReducer,
    defaultNonImeiImageCreateReducer,
    defaultNonImeiImageUpdateReducer,
    defaultAllNonImeiImageListReducer
} from './reducers/defaultImagesReducers';
import { countryReducer } from './reducers/countryReducers';
import {
    contactOdooCreateReducer,
    contactByOdooIdReducer,
    contactOdooListReducer,
    contactOdooUpdateReducer,
    contactOdooDeleteReducer,
    contactOdooGetReducer
} from './reducers/contactOdooReducers';
import { documentsReducer } from './reducers/documentReducers';
import { wbListReducer, wbListCreateReducer } from './reducers/wblistReducers';
import { ImeiProductReducer, ImeiProductsReducer } from './reducers/imeiReducers';
import {
    messageListReducer,
    messageCreateReducer,
    messageUpdateReducer,
    messageDeleteReducer,
    messageAsReadReducer,
    messageAsUnreadCountReducer,
    sendMailReducer
} from './reducers/messageReducers';
import {
    conversationListReducer,
    conversationAddReducer,
    conversationReceiversReducer,
    conversationDelReducer
} from './reducers/conversationReducers';
import { configListReducer, configGetReducer, configAddReducer } from './reducers/configReducers';
import { historyReducer, historyAllReducer, deletedImeisReducer } from './reducers/historyReducers';
import { headerBrandOrderReducer, headerBrandOrderAddReducer, headerBrandOrderDelReducer } from './reducers/headerBrandOrderReducers';
import { paymentReducer } from './reducers/paymentReducers';
import { 
    fleetUsersListReducer, 
    fleetUsersAddReducer, 
    fleetUsersAddByFileReducer,
    fleetUsersUpdateReducer, 
    fleetUsersDeleteReducer } from './reducers/fleetUsersReducers';

import { simListReducer, simAddReducer, pukAddReducer, simUpdateReducer, simDeleteReducer } from './reducers/simReducers';

import { 
    fleetEntityListReducer,
    fleetEntityGetReducer,
    fleetEntityAddReducer,
    fleetEntityAddByFileReducer,
    fleetEntityUpdateReducer,
    fleetEntityDeleteReducer
} from './reducers/fleetEntityReducers';

import { 
    headerCategoriesListReducer, 
    headerCategorieCreateReducer,  
    headerCategorieUpdateReducer,
    headerCategorieDeleteReducer
} from './reducers/headerCategoriesReducers';

import { PurchaseListReducer, PurchaseAddReducer, PurchaseUpdateReducer, PurchaseDeleteReducer } from './reducers/purchaseReducers';
import { skuListReducer, skuAddReducer, skuCheckReducer, skuDeleteReducer } from './reducers/skuReducers';
import { ClientListReducer, impersonatedReducer, impersonateRevertReducer } from './reducers/clientReducers';

import { userSellerListReducer, userSellerGetReducer, userSellerMajReducer } from './reducers/userSellerReducers';
import { rentalRateMajReducer, rentalRateDeleteReducer } from './reducers/rentalRateReducers';
import { accessoriesListReducer, accessoriesMajReducer } from './reducers/accessoriesReducers';

const reducer = combineReducers({
    accessoriesList: accessoriesListReducer,
    accessoriesMaj: accessoriesMajReducer,
    afterSale: afterSaleReducer,
    afterSaleConfigList: afterSaleConfigReducer,
    afterSaleConfigAdd: afterSaleConfigAddReducer,
    afterSaleConfigUpdate: afterSaleConfigUpdateReducer,
    afterSaleConfigDelete: afterSaleConfigDeleteReducer,
    allFeaturesList: allFeaturesReducer,
    allStock: getAllStockReducer,

    bannerList: BannerListReducer,
    brandList: BrandListReducer,

    cart: cartReducer,
    categoriesList: CategoriesListReducer,
    categoriesDelete: CategoriesDeleteReducer,
    categoriesCreate: CategoriesCreateReducer,
    categoriesUpdate: CategoriesUpdateReducer,
    clientList: ClientListReducer,
    configAdd: configAddReducer,
    configGet: configGetReducer,
    configList: configListReducer,
    contactByOdooId: contactByOdooIdReducer,
    contactOdooCreate: contactOdooCreateReducer,
    contactOdooDelete: contactOdooDeleteReducer,
    contactOdooGet: contactOdooGetReducer,
    contactOdooList: contactOdooListReducer,
    contactOdooUpdate: contactOdooUpdateReducer,
    conversationAdd: conversationAddReducer,
    conversationDel: conversationDelReducer,
    conversationsList: conversationListReducer,
    conversationsReceivers: conversationReceiversReducer,
    countryList: countryReducer,
    createdMessage: messageCreateReducer,

    defaultAllNonImeiImageList: defaultAllNonImeiImageListReducer,
    defaultImageCreate: defaultImageCreateReducer,
    defaultImageList: defaultImageListReducer,
    defaultImageUpdate: defaultImageUpdateReducer,
    defaultNonImeiImageCreate: defaultNonImeiImageCreateReducer,
    defaultNonImeiImageList: defaultNonImeiImageListReducer,
    defaultNonImeiImageUpdate: defaultNonImeiImageUpdateReducer,
    deletedImeis: deletedImeisReducer,
    deletedMessage: messageDeleteReducer,
    documentList: documentsReducer,

    featureAdd: featureAddReducer,
    featureFilter: featureFilterReducer,
    featureMaj: featureMajReducer,
    featureDelete: featureDeleteReducer,
    featureList: featureListReducer,
    fleetCreate: FleetCreateReducer,
    fleetEntityList: fleetEntityListReducer,
    fleetEntityGet: fleetEntityGetReducer,
    fleetEntityAdd: fleetEntityAddReducer,
    fleetEntityAddByFile: fleetEntityAddByFileReducer,
    fleetEntityUpdate: fleetEntityUpdateReducer,
    fleetEntityDelete: fleetEntityDeleteReducer,
    fleetUpdate: FleetUpdateReducer,
    fleetList: FleetListReducer,
    FleetGlobalImport: FleetGlobalImportReducer,
    fleetGet: FleetGetReducer,
    fleetDelete: FleetDeleteReducer,
    fleetGetProducts: FleetGetProductsReducer,
    fleetUsersAdd: fleetUsersAddReducer,
    fleetUsersAddByFile: fleetUsersAddByFileReducer,
    fleetUsersDelete: fleetUsersDeleteReducer,
    fleetUsersList: fleetUsersListReducer,
    fleetUsersUpdate: fleetUsersUpdateReducer,

    getHistoryList: historyReducer,
    getAllHistoryList: historyAllReducer,

    headerBrandOrder: headerBrandOrderReducer,
    headerBrandOrderAdd: headerBrandOrderAddReducer,
    headerBrandOrderDel: headerBrandOrderDelReducer,
    headerCategoriesList: headerCategoriesListReducer,
    headerCategorieCreate: headerCategorieCreateReducer,
    headerCategorieUpdate: headerCategorieUpdateReducer,
    headerCategorieDelete: headerCategorieDeleteReducer,

    imageAllNonImeiList: imageAllNonImeiListReducer,
    imageList: imageListReducer,
    imageNonImeiList: imageNonImeiListReducer,
    imageUpload: imageUploadReducer,
    imeiProduct: ImeiProductReducer,
    imeiProducts: ImeiProductsReducer,
    impersonated: impersonatedReducer,
    impersonateRevert: impersonateRevertReducer,

    listMessages: messageListReducer,

    markedAsRead: messageAsReadReducer,
    markedAsUnreadCount: messageAsUnreadCountReducer,

    odooStock: stockReducer,
    orderBCCreate: OrderBCCreateReducer,
    orderBCList: OrderBCListReducer,
    orderCreate: OrderCreateReducer,
    orderDetails: OrderDetailsReducer,
    orderList: OrderListReducer,
    orderPay: OrderPayReducer,

    paymentResponse: paymentReducer,
    productByImei: ProductByImeiFromAPIReducer,
    productCreated: productCreateReducer,
    productCreateInOdoo: productCreateInOdooReducer,
    productDeleted: productDeleteReducer,
    productDetails: ProductDetailsReducer,
    productFromCircleByImei: productFromCircleByImeiReducer,
    productFromCircleByTac: productFromCircleByTacReducer,
    productInDbCircle: productInDbCircleReducer,
    productList: ProductListReducer,
    productUpdated: productUpdateReducer,
    productsFromCircle: productsFromCircleReducer,
    productNonImeiCreate: productNonImeiCreateReducer,
    productNonImeiDelete: productNonImeiDeleteReducer,
    productNonImeiList: productNonImeiListReducer,
    productNonImeiDetails: ProductNonImeiDetailsReducer,
    productNonImeiUpdate: productNonImeiUpdateReducer,
    pukAdd: pukAddReducer,
    purchaseList: PurchaseListReducer,
    purchaseAdd: PurchaseAddReducer,
    purchaseUpdate: PurchaseUpdateReducer,
    purchaseDelete: PurchaseDeleteReducer,

    quotationList: QuotationListReducer,

    rentalRateMaj: rentalRateMajReducer,
    rentalRateDelete: rentalRateDeleteReducer,

    mailSend: sendMailReducer,
    simAdd: simAddReducer,
    simUpdate: simUpdateReducer,
    simDelete: simDeleteReducer,
    simList: simListReducer,
    skuList: skuListReducer,
    skuAdd: skuAddReducer,
    skuDelete: skuDeleteReducer,
    skuCheck: skuCheckReducer,

    ticketList: TicketListReducer,
    ticketCreate: TicketCreateReducer,
    typeAfterSaleConfigList: typeAfterSaleConfigReducer,
    typeAfterSaleConfigAdd: typeAfterSaleConfigAddReducer,
    typeAfterSaleConfigUpdate: typeAfterSaleConfigUpdateReducer,
    typeAfterSaleConfigDelete: typeAfterSaleConfigDeleteReducer,

    updatedMessage: messageUpdateReducer,
    userById: userByIdReducer,
    userDetails: userDetailsReducer,
    userList: userListReducer,
    userLogin: userLoginReducer,
    userRefresh: userRefreshReducer,
    userRegister: userRegisterReducer,
    userSellerList: userSellerListReducer,
    userSellerGet: userSellerGetReducer,
    userSellerMaj: userSellerMajReducer,
    userUpdateProfile: userUpdateProfileReducer,

    warrantyAll: warrantyListReducer, //
    warrantyGet: warrantyByIdReducer,
    warrantyGetByImei: warrantyGetByImeiReducer,
    warrantyAdd: warrantyAddReducer,
    wBList: wbListReducer,
    wBListCreate: wbListCreateReducer
});

const cartItemsFromStorage = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [];

const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

const shippingAddressFromStorage = localStorage.getItem('shippingAddress') ? JSON.parse(localStorage.getItem('shippingAddress')) : {};

const shippingPrice = localStorage.getItem('shippingPrice') ? JSON.parse(localStorage.getItem('shippingPrice')) : {};

const paymentMethod = localStorage.getItem('paymentMethod') ? JSON.parse(localStorage.getItem('paymentMethod')) : {};

const item = localStorage.getItem('afterSaleDemand') ? JSON.parse(localStorage.getItem('item')) : {};

const currentStep = localStorage.getItem('afterSaleDemand') ? JSON.parse(localStorage.getItem('currentStep')) : {};

const chosenProtections = localStorage.getItem('afterSaleDemand') ? JSON.parse(localStorage.getItem('chosenProtections')) : [];

const chosenReparations = localStorage.getItem('afterSaleDemand') ? JSON.parse(localStorage.getItem('chosenReparations')) : [];

const initialState = {
    cart: {
        cartItems: cartItemsFromStorage,
        shippingAddress: shippingAddressFromStorage,
        shippingPrice: shippingPrice,
        paymentMethod: paymentMethod,
    },
    userLogin: { userInfo: userInfoFromStorage },
    afterSale: {
        demand: {
            item: item,
            currentStep: currentStep,
            chosenProtections: chosenProtections,
            chosenReparations: chosenReparations
        }
    },
    productsFromCircle: {
        circleProducts: []
    },
    productCreated: {
        product: {}
    },
    odooStock: {
        stock: {}
    },
    productInDbCircle: {
        productInCircle: {}
    },
    productFromCircleByImei: {
        circleProductByImei: {}
    }
};

const middleware = [thunk];

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;