import {
    PAYMENT_REQUEST,
    PAYMENT_SUCCESS,
    PAYMENT_FAIL,
}
from '../constants/paymentConstants.js'

import axios from 'axios'

export const paymentCharge = (payment, reference) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PAYMENT_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/payment/charge/`,
            {payment: payment, reference: reference},
            )

        dispatch({
            type: PAYMENT_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PAYMENT_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }   
}