import React, { useState, useEffect } from 'react';
import './Loader.css';
import { useTranslation } from 'react-i18next';

function Loader() {
    const { t, i18n } = useTranslation();

    const texts = [t('Loading'), t('A bit of patience'), t('Almost done'), t('A little more time'), t('It\'s taking longer than expected'),];
    const [textIndex, setTextIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setTextIndex((prevTextIndex) => {
                if (prevTextIndex + 1 >= texts.length) {
                    clearInterval(interval);
                    return prevTextIndex;
                } else {
                    return prevTextIndex + 1;
                }
            });
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className="loader-container">
            <div className="loading">{texts[textIndex]}...</div>
            <div className="loader">
                {Array.from({ length: 5 }).map((_, i) => (
                    <div key={i} className={`bubble bubble-${i + 1}`} />
                ))}
            </div>
        </div>
    );
}

export default Loader;
