import {
    IMAGE_LIST_REQUEST,
    IMAGE_LIST_SUCCESS,
    IMAGE_LIST_FAIL,

    IMAGE_UPLOAD_REQUEST,
    IMAGE_UPLOAD_SUCCESS,
    IMAGE_UPLOAD_FAIL,

    IMAGE_UPDATE_REQUEST,
    IMAGE_UPDATE_SUCCESS,
    IMAGE_UPDATE_FAIL,

    IMAGE_DELETE_REQUEST,
    IMAGE_DELETE_SUCCESS,
    IMAGE_DELETE_FAIL,

    IMAGE_NON_IMEI_LIST_REQUEST,
    IMAGE_NON_IMEI_LIST_SUCCESS,
    IMAGE_NON_IMEI_LIST_FAIL,

    IMAGE_ALL_NON_IMEI_LIST_REQUEST,
    IMAGE_ALL_NON_IMEI_LIST_SUCCESS,
    IMAGE_ALL_NON_IMEI_LIST_FAIL
}
from '../constants/imageConstants'

export const imageListReducer = (state = { images: [] }, action) => {
    switch (action.type) {
        case IMAGE_LIST_REQUEST:
            return { loading: true, images: [] }
        case IMAGE_LIST_SUCCESS:
            return { loading: false, images: action.payload }
        case IMAGE_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const imageAllNonImeiListReducer = (state = { imagesNonImei: [] }, action) => {
    switch (action.type) {
        case IMAGE_ALL_NON_IMEI_LIST_REQUEST:
            return { loading: true, images: [] }
        case IMAGE_ALL_NON_IMEI_LIST_SUCCESS:
            return { loading: false, allImagesNonImei: action.payload }
        case IMAGE_ALL_NON_IMEI_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const imageNonImeiListReducer = (state = { imagesNonImei: [] }, action) => {
    switch (action.type) {
        case IMAGE_NON_IMEI_LIST_REQUEST:
            return { loading: true, images: [] }
        case IMAGE_NON_IMEI_LIST_SUCCESS:
            return { loading: false, imagesNonImei: action.payload }
        case IMAGE_NON_IMEI_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const imageUploadReducer = (state = {}, action) => {
    switch (action.type) {
        case IMAGE_UPLOAD_REQUEST:
            return { loading: true }
        case IMAGE_UPLOAD_SUCCESS:
            return { loading: false, success: true, image: action.payload }
        case IMAGE_UPLOAD_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const imageUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case IMAGE_UPDATE_REQUEST:
            return { loading: true }
        case IMAGE_UPDATE_SUCCESS:
            return { loading: false, success: true }
        case IMAGE_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const imageDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case IMAGE_DELETE_REQUEST:
            return { loading: true }
        case IMAGE_DELETE_SUCCESS:
            return { loading: false, success: true }
        case IMAGE_DELETE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}