import { findVal } from './utils';

export const getSavHistory = (object) => {
    var sav_history = object.history.sav
    var sav_history_list = [];
    sav_history.forEach((sav) => {
        var sav_history_item = {
            "ticket_id": sav.ticket_id,
            "start": sav.start,
            "end": sav.end,
            "demandeur": sav.demandeur,
            "due_to": sav.due_to,
            "returned_to": sav.returned_to,
            "returned_method": sav.returned_method,
        }
        sav_history_list.push(sav_history_item);
    })
    return sav_history_list;
}

export const getOrderHistory = (object) => {
    var order = object.history.order
    
    
    var order_history_item = {
        "order_id": order.order_id,
        "purchase_date": order.purchase_date,
        "purchase_user": order.purchase_user,
        "purchase_status": order.purchase_status,
        "purchase_comment": order.purchase_comment,
    }

    return order_history_item;
}

export const getTableHistory = (object) => {
    let histories = object.history;
    let sav = object.sav_history;

    
    
    

    let tableHistory = [];

    if (histories && histories.length > 0) {
        histories.forEach(history => {
            
            
            if (history && (history.add_to_fleet || history.sav_history)) {
                
                if (history && history.add_to_fleet) {
                    tableHistory.push({
                        "operation": "Ajout à ma flotte",
                        "reference": '   ---   ',
                        "demandeur": '   ---   ',
                        "date": new Date(history.add_to_fleet).toLocaleDateString("fr-FR"),
                    })
                }

                if (history && history.sav_history) {
                    tableHistory.push({
                        "operation": "sav",
                        "reference": history.sav_history.name,
                        "demandeur": history.sav_history.partner_id[1],
                        "date": new Date(history.sav_history.create_date).toLocaleDateString("fr-FR"),
                    })
                }

                if (history && history.remove_from_fleet) {
                    tableHistory.push({
                        "operation": "Sortie de ma flotte",
                        "reference": '   ---   ',
                        "demandeur": '   ---   ',
                        "date": new Date(history.remove_from_fleet).toLocaleDateString("fr-FR"),

                    })
                }

                if (history && history.update_fleet_user) {
                    tableHistory.push({
                        "operation": "Changement d'utilisateur",
                        "reference": '   ---   ',
                        "demandeur": '   ---   ',
                        "date": new Date(history.update_fleet_user).toLocaleDateString("fr-FR"),
                    })
                }
            }
        });
    }


    

    return tableHistory;
}


export function getAllNestedKeys(obj, prefix = '') {
    const keys = [];
    for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            const nestedKeys = getAllNestedKeys(obj[key], `${prefix}${key}.`);
            keys.push(...nestedKeys);
        } else {
            keys.push(`${prefix}${key}`);
        }
    }
    return keys;
}