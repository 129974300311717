import axios from 'axios';
import {
    QUOTATION_LIST_REQUEST,
    QUOTATION_LIST_SUCCESS,
    QUOTATION_LIST_FAIL,

    QUOTATION_CREATE_REQUEST,
    QUOTATION_CREATE_SUCCESS,
    QUOTATION_CREATE_FAIL

} from "../constants/quotationConstants";

export const listQuotations = () => async (dispatch, getState) => {
    try {
        dispatch({ type: QUOTATION_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            'api/quotations/'
            )

        dispatch({ type: QUOTATION_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ 
            type: QUOTATION_LIST_FAIL, 
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message })
    }
}

export const createQuotation = (quotation) => async (dispatch, getState) => {
    try {
        dispatch({
            type: QUOTATION_CREATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/quotations/add/`,
            quotation,
            )

        dispatch({
            type: QUOTATION_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: QUOTATION_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}