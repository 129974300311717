import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/Olinn.png'; 
import facebookIcon from '../../assets/images/facebook-icon.svg';
import twitterIcon from '../../assets/images/twitter-icon.png';
import instagramIcon from '../../assets/images/linkedin-icon.svg';
import './Footer.css';

function Footer() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return (
    <footer className={userInfo && userInfo.impersonated_by ? 'impersonated' :
      userInfo && userInfo.isAdmin ? 'admin' : 'client'
    }>
      <Container>
        <Row className='footer-main-row'>
          <Col xs={12} md={4} className="footer-section">
            <p>Olinn Store &copy; 2023</p>
            <ul>
              <li><Link to="/home">Home</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li><Link to="/partners">Partners</Link></li>
              <li><Link to="/message">Messages</Link></li>
            </ul>
          </Col>
          <Col xs={12} md={4} className="footer-section">
            <h5>Suivez-nous</h5>
            <a href="https://www.facebook.com/YourPage">
              <img src={facebookIcon} alt="Facebook" />
            </a>
            <a href="https://www.twitter.com/YourPage">
              <img src={twitterIcon} alt="Twitter" />
            </a>
            <a href="https://www.linkedin.com/YourPage">
              <img src={instagramIcon} alt="Linkedin" />
            </a>
          </Col>
        </Row>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Conditions d'utilisation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Insérez ici votre contenu...</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </footer>
  )
}

export default Footer;
