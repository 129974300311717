import React, { useEffect, useState, useLayoutEffect, useRef, useCallback } from 'react';
import { Row, Col, Form, Image } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux'
import Color from 'color';

import { validImei } from '../../../../../utils/luhnUtils';

import Message from '../../../../../components/Message/Message';
import Loader from '../../../../../components/Loader/Loader';
import Select from '../../../../../components/Select/Select';

import Switch from "react-switch";

import { removeDuplicatesByNestedProperty } from '../../../../../utils/utils';

import { listProducts, createProductInOdoo, ProductByImeiFromAPI, productCreateInOdooReset, checkProductInDbCircle } from '../../../../../actions/productActions';
import { allFeatures, listFeatures, addFeatures, deleteFeature, majIsFilter, majFeatureReset, addFeatureReset } from '../../../../../actions/featureActions';

import "disk-color-picker";

import './ArticleNewValues.css';
import '../Articles.css'

import { useTranslation } from 'react-i18next';

function ArticleNewValues() {
  const ref = useRef(null);
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation();

  const [toggleChecked, setToggleChecked] = useState(false);

  const [rom, setRom] = useState('')
  const [ram, setRam] = useState('')
  const [hexColor, setHexColor] = useState('')
  const [selectedColor, setSelectedColor] = useState('')
  const [newFeatureName, setNewFeatureName] = useState('')
  const [newFeatureValue, setNewFeatureValue] = useState('')
  const [is_filter, setIs_filter] = useState(false)

  const [imei, setImei] = useState('')
  const [_imeiInput, setImeiInput] = useState('')
  const [id_odoo, setId_odoo] = useState(null)

  const [messageVariant, setMessageVariant] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState(false);

  const [feature, setFeature] = useState(null)
  const [selectedFeature, setSelectedFeature] = useState(null)
  const [selectFeatures, setSelectFeatures] = useState([])

  const [productToDisplay, setProductToDisplay] = useState({})

  const productsFromCircle = useSelector(state => state.productsFromCircle)
  const { circleProducts, loading: loadingCircleProducts } = productsFromCircle

  const productsFromApi = useSelector(state => state.productByImei)
  const { productByImei, loading: loadingProductFromApi } = productsFromApi

  const productFeatures = useSelector(state => state.featureList)
  const { features, loading: loadingFeatures } = productFeatures

  const featureAdd = useSelector(state => state.featureAdd)
  const { success: successFeatureAdd, loading: loadingFeatureAdd } = featureAdd

  const featureMaj = useSelector(state => state.featureMaj)
  const { success: successFeatureMaj, loading: loadingFeatureMaj } = featureMaj

  const featureDelete = useSelector(state => state.featureDelete)
  const { success: successFeatureDelete, loading: loadingFeatureDelete } = featureDelete

  const allProductFeatures = useSelector(state => state.allFeaturesList)
  const { featuresList } = allProductFeatures

  const productCreateInOdoo = useSelector((state) => state.productCreateInOdoo);
  const { loading: loadingProductCreateInOdoo, error: errorProductCreateInOdoo, success: successProductCreateInOdoo, product_id_odoo } = productCreateInOdoo;

  useEffect(() => {
    dispatch(allFeatures())
  }, [])

  useLayoutEffect(() => {
    if (ref.current)
      ref.current.addEventListener("change", onColorChange);
  }, [ref]);

  useEffect(() => {
    setErrorMessages()
    setSelectedFeature('')
    setProductToDisplay({})
    if (_imeiInput.length === 15 && validImei(_imeiInput)) {
      dispatch(ProductByImeiFromAPI(_imeiInput))
      dispatch(createProductInOdoo(_imeiInput))
      dispatch(listFeatures(_imeiInput))
    }

  }, [_imeiInput])

  useEffect(() => {
    if (successProductCreateInOdoo) {
      setId_odoo(product_id_odoo.id_odoo)
    }
  }, [successProductCreateInOdoo])

  useEffect(() => {
    if (successProductCreateInOdoo) {
      dispatch(productCreateInOdooReset())
    }
  }, [id_odoo])

  useEffect(() => {
    if (circleProducts && circleProducts.length > 0) {
      setProductToDisplay(circleProducts[0])
    }
  }, [circleProducts])

  useEffect(() => {
    if (productByImei) {
      setProductToDisplay(productByImei)
    }
  }, [productByImei])

  useEffect(() => {
    try {
      if (hexColor != null && hexColor != undefined && hexColor != '') {
        const c = Color(hexColor);
        const hsl = c.hsl();
        const hue = hsl.color[0];
        const saturation = hsl.color[1];
        const lightness = hsl.color[2];
        setSelectedColor(getColorName(hue, saturation, lightness))
      }
      else {
        setSelectedColor(hexColor)
      }
    }
    catch (e) {
      setSelectedColor(hexColor)
    }
  }, [hexColor])

  useEffect(() => {
    if (featuresList) {
      let values = []
      removeDuplicatesByNestedProperty(featuresList, "name").sort((a, b) => a.id - b.id).forEach(feature => {
        values.push({ value: feature.id, label: feature.name })
      })
      setSelectFeatures(values)
    }
  }, [featuresList])

  useEffect(() => {
    if (features && features.length > 0 && selectedFeature && selectedFeature != '') {
      let f = features.find(feature => feature.name === selectedFeature)
      if (f === undefined)
        f = featuresList.filter(feature => feature.name === selectedFeature)[0]

      setIs_filter(f && f != undefined ? f.is_filter : false)
    }
  }, [selectedFeature])

  useEffect(() => {
    if (successFeatureAdd || successFeatureDelete) {
      dispatch(allFeatures())
      dispatch(listFeatures(_imeiInput))
      setNewFeatureValue('')
      dispatch(listProducts());

      if (successFeatureAdd)
        dispatch(addFeatureReset())
    }
  }, [successFeatureAdd, successFeatureDelete])

  useEffect(() => {
    if (successFeatureMaj) {
      dispatch(majFeatureReset())
    }
  }, [successFeatureMaj])

  function setErrorMessages() {
    setError(false)

    if (_imeiInput.length > 15) {
      setMessage('Le numéro IMEI doit comporter 15 chiffres.')
      setMessageVariant('danger')
    }
    else if (_imeiInput.length === 15 && !validImei(_imeiInput)) {
      setMessage('Le numero IMEI ' + _imeiInput + ' n\'est pas valide.')
      setMessageVariant('danger')
    }
    else if (_imeiInput.length === 15 && validImei(_imeiInput)) {
      setMessage('')
    }
    else
      setMessage('')
  }

  function getColorName(hue, saturation, lightness) {
    let colorName;
    if (lightness <= 5) {
      colorName = 'noir';
    }
    else if (lightness >= 98) {
      colorName = 'blanc';
    }
    else if (hue >= 0 && hue < 20 || hue >= 330 && hue <= 360) {
      colorName = 'rouge';
    } else if (hue >= 20 && hue < 45) {
      colorName = 'orange';
    } else if (hue >= 45 && hue < 70) {
      colorName = 'jaune';
    } else if (hue >= 70 && hue < 150) {
      colorName = 'vert';
    } else if (hue >= 150 && hue < 260) {
      colorName = 'bleu';
    } else if (hue >= 260 && hue < 290) {
      colorName = 'violet';
    } else if (hue >= 290 && hue < 330) {
      colorName = 'rose';
    }
    return colorName;
  }

  const submitHandler = (e) => {
    e.preventDefault()
  }

  const onColorChange = (event) => {
    setHexColor(event.target.value);
  };

  const handleToggleChange = (checked) => {
    setToggleChecked(checked);
  };

  const onHandleFeatureSelectChange = useCallback((value, name) => {
    if (featuresList && featuresList.length > 0 && value != '') {
      setSelectedFeature(featuresList.find(feature => feature.id === value).name)
    }
    else {
      setSelectedFeature('')
      setFeature('')
    }

  });

  const removeFeatureHandler = (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette caractéristique ?')) {
      dispatch(deleteFeature(id))
    }
  }

  const setNewFeatureValueHandler = (e) => {
    setNewFeatureValue(e.target.value)
  }

  const setNewFeatureNameHandler = (e) => {
    setNewFeatureName(e.target.value)
  }

  const addFeature = (e) => {
    e.preventDefault()

    let featureName = toggleChecked ? selectedFeature : newFeatureName
    if (_imeiInput && featureName && newFeatureValue && validImei(_imeiInput) && featureName != '' && newFeatureValue != '')
      dispatch(addFeatures(id_odoo, featureName, is_filter, newFeatureValue))
  }

  const MajIsFilter = (checked) => {
    setIs_filter(checked)
    dispatch(majIsFilter(_imeiInput, selectedFeature, checked))
  }

  return (
    <div className='articles-container'>
      <Row className='articles-title-row'>
        <h1>Gestion des caractéristiques</h1>
      </Row>
      <Row className='articles-main-row'>
        <Col md={8}>
          <Form onSubmit={submitHandler}>
            <Form.Group className='form-group-profile' controlId='ROM'>
              <Row className='articles-imei-input'>
                <Col md={6}>
                  <Form.Control
                    type="text"
                    id="imei-input"
                    aria-describedby="passwordHelpBlock"
                    placeholder="Numéro IMEI"
                    value={_imeiInput}
                    onChange={(e) => {
                      e.stopPropagation();
                      setImeiInput(e.target.value)
                    }}
                    ReactTooltip={"Le numéro IMEI doit comporter 15 chiffres."}
                  />
                </Col>
              </Row>
              {
                _imeiInput && validImei(_imeiInput) ?
                  <>
                    <Row className='articles-manage-row'>
                      <Col md={10}>
                        {message && message.length > 0 ? <Message variant={messageVariant}>{message}</Message> : null}
                      </Col>
                    </Row>
                    <Row className='articles-newvalues-features-row'>
                      <Col md={1}>
                        <Switch
                          className='addarticles-switch'
                          onChange={handleToggleChange}
                          checked={toggleChecked}
                          offColor="#2f86ca"
                          boxShadow="0px 5px 10px rgba(0, 0, 0, 0.6)"
                        />
                      </Col>
                      <Col md={2}>
                        <span>{!toggleChecked ? 'Nouveau' : 'Modification'}</span>
                      </Col>
                    </Row>
                    <Row className='articles-newvalues-features-row'>
                      <Col md={6}>
                        {
                          toggleChecked ?
                            <Select
                              className='addnew-values-categories-select'
                              name="addnew-values-categories-select"
                              title="Caractéristiques"
                              value={feature}
                              options={selectFeatures}
                              onChangeFunc={onHandleFeatureSelectChange}
                              isMulti={false}
                              isSearchable={true}
                              isClearable={true}
                            />
                            :
                            <Form.Control
                              required
                              id='addnew-values-featurename-input'
                              type='text'
                              placeholder='Caractéristique'
                              value={newFeatureName}
                              onChange={(e) => setNewFeatureNameHandler(e)}
                            ></Form.Control>
                        }
                      </Col>
                      <Col md={4}>
                        {
                          toggleChecked && selectedFeature && selectedFeature != 'COLOR' ?
                            <>
                              <input
                                id="is-filter-checkbox"
                                className='addnew-values-is-filter-checkbox'
                                type="checkbox"
                                checked={is_filter}
                                onClick={(e) => MajIsFilter(e.target.checked)} // Stop propagation on click event
                              />
                              <label className='addnew-values-is-filter-label' htmlFor="is-filter-checkbox">{t('must be a filter')}</label>
                            </>
                            :
                            null
                        }
                      </Col>
                    </Row>
                    {
                      toggleChecked && features && features.length > 0 && selectedFeature != '' ?
                        features
                          .filter(feature => feature.name === selectedFeature)
                          .map((feature, index) => {
                            return (
                              <Row className='articles-newvalues-features-row'>
                                <Col md={4}>
                                  <Form.Control
                                    type='text'
                                    value={feature.value}
                                    //onChange={(e) => setNewSpecValue(e.target.value)}
                                    autoFocus={false}
                                  />
                                </Col>
                                <Col className='new-values-button-col' md={2}>
                                  <button className='btn-new-values-remove' onClick={() => removeFeatureHandler(feature.id)}><i className="fa-solid fa-trash fa-lg"></i></button>
                                </Col>
                              </Row>
                            )
                          })
                        : null
                    }
                    <Row className='articles-newvalues-features-row' >
                      <Col md={4}>
                        <Form.Control
                          type='text'
                          value={typeof (newFeatureValue) == "string" ? newFeatureValue : ''}
                          onChange={(e) => setNewFeatureValueHandler(e)}
                          autoFocus={false}
                        />
                      </Col>
                      <Col className='new-values-button-col' md={2}>
                        <button className='btn-new-values-add' onClick={(e) => addFeature(e)}><i className="fa-solid fa-add fa-lg"></i></button>
                      </Col>
                    </Row>
                  </>
                  : null
              }

            </Form.Group>
          </Form>
        </Col>
        <Col className='articles-newvalues-detail-col' md={4}>
          {loadingProductFromApi ? <Loader /> :
            productToDisplay && productToDisplay.tac ?
              (
                <>
                  <Row className='articles-detail-title'>
                    {productToDisplay.brand} {productToDisplay.name}
                  </Row>
                  <Row className='articles-newvalues-detail-img-row'>
                    <Image className='articles-newvalues-detail-img' src={productToDisplay.device_image} alt={productToDisplay.name} fluid rounded />
                  </Row>
                  <Row className='articles-detail-rows'>
                    <Row className=''>
                      Marque : {productToDisplay.brand}
                    </Row>
                    <Row className=''>
                      Modèle : {productToDisplay.model}
                    </Row>
                    <Row className=''>
                      IMEI : {_imeiInput}
                    </Row>
                  </Row>
                </>
              )
              : null
          }
        </Col>
      </Row>
    </div >
  )
}

export default ArticleNewValues