import {
    PAYMENT_REQUEST,
    PAYMENT_SUCCESS,
    PAYMENT_FAIL,
}
from '../constants/paymentConstants.js'

export const paymentReducer = (state = { payment: [] }, action) => {
    switch (action.type) {
        case PAYMENT_REQUEST:
            return { loading: true, payment: [] }
        case PAYMENT_SUCCESS:
            return { loading: false, paymentDetails: action.payload, success: true }
        case PAYMENT_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}