import{
    STOCK_REQUEST,
    STOCK_SUCCESS,
    STOCK_FAIL,

    STOCK_ALL_REQUEST,
    STOCK_ALL_SUCCESS,
    STOCK_ALL_FAIL,

    UPDATE_STOCK_REQUEST,
    UPDATE_STOCK_SUCCESS,
    UPDATE_STOCK_FAIL,
}
from '../constants/stockConstants'

export const stockReducer = (state = {stock: []}, action) => {
    switch(action.type){
        case STOCK_REQUEST:
            return {loading: true, stock: []}
        case STOCK_SUCCESS:
            return {loading: false, stock: action.payload}
        case STOCK_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const getAllStockReducer = (state = {stock: []}, action) => {
    switch(action.type){
        case STOCK_ALL_REQUEST:
            return {loading: true, stock: []}
        case STOCK_ALL_SUCCESS:
            return {loading: false, allOdooStock: action.payload}
        case STOCK_ALL_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const updateStockReducer = (state = {stock: []}, action) => {
    switch(action.type){
        case UPDATE_STOCK_REQUEST:
            return {loading: true, stock: []}
        case UPDATE_STOCK_SUCCESS:
            return {loading: false, stock: action.payload}
        case UPDATE_STOCK_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}