import{
    FLEET_ENTITY_LIST_REQUEST,
    FLEET_ENTITY_LIST_SUCCESS,
    FLEET_ENTITY_LIST_FAIL,

    FLEET_ENTITY_GET_REQUEST,
    FLEET_ENTITY_GET_SUCCESS,
    FLEET_ENTITY_GET_FAIL,

    FLEET_ENTITY_ADD_REQUEST,
    FLEET_ENTITY_ADD_SUCCESS,
    FLEET_ENTITY_ADD_FAIL,

    FLEET_ENTITY_ADD_BY_FILE_REQUEST,
    FLEET_ENTITY_ADD_BY_FILE_SUCCESS,
    FLEET_ENTITY_ADD_BY_FILE_FAIL,

    FLEET_ENTITY_DELETE_REQUEST,
    FLEET_ENTITY_DELETE_SUCCESS,
    FLEET_ENTITY_DELETE_FAIL,

    FLEET_ENTITY_UPDATE_REQUEST,
    FLEET_ENTITY_UPDATE_SUCCESS,
    FLEET_ENTITY_UPDATE_FAIL,

    RESET_FLEET_ENTITY_ADD_SUCCESS,
    RESET_FLEET_ENTITY_DELETE_SUCCESS,
    RESET_FLEET_ENTITY_UPDATE_SUCCESS,
    RESET_FLEET_ENTITY_ADD_BY_FILE_SUCCESS
}
from '../constants/fleetEntityConstants'

export const fleetEntityListReducer = (state = {}, action) => {
    switch (action.type) {
        case FLEET_ENTITY_LIST_REQUEST:
            return { loading: true }
        case FLEET_ENTITY_LIST_SUCCESS:
            return { loading: false, fleetEntities: action.payload }
        case FLEET_ENTITY_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const fleetEntityGetReducer = (state = { fleetEntity: {} }, action) => {
    switch (action.type) {
        case FLEET_ENTITY_GET_REQUEST:
            return { loading: true, fleetEntity: {} }
        case FLEET_ENTITY_GET_SUCCESS:
            return { loading: false, fleetEntity: action.payload }
        case FLEET_ENTITY_GET_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const fleetEntityAddReducer = (state = {}, action) => {
    switch (action.type) {
        case FLEET_ENTITY_ADD_REQUEST:
            return { loading: true }
        case FLEET_ENTITY_ADD_SUCCESS:
            return { loading: false, success: true, fleetEntity: action.payload }
        case FLEET_ENTITY_ADD_FAIL:
            return { loading: false, error: action.payload }
        case RESET_FLEET_ENTITY_ADD_SUCCESS:
            return { ...state, success: false };
        default:
            return state
    }
}

export const fleetEntityAddByFileReducer = (state = {}, action) => {
    switch (action.type) {
        case FLEET_ENTITY_ADD_BY_FILE_REQUEST:
            return { loading: true }
        case FLEET_ENTITY_ADD_BY_FILE_SUCCESS:
            return { loading: false, success: true, fleetEntity: action.payload }
        case FLEET_ENTITY_ADD_BY_FILE_FAIL:
            return { loading: false, error: action.payload }
        case RESET_FLEET_ENTITY_ADD_BY_FILE_SUCCESS:
            return { ...state, success: false };
        default:
            return state
    }
}

export const fleetEntityDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case FLEET_ENTITY_DELETE_REQUEST:
            return { loading: true }
        case FLEET_ENTITY_DELETE_SUCCESS:
            return { loading: false, success: true }
        case FLEET_ENTITY_DELETE_FAIL:
            return { loading: false, error: action.payload }
        case RESET_FLEET_ENTITY_DELETE_SUCCESS:
            return { ...state, success: false };
        default:
            return state
    }
}

export const fleetEntityUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case FLEET_ENTITY_UPDATE_REQUEST:
            return { loading: true }
        case FLEET_ENTITY_UPDATE_SUCCESS:
            return { loading: false, success: true, fleetEntity: action.payload }
        case FLEET_ENTITY_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        case RESET_FLEET_ENTITY_UPDATE_SUCCESS:
            return { ...state, success: false };
        default:
            return state
    }
}