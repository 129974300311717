const user = [
    {
        "id": 1,
        "email": "test@test.gmail",
        "nom": "durant",
        "prenom": "olivier",
        "telephone": "telephone",
        "password": "password",
        "password2": "password2",
        "societes": {
            "societe1": {
                "nom": "nom",
                "siren": "siren",
                "TVA intra": "TVA intra",               
                "etablissement":[
                    {
                        'id': 1,
                        "nom": "Google Etab1",
                        "numVoie": "8",
                        "voie": "rue de londres",
                        "codePostal": "75009",
                        "ville": "Paris",
                        "pays": "France",
                        "telephone": "0606060606",
                        "siret": "1532456789"
                    },
                    {
                        'id': 2,
                        "nom": "Google Etab2",
                        "numVoie": "9",
                        "voie": "rue de londres",
                        "codePostal": "75009",
                        "ville": "Paris",
                        "pays": "France",
                        "telephone": "0707070707",
                        "siret": "1532456789"
                    },
                    {
                        'id': 3,
                        "nom": "Google Etab3",
                        "numVoie": "10",
                        "voie": "rue de londres",
                        "codePostal": "75009",
                        "ville": "Paris",
                        "pays": "France",
                        "telephone": "0607060706",
                        "siret": "1532456789"
                    }
                ]
            }
        }

    }
]

export default user