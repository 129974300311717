import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Row, Col, ListGroup, Image, Button, Container, Form, Card } from 'react-bootstrap'

import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useTranslation } from 'react-i18next';

import './EntityModal.css';

Modal.setAppElement('#root');

const EntityModal = ({ isOpen, onRequestClose, entities, onAddEntityCard, onSelectEntityCard }) => {
  const [entityCardForm, setEntityCardForm] = useState({ name: '', siret: '' });
  const [filteredEntities, setFilteredFleetEntities] = useState(entities);
  const [searchTerm, setSearchTerm] = useState('');
  const { t, i18n } = useTranslation();

  const searchKeys = ['name', 'siret']
  const fuseOptions = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: searchKeys
  };

  useEffect(() => {

    setFilteredFleetEntities(entities);
  }, [entities]);

  useEffect(() => {
    let list = filteredEntities && filteredEntities.length > 0 ? filteredEntities : entities;


    if (searchTerm !== '') {

      setFilteredFleetEntities(list.filter((f) =>
        f.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        f.siret.toLowerCase().includes(searchTerm.toLowerCase())
      ));
    }
    else {
      setFilteredFleetEntities(entities);
    }
  }, [searchTerm]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEntityCardForm({ ...entityCardForm, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onAddEntityCard(entityCardForm);
    setEntityCardForm({ name: '', siret: '' });
  };

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string.length === 0 || string === "") {
      setSearchTerm('');
      setFilteredFleetEntities(entities);
    }
    setSearchTerm(string);
  };

  const handleOnSelect = (item) => {
    // the item selected
    setFilteredFleetEntities(entities.filter((fu) => fu.id === item.id));
    setSearchTerm(item.name + '   ---   ' + item.siret);
  };

  const handleOnFocus = () => {

  };

  const formatResult = (item) => {
    return (
      <>
        {
          item && item != undefined ? (
            <span>{item.siret}    ---    {item.name}</span>
          ) : null
        }

      </>
    )
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="entity-modal"
      style={{ overlay: { zIndex: 9999 } }} // Ajout du z-index
    >
      <Container>
        <Row className="modal-header">
          <Col md={10}>
            <h2>{t('Associate an Entity')}</h2>
          </Col>
          <Col className="text-right">
            <button className='entity-modal-close-btn' type="submit" onClick={onRequestClose}>{t('Close')}</button>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  className='modal-input'
                  type="text"
                  name="nom"
                  placeholder={t('Name')}
                  value={entityCardForm.nom}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  className='modal-input'
                  type="text"
                  name="siret"
                  placeholder="SIRET"
                  value={entityCardForm.siret}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  className='modal-input'
                  type="text"
                  name="voie1"
                  placeholder={t('Street')}
                  value={entityCardForm.voie1}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  className='modal-input'
                  type="text"
                  name="ville"
                  placeholder={t('City')}
                  value={entityCardForm.ville}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  className='modal-input'
                  type="text"
                  name="voie2"
                  placeholder={t('Street 2')}
                  value={entityCardForm.voie2}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  className='modal-input'
                  type="text"
                  name="pays"
                  placeholder={t('Country')}
                  value={entityCardForm.pays}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Control
                  className='modal-input'
                  type="text"
                  name="codePostal"
                  placeholder={t('Postal code')}
                  value={entityCardForm.codePostal}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="entity-modal-add-btn-row">
            <button className='entity-modal-add-btn' type="submit">{t('Add')}</button>
          </Row>
        </Form>
        <Row className="mb-4 mt-4">
          <Col>
            <h2>{t('Choose an entity from the list')}</h2>
          </Col>
          <Col md={6}>
            <ReactSearchAutocomplete
              items={filteredEntities && filteredEntities != undefined && filteredEntities.length > 0 ? filteredEntities : entities}
              fuseOptions={fuseOptions}
              resultStringKeyName="name"
              onSearch={handleOnSearch}
              onSelect={handleOnSelect}
              onFocus={handleOnFocus}
              onClear={() => { setSearchTerm(''); setFilteredFleetEntities(entities); }}
              autoFocus
              placeholder={t('Search')}
              formatResult={formatResult}
              threshold={0.6} // 0 = exact match, 1 = everything matches
              ignoreLocation={true}
              maxResults={8}
              styling={{
                width: "100%",
                border: "1px solid #dfe1e5",
                borderRadius: "5px",
                backgroundColor: "white",
                boxShadow: "rgba(58, 53, 53, 0.2) 0px 1px 6px 0px",
                hoverBackgroundColor: "#eee",
                color: "#212121",
                fontSize: "12px",
                fontFamily: "Arial",
                iconColor: "grey",
                lineColor: "rgb(232, 234, 237)",
                placeholderColor: "grey",
                clearIconMargin: '0 5px 0 0',
                searchIconMargin: '0 0 0 5px',
                zIndex: 5,
              }}
            />
          </Col>
        </Row>
        <Row>
          <ListGroup className="entity-list">
            {(filteredEntities && filteredEntities.length > 0 ? filteredEntities : entities).map((entity) => (
              <ListGroup.Item key={entity.id} onClick={() => onSelectEntityCard(entity)} className="entity-item">
                {entity.name} {entity.siret}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Row>
      </Container>
    </Modal >
  );
};

export default EntityModal;
