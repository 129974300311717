export const DEFAULT_IMAGE_LIST_REQUEST = 'DEFAULT_IMAGE_LIST_REQUEST'
export const DEFAULT_IMAGE_LIST_SUCCESS = 'DEFAULT_IMAGE_LIST_SUCCESS'
export const DEFAULT_IMAGE_LIST_FAIL = 'DEFAULT_IMAGE_LIST_FAIL'

export const DEFAULT_IMAGE_CREATE_REQUEST = 'DEFAULT_IMAGE_CREATE_REQUEST'
export const DEFAULT_IMAGE_CREATE_SUCCESS = 'DEFAULT_IMAGE_CREATE_SUCCESS'
export const DEFAULT_IMAGE_CREATE_FAIL = 'DEFAULT_IMAGE_CREATE_FAIL'

export const DEFAULT_IMAGE_UPDATE_REQUEST = 'DEFAULT_IMAGE_UPDATE_REQUEST'
export const DEFAULT_IMAGE_UPDATE_SUCCESS = 'DEFAULT_IMAGE_UPDATE_SUCCESS'
export const DEFAULT_IMAGE_UPDATE_FAIL = 'DEFAULT_IMAGE_UPDATE_FAIL'


export const DEFAULT_ALL_NON_IMEI_IMAGE_LIST_REQUEST = 'DEFAULT_ALL_NON_IMEI_IMAGE_LIST_REQUEST'
export const DEFAULT_ALL_NON_IMEI_IMAGE_LIST_SUCCESS = 'DEFAULT_ALL_NON_IMEI_IMAGE_LIST_SUCCESS'
export const DEFAULT_ALL_NON_IMEI_IMAGE_LIST_FAIL = 'DEFAULT_ALL_NON_IMEI_IMAGE_LIST_FAIL'

export const DEFAULT_NON_IMEI_IMAGE_LIST_REQUEST = 'DEFAULT_NON_IMEI_IMAGE_LIST_REQUEST'
export const DEFAULT_NON_IMEI_IMAGE_LIST_SUCCESS = 'DEFAULT_NON_IMEI_IMAGE_LIST_SUCCESS'
export const DEFAULT_NON_IMEI_IMAGE_LIST_FAIL = 'DEFAULT_NON_IMEI_IMAGE_LIST_FAIL'

export const DEFAULT_NON_IMEI_IMAGE_CREATE_REQUEST = 'DEFAULT_NON_IMEI_IMAGE_CREATE_REQUEST'
export const DEFAULT_NON_IMEI_IMAGE_CREATE_SUCCESS = 'DEFAULT_NON_IMEI_IMAGE_CREATE_SUCCESS'
export const DEFAULT_NON_IMEI_IMAGE_CREATE_FAIL = 'DEFAULT_NON_IMEI_IMAGE_CREATE_FAIL'

export const DEFAULT_NON_IMEI_IMAGE_UPDATE_REQUEST = 'DEFAULT_NON_IMEI_IMAGE_UPDATE_REQUEST'
export const DEFAULT_NON_IMEI_IMAGE_UPDATE_SUCCESS = 'DEFAULT_NON_IMEI_IMAGE_UPDATE_SUCCESS'
export const DEFAULT_NON_IMEI_IMAGE_UPDATE_FAIL = 'DEFAULT_NON_IMEI_IMAGE_UPDATE_FAIL'