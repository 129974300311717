import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, ListGroup, Image, Button } from 'react-bootstrap'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import { Link } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom';

import { Container, Grid, Typography } from '@mui/material';
import UserGeneralInfo from '../../../../components/FleetUser/UserGeneralInfo';
import UserEntity from '../../../../components/FleetUser/UserEntity';
import UserDevices from '../../../../components/FleetUser/UserDevices';
import UserRoles from '../../../../components/FleetUser/UserRoles';

import { listFleet } from '../../../../actions/fleetActions';

import { useTranslation } from 'react-i18next';

function FleetUsersHistory() {
    const dispatch = useDispatch()
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const fleetList = useSelector(state => state.fleetList)
    const { error, loading, fleet, page, pages } = fleetList

    const fleetUserDetail = location.state.fleet_user;

    const user_values = [1, 2]
    const device_values = [3, 4, 5]
    const entity_values = [6, 7, 8]
    const role_values = [9, 10, 11]

    const [entityHistory, setEntityHistory] = useState([])
    const [roleHistory, setRoleHistory] = useState([])
    const [deviceHistory, setDeviceHistory] = useState([])

    useEffect(() => {    
        if (fleet && fleet.length === 0) {
            dispatch(listFleet())
        }
    }, [])

    useEffect(() => {
        if (fleetUserDetail) {
            
            setEntityHistory(fleetUserDetail.fleet_user_historique.filter((item) => entity_values.includes(item.typeHistoriqueFleetUserOperation)))
            setRoleHistory(fleetUserDetail.fleet_user_historique.filter((item) => role_values.includes(item.typeHistoriqueFleetUserOperation)))
            setDeviceHistory(getDeviceHistory());
        }
    }, [fleetUserDetail])

    function getDeviceHistory() {
        // Étape 1: Trier le tableau par fleet_id et created_at
        const sortedData = fleetUserDetail.fleet_user_historique.
            filter((item) => device_values.includes(item.typeHistoriqueFleetUserOperation))
            .sort((a, b) => {
                if (a.fleet_id === b.fleet_id) {
                    return new Date(a.created_at) - new Date(b.created_at);
                }
                return a.fleet_id - b.fleet_id;
            })

        // Étape 2: Parcourir le tableau et créer un nouveau tableau
        const result = [];
        let currentFleetId = null;
        let currentObj = null;

        sortedData.forEach(item => {
            if (item.fleet_id !== currentFleetId) {
                currentFleetId = item.fleet_id;
                let currentFleet = fleet.find((item) => item.id === currentFleetId);

                if (currentFleet != undefined) {
                    let fleet_product_details = currentFleet.fleet_product_details[0];

                    currentObj = {
                        type: fleet_product_details.categorie,
                        brand: fleet_product_details.brand,
                        model: fleet_product_details.name,
                        number: currentFleet.imei ? currentFleet.imei : currentFleet.serial,
                        updated_by: item.updated_by,
                    };
                    result.push(currentObj);
                }

            }

            if (currentObj) {
                if (item.typeHistoriqueFleetUserOperation === 3) {
                    currentObj.created_at = new Date(item.created_at).toLocaleDateString();
                } else if (item.typeHistoriqueFleetUserOperation === 5) {
                    currentObj.deleted_at = new Date(item.created_at).toLocaleDateString();
                }
            }

        });

        return result;
    }

    return (
        <Container>
            <Typography variant="h4" gutterBottom>{t('User history')}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <UserGeneralInfo user={fleetUserDetail} />
                        </Grid>
                        <Grid item>
                            <UserDevices history={deviceHistory} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <UserEntity history={entityHistory} />
                        </Grid>
                        <Grid item>
                            <UserRoles history={roleHistory} />
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Container>
    )
}

export default FleetUsersHistory