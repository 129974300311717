import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Row, Col, Image } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, Link } from 'react-router-dom'

import Switch from "react-switch";

import { getOdooStock, updateOdooStock } from '../../../../../actions/stockActions';

import Message from '../../../../../components/Message/Message';
import Loader from '../../../../../components/Loader/Loader';
import Select from '../../../../../components/Select/Select';

import { ProductsFromCircle, createProduct, createReset, checkProductInDbCircle, ProductByImeiFromAPI, listProducts } from '../../../../../actions/productActions';
import { allFeatures, listFeatures } from '../../../../../actions/featureActions';
import { addWarranty } from '../../../../../actions/warrantyActions';
import { validImei } from '../../../../../utils/luhnUtils';

import { removeDuplicatesByNestedProperty } from '../../../../../utils/utils';

import '../Articles.css'
import './AddArticles.css';

function AddArticles() {
  const searchRef = useRef(null);
  const [form, setForm] = useState({});

  const [toggleChecked, setToggleChecked] = useState(false);
  const [messageVariant, setMessageVariant] = useState('')
  const [imei, setImei] = useState('');
  const [warranty, setWarranty] = useState({});
  const [inputImei, setInputImei] = useState('');
  const [search, setSearch] = useState(false);
  const [message, setMessage] = useState('')
  const [newScan, setNewScan] = useState(false)

  const [selectedFeatures, setSelectedFeatures] = useState([])
  const [selectedNumber, setSelectedNumber] = useState(0)
  const [selectedType, setSelectedType] = useState('')

  const [numberListForSelect, setNumberListForSelect] = useState([])
  const [typeListForSelect, setTypeListForSelect] = useState([])

  const [featuresFormValues, setFeaturesFormValues] = useState([]);

  const [productToDisplay, setProductToDisplay] = useState(null)

  const productCreated = useSelector(state => state.productCreated)
  const { loading, success, product } = productCreated

  const productsFromCircle = useSelector(state => state.productsFromCircle)
  const { circleProducts } = productsFromCircle

  const odooStock = useSelector(state => state.odooStock)
  const { stock } = odooStock

  const productInDbCircle = useSelector(state => state.productInDbCircle)
  const { productInCircle } = productInDbCircle

  const allProductFeatures = useSelector(state => state.allFeaturesList)
  const { featuresList } = allProductFeatures

  const productsFromApi = useSelector(state => state.productByImei)
  const { productByImei, loading: loadingProductFromApi } = productsFromApi

  const warrantyFromDb = useSelector(state => state.warranty)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(allFeatures())
    setSelectedNumber(2)
    setSelectedType('ans')
    setProductToDisplay(null)
    searchRef.current.focus()
  }, [])

  useEffect(() => {
    let featuresArr = []
    if (featuresList && featuresList.length > 0 && inputImei.length === 15 && validImei(inputImei)) {

      let featuresListByTac = featuresList.filter(feature => feature.tac === inputImei.substring(0, 8))

      removeDuplicatesByNestedProperty(featuresListByTac, "name").filter(feature => feature.tac === inputImei.substring(0, 8)).map((feature, index) => {
        let item = {
          'name': feature.name,
          'options': featuresListByTac.filter(f => f.name === feature.name)
            .map(f => ({ label: f.value, value: f.value }))
            .sort((a, b) => {
              const aVal = parseFloat(a.label);
              const bVal = parseFloat(b.label);
              if (!isNaN(aVal) && !isNaN(bVal)) {
                return aVal - bVal;
              } else {
                return a.label.localeCompare(b.label);
              }
            })
          ,
          'value': featuresListByTac.filter(f => f.name === feature.name)[0].value
        };
        featuresArr.push(item)
      })

      setFeaturesFormValues(featuresArr)
    }

  }, [featuresList, inputImei])

  useEffect(() => {
    dispatch(createReset())
    if ((toggleChecked || (!toggleChecked && search)) && validImei(imei))
      dispatch(checkProductInDbCircle(imei))

    setSearch(false)
    setNewScan(false)
  }, [imei, search, newScan])

  useEffect(() => {

    if (productInCircle && productInCircle.productInDbCircle) {
      setMessage('Le numero IMEI ' + inputImei + ' est déjà en stock.')
      setMessageVariant('warning')
      dispatch(ProductsFromCircle(imei))
    }
    else if (validImei(imei)) {

      let selected_features = []
      featuresFormValues.map(feature => {
        selected_features.push({
          'imei': imei,
          'tac': imei.substring(0, 8),
          'name': feature.name,
          'value': feature.value
        })
      })
      dispatch(createProduct(imei, selected_features))
    }

    resetConst()
  }, [productInCircle])

  useEffect(() => {
    if (success) {
      dispatch(listProducts())
    }
    dispatch(createReset())
  }, [success])

  useEffect(() => {
    dispatch(addWarranty(warranty.id_odoo, warranty.years ? warranty.years : warranty.months))
  }, [warranty])

  useEffect(() => {
    if (product && product.id_odoo) {
      selectedType === 'ans' || selectedType === 'an' ?
        setWarranty({
          id_odoo: product.id_odoo,
          years: selectedNumber,
        })
        :
        setWarranty({
          id_odoo: product.id_odoo,
          months: selectedNumber,
        })

      dispatch(getOdooStock(product.id_odoo))
      setProductToDisplay(product)
    }
  }, [product])

  useEffect(() => {
    if (productByImei) {
      setProductToDisplay(productByImei)
    }
  }, [productByImei])

  useEffect(() => {
    if (circleProducts && inputImei.length === 15 && !validImei(inputImei)) {
      setProductToDisplay(circleProducts)
      dispatch(getOdooStock(circleProducts.id_odoo))
    }
  }, [circleProducts])

  useEffect(() => {
    setErrorMessages()
    if (inputImei.length === 15 && validImei(inputImei)) {
      dispatch(ProductByImeiFromAPI(inputImei))
      dispatch(listFeatures(inputImei))
    }
    else {
      setFeaturesFormValues([])
      setProductToDisplay(null)
    }

  }, [inputImei])

  useEffect(() => {
    getNumberValues()
    getTypeValues()
  }, [selectedNumber, selectedType])

  function getNumberValues() {
    let arr = selectedType === 'mois' ?
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] :
      [1, 2, 3, 4, 5]

    let numbers = []

    arr.forEach(element => {
      numbers.push({ label: element, value: element })
    });

    setNumberListForSelect(numbers)
  }

  function getTypeValues() {
    let arr = selectedNumber === 1 ?
      ['mois', 'an'] :
      ['mois', 'ans']

    let types = []

    arr.forEach(element => {
      types.push({ label: element, value: element })
    });

    setTypeListForSelect(types)
  }

  function resetConst() {
    if (imei.length > 0) {
      setSearch(false)
      setInputImei('')
    }
    searchRef.current.focus()
  }

  function setErrorMessages() {
    if (inputImei.length > 15) {
      setMessage('Le numéro IMEI doit comporter 15 chiffres.')
      setMessageVariant('danger')
    }
    else if (inputImei.length === 15 && !validImei(inputImei)) {
      setMessage('Le numero IMEI ' + inputImei + ' n\'est pas valide.')
      setMessageVariant('danger')
    }
    else if (productInCircle && !productInCircle.productInDbCircle) {
      setMessage('')
    }
  }

  function manageImeiInput(value) {
    setInputImei(value)
    if (value.length >= 15) {
      setNewScan(true)
      setImei(value)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    setSearch(true)
  }

  const handleToggleChange = (checked) => {
    setToggleChecked(checked);
  };

  const onHandleSelectNumberChange = useCallback((value, name) => {
    setSelectedNumber(value)
  });

  const onHandleSelectTypeChange = useCallback((value, name) => {
    setSelectedType(value)
  });

  const onFormValueChange = (value, index, field) => {
    const updatedFormValues = [...featuresFormValues];
    updatedFormValues[index].value = value;
    setFeaturesFormValues(updatedFormValues);
  };


  return (
    <div className='articles-container'>
      <Row className='articles-title-row'>
        <h1>Ajouter un article</h1>
      </Row>
      <Row className='articles-main-row'>
        <Col md={6}>
          <Row className='articles-switch-row'>
            <Col md={2}>
              <Switch
                className='addarticles-switch'

                onChange={handleToggleChange}
                checked={toggleChecked}
                offColor="#2f86ca"
                boxShadow="0px 5px 10px rgba(0, 0, 0, 0.6)"
              />
            </Col>
            <Col md={8}>
              <span>{!toggleChecked ? 'Ajout automatique désactivé' : 'Ajout automatique activé'}</span>
            </Col>
          </Row>
          <Row className='articles-manage-row'>
            <Col md={6}>
              <Form.Control
                // as="textarea" => pour faire un textarea
                type="text"
                id="imei-input"
                aria-describedby="passwordHelpBlock"
                placeholder="Numéro IMEI"
                ref={searchRef}
                value={inputImei}
                onChange={(e) => manageImeiInput(e.target.value)}
                ReactTooltip={"Le numéro IMEI doit comporter 15 chiffres."}
              />
            </Col>
            <Col md={4}>
              {
                !toggleChecked ?
                  <button className='addArticlesSearchBtn' type='submit' onClick={submitHandler} disabled={!validImei(imei)}>Ajouter</button>
                  : null
              }
            </Col>
          </Row>
          <Row className='articles-manage-row'>
            <Col md={10}>
              {message && message.length > 0 ? <Message variant={messageVariant}>{message}</Message> : null}
            </Col>
          </Row>
          <Row className='articles-features-row'>
            <Row className='articles-features-detail-row'>
              <Row>
                <span>Garantie</span>
              </Row>
              <Row>
                <Col md={4}>
                  <Select
                    className='articles-rom-select'
                    name="nbr"
                    title="Nbr"
                    value={selectedNumber}
                    options={numberListForSelect}
                    onChangeFunc={onHandleSelectNumberChange}
                  />
                </Col>
                <Col md={6}>
                  <Select
                    className='articles-rom-select'
                    name="mois-anee"
                    title="Mois/Année"
                    value={selectedType}
                    options={typeListForSelect}
                    onChangeFunc={onHandleSelectTypeChange}
                  />
                </Col>
              </Row>
            </Row>
            {
              featuresFormValues && featuresFormValues.length > 0 ?
                featuresFormValues.map((item, index) => (
                  <>
                    <span>{item.name}</span>
                    <Row className='aftersale-item-row' key={item.id}>
                      <Select
                        className='articles-rom-select'
                        name={item.name}
                        title={item.name}
                        options={item.options}
                        value={item.value}
                        onChangeFunc={(e) => onFormValueChange(e, index, item.name)}
                      />
                    </Row>
                  </>
                )) : null
            }
          </Row>
          {/* <Row className='addarticles-add-row'>
            <Link
              to={`/articles/setNewValues/`}
            >
              <button
                className='addarticles-set-new-values-btn'
                type='button'
                disabled={false}
              >
                Ajouter des valeurs
              </button>
            </Link>
          </Row> */}
        </Col>
        <Col className='articles-detail-col' md={6}>
          {loading ? <Loader /> :
            productToDisplay && productToDisplay.name ?
              (
                <>
                  <Row className='articles-detail-title'>
                    {productToDisplay.brand} {productToDisplay.name}
                  </Row>
                  <Row className='articles-detail-img-row'>
                    <Image className='articles-detail-img' src={productToDisplay.device_image} alt={productToDisplay.name} fluid rounded />
                  </Row>
                  <Row className='articles-detail-rows'>
                    <Row className=''>
                      Marque : {productToDisplay.brand}
                    </Row>
                    <Row className=''>
                      Modèle : {productToDisplay.model}
                    </Row>
                    <Row className=''>
                      IMEI : {imei}
                    </Row>
                    {
                      product && product.tac ?
                        <Row className=''>
                          Stock : {stock && stock.value ? stock.value : 0}
                        </Row>
                        : null
                    }

                  </Row>
                </>
              )
              : null
          }
        </Col>
      </Row>
    </div>
  )
}

export default AddArticles