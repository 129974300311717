import axios from 'axios';
import { getCookie } from '../utils/userUtils'
import {
    IMAGE_LIST_REQUEST,
    IMAGE_LIST_SUCCESS,
    IMAGE_LIST_FAIL,

    IMAGE_UPLOAD_REQUEST,
    IMAGE_UPLOAD_SUCCESS,
    IMAGE_UPLOAD_FAIL,

    IMAGE_UPDATE_REQUEST,
    IMAGE_UPDATE_SUCCESS,
    IMAGE_UPDATE_FAIL,

    IMAGE_DELETE_REQUEST,
    IMAGE_DELETE_SUCCESS,
    IMAGE_DELETE_FAIL,

    IMAGE_NON_IMEI_LIST_REQUEST,
    IMAGE_NON_IMEI_LIST_SUCCESS,
    IMAGE_NON_IMEI_LIST_FAIL,

    IMAGE_ALL_NON_IMEI_LIST_REQUEST,
    IMAGE_ALL_NON_IMEI_LIST_SUCCESS,
    IMAGE_ALL_NON_IMEI_LIST_FAIL
}
    from '../constants/imageConstants'


export const listAllNonImeiImage = () => async (dispatch, getState) => {
    try {
        dispatch({ type: IMAGE_ALL_NON_IMEI_LIST_REQUEST })
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
                'X-CSRFToken': getCookie('csrftoken'),
            },
        })

        const { data } = await instance.get(
            `/api/file/list/all-image-non-imei/`,
            )
        dispatch({
            type: IMAGE_ALL_NON_IMEI_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: IMAGE_ALL_NON_IMEI_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}


export const listNonImeiImage = (brand, model, name) => async (dispatch, getState) => {
    try {
        dispatch({ type: IMAGE_NON_IMEI_LIST_REQUEST })
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
                'X-CSRFToken': getCookie('csrftoken'),
            },
        })

        const { data } = await instance.get(
            `/api/file/list/image-non-imei/?brand=${brand}&model=${model}&name=${name}`,
            )
        dispatch({
            type: IMAGE_NON_IMEI_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: IMAGE_NON_IMEI_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const listImageByImei = (tac, subFolder, color='all') => async (dispatch, getState) => {
    
    try {
        dispatch({ type: IMAGE_LIST_REQUEST })
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
                'X-CSRFToken': getCookie('csrftoken'),
            },
        })

        const { data } = await instance.post(
            `/api/file/list/image/`,
            { 'tac': tac, 'subFolder': subFolder, 'colorSubFolder': color },
            )
        
        
        dispatch({
            type: IMAGE_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: IMAGE_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const uploadImage = (formData) => async (dispatch, getState) => {
    
    try {
        dispatch({ type: IMAGE_UPLOAD_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo.token}`,
                'X-CSRFToken': getCookie('csrftoken'),
            },
        })

        
        const { data } = await instance.post(
            '/api/file/upload/image/',
            formData,
            )

        dispatch({
            type: IMAGE_UPLOAD_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: IMAGE_UPLOAD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const updateImage = (formData) => async (dispatch, getState) => {  
    try {
        dispatch({ type: IMAGE_UPLOAD_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo.token}`,
                'X-CSRFToken': getCookie('csrftoken'),
            },
        })


        const { data } = await instance.post(
            '/api/file/upload/updateImage/',
            formData,
            )

        dispatch({
            type: IMAGE_UPLOAD_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: IMAGE_UPLOAD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const deleteImage = (formData) => async (dispatch, getState) => { 
    try {
        dispatch({ type: IMAGE_DELETE_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo.token}`,
                'X-CSRFToken': getCookie('csrftoken'),
            },
        })


        const { data } = await instance.post(
            '/api/file/delete/image/',
            formData,
            )

        dispatch({
            type: IMAGE_DELETE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: IMAGE_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}