import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Row, Col, ListGroup, Image, Card, Container } from 'react-bootstrap'
import useFileUpload from 'react-use-file-upload'
import { useDispatch, useSelector } from 'react-redux'


import uuid from 'react-uuid';

import { uploadImage, deleteImage } from '../../../../../actions/imageActions';
import { listBanner } from '../../../../../actions/bannerActions';

import './ImageBanner.css';
import '../Images.css'

function ImageTuiles() {
  const searchRef = useRef(null);
  const dispatch = useDispatch()
  const inputRef = useRef();

  const [imei, setImei] = useState('');
  const [selectedColor, setSelectedColor] = useState('')

  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile
  } = useFileUpload();

  const listImage = useSelector(state => state.bannerList)
  const { loading, error, banner } = listImage

  useEffect(() => {
    dispatch(listBanner())
  }, [])

  const handleFileUpload = (e) => {
    
    let i = 0
    let folder = 'banner'
    files.forEach(image => {
      
      
      let formData = new FormData();
      let extension = image.name.split('.')[image.name.split('.').length - 1]

      formData.append('image', image, uuid() + '.' + extension);
      formData.append('folder', folder);

      dispatch(uploadImage(formData)).then(() => {
        dispatch(listBanner())
      })
      i++    
    });
    clearAllFiles()
  };


  const removeFromImagesHandler = (name) => {
    // on supprime une image produit grâce au nom
    let folder = 'banner'
    let formData = new FormData();

    formData.append('name', name);
    formData.append('folder', folder);

    
    

    dispatch(deleteImage(formData)).then(() => {
      dispatch(listBanner())
    })
  }


  return (
    <div className='images-container'>
      <Row className='images-title-row'>
        <h1>Images Bannière Accueil</h1>
      </Row>
      <Row className='images-main-row'>
        <Col className='imagesbanner-upload-col' md={5}>
          <Row className='imagesbanner-file-container'>
            <div
              className='imagesbanner-list-files-container'
            >
              <Row className='imagesbanner-list-files-row'>
                <ul>
                  <p className='imagesbanner-list-files-title'>Images à envoyer</p>
                  {fileNames.map((name) => (
                    <li key={name}>
                      <span>{name}</span>

                      <span onClick={() => removeFile(name)}>
                        <i className="fa-regular fa-trash-can file-remove"></i>
                      </span>
                    </li>
                  ))}
                </ul>
              </Row>
              <Row className='imagesbanner-btn_addfiles-row'>
                <button className='imagesbanner-btn-add-file' onClick={() => handleFileUpload()}>Ajouter</button>
              </Row>

            </div>

            {/* Provide a drop zone and an alternative button inside it to upload files. */}
            <div
              className='imagesbanner-drop-zone'
              onDragEnter={handleDragDropEvent}
              onDragOver={handleDragDropEvent}
              onDrop={(e) => {
                handleDragDropEvent(e);
                setFiles(e, 'a');
              }}
            >
              <p>Déposez vos fichiers ici</p>

              <button className='imagesbanner-btn-select-file' onClick={() => inputRef.current.click()}>Sélectionner un fichier</button>

              <input
                ref={inputRef}
                type="file"
                multiple
                style={{ display: 'none' }}
                onChange={(e) => {
                  setFiles(e, 'a');
                  inputRef.current.value = null;
                }}
              />
            </div>
          </Row>
        </Col>
        <Col className='imagesbanner-list-col' md={5}>
          {banner && banner.length > 0 ?
              <ListGroup className='imagesbanner-banner' variant='flush'>
                {banner.map((item, index) => (
                  <ListGroup.Item className='imagesbanner-items' key={item.name}>
                    <Row className='imagesbanner-row-item'>
                      <Col className='vertical-center' md={8}>
                        <Image className='imagesbanner-img' src={item.url} alt={item.url} fluid rounded />
                      </Col>
                      <Col className='imagesbanner-item-input' md={4}>
                        <button
                          className="btn-imagesbanner-remove"
                          onClick={() => dispatch(removeFromImagesHandler(item.name))}>
                          <i className='fas fa-trash'></i>
                        </button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))
                }
              </ListGroup>
            : null
          }

        </Col>
      </Row>
    </div>
  )
}

export default ImageTuiles