export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST'
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS'
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL'

export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST'
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS'
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL'
export const ORDER_CREATE_SUCCESS_RESET = 'ORDER_CREATE_SUCCESS_RESET'

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL'

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST'
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS'
export const ORDER_PAY_FAIL = 'ORDER_PAY_FAIL'
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET'

export const ORDER_BC_REQUEST = 'ORDER_BC_REQUEST'
export const ORDER_BC_SUCCESS = 'ORDER_BC_SUCCESS'
export const ORDER_BC_FAIL = 'ORDER_BC_FAIL'

export const ORDER_BC_CREATE_REQUEST = 'ORDER_BC_CREATE_REQUEST'
export const ORDER_BC_CREATE_SUCCESS = 'ORDER_BC_CREATE_SUCCESS'
export const ORDER_BC_CREATE_FAIL = 'ORDER_BC_CREATE_FAIL'