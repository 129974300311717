import {
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAIL,

    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,

    PRODUCT_BY_IMEI_FROM_API_REQUEST,
    PRODUCT_BY_IMEI_FROM_API_SUCCESS,
    PRODUCT_BY_IMEI_FROM_API_FAIL,

    CHECK_PRODUCT_FROM_CIRCLE_REQUEST,
    CHECK_PRODUCT_FROM_CIRCLE_SUCCESS,
    CHECK_PRODUCT_FROM_CIRCLE_FAIL,

    PRODUCT_FROM_CIRCLE_BY_IMEI_REQUEST,
    PRODUCT_FROM_CIRCLE_BY_IMEI_SUCCESS,
    PRODUCT_FROM_CIRCLE_BY_IMEI_FAIL,
    RESET_PRODUCT_FROM_CIRCLE_BY_IMEI,

    PRODUCT_FROM_CIRCLE_BY_TAC_REQUEST,
    PRODUCT_FROM_CIRCLE_BY_TAC_SUCCESS,
    PRODUCT_FROM_CIRCLE_BY_TAC_FAIL,
    RESET_PRODUCT_FROM_CIRCLE_BY_TAC,

    PRODUCT_FROM_CIRCLE_REQUEST,
    PRODUCT_FROM_CIRCLE_SUCCESS,
    PRODUCT_FROM_CIRCLE_FAIL,

    PRODUCT_CREATE_REQUEST,
    PRODUCT_CREATE_SUCCESS,
    PRODUCT_CREATE_FAIL,
    PRODUCT_CREATE_RESET,

    PRODUCT_UPDATE_REQUEST,
    PRODUCT_UPDATE_SUCCESS,
    PRODUCT_UPDATE_FAIL,
    PRODUCT_UPDATE_RESET,

    PRODUCT_DELETE_REQUEST,
    PRODUCT_DELETE_SUCCESS,
    PRODUCT_DELETE_FAIL,
    PRODUCT_DELETE_RESET,

    PRODUCT_NON_IMEI_LIST_REQUEST,
    PRODUCT_NON_IMEI_LIST_SUCCESS,
    PRODUCT_NON_IMEI_LIST_FAIL,

    PRODUCT_NON_IMEI_DETAILS_REQUEST,
    PRODUCT_NON_IMEI_DETAILS_SUCCESS,
    PRODUCT_NON_IMEI_DETAILS_FAIL,

    PRODUCT_NON_IMEI_CREATE_REQUEST,
    PRODUCT_NON_IMEI_CREATE_SUCCESS,
    PRODUCT_NON_IMEI_CREATE_FAIL,

    PRODUCT_NON_IMEI_UPDATE_REQUEST,
    PRODUCT_NON_IMEI_UPDATE_SUCCESS,
    PRODUCT_NON_IMEI_UPDATE_FAIL,

    PRODUCT_NON_IMEI_DELETE_REQUEST,
    PRODUCT_NON_IMEI_DELETE_SUCCESS,
    PRODUCT_NON_IMEI_DELETE_FAIL,

    PRODUCT_CREATE_IN_ODOO_REQUEST,
    PRODUCT_CREATE_IN_ODOO_SUCCESS,
    PRODUCT_CREATE_IN_ODOO_FAIL,
    PRODUCT_CREATE_IN_ODOO_RESET,
} from '../constants/productConstants'

import axios from 'axios'

export const listProducts = () => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        dispatch({ type: PRODUCT_LIST_REQUEST })
        const params = {
            id_odoo: userInfo.id_odoo,
            id_user: userInfo._id
        };

        const { data } = await instance.get(
            'api/products/', 
            { params }
            )

        dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: PRODUCT_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        })
    }
}

export const detailsProduct = (productId) => async (dispatch, getState) => {
    try {
        dispatch({ type: PRODUCT_DETAILS_REQUEST, payload: productId })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            `/api/products/${productId}/`
            )

        dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        })
    }
}

export const detailsNonImeiProduct = (productId) => async (dispatch, getState) => {
    try {
        dispatch({ type: PRODUCT_NON_IMEI_DETAILS_REQUEST, payload: productId })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            `/api/products/getNonImeiProduct/${productId}/`
            )

        dispatch({ type: PRODUCT_NON_IMEI_DETAILS_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: PRODUCT_NON_IMEI_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        })
    }
}

export const ProductByImeiFromAPI = (imei) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_BY_IMEI_FROM_API_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            `/api/imei/?imei=${imei}`,
            )

        dispatch({
            type: PRODUCT_BY_IMEI_FROM_API_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PRODUCT_BY_IMEI_FROM_API_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const checkProductInDbCircle = (imei) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CHECK_PRODUCT_FROM_CIRCLE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/products/checkProductInDbCircle/`,
            { 'imei': imei },
            )

        dispatch({
            type: CHECK_PRODUCT_FROM_CIRCLE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CHECK_PRODUCT_FROM_CIRCLE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getCircleProductByIMEI = (imei) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_FROM_CIRCLE_BY_IMEI_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/products/getCircleProductByIMEI/`,
            { 'imei': imei },
            )

        dispatch({
            type: PRODUCT_FROM_CIRCLE_BY_IMEI_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PRODUCT_FROM_CIRCLE_BY_IMEI_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getCircleProductByTACOrName = (tac, brand, name) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_FROM_CIRCLE_BY_TAC_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/products/getCircleProductByTACOrName/`,
            { 'tac': tac, 'brand': brand, 'name': name },
            )

        dispatch({
            type: PRODUCT_FROM_CIRCLE_BY_TAC_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PRODUCT_FROM_CIRCLE_BY_TAC_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const ProductsFromCircle = (tac) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_FROM_CIRCLE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/products/getCircleProducts/`,
            { 'tac': tac },
            )

        dispatch({
            type: PRODUCT_FROM_CIRCLE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PRODUCT_FROM_CIRCLE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createProduct = (imei, selectedFeatures) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_CREATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/products/create/`,
            { 'imei': imei, 'selectedFeatures': selectedFeatures },
            )

        dispatch({
            type: PRODUCT_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PRODUCT_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateProduct = (product) => async (dispatch, getState) => {
    try {
        
        
        dispatch({
            type: PRODUCT_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/products/update/`,
            product,
            )

        dispatch({
            type: PRODUCT_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PRODUCT_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createReset = () => (dispatch) => {
    dispatch({ type: PRODUCT_CREATE_RESET })
}

export const deleteProduct = (imeis) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/products/delete/`,
            { 'imeis': imeis },
            )

        dispatch({ type: PRODUCT_DELETE_SUCCESS })
    } catch (error) {
        dispatch({
            type: PRODUCT_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const listNonImeiProducts = (id_contact_odoo) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_NON_IMEI_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(`/api/products/getNonImeiProducts?&id_user=${userInfo._id}`)

        dispatch({
            type: PRODUCT_NON_IMEI_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: PRODUCT_NON_IMEI_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createNonImeiProduct = (product) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_NON_IMEI_CREATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/products/createNonImeiProduct/`,
            product,
            )

        dispatch({
            type: PRODUCT_NON_IMEI_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PRODUCT_NON_IMEI_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}  

export const updateNonImeiProduct = (product) => async (dispatch, getState) => {
    try {
        
        
        dispatch({
            type: PRODUCT_NON_IMEI_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/products/updateNonImeiProduct/`,
            product,
            )

        dispatch({
            type: PRODUCT_NON_IMEI_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PRODUCT_NON_IMEI_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteNonImeiProduct = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_NON_IMEI_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/products/deleteNonImeiProduct/`,
            { 'id': id },
            )

        dispatch({ type: PRODUCT_NON_IMEI_DELETE_SUCCESS })
    } catch (error) {
        dispatch({
            type: PRODUCT_NON_IMEI_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}   

export const createProductInOdoo = (imei) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_CREATE_IN_ODOO_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/products/createProductInOdoo/`,
            { 'imei': imei },
            )

        dispatch({
            type: PRODUCT_CREATE_IN_ODOO_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PRODUCT_CREATE_IN_ODOO_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const productFromCircleByImeiReset = () => (dispatch) => {
    dispatch({ type: RESET_PRODUCT_FROM_CIRCLE_BY_IMEI })
}

export const productFromCircleByTacReset = () => (dispatch) => {
    dispatch({ type: RESET_PRODUCT_FROM_CIRCLE_BY_TAC })
}

export const productCreateInOdooReset = () => (dispatch) => {
    dispatch({ type: PRODUCT_CREATE_IN_ODOO_RESET })
}

export const productUpdateReset = () => (dispatch) => {
    dispatch({ type: PRODUCT_UPDATE_RESET })
}

export const productDeleteReset = () => (dispatch) => {
    dispatch({ type: PRODUCT_DELETE_RESET })
}