import axios from 'axios';
import{
    WARRANTY_LIST_REQUEST,
    WARRANTY_LIST_SUCCESS,
    WARRANTY_LIST_FAIL,

    WARRANTY_BY_ID_REQUEST,
    WARRANTY_BY_ID_SUCCESS,
    WARRANTY_BY_ID_FAIL,

    WARRANTY_ADD_REQUEST,
    WARRANTY_ADD_SUCCESS,
    WARRANTY_ADD_FAIL,

    WARRANTY_GET_BY_IMEI_REQUEST,
    WARRANTY_GET_BY_IMEI_SUCCESS,
    WARRANTY_GET_BY_IMEI_FAIL
} from '../constants/warrantyConstants';

export const getAllWarranty = () => async (dispatch, getState) => {
    try {
        dispatch({ type: WARRANTY_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            'api/warranty/all'
            )

        dispatch({ type: WARRANTY_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ 
            type: WARRANTY_LIST_FAIL, 
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message })
    }
}

export const getWarrantyByImei = (id_odoo, imei) => async (dispatch, getState) => {
    try {
        dispatch({ type: WARRANTY_GET_BY_IMEI_REQUEST, payload: id_odoo })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            `/api/warranty/imei/?id_odoo=${id_odoo}&imei=${imei}`
            );

        dispatch({ type: WARRANTY_GET_BY_IMEI_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ 
            type: WARRANTY_GET_BY_IMEI_FAIL, 
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message })
    }
}

export const getWarranty = (id_odoo) => async (dispatch, getState) => {
    try {
        dispatch({ type: WARRANTY_BY_ID_REQUEST, payload: id_odoo })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            `/api/warranty/?id_odoo=${id_odoo}`
            );

        dispatch({ type: WARRANTY_BY_ID_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ 
            type: WARRANTY_BY_ID_FAIL, 
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message })
    }
}

export const addWarranty  = (id_odoo, years, months) => async (dispatch, getState) => {
    try {
        dispatch({
            type: WARRANTY_ADD_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/warranty/add/`,
            { 'id_odoo': id_odoo, 'years': years, 'months': months },
            )

        dispatch({
            type: WARRANTY_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: WARRANTY_ADD_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}