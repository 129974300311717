import React, { useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import CountrySelector from '../../components/Selector/CountrySelector/CountrySelector';
import { getEtablissementAdresses } from '../../utils/userUtils';
import User from '../../Test/user';
import './CartAdresses.css'

function CartAdresses() {

    let navigate = useNavigate();

    const adressList = getEtablissementAdresses(User, 'etablissement');

    const [inputNum, setInputNum] = useState("");
    const addInputNum = (event) => {
        setInputNum(event.target.value);
    };

    const [inputStreet, setInputStreet] = useState("");
    const addInputStreet = (event) => {
        setInputStreet(event.target.value);
    };

    const [inputPostalCode, setInputPostalCode] = useState("");
    const addInputPostalCode = (event) => {
        setInputPostalCode(event.target.value);
    };
    const [inputCity, setInputCity] = useState("");
    const addInputCity = (event) => {
        setInputCity(event.target.value);
    };

    const [inputCountry, setInputCountry] = useState("");
    const addInputCountry = (event) => {
        setInputCountry(event.target.value);
    };

    const closeCartAdresses = () => {
        document.getElementById("cartAdresses").style.display = "none";
    }

    const returnToCart = () => {
        navigate('/cart');
    }


    const addAdress = () => {
        
        const newAdress = {
            id: 0,
            street: inputStreet,
            postalCode: inputPostalCode,
            city: inputCity,
            country: inputCountry
        }
        adressList.push(newAdress);
    }



    return (
        <>
            <Container className='py-5 cart-adresses-container'>
                <Row className='cart-adresses-title'>
                    Ajouter une nouvelle adresse
                </Row>
                <Row className="cart-adresses-row">
                    <input
                        className="cart-adresses-input"
                        onChange={addInputNum}
                        value={inputNum}
                        placeholder={"Numéro"}
                    />
                </Row>
                <Row className="cart-adresses-row">
                    <input
                        className="cart-adresses-input"
                        onChange={addInputStreet}
                        value={inputStreet}
                        placeholder={"Nom de la rue"}
                    />
                </Row>
                <Row className="cart-adresses-row">
                    <input
                        className="cart-adresses-input"
                        onChange={addInputPostalCode}
                        value={inputPostalCode}
                        placeholder={"Code Postal"}
                    />
                </Row>
                <Row className="cart-adresses-row">
                    <input
                        className="cart-adresses-input"
                        onChange={addInputCity}
                        value={inputCity}
                        placeholder={"Ville"}
                    />
                </Row>
                <Row className="cart-adresses-row">
                    <CountrySelector />
                </Row>
                <Row className="cart-adresses-btn-row">
                    <button className='btn-return-cart-adress' onClick={returnToCart}>Retour</button>
                    <button className='btn-add-cart-adress' onClick={addAdress}>Ajouter</button>
                </Row>
            </Container>
        </>
    )
}

export default CartAdresses