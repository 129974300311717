import {
    CONTACT_ODOO_LIST_REQUEST,
    CONTACT_ODOO_LIST_SUCCESS,
    CONTACT_ODOO_LIST_FAIL,

    CONTACT_ODOO_GET_REQUEST,
    CONTACT_ODOO_GET_SUCCESS,
    CONTACT_ODOO_GET_FAIL,

    CONTACT_ODOO_CREATE_REQUEST,
    CONTACT_ODOO_CREATE_SUCCESS,
    CONTACT_ODOO_CREATE_FAIL,
    CONTACT_ODOO_CREATE_RESET,

    CONTACT_ODOO_UPDATE_REQUEST,
    CONTACT_ODOO_UPDATE_SUCCESS,
    CONTACT_ODOO_UPDATE_FAIL,

    CONTACT_ODOO_DELETE_REQUEST,
    CONTACT_ODOO_DELETE_SUCCESS,
    CONTACT_ODOO_DELETE_FAIL,

    CONTACT_BY_ODOO_ID_REQUEST,
    CONTACT_BY_ODOO_ID_SUCCESS,
    CONTACT_BY_ODOO_ID_FAIL,
}
    from '../constants/contactOdooConstants.js'

import axios from 'axios'

export const listContactsOdoo = (id_odoo) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONTACT_ODOO_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            `/api/contact-odoo/?id_odoo=${id_odoo}`
            , 
        )

        dispatch({
            type: CONTACT_ODOO_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: CONTACT_ODOO_LIST_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const getContactOdoo = (id_odoo) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONTACT_ODOO_GET_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            `/api/contact-odoo/get/?id_odoo=${id_odoo}`
            , 
        )

        dispatch({
            type: CONTACT_ODOO_GET_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: CONTACT_ODOO_GET_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const createContactOdoo = (contactOdoo) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONTACT_ODOO_CREATE_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            '/api/contact-odoo/add/', contactOdoo
            , 
        )

        dispatch({
            type: CONTACT_ODOO_CREATE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: CONTACT_ODOO_CREATE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const updateContactOdoo = (contactOdoo) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONTACT_ODOO_UPDATE_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.put(
            '/api/contact-odoo/update/', contactOdoo
            )

        dispatch({
            type: CONTACT_ODOO_UPDATE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: CONTACT_ODOO_UPDATE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const deleteContactOdoo = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONTACT_ODOO_DELETE_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.delete(
            `/api/contact-odoo/del/${id}/`
            )

        dispatch({
            type: CONTACT_ODOO_DELETE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: CONTACT_ODOO_DELETE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const getContactByOdooId = (id_odoo) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONTACT_BY_ODOO_ID_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            `/api/contact-odoo/get/?id_odoo=${id_odoo}`
            )
            
        dispatch({
            type: CONTACT_BY_ODOO_ID_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: CONTACT_BY_ODOO_ID_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const resetContactOdoo = () => async (dispatch, getState) => {
    dispatch({ type: CONTACT_ODOO_CREATE_RESET })
}
