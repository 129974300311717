export const validImei = (imei) => {
    let imeiArray = ('' + imei).split('');
    let lastDigit = imeiArray[imeiArray.length - 1];
    let firstOperation = [];
    let secondOperation = [];

    //Première opération
    //On multiplie par 2 les chiffres à l'indice impair après avoir enlevé le dernier chiffre
    //On exclue le dernier chiffre
    for (let i = 0; i < imeiArray.length - 1; i++) {
        if (i % 2 !== 0)
            firstOperation.push('' + (parseInt(imeiArray[i]) * 2));
        else
            firstOperation.push(imeiArray[i]);
    }

    // Seconde opération
    // On sépare les nombres à deux chiffres
    for (let i = 0; i < firstOperation.length; i++) {
        if (firstOperation[i].length > 1) {
            let temp = firstOperation[i].split('');
            for (let j = 0; j < temp.length; j++) {
                secondOperation.push(parseInt(temp[j]));
            }
        } else {
            secondOperation.push(parseInt(firstOperation[i]));
        }
    }

    // Troisième opération
    // On additionne les chiffres
    let sum = 0;
    for (let i = 0; i < secondOperation.length; i++) {
        sum += secondOperation[i];
    }

    // Quatrième opération
    // On vérifie si l'arrondi à la dizaine supérieure - la somme est égale au dernier chiffre
    if (((Math.ceil(sum / 10) * 10) - sum) === parseInt(lastDigit))
        return true;
    return false;
}