import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, ListGroup, Image } from 'react-bootstrap'
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAnglesLeft,
    faAngleLeft,
    faAngleRight,
    faAnglesRight
} from '@fortawesome/free-solid-svg-icons';

import DataGrid, { Row as DataGridRow, Column, textEditor } from 'react-data-grid';
import { Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import 'react-data-grid/lib/styles.css';

import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import ReactTooltip from 'react-tooltip';

import Loader from '../../../../components/Loader/Loader'


import { listProducts } from '../../../../actions/productActions';
import { listNonImeiProducts } from '../../../../actions/productActions';

import { listCategories } from '../../../../actions/categoriesActions';
import { listHeaderCategories } from '../../../../actions/headerCategoriesActions';
import { listAccessories, majAccessories, majAccessoriesReset } from '../../../../actions/accessoriesActions';

import Select from '../../../../components/Select/Select'
import { useTranslation } from 'react-i18next';
import { set } from "date-fns";


import './Accessories.css'

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function Accessories() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const productList = useSelector(state => state.productList)
    const { error, loading, products, page, pages } = productList

    const productNonImeiList = useSelector(state => state.productNonImeiList)
    const { productsNonImei, loading: nonImeiLoading } = productNonImeiList

    const categoriesList = useSelector(state => state.categoriesList)
    const { categories } = categoriesList

    const headerCategoriesList = useSelector((state) => state.headerCategoriesList);
    const { loading: loadingHeaderCategories, error: errorHeaderCategories, headerCategories } = headerCategoriesList;

    const [productsData, setProductsData] = useState();
    const [filteredProductsData, setFilteredProductsData] = useState([]);

    const [selectHeaderCategorie, setSelectHeaderCategorie] = useState([]);
    const [selectedHeaderCategory, setSelectedHeaderCategory] = useState(null);

    const [selectCategorie, setSelectCategorie] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [searchTerm, setSearchTerm] = useState('');
    const [productSearchTerm, setProductSearchTerm] = useState('');

    const [selectedProduct, setSelectedProduct] = useState(null);

    const [searchAccessoriesTerm, setSearchAccessoriesTerm] = useState('');
    const [resetSelect, setResetSelect] = useState(false);

    const [checkedProducts, setCheckedProducts] = useState([]);
    const [notValid, setNotValid] = useState(false)
    const [leftProducts, setLeftProducts] = useState([]);
    const [rightProducts, setRightProducts] = useState([]);

    const leftCheckedProducts = intersection(checkedProducts, [...leftProducts]);
    const rightCheckedProducts = intersection(checkedProducts, rightProducts);

    const accessoriesList = useSelector(state => state.accessoriesList)
    const { error: errorAccessories, loading: loadingAccessories, accessories } = accessoriesList

    const accessoriesMaj = useSelector(state => state.accessoriesMaj)
    const { error: errorAccessoriesMaj, loading: loadingAccessoriesMaj, success: successAccessoriesMaj } = accessoriesMaj

    const [accessoriesData, setAccessoriesData] = useState();
    const [filteredAccessoriesData, setFilteredAccessoriesData] = useState([]);

    const [checkedAccessories, setCheckedAccessories] = useState([]);
    const [leftAccessories, setLeftAccessories] = useState([]);
    const [rightAccessories, setRightAccessories] = useState([]);

    const leftCheckedAccessories = intersection(checkedAccessories, [...leftAccessories]);
    const rightCheckedAccessories = intersection(checkedAccessories, rightAccessories);



    const searchKeys = ['brand', 'model', 'name']
    const fuseOptions = {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: searchKeys
    };

    useEffect(() => {
        if (products && products != undefined && products.length === 0) {
            dispatch(listProducts())
        }
        if (productsNonImei && productsNonImei != undefined && productsNonImei.length === 0) {
            dispatch(listNonImeiProducts())
        }
        if (categories && categories != undefined && categories.length === 0) {
            dispatch(listCategories())
        }
        if (headerCategories && headerCategories != undefined && headerCategories.length === 0) {
            dispatch(listHeaderCategories())
        }
    }, [])

    useEffect(() => {
        if (headerCategories && headerCategories.length > 0) {
            let values = []
            headerCategories
                .sort((a, b) => a.id - b.id)
                .filter(hc => !hc.is_imei)
                .forEach(hc => {
                    values.push({ value: hc.id, label: hc.name })
                })
            setSelectHeaderCategorie(values)
        }
    }, [headerCategories])

    useEffect(() => {
        if (selectedHeaderCategory && categories && categories.length > 0) {
            fillCategories()
        }
        else {
            setSelectedCategory(null);
            setSelectCategorie([]);
        }
    }, [selectedHeaderCategory, categories]);

    useEffect(() => {
        if (selectedCategory) {
            if (selectedCategory.length > 0) {
                let product_to_display = [];
                selectedCategory.forEach(sc => {
                    product_to_display = [...product_to_display, ...productsNonImei.filter(p => p.categories.includes(sc.id))];
                });
                setProductsData(product_to_display);
            }
            else {
                setProductsData(productsNonImei.filter(p => p.categories.includes(selectedCategory.id)));
            }
        }
        else {
            setProductsData([...productsNonImei]);
        }

    }, [selectedCategory])

    useEffect(() => {
        if (productsData && productsData.length > 0) {
            setFilteredProductsData(productsData);
        }
        else
            setFilteredProductsData([]);
    }, [productsData])

    useEffect(() => {
        if (searchTerm !== '') {
            let list = filteredProductsData && filteredProductsData.length > 0 ? filteredProductsData : productsData;
            setFilteredProductsData(list.filter((f) =>
                f.brand.toLowerCase().includes(searchTerm.toLowerCase()) ||
                f.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                f.model.toLowerCase().includes(searchTerm.toLowerCase())
            ));
        } else {
            setFilteredProductsData(productsData);
            setResetSelect(!resetSelect);
        }
    }, [searchTerm]);

    useEffect(() => {
        if (filteredProductsData) {
            if (accessories && accessories.productsNonImei && accessories.productsNonImei != undefined && accessories.productsNonImei.length > 0) {
                let accessories_products = filteredProductsData.filter(p => accessories.product.id = p.id);
                setRightProducts(accessories_products);
                setLeftProducts(not(filteredProductsData, accessories_products));
            }
            else {
                setLeftProducts(filteredProductsData);
                setRightProducts([]);
            }
        }
    }, [filteredProductsData])

    useEffect(() => {
        if (successAccessoriesMaj) {
            dispatch(majAccessoriesReset)
        }
    }, [successAccessoriesMaj])

    const formatResult = (item) => {
        return (
            <>
                {
                    item ? (
                        <span>{item.brand} {item.name}</span>
                    ) : null
                }

            </>
        )
    };

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        if (string.length === 0 || string === "") {
            setSearchTerm('');
            setFilteredProductsData(productsData);
        }
        setSearchTerm(string);
    };

    const handleOnSelectProduct = (item) => {
        // the item selected
        let is_imei = categories.filter((category) => category.id === item.categories[0])[0].is_imei;
        setSelectedProduct((is_imei ? products : productsNonImei).filter((product) => product.id === item.id)[0]);
        setProductSearchTerm(item.id);
    };

    const handleOnFocusProduct = () => {

    };

    const handleOnSearchProduct = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        if (string.length === 0 || string === "") {
            setProductSearchTerm('');
            setSelectedProduct(null);
        }
        setProductSearchTerm(string);
    };

    const handleOnSelect = (item) => {
        // the item selected
        setFilteredProductsData(filteredProductsData.filter((product) => product.id === item.id && product.categories == item.categories));
        //setSearchTerm(item.id);
    };

    const handleOnFocus = () => {

    };

    const onHandleHeaderCategorySelectChange = (value) => {
        setLeftProducts(productsData)
        setRightProducts([])
        setSelectedHeaderCategory(headerCategories.filter(hc => hc.id === value)[0])
    }

    const onHandleCategorySelectChange = (value) => {
        if (selectedHeaderCategory && selectedHeaderCategory != undefined)
            if (value && value != undefined)
                setSelectedCategory(categories.filter(c => c.id === value)[0])
            else
                fillCategories()
        else
            setSelectedCategory(categories.filter(c => c.id === value)[0])
    }

    function fillCategories() {
        let values = [];
        categories
            .filter(c => selectedHeaderCategory.categories.includes(c.id))
            .sort((a, b) => a.id - b.id)
            .forEach(hc => {
                values.push({ value: hc.id, label: hc.name });
            });

        if (values.length == 1)
            setSelectedCategory(categories.filter(c => selectedHeaderCategory.categories.includes(c.id))[0]);
        else {
            setSelectedCategory(categories.filter(c => selectedHeaderCategory.categories.includes(c.id)))
        }

        setSelectCategorie(values);
    }

    const handleAllLeftProducts = () => {
        setLeftProducts(filteredProductsData);
        setRightProducts([]);
    };

    const handleAllRightProducts = () => {
        setRightProducts(rightProducts.concat([...leftProducts]));
        setLeftProducts([]);
    };

    const handleCheckedRightProducts = () => {
        setRightProducts(rightProducts.concat(leftCheckedProducts));
        setLeftProducts(not(leftProducts, leftCheckedProducts));
        setCheckedProducts(not(checkedProducts, leftCheckedProducts));
    };

    const handleCheckedLeftProducts = () => {
        setLeftProducts(leftProducts.concat(productsData.filter((p) => rightCheckedProducts.includes(p))));
        setRightProducts(not(rightProducts, rightCheckedProducts));
        setCheckedProducts(not(checkedProducts, rightCheckedProducts));
    };

    const customListProducts = (items) => (
        <List dense component="div" role="list">
            {items && items.length > 0 && items.map((value) => {
                const labelId = `accessories-list-item-${value}-label`;

                return (
                    <ListItem
                        className='accessories-list-item'
                        key={value}
                        role="listitem"
                        button
                        onClick={handleCheckedProducts(value)}
                    >
                        <ListItemIcon>
                            <Checkbox
                                checked={checkedProducts.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                    "aria-labelledby": labelId
                                }}
                            />
                        </ListItemIcon>
                        <ListItemIcon>
                            <img className="accessories-list-img" src={value.device_image} alt="" />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${value.brand} ${value.name}`} />
                    </ListItem>
                );
            })}
            <ListItem />
        </List>
    );

    const customRightListProducts = (items) => (
        <List dense component="div" role="accessories-list">
            {items && items != undefined && items.length > 0 && items.map((value, index) => {
                const labelId = `accessories-list-item-${value}-label`;

                return (
                    <ListItem
                        className='accessories-list-item'
                        key={value}
                        role="listitem"
                    >
                        <ListItemIcon>
                            <Checkbox
                                checked={checkedProducts.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                    "aria-labelledby": labelId
                                }}
                                onClick={handleCheckedProducts(value)}
                            />
                        </ListItemIcon>
                        <ListItemIcon>
                            <img className="accessories-list-img" src={value.device_image} alt="" />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${value.name}`} />
                    </ListItem>
                );
            })}
            <ListItem />
        </List>
    );

    const handleCheckedProducts = (value) => () => {
        const currentIndex = checkedProducts.indexOf(value);
        const newChecked = [...checkedProducts];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedProducts(newChecked);
    };

    const ProductsList = (
        <>
            <Row className='accessories-product-list-row'>
                <Col className='accessories-accessories-list-col' md={4}>
                    <Row>{customListProducts(leftProducts)}</Row>
                </Col>
                <Col className='accessories-list-button-col' md={1}>
                    <Row className='accessories-list-button-row'>
                        <button
                            className='accessories-list-button-add'
                            onClick={handleAllRightProducts}
                            aria-label="move all rightProducts"
                        ><FontAwesomeIcon icon={faAnglesRight} /></button>
                    </Row>
                    <Row className='accessories-list-button-row'>
                        <button
                            className='accessories-list-button-add'
                            onClick={handleCheckedRightProducts}
                            aria-label="move selected rightProducts"
                        ><FontAwesomeIcon icon={faAngleRight} /></button>
                    </Row>
                    <Row className='accessories-list-button-row'>
                        <button
                            className='accessories-list-button-remove'
                            onClick={handleCheckedLeftProducts}
                            aria-label="move selected leftProducts"
                        ><FontAwesomeIcon icon={faAngleLeft} /></button>
                    </Row>
                    <Row className='accessories-list-button-row'>
                        <button
                            className='accessories-list-button-remove'
                            onClick={handleAllLeftProducts}
                            aria-label="move all leftProducts"
                        ><FontAwesomeIcon icon={faAnglesLeft} /></button>
                    </Row>
                </Col>
                <Col className='accessories-accessories-list-col' md={4}>
                    <Row>{customRightListProducts(rightProducts)}</Row>
                </Col>
            </Row>
        </>
    )

    const reset = () => {
        setLeftProducts(filteredProductsData ? filteredProductsData : productsData);
        setRightProducts([]);
    };

    const saveAccessories = () => {
        let product_ids = rightProducts.map(c => c.id)
        let is_imei = categories.filter((category) => category.id === selectedProduct.categories[0])[0].is_imei;
        dispatch(majAccessories(selectedProduct.id, is_imei, product_ids))
    }

    useEffect(() => {
        if (selectedProduct) {
            let is_imei = categories.filter((category) => category.id === selectedProduct.categories[0])[0].is_imei;
            dispatch(listAccessories(selectedProduct.id, is_imei))
        }
    }, [selectedProduct])

    useEffect(() => {
        if (accessories && accessories.productsNonImei && accessories.productsNonImei != undefined) {
            let accessories_products = productsNonImei.filter((product) => accessories.productsNonImei.includes(product.id))
            setRightProducts(accessories_products)
        }
    }, [accessories])

    return (
        <>
            {
                (loading || nonImeiLoading || loadingAccessoriesMaj) ? <Loader /> :
                    <>
                        <Row className="accessories-filters">
                            <h2>Sélectionner un article</h2>
                            <Col md={6}>
                                <ReactSearchAutocomplete
                                    items={(products && products != undefined && products.length > 0 ? products : [])
                                        .concat(productsNonImei && productsNonImei != undefined && productsNonImei.length > 0 ? productsNonImei : [])}
                                    fuseOptions={fuseOptions}
                                    resultStringKeyName="name"
                                    onSearch={handleOnSearchProduct}
                                    onSelect={handleOnSelectProduct}
                                    onFocus={handleOnFocusProduct}
                                    onClear={() => {
                                        setSearchTerm('');
                                        setSelectedHeaderCategory(null);
                                    }}
                                    autoFocus
                                    placeholder={t('Search')}
                                    formatResult={formatResult}
                                    threshold={0.6} // 0 = exact match, 1 = everything matches
                                    ignoreLocation={true}
                                    maxResults={8}
                                    styling={{
                                        width: "100%",
                                        border: "1px solid #dfe1e5",
                                        borderRadius: "5px",
                                        backgroundColor: "white",
                                        boxShadow: "rgba(58, 53, 53, 0.2) 0px 1px 6px 0px",
                                        hoverBackgroundColor: "#eee",
                                        color: "#212121",
                                        fontSize: "12px",
                                        fontFamily: "Arial",
                                        iconColor: "grey",
                                        lineColor: "rgb(232, 234, 237)",
                                        placeholderColor: "grey",
                                        clearIconMargin: '0 5px 0 0',
                                        searchIconMargin: '0 0 0 5px',
                                        zIndex: 5,
                                    }}
                                />
                            </Col>
                            <Col md={6}>
                                {
                                    selectedProduct ?
                                        <Row className='accessories-item'>
                                            <Col md={3}>
                                                <Image className='accessories-img' src={selectedProduct ? selectedProduct.device_image : null} alt={selectedProduct.name} fluid rounded />
                                            </Col>
                                            <Col className='accessories-start-col' md={8}>
                                                <Row>
                                                    <span>{selectedProduct.brand} {selectedProduct.name}</span>
                                                </Row>
                                            </Col>
                                        </Row>
                                        : null
                                }
                            </Col>
                        </Row>
                        {
                            selectedProduct ?
                                <>
                                    <Row className="accessories-filters">
                                        <h2>Sélectionner des accessoires</h2>
                                        <Col md={6}>
                                            <Select
                                                className='categories-select'
                                                name="categories-select"
                                                title="Catégories"
                                                value={selectedHeaderCategory}
                                                options={selectHeaderCategorie}
                                                onChangeFunc={(e) => onHandleHeaderCategorySelectChange(e)}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <ReactSearchAutocomplete
                                                items={filteredProductsData && filteredProductsData != undefined && filteredProductsData.length > 0 ?
                                                    filteredProductsData
                                                    : []}
                                                fuseOptions={fuseOptions}
                                                resultStringKeyName="name"
                                                onSearch={handleOnSearch}
                                                onSelect={handleOnSelect}
                                                onFocus={handleOnFocus}
                                                onClear={() => {
                                                    setProductSearchTerm('');
                                                }}
                                                autoFocus
                                                placeholder={t('Search')}
                                                formatResult={formatResult}
                                                threshold={0.6} // 0 = exact match, 1 = everything matches
                                                ignoreLocation={true}
                                                maxResults={8}
                                                styling={{
                                                    width: "100%",
                                                    border: "1px solid #dfe1e5",
                                                    borderRadius: "5px",
                                                    backgroundColor: "white",
                                                    boxShadow: "rgba(58, 53, 53, 0.2) 0px 1px 6px 0px",
                                                    hoverBackgroundColor: "#eee",
                                                    color: "#212121",
                                                    fontSize: "12px",
                                                    fontFamily: "Arial",
                                                    iconColor: "grey",
                                                    lineColor: "rgb(232, 234, 237)",
                                                    placeholderColor: "grey",
                                                    clearIconMargin: '0 5px 0 0',
                                                    searchIconMargin: '0 0 0 5px',
                                                    zIndex: 5,
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    {
                                        selectedHeaderCategory && selectedHeaderCategory.categories && selectedHeaderCategory.categories.length > 1 &&
                                        <Row className="accessories-filters">
                                            <Col md={8}>
                                                <Select
                                                    className='accessories-accessories-select'
                                                    name="accessories-select"
                                                    title="Sous catégories"
                                                    value={selectedCategory}
                                                    options={selectCategorie}
                                                    onChangeFunc={(e) => onHandleCategorySelectChange(e)}
                                                />
                                            </Col>
                                        </Row>
                                    }
                                    {
                                        selectedHeaderCategory && selectedHeaderCategory.categories ?
                                            ProductsList
                                            :
                                            null
                                    }
                                </>
                                : null
                        }
                        <Row className='accessories-button'>
                            <Col md={2}>
                                <button
                                    className='accessories-btn-cancel'
                                    type='button'
                                    disabled={false}
                                    onClick={reset}
                                >
                                    Reset
                                </button>
                            </Col>
                            <Col md={2}>
                                <button
                                    className='accessories-btn-create'
                                    type='button'
                                    disabled={notValid}
                                    onClick={saveAccessories}
                                >
                                    Valider
                                </button>
                            </Col>
                        </Row>
                    </>
            }
        </>
    )
}

export default Accessories