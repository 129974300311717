import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom';

import DataGrid, { Row as DataGridRow, Column, textEditor } from 'react-data-grid';
import { Row, Col } from 'react-bootstrap'

import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import ReactTooltip from 'react-tooltip';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Select from '../../../../components/Select/Select'

import Modal from 'react-modal';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import 'react-data-grid/lib/styles.css';
import {
    Menu,
    MenuItem,
    List,
    ListItem,
    Collapse,
    ListItemIcon,
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBuilding,
    faUserEdit,
    faUserMinus,
    faEllipsisVertical,
    faFileExcel,
    faXmark,
    faFileCirclePlus,
    faBook,
    faBuildingCircleCheck,
    faBuildingCircleXmark
} from '@fortawesome/free-solid-svg-icons';

import excellogo from '../../../../assets/images/excel-logo.png';

import { listFleetUsers } from '../../../../actions/fleetUsersActions'

import {
    getFleetEntities,
    getFleetEntity,
    addFleetEntity,
    addFleetEntityByFile,
    deleteFleetEntity,
    updateFleetEntity,
    resetFleetEntityAddSuccess,
    resetFleetEntityDeleteSuccess,
    resetFleetEntityUpdateSuccess,
    resetFleetEntityAddByFileSuccess
} from '../../../../actions/fleetEntityActions'

import { listUsers } from '../../../../actions/userActions'

import Loader from '../../../../components/Loader/Loader'

import './FleetEntity.css'
import { listFleet } from '../../../../actions/fleetActions';
import { useTranslation } from 'react-i18next';

function FleetEntity() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const fleetUsersList = useSelector(state => state.fleetUsersList)
    const { error: errorFleetUsers, loading: loadingFleetUsers, fleetUsers } = fleetUsersList

    const userList = useSelector(state => state.userList)
    const { error: errorUsers, loading: loadingUsers, users } = userList

    const fleetEntityList = useSelector(state => state.fleetEntityList)
    const { error, loading, fleetEntities } = fleetEntityList

    const fleetEntityGet = useSelector(state => state.fleetEntityGet)
    const { error: errorfleetEntityGet, loading: loadingfleetEntityGet, fleetEntity } = fleetEntityGet

    const fleetEntityAdd = useSelector(state => state.fleetEntityAdd)
    const { success: successfleetEntityAdd, error: errorfleetEntityAdd, loading: loadingfleetEntityAdd,
        fleetEntity: fleetEntityAddd } = fleetEntityAdd

    const fleetEntityAddByFile = useSelector(state => state.fleetEntityAddByFile)
    const { success: successfleetEntityAddByFile, error: errorfleetEntityAddByFile, loading: fleetEntityAddByFileByFile,
        fleetEntity: fleetEntityAdddByFile } = fleetEntityAdd

    const fleetEntityUpdate = useSelector(state => state.fleetEntityUpdate)
    const { success: successfleetEntityUpdate, error: errorfleetEntityUpdate, loading: loadingfleetEntityUpdate,
        fleetEntity: fleetEntityUpdated } = fleetEntityUpdate

    const fleetEntityDelete = useSelector(state => state.fleetEntityDelete)
    const { success: successfleetEntityDelete, error: errorfleetEntityDelete, loading: loadingfleetEntityDelete } = fleetEntityDelete

    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [openInformationDialog, setOpenInformationDialog] = useState(false);

    const [rows, setRows] = useState([]);
    const [informationMessage, setInformationMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const [modalIsOpen, setIsOpen] = React.useState(false);

    const msgAddentity = t('Add an entity');
    const msgAddentitybyfile = t('Add entities via file');
    const msgExcelDownload = t('Download data to excel file');
    const msgFileExample = t('Download sample file');

    const excelUrl = "http://localhost:8000/media/example-files/importFlotteEntities.csv";

    const [portalEl, setPortalEl] = useState(null);

    const searchKeys = ['name', 'siret']
    const fuseOptions = {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: searchKeys
    };

    useEffect(() => {
        dispatch(getFleetEntities())
        dispatch(listFleetUsers())
        dispatch(listUsers())
    }, [])

    useEffect(() => {
        const el = document.createElement('div');
        document.body.appendChild(el);
        setPortalEl(el);

        return () => {
            document.body.removeChild(el);
        };
    }, []);

    useEffect(() => {
        if (fleetEntities) {
            setRows(fleetEntities)
        }
    }, [fleetEntities])

    useEffect(() => {
        if (informationMessage != '')
            handleInformationDialogOpen();
    }, [errorfleetEntityAdd, errorfleetEntityUpdate, errorfleetEntityDelete])

    useEffect(() => {
        if (rows && rows != undefined && rows.length > 0 && users && users != undefined && users.length > 0) {
            rows.forEach((row, index) => {
                const user = users.find(user => user.id === row.entity_id);
                if (user) {
                    row.entity = user.name;
                }
            });
            setSortedRows(getFormattedRows(rows));
        }
        else if (rows && rows != undefined && rows.length === 0)
            setSortedRows([]);
    }, [rows, users]);

    function getFormattedRows(filteredFleetEntityData) {
        let deviceRows = [];
        filteredFleetEntityData.forEach((item) => {
            let deviceRow = {};
            deviceRow.id = item.id;
            deviceRow.name = item.name ? item.name : '';
            deviceRow.siret = item.siret ? item.siret : '';
            deviceRow.voie1 = item.adress && item.adress.voie1 ? item.adress.voie1 : '';
            deviceRow.voie2 = item.adress && item.adress.voie2 ? item.adress.voie2 : '';
            deviceRow.codePostal = item.adress && item.adress.code_postal ? item.adress.code_postal : '';
            deviceRow.ville = item.adress && item.adress.ville ? item.adress.ville : '';
            deviceRow.pays = item.adress && item.adress.pays ? item.adress.pays : '';

            deviceRows.push(deviceRow);
        });
        return deviceRows;
    }

    useEffect(() => {
        if (successfleetEntityAdd || successfleetEntityDelete || successfleetEntityUpdate || successfleetEntityAddByFile) {
            handleSuccessDialogOpen();
            dispatch(getFleetEntities())

            dispatch(resetFleetEntityAddSuccess());
            dispatch(resetFleetEntityUpdateSuccess());
            dispatch(resetFleetEntityUpdateSuccess());
            dispatch(resetFleetEntityAddByFileSuccess());
        }
    }, [successfleetEntityAdd, successfleetEntityDelete, successfleetEntityUpdate, successfleetEntityAddByFile])

    useEffect(() => {
        if (searchTerm !== '') {

            let list = sortedRows && sortedRows.length > 0 ? sortedRows : rows;
            setSortedRows(list.filter((f) =>
                f.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                f.siret.toLowerCase().includes(searchTerm.toLowerCase())
            ));
        } else {
            setSortedRows(rows);
        }
    }, [searchTerm]);

    const handleSuccessDialogOpen = () => {
        setOpenSuccessDialog(true);
    };

    const handleSuccessDialogClose = () => {
        setOpenSuccessDialog(false);
    };

    const handleInformationDialogOpen = () => {
        setOpenInformationDialog(true);
    };

    const handleInformationDialogClose = () => {
        setOpenInformationDialog(false);
    };

    useEffect(() => {
        const el = document.createElement('div');
        document.body.appendChild(el);
        setPortalEl(el);

        return () => {
            document.body.removeChild(el);
        };
    }, []);

    const SelectEditor = ({ value, onCommit }) => {
        const options = [
            { value: 'option1', label: 'Option 1' },
            { value: 'option2', label: 'Option 2' },
            { value: 'option3', label: 'Option 3' },
        ];

        const handleChange = (selectedOption) => {
            onCommit(selectedOption);
        };

        const customStyles = {
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        };

        return portalEl
            ? ReactDOM.createPortal(
                <Select
                    value={options.find((option) => option.value === value)}
                    onChange={handleChange}
                    options={options}
                    styles={customStyles}
                />,
                portalEl
            )
            : null;
    };

    const columns = [
        {
            key: 'name', name: t('Name'), editable: true,
            editor: textEditor, cellClass: 'cell-align-center',
            headerRenderer: (props) => (
                <HeaderCellRenderer
                    {...props}
                    onHeaderClick={onHeaderClick}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                />
            ),
        },
        {
            key: 'siret', name: 'SIRET', editable: true,
            editor: textEditor, cellClass: 'cell-align-center',
            headerRenderer: (props) => (
                <HeaderCellRenderer
                    {...props}
                    onHeaderClick={onHeaderClick}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                />
            ),
        },
        {
            key: 'voie1', name: t('Street'), editable: true,
            editor: textEditor, cellClass: 'cell-align-center',
            headerRenderer: (props) => (
                <HeaderCellRenderer
                    {...props}
                    onHeaderClick={onHeaderClick}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                />
            ),
        },
        {
            key: 'voie2', name: t('Street 2'), editable: true,
            editor: textEditor, cellClass: 'cell-align-center',
            headerRenderer: (props) => (
                <HeaderCellRenderer
                    {...props}
                    onHeaderClick={onHeaderClick}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                />
            ),
        },
        {
            key: 'codePostal', name: t('Postal Code'), editable: true,
            editor: textEditor, cellClass: 'cell-align-center',
            headerRenderer: (props) => (
                <HeaderCellRenderer
                    {...props}
                    onHeaderClick={onHeaderClick}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                />
            ),
        },
        {
            key: 'ville', name: t('City'), editable: true,
            editor: textEditor, cellClass: 'cell-align-center',
            headerRenderer: (props) => (
                <HeaderCellRenderer
                    {...props}
                    onHeaderClick={onHeaderClick}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                />
            ),
        },
        {
            key: 'pays', name: t('Country'), editable: true,
            editor: textEditor, cellClass: 'cell-align-center',
            headerRenderer: (props) => (
                <HeaderCellRenderer
                    {...props}
                    onHeaderClick={onHeaderClick}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                />
            ),
        },
        {
            key: 'actions',
            name: t('actions'),
            headerRenderer: () => <div style={{ textAlign: 'center' }}>Actions</div>,
            formatter: ({ row }) => <ActionsCell row={row} />,
            cellClass: 'cell-align-center',
            width: 50,
        },
    ];

    const [sortedRows, setSortedRows] = useState(rows);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);

    function HeaderCellRenderer({
        column,
        onHeaderClick,
        sortColumn,
        sortDirection,
    }) {
        const handleClick = () => {
            onHeaderClick(column);
        };

        const isSorted = sortColumn === column.key;

        return (
            <div
                onClick={handleClick}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer',
                }}
            >
                {column.name}
                {isSorted && (sortDirection === 'ASC' ? '▲' : '▼')}
            </div>
        );
    }

    function ActionsCell({ row }) {
        const rowIdx = sortedRows.findIndex((r) => r.id === row.id);
        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleMenuItemClick = (event, setAnchorFunction, currentAnchorEl) => {
            if (currentAnchorEl === event.currentTarget) {
                // Si l'élément de la liste est déjà ouvert, le fermer
                setAnchorFunction(null);
            } else {
                // Sinon, ouvrir le sous-menu pour cet élément de la liste
                setAnchorFunction(event.currentTarget);
            }
        };

        return (
            <div className='clickable-cell'>
                <div onClick={handleClick} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                </div>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    getContentAnchorEl={null}
                    PaperProps={{
                        style: {
                            minWidth: '200px',
                        },
                    }}
                >

                    <List component="div" disablePadding>
                        {row.isNew ? (
                            <>
                                {
                                    row.name != '' ? (
                                        <ListItem button onClick={() => {
                                            handleAddEntity(rowIdx);
                                            handleClose();
                                        }} style={{ paddingLeft: '40px' }}>
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faBuildingCircleCheck} />
                                            </ListItemIcon>
                                            {t('Add')}
                                        </ListItem>
                                    ) : null

                                }

                                <ListItem
                                    button
                                    onClick={() => {
                                        handleCancelChanges(rowIdx);
                                        handleClose();
                                    }}
                                    style={{ paddingLeft: '40px' }}
                                >
                                    <ListItemIcon>
                                        <FontAwesomeIcon icon={faXmark} />
                                    </ListItemIcon>
                                    {t('Discard changes')}
                                </ListItem>
                            </>
                        ) : (
                            <>
                                <ListItem
                                    button
                                    onClick={() => {
                                        handleCancelChanges(rowIdx);
                                        handleClose();
                                    }}
                                    style={{ paddingLeft: '40px' }}
                                >
                                    <ListItemIcon>
                                        <FontAwesomeIcon icon={faXmark} />
                                    </ListItemIcon>
                                    {t('Discard changes')}
                                </ListItem>
                                <ListItem button onClick={() => {
                                    handleUpdate(rowIdx);
                                    handleClose();
                                }} style={{ paddingLeft: '40px' }}>
                                    <ListItemIcon>
                                        <FontAwesomeIcon icon={faBuildingCircleCheck} />
                                    </ListItemIcon>
                                    {t('Update')}
                                </ListItem>

                                <ListItem button onClick={() => {
                                    handleDelete(rowIdx);
                                    handleClose();
                                }} style={{ paddingLeft: '40px' }}>
                                    <ListItemIcon>
                                        <FontAwesomeIcon icon={faBuildingCircleXmark} />
                                    </ListItemIcon>
                                    {t('Delete')}
                                </ListItem>
                            </>
                        )}
                    </List>
                </Menu>
            </div>
        );

    }

    const onHeaderClick = (column) => {
        let newSortDirection = sortDirection === 'ASC' ? 'DESC' : 'ASC';
        if (sortColumn !== column.key) {
            newSortDirection = 'ASC';
        }
        setSortColumn(column.key);
        setSortDirection(newSortDirection);
        handleSort(column.key, newSortDirection);
    };

    const handleSort = (columnKey, direction) => {
        const sortedData = [...sortedRows].sort((a, b) => {
            if (direction === 'ASC') {
                return a[columnKey].localeCompare(b[columnKey]);
            } else {
                return b[columnKey].localeCompare(a[columnKey]);
            }
        });

        setSortedRows(sortedData);
    };

    function handleCancelChanges(rowIdx) {
        const newRowData = [...sortedRows];

        if (newRowData[rowIdx].isNew) {
            newRowData.splice(rowIdx, 1);
        } else {
            // Remettre les valeurs d'origine pour la ligne modifiée
            let newObj = getFormattedRows([fleetEntities[rowIdx]])[0]
            newRowData[rowIdx] = { ...newObj };
        }

        setSortedRows(newRowData);
    }

    function handleAddRow() {
        const newRow = { id: getNewId(), lastname: '', firstname: '', entity: '', role: '', isNew: true };
        setSortedRows([...sortedRows, newRow]);
    }

    const handleFileImport = (event) => {

        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            // Utilisez TextDecoder pour décoder les données avec l'encodage UTF-8
            const decoder = new TextDecoder('utf-8');
            const decodedData = decoder.decode(e.target.result);

            // Utilisez les données décodées pour lire le fichier XLSX
            const workbook = XLSX.read(decodedData, { type: 'binary' });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            const newRows = rows.slice(1).map((row) => {
                // Remplacer les cellules non remplies par des chaînes vides
                const sanitizedRow = row.map((cell) => (cell === undefined || cell === null ? '' : cell));


                return {
                    name: sanitizedRow[0],
                    siret: sanitizedRow[1],
                    adress: {
                        voie1: sanitizedRow[2],
                        voie2: sanitizedRow[3],
                        code_postal: sanitizedRow[4],
                        ville: sanitizedRow[5],
                        pays: sanitizedRow[6],
                    }
                };
            });

            dispatch(addFleetEntityByFile(newRows));
            setIsOpen(false);
        };
        reader.readAsArrayBuffer(file);
    };

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handleAddEntity = (rowIdx) => {
        let row = sortedRows[rowIdx];
        if (row.name === '')
            setInformationMessage(t('Please fill in the name field'));
        else if (row.voie1 === '' || row.codePostal === '' || row.ville === '')
            setInformationMessage(t('Please fill in the address fields'));
        else {
            let adress = null

            if (row.voie1 !== '' && row.codePostal !== '' && row.ville !== ''
                && row.voie1 !== undefined && row.codePostal !== undefined && row.ville !== undefined) {
                adress = {
                    voie1: row.voie1,
                    voie2: row.voie2,
                    code_postal: row.codePostal,
                    ville: row.ville,
                    pays: row.pays
                }
            }

            let newObj = {
                name: row.name,
                siret: row.siret,
                adress: adress
            }
            dispatch(addFleetEntity(newObj));
        }

    };

    const handleUpdate = (rowIdx) => {
        let row = sortedRows[rowIdx];
        if (row.name === '')
            setInformationMessage(t('Please fill in the name field'));
        else if (row.voie1 === '' || row.codePostal === '' || row.ville === '')
            setInformationMessage(t('Please fill in the address fields'));
        else {
            let newObj = {
                id: row.id,
                name: row.name,
                siret: row.siret,
                adress: {
                    voie1: row.voie1,
                    voie2: row.voie2,
                    code_postal: row.codePostal,
                    ville: row.ville,
                    pays: row.pays
                }
            }
            dispatch(updateFleetEntity(newObj));
        };
    };

    const handleDelete = (rowIdx) => {
        let row = sortedRows[rowIdx];
        dispatch(deleteFleetEntity(row.id));
    };

    function getNewId() {
        const ids = sortedRows.map((row) => row.id);
        const maxId = Math.max(...ids);
        return maxId + 1;
    }

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        if (string.length === 0 || string === "") {
            setSearchTerm('');
            dispatch(getFleetEntities());
        }
        //setImeiInput(string);
        setSearchTerm(string);
    };

    const handleOnSelect = (item) => {
        // the item selected
        setSortedRows(sortedRows.filter((user) => user.id === item.id));
    };

    const handleOnFocus = () => {

    };

    const formatResult = (item) => {
        return (
            <>
                {
                    item && item != undefined ? (
                        <span>{item.name}    ---    {item.siret}</span>
                    ) : null
                }

            </>
        )
    };

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(sortedRows);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        const s2ab = (s) => {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i < s.length; i++) {
                view[i] = s.charCodeAt(i) & 0xff;
            }
            return buf;
        };

        saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), 'flotte-entités.xlsx');
    };

    return (
        <div>
            <Row className='fleet-users-btn-row'>
                <Col md={1}>
                    <button
                        className='fleet-add-btn'
                        onClick={handleAddRow}
                        data-tip="React-tooltip"
                        data-for={'tooltip-adduser'}
                    ><FontAwesomeIcon icon={faBuilding} /></button>
                    <ReactTooltip id={'tooltip-adduser'}
                        type="dark"
                        effect="solid"
                        clickable={true}
                        place='bottom'
                        getContent={function () {
                            return (
                                <div>
                                    <Row>{msgAddentity}</Row>
                                </div>
                            )
                        }} />
                </Col>
                <Col md={1}>
                    <button
                        className='fleet-add-btn'
                        onClick={openModal}
                        data-tip="React-tooltip"
                        data-for={'tooltip-adduserbyfile'}
                    ><FontAwesomeIcon icon={faFileCirclePlus} /></button>
                    <ReactTooltip id={'tooltip-adduserbyfile'}
                        type="dark"
                        effect="solid"
                        clickable={true}
                        place='bottom'
                        getContent={function () {
                            return (
                                <div>
                                    <Row>{msgAddentitybyfile}</Row>
                                </div>
                            )
                        }} />
                </Col>
                <Col md={9}>
                    <ReactSearchAutocomplete
                        items={sortedRows && sortedRows != undefined && sortedRows.length > 0 ? sortedRows : []}
                        fuseOptions={fuseOptions}
                        resultStringKeyName="name"
                        onSearch={handleOnSearch}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        onClear={() => { setSearchTerm(''); dispatch(listFleetUsers()); }}
                        autoFocus
                        placeholder={t('Search')}
                        formatResult={formatResult}
                        threshold={0} // 0 = exact match, 1 = everything matches
                        ignoreLocation={true}
                        maxResults={8}
                        styling={{
                            width: "100%",
                            border: "1px solid #dfe1e5",
                            borderRadius: "5px",
                            backgroundColor: "white",
                            boxShadow: "rgba(58, 53, 53, 0.2) 0px 1px 6px 0px",
                            hoverBackgroundColor: "#eee",
                            color: "#212121",
                            fontSize: "12px",
                            fontFamily: "Arial",
                            iconColor: "grey",
                            lineColor: "rgb(232, 234, 237)",
                            placeholderColor: "grey",
                            clearIconMargin: '0 5px 0 0',
                            searchIconMargin: '0 0 0 5px',
                            zIndex: 10,
                        }}
                    />
                </Col>
                <Col md={1}>
                    <button
                        onClick={exportToExcel}
                        className='btn-excel-export'
                        data-tip="React-tooltip" data-for={'tooltip-exceldownload'}
                    >
                        <FontAwesomeIcon icon={faFileExcel} size="xl" />
                    </button>
                    <ReactTooltip id={'tooltip-exceldownload'}
                        type="dark"
                        effect="solid"
                        clickable={true}
                        place='bottom'
                        getContent={function () {
                            return (
                                <div>
                                    <Row>{msgExcelDownload}</Row>
                                </div>
                            )
                        }} />
                </Col>
            </Row>
            <Row className='fleet-data-row'>
                {
                    loading || loadingfleetEntityAdd || loadingfleetEntityDelete || loadingfleetEntityGet || loadingFleetUsers ? <Loader /> :
                        sortedRows && sortedRows.length > 0 ? (
                            <DataGrid
                                className="rdg-light fleet-users-datagrid"
                                columns={columns}
                                rows={sortedRows}
                                rowGetter={(i) => sortedRows[i]}
                                rowsCount={sortedRows.length}
                                onRowsChange={setSortedRows}
                                onHeaderClick={onHeaderClick}
                                onGridRowsUpdated={setSortedRows}
                            />
                        ) : (
                            <>
                                <div className="no-users-container">Vous n'avez pas encore d'entités enregistrées.</div>
                            </>
                        )

                }
            </Row>
            <Dialog
                open={openSuccessDialog}
                onClose={handleSuccessDialogClose}
                aria-labelledby="success-dialog-title"
                aria-describedby="success-dialog-description"
            >
                <DialogTitle id="success-dialog-title">{t('Successful operation')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="success-dialog-description">
                        {t('The operation was successfully completed.')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSuccessDialogClose} color="primary">
                    {t('Close')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openInformationDialog}
                onClose={handleInformationDialogClose}
                aria-labelledby="success-dialog-title"
                aria-describedby="success-dialog-description"
            >
                <DialogTitle id="success-dialog-title">{t('Information')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="success-dialog-description">
                        {informationMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSuccessDialogClose} color="primary">
                    {t('Close')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Modal
                className="fleet-users-file-modal"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel={t('Import csv file')}
                style={{ overlay: { zIndex: 9999 } }}
            >
                <div className="modal-content">
                    <Row className='fleet-users-modal-row'>
                        <Col md={8}>
                            <div className='fleet-users-modal-title'>{t('Import csv file')}</div>
                        </Col>
                        <Col className='fleet-users-modal-file-example' md={2}>
                            {/* TODO : A gérer depuis les fichiers statiques de Django */}
                            <a href={excelUrl} download>
                                <img className='fleet-users-excel-example'
                                    src={excellogo}
                                    alt="example"
                                    data-tip="React-tooltip"
                                    data-for={'tooltip-file-example'}
                                />

                                <ReactTooltip id={'tooltip-file-example'}
                                    type="dark"
                                    effect="solid"
                                    clickable={true}
                                    place='bottom'
                                    getContent={function () {
                                        return (
                                            <div>
                                                <Row>{msgFileExample}</Row>
                                            </div>
                                        )
                                    }} />
                            </a>
                        </Col>
                    </Row>
                    <Row className='fleet-users-modal-row'>
                        <Col md={6}>
                            <input className='fleet-users-modal-input' type="file" accept=".xls,.xlsx,.csv" onChange={handleFileImport} />
                        </Col>
                    </Row>
                    <Row className='fleet-users-modal-row'>
                        <Col md={6}>
                            <button className='fleet-users-modal-close-btn' onClick={closeModal}>{t('Close')}</button>
                        </Col>
                    </Row>

                </div>
            </Modal>
        </div>
    )
}

export default FleetEntity