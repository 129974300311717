import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Row, Col, ListGroup, Image, Card, Container } from 'react-bootstrap'
import useFileUpload from 'react-use-file-upload'
import { useDispatch, useSelector } from 'react-redux'

import Switch from "react-switch";

import uuid from 'react-uuid';

import Message from '../../../../../components/Message/Message';
import Loader from '../../../../../components/Loader/Loader';
import Select from '../../../../../components/Select/Select';

import { listCategories, addCategorie, updateCategories, deleteCategories } from '../../../../../actions/categoriesActions';
import { uploadImage, updateImage, deleteImage } from '../../../../../actions/imageActions';

import './ImageTuiles.css';
import '../Images.css'

function ImageTuiles() {
  const searchRef = useRef(null);
  const dispatch = useDispatch()
  const inputRef = useRef();

  const [toggleChecked, setToggleChecked] = useState(false);
  const [categorie, setCategorie] = useState({})
  const [selectedCategorie, setSelectedCategorie] = useState('')
  const [selectCategories, setSelectCategories] = useState([])

  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile
  } = useFileUpload();

  const listImage = useSelector(state => state.imageList)
  const { images } = listImage

  const categoriesList = useSelector(state => state.categoriesList)
  const { loading, categories } = categoriesList

  const categoriesDelete = useSelector(state => state.categoriesDelete)
  const { success } = categoriesDelete

  const categoriesCreate = useSelector(state => state.categoriesCreate)
  const { successCreate } = categoriesCreate

  useEffect(() => {
    dispatch(listCategories())
  }, [])

  useEffect(() => {
    if (success || successCreate) {
      resetScreen()
    }
  }, [success, successCreate])

  useEffect(() => {
    if (categories) {
      let values = []
      categories.sort((a, b) => a.id - b.id).forEach(category => {
        values.push({ value: category.id, label: category.name })
      })
      setSelectCategories(values)
    }
  }, [categories])

  useEffect(() => {
    if (categorie) {
      
      
    }
  }, [categorie])

  useEffect(() => {
    
    
    if (categories && categories.length > 0) {
      categories.forEach(element => {
        if (element.id === selectedCategorie) {
          setCategorie(element)
        }
      });
    }
  }, [selectedCategorie])

  function resetScreen() {
    setSelectedCategorie('')
    setCategorie({})
    setSelectCategories(...categories, [])
    dispatch(listCategories())
  }

  const handleFileUpload = (e) => {
    
    let i = 0
    let folder = 'tuiles'
    if (toggleChecked) {
      files.forEach(image => {
        let formData = new FormData();
        let extension = image.name.split('.')[image.name.split('.').length - 1]
        let image_name = uuid() + '.' + extension

        formData.append('image', image, image_name);
        formData.append('folder', folder);

        let subFolders = [categorie.name]
        subFolders.forEach((element, index) => {
          formData.append(`subFolders[${index}]`, element);
        });

        
        

        dispatch(uploadImage(formData)).then(() => {
          let category_url = folder + '/' + categorie.name + '/' + image_name
          dispatch(addCategorie(categorie.name, category_url, categorie.is_odoo, categorie.is_imei, categorie.is_product_category))
          .then(() => {
            dispatch(listCategories())
          })
        })
        i++
       
      });
    }
    else {
      files.forEach(image => {
        let formData = new FormData();
        let extension = image.name.split('.')[image.name.split('.').length - 1]

        let image_name = uuid() + '.' + extension
        formData.append('image', image, image_name);
        formData.append('folder', folder);

        let subFolders = [categorie.name]
        subFolders.forEach((element, index) => {
          formData.append(`subFolders[${index}]`, element);
        });

        let category_url = folder + '/' + categorie.name + '/' + image_name

        dispatch(updateCategories(categorie.id, categorie.name, category_url, categorie.is_odoo, categorie.is_imei, categorie.is_product_category))
        .then(() => {
          dispatch(updateImage(formData)).then(() => {
            
            setSelectedCategorie('')
            dispatch(listCategories())
          })
        })
        i++
      });
    }
    setSelectedCategorie(null)
    setCategorie({})
    clearAllFiles()
    dispatch(listCategories())
  };

  const removeFromImagesHandler = (id) => {
    dispatch(deleteImage('tuiles/' + categorie.name)).then(() => {
      dispatch(deleteCategories(id))
    })
  }

  const handleToggleChange = (checked) => {
    setToggleChecked(checked);
  };

  const onHandleCategorySelectChange = useCallback((value, name) => {
    setSelectedCategorie(value)
  });

  const handleCategorie = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setCategorie({ ...categorie, [name]: newValue });
  }

  return (
    <div className='images-container'>
      <Row className='images-title-row'>
        <h1>Images Tuiles Accueil</h1>
      </Row>
      <Row className='imagestuiles-main-row'>
        <Row className='imagestuiles-first-row'>
          <Row className='imagestuiles-switch-row'>
            <Col md={4}>
              <Switch
                className='imagestuiles-switch'
                onChange={handleToggleChange}
                checked={toggleChecked}
                offColor="#2f86ca"
                boxShadow="0px 5px 10px rgba(0, 0, 0, 0.6)"
              />
              <span>{toggleChecked ? 'Nouvelle catégorie' : 'MAJ catégorie'}</span>
            </Col>
            <Col md={4}>
              {!toggleChecked && selectedCategorie && categorie != '' ?
                <Image className='imagestuiles-tuiles-img' src={categorie.image_url} alt={categorie.image_url} fluid rounded />
                : null
              }
            </Col>
          </Row>
          <Row className='imagestuiles-input-row'>
            {
              toggleChecked ?
                <>
                  <Col md={4}>
                    <input type="text"
                      placeholder='Nom catégorie'
                      className="imagestuiles-input-categorie"
                      name={'name'}
                      value={categorie.name}
                      onChange={(e) => handleCategorie(e)} />
                  </Col>
                  <Col>
                    <div>
                      <input
                        id='is_odoo'
                        type="checkbox"
                        name='is_odoo'
                        onChange={(e) => handleCategorie(e)}
                        value={categorie.is_odoo}
                      />
                      <label className='imagestuiles-label-checkbox' htmlFor='is_odoo'>Catégorie Odoo</label>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <input
                        id='is_product_category'
                        type="checkbox"
                        name='is_product_category'
                        onChange={(e) => handleCategorie(e)}
                        value={categorie.is_product_category}
                      />
                      <label className='imagestuiles-label-checkbox' htmlFor='is_product_category'>Catégorie produit</label>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <input
                        id='is_imei'
                        type="checkbox"
                        name='is_imei'
                        onChange={(e) => handleCategorie(e)}
                        value={categorie.is_imei}
                      />
                      <label className='imagestuiles-label-checkbox' htmlFor='is_imei'>Liée à un produit imei</label>
                    </div>
                  </Col>
                </>
                :
                <>
                  <Col md={6}>
                    <Select
                      className='imagestuiles-select'
                      name="categorie-select"
                      title="Catégories"
                      value={categorie}
                      options={selectCategories}
                      onChangeFunc={onHandleCategorySelectChange}
                    />
                  </Col>
                  <Col className='imagestuiles-delete-col' md={2}>
                    <button
                      className="imagestuiles-remove"
                      disabled={categorie.is_odoo}
                      onClick={() => dispatch(removeFromImagesHandler(categorie.id))}>
                      <i className='fas fa-trash'></i>
                    </button>
                  </Col>
                </>
            }
          </Row>
        </Row>
        <Row className='imagestuiles-file-container'>
          <div
            className='imagestuiles-list-files-container'
          >
            <Row className='imagestuiles-list-files-row'>
              <ul>
                <p className='imagestuiles-list-files-title'>Images à envoyer</p>
                {fileNames.map((name) => (
                  <li key={name}>
                    <span>{name}</span>

                    <span onClick={() => removeFile(name)}>
                      <i className="fa-regular fa-trash-can file-remove"></i>
                    </span>
                  </li>
                ))}
              </ul>
            </Row>
            {
              (selectedCategorie && selectedCategorie != '') || toggleChecked ?
                <Row className='imagestuiles-btn_addfiles-row'>
                  <button className='imagestuiles-btn-add-file' onClick={() => handleFileUpload()} disabled={!categorie.name}>Ajouter</button>
                </Row>
                : null
            }
          </div>

          {/* Provide a drop zone and an alternative button inside it to upload files. */}
          <div
            className='imagestuiles-drop-zone'
            onDragEnter={handleDragDropEvent}
            onDragOver={handleDragDropEvent}
            onDrop={(e) => {
              handleDragDropEvent(e);
              setFiles(e, 'a');
            }}
          >
            <p>Déposez vos fichiers ici</p>

            <button className='imagestuiles-btn-select-file' onClick={() => inputRef.current.click()}>Sélectionner un fichier</button>

            <input
              ref={inputRef}
              type="file"
              multiple
              style={{ display: 'none' }}
              onChange={(e) => {
                setFiles(e, 'a');
                inputRef.current.value = null;
              }}
            />
          </div>
        </Row>
      </Row >
    </div >
  )
}

export default ImageTuiles