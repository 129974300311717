// filterType values
// gender, color, language
export const toArrayKeys = (filterType) => {
  const filterTypeKeys = Object.keys(filterType);
  return Object.keys(filterType);
};

// filterType values
// gender, color, language
export const toArrayValues = (filterType) => {
  return Object.values(filterType);
};



export const findVal = (object, key) => {
  var value;
  Object.keys(object).some(function (k) {
    if (k === key) {
      value = object[k];
      return true;
    }
    if (object[k] && typeof object[k] === 'object') {
      value = findVal(object[k], key);
      return value !== undefined;
    }
  });
  return value;
};

export const removeDuplicates = (arr) => {
  let unique_array = arr.filter(function (elem, index, self) {
    return index === self.indexOf(elem);
  });
  return unique_array;
}

export const removeDuplicatesByProperties = (arr, prop) => {
  let obj = {};
  let newArr = [];
  if (arr && arr.length > 0) {
    for (let i = 0, len = arr.length; i < len; i++)
      obj[arr[i][prop]] = arr[i];

    for (let key in obj)
      newArr.push(obj[key]);
  }

  return newArr;
}

export const removeDuplicatesByNestedProperty = (arr, prop) => {
  const map = new Map();
  const result = [];

  function recursiveCheck(obj) {
    for (let key in obj) {
      const value = obj[key];
      if (typeof value === 'object') {
        recursiveCheck(value);
      } else if (key === prop) {
        if (!map.has(value)) {
          map.set(value, true);
          result.push(obj);
        }
      }
    }
  }

  arr.forEach((obj) => recursiveCheck(obj));
  return result;
}

export const checkProperties = (obj, ...keys) => {
  if (obj && keys && keys.length > 0) {
    for (let i = 0; i < keys.length; i++) {
      if (!obj || !obj.hasOwnProperty(keys[i])) {
        return false;
      }
      obj = obj[keys[i]];
    }
    return true;
  }
  else
    return false;
}

export function pluralize(word) {
  // Vérifiez si le mot est au singulier. Cette condition peut être ajustée en fonction des règles de votre langue.
  if (word && word[word.length - 1] !== 's') {
    return word + 's';
  }
  return word;
}

export const arraysAreEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i].name !== arr2[i].name || arr1[i].value !== arr2[i].value) {
      return false;
    }
  }
  return true;
}