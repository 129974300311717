import {
    ACCESSORIES_LIST_REQUEST,
    ACCESSORIES_LIST_SUCCESS,
    ACCESSORIES_LIST_FAIL,
    
    ACCESSORIES_MAJ_REQUEST,
    ACCESSORIES_MAJ_SUCCESS,
    ACCESSORIES_MAJ_FAIL,
    ACCESSORIES_MAJ_RESET
}
    from '../constants/accessoriesConstants';

import axios from 'axios';

export const listAccessories = (id_product, is_imei) => async (dispatch, getState) => {
    try {
        dispatch({ type: ACCESSORIES_LIST_REQUEST })
        
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            `/api/accessories?id_product=${id_product}&is_imei=${is_imei}`
            )

        dispatch({
            type: ACCESSORIES_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: ACCESSORIES_LIST_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const majAccessories = (product_id, is_imei, accessories_products) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ACCESSORIES_MAJ_REQUEST,
        });
        
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/accessories/maj/`,
            { 'product_id': product_id, 'is_imei': is_imei, 'accessories_products': accessories_products })

        dispatch({
            type: ACCESSORIES_MAJ_SUCCESS,
            payload: data,
        });

        dispatch({ type: ACCESSORIES_MAJ_RESET })
    } catch (error) {
        dispatch({
            type: ACCESSORIES_MAJ_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const majAccessoriesReset = () => (dispatch) => {
    dispatch({ type: ACCESSORIES_MAJ_RESET })
}