import { CirclePicker } from 'react-color'
import { useState } from 'react'
import Container from 'react-bootstrap/Container'

import './ColorSelector.css'


function ColorSelector(props) {

    const [circleColor, setCircleColor] = useState();
    //const colors = ["#000000", "#F2F2F2", "#0000FF", "#B5B5B5"];
    const titleContent = "Couleurs"


    const selectColor = (color) => {
        setCircleColor(color);
        props.onSubmit(color);
    }

    return (
        <div className='circle-picker'>
            {
                (props.title === "true") ?
                        <h3 className='color-title'>
                            {titleContent}
                        </h3>
                    :
                    ""
            }

            <Container className='circle-picker-container'>
                {/* <p>Couleurs dispo : {circleColor}</p> */}

                <CirclePicker
                    color={circleColor}
                    colors={props.colors}
                    onChange={(props.select === "true") ? (e) => selectColor(e.hex) : null}
                    circleSpacing={15}
                    circleSize={20}
                />
            </Container>
        </div>
    )
}

export default ColorSelector