import React from "react";
import {
    Container,
    CenteredPhoto,
    ThumbsContainer,
    Thumb,
    Lightbox
} from "./Elements";
import { AnimatePresence, motion, AnimateSharedLayout } from "framer-motion";
import './Gallery.css'

function Gallery({ urls }) {

    const variants = {
        enter: {
            scale: 1.05,
            boxShadow: "0px 0px 3px 1px rgba(74,20,140,1)",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
        },
        exit: { scale: 1, boxShadow: "0px", border: "0px" }
    };

    const [selectedPhoto, setSelectedPhoto] = React.useState(0);
    const [lightboxOpen, setLightboxOpen] = React.useState(false);

    React.useEffect(() => {
        if (lightboxOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [lightboxOpen]);

    return (
        <AnimateSharedLayout type="crossfade">
            <Container>
                <div style={{ display: "flex" }}>
                    <ThumbsContainer>
                        {urls.map((url, i) => (
                            <Thumb
                                key={`thumb-${i}`}
                                variants={variants}
                                onMouseOver={() => setSelectedPhoto(i)}
                                whileTap={{ scale: 1 }}
                                onClick={() => setLightboxOpen(true)}
                                animate={selectedPhoto === i ? "enter" : "exit"}
                            >
                                {selectedPhoto !== i && <div className="outline" />}
                                <img src={url} />
                            </Thumb>
                        ))}
                    </ThumbsContainer>
                    <CenteredPhoto layoutId="photoContainer">
                        <AnimatePresence initial={false}>
                            <motion.div
                                key={`image-${selectedPhoto}`}
                                className="photo"
                                exit={{ opacity: 0, height: 0 }}
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: "100%" }}
                                transition={{ height: { duration: 0 } }}
                            >
                                <motion.img
                                    src={urls[selectedPhoto]}
                                    layoutId={`image-${selectedPhoto}`}
                                    onClick={() => setLightboxOpen(true)}
                                />
                            </motion.div>
                        </AnimatePresence>
                    </CenteredPhoto>
                </div>
            </Container>
            <AnimatePresence>
                {lightboxOpen && (
                    <Lightbox
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}                     
                    >
                        <div className="close" onClick={() => setLightboxOpen(false)} />
                        <motion.div className="photoContainer">
                            <motion.img
                                layoutId={`image-${selectedPhoto}`}
                                src={urls[selectedPhoto]}
                                transition={{ duration: 0.2 }}
                            />
                        </motion.div>
                    </Lightbox>
                )}
            </AnimatePresence>
        </AnimateSharedLayout>
    );
}

export default Gallery;