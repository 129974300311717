import React, { useEffect, useState } from 'react';
import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/system';

import { listFleetUsers } from '../../actions/fleetUsersActions';

const DeviceHistoryItem = ({ historyItem, isCurrentData }) => {
    const dispatch = useDispatch();

    const fleetUsersList = useSelector(state => state.fleetUsersList)
    const { error, loading, fleetUsers } = fleetUsersList

    const [operationName, setOperationName] = useState('');
    const [date, setDate] = useState(null);
    const [order, setOrder] = useState(null);
    const [user, setUser] = useState(null);
    const [updatedBy, setUpdatedBy] = useState(null);

    useEffect(() => {


        if (fleetUsers && fleetUsers.length === 0)
            dispatch(listFleetUsers());
    }, []);

    useEffect(() => {
        if (fleetUsers && fleetUsers.length > 0)
            setOperationName(getOperationName());
    }, [fleetUsers]);

    useEffect(() => {
        
    }, [date]);

    const HighlightedTableRow = styled(TableRow)({
        backgroundColor: '#CCFAD2', // Couleur de fond de votre choix pour mettre en valeur la ligne
    });

    const TableRowComponent = isCurrentData ? HighlightedTableRow : TableRow;

    function getOperationName() {
        
        
        setUpdatedBy(historyItem.updated_by ? historyItem.updated_by.name : null);

        if (historyItem.add_to_fleet) {
            
            setDate(new Date(historyItem.add_to_fleet).toLocaleDateString('fr-FR'));
            setOrder(historyItem.new_value ? historyItem.new_value : 'ajout manuel');
            return historyItem.new_value ? 'Ajouté automatiquement' : 'Ajouté au parc';
        }
        else if (historyItem.remove_from_fleet) {
            setDate(new Date(historyItem.remove_from_fleet).toLocaleDateString('fr-FR'));
            return 'Retiré du parc';
        }
        else if (historyItem.update_fleet_user) {
            setDate(new Date(historyItem.update_fleet_user).toLocaleDateString('fr-FR'));
            let fleet_user = fleetUsers.find(fleetUser => fleetUser.id === historyItem.new_value);
            setUser(fleet_user ? fleet_user.firstname + ' ' + fleet_user.lastname : 'Non affecté');
            return 'Changement d\'utilisateur';
        }

    }
    return (
        <TableRowComponent>
            <TableCell>{operationName ? `${operationName}` : null}</TableCell>
            <TableCell>{date ? `Date: ${date}` : null}</TableCell>
            <TableCell>{order ? `${order}` : null}</TableCell>
            <TableCell>{user ? `${user}` : null}</TableCell>
            <TableCell>{updatedBy ? `${updatedBy}` : null}</TableCell>
        </TableRowComponent>
    );
}

export default DeviceHistoryItem;
