export const WARRANTY_LIST_REQUEST = 'WARRANTY_LIST_REQUEST'
export const WARRANTY_LIST_SUCCESS = 'WARRANTY_LIST_SUCCESS'
export const WARRANTY_LIST_FAIL = 'WARRANTY_LIST_FAIL'

export const WARRANTY_BY_ID_REQUEST = 'WARRANTY_BY_ID_REQUEST'
export const WARRANTY_BY_ID_SUCCESS = 'WARRANTY_BY_ID_SUCCESS'
export const WARRANTY_BY_ID_FAIL = 'WARRANTY_BY_ID_FAIL'

export const WARRANTY_ADD_REQUEST = 'WARRANTY_ADD_REQUEST'
export const WARRANTY_ADD_SUCCESS = 'WARRANTY_ADD_SUCCESS'
export const WARRANTY_ADD_FAIL = 'WARRANTY_ADD_FAIL'

export const WARRANTY_GET_BY_IMEI_REQUEST = 'WARRANTY_GET_BY_IMEI_REQUEST'
export const WARRANTY_GET_BY_IMEI_SUCCESS = 'WARRANTY_GET_BY_IMEI_SUCCESS'
export const WARRANTY_GET_BY_IMEI_FAIL = 'WARRANTY_GET_BY_IMEI_FAIL'