import axios from 'axios';
import {
    ORDER_LIST_REQUEST,
    ORDER_LIST_SUCCESS,
    ORDER_LIST_FAIL,

    ORDER_CREATE_REQUEST,
    ORDER_CREATE_SUCCESS,
    ORDER_CREATE_FAIL,
    ORDER_CREATE_SUCCESS_RESET,

    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAIL,

    ORDER_PAY_REQUEST,
    ORDER_PAY_SUCCESS,
    ORDER_PAY_FAIL,
    ORDER_PAY_RESET,

    ORDER_BC_REQUEST,
    ORDER_BC_SUCCESS,
    ORDER_BC_FAIL,

    ORDER_BC_CREATE_REQUEST,
    ORDER_BC_CREATE_SUCCESS,
    ORDER_BC_CREATE_FAIL,

} from "../constants/orderConstants";

export const listOrders = () => async (dispatch, getState) => {
    try {
        dispatch({ type: ORDER_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
            params: {
                partner_id: userInfo.id_odoo
            }
        })

        const { data } = await instance.get('api/orders/')
        dispatch({ type: ORDER_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: ORDER_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        })
    }
}

export const listOrdersBC = () => async (dispatch, getState) => {
    try {
        dispatch({ type: ORDER_BC_REQUEST })
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get('api/orders/getBC/')
        dispatch({ type: ORDER_BC_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: ORDER_BC_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        })
    }
}

export const createOrder = (order) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_CREATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/orders/add/`,
            order,
            )

        dispatch({
            type: ORDER_CREATE_SUCCESS,
            payload: data,
        })

        localStorage.setItem('orderCreate', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: ORDER_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createOrderBC = (numbers, BC_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_BC_CREATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/orders/addBC/`,
            {user_id: userInfo.id, numbers: numbers, BC_id: BC_id},
            )

        dispatch({
            type: ORDER_BC_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: ORDER_BC_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getOrderDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_DETAILS_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(`/api/orders/${id}/`)

        dispatch({
            type: ORDER_DETAILS_SUCCESS,
            payload: data,
        })

        localStorage.setItem('orderDetails', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: ORDER_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const payOrder = (orderId, paymentResult) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_PAY_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.put(
            `/api/orders/${orderId}/pay/`,
            paymentResult)

        dispatch({
            type: ORDER_PAY_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: ORDER_PAY_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const resetOrderCreate = () => (dispatch) => {
    dispatch({ type: ORDER_CREATE_SUCCESS_RESET })
}

