import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Container, Row, Col, Carousel } from 'react-bootstrap'
import Categorie from '../../components/Tuiles/Categorie'
import Brand from '../../components/Tuiles/Brand'

import Loader from '../../components/Loader/Loader'

import { listBanner } from '../../actions/bannerActions';
import { listHeaderCategories } from '../../actions/headerCategoriesActions';
import { listProducts, listNonImeiProducts } from '../../actions/productActions';

import './Home.css'

function Home() {
    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const listImage = useSelector(state => state.bannerList)
    const { loading, error, banner } = listImage

    const headerCategoriesList = useSelector((state) => state.headerCategoriesList);
    const { loading: loadingHeaderCategories, error: errorHeaderCategories, headerCategories } = headerCategoriesList;

    const productNonImeiList = useSelector((state) => state.productNonImeiList);
    const { loading: loadingNonImei, error: errorNonImei, products: productsNonImei } = productNonImeiList;

    const productList = useSelector((state) => state.productList);
    const { loading: loadingProducts, error: errorProducts, products } = productList;

    useEffect(() => {
        dispatch(listBanner());
        dispatch(listHeaderCategories());
    }, [])

    return (
        <>
            {loading ? <Loader /> :
                <Container fluid>
                    <Row>
                        <Carousel className="home-banner-carousel">
                            {banner && banner.map(b => (
                                <Carousel.Item key={b.id}>
                                    <img className="d-block w-100 home-banner-img" src={b.url} alt={b.id} />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Row>
                    <Row className='home-categorie-row justify-content-around'>
                        {headerCategories && headerCategories.map(categorie => (
                            <Col key={categorie.id} xs={6} sm={4} md={4} lg={3} xl={2}>
                                <Categorie categorie={categorie} />
                            </Col>
                        ))}
                    </Row>
                </Container>
            }
        </>
    )
}

export default Home