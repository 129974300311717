import {
    AFTERSALE_ADD_DEMAND,
    AFTERSALE_REMOVE_DEMAND,
    AFTERSALE_RESET,


    // constantes de configuration

    AFTERSALE_CONFIG_LIST_REQUEST,
    AFTERSALE_CONFIG_LIST_SUCCESS,
    AFTERSALE_CONFIG_LIST_FAIL,

    AFTERSALE_CONFIG_ADD_REQUEST,
    AFTERSALE_CONFIG_ADD_SUCCESS,
    AFTERSALE_CONFIG_ADD_FAIL,

    AFTERSALE_CONFIG_UPDATE_REQUEST,
    AFTERSALE_CONFIG_UPDATE_SUCCESS,
    AFTERSALE_CONFIG_UPDATE_FAIL,

    AFTERSALE_CONFIG_DELETE_REQUEST,
    AFTERSALE_CONFIG_DELETE_SUCCESS,
    AFTERSALE_CONFIG_DELETE_FAIL,

    TYPE_AFTERSALE_CONFIG_LIST_REQUEST,
    TYPE_AFTERSALE_CONFIG_LIST_SUCCESS,
    TYPE_AFTERSALE_CONFIG_LIST_FAIL,

    TYPE_AFTERSALE_CONFIG_ADD_REQUEST,
    TYPE_AFTERSALE_CONFIG_ADD_SUCCESS,
    TYPE_AFTERSALE_CONFIG_ADD_FAIL,

    TYPE_AFTERSALE_CONFIG_UPDATE_REQUEST,
    TYPE_AFTERSALE_CONFIG_UPDATE_SUCCESS,
    TYPE_AFTERSALE_CONFIG_UPDATE_FAIL,

    TYPE_AFTERSALE_CONFIG_DELETE_REQUEST,
    TYPE_AFTERSALE_CONFIG_DELETE_SUCCESS,
    TYPE_AFTERSALE_CONFIG_DELETE_FAIL,
} from "../constants/afterSaleConstants";


export const afterSaleReducer = (state = { afterSale: {} }, action) => {
    switch (action.type) {
        case AFTERSALE_ADD_DEMAND:
            const demand = action.payload;
            return { ...state, demand };
        case AFTERSALE_REMOVE_DEMAND:
            return {
                ...state,
                afterSale: state.filter((x) => x !== action.payload),
            };
        case AFTERSALE_RESET:
            return {};
        default:
            return state;
    }
};


// -------------------------------------
// constantes de configuration
// -------------------------------------

export const afterSaleConfigReducer = (state = { afterSaleConfig: [] }, action) => {
    switch (action.type) {
        case AFTERSALE_CONFIG_LIST_REQUEST:
            return { loading: true, afterSaleConfig: [] };
        case AFTERSALE_CONFIG_LIST_SUCCESS:
            return {
                loading: false,
                afterSaleConfig: action.payload,
            };
        case AFTERSALE_CONFIG_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const afterSaleConfigAddReducer = (state = {}, action) => {
    switch (action.type) {
        case AFTERSALE_CONFIG_ADD_REQUEST:
            return { loading: true };
        case AFTERSALE_CONFIG_ADD_SUCCESS:
            return { loading: false, successAdd: true };
        case AFTERSALE_CONFIG_ADD_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const afterSaleConfigUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case AFTERSALE_CONFIG_UPDATE_REQUEST:
            return { loading: true };
        case AFTERSALE_CONFIG_UPDATE_SUCCESS:
            return { loading: false, successUpdate: true };
        case AFTERSALE_CONFIG_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const afterSaleConfigDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case AFTERSALE_CONFIG_DELETE_REQUEST:
            return { loading: true };
        case AFTERSALE_CONFIG_DELETE_SUCCESS:
            return { loading: false, successDelete: true };
        case AFTERSALE_CONFIG_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const typeAfterSaleConfigReducer = (state = { typeAfterSaleConfig: [] }, action) => {
    switch (action.type) {
        case TYPE_AFTERSALE_CONFIG_LIST_REQUEST:
            return { loading: true, typeAfterSaleConfig: [] };
        case TYPE_AFTERSALE_CONFIG_LIST_SUCCESS:
            return {
                loading: false,
                typeAfterSaleConfig: action.payload,
            };
        case TYPE_AFTERSALE_CONFIG_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const typeAfterSaleConfigAddReducer = (state = {}, action) => {
    switch (action.type) {
        case TYPE_AFTERSALE_CONFIG_ADD_REQUEST:
            return { loading: true };
        case TYPE_AFTERSALE_CONFIG_ADD_SUCCESS:
            return { loading: false, successAdd: true };
        case TYPE_AFTERSALE_CONFIG_ADD_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const typeAfterSaleConfigUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case TYPE_AFTERSALE_CONFIG_UPDATE_REQUEST:
            return { loading: true };
        case TYPE_AFTERSALE_CONFIG_UPDATE_SUCCESS:
            return { loading: false, successUpdate: true };
        case TYPE_AFTERSALE_CONFIG_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const typeAfterSaleConfigDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case TYPE_AFTERSALE_CONFIG_DELETE_REQUEST:
            return { loading: true };
        case TYPE_AFTERSALE_CONFIG_DELETE_SUCCESS:
            return { loading: false, successDelete: true };
        case TYPE_AFTERSALE_CONFIG_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}
