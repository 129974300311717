import {
    RENTAL_RATE_MAJ_REQUEST,
    RENTAL_RATE_MAJ_SUCCESS,
    RENTAL_RATE_MAJ_FAIL,
    RENTAL_RATE_MAJ_RESET,
    RENTAL_RATE_DELETE_REQUEST,
    RENTAL_RATE_DELETE_SUCCESS,
    RENTAL_RATE_DELETE_FAIL
}
    from '../constants/rentalRateConstants'

import axios from 'axios'

export const majRentalRate = (products, is_leasable, price) => async (dispatch, getState) => {
    try {
        dispatch({
            type: RENTAL_RATE_MAJ_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
                'Content-Type': 'application/json'
            },
        })

        const { data } = await instance.post(
            `/api/rental-rate/maj/`,
            {'products': products, 'is_leasable': is_leasable, 'price': price},
            
        )
        dispatch({
            type: RENTAL_RATE_MAJ_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: RENTAL_RATE_MAJ_FAIL,
            payload: error.response && error.response.data.message ? error.response.data

                .message : error.message,
        })
    }
}

export const rentalRateDelete = (products) => async (dispatch, getState) => {
    try {
        dispatch({
            type: RENTAL_RATE_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
                'Content-Type': 'application/json'
            },
        })

        await instance.post(
            `/api/rental-rate/del/`,
            {'products': products},
            
        )

        dispatch({
            type: RENTAL_RATE_DELETE_SUCCESS,
        })

    } catch (error) {
        dispatch({
            type: RENTAL_RATE_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data

                .message : error.message,
        })
    }
}

export const rentalRateMajReset = () => (dispatch) => {
    dispatch({ type: RENTAL_RATE_MAJ_RESET })
}