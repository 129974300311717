import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Row, Col, ListGroup, Image, Button, Container, Form, Card } from 'react-bootstrap'

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import './SimModal.css';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';


Modal.setAppElement('#root');

const SimModal = ({ isOpen, onRequestClose, sims, onAddSimCard, onSelectSimCard }) => {
  const [simCardForm, setSimCardForm] = useState({ number: '', phone_number: '' });
  const [filteredSims, setFilteredFleetSims] = useState(sims);
  const [searchTerm, setSearchTerm] = useState('');
  const { t, i18n } = useTranslation();

  const searchKeys = ['number', 'phone_number']
  const fuseOptions = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: searchKeys
  };

  useEffect(() => {

    setFilteredFleetSims(sims);
  }, [sims]);

  useEffect(() => {
    let list = filteredSims && filteredSims.length > 0 ? filteredSims : sims;


    if (searchTerm !== '') {

      setFilteredFleetSims(list.filter((f) =>
        f.number.toLowerCase().includes(searchTerm.toLowerCase()) ||
        f.phone_number.toLowerCase().includes(searchTerm.toLowerCase())
      ));
    }
    else {
      setFilteredFleetSims(sims);
    }
  }, [searchTerm]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSimCardForm({ ...simCardForm, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onAddSimCard(simCardForm);
    setSimCardForm({ number: '', phone_number: '' });
  };

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string.length === 0 || string === "") {
      setSearchTerm('');
      setFilteredFleetSims(sims);
    }
    setSearchTerm(string);
  };

  const handleOnSelect = (item) => {
    // the item selected
    setFilteredFleetSims(sims.filter((fu) => fu.id === item.id));
    setSearchTerm(item.number + '   ---   ' + item.phone_number);
  };

  const handleOnFocus = () => {

  };

  const formatResult = (item) => {
    return (
      <>
        {
          item && item != undefined ? (
            <span>{item.phone_number}    ---    {item.number}</span>
          ) : null
        }

      </>
    )
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="sim-modal"
      style={{ overlay: { zIndex: 9999 } }} // Ajout du z-index
    >
      <Container>
        <Row className="modal-header">
          <Col md={10}>
            <h2>{t('Associate a SIM card')}</h2>
          </Col>
          <Col className="text-right">
            <button className='sim-modal-close-btn' type="submit" onClick={onRequestClose}>{t('Close')}</button>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  className='modal-input'
                  type="text"
                  name="phone_number"
                  placeholder={t('Line number')}
                  value={simCardForm.phone_number}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  className='modal-input'
                  type="text"
                  name="number"
                  placeholder={t('Sim number')}
                  value={simCardForm.number}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="sim-modal-add-btn-row">
            <button className='sim-modal-add-btn' type="submit">{t('Add')}</button>
          </Row>
        </Form>
        <Row className="mb-4 mt-4">
          <Col>
            <h2>{t('Choose a SIM card from the list')}</h2>
          </Col>
          <Col md={6}>
            <ReactSearchAutocomplete
              items={filteredSims && filteredSims != undefined && filteredSims.length > 0 ? filteredSims : sims}
              fuseOptions={fuseOptions}
              resultStringKeyName="number"
              onSearch={handleOnSearch}
              onSelect={handleOnSelect}
              onFocus={handleOnFocus}
              onClear={() => { setSearchTerm(''); setFilteredFleetSims(sims); }}
              autoFocus
              placeholder={t('Search')}
              formatResult={formatResult}
              threshold={0.6} // 0 = exact match, 1 = everything matches
              ignoreLocation={true}
              maxResults={8}
              styling={{
                width: "100%",
                border: "1px solid #dfe1e5",
                borderRadius: "5px",
                backgroundColor: "white",
                boxShadow: "rgba(58, 53, 53, 0.2) 0px 1px 6px 0px",
                hoverBackgroundColor: "#eee",
                color: "#212121",
                fontSize: "12px",
                fontFamily: "Arial",
                iconColor: "grey",
                lineColor: "rgb(232, 234, 237)",
                placeholderColor: "grey",
                clearIconMargin: '0 5px 0 0',
                searchIconMargin: '0 0 0 5px',
                zIndex: 5,
              }}
            />
          </Col>
        </Row>
        <Row>
          <ListGroup className="sim-list">
            {(filteredSims && filteredSims.length > 0 ? filteredSims : sims).map((sim) => (
              <ListGroup.Item key={sim.id} onClick={() => onSelectSimCard(sim)} className="sim-item">
                {sim.number} {sim.phone_number} {sim.role}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Row>
      </Container>
    </Modal >
  );
};

export default SimModal;
