import axios from 'axios';
import {
    FLEET_LIST_REQUEST,
    FLEET_LIST_SUCCESS,
    FLEET_LIST_FAIL,

    FLEET_CREATE_REQUEST,
    FLEET_CREATE_SUCCESS,
    FLEET_CREATE_FAIL,

    FLEET_GLOBAL_IMPORT_REQUEST,
    FLEET_GLOBAL_IMPORT_SUCCESS,
    FLEET_GLOBAL_IMPORT_FAIL,

    FLEET_UPDATE_REQUEST,
    FLEET_UPDATE_SUCCESS,
    FLEET_UPDATE_FAIL,

    FLEET_DELETE_REQUEST,
    FLEET_DELETE_SUCCESS,
    FLEET_DELETE_FAIL,

    FLEET_GET_REQUEST,
    FLEET_GET_SUCCESS,
    FLEET_GET_FAIL,

    RESET_FLEET_UPDATE_SUCCESS,
    RESET_FLEET_DELETE_SUCCESS,
    RESET_GLOBAL_IMPORT_SUCCESS,
} from "../constants/fleetConstants";

export const listFleet = () => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        dispatch({ type: FLEET_LIST_REQUEST })

        const { data } = await instance.get(
            `api/fleet/?ste_id=${userInfo.id}`
            )

        dispatch({ type: FLEET_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: FLEET_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        })
    }
}

export const getFromFleet = (imei) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        dispatch({ type: FLEET_GET_REQUEST })

        const { data } = await instance.get(
            `api/fleet/imei/?imei=${imei}&ste_id=${userInfo.id}`
            )

        dispatch({ type: FLEET_GET_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: FLEET_GET_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        })
    }
}

export const getProductsFromFleet = () => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        dispatch({ type: FLEET_GET_REQUEST })

        const { data } = await instance.get(
            `api/fleet/products/?ste_id=${userInfo.id}`
            )

        dispatch({ type: FLEET_GET_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: FLEET_GET_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        })
    }
}

export const importFleet = (rows) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })
        dispatch({
            type: FLEET_GLOBAL_IMPORT_REQUEST,
        })
        
        const { data } = await instance.post(
            `/api/fleet/global-import/`,
            {'user_id': userInfo.id, 'rows': rows, 'updated_by': userInfo.impersonated_by ? userInfo.impersonated_by : userInfo._id },
            )

        
        dispatch({ type: FLEET_GLOBAL_IMPORT_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: FLEET_GLOBAL_IMPORT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        })
    }
}

export const createFleet = (imeis) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })
        
        
        dispatch({
            type: FLEET_CREATE_REQUEST,
        })
        
        const { data } = await instance.post(
            `/api/fleet/add/`,
            {'ste_id': userInfo.id, 'imeis': imeis, 'updated_by': userInfo.impersonated_by ? userInfo.impersonated_by : userInfo._id },
            )

        
        dispatch({ type: FLEET_CREATE_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: FLEET_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        })
    }
}

export const updateFleet = (imei, user_id) => async (dispatch, getState) => {
    try {
        
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        dispatch({
            type: FLEET_UPDATE_REQUEST
        })

        

        const { data } = await instance.post(
            `/api/fleet/update/`,
            { 'user_id': user_id, 'ste_id': userInfo.id, 'imei': imei, 'updated_by': userInfo.impersonated_by ? userInfo.impersonated_by : userInfo._id },
            )
        
        dispatch({ type: FLEET_UPDATE_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: FLEET_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        })
    }
}
export const removeFromFleet = (imei) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/fleet/del/`,
            { 'imei': imei },
            )

        dispatch({
            type: FLEET_DELETE_REQUEST,
            payload: data,
        })
        dispatch({ type: FLEET_DELETE_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: FLEET_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        })
    }
}

export const resetUpdateSuccess = () => {
    return { type: RESET_FLEET_UPDATE_SUCCESS };
}

export const resetDeleteSuccess = () => {
    return { type: RESET_FLEET_DELETE_SUCCESS };
}

export const resetGlobalImportSuccess = () => {
    return { type: RESET_GLOBAL_IMPORT_SUCCESS };
}