import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Row, Col, Image } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, Link } from 'react-router-dom'

import {
  listTypeAfterSaleConfig,
  addTypeAfterSaleConfig,
  updateTypeAfterSaleConfig,
  deleteTypeAfterSaleConfig
} from '../../../../actions/afterSaleActions';

import Loader from '../../../../components/Loader/Loader';
import Select from '../../../../components/Select/Select';

import './AfterSale.css';

function TypeAfterSale() {
  const dispatch = useDispatch();

  const header = [
    {
      name: "Nom",
      md: 2
    },
    {
      name: "Description",
      md: 2
    },
    {
      name: "Actions",
      md: 1
    }
  ];


  const typeAfterSaleConfigList = useSelector(state => state.typeAfterSaleConfigList);
  const { typeAfterSaleConfig, loading } = typeAfterSaleConfigList;

  const typeAfterSaleConfigAdd = useSelector(state => state.typeAfterSaleConfigAdd);
  const { successAdd } = typeAfterSaleConfigAdd;

  const typeAfterSaleConfigUpdate = useSelector(state => state.typeAfterSaleConfigUpdate);
  const { successUpdate } = typeAfterSaleConfigUpdate;

  const typeAfterSaleConfigDelete = useSelector(state => state.typeAfterSaleConfigDelete);
  const { successDelete } = typeAfterSaleConfigDelete;

  const [typeAfterSaleFormValues, setTypeAfterSaleFormValues] = useState([]);

  const [newTypeAftersaleName, setNewTypeAftersaleName] = useState('');
  const [newTypeAftersaleDescription, setNewTypeAftersaleDescription] = useState('');

  const [selectTypeAfterSaleConfig, setSelectTypeAfterSaleConfig] = useState([])

  useEffect(() => {
    dispatch(listTypeAfterSaleConfig());
  }, [dispatch]);


  useEffect(() => {
    if (typeAfterSaleConfig && typeAfterSaleConfig.length > 0) {
      setTypeAfterSaleFormValues(typeAfterSaleConfig.map(item => ({
        typeAftersaleName: item.name,
        typeAftersaleDescription: item.description,
      })));
    }
  }, [typeAfterSaleConfig]);

  useEffect(() => {
    if (successAdd || successUpdate || successDelete) {
      dispatch(listTypeAfterSaleConfig());
    }
  }, [dispatch, successAdd, successUpdate, successDelete]);

  const addTypeAfterSale = (e) => {
    e.preventDefault();
    dispatch(addTypeAfterSaleConfig(newTypeAftersaleName, newTypeAftersaleDescription))
    emptyNewObjects()
  }

  const updateTypeAfterSale = (e, index) => {
    
    
    const updatedFormValues = typeAfterSaleFormValues[index];
    dispatch(updateTypeAfterSaleConfig(e.currentTarget.value, updatedFormValues.typeAftersaleName, updatedFormValues.typeAftersaleDescription));
  }

  const removeTypeAfterSale = (e) => {
    dispatch(deleteTypeAfterSaleConfig(e.target.value))
  }

  const eraseTypeAfterSale = (e) => {
    emptyNewObjects()
  }

  const onFormValueChange = (e, index, field) => {
    const updatedFormValues = [...typeAfterSaleFormValues];
    updatedFormValues[index][field] = e.target.value;
    setTypeAfterSaleFormValues(updatedFormValues);
  }

  function emptyNewObjects() {
    setNewTypeAftersaleName('')
    setNewTypeAftersaleDescription('')
  }

  return (
    <div className='aftersale-config-main'>
      <Row className='aftersale-title'>
        <span>Configuration des types de prestations SAV</span>
      </Row>
      <Row className='aftersale-items-row'>
        <Row className='aftersale-type-item-row'>
          {header.map((item, index) => (
            <Col md={item.md} key={index}>
              <span>{item.name}</span>
            </Col>
          ))}
        </Row>
        {
          loading ? <Loader /> :
            (typeAfterSaleConfig && typeAfterSaleConfig.length > 0 && typeAfterSaleFormValues && typeAfterSaleFormValues.length > 0) ?
              typeAfterSaleConfig.map((item, index) => (
                <Row className='aftersale-type-item-row' key={item.id}>
                  <Col md={4}>
                    <Form.Control
                      type='text'
                      value={typeAfterSaleFormValues.length > index ? typeAfterSaleFormValues[index].typeAftersaleName : ''}
                      onChange={(e) => onFormValueChange(e, index, 'typeAftersaleName')}
                      autoFocus={false}
                    />
                  </Col>
                  <Col md={4}>
                    <Form.Control
                      type='text'
                      value={typeAfterSaleFormValues.length > index ? typeAfterSaleFormValues[index].typeAftersaleDescription : ''}
                      onChange={(e) => onFormValueChange(e, index, 'typeAftersaleDescription')}
                      autoFocus={false}
                    />
                  </Col>
                  <Col className='aftersale-button-col' md={2}>
                    <button className='btn-aftersale-update' value={item.id} onClick={(e) => updateTypeAfterSale(e, index)}><i className="fa-solid fa-rotate fa-lg"></i></button>
                    <button className='btn-aftersale-remove' value={item.id} onClick={(e) => removeTypeAfterSale(e)}><i className="fa-solid fa-trash fa-lg"></i></button>
                  </Col>
                </Row>
              )) : null
        }
        < Row className='aftersale-type-item-row'>
          <Col md={4}>
            <Form.Control
              type='text'
              value={newTypeAftersaleName}
              onChange={(e) => setNewTypeAftersaleName(e.target.value)}
              placeholder="Nom"
              autoFocus={false}
            />
          </Col>
          <Col md={4}>
            <Form.Control
              type='text'
              value={newTypeAftersaleDescription}
              onChange={(e) => setNewTypeAftersaleDescription(e.target.value)}
              placeholder="Description"
              autoFocus={false}
            />
          </Col>
          <Col className='aftersale-button-col' md={2}>
            <button className='btn-aftersale-add' onClick={(e) => addTypeAfterSale(e)}><i className="fa-solid fa-add fa-lg"></i></button>
            <button className='btn-aftersale-erase' onClick={(e) => eraseTypeAfterSale(e)}><i className="fa-solid fa-eraser fa-lg"></i></button>
          </Col>
        </Row>
      </Row>

    </div >
  );
}

export default TypeAfterSale;

