import React, { useEffect, useState } from 'react';
import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';


const UserDevices = ({ history }) => {
    const HighlightedTableRow = styled(TableRow)({
        backgroundColor: '#CCFAD2', // Couleur de fond de votre choix pour mettre en valeur la ligne
    });

    const { t, i18n } = useTranslation();

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">{t('Devices used')}</Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Kind')}</TableCell>
                                <TableCell>{t('brand')}</TableCell>
                                <TableCell>{t('model')}</TableCell>
                                <TableCell>{t('serial number /imei')}</TableCell>
                                <TableCell>{t('Date Added')}</TableCell>
                                <TableCell>{t('Deletion date')}</TableCell>
                                <TableCell>{t('Updated by')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {history.map((device, index) => (
                                device === history[history.length - 1] ?
                                <HighlightedTableRow key={index}>
                                    <TableCell>{device.type}</TableCell>
                                    <TableCell>{device.brand}</TableCell>
                                    <TableCell>{device.model}</TableCell>
                                    <TableCell>{device.number}</TableCell>
                                    <TableCell>{device.created_at}</TableCell>
                                    <TableCell>{device.deleted_at}</TableCell>
                                    <TableCell>{device.updated_by ? device.updated_by.name : null}</TableCell>
                                </HighlightedTableRow>
                                :
                                <TableRow key={index}>
                                    <TableCell>{device.type}</TableCell>
                                    <TableCell>{device.brand}</TableCell>
                                    <TableCell>{device.model}</TableCell>
                                    <TableCell>{device.number}</TableCell>
                                    <TableCell>{device.created_at}</TableCell>
                                    <TableCell>{device.deleted_at}</TableCell>
                                    <TableCell>{device.updated_by ? device.updated_by.name : null}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
};

export default UserDevices;
