import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Nav, Navbar, NavDropdown, Col, Row, ListGroup } from 'react-bootstrap';

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFileExcel,
    faCartShopping,
    faEllipsisVertical,
} from '@fortawesome/free-solid-svg-icons';

import DataGrid, { Row as DataGridRow, Column, textEditor } from 'react-data-grid';
import { Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import 'react-data-grid/lib/styles.css';
import {
    Menu,
    MenuItem,
    List,
    ListItem,
    Collapse,
    ListItemIcon,
} from '@material-ui/core';

import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import ReactTooltip from 'react-tooltip';

import './Catalog.css'

import { listProducts } from '../../../../actions/productActions';
import { listNonImeiProducts } from '../../../../actions/productActions';

import { listCategories } from '../../../../actions/categoriesActions';
import { listHeaderCategories } from '../../../../actions/headerCategoriesActions';

import Select from '../../../../components/Select/Select'
import { useTranslation } from 'react-i18next';


function Catalog() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const productList = useSelector(state => state.productList)
    const { error, loading, products, page, pages } = productList

    const productNonImeiList = useSelector(state => state.productNonImeiList)
    const { productsNonImei } = productNonImeiList

    const categoriesList = useSelector(state => state.categoriesList)
    const { categories } = categoriesList

    const headerCategoriesList = useSelector((state) => state.headerCategoriesList);
    const { loading: loadingHeaderCategories, error: errorHeaderCategories, headerCategories } = headerCategoriesList;

    const [rowIdx, setRowIdx] = useState(null);

    const [productsRows, setProductsRows] = useState([]);

    const [productsData, setProductsData] = useState();
    const [filteredProductsData, setFilteredProductsData] = useState([]);

    const [selectHeaderCategorie, setSelectHeaderCategorie] = useState([]);
    const [selectedHeaderCategory, setSelectedHeaderCategory] = useState(null);

    const [selectCategorie, setSelectCategorie] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [searchTerm, setSearchTerm] = useState('');
    const [resetSelect, setResetSelect] = useState(false);

    const [categorie, setCategorie] = useState();
    const [brand, setBrand] = useState();
    const [name, setName] = useState();

    const searchKeys = ['brand', 'model', 'name.name']
    const fuseOptions = {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: searchKeys
    };

    const msgExcelDownload = t('Download data to excel file');

    useEffect(() => {
        if (products.length === 0) {
            dispatch(listProducts())
        }
        if (productsNonImei.length === 0) {
            dispatch(listNonImeiProducts())
        }
        if (categories.length === 0) {
            dispatch(listCategories())
        }
        if (headerCategories.length === 0) {
            dispatch(listHeaderCategories())
        }
    }, [])

    useEffect(() => {
        if (headerCategories && headerCategories.length > 0) {
            let values = []
            headerCategories
                .sort((a, b) => a.id - b.id).forEach(hc => {
                    values.push({ value: hc.id, label: hc.name })
                })
            setSelectHeaderCategorie(values)
        }
    }, [headerCategories])

    useEffect(() => {
        if (selectedHeaderCategory && categories && categories.length > 0) {
            fillCategories()
        }
        else {
            setSelectedCategory(null);
            setSelectCategorie([]);
        }
    }, [selectedHeaderCategory, categories]);

    function fillCategories() {
        let values = [];
        categories
            .filter(c => selectedHeaderCategory.categories.includes(c.id))
            .sort((a, b) => a.id - b.id)
            .forEach(hc => {
                values.push({ value: hc.id, label: hc.name });
            });

        if (values.length == 1)
            setSelectedCategory(categories.filter(c => selectedHeaderCategory.categories.includes(c.id))[0]);
        else {
            setSelectedCategory(categories.filter(c => selectedHeaderCategory.categories.includes(c.id)))
        }

        setSelectCategorie(values);
    }

    useEffect(() => {
        if (selectedCategory) {
            if (selectedCategory.length > 0) {
                let product_to_display = [];
                selectedCategory.forEach(sc => {
                    if (selectedCategory.is_imei) {
                        product_to_display = [...product_to_display, ...products.filter(p => p.categories.includes(sc.id))];
                    }
                    else {
                        product_to_display = [...product_to_display, ...productsNonImei.filter(p => p.categories.includes(sc.id))];
                    }
                });
                setProductsData(product_to_display);
            }
            else if (selectedCategory.is_imei) {
                setProductsData(products.filter(p => p.categories.includes(selectedCategory.id)));
            }
            else {
                setProductsData(productsNonImei.filter(p => p.categories.includes(selectedCategory.id)));
            }
        }
        else {
            setProductsData([...products, ...productsNonImei]);
        }

    }, [selectedCategory])

    useEffect(() => {
        if (productsData && productsData.length > 0) {
            setFilteredProductsData(productsData);
        }
        else
            setFilteredProductsData([]);
    }, [productsData])

    useEffect(() => {
        if (searchTerm !== '') {
            let list = filteredProductsData && filteredProductsData.length > 0 ? filteredProductsData : productsData;
            setFilteredProductsData(list.filter((f) =>
                f.brand.toLowerCase().includes(searchTerm.toLowerCase()) ||
                f.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                f.model.toLowerCase().includes(searchTerm.toLowerCase())
            ));
        } else {
            setFilteredProductsData(productsData);
            setResetSelect(!resetSelect);
        }
    }, [searchTerm]);

    const onHandleHeaderCategorySelectChange = (value) => {
        setSelectedHeaderCategory(headerCategories.filter(hc => hc.id === value)[0])
    }

    const onHandleCategorySelectChange = (value) => {
        if (selectedHeaderCategory && selectedHeaderCategory != undefined)
            if (value && value != undefined)
                setSelectedCategory(categories.filter(c => c.id === value)[0])
            else
                fillCategories()
        else
            setSelectedCategory(categories.filter(c => c.id === value)[0])
    }

    useEffect(() => {
        
    }, [selectedHeaderCategory])

    const [sortedRows, setSortedRows] = useState(productsRows);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);

    const handleSort = (columnKey, direction) => {
        const sortedData = [...sortedRows].sort((a, b) => {
            if (direction === 'ASC') {
                return a[columnKey].localeCompare(b[columnKey]);
            } else {
                return b[columnKey].localeCompare(a[columnKey]);
            }
        });

        setSortedRows(sortedData);
    };

    const onHeaderClick = (column) => {
        let newSortDirection = sortDirection === 'ASC' ? 'DESC' : 'ASC';
        if (sortColumn !== column.key) {
            newSortDirection = 'ASC';
        }
        setSortColumn(column.key);
        setSortDirection(newSortDirection);
        handleSort(column.key, newSortDirection);
    };

    const catalog_columns = [
        {
            key: 'brand', name: t('brand'), cellClass: 'cell-align-center',
            headerRenderer: (props) => (
                <HeaderCellRenderer
                    {...props}
                    onHeaderClick={onHeaderClick}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                />
            ),
        },
        {
            key: 'name',
            name: t('Name'),
            headerRenderer: (props) => (
                <HeaderCellRenderer
                    {...props}
                    onHeaderClick={onHeaderClick}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                />
            ),
            cellClass: 'cell-align-center'
        },
        {
            key: 'model', name: t('model'), cellClass: 'cell-align-center',
            headerRenderer: (props) => (
                <HeaderCellRenderer
                    {...props}
                    onHeaderClick={onHeaderClick}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                />
            ),
        },
        {
            key: 'price',
            name: t('my rate'),
            headerRenderer: (props) => (
                <HeaderCellRenderer
                    {...props}
                    onHeaderClick={onHeaderClick}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                />
            ),
            cellClass: 'cell-align-center'
        },
        {
            key: 'actions',
            name: t('actions'),
            headerRenderer: () => <div style={{ textAlign: 'center' }}>Actions</div>,
            formatter: ({ row }) => <ActionsCell row={row} />,
            cellClass: 'cell-align-center',
            width: 50,
        },
    ];

    function HeaderCellRenderer({
        column,
        onHeaderClick,
        sortColumn,
        sortDirection,
    }) {
        const handleClick = () => {
            onHeaderClick(column);
        };

        const isSorted = sortColumn === column.key;

        return (
            <div
                onClick={handleClick}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer',
                }}
            >
                {column.name}
                {isSorted && (sortDirection === 'ASC' ? '▲' : '▼')}
            </div>
        );
    }

    useEffect(() => {
        if (filteredProductsData && filteredProductsData != undefined && filteredProductsData.length > 0) {
            setBrand(filteredProductsData.map((item) => { return { label: item.brand, value: item.brand } }));
            setCategorie(filteredProductsData.map((item) => { return { label: item.categorie, value: item.categorie } }));

            setProductsRows(getProductRowsFromFilteredProductsData(filteredProductsData));
        }
        else
            setProductsRows([]);
    }, [filteredProductsData])

    function getProductRowsFromFilteredProductsData(filteredProductData) {
        let productRows = [];
        filteredProductData.forEach((item) => {
            let productRow = {};
            let price = parseFloat(item.price ? item.price : item.public_price).toFixed(2).toString() + ' €';
            productRow.id = item.id;
            productRow.brand = item.brand ? item.brand : '';
            productRow.model = item.model ? item.model : '';
            productRow.name = item.name ? item.name : '';
            productRow.price = price;
            productRow.categorie_odoo = item.categorie_odoo
            productRows.push(productRow);
        });
        return productRows;
    }

    useEffect(() => {
        if (productsRows && productsRows != undefined && productsRows.length > 0) {
            setSortedRows(productsRows);
        }
        else
            setSortedRows([]);
    }, [productsRows])

    function ActionsCell({ row }) {
        const row_idx = sortedRows.findIndex((r) => r.id === row.id);
        const [anchorEl, setAnchorEl] = React.useState(null);
        const [userAnchorEl, setUserAnchorEl] = useState(null);
        const [historiqueAnchorEl, setHistoriqueAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
            setRowIdx(row_idx);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleMenuItemClick = (event, setAnchorFunction, currentAnchorEl) => {
            if (currentAnchorEl === event.currentTarget) {
                // Si l'élément de la liste est déjà ouvert, le fermer
                setAnchorFunction(null);
            } else {
                // Sinon, ouvrir le sous-menu pour cet élément de la liste
                setAnchorFunction(event.currentTarget);
            }
        };

        const handleOrder = (id, categorie_odoo) => {
            
            let product_categorie = categories.filter(c => c.name === categorie_odoo)[0];
            
            let product = null;
            if (product_categorie.is_imei)
                product = products.filter(p => p.id === id)[0];
            else
                product = productsNonImei.filter(p => p.id === id)[0];



            navigate(product_categorie.is_imei ? `/imei-products/${id}/` : `/products/${id}/`, { state: { p: product } });
        };

        return (
            <div>
                <div onClick={handleClick} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                </div>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    getContentAnchorEl={null}
                    PaperProps={{
                        style: {
                            minWidth: '200px',
                        },
                    }}
                >
                    <List>
                        <ListItem button onClick={() => handleOrder(row.id, row.categorie_odoo)}>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faCartShopping} />
                            </ListItemIcon>
                            {t('Order')}
                        </ListItem>
                    </List>
                </Menu>
            </div>
        );

    }

    const rowClassGetter = (rowIdx) => {
        const row = sortedRows[rowIdx];
        // Remplacez cette condition par la condition réelle à vérifier dans `sortedRows`
        const isConditionMet = row && row.removed;

        //return isConditionMet ? 'highlighted-row' : '';
        return 'highlighted-row';
    };

    const RowRenderer = () => {
        return <Row {...this.props} extraClasses="highlighted-row" />
    }


    const formatResult = (item) => {
        return (
            <>
                {
                    item ? (
                        <span>{item.brand} {item.name}</span>
                    ) : null
                }

            </>
        )
    };

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        if (string.length === 0 || string === "") {
            setSearchTerm('');
            setFilteredProductsData(productsData);
        }
        setSearchTerm(string);
    };

    const handleOnSelect = (item) => {
        // the item selected
        setFilteredProductsData(filteredProductsData.filter((product) => product.id === item.id && product.categories == item.categories));
        setSearchTerm(item.id);
    };

    const handleOnFocus = () => {

    };

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(sortedRows);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        const s2ab = (s) => {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i < s.length; i++) {
                view[i] = s.charCodeAt(i) & 0xff;
            }
            return buf;
        };

        saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), 'catalogue.xlsx');
    };

    return (
        <div className="catalog-main-row">
            <Row className="catalog-filters">
                <Col md={8}>
                    <ReactSearchAutocomplete
                        items={filteredProductsData && filteredProductsData != undefined && filteredProductsData.length > 0 ?
                            filteredProductsData
                            : []}
                        fuseOptions={fuseOptions}
                        resultStringKeyName="name"
                        onSearch={handleOnSearch}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        onClear={() => { setSearchTerm(''); setFilteredProductsData(productsData); setResetSelect(!resetSelect); }}
                        autoFocus
                        placeholder={t('Search')}
                        formatResult={formatResult}
                        threshold={0.6} // 0 = exact match, 1 = everything matches
                        ignoreLocation={true}
                        maxResults={8}
                        styling={{
                            width: "100%",
                            border: "1px solid #dfe1e5",
                            borderRadius: "5px",
                            backgroundColor: "white",
                            boxShadow: "rgba(58, 53, 53, 0.2) 0px 1px 6px 0px",
                            hoverBackgroundColor: "#eee",
                            color: "#212121",
                            fontSize: "12px",
                            fontFamily: "Arial",
                            iconColor: "grey",
                            lineColor: "rgb(232, 234, 237)",
                            placeholderColor: "grey",
                            clearIconMargin: '0 5px 0 0',
                            searchIconMargin: '0 0 0 5px',
                            zIndex: 5,
                        }}
                    />
                </Col>
            </Row>
            <Row className="catalog-filters">
                <Col md={8}>
                    <Select
                        className='categories-select'
                        name="categories-select"
                        title="Catégories"
                        value={selectedHeaderCategory}
                        options={selectHeaderCategorie}
                        onChangeFunc={(e) => onHandleHeaderCategorySelectChange(e)}
                    />
                </Col>
                <Col md={2}>
                    <button
                        onClick={exportToExcel}
                        className='btn-excel-export'
                        data-tip="React-tooltip" data-for={'tooltip'}
                    >
                        <FontAwesomeIcon icon={faFileExcel} size="xl" />
                    </button>
                    <ReactTooltip id={'tooltip'}
                        type="dark"
                        effect="solid"
                        clickable={true}
                        place='bottom'
                        getContent={function () {
                            return (
                                <div>
                                    <Row>{msgExcelDownload}</Row>
                                </div>
                            )
                        }} />
                </Col>
            </Row>
            {
                selectedHeaderCategory && selectedHeaderCategory.categories && selectedHeaderCategory.categories.length > 1 &&
                <Row className="catalog-filters">
                    <Col md={8}>
                        <Select
                            className='catalog-categories-select'
                            name="categories-select"
                            title="Sous catégories"
                            value={selectedCategory}
                            options={selectCategorie}
                            onChangeFunc={(e) => onHandleCategorySelectChange(e)}
                        />
                    </Col>
                </Row>
            }

            <Row className="catalog-datagrid">
                <DataGrid
                    className='rdg-light catalog-datagrid'
                    columns={catalog_columns}
                    rows={sortedRows}
                    rowGetter={(i) => sortedRows[i]}
                    rowsCount={sortedRows.length}
                    onRowsChange={setSortedRows}
                    onHeaderClick={onHeaderClick}
                    onGridRowsUpdated={setSortedRows}
                    rowClassName={rowClassGetter}
                    rowRenderer={RowRenderer}
                />
            </Row>
        </div>
    )
}

export default Catalog