import {
    MESSAGE_LIST_REQUEST,
    MESSAGE_LIST_SUCCESS,
    MESSAGE_LIST_FAIL,

    MESSAGE_CREATE_REQUEST,
    MESSAGE_CREATE_SUCCESS,
    MESSAGE_CREATE_FAIL,
    MESSAGE_CREATE_RESET,

    MESSAGE_DELETE_REQUEST,
    MESSAGE_DELETE_SUCCESS,
    MESSAGE_DELETE_FAIL,
    MESSAGE_DELETE_RESET,

    MESSAGE_UPDATE_REQUEST,
    MESSAGE_UPDATE_SUCCESS,
    MESSAGE_UPDATE_FAIL,
    MESSAGE_UPDATE_RESET,

    MESSAGE_AS_READ_REQUEST,
    MESSAGE_AS_READ_SUCCESS,
    MESSAGE_AS_READ_FAIL,

    MESSAGE_AS_UNREAD_COUNT_REQUEST,
    MESSAGE_AS_UNREAD_COUNT_SUCCESS,
    MESSAGE_AS_UNREAD_COUNT_FAIL,

    MESSAGE_SEND_MAIL_REQUEST,
    MESSAGE_SEND_MAIL_SUCCESS,
    MESSAGE_SEND_MAIL_FAIL,
    MESSAGE_SEND_MAIL_RESET,
}
    from '../constants/messageConstants.js'

import axios from 'axios'

export const getMessages = (conversation_id) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        dispatch({ type: MESSAGE_LIST_REQUEST })

        const { data } = await instance.get(
            `/api/message/?user_id=${userInfo._id}&conversation_id=${conversation_id}`
            )

        dispatch({
            type: MESSAGE_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: MESSAGE_LIST_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const createMessage = (subject, body, conversation_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MESSAGE_CREATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/message/add/`,
            { 'user_id': userInfo._id, 'subject': subject, 'body': body, 'conversation_id': conversation_id },
            )

        dispatch({
            type: MESSAGE_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: MESSAGE_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateMessage = (message_id, subject, body, conversation_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MESSAGE_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/message/update/`,
            { 'message_id': message_id, 'subject': subject, 'body': body, 'conversation_id': conversation_id },
            )

        dispatch({
            type: MESSAGE_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: MESSAGE_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteMessage = (message_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MESSAGE_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/message/delete/`,
            { 'message_id': message_id },
            )

        dispatch({
            type: MESSAGE_DELETE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: MESSAGE_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const markMessageAsRead = (message_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MESSAGE_AS_READ_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/message/mark-as-read/`,
            { 'id': message_id },
            )

        dispatch({
            type: MESSAGE_AS_READ_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: MESSAGE_AS_READ_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getUnreadMessageCount = () => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        dispatch({ type: MESSAGE_AS_UNREAD_COUNT_REQUEST })

        const { data } = await instance.get(
            `/api/message/get-unread-count/?user_id=${userInfo._id}`
            )

        dispatch({
            type: MESSAGE_AS_UNREAD_COUNT_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: MESSAGE_AS_UNREAD_COUNT_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

export const sendMail = (subject, body) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MESSAGE_SEND_MAIL_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json'
            },
        })

        const { data } = await instance.post(
            `/api/message/send-mail/`,
            { 'sender_id': userInfo._id, 'subject': subject, 'body': body },
            )

        dispatch({
            type: MESSAGE_SEND_MAIL_SUCCESS,
            payload: data,
        })

    }
    catch (error) {
        dispatch({
            type: MESSAGE_SEND_MAIL_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const resetMessageCreate = () => (dispatch) => {
    dispatch({ type: MESSAGE_CREATE_RESET })
}

export const resetMessageDelete = () => (dispatch) => {
    dispatch({ type: MESSAGE_DELETE_RESET })
}

export const resetMessageUpdate = () => (dispatch) => {
    dispatch({ type: MESSAGE_UPDATE_RESET })
}

// export const resetMessageList = () => (dispatch) => {
//     dispatch({ type: MESSAGE_LIST_RESET })
// }

// export const resetMessageAsRead = () => (dispatch) => {
//     dispatch({ type: MESSAGE_AS_READ_RESET })
// }

// export const resetMessageAsUnreadCount = () => (dispatch) => {
//     dispatch({ type: MESSAGE_AS_UNREAD_COUNT_RESET })
// }

export const resetMessageSendMail = () => (dispatch) => {
    dispatch({ type: MESSAGE_SEND_MAIL_RESET })
}