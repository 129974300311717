import{
    WB_LIST_REQUEST,
    WB_LIST_SUCCESS,
    WB_LIST_FAIL,

    WB_LIST_CREATE_REQUEST,
    WB_LIST_CREATE_SUCCESS,
    WB_LIST_CREATE_FAIL
}
from '../constants/wblistConstants'

export const wbListReducer = (state = { wblist: [] }, action) => {
    switch (action.type) {
        case WB_LIST_REQUEST:
            return { loading: true, wblist: [] }
        case WB_LIST_SUCCESS:
            return { loading: false, wblist: action.payload }
        case WB_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const wbListCreateReducer = (state = { wblistmaj: [] }, action) => {
    switch (action.type) {
        case WB_LIST_CREATE_REQUEST:
            return { loading: true, wblistmaj: [] }
        case WB_LIST_CREATE_SUCCESS:
            return { loading: false, wblistmaj: action.payload, success: true }
        case WB_LIST_CREATE_FAIL:
            return { loading: false, error: action.payload, success: false }
        default:
            return state
    }
}