import{
    HEADER_BRAND_ORDER_REQUEST,
    HEADER_BRAND_ORDER_SUCCESS,
    HEADER_BRAND_ORDER_FAIL,

    HEADER_BRAND_ORDER_ADD_REQUEST,
    HEADER_BRAND_ORDER_ADD_SUCCESS,
    HEADER_BRAND_ORDER_ADD_FAIL,

    HEADER_BRAND_ORDER_DEL_REQUEST,
    HEADER_BRAND_ORDER_DEL_SUCCESS,
    HEADER_BRAND_ORDER_DEL_FAIL
}
from '../constants/headerBrandOrderConstants.js'

export const headerBrandOrderReducer = (state = {headerBrandOrder: []}, action) => {
    switch(action.type){
        case HEADER_BRAND_ORDER_REQUEST:
            return {loading: true, headerBrandOrder: []}
        case HEADER_BRAND_ORDER_SUCCESS:
            return {loading: false, headerBrandOrderList: action.payload, success: true}
        case HEADER_BRAND_ORDER_FAIL:
            return {loading: false, error: action.payload, success: false}
        default:
            return state
    }
}

export const headerBrandOrderAddReducer = (state = {headerBrandOrder: []}, action) => {
    switch(action.type){
        case HEADER_BRAND_ORDER_ADD_REQUEST:
            return {loading: true, headerBrandOrder: []}
        case HEADER_BRAND_ORDER_ADD_SUCCESS:
            return {loading: false, headerBrandOrderAdded: action.payload, success: true}
        case HEADER_BRAND_ORDER_ADD_FAIL:
            return {loading: false, error: action.payload, success: false}
        default:
            return state
    }
}

export const headerBrandOrderDelReducer = (state = {headerBrandOrder: []}, action) => {
    switch(action.type){
        case HEADER_BRAND_ORDER_DEL_REQUEST:
            return {loading: true, headerBrandOrder: []}
        case HEADER_BRAND_ORDER_DEL_SUCCESS:
            return {loading: false, headerBrandOrderDeleted: action.payload, success: true}
        case HEADER_BRAND_ORDER_DEL_FAIL:
            return {loading: false, error: action.payload, success: false}
        default:
            return state
    }
}