import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Row, Col, ListGroup, Image, Button, Container, Form, Card } from 'react-bootstrap'

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import './UserModal.css';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';


Modal.setAppElement('#root');

const UserModal = ({ isOpen, onRequestClose, fleetUsers, onAddUser, onSelectUser }) => {
  const [userForm, setUserForm] = useState({ lastname: '', firstname: '', entity: '', role: '' });
  const [filteredFleetUsers, setFilteredFleetUsers] = useState(fleetUsers);
  const [searchTerm, setSearchTerm] = useState('');
  const { t, i18n } = useTranslation();

  const searchKeys = ['lasname', 'firstname', 'role']
  const fuseOptions = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: searchKeys
  };

  useEffect(() => {
    let list = filteredFleetUsers && filteredFleetUsers.length > 0 ? filteredFleetUsers : fleetUsers;
    
    
    if (searchTerm !== '') {
      
      setFilteredFleetUsers(list.filter((f) =>
        f.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        f.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        // f.entity.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (f.role.toLowerCase().includes(searchTerm.toLowerCase())
        )));
    }
    else {
      setFilteredFleetUsers(fleetUsers);
    }
  }, [searchTerm]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserForm({ ...userForm, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onAddUser(userForm);
    setUserForm({ lastname: '', firstname: '', entity: '', role: '' });
  };

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string.length === 0 || string === "") {
      setSearchTerm('');
      setFilteredFleetUsers(fleetUsers);
    }
    setSearchTerm(string);
  };

  const handleOnSelect = (item) => {
    // the item selected
    setFilteredFleetUsers(fleetUsers.filter((fu) => fu.id === item.id));
    setSearchTerm(item.lastname + ' ' + item.firstname);
  };

  const handleOnFocus = () => {
    
  };

  const formatResult = (item) => {
    return (
      <>
        {
          item && item != undefined ? (
            <span>{item.firstname} {item.lastname} : {item.role}    ---    {item.entity}</span>
          ) : null
        }

      </>
    )
  };

  return (
    <Modal 
    isOpen={isOpen} 
    onRequestClose={onRequestClose} 
    className="user-modal"
    style={{ overlay: { zIndex: 9999 } }} // Ajout du z-index
    >
      <Container>
        <Row className="modal-header">
          <Col md={10}>
            <h2>{t('Add a new user')}</h2>
          </Col>
          <Col className="text-right">
            <button className='user-modal-close-btn' type="submit" onClick={onRequestClose}>{t('Close')}</button>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  className='modal-input'
                  type="text"
                  name="firstname"
                  placeholder={t('First Name')}
                  value={userForm.firstname}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  className='modal-input'
                  type="text"
                  name="lastname"
                  placeholder={t('Name')}
                  value={userForm.lastname}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            {/* <Col>
              <Form.Group>
                <Form.Control
                  className='modal-input'
                  type="text"
                  name="entity"
                  placeholder="Entité"
                  value={userForm.entity}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col> */}
            <Col md={6}>
              <Form.Group>
                <Form.Control
                  className='modal-input'
                  type="text"
                  name="role"
                  placeholder={t('Role')}
                  value={userForm.role}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="user-modal-add-btn-row">
            <button className='user-modal-add-btn' type="submit">{t('Add')}</button>
          </Row>
        </Form>
        <Row className="mb-4 mt-4">
          <Col>
            <h2>{t('Choose a user from the list')}</h2>
          </Col>
          <Col md={6}>
            <ReactSearchAutocomplete
              items={filteredFleetUsers && filteredFleetUsers != undefined && filteredFleetUsers.length > 0 ? filteredFleetUsers : fleetUsers}
              fuseOptions={fuseOptions}
              resultStringKeyName="lastname"
              onSearch={handleOnSearch}
              onSelect={handleOnSelect}
              onFocus={handleOnFocus}
              onClear={() => { setSearchTerm(''); setFilteredFleetUsers(fleetUsers); }}
              autoFocus
              placeholder={t('Search')}
              formatResult={formatResult}
              threshold={0.6} // 0 = exact match, 1 = everything matches
              ignoreLocation={true}
              maxResults={8}
              styling={{
                width: "100%",
                border: "1px solid #dfe1e5",
                borderRadius: "5px",
                backgroundColor: "white",
                boxShadow: "rgba(58, 53, 53, 0.2) 0px 1px 6px 0px",
                hoverBackgroundColor: "#eee",
                color: "#212121",
                fontSize: "12px",
                fontFamily: "Arial",
                iconColor: "grey",
                lineColor: "rgb(232, 234, 237)",
                placeholderColor: "grey",
                clearIconMargin: '0 5px 0 0',
                searchIconMargin: '0 0 0 5px',
                zIndex: 5,
              }}
            />
          </Col>
        </Row>
        <Row>
          <ListGroup className="user-list">
            {(filteredFleetUsers && filteredFleetUsers.length > 0 ? filteredFleetUsers : fleetUsers).map((user) => (
              <ListGroup.Item key={user.id} onClick={() => onSelectUser(user)} className="user-item">
                {user.lastname} {user.firstname} {user.role}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Row>
      </Container>
    </Modal>
  );
};

export default UserModal;
