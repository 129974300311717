import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Row, Col, Image } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux'

import Switch from "react-switch";

import Message from '../../../../../components/Message/Message';
import Loader from '../../../../../components/Loader/Loader';
import Select from '../../../../../components/Select/Select'


import { deleteProduct, createReset, productDeleteReset, getCircleProductByIMEI } from '../../../../../actions/productActions';
import { deleteReset, listProducts } from '../../../../../actions/productActions';
import { listOrdersBC, createOrderBC } from '../../../../../actions/orderActions';
import { validImei } from '../../../../../utils/luhnUtils';

import '../Articles.css'
import './RemoveArticles.css';

function RemoveArticles() {
  const searchRef = useRef(null);
  const [form, setForm] = useState({});

  const [toggleChecked, setToggleChecked] = useState(false);
  const [messageVariant, setMessageVariant] = useState('')
  const [error, setError] = useState(false);
  const [imeis, setImeis] = useState('');
  const [inputImeis, setInputImeis] = useState('');
  const [search, setSearch] = useState(false);
  const [message, setMessage] = useState('')
  const [newScan, setNewScan] = useState(false)
  const [bcListForSelect, setBcListForSelect] = useState([])
  const [selectedBc, setSelectedBc] = useState({})
  const [BC_id, setBC_id] = useState('')

  const [productToDisplay, setProductToDisplay] = useState({})
  const [resetSelect, setResetSelect] = useState(false);

  const productDeleted = useSelector(state => state.productDeleted)
  const { loading, success } = productDeleted

  const productFromCircle = useSelector(state => state.productFromCircleByImei)
  const { circleProductByImei } = productFromCircle

  const productInDbCircle = useSelector(state => state.productInDbCircle)
  const { productInCircle } = productInDbCircle

  const orderBCList = useSelector(state => state.orderBCList)
  const { ordersBC } = orderBCList

  const dispatch = useDispatch()

  useEffect(() => {
    searchRef.current.focus()
    dispatch(listOrdersBC())
  }, [])

  useEffect(() => {
    if (success) {
      dispatch(listProducts())
    }
    dispatch(productDeleteReset())
  }, [success])

  useEffect(() => {
    let bc_list_for_select = []
    if (ordersBC && ordersBC.length > 0) {
      ordersBC.forEach(element => {
        bc_list_for_select.push({
          value: element.id,
          label: element.name + ' - ' + element.date_order
        })
      });
      setBcListForSelect(bc_list_for_select)
    }
  }, [ordersBC])

  useEffect(() => {
    dispatch(createReset())
    if ((toggleChecked || (!toggleChecked && search))) {
      let validImeisInput = []
      if (imeis.length > 0) {

        let imeiInputs = imeis.split('\n')
        imeiInputs.forEach(imei => {
          if (validImei(imei)) {
            validImeisInput.push(imei)
          }
        })
        if (validImeisInput.length > 0) {
          
          dispatch(createOrderBC(validImeisInput, BC_id))
          dispatch(deleteProduct(validImeisInput))
        }
      }
      resetConst()
    }

    setSearch(false)
    setNewScan(false)
  }, [imeis, search, newScan])

  // useEffect(() => {
  //   
  //   if (productInCircle && productInCircle.productInDbCircle) {
  //     
  //     dispatch(createOrderBC(productInCircle.imei, BC_id))
  //     dispatch(deleteProduct(productInCircle.imei))
  //   }
  //   else if (inputImeis != '') {
  //     setMessage('Le numero IMEI ' + inputImeis + ' , n \'est pas en stock.')
  //     setMessageVariant('warning')
  //   }
  //   resetConst()
  // }, [productInCircle])

  useEffect(() => {
    if (circleProductByImei) {
      setProductToDisplay(circleProductByImei)
    }
  }, [circleProductByImei])

  useEffect(() => {
    setErrorMessages()
  }, [inputImeis])

  function resetConst() {
    if (imeis.length > 0) {
      setSearch(false)
      setError(false)
      setInputImeis('')
    }
    searchRef.current.focus()
  }

  useEffect(() => {
    if (success) {
      dispatch(getCircleProductByIMEI(productFromCircle.imei))
      setMessage('L\'article a été retiré avec succès.')
      setMessageVariant('success')
      
    }
  }, [success])

  function setErrorMessages() {
    setError(false)
    setMessage('')
    setMessageVariant('')

    let inputImeisToCheck = inputImeis.split('\n')

    if (inputImeisToCheck.length > 0) {
      let messages = []
      inputImeisToCheck.forEach(imei => {
        if (imei.length > 15) {
          messages.push(`\n${imei} : Le numéro IMEI doit comporter 15 chiffres.`)
          setError(true)
          setMessageVariant('danger')
        }
        else if (imei.length === 15 && !validImei(imei)) {
          messages.push(`\n${imei} : Le numero IMEI n\'est pas valide.`)
          setError(true)
          setMessageVariant('danger')
        }
        setMessage(messages)
      })

      if (messages.length > 0)
        setMessage(messages.map((message, index) => message))
    }


  }

  function manageImeiInput(value) {
    setInputImeis(value)
    if (value.length >= 15) {
      setNewScan(true)
      setImeis(value)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    setSearch(true)
  }

  const handleToggleChange = (checked) => {
    setToggleChecked(checked);
  };

  const onHandleSelectChange = (value, key) => {
    if(value === null) {
      setSelectedBc(null);
      setBC_id(''); // or any default value you want to set
    } else {
      setBC_id(ordersBC.filter(order => order.id === value)[0].name);
      setSelectedBc(value);
    }
  };
  
  const validImeis = () => {
    let imeiInputs = imeis.split('\n').filter(imei => imei.length === 15)

    if (imeiInputs.length === 0) return false;

    return imeiInputs.some(imei => validImei(imei));
  }

  return (
    <div className='articles-container'>
      <Row className='articles-title-row'>
        <h1>Retirer un article</h1>
      </Row>
      <Row className='articles-main-row'>
        <Col md={8}>
          <Row className='articles-switch-row'>
            <Col md={2}>
              <Switch
                className='addarticles-switch'

                onChange={handleToggleChange}
                checked={toggleChecked}
                offColor="#2f86ca"
                boxShadow="0px 5px 10px rgba(0, 0, 0, 0.6)"
              />
            </Col>
            <Col md={8}>
              <span>{!toggleChecked ? 'Sortie automatique désactivé' : 'Sortie automatique activé'}</span>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Control
                as="textarea"
                type="text"
                id="imeis-input"
                aria-describedby="passwordHelpBlock"
                placeholder="Numéro IMEI"
                ref={searchRef}
                value={inputImeis}
                onChange={(e) => manageImeiInput(e.target.value)}
                ReactTooltip={"Le numéro IMEI doit comporter 15 chiffres."}
              />
            </Col>
            <Col md={6}>
              <Select
                name="bc"
                title="Bon de commande"
                value={selectedBc}
                options={bcListForSelect}
                onChangeFunc={(e) => onHandleSelectChange(e)}
              />
            </Col>
            <Col md={3}>
              {
                !toggleChecked ?
                  <button className='removeArticlesBtn' type='submit' onClick={submitHandler} disabled={!validImeis(imeis) || !selectedBc}>Retirer</button>
                  : null
              }
            </Col>
          </Row>
          <Row className='articles-manage-row'>
            <Col md={10}>
              {message && message.length > 0 ? <Message variant={messageVariant}>{message}</Message> : null}
            </Col>
          </Row>
        </Col>
        <Col className='articles-detail-col' md={4}>
          {loading ? <Loader /> :
            productToDisplay && productToDisplay.name ?
              (
                <>
                  <Row className='articles-detail-title'>
                    {productToDisplay.brand} {productToDisplay.name}
                  </Row>
                  <Row className='articles-detail-img-row'>
                    <Image className='articles-detail-img' src={productToDisplay.device_image} alt={productToDisplay.name} fluid rounded />
                  </Row>
                  <Row className='articles-detail-rows'>
                    <Row className=''>
                      Marque : {productToDisplay.brand}
                    </Row>
                    <Row className=''>
                      Modèle : {productToDisplay.model}
                    </Row>
                    <Row className=''>
                      IMEI : {imeis}
                    </Row>
                  </Row>
                </>
              )
              : null
          }
        </Col>
      </Row>
    </div>
  )
}

export default RemoveArticles
