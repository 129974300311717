export const HISTORY_GET_REQUEST = 'HISTORY_GET_REQUEST';
export const HISTORY_GET_SUCCESS = 'HISTORY_GET_SUCCESS';
export const HISTORY_GET_FAIL = 'HISTORY_GET_FAIL';

export const HISTORY_ALL_REQUEST = 'HISTORY_ALL_REQUEST';
export const HISTORY_ALL_SUCCESS = 'HISTORY_ALL_SUCCESS';
export const HISTORY_ALL_FAIL = 'HISTORY_ALL_FAIL';

export const DELETED_IMEIS_REQUEST = 'DELETED_IMEIS_REQUEST';
export const DELETED_IMEIS_SUCCESS = 'DELETED_IMEIS_SUCCESS';
export const DELETED_IMEIS_FAIL = 'DELETED_IMEIS_FAIL';