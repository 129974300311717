import {
    IMEI_PRODUCT_REQUEST,
    IMEI_PRODUCT_SUCCESS,
    IMEI_PRODUCT_FAIL,
    
    IMEI_PRODUCTS_REQUEST,
    IMEI_PRODUCTS_SUCCESS,
    IMEI_PRODUCTS_FAIL,
}
    from "../constants/imeiConstants";

export const ImeiProductReducer = (state = { imei: {} }, action) => {
    switch (action.type) {
        case IMEI_PRODUCT_REQUEST:
            return { loading: true, ...state }
        case IMEI_PRODUCT_SUCCESS:
            return { loading: false, productByImei: action.payload }
        case IMEI_PRODUCT_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const ImeiProductsReducer = (state = { imeis: [] }, action) => {
    switch (action.type) {
        case IMEI_PRODUCTS_REQUEST:
            return { loading: true, imeis: [] }
        case IMEI_PRODUCTS_SUCCESS:
            return { loading: false, productsByImeis: action.payload }
        case IMEI_PRODUCTS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}