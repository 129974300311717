import {
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAIL,

    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,

    PRODUCT_BY_IMEI_FROM_API_REQUEST,
    PRODUCT_BY_IMEI_FROM_API_SUCCESS,
    PRODUCT_BY_IMEI_FROM_API_FAIL,

    PRODUCT_FROM_CIRCLE_REQUEST,
    PRODUCT_FROM_CIRCLE_SUCCESS,
    PRODUCT_FROM_CIRCLE_FAIL,

    CHECK_PRODUCT_FROM_CIRCLE_REQUEST,
    CHECK_PRODUCT_FROM_CIRCLE_SUCCESS,
    CHECK_PRODUCT_FROM_CIRCLE_FAIL,

    PRODUCT_FROM_CIRCLE_BY_IMEI_REQUEST,
    PRODUCT_FROM_CIRCLE_BY_IMEI_SUCCESS,
    PRODUCT_FROM_CIRCLE_BY_IMEI_FAIL,
    RESET_PRODUCT_FROM_CIRCLE_BY_IMEI,

    PRODUCT_FROM_CIRCLE_BY_TAC_REQUEST,
    PRODUCT_FROM_CIRCLE_BY_TAC_SUCCESS,
    PRODUCT_FROM_CIRCLE_BY_TAC_FAIL,
    RESET_PRODUCT_FROM_CIRCLE_BY_TAC,

    PRODUCT_CREATE_REQUEST,
    PRODUCT_CREATE_SUCCESS,
    PRODUCT_CREATE_FAIL,
    PRODUCT_CREATE_RESET,

    PRODUCT_UPDATE_REQUEST,
    PRODUCT_UPDATE_SUCCESS,
    PRODUCT_UPDATE_FAIL,
    PRODUCT_UPDATE_RESET,

    PRODUCT_DELETE_REQUEST,
    PRODUCT_DELETE_SUCCESS,
    PRODUCT_DELETE_FAIL,
    PRODUCT_DELETE_RESET,

    PRODUCT_NON_IMEI_LIST_REQUEST,
    PRODUCT_NON_IMEI_LIST_SUCCESS,
    PRODUCT_NON_IMEI_LIST_FAIL,

    PRODUCT_NON_IMEI_DETAILS_REQUEST,
    PRODUCT_NON_IMEI_DETAILS_SUCCESS,
    PRODUCT_NON_IMEI_DETAILS_FAIL,

    PRODUCT_NON_IMEI_CREATE_REQUEST,
    PRODUCT_NON_IMEI_CREATE_SUCCESS,
    PRODUCT_NON_IMEI_CREATE_FAIL,

    PRODUCT_NON_IMEI_UPDATE_REQUEST,
    PRODUCT_NON_IMEI_UPDATE_SUCCESS,
    PRODUCT_NON_IMEI_UPDATE_FAIL,

    PRODUCT_NON_IMEI_DELETE_REQUEST,
    PRODUCT_NON_IMEI_DELETE_SUCCESS,
    PRODUCT_NON_IMEI_DELETE_FAIL,

    PRODUCT_CREATE_IN_ODOO_REQUEST,
    PRODUCT_CREATE_IN_ODOO_SUCCESS,
    PRODUCT_CREATE_IN_ODOO_FAIL,
    PRODUCT_CREATE_IN_ODOO_RESET,
} from '../constants/productConstants'


export const ProductListReducer = (state = { products: [] }, action) => {
    switch (action.type) {
        case PRODUCT_LIST_REQUEST:
            return { loading: true, products: [] }
        case PRODUCT_LIST_SUCCESS:
            return { loading: false, products: action.payload }
        case PRODUCT_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const ProductDetailsReducer = (state = { product: { reviews: [] } }, action) => {
    switch (action.type) {
        case PRODUCT_DETAILS_REQUEST:
            return { loading: true, ...state }
        case PRODUCT_DETAILS_SUCCESS:
            return { loading: false, product: action.payload }
        case PRODUCT_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const ProductByImeiFromAPIReducer = (state = { product: { reviews: [] } }, action) => {
    switch (action.type) {
        case PRODUCT_BY_IMEI_FROM_API_REQUEST:
            return { loading: true, ...state }
        case PRODUCT_BY_IMEI_FROM_API_SUCCESS:
            return { loading: false, productByImei: action.payload }
        case PRODUCT_BY_IMEI_FROM_API_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const productsFromCircleReducer = (state = { productsFromCircle: { reviews: [] } }, action) => {
    switch (action.type) {
        case PRODUCT_FROM_CIRCLE_REQUEST:
            return { loading: true, ...state }
        case PRODUCT_FROM_CIRCLE_SUCCESS:
            return { loading: false, circleProducts: action.payload }
        case PRODUCT_FROM_CIRCLE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const productFromCircleByImeiReducer = (state = { productFromCircleByImei: { reviews: [] } }, action) => {
    switch (action.type) {
        case PRODUCT_FROM_CIRCLE_BY_IMEI_REQUEST:
            return { loading: true, ...state }
        case PRODUCT_FROM_CIRCLE_BY_IMEI_SUCCESS:
            return { loading: false, circleProductByImei: action.payload }
        case PRODUCT_FROM_CIRCLE_BY_IMEI_FAIL:
            return { loading: false, error: action.payload }
        case RESET_PRODUCT_FROM_CIRCLE_BY_IMEI:
            return { productFromCircleByImei: { reviews: [] } }
        default:
            return state
    }
}

export const productFromCircleByTacReducer = (state = { productFromCircleByTAC: { reviews: [] } }, action) => {
    switch (action.type) {
        case PRODUCT_FROM_CIRCLE_BY_TAC_REQUEST:
            return { loading: true, ...state }
        case PRODUCT_FROM_CIRCLE_BY_TAC_SUCCESS:
            return { loading: false, circleProductByTAC: action.payload, success: true }
        case PRODUCT_FROM_CIRCLE_BY_TAC_FAIL:
            return { loading: false, error: action.payload }
        case RESET_PRODUCT_FROM_CIRCLE_BY_TAC:
            return { productFromCircleByTAC: { reviews: [] } }
        default:
            return state
    }
}

export const productCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_CREATE_REQUEST:
            return { loading: true }
        case PRODUCT_CREATE_SUCCESS:
            return { loading: false, success: true, product: action.payload }
        case PRODUCT_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case PRODUCT_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const productUpdateReducer = (state = { product: {} }, action) => {
    switch (action.type) {
        case PRODUCT_UPDATE_REQUEST:
            return { loading: true }
        case PRODUCT_UPDATE_SUCCESS:
            return { loading: false, success: true, product: action.payload }
        case PRODUCT_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        case PRODUCT_UPDATE_RESET:
            return { product: {} }
        default:
            return state
    }
}

export const productInDbCircleReducer = (state = { productInDbCircle: { reviews: [] } }, action) => {
    switch (action.type) {
        case CHECK_PRODUCT_FROM_CIRCLE_REQUEST:
            return { loading: true, ...state }
        case CHECK_PRODUCT_FROM_CIRCLE_SUCCESS:
            return { loading: false, productInCircle: action.payload }
        case CHECK_PRODUCT_FROM_CIRCLE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const productDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_DELETE_REQUEST:
            return { loading: true }
        case PRODUCT_DELETE_SUCCESS:
            return { loading: false, success: true }
        case PRODUCT_DELETE_FAIL:
            return { loading: false, error: action.payload }
        case PRODUCT_DELETE_RESET:
            return {}
        default:
            return state
    }
}

export const productNonImeiListReducer = (state = { productsNonImei: [] }, action) => {
    switch (action.type) {
        case PRODUCT_NON_IMEI_LIST_REQUEST:
            return { loading: true, productsNonImei: [] }
        case PRODUCT_NON_IMEI_LIST_SUCCESS:
            return { loading: false, productsNonImei: action.payload }
        case PRODUCT_NON_IMEI_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const ProductNonImeiDetailsReducer = (state = { product: { reviews: [] } }, action) => {
    switch (action.type) {
        case PRODUCT_NON_IMEI_DETAILS_REQUEST:
            return { loading: true, ...state }
        case PRODUCT_NON_IMEI_DETAILS_SUCCESS:
            return { loading: false, nonImeiProduct: action.payload }
        case PRODUCT_NON_IMEI_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const productNonImeiCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_NON_IMEI_CREATE_REQUEST:
            return { loading: true }
        case PRODUCT_NON_IMEI_CREATE_SUCCESS:
            return { loading: false, success: true, product: action.payload }
        case PRODUCT_NON_IMEI_CREATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const productNonImeiUpdateReducer = (state = { product: {} }, action) => {
    switch (action.type) {
        case PRODUCT_NON_IMEI_UPDATE_REQUEST:
            return { loading: true }
        case PRODUCT_NON_IMEI_UPDATE_SUCCESS:
            return { loading: false, success: true, product: action.payload }
        case PRODUCT_NON_IMEI_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const productNonImeiDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_NON_IMEI_DELETE_REQUEST:
            return { loading: true }
        case PRODUCT_NON_IMEI_DELETE_SUCCESS:
            return { loading: false, success: true }
        case PRODUCT_NON_IMEI_DELETE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const productCreateInOdooReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_CREATE_IN_ODOO_REQUEST:
            return { loading: true }
        case PRODUCT_CREATE_IN_ODOO_SUCCESS:
            return { loading: false, success: true, product_id_odoo: action.payload }
        case PRODUCT_CREATE_IN_ODOO_FAIL:
            return { loading: false, error: action.payload }
        case PRODUCT_CREATE_IN_ODOO_RESET:
            return {}
        default:
            return state
    }
}
