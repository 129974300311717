import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Row, Col, Image, ListGroup } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux'

import Switch from "react-switch";

import Message from '../../../../../components/Message/Message';
import Loader from '../../../../../components/Loader/Loader';

import { getCircleProductByIMEI, checkProductInDbCircle, listProducts } from '../../../../../actions/productActions';
import { getAllOdooStock } from '../../../../../actions/stockActions';
import { validImei } from '../../../../../utils/luhnUtils';

import '../Articles.css'
import './InfosStock.css';

function InfosStock() {
  const searchRef = useRef(null);
  const [form, setForm] = useState({});

  const [toggleChecked, setToggleChecked] = useState(false);
  const [messageVariant, setMessageVariant] = useState('')
  const [error, setError] = useState(false);
  const [imei, setImei] = useState('');
  const [inputImei, setInputImei] = useState('');
  const [search, setSearch] = useState(false);
  const [message, setMessage] = useState('')
  const [newScan, setNewScan] = useState(false)

  const [productToDisplay, setProductToDisplay] = useState({})


  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productFromCircle = useSelector(state => state.productFromCircleByImei)
  const { circleProductByImei } = productFromCircle

  const productInDbCircle = useSelector(state => state.productInDbCircle)
  const { productInCircle } = productInDbCircle

  const odooAllStock = useSelector(state => state.allStock)
  const { allOdooStock } = odooAllStock

  const productList = useSelector(state => state.productList)
  const { loading, products, page, pages } = productList

  const dispatch = useDispatch()

  useEffect(() => {
    searchRef.current.focus()
    dispatch(getAllOdooStock()).then(() => {
      if (!products || products.length === 0)
        dispatch(listProducts());
    })
  }, [])

  useEffect(() => {

    if ((toggleChecked || (!toggleChecked && search)) && validImei(imei))
      dispatch(getCircleProductByIMEI(imei))

    setSearch(false)
    setNewScan(false)
  }, [imei, search, newScan])

  useEffect(() => {
    if (productInCircle && productInCircle.productInDbCircle) {
      dispatch(getCircleProductByIMEI(imei))
      setMessage('')
    }
    else if (inputImei != '') {
      setMessage('Le numero IMEI ' + inputImei + ' , n \'est pas en stock.')
      setMessageVariant('warning')
    }
    else
      setMessage('')
    resetConst()
  }, [productInCircle])

  useEffect(() => {
    if (circleProductByImei) {
      setProductToDisplay(circleProductByImei)
    }
  }, [circleProductByImei])

  useEffect(() => {
    
    
    
    

  }, [productToDisplay])


  useEffect(() => {
    setErrorMessages()
  }, [inputImei])

  function resetConst() {
    if (imei.length > 0) {
      setSearch(false)
      setError(false)
      setInputImei('')
    }
    searchRef.current.focus()
  }

  function setErrorMessages() {
    setError(false)
    if (inputImei.length > 15) {
      setMessage('Le numéro IMEI doit comporter 15 chiffres.')
      setError(true)
      setMessageVariant('danger')
    }
    else if (inputImei.length === 15 && !validImei(inputImei)) {
      setMessage('Le numero IMEI ' + inputImei + ' n\'est pas valide.')
      setError(true)
      setMessageVariant('danger')
    }
  }

  function manageImeiInput(value) {
    setInputImei(value)
    if (value.length >= 15) {
      setNewScan(true)
      setImei(value)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    setSearch(true)
  }

  const handleToggleChange = (checked) => {
    setToggleChecked(checked);
  };

  const handleSelectProduct = (event, item) => {
    setMessage('')
    setProductToDisplay(item);
  };


  return (
    <div className='articles-container'>
      <Row className='articles-title-row'>
        <h1>Informations stock</h1>
      </Row>
      <Row className='articles-main-row'>
        <Col md={6}>
          <Row className='articles-manage-row'>
            <Col md={6}>
              <Form.Control
                type="text"
                id="imei-input"
                aria-describedby="passwordHelpBlock"
                placeholder="Numéro IMEI"
                ref={searchRef}
                value={inputImei}
                onChange={(e) => manageImeiInput(e.target.value)}
                ReactTooltip={"Le numéro IMEI doit comporter 15 chiffres."}
              />
            </Col>
            <Col md={4}>
              {
                !toggleChecked ?
                  <button className='addArticlesSearchBtn' type='submit' onClick={submitHandler} disabled={!validImei(imei)}>Rechercher</button>
                  : null
              }
            </Col>
          </Row>
          <Row className='articles-manage-row'>
            <Col md={10}>
              {message && message.length > 0 ? <Message variant={messageVariant}>{message}</Message> : null}
            </Col>
          </Row>
          {
            products && products.length > 0 ?
              <Row className='infosstock-products-row'>
                <ListGroup className='infosstock-products' variant='flush'>
                  {products.map((product) => (
                    <Row className='infosstock-row-item' onClick={(e) => handleSelectProduct(e, product)}>
                      <ListGroup.Item className='border-0 infosstock-items' key={product.id}>
                        <Col md={2}>
                          <Image className='infosstock-img' src={product.device_image} alt={product.name} fluid rounded />
                        </Col>
                        <Col className='infosstock-start-col' col-md={8}>
                          <Row className='infosstock-center-row'>
                            {product.brand} {product.name}
                          </Row>
                        </Col>
                      </ListGroup.Item>
                    </Row>
                  ))}
                </ListGroup>
              </Row>
              : null
          }
        </Col>
        <Col className='articles-detail-col' md={6}>
          {loading ? <Loader /> :
            productToDisplay && productToDisplay.name ?
              (
                <>
                  <Row className='articles-detail-title'>
                    {productToDisplay.brand} {productToDisplay.name}
                  </Row>
                  <Row className='articles-detail-img-row'>
                    <Image className='articles-detail-img' src={productToDisplay.device_image} alt={productToDisplay.name} fluid rounded />
                  </Row>
                  <Row className='articles-detail-rows'>
                    <Row className=''>
                      Marque : {productToDisplay.brand}
                    </Row>
                    <Row className=''>
                      Modèle : {productToDisplay.model}
                    </Row>
                    <Row>
                      Stock : {
                        allOdooStock && allOdooStock.length > 0 && productToDisplay.id_odoo ?
                          allOdooStock.find((item) => item.id === productToDisplay.id_odoo).qty
                          : null
                      }
                    </Row>
                  </Row>
                </>
              )
              : null
          }
        </Col>
      </Row>
    </div>
  )
}

export default InfosStock
