import { 
    TICKET_LIST_REQUEST,
    TICKET_LIST_SUCCESS,
    TICKET_LIST_FAIL,

    TICKET_CREATE_REQUEST, 
    TICKET_CREATE_SUCCESS, 
    TICKET_CREATE_FAIL,
    TICKET_CREATE_RESET
} from "../constants/ticketConstants";

export const TicketListReducer = (state ={tickets: [] }, action) => {
    switch (action.type) {
        case TICKET_LIST_REQUEST:
            return { loading: true, tickets: [] }
        case TICKET_LIST_SUCCESS:
            return { loading: false, tickets: action.payload }
        case TICKET_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const TicketCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case TICKET_CREATE_REQUEST:
            return { loading: true };
        case TICKET_CREATE_SUCCESS:
            return { loading: false, success: true, ticket: action.payload };
        case TICKET_CREATE_FAIL:
            return { loading: false, error: action.payload };
        case TICKET_CREATE_RESET:
            return {};
        default:
            return state;
    }
}