import{
    PURCHASE_LIST_REQUEST,
    PURCHASE_LIST_SUCCESS,
    PURCHASE_LIST_FAIL,
    PURCHASE_LIST_RESET,

    PURCHASE_ADD_REQUEST,
    PURCHASE_ADD_SUCCESS,
    PURCHASE_ADD_FAIL,

    PURCHASE_UPDATE_REQUEST,
    PURCHASE_UPDATE_SUCCESS,
    PURCHASE_UPDATE_FAIL,

    PURCHASE_DELETE_REQUEST,
    PURCHASE_DELETE_SUCCESS,
    PURCHASE_DELETE_FAIL,
}
from "../constants/purchaseConstants";

export const PurchaseListReducer = (state ={purchases: [] }, action) => {
    switch (action.type) {
        case PURCHASE_LIST_REQUEST:
            return { loading: true, purchases: [] }
        case PURCHASE_LIST_SUCCESS:
            return { loading: false, purchases: action.payload, success: true }
        case PURCHASE_LIST_FAIL:
            return { loading: false, error: action.payload }
        case PURCHASE_LIST_RESET:
            return { purchases: [], success: false }
        default:
            return state
    }
}

export const PurchaseAddReducer = (state = {}, action) => {
    switch (action.type) {
        case PURCHASE_ADD_REQUEST:
            return { loading: true };
        case PURCHASE_ADD_SUCCESS:
            return { loading: false, success: true, purchase: action.payload };
        case PURCHASE_ADD_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const PurchaseUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case PURCHASE_UPDATE_REQUEST:
            return { loading: true };
        case PURCHASE_UPDATE_SUCCESS:
            return { loading: false, success: true, purchase: action.payload };
        case PURCHASE_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const PurchaseDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PURCHASE_DELETE_REQUEST:
            return { loading: true };
        case PURCHASE_DELETE_SUCCESS:
            return { loading: false, success: true, purchase: action.payload };
        case PURCHASE_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}