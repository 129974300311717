import{
    CONVERSATION_LIST_REQUEST,
    CONVERSATION_LIST_SUCCESS,
    CONVERSATION_LIST_FAIL,

    CONVERSATION_ADD_REQUEST,
    CONVERSATION_ADD_SUCCESS,
    CONVERSATION_ADD_FAIL,

    CONVERSATION_RECEIVERS_GET_REQUEST,
    CONVERSATION_RECEIVERS_GET_SUCCESS,
    CONVERSATION_RECEIVERS_GET_FAIL,

    CONVERSATION_DEL_REQUEST,
    CONVERSATION_DEL_SUCCESS,
    CONVERSATION_DEL_FAIL,
}
from '../constants/conversationConstants'

export const conversationListReducer = (state = { conversations: [] }, action) => {
    switch (action.type) {
        case CONVERSATION_LIST_REQUEST:
            return { loading: true, conversations: [] }
        case CONVERSATION_LIST_SUCCESS:
            return {
                loading: false,
                conversations: action.payload,
            }
        case CONVERSATION_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const conversationReceiversReducer = (state = { receivers: [] }, action) => {
    switch (action.type) {
        case CONVERSATION_RECEIVERS_GET_REQUEST:
            return { loading: true, receivers: [] }
        case CONVERSATION_RECEIVERS_GET_SUCCESS:
            return {
                loading: false,
                receivers: action.payload,
            }
        case CONVERSATION_RECEIVERS_GET_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const conversationAddReducer = (state = {}, action) => {
    switch (action.type) {
        case CONVERSATION_ADD_REQUEST:
            return { loading: true }
        case CONVERSATION_ADD_SUCCESS:
            return { loading: false, addSuccess: true }
        case CONVERSATION_ADD_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const conversationDelReducer = (state = {}, action) => {
    switch (action.type) {
        case CONVERSATION_DEL_REQUEST:
            return { loading: true }
        case CONVERSATION_DEL_SUCCESS:
            return { loading: false, delSuccess: true }
        case CONVERSATION_DEL_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}