import{
    SIM_GET_REQUEST,
    SIM_GET_SUCCESS,
    SIM_GET_FAILURE,

    SIM_ADD_REQUEST,
    SIM_ADD_SUCCESS,
    SIM_ADD_FAILURE,

    SIM_ADD_BY_FILE_REQUEST,
    SIM_ADD_BY_FILE_SUCCESS,
    SIM_ADD_BY_FILE_FAILURE,

    PUK_ADD_REQUEST,
    PUK_ADD_SUCCESS,
    PUK_ADD_FAILURE,

    SIM_UPDATE_REQUEST,
    SIM_UPDATE_SUCCESS,
    SIM_UPDATE_FAILURE,

    SIM_DELETE_REQUEST,
    SIM_DELETE_SUCCESS,
    SIM_DELETE_FAILURE,

    RESET_SIM_ADD_SUCCESS,
    RESET_SIM_ADD_BY_FILE_SUCCESS,
    RESET_SIM_UPDATE_SUCCESS,
    RESET_SIM_DELETE_SUCCESS,
    RESET_PUK_ADD_BY_FILE_SUCCESS
}
from '../constants/simConstants'

export const simListReducer = (state = { sims: [] }, action) => {
    switch (action.type) {
        case SIM_GET_REQUEST:
            return { loading: true, sims: [] }
        case SIM_GET_SUCCESS:
            return { loading: false, sims: action.payload }
        case SIM_GET_FAILURE:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const simAddReducer = (state = { sim: {} }, action) => {
    switch (action.type) {
        case SIM_ADD_REQUEST:
            return { loading: true, ...state }
        case SIM_ADD_SUCCESS:
            return { loading: false, sim: action.payload, success: true }
        case SIM_ADD_FAILURE:
            return { loading: false, error: action.payload }
        case RESET_SIM_ADD_SUCCESS:
            return { success: false, sim: action.payload }
        default:
            return state
    }
}

export const simAddByFileReducer = (state = { sim: {} }, action) => {
    switch (action.type) {
        case SIM_ADD_BY_FILE_REQUEST:
            return { loading: true, ...state }
        case SIM_ADD_BY_FILE_SUCCESS:
            return { loading: false, sim: action.payload, success: true }
        case SIM_ADD_BY_FILE_FAILURE:
            return { loading: false, error: action.payload }
        case RESET_SIM_ADD_BY_FILE_SUCCESS:
            return { success: false, sim: action.payload }
        default:
            return state
    }
}

export const pukAddReducer = (state = { sim: {} }, action) => {
    switch (action.type) {
        case PUK_ADD_REQUEST:
            return { loading: true, ...state }
        case PUK_ADD_SUCCESS:
            return { loading: false, sim: action.payload, success: true }
        case PUK_ADD_FAILURE:
            return { loading: false, error: action.payload }
        case RESET_PUK_ADD_BY_FILE_SUCCESS:
            return { success: false, sim: action.payload }
        default:
            return state
    }
}

export const simUpdateReducer = (state = { sim: {} }, action) => {
    switch (action.type) {
        case SIM_UPDATE_REQUEST:
            return { loading: true, ...state }
        case SIM_UPDATE_SUCCESS:
            return { loading: false, sim: action.payload, success: true }
        case SIM_UPDATE_FAILURE:
            return { loading: false, error: action.payload }
        case RESET_SIM_UPDATE_SUCCESS:
            return { success: false, sim: action.payload }
        default:
            return state
    }
}

export const simDeleteReducer = (state = { sim: {} }, action) => {
    switch (action.type) {
        case SIM_DELETE_REQUEST:
            return { loading: true, ...state }
        case SIM_DELETE_SUCCESS:
            return { loading: false, sim: action.payload, success: true }
        case SIM_DELETE_FAILURE:
            return { loading: false, error: action.payload }
        case RESET_SIM_DELETE_SUCCESS:
            return { success: false, sim: action.payload }
        default:
            return state
    }
}