import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const UserGeneralInfo = ({ user }) => {
  const { t, i18n } = useTranslation();

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{t('General informations')}</Typography>
        <Typography>{t('Name')} : {user.lastname}</Typography>
        <Typography>{t('First Name')} : {user.firstname}</Typography>
        <Typography>{t('Creation date')}: {new Date(user.created_at).toLocaleDateString()}</Typography>
        <Typography>{t('Entity')} : {user.entite ? user.entite.name : 'Non affecté'}</Typography>
        <Typography>{t('Role')}: {user.role}</Typography>
      </CardContent>
    </Card>
  );
};

export default UserGeneralInfo;
