import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'


import { Row, Col, ListGroup, Image, Button, Container, Form } from 'react-bootstrap'
import Modal from 'react-modal';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import ReactTooltip from 'react-tooltip';

import DataGrid, { Row as DataGridRow, Column, textEditor } from 'react-data-grid';
import { Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import 'react-data-grid/lib/styles.css';
import {
  Menu,
  MenuItem,
  List,
  ListItem,
  Collapse,
  ListItemIcon,
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faBuilding,
  faUserPlus,
  faLock,
  faTicket,
  faLaptopCode,
  faMobile,
  faTablet,
  faDesktop,
  faSimCard,
  faLink,
  faLinkSlash,
  faFileCirclePlus,
  faBook,
  faCartShopping,
  faEllipsisVertical,
  faFileExcel,
  faTrash,
  faMobileScreen,
  faEye,
} from '@fortawesome/free-solid-svg-icons';

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import { Link } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom';

import { addOneYear, minusOneYear, daysInMonth } from '../../../../utils/dateUtils'

import { removeDuplicatesByNestedProperty, findVal } from "../../../../utils/utils";
import { getAllHistory, getDeletedImeis } from '../../../../actions/historyActions';
import Select from '../../../../components/Select/Select'
import Message from '../../../../components/Message/Message'
import ConfirmationModal from '../../../../components/Modal/ConfirmationModal';
import AreaCharts from '../../../../components/Charts/AreaChart'
import InformationChart from '../../../../components/Charts/InformationChart'
import LineCharts from '../../../../components/Charts/LineChart'
import PieChart from '../../../../components/Charts/PieChart.js'

import Loader from '../../../../components/Loader/Loader'

import {
  listFleet,
  updateFleet,
  removeFromFleet,
  importFleet,
  resetUpdateSuccess,
  resetDeleteSuccess,
  resetGlobalImportSuccess,
} from '../../../../actions/fleetActions';
import { getCircleProductByIMEI, getCircleProductByTACOrName, productFromCircleByImeiReset, productFromCircleByTacReset } from '../../../../actions/productActions';
import { listSims, updateSim } from '../../../../actions/simActions';

import {
  listFleetUsers,
  addFleetUser,
  addFleetUsersByFile,
  updateFleetUser,
  deleteFleetUser,
  resetFleetUserAddSuccess,
  resetFleetUserAddByFileSuccess,
  resetFleetUserDeleteSuccess,
  resetFleetUserUpdateSuccess,
} from '../../../../actions/fleetUsersActions'

import SearchIcon from '@material-ui/icons/Search';

import { addToCart } from '../../../../actions/cartActions';
import excellogo from '../../../../assets/images/excel-logo.png';

import './Fleets.css'
import { listContactsOdoo } from '../../../../actions/contactOdooActions';

import FleetUsers from '../FleetUsers/FleetUsers'
import UserModal from './UserModal/UserModal';
import SimModal from './SimModal/SimModal';

import { useTranslation } from 'react-i18next';
import { format, setMonth, startOfYear } from 'date-fns';
import { fr, enUS } from 'date-fns/locale';


function Fleet() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [simCardForm, setSimCardForm] = useState({ id: null, number: '', phone_number: '', puk: '' });
  const [simCard, setSimCard] = useState({ id: null, number: '', phone_number: '', puk: '' });

  const [row, setRow] = useState(null);

  const fleetList = useSelector(state => state.fleetList)
  const { error, loading, fleet, page, pages } = fleetList

  const FleetGlobalImport = useSelector(state => state.FleetGlobalImport)
  const { error: errorGlobalImport, loading: loadingGlobalImport, success: successGlobalImport } = FleetGlobalImport

  const fleetUpdate = useSelector(state => state.fleetUpdate)
  const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = fleetUpdate

  const deletedImeis = useSelector(state => state.deletedImeis)
  const { error: errorDeleteImeis, loading: loadingDeleteImeis, deletedImeisList, success: successDeletedImeisList } = deletedImeis

  const getAllHistoryList = useSelector(state => state.getAllHistoryList)
  const { error: errorGetAllHistory, loading: loadingGetAllHistory, historyAll } = getAllHistoryList

  const productFromCircleByImei = useSelector(state => state.productFromCircleByImei)
  const { circleProductByImei, error: errorCircleProduct, success: successCircleProduct, loading: loadingCircleProduct } = productFromCircleByImei

  const productFromCircleByTac = useSelector(state => state.productFromCircleByTac)
  const { circleProductByTAC, error: errorCircleProductByTAC, success: successCircleProductByTAC, loading: loadingCircleProductByTAC } = productFromCircleByTac

  const fleetUsersList = useSelector(state => state.fleetUsersList)
  const { error: errorFleetUsersList, loading: loadingFleetUsersList, fleetUsers } = fleetUsersList

  const simList = useSelector(state => state.simList)
  const { sims } = simList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const contactOdooList = useSelector((state) => state.contactOdooList)
  const { contactsOdoo, error: errorContactOdooList } = contactOdooList

  // const getHistoryList = useSelector((state) => state.getHistoryList);
  // const { history } = getHistoryList;

  const fleetDelete = useSelector(state => state.fleetDelete)
  const { success: successDelete, error: errorDelete } = fleetDelete

  const [users, setUsers] = useState([]);

  const [form, setForm] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);
  const [globalImportModalOpen, setGlobalImportModalOpen] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const [fleetUsersModalOpen, setFleetUsersModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedSimCard, setSelectedSimCard] = useState(null);

  const fleetUsersAdd = useSelector(state => state.fleetUsersAdd)
  const { error: errorUsersAdd, loading: loadingUsersAdd, success: successUsersAdd, fleetUser } = fleetUsersAdd

  const fleetUsersUpdate = useSelector(state => state.fleetUsersUpdate)
  const { error: errorUsersUpdate, loading: loadingUsersUpdate, success: successUsersUpdate } = fleetUsersUpdate

  const productList = useSelector(state => state.productList)
  const { products } = productList

  const [modalOpen, setModalOpen] = useState(false);
  const [simModalOpen, setSimModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationSimModalOpen, setConfirmationSimModalOpen] = useState(false);
  const [confirmationSuppressionModalOpen, setConfirmationSuppressionModalOpen] = useState(false);
  const [pukModalOpen, setPukModalOpen] = useState(false);

  const [rowIdx, setRowIdx] = useState(null);

  const [qtyByBrand, setQtyByBrand] = useState(null)
  const [qtyByType, setQtyByType] = useState(null)
  const [mobileInfo, setMobileInfo] = useState(null)
  const [averageMobileAge, setAverageMobileAge] = useState([])

  //const searchKeys = ['brand', 'current_user', 'name'];
  //const searchImeiKeys = ['imei'];
  const searchKeys = ['imei', 'serial', 'fleet_product_details.name', 'fleet_product_details.brand',
    'user_lastname', 'user_firstname', 'sim_number']
  const fuseOptions = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: searchKeys
  };

  const [deviceRows, setDeviceRows] = useState([]);

  const inputRef = useRef(null);
  const [imeiInput, setImeiInput] = useState('');

  const [categorie, setCategorie] = useState();
  const [brand, setBrand] = useState();
  const [name, setName] = useState();
  const [entity, setEntity] = useState();
  const [current_user, setCurrentUser] = useState();

  const [puk, setPuk] = useState(null);
  const [modalPuk, setModalPuk] = useState(null)

  const [filterObj, setFilterObj] = useState({});
  const [emptyFilter, setEmptyFilter] = useState(false);
  const [removedFromFleet, setRemovedFromFleet] = useState(false);
  const [removedFromFleetChecked, setRemovedFromFleetChecked] = useState(false);
  const [history, setHistory] = useState([]);

  const [fleetData, setFleetData] = useState();
  const [filteredFleetData, setFilteredFleetData] = useState([]);
  const [selectedImei, setSelectedImei] = useState();
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedSimUser, setSelectedSimUser] = useState();

  const [modalImei, setModalImei] = useState(0);

  const [selectedContact, setSelectedContact] = useState(null); // Ajout de l'état pour stocker le contact sélectionné
  const [resetSelect, setResetSelect] = useState(false);

  const msgExcelDownload = t('Download data to excel file');

  const msgAddDevice = t('Add device');
  const msgGlobalImportByFile = t('Import all your fleet data via file');

  const currentYear = startOfYear(new Date());
  let locale = i18n.language == 'fr' ? fr : enUS;

  const labels = Array.from({ length: 12 }, (_, i) => format(setMonth(currentYear, i), 'MMMM', { locale }));
  const months = Object.fromEntries(labels.map((month, i) => [month, i]));

  const msgFileExample = t('Download sample file');

  const excelUrl = "http://localhost:8000/media/example-files/importGlobalFlotte.xlsx";
  const puk_undefined = t('PUK code not defined')

  useEffect(() => {
    dispatch(listFleetUsers())
    if (sims.length === 0)
      dispatch(listSims())
    dispatch(productFromCircleByImeiReset())
    dispatch(listFleet());
    dispatch(listContactsOdoo(userInfo.id_odoo))
  }, [])

  useEffect(() => {
    if (contactsOdoo && contactsOdoo.length > 0) {
      setUsers(contactsOdoo.filter((contact) => contact.is_company === false))
    }
  }, [contactsOdoo])

  useEffect(() => {
    if (successDelete || successUpdate || successUsersAdd || successUsersUpdate || successGlobalImport) {
      handleSuccessDialogOpen();

      setSelectedUser(null);
      setSelectedSimCard(null);
      setSelectedImei(null);
      setModalOpen(false);
      setSimModalOpen(false);
      setConfirmationModalOpen(false);
      setFleetUsersModalOpen(false);
      setGlobalImportModalOpen(false);

      dispatch(resetFleetUserAddSuccess());
      dispatch(resetFleetUserUpdateSuccess());
      dispatch(resetDeleteSuccess());
      dispatch(resetUpdateSuccess());
      dispatch(resetDeleteSuccess());
      dispatch(resetGlobalImportSuccess());

      dispatch(listFleet());
    }
  }, [successDelete, successUpdate, successUsersAdd, successUsersAdd, successUsersUpdate, successGlobalImport])

  useEffect(() => {
    if (successUsersAdd) {
      dispatch(updateFleet(selectedImei, fleetUser.id))
      setSelectedImei(null)
    }
  }, [successUsersAdd, selectedUser])

  useEffect(() => {
    if (historyAll && historyAll.length > 0 && !removedFromFleet) {
      setFilteredFleetData(filteredFleetData.filter((item) => {
        return historyAll.some((h) => {
          if (removedFromFleet)
            return historyAll.some((h) => {
              return h.imei === item.imei &&
                (
                  h.history && h.history.length > 0 &&
                  (!h.history[h.history.length - 1].remove_from_fleet ||
                    h.history[h.history.length - 1].remove_from_fleet === undefined)
                )
                ;
            });
        });
      }
      ));
    }
  }, [removedFromFleet])

  useEffect(() => {
    if (fleet && fleet.length > 0) {
      let imeis = '';
      fleet.forEach((item) => {
        imeis += item.imei + ',';
      })
      dispatch(getAllHistory(imeis));
    }
  }, [fleet])

  useEffect(() => {
    if (historyAll && historyAll.length > 0) {
      setQtyByBrand(getQtyByBrand());
      setQtyByType(getQtyByType());

      setMobileInfo(getMobileInfo());
      setAverageMobileAge(getAverageMobileAge());
    }
  }, [historyAll])

  useEffect(() => {
    if (fleet && fleet.length > 0) {
      dispatch(getDeletedImeis(fleet.map((item) => { return item.imei })));
      dispatch(getAllHistory(fleet.map((item) => { return item.imei })));
      setFleetData(fleet);
    }

  }, [fleet])

  useEffect(() => {
    if (fleet && fleet.length > 0 && historyAll && historyAll.length > 0) {
      let defaultFleet = fleet.filter((item) => {
        return historyAll.some((h) => {
          return h.imei === item.imei &&
            (
              h.history && h.history.length > 0 &&
              (!h.history[h.history.length - 1].remove_from_fleet ||
                h.history[h.history.length - 1].remove_from_fleet === undefined)
            )
            ;
        });
      });
      setFilteredFleetData(defaultFleet);
      FillFilters(fleet);
    }
  }, [historyAll])

  useEffect(() => {
    if (fleetData && fleetData.length > 0) {
      const filteredFleetDataArr = fleetData.filter((p) => {
        return Object.keys(filterObj).every((key) => {
          if (key === 'removedFromFleet') {
            // On va traiter ici l'affichage des produits qui ont été retirés de la flotte
            if (filterObj[key] === false && historyAll != undefined && historyAll.length > 0) {
              return historyAll.some((h) => {
                p.removed = !(h.imei === p.imei &&
                  (
                    h.history && h.history.length > 0 &&
                    (!h.history[h.history.length - 1].remove_from_fleet ||
                      h.history[h.history.length - 1].remove_from_fleet === undefined)
                  ))
                  ;
                return h.imei === p.imei &&
                  (
                    h.history && h.history.length > 0 &&
                    (!h.history[h.history.length - 1].remove_from_fleet ||
                      h.history[h.history.length - 1].remove_from_fleet === undefined)
                  )
                  ;
              });
            }
            else {
              return true;
            }
          }
          else if (key === 'entity') {
            
            
            return filterObj[key] === p.entite.id;
          }
          else if (filterObj[key] !== null && filterObj[key] !== undefined && filterObj[key] !== '') {
            return filterObj[key] === findVal(p, key);
          }
          else
            return true;
        });
      });
      setFilteredFleetData(filteredFleetDataArr);
    }

  }, [filterObj, fleetData]);

  useEffect(() => {
    if (selectedUser && selectedImei) {
      dispatch(updateFleet(selectedImei, selectedUser.id))
    }
  }, [selectedUser, selectedImei])

  useEffect(() => {
    if (selectedSimCard && selectedSimUser) {
      let fleet_user_row = {
        id: selectedSimUser.id,
        firstname: selectedSimUser.firstname,
        lastname: selectedSimUser.lastname,
        entity: selectedSimUser.entity,
        role: selectedSimUser.role,
        fleet_sim: selectedSimCard ? selectedSimCard.id : null,
      }
      dispatch(updateFleetUser(fleet_user_row))
      setSelectedSimCard(null);
      setSelectedUser(null);
    }
  }, [selectedSimCard, selectedSimUser])

  function getQtyByBrand() {
    const brands = fleet.reduce((acc, item) => {
      if (item.fleet_product_details && item.fleet_product_details.length > 0) {
        if (acc[item.fleet_product_details[0].brand]) {
          acc[item.fleet_product_details[0].brand] += 1;
        } else {
          acc[item.fleet_product_details[0].brand] = 1;
        }
      }
      return acc;
    }, {});
    return brands
  }

  function getQtyByType() {
    const types = fleet.reduce((acc, item) => {
      if (item.fleet_product_details && item.fleet_product_details.length > 0) {
        if (acc[item.fleet_product_details[0].categorie]) {
          acc[item.fleet_product_details[0].categorie] += 1;
        } else {
          acc[item.fleet_product_details[0].categorie] = 1;
        }
      }
      return acc;
    }, {});
    return types
  }

  function getMobileInfo() {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: t('Number of mobiles'),
        },
      },
    };

    const data = {
      labels,
      datasets: [
        {
          label: t('In circulation'),
          data: labels.map((label) => getNbMobilesByMonth(months[label])),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: t('In SAV'),
          data: labels.map((label) => getNbMobilesSavByMonth(months[label])),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    };

    function getNbMobilesByMonth(monthIndex) {
      const date = new Date();
      const monthDate = new Date(date.setMonth(monthIndex));

      let nbMobiles = 0;
      let nbMobilesLastYear = 0;

      let historyItems = historyAll.filter((history_item) => {
        return fleet.some((fleet_item) => fleet_item.imei === history_item.imei);
      });

      if (historyItems.length > 0) {
        nbMobiles = historyItems.filter((h) =>
          h.history.filter((item) => new Date(item.add_to_fleet) <= monthDate
            &&
            (
              !item.remove_from_fleet ||
              new Date(item.remove_from_fleet) > monthDate
            )
          ).length
        ).length;


        nbMobilesLastYear = historyItems.filter((h) =>
          h.history.filter((item) => new Date(item.add_to_fleet) <= minusOneYear(new Date().toUTCString())
            && (
              !item.remove_from_fleet ||
              new Date(item.remove_from_fleet) >= minusOneYear(new Date().toUTCString())
            )).length
        ).length;
      }

      return nbMobiles;
    }

    function getNbMobilesSavByMonth(monthIndex) {
      const date = new Date();
      const monthDate = new Date(date.setMonth(monthIndex));
      const maxDayInMonth = daysInMonth(monthDate.getFullYear(), monthIndex)
      let nbSav = 0;

      let historyItems = historyAll.filter((history_item) => {
        return fleet.some((fleet_item) => fleet_item.imei === history_item.imei && history_item.history.filter((item) => item.sav_history).length > 0);
      });


      if (historyItems.length > 0) {
        historyItems.map((item) => {
          const sav = item.history.filter((item) => item.sav_history).length > 0 ? item.history.filter((item) => item.sav_history) : [];
          sav.map((s) => {
            let sav_item = s.sav_history
            if (new Date(sav_item.create_date) <= new Date(monthDate.setDate(maxDayInMonth)) && new Date(sav_item.write_date) >= new Date(monthDate.setDate(1))) {
              nbSav++;
            }
          })
        });
        return nbSav;
      }
      else return 0;
    }




    return { "options": options, "data": data }
  }

  function getAverageMobileAge() {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: t('Average fleet age'),
        },
      },
    };

    const data = {
      labels,
      datasets: [
        {
          fill: true,
          label: t('Average age'),
          data: labels.map((label) => getAverageMobileAgeByIndex(months[label])),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    };

    function getAverageMobileAgeByIndex(monthIndex) {
      const date = new Date();
      const monthDate = new Date(date.setMonth(monthIndex));
      let monthAges = [];

      let historyItems = historyAll.filter((history_item) => {
        return fleet.some((fleet_item) => fleet_item.imei === history_item.imei);
      });

      if (historyItems.length > 0) {
        historyItems.map((item) => {
          item.history.map((history) => {
            let add_to_fleet = new Date(history.add_to_fleet);
            if (add_to_fleet <= monthDate &&
              (!history.remove_from_fleet || (history.remove_from_fleet && new Date(history.remove_from_fleet) >= monthDate))
            ) {
              var age = monthDate.getFullYear() - add_to_fleet.getFullYear();
              var m = monthDate.getMonth() - add_to_fleet.getMonth();
              monthAges.push(age * 12 + m);
            }
          })
        });
      }
      const average = monthAges.length > 0 ? monthAges.reduce((a, b) => a + b) / monthAges.length : 0;

      return average;
    }

    return { "options": options, "data": data }
  }

  const handleSuccessDialogOpen = () => {
    setOpenSuccessDialog(true);
  };

  const handleSuccessDialogClose = () => {
    setOpenSuccessDialog(false);
  };

  const [sortedRows, setSortedRows] = useState(deviceRows);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const handleSort = (columnKey, direction) => {
    const sortedData = [...sortedRows].sort((a, b) => {
      if (direction === 'ASC') {
        return a[columnKey].localeCompare(b[columnKey]);
      } else {
        return b[columnKey].localeCompare(a[columnKey]);
      }
    });

    setSortedRows(sortedData);
  };

  const onHeaderClick = (column) => {
    let newSortDirection = sortDirection === 'ASC' ? 'DESC' : 'ASC';
    if (sortColumn !== column.key) {
      newSortDirection = 'ASC';
    }
    setSortColumn(column.key);
    setSortDirection(newSortDirection);
    handleSort(column.key, newSortDirection);
  };

  const device_columns = [
    {
      key: 'brand', name: t('brand'), cellClass: 'cell-align-center',
      headerRenderer: (props) => (
        <HeaderCellRenderer
          {...props}
          onHeaderClick={onHeaderClick}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
      ),
    },
    {
      key: 'model', name: t('model'), cellClass: 'cell-align-center',
      headerRenderer: (props) => (
        <HeaderCellRenderer
          {...props}
          onHeaderClick={onHeaderClick}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
      ),
    },
    {
      key: 'number',
      name: t('serial number /imei'),
      headerRenderer: (props) => (
        <HeaderCellRenderer
          {...props}
          onHeaderClick={onHeaderClick}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
      ),
      cellClass: 'cell-align-center'
    },
    {
      key: 'user',
      name: t('user'),
      headerRenderer: (props) => (
        <HeaderCellRenderer
          {...props}
          onHeaderClick={onHeaderClick}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
      ),
      formatter: ({ row }) => <UserCell row={row} />,
      cellClass: 'cell-align-center',
      sortable: true
    },
    {
      key: 'entite', name: t('entity'), cellClass: 'cell-align-center',
      headerRenderer: (props) => (
        <HeaderCellRenderer
          {...props}
          onHeaderClick={onHeaderClick}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
      ),
    },
    {
      key: 'sim',
      name: t('sim card number'),
      headerRenderer: (props) => (
        <HeaderCellRenderer
          {...props}
          onHeaderClick={onHeaderClick}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
      ),
      formatter: ({ row }) => <SimCell row={row} />,
      cellClass: 'cell-align-center',
      sortable: true
    },
    {
      key: 'phone_number', name: t('Line number'), cellClass: 'cell-align-center',
      headerRenderer: (props) => (
        <HeaderCellRenderer
          {...props}
          onHeaderClick={onHeaderClick}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
      ),
    },
    {
      key: 'date', name: t('Date of entry'), cellClass: 'cell-align-center',
      headerRenderer: (props) => (
        <HeaderCellRenderer
          {...props}
          onHeaderClick={onHeaderClick}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
      ),
    },
    {
      key: 'actions',
      name: t('actions'),
      headerRenderer: () => <div style={{ textAlign: 'center' }}>Actions</div>,
      formatter: ({ row }) => <ActionsCell row={row} />,
      cellClass: 'cell-align-center',
      width: 50,
    },
  ];

  function HeaderCellRenderer({
    column,
    onHeaderClick,
    sortColumn,
    sortDirection,
  }) {
    const handleClick = () => {
      onHeaderClick(column);
    };

    const isSorted = sortColumn === column.key;

    return (
      <div
        onClick={handleClick}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          cursor: 'pointer',
        }}
      >
        {column.name}
        {isSorted && (sortDirection === 'ASC' ? '▲' : '▼')}
      </div>
    );
  }

  useEffect(() => {
    if (filteredFleetData && filteredFleetData != undefined && filteredFleetData.length > 0) {
      setBrand(removeDuplicatesByNestedProperty(filteredFleetData, "brand").map((item) => { return { label: item.brand, value: item.brand } }));
      setCategorie(removeDuplicatesByNestedProperty(filteredFleetData, "categorie").map((item) => { return { label: item.categorie, value: item.categorie } }));
      let fleet_product_details = filteredFleetData.map((item) => { return item.fleet_product_details[0] });
      setName(removeDuplicatesByNestedProperty(fleet_product_details, "name").map((item) => { return { label: item.name, value: item.name } }));
      setCurrentUser(removeDuplicatesByNestedProperty(filteredFleetData, "user_name").map((item) => { return { label: item.current_user, value: item.current_user } }));
      let entities = filteredFleetData.map((item) => { return { label: item.entite.name, value: item.entite.id } });
      setEntity(removeDuplicatesByNestedProperty(entities, "value"));
      setDeviceRows(getDeviceRowsFromFilteredFleetData(filteredFleetData));
    }
  }, [filteredFleetData])

  useEffect(() => {
    if (deviceRows && deviceRows != undefined && deviceRows.length > 0) {
      setSortedRows(deviceRows);
    }
  }, [deviceRows])

  function getDeviceRowsFromFilteredFleetData(filteredFleetData) {
    let deviceRows = [];
    filteredFleetData.forEach((item) => {
      let deviceRow = {};
      deviceRow.id = item.id;
      deviceRow.entite = item.entite ? item.entite.name : '';
      deviceRow.user = item.user ? item.user.firstname + ' ' + item.user.lastname : '';
      deviceRow.fleet_user = item.user ? item.user : null;
      deviceRow.brand = item.fleet_product_details[0] != undefined && item.fleet_product_details[0].brand ? item.fleet_product_details[0].brand : '';
      deviceRow.model = item.fleet_product_details[0] != undefined && item.fleet_product_details[0].name ? item.fleet_product_details[0].name : '';
      deviceRow.number = item.imei;
      deviceRow.simCard = item.sim ? item.sim : null;
      deviceRow.sim = item.sim && item.sim.number ? item.sim.number : '';
      deviceRow.phone_number = item.sim && item.sim.phone_number ? item.sim.phone_number : '';
      deviceRow.date = new Date(item.created_at).toLocaleDateString('fr-FR');
      deviceRow.product = item.fleet_product_details[0];
      deviceRow.removed = item.removed ? item.removed : false;
      deviceRow.can_be_ordered = item.can_be_ordered ? item.can_be_ordered : false;

      deviceRows.push(deviceRow);
    });
    return deviceRows;
  }

  function ActionsCell({ row }) {
    const row_idx = sortedRows.findIndex((r) => r.id === row.id);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userAnchorEl, setUserAnchorEl] = useState(null);
    const [historiqueAnchorEl, setHistoriqueAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      setRowIdx(row_idx);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleMenuItemClick = (event, setAnchorFunction, currentAnchorEl) => {
      if (currentAnchorEl === event.currentTarget) {
        // Si l'élément de la liste est déjà ouvert, le fermer
        setAnchorFunction(null);
      } else {
        // Sinon, ouvrir le sous-menu pour cet élément de la liste
        setAnchorFunction(event.currentTarget);
      }
    };

    const viewHistory = () => {
      handleClose();
      navigate(`/fleet-details/${row.id}/`, { state: { f: fleetData.filter((item) => item.id === row.id)[0] } });
    };

    const createTicket = () => {
      navigate(`/aftersale/new`, { state: { f: fleetData.filter((item) => item.id === row.id)[0] } });
    };

    return (
      <div className='clickable-cell'>
        <div onClick={handleClick} style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </div>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          getContentAnchorEl={null}
          PaperProps={{
            style: {
              minWidth: '200px',
            },
          }}
        >
          <List>
            {
              row.can_be_ordered ?
                <ListItem button
                  onClick={() => {
                    handleOrderProduct();
                    handleClose();
                  }}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faCartShopping} />
                  </ListItemIcon>
                  {t("Add")}
                </ListItem>
                : null
            }
            <ListItem button onClick={createTicket}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faTicket} />
              </ListItemIcon>
              {t("Create my ticket")}
            </ListItem>
            <ListItem button onClick={viewHistory}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faBook} />
              </ListItemIcon>
              {t("View History")}
            </ListItem>
            <ListItem button onClick={() => {
              setConfirmationSuppressionModalOpen(true);
              handleClose();
            }}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faTrash} />
              </ListItemIcon>
              {t("Remove from my fleet")}
            </ListItem>
          </List>
        </Menu>
      </div>
    );

  }

  function UserCell({ row }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userAnchorEl, setUserAnchorEl] = useState(null);
    const [materialAnchorEl, setMaterialAnchorEl] = useState(null);
    const [simAnchorEl, setSimAnchorEl] = useState(null);
    const [entiteAnchorEl, setEntiteAnchorEl] = useState(null);

    const [mobileAnchorEl, setMobileAnchorEl] = useState(null);
    const [tabletAnchorEl, setTabletAnchorEl] = useState(null);
    const [pcAnchorEl, setPcAnchorEl] = useState(null);


    const handleClick = (event) => {
      setSelectedImei(row.number);
      setSelectedSimUser(row.fleet_user);
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setUserAnchorEl(null);
      setMaterialAnchorEl(null);
      setSimAnchorEl(null);
      setEntiteAnchorEl(null);
    };

    const handleMenuItemClick = (event, setAnchorFunction, currentAnchorEl) => {
      if (currentAnchorEl === event.currentTarget) {
        // Si l'élément de la liste est déjà ouvert, le fermer
        setAnchorFunction(null);
      } else {
        // Sinon, ouvrir le sous-menu pour cet élément de la liste
        setAnchorFunction(event.currentTarget);
      }
    };

    const handleSubMenuItemClick = (event, setSubAnchorFunction, currentAnchorEl) => {
      if (currentAnchorEl === event.currentTarget) {
        // Si l'élément de la liste est déjà ouvert, le fermer
        setSubAnchorFunction(null);
      } else {
        // Sinon, ouvrir le sous-menu pour cet élément de la liste
        setSubAnchorFunction(event.currentTarget);
      }
    };

    const viewUserHistory = () => {
      handleClose();
      navigate(`/fleet-user-history/`, {
        state:
          { fleet_user: fleetData.filter((item) => item.id === row.id)[0].user }
      });
    };


    return (
      <div className='clickable-cell'>
        <span onClick={handleClick} style={{ cursor: 'pointer', display: 'inline-block', width: '100%', height: '100%' }}>
          {row.user || t('Unaffected')}
        </span>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          getContentAnchorEl={null}
          PaperProps={{
            style: {
              minWidth: '200px',
            },
          }}
        >
          <List>
            <ListItem button onClick={(event) => handleMenuItemClick(event, setUserAnchorEl, userAnchorEl)}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faUser} />
              </ListItemIcon>
              {t('user')}
            </ListItem>
            <Collapse
              in={Boolean(userAnchorEl)}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem button onClick={openModal} style={{ paddingLeft: '40px' }}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faLink} />
                  </ListItemIcon>
                  {t('Associate')}
                </ListItem>
                {
                  row.user ? (
                    <>
                      <ListItem button onClick={() => setConfirmationModalOpen(true)} style={{ paddingLeft: '40px' }}>
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faLinkSlash} />
                        </ListItemIcon>
                        {t('Ungroup')}
                      </ListItem>
                      <ListItem button onClick={viewUserHistory} style={{ paddingLeft: '40px' }}>
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faBook} />
                        </ListItemIcon>
                        {t('See history')}
                      </ListItem>
                    </>
                  ) : null
                }
              </List>
            </Collapse>

            {row.user ? (
              <>
                {/* Further elements have been commented out but similar changes as above should be made */}
              </>
            ) : null
            }
          </List>
        </Menu>
      </div>
    );

  }

  function SimCell({ row }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userAnchorEl, setUserAnchorEl] = useState(null);
    const [simAnchorEl, setSimAnchorEl] = useState(null);

    const handleClick = (event) => {
      setSelectedSimUser(row.fleet_user);
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setUserAnchorEl(null);
      setSimAnchorEl(null);
    };

    const handleMenuItemClick = (event, setAnchorFunction, currentAnchorEl) => {
      if (currentAnchorEl === event.currentTarget) {
        // Si l'élément de la liste est déjà ouvert, le fermer
        setAnchorFunction(null);
      } else {
        // Sinon, ouvrir le sous-menu pour cet élément de la liste
        setAnchorFunction(event.currentTarget);
      }
    };

    const handleSubMenuItemClick = (event, setSubAnchorFunction, currentAnchorEl) => {
      if (currentAnchorEl === event.currentTarget) {
        // Si l'élément de la liste est déjà ouvert, le fermer
        setSubAnchorFunction(null);
      } else {
        // Sinon, ouvrir le sous-menu pour cet élément de la liste
        setSubAnchorFunction(event.currentTarget);
      }
    };

    const viewPukCode = () => {
      const pukValue = (row.simCard && row.simCard.puk) ? row.simCard.puk : '';
      setSimCardForm({
        id: row.simCard.id,
        number: row.simCard.number,
        phone_number: row.simCard.phone_number,
        puk: pukValue,
      });
      setSimCard({
        id: row.simCard.id,
        number: row.simCard.number,
        phone_number: row.simCard.phone_number,
        puk: pukValue,
      })
      setPukModalOpen(true);
    };

    if (!row.user)
      return null;
    else
      return (
        <div className='clickable-cell'>
          <span onClick={handleClick} style={{ cursor: 'pointer', display: 'inline-block', width: '100%', height: '100%' }}>
            {row.sim || t('Unaffected')}
          </span>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            getContentAnchorEl={null}
            PaperProps={{
              style: {
                minWidth: '200px',
              },
            }}
          >

            <List>

              <>
                <ListItem button onClick={(event) => handleMenuItemClick(event, setSimAnchorEl, simAnchorEl)}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faSimCard} />
                  </ListItemIcon>
                  {t("SIM card")}
                </ListItem>
                <Collapse
                  in={Boolean(simAnchorEl)}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem button onClick={openSimModal} style={{ paddingLeft: '40px' }}>
                      <ListItemIcon>
                        <FontAwesomeIcon icon={faLink} />
                      </ListItemIcon>
                      {t("Associate")}
                    </ListItem>
                    {
                      row.sim && row.sim != '' ? (
                        <>
                          <ListItem button onClick={() => setConfirmationSimModalOpen(true)} style={{ paddingLeft: '40px' }}>
                            <ListItemIcon>
                              <FontAwesomeIcon icon={faLinkSlash} />
                            </ListItemIcon>
                            {t("Ungroup")}
                          </ListItem>
                          <ListItem button onClick={viewPukCode}>
                            <ListItemIcon>
                              <FontAwesomeIcon icon={faLock} />
                            </ListItemIcon>
                            {t("PUK code")}
                          </ListItem>
                        </>
                      ) : null
                    }
                  </List>
                </Collapse>
              </>
            </List>
          </Menu>
        </div>
      );
  }

  function FillFilters(data) {
    const categories = removeDuplicatesByNestedProperty(data, "categorie").map((item) => { return { label: item.categorie, value: item.categorie } });
    const brands = removeDuplicatesByNestedProperty(data, "brand").map((item) => { return { label: item.brand, value: item.brand } });
    let fleet_product_details = data.map((item) => { return item.fleet_product_details[0] })
    const names = removeDuplicatesByNestedProperty(fleet_product_details, "name").map((item) => { return { label: item.name, value: item.name } });
    const current_users = removeDuplicatesByNestedProperty(data, "user_name").map((item) => { return { label: item.current_user, value: item.current_user } });

    setCategorie(categories);
    setBrand(brands);
    setName(names);
    setCurrentUser(current_users);
  }

  let subtitle;
  const fleetUsersCustomStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '30%',
      zIndex: 6,
    },
  };

  const addMobile = () => {
    navigate(`/addtofleet`);
  };

  const onHandleSelectChange = useCallback((value, key) => {
    // On passe une copie de l'objet pour déclencher le re-render
    // UseEffect utilise une stricte égalité entre les objets et ne regarde pas les propriétés de ces objets
    const newFilterObj = { ...filterObj };
    if (value) {
      newFilterObj[key] = value;
    } else {
      delete newFilterObj[key];
      newFilterObj['removedFromFleet'] = false;
      setRemovedFromFleetChecked(false);
    }
    setFilterObj(newFilterObj);
  }, []);

  const RemoveFromFleetHandler = (e) => {
    const { value, checked } = e.target;
    setRemovedFromFleetChecked(checked);
    setFilterObj({ ...Object.assign(filterObj, { ['removedFromFleet']: checked }) });
  }

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (searchTerm !== '') {
      let list = filteredFleetData && filteredFleetData.length > 0 ? filteredFleetData : fleetData;
      setFilteredFleetData(list.filter((f) =>
        f.imei.toLowerCase().includes(searchTerm.toLowerCase()) ||
        f.fleet_product_details[0].name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        f.fleet_product_details[0].brand.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (f.fleet_product_details[0].brand.toLowerCase() + ' ' + f.fleet_product_details[0].name.toLowerCase())
          .includes(searchTerm.toLowerCase()) ||
        (f.user && f.user.lastname != null ? f.user.lastname.toLowerCase().includes(searchTerm.toLowerCase()) : false) ||
        (f.user && f.user.firstname != null ? f.user.firstname.toLowerCase().includes(searchTerm.toLowerCase()) : false) ||
        (f.sim != null && f.sim.number != null ? f.sim.number.toLowerCase().includes(searchTerm.toLowerCase()) : false) ||
        (f.sim != null && f.sim.phone_number != null ? f.sim.phone_number.toLowerCase().includes(searchTerm.toLowerCase()) : false)
      ));
    } else {
      setFilteredFleetData(fleetData);
      setResetSelect(!resetSelect);
    }
  }, [searchTerm]);


  const formatResult = (item) => {
    return (
      <>
        {
          item && item.fleet_product_details != undefined ? (
            <span>{item.imei} --- {item.user != null && item.user != undefined ? item.user.firstname + ' ' + item.user.lastname : null}</span>
          ) : null
        }

      </>
    )
  };

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string.length === 0 || string === "") {
      setImeiInput('');
      setSearchTerm('');
      setFilteredFleetData(fleetData);
    }
    //setImeiInput(string);
    setSearchTerm(string);
  };

  const handleOnSelect = (item) => {
    // the item selected
    setFilteredFleetData(filteredFleetData.filter((product) => product.imei === item.imei));
    setImeiInput(item.imei);
    setSearchTerm(item.imei);
  };

  const handleOnFocus = () => {

  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(sortedRows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    };

    saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), 'flotte.xlsx');
  };

  const handleOrderProduct = () => {
    let row = sortedRows[rowIdx];
    if (row.number && row.number !== '' && row.number.length >= 8)
      dispatch(getCircleProductByTACOrName(row.number.substring(0, 8), row.brand, row.model));
  }

  const handleDelete = () => {
    let row = sortedRows[rowIdx];
    dispatch(removeFromFleet(row.number))
    setConfirmationSuppressionModalOpen(false)
  };

  const handleDissociateUser = () => {
    setConfirmationModalOpen(false);
    dispatch(updateFleet(selectedImei, null))
  };

  const handleDissociateSimCard = () => {
    setConfirmationSimModalOpen(false);
    if (selectedSimUser) {
      let fleet_user_row = {
        id: selectedSimUser.id,
        firstname: selectedSimUser.firstname,
        lastname: selectedSimUser.lastname,
        entity: selectedSimUser.entity,
        role: selectedSimUser.role,
        fleet_sim: null,
      }
      dispatch(updateFleetUser(fleet_user_row))
      selectedSimUser(null);
    }
  };

  useEffect(() => {
    //TODO Ajouter la possibilité de naviguer vers un produit non IMEI
    if (successCircleProductByTAC && circleProductByTAC && circleProductByTAC.id) {
      navigate(`/imei-products/${circleProductByTAC.id}/`, { state: { p: circleProductByTAC } });
      dispatch(productFromCircleByTacReset())
    }
  }, [circleProductByTAC, successCircleProductByTAC]);

  function openFleetUsersModal() {
    setFleetUsersModalOpen(true);
  }

  function closeFleetUsersModal() {
    setFleetUsersModalOpen(false);
  }

  const openModal = () => {
    setModalOpen(true);
  };

  const openGlobalImportModal = () => {
    setGlobalImportModalOpen(true);
  };

  const openSimModal = () => {
    setSimModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const closeGlobalImportModal = () => {
    setPuk(puk_undefined)
    setGlobalImportModalOpen(false);
  };

  const closeSimModal = () => {
    setSimModalOpen(false);
  };

  const closePukModal = () => {
    setPuk(null)
    setPukModalOpen(false);
  };

  const addUserCard = (user) => {
    closeFleetUsersModal();
  };

  const addSimCard = (simCard) => {
    closeSimModal();
  };

  const selectUser = (user) => {
    setSelectedUser(user);
    closeModal();
  };

  const selectSimCard = (simCard) => {
    setSelectedSimCard(simCard);
    closeSimModal();
  };

  const rowClassGetter = (rowIdx) => {
    const row = sortedRows[rowIdx];
    // Remplacez cette condition par la condition réelle à vérifier dans `sortedRows`
    const isConditionMet = row && row.removed;

    //return isConditionMet ? 'highlighted-row' : '';
    return 'highlighted-row';
  };

  const RowRenderer = () => {
    return <Row {...this.props} extraClasses="highlighted-row" />
  }

  const handleFileImport = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      // Utilisez TextDecoder pour décoder les données avec l'encodage UTF-8
      const binaryData = e.target.result;
      const workbook = XLSX.read(binaryData, { type: 'binary' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const newRows = rows.slice(1).map((row) => {
        // Remplacer les cellules non remplies par des chaînes vides
        const sanitizedRow = row.map((cell) => (cell === undefined || cell === null ? '' : cell));

        return {
          date: sanitizedRow[0] != '' && sanitizedRow[0] != undefined ? sanitizedRow[0] : null,
          ref: sanitizedRow[1] != '' && sanitizedRow[1] != undefined ? sanitizedRow[1] : null,
          entite: sanitizedRow[2] != '' && sanitizedRow[2] != undefined ? sanitizedRow[2] : null,
          prenom: sanitizedRow[3] != '' && sanitizedRow[3] != undefined ? sanitizedRow[3] : null,
          nom: sanitizedRow[4] != '' && sanitizedRow[4] != undefined ? sanitizedRow[4] : null,
          type: sanitizedRow[5] != '' && sanitizedRow[5] != undefined ? sanitizedRow[5] : null,
          model: sanitizedRow[6] != '' && sanitizedRow[6] != undefined ? sanitizedRow[6] : null,
          number: sanitizedRow[7] != '' && sanitizedRow[7] != undefined ? sanitizedRow[7] : null,
          sim: sanitizedRow[8] != '' && sanitizedRow[8] != undefined ? sanitizedRow[8] : null,
        };
      });

      dispatch(importFleet(newRows));
      setIsOpen(false);
    };
    reader.readAsArrayBuffer(file);
  };

  const handlePukSubmit = (event) => {
    event.preventDefault();

    dispatch(updateSim(simCardForm));
    setSimCardForm({ id: null, number: '', phone_number: '', puk: '' })
    setSimCard({ id: null, number: '', phone_number: '', puk: '' })
    closePukModal();
  };

  const handleModalPukChange = (event) => {
    const { name, value } = event.target;
    setSimCardForm({ ...simCardForm, [name]: value });
  };

  const options = {
    responsive: false,  // This allows us to set the size of the chart
    maintainAspectRatio: true,  // Maintains the aspect ratio
    width: 200,  // Width in pixels
    height: 150,  // Height in pixels
  }

  return (
    <div>
      {loading ? <Loader /> :
        <>
          {
            userInfo && !userInfo.impersonated_by && fleet && fleet.length > 0 && mobileInfo && qtyByBrand && averageMobileAge ?
              <Row className='fleet-chart-row'>
                <Col className='fleet-chart-col' md={4}>
                  <Row className='custom-linechart-row'>
                    {mobileInfo ? <LineCharts item={mobileInfo} /> : null}
                  </Row>
                </Col>
                <Col md={3}>
                  {
                    fleet && fleet.length > 0 ?
                      <div className="custom-piechart">
                        {qtyByBrand ? <PieChart pieData={qtyByBrand} labelContent={t("Quantity")} title={t("Quantity by brand")} />
                          : null}
                      </div>
                      : null
                  }
                </Col>
                <Col className='fleet-chart-col' md={4}>
                  <Row className='custom-areachart-row'>
                    {averageMobileAge ? <AreaCharts item={averageMobileAge} /> : null}
                  </Row>
                </Col>
              </Row>
              : null
          }

          <Row className='fleet-row'>
            <Col md={1}>
              <button
                className='fleet-add-btn'
                onClick={addMobile}
                data-tip="React-tooltip"
                data-for={'tooltip-adduser'}
              ><FontAwesomeIcon icon={faMobileScreen} /></button>
              <ReactTooltip id={'tooltip-adduser'}
                type="dark"
                effect="solid"
                clickable={true}
                place='bottom'
                getContent={function () {
                  return (
                    <div>
                      <Row>{msgAddDevice}</Row>
                    </div>
                  )
                }} />
            </Col>
            <Col md={1}>
              <button
                className='fleet-add-btn'
                onClick={openGlobalImportModal}
                data-tip="React-tooltip"
                data-for={'tooltip-adduserbyfile'}
              ><FontAwesomeIcon icon={faFileCirclePlus} /></button>
              <ReactTooltip id={'tooltip-adduserbyfile'}
                type="dark"
                effect="solid"
                clickable={true}
                place='bottom'
                getContent={function () {
                  return (
                    <div>
                      <Row>{msgGlobalImportByFile}</Row>
                    </div>
                  )
                }} />
            </Col>
            <Col md={8}>
              <ReactSearchAutocomplete
                items={filteredFleetData && filteredFleetData != undefined && filteredFleetData.length > 0 ?
                  filteredFleetData
                  : []}
                fuseOptions={fuseOptions}
                resultStringKeyName="imei"
                onSearch={handleOnSearch}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                onClear={() => { setImeiInput(''); setSearchTerm(''); setFilteredFleetData(fleetData); setResetSelect(!resetSelect); }}
                autoFocus
                placeholder={t("Search")}
                formatResult={formatResult}
                threshold={0.6} // 0 = exact match, 1 = everything matches
                ignoreLocation={true}
                maxResults={8}
                styling={{
                  width: "100%",
                  border: "1px solid #dfe1e5",
                  borderRadius: "5px",
                  backgroundColor: "white",
                  boxShadow: "rgba(58, 53, 53, 0.2) 0px 1px 6px 0px",
                  hoverBackgroundColor: "#eee",
                  color: "#212121",
                  fontSize: "12px",
                  fontFamily: "Arial",
                  iconColor: "grey",
                  lineColor: "rgb(232, 234, 237)",
                  placeholderColor: "grey",
                  clearIconMargin: '0 5px 0 0',
                  searchIconMargin: '0 0 0 5px',
                  zIndex: 5,
                }}
              />
            </Col>
            <Col md={1}>
              <button
                onClick={exportToExcel}
                className='btn-excel-export'
                data-tip="React-tooltip" data-for={'tooltip'}
              >
                <FontAwesomeIcon icon={faFileExcel} size="xl" />
              </button>
              <ReactTooltip id={'tooltip'}
                type="dark"
                effect="solid"
                clickable={true}
                getContent={function () {
                  return (
                    <div>
                      <Row>{msgExcelDownload}</Row>
                    </div>
                  )
                }} />
            </Col>
          </Row>
          <Row className='fleet-row-list'>
            <Row className="fleet-display-items-row">
              <Col md={1}>
                <input
                  className="fleet-display-items-checkbox"
                  type="checkbox"
                  name={'removedFromFleet'}
                  value={removedFromFleet}
                  checked={removedFromFleetChecked}
                  onChange={(event) => RemoveFromFleetHandler(event)}
                />
              </Col>
              <Col md={6}>
                <label className="fleet-display-items-label">{t('View retired products')}</label>
              </Col>
            </Row>
            <Row className="fleet-search-row" >
              <Row>
                <Col md={3}>
                  <Select
                    className='fleet-select'
                    name="entity"
                    title="Entité"
                    value={form.entity}
                    options={entity}
                    onChangeFunc={onHandleSelectChange}
                    reset={resetSelect}
                  />
                </Col>
                <Col md={3}>
                  <Select
                    className='fleet-select'
                    name="categorie"
                    title="Categorie"
                    value={form.categorie}
                    options={categorie}
                    onChangeFunc={onHandleSelectChange}
                    reset={resetSelect}
                  />
                </Col>
                <Col md={3}>
                  <Select
                    className='fleet-select'
                    name="brand"
                    title="Marques"
                    value={form.brand}
                    options={brand}
                    onChangeFunc={onHandleSelectChange}
                    reset={resetSelect}
                  />
                </Col>
                <Col md={3}>
                  <Select
                    className='fleet-select'
                    name="name"
                    title="Modèle"
                    value={form.name}
                    options={name}
                    onChangeFunc={onHandleSelectChange}
                    reset={resetSelect}
                  />
                </Col>
              </Row>
            </Row>
            {fleet && fleet.length === 0 ? (
              <Message variant='info'>
                {t('Your fleet is empty')} 
              </Message>
            ) :
              <Row className='fleet-data-row'>
                <DataGrid
                  className='rdg-light fleet-datagrid'
                  columns={device_columns}
                  rows={sortedRows}
                  rowGetter={(i) => sortedRows[i]}
                  rowsCount={sortedRows.length}
                  onRowsChange={setSortedRows}
                  onHeaderClick={onHeaderClick}
                  onGridRowsUpdated={setSortedRows}
                  rowClassName={rowClassGetter}
                  rowRenderer={RowRenderer}
                />
              </Row>
            }
          </Row>

        </>

      }
      <Dialog
        open={openSuccessDialog}
        onClose={handleSuccessDialogClose}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
      >
        <DialogTitle id="success-dialog-title">{t('Successful operation')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="success-dialog-description">
            {t('The operation was successfully completed.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessDialogClose} color="primary">
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>

      <UserModal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        fleetUsers={fleetUsers}
        onAddUser={addUserCard}
        onSelectUser={selectUser}
      />

      <SimModal
        isOpen={simModalOpen}
        onRequestClose={closeSimModal}
        sims={sims}
        onAddSimCard={addSimCard}
        onSelectSimCard={selectSimCard}
      />

      <ConfirmationModal
        isOpen={confirmationModalOpen}
        onRequestClose={() => setConfirmationModalOpen(false)}
        text={t('Are you sure you want to unlink this user?')}
        onConfirmDelete={handleDissociateUser}
      />

      <ConfirmationModal
        isOpen={confirmationSimModalOpen}
        onRequestClose={() => setConfirmationSimModalOpen(false)}
        text={t('Do you really want to unpair this SIM card?')}
        onConfirmDelete={handleDissociateSimCard}
      />

      <ConfirmationModal
        isOpen={confirmationSuppressionModalOpen}
        operation={'suppression'}
        onRequestClose={() => setConfirmationSuppressionModalOpen(false)}
        text={t('Are you sure you want to remove this aircraft from your fleet? \n Please note that the associated user and SIM card will be retained.')}
        onConfirmDelete={handleDelete}
      />

      <Modal
        className="fleet-users-file-modal"
        isOpen={globalImportModalOpen}
        onRequestClose={closeGlobalImportModal}
        contentLabel={t('Importer un fichier csv')}
        style={{ overlay: { zIndex: 9999 } }}
      >
        {
          loadingGlobalImport ? <Loader /> :
            <div className="modal-content">
              <Row className='fleet-users-modal-row'>
                <Col md={8}>
                  <div className='fleet-users-modal-title'>{t('Importer un fichier csv')}</div>
                </Col>
                <Col className='fleet-users-modal-file-example' md={2}>
                  {/* TODO : A gérer depuis les fichiers statiques de Django */}
                  <a href={excelUrl} download>
                    <img className='fleet-users-excel-example'
                      src={excellogo}
                      alt="example"
                      data-tip="React-tooltip"
                      data-for={'tooltip-file-example'}
                    />

                    <ReactTooltip id={'tooltip-file-example'}
                      type="dark"
                      effect="solid"
                      clickable={true}
                      place='bottom'
                      getContent={function () {
                        return (
                          <div>
                            <Row>{msgFileExample}</Row>
                          </div>
                        )
                      }} />
                  </a>
                </Col>
              </Row>
              <Row className='fleet-users-modal-row'>
                <Col md={6}>
                  <input className='fleet-users-modal-input' type="file" accept=".xls,.xlsx,.csv" onChange={handleFileImport} />
                </Col>
              </Row>
              <Row className='fleet-users-modal-row'>
                <Col md={6}>
                  <button className='fleet-users-modal-close-btn' onClick={closeGlobalImportModal}>Fermer</button>
                </Col>
              </Row>
            </div>
        }
      </Modal>
      <Modal
        className="fleet-file-modal"
        isOpen={pukModalOpen}
        onRequestClose={closePukModal}
        contentLabel="Mon code PUK"
        style={{ overlay: { zIndex: 9999 } }}
      >
        <Container>
          <Row className="modal-header">
            <h2>{t('My PUK code')}</h2>
          </Row>
          <Form onSubmit={handlePukSubmit}>
            <Row>
              {
                (!simCardForm || !simCardForm.puk || simCardForm.puk != simCard.puk) ? (
                  <>
                    <span>{t('Add a PUK code')}</span>
                    <Form.Group>
                      <Form.Control
                        className='modal-input'
                        type="text"
                        name="puk"
                        placeholder="Code PUK"
                        value={simCardForm.puk}
                        onChange={handleModalPukChange}
                        required
                      />
                    </Form.Group>
                    <Row className="fleet-modal-add-btn-row">
                      <Col>
                        <button className='fleet-modal-cancel-btn' onClick={closePukModal}>{t('Cancel')}</button>
                      </Col>
                      <Col>
                        <button className='fleet-modal-add-btn' type="submit">{t('Add')}</button>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <span>
                      <h3>{simCardForm.puk}</h3>
                    </span>
                    <Row className="fleet-modal-add-btn-row">
                      <button className='fleet-modal-cancel-btn' onClick={closePukModal}>{t('Close')}</button>
                    </Row>
                  </>
                )
              }
            </Row>
          </Form>
        </Container>
      </Modal>
    </div >
  )
}

export default Fleet