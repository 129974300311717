import { 
    ORDER_LIST_REQUEST,
    ORDER_LIST_SUCCESS,
    ORDER_LIST_FAIL,

    ORDER_CREATE_REQUEST, 
    ORDER_CREATE_SUCCESS, 
    ORDER_CREATE_FAIL ,
    ORDER_CREATE_SUCCESS_RESET,

    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAIL,

    ORDER_PAY_REQUEST,
    ORDER_PAY_SUCCESS,
    ORDER_PAY_FAIL,
    ORDER_PAY_RESET,

    ORDER_BC_REQUEST,
    ORDER_BC_SUCCESS,
    ORDER_BC_FAIL,

    ORDER_BC_CREATE_REQUEST,
    ORDER_BC_CREATE_SUCCESS,
    ORDER_BC_CREATE_FAIL,
} from "../constants/orderConstants";

export const OrderListReducer = (state ={orders: [] }, action) => {
    switch (action.type) {
        case ORDER_LIST_REQUEST:
            return { loading: true, orders: [] }
        case ORDER_LIST_SUCCESS:
            return { loading: false, orders: action.payload }
        case ORDER_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const OrderBCListReducer = (state ={orders: [] }, action) => {
    switch (action.type) {
        case ORDER_BC_REQUEST:
            return { loading: true, orders: [] }
        case ORDER_BC_SUCCESS:
            return { loading: false, ordersBC: action.payload }
        case ORDER_BC_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const OrderBCCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER_BC_CREATE_REQUEST:
            return { loading: true };
        case ORDER_BC_CREATE_SUCCESS:
            return { loading: false, success: true, order: action.payload };
        case ORDER_BC_CREATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const OrderCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER_CREATE_REQUEST:
            return { loading: true };
        case ORDER_CREATE_SUCCESS:
            return { loading: false, success: true, order: action.payload };
        case ORDER_CREATE_FAIL:
            return { loading: false, error: action.payload };
        case ORDER_CREATE_SUCCESS_RESET:
            return { success: false};
        default:
            return state;
    }
}

export const OrderDetailsReducer = (state = { loading: true, orderItems: [], shippingAddress: {} }, action) => {
    switch (action.type) {
        case ORDER_DETAILS_REQUEST:
            return { ...state, loading: true }
        case ORDER_DETAILS_SUCCESS:
            return { loading: false, order: action.payload }
        case ORDER_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const OrderPayReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER_PAY_REQUEST:
            return { loading: true }
        case ORDER_PAY_SUCCESS:
            return { loading: false, success: true }
        case ORDER_PAY_FAIL:
            return { loading: false, error: action.payload }
        case ORDER_PAY_RESET:
            return {}
        default:
            return state
    }
}
