import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';

import Loader from '../../components/Loader/Loader'
import Message from '../../components/Message/Message'
import FormContainer from '../../components/FormContainer/FormContainer'
import { login } from '../../actions/userActions'

import './Login.css'
import { useTranslation } from 'react-i18next';

function Login() {
    const { t, i18n } = useTranslation();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const dispatch = useDispatch()
    const location = useLocation();
    let navigate = useNavigate();

    const redirect = location.search ? location.search.split('=')[1] : '/'

    const userLogin = useSelector((state) => state.userLogin)
    const { loading, error, userInfo } = userLogin

    useEffect(() => {
        if (userInfo) {
            navigate(redirect);
        }
    }, [userInfo, redirect])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(login(email, password))
    }

    return (
        <FormContainer>
            <h2 className='login-title'>{(t('Login to your account'))}</h2>
            {error && <Message variant='danger'>{error}</Message>}
            {loading && <Loader />}
            <Form onSubmit={submitHandler} className='login-form'>
                <Form.Group controlId='email'>
                    <Form.Label>{t('E-mail address')}</Form.Label>
                    <Form.Control
                        type='email'
                        placeholder={t('Enter your email address')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                </Form.Group>

                <Form.Group controlId='password'>
                    <Form.Label>{t('Password')}</Form.Label>
                    <Form.Control
                        type='password'
                        placeholder={t('Enter your password')}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    ></Form.Control>
                </Form.Group>
                <Button type='submit' className='login-btn'>
                    {t('Login')}
                </Button>
            </Form>

            <Row className='py-3'>
                <Col>
                    {t('new customer')} {'? '}
                    <Link to={redirect ? `/register?redirect=${redirect}` : '/register'} className='register-link'>
                        {t('Register')}
                    </Link>
                </Col>
            </Row>
        </FormContainer>
    )
}

export default Login
