import{
    HEADER_BRAND_ORDER_REQUEST,
    HEADER_BRAND_ORDER_SUCCESS,
    HEADER_BRAND_ORDER_FAIL,

    HEADER_BRAND_ORDER_ADD_REQUEST,
    HEADER_BRAND_ORDER_ADD_SUCCESS,
    HEADER_BRAND_ORDER_ADD_FAIL,

    HEADER_BRAND_ORDER_DEL_REQUEST,
    HEADER_BRAND_ORDER_DEL_SUCCESS,
    HEADER_BRAND_ORDER_DEL_FAIL
}
from '../constants/headerBrandOrderConstants.js'

import axios from 'axios'

export const listHeaderBrandOrder = () => async (dispatch, getState) => {
    try{
        dispatch({type: HEADER_BRAND_ORDER_REQUEST})

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const {data} = await instance.get(
            '/api/header-order'
            )
            
        dispatch({type: HEADER_BRAND_ORDER_SUCCESS, payload: data})
    } catch(error){
        dispatch({type: HEADER_BRAND_ORDER_FAIL, payload: error.response && error.response.data.message ? error.response.data.message : error.message})
    }
}

export const addHeaderBrandOrder = (headerBrandOrder) => async (dispatch, getState) => {
    try{
        dispatch({type: HEADER_BRAND_ORDER_ADD_REQUEST})

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const {data} = await instance.post(
            '/api/header-order/add/', headerBrandOrder
            )

        dispatch({type: HEADER_BRAND_ORDER_ADD_SUCCESS, payload: data})
    } catch(error){
        dispatch({type: HEADER_BRAND_ORDER_ADD_FAIL, payload: error.response && error.response.data.message ? error.response.data.message : error.message})
    }
}

export const delHeaderBrandOrder = (headerBrandOrder) => async (dispatch, getState) => {
    try{
        dispatch({type: HEADER_BRAND_ORDER_DEL_REQUEST})

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const {data} = await instance.post(
            '/api/header-order/del/', headerBrandOrder
            )

        dispatch({type: HEADER_BRAND_ORDER_DEL_SUCCESS, payload: data})
    } catch(error){
        dispatch({type: HEADER_BRAND_ORDER_DEL_FAIL, payload: error.response && error.response.data.message ? error.response.data.message : error.message})
    }
}