import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Nav, Navbar, NavDropdown, Col, Row, ListGroup } from 'react-bootstrap';

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRotate,
  faCheck,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

import DataGrid, { Row as DataGridRow, Column, textEditor } from 'react-data-grid';
import { Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import 'react-data-grid/lib/styles.css';
import {
  Menu,
  MenuItem,
  List,
  ListItem,
  Collapse,
  ListItemIcon,
} from '@material-ui/core';

import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import ReactTooltip from 'react-tooltip';

import './Rental.css'

import Loader from '../../../../components/Loader/Loader'


import { listProducts } from '../../../../actions/productActions';
import { listNonImeiProducts } from '../../../../actions/productActions';

import { listCategories } from '../../../../actions/categoriesActions';
import { listHeaderCategories } from '../../../../actions/headerCategoriesActions';
import { majRentalRate, rentalRateMajReset, rentalRateDelete } from '../../../../actions/rentalRateActions';

import Select from '../../../../components/Select/Select'
import { useTranslation } from 'react-i18next';
import { set } from "date-fns";

function Rental() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const productList = useSelector(state => state.productList)
  const { error, loading, products, page, pages } = productList

  const productNonImeiList = useSelector(state => state.productNonImeiList)
  const { productsNonImei, loading: nonImeiLoading } = productNonImeiList

  const categoriesList = useSelector(state => state.categoriesList)
  const { categories } = categoriesList

  const headerCategoriesList = useSelector((state) => state.headerCategoriesList);
  const { loading: loadingHeaderCategories, error: errorHeaderCategories, headerCategories } = headerCategoriesList;

  const rentalRateMaj = useSelector(state => state.rentalRateMaj)
  const { loading: loadingRentalRateMaj, error: errorRentalRateMaj, success: successRentalRateMaj } = rentalRateMaj

  const rentalRateDelete = useSelector(state => state.rentalRateDelete)
  const { loading: loadingRentalRateDelete, error: errorRentalRateDelete, success: successRentalRateDelete } = rentalRateDelete

  const [rowIdx, setRowIdx] = useState(null);

  const [productsRows, setProductsRows] = useState([]);

  const [productsData, setProductsData] = useState();
  const [filteredProductsData, setFilteredProductsData] = useState([]);

  const [selectHeaderCategorie, setSelectHeaderCategorie] = useState([]);
  const [selectedHeaderCategory, setSelectedHeaderCategory] = useState(null);

  const [selectCategorie, setSelectCategorie] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [resetSelect, setResetSelect] = useState(false);

  const [categorie, setCategorie] = useState();
  const [brand, setBrand] = useState();
  const [name, setName] = useState();
  const [price, setPrice] = useState();

  const [rentalEnabled, setRentalEnabled] = useState(false);
  const [rentalEnabledChecked, setRentalEnabledChecked] = useState(false);

  const searchKeys = ['brand', 'model', 'name.name']
  const fuseOptions = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: searchKeys
  };


  useEffect(() => {
    if (products && products != undefined && products.length === 0) {
      dispatch(listProducts())
    }
    if (productsNonImei && productsNonImei != undefined && productsNonImei.length === 0) {
      dispatch(listNonImeiProducts())
    }
    if (categories && categories != undefined && categories.length === 0) {
      dispatch(listCategories())
    }
    if (headerCategories && headerCategories != undefined && headerCategories.length === 0) {
      dispatch(listHeaderCategories())
    }
  }, [])

  useEffect(() => {
    if (successRentalRateMaj) {
      setSortedRows([])
      setRentalEnabled(false)
      setRentalEnabledChecked(false)
      setSelectedHeaderCategory(null)
      setSelectedCategory(null)
      dispatch(listProducts())
      dispatch(listNonImeiProducts())  
      dispatch(rentalRateMajReset())
    }
  }, [successRentalRateMaj])

  useEffect(() => {
    if (headerCategories && headerCategories.length > 0) {
      let values = []
      headerCategories
        .sort((a, b) => a.id - b.id).forEach(hc => {
          values.push({ value: hc.id, label: hc.name })
        })
      setSelectHeaderCategorie(values)
    }
  }, [headerCategories])

  useEffect(() => {
    if (selectedHeaderCategory && categories && categories.length > 0) {
      fillCategories()
    }
    else {
      setSelectedCategory(null);
      setSelectCategorie([]);
    }
  }, [selectedHeaderCategory, categories]);

  function fillCategories() {
    let values = [];
    categories
      .filter(c => selectedHeaderCategory.categories.includes(c.id))
      .sort((a, b) => a.id - b.id)
      .forEach(hc => {
        values.push({ value: hc.id, label: hc.name });
      });

    if (values.length == 1)
      setSelectedCategory(categories.filter(c => selectedHeaderCategory.categories.includes(c.id))[0]);
    else {
      setSelectedCategory(categories.filter(c => selectedHeaderCategory.categories.includes(c.id)))
    }

    setSelectCategorie(values);
  }

  useEffect(() => {
    if (selectedCategory) {
      if (selectedCategory.length > 0) {
        let product_to_display = [];
        selectedCategory.forEach(sc => {
          if (selectedCategory.is_imei) {
            product_to_display = [...product_to_display, ...products.filter(p => p.categories.includes(sc.id))];
          }
          else {
            product_to_display = [...product_to_display, ...productsNonImei.filter(p => p.categories.includes(sc.id))];
          }
        });
        setProductsData(product_to_display);
      }
      else if (selectedCategory.is_imei) {
        setProductsData(products.filter(p => p.categories.includes(selectedCategory.id)));
      }
      else {
        setProductsData(productsNonImei.filter(p => p.categories.includes(selectedCategory.id)));
      }
    }
    else {
      setProductsData([...products, ...productsNonImei]);
    }

  }, [selectedCategory])

  useEffect(() => {
    if (productsData && productsData.length > 0) {
      setFilteredProductsData(productsData);
    }
    else
      setFilteredProductsData([]);
  }, [productsData])

  useEffect(() => {
    if (searchTerm !== '') {
      let list = filteredProductsData && filteredProductsData.length > 0 ? filteredProductsData : productsData;
      setFilteredProductsData(list.filter((f) =>
        f.brand.toLowerCase().includes(searchTerm.toLowerCase()) ||
        f.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        f.model.toLowerCase().includes(searchTerm.toLowerCase())
      ));
    } else {
      setFilteredProductsData(productsData);
      setResetSelect(!resetSelect);
    }
  }, [searchTerm]);

  const onHandleHeaderCategorySelectChange = (value) => {
    setSelectedHeaderCategory(headerCategories.filter(hc => hc.id === value)[0])
  }

  const onHandleCategorySelectChange = (value) => {
    if (selectedHeaderCategory && selectedHeaderCategory != undefined)
      if (value && value != undefined)
        setSelectedCategory(categories.filter(c => c.id === value)[0])
      else
        fillCategories()
    else
      setSelectedCategory(categories.filter(c => c.id === value)[0])
  }

  const [sortedRows, setSortedRows] = useState(productsRows);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const handleSort = (columnKey, direction) => {
    const sortedData = [...sortedRows].sort((a, b) => {
      if (direction === 'ASC') {
        return a[columnKey].localeCompare(b[columnKey]);
      } else {
        return b[columnKey].localeCompare(a[columnKey]);
      }
    });

    setSortedRows(sortedData);
  };

  const onHeaderClick = (column) => {
    let newSortDirection = sortDirection === 'ASC' ? 'DESC' : 'ASC';
    if (sortColumn !== column.key) {
      newSortDirection = 'ASC';
    }
    setSortColumn(column.key);
    setSortDirection(newSortDirection);
    handleSort(column.key, newSortDirection);
  };

  const catalog_columns = [
    {
      key: 'brand', name: t('brand'), cellClass: 'cell-align-center',
      headerRenderer: (props) => (
        <HeaderCellRenderer
          {...props}
          onHeaderClick={onHeaderClick}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
      ),
    },
    {
      key: 'name',
      name: t('Name'),
      headerRenderer: (props) => (
        <HeaderCellRenderer
          {...props}
          onHeaderClick={onHeaderClick}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
      ),
      cellClass: 'cell-align-center'
    },
    {
      key: 'model', name: t('model'), cellClass: 'cell-align-center',
      headerRenderer: (props) => (
        <HeaderCellRenderer
          {...props}
          onHeaderClick={onHeaderClick}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
      ),
    },
    {
      key: 'rental_price',
      name: t('rental rate'),
      headerRenderer: (props) => (
        <HeaderCellRenderer
          {...props}
          onHeaderClick={onHeaderClick}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
      ),
      cellClass: 'cell-align-center'
    },
  ];

  function HeaderCellRenderer({
    column,
    onHeaderClick,
    sortColumn,
    sortDirection,
  }) {
    const handleClick = () => {
      onHeaderClick(column);
    };

    const isSorted = sortColumn === column.key;

    return (
      <div
        onClick={handleClick}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          cursor: 'pointer',
        }}
      >
        {column.name}
        {isSorted && (sortDirection === 'ASC' ? '▲' : '▼')}
      </div>
    );
  }

  useEffect(() => {
    if (filteredProductsData && filteredProductsData != undefined && filteredProductsData.length > 0) {
      setBrand(filteredProductsData.map((item) => { return { label: item.brand, value: item.brand } }));
      setCategorie(filteredProductsData.map((item) => { return { label: item.categorie, value: item.categorie } }));

      setProductsRows(getProductRowsFromFilteredProductsData(filteredProductsData));
    }
    else
      setProductsRows([]);
  }, [filteredProductsData])

  function getProductRowsFromFilteredProductsData(filteredProductData) {
    let productRows = [];
    filteredProductData.forEach((item) => {
      let productRow = {};
      productRow.id = item.id;
      productRow.brand = item.brand ? item.brand : '';
      productRow.model = item.model ? item.model : '';
      productRow.name = item.name ? item.name : '';
      productRow.rental_price = item.rental_price + ' ' + t('€ / month');
      productRow.categorie_odoo = item.categorie_odoo
      productRows.push(productRow);
    });
    return productRows;
  }

  useEffect(() => {
    if (productsRows && productsRows != undefined && productsRows.length > 0) {
      setSortedRows(productsRows);
    }
    else
      setSortedRows([]);
  }, [productsRows])


  const rowClassGetter = (rowIdx) => {
    const row = sortedRows[rowIdx];
    // Remplacez cette condition par la condition réelle à vérifier dans `sortedRows`
    const isConditionMet = row && row.removed;

    //return isConditionMet ? 'highlighted-row' : '';
    return 'highlighted-row';
  };

  const RowRenderer = () => {
    return <Row {...this.props} extraClasses="highlighted-row" />
  }


  const formatResult = (item) => {
    return (
      <>
        {
          item ? (
            <span>{item.brand} {item.name}</span>
          ) : null
        }

      </>
    )
  };

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string.length === 0 || string === "") {
      setSearchTerm('');
      setFilteredProductsData(productsData);
    }
    setSearchTerm(string);
  };

  const handleOnSelect = (item) => {
    // the item selected
    setFilteredProductsData(filteredProductsData.filter((product) => product.id === item.id && product.categories == item.categories));
    setSearchTerm(item.id);
  };

  const handleOnFocus = () => {

  };

  const applyRentalRate = () => {
    dispatch(majRentalRate(sortedRows, rentalEnabledChecked, price))
  }

  const resetRentalRate = () => {
    setPrice(0);
  }

  const cancelRentalRate = () => {
    
  }

  const rentalEnabledHandler = (e) => {
    const { value, checked } = e.target;
    setRentalEnabledChecked(checked);
  }

  return (
    <>
      {
        (loading || nonImeiLoading || loadingRentalRateMaj) ? <Loader /> :
          <div className="rental-main-row">
            <Row className="rental-filters">
              <Col md={8}>
                <ReactSearchAutocomplete
                  items={filteredProductsData && filteredProductsData != undefined && filteredProductsData.length > 0 ?
                    filteredProductsData
                    : []}
                  fuseOptions={fuseOptions}
                  resultStringKeyName="name"
                  onSearch={handleOnSearch}
                  onSelect={handleOnSelect}
                  onFocus={handleOnFocus}
                  onClear={() => { setSearchTerm(''); setFilteredProductsData(productsData); setResetSelect(!resetSelect); }}
                  autoFocus
                  placeholder={t('Search')}
                  formatResult={formatResult}
                  threshold={0.6} // 0 = exact match, 1 = everything matches
                  ignoreLocation={true}
                  maxResults={8}
                  styling={{
                    width: "100%",
                    border: "1px solid #dfe1e5",
                    borderRadius: "5px",
                    backgroundColor: "white",
                    boxShadow: "rgba(58, 53, 53, 0.2) 0px 1px 6px 0px",
                    hoverBackgroundColor: "#eee",
                    color: "#212121",
                    fontSize: "12px",
                    fontFamily: "Arial",
                    iconColor: "grey",
                    lineColor: "rgb(232, 234, 237)",
                    placeholderColor: "grey",
                    clearIconMargin: '0 5px 0 0',
                    searchIconMargin: '0 0 0 5px',
                    zIndex: 5,
                  }}
                />
              </Col>
            </Row>
            <Row className="rental-filters">
              <Select
                className='categories-select'
                name="categories-select"
                title="Catégories"
                value={selectedHeaderCategory}
                options={selectHeaderCategorie}
                onChangeFunc={(e) => onHandleHeaderCategorySelectChange(e)}
              />
            </Row>
            {
              selectedHeaderCategory && selectedHeaderCategory.categories && selectedHeaderCategory.categories.length > 1 &&
              <Row className="rental-filters">
                <Col md={8}>
                  <Select
                    className='rental-categories-select'
                    name="categories-select"
                    title="Sous catégories"
                    value={selectedCategory}
                    options={selectCategorie}
                    onChangeFunc={(e) => onHandleCategorySelectChange(e)}
                  />
                </Col>
              </Row>
            }

            <Row className="rental-filters">
              <Col md={2}>
                <input
                  className="rental-rate-input"
                  type="text"
                  placeholder={t('rental price')}
                  onKeyPress={(event) => {
                    const value = event.target.value;
                    const key = event.key;

                    // Vérifie si la touche pressée est un chiffre, un point, et qu'il n'y a pas déjà un point
                    if (!/[0-9.]/.test(key) || (key === '.' && value.includes('.'))) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => setPrice(e.target.value.replace(/[^0-9.]/g, ""))}
                />
              </Col>
              <Col md={2}>
                <input
                  id="is_leasable"
                  className="rental-rate-checkbox"
                  type="checkbox"
                  name="is_leasable"
                  value={rentalEnabled}
                  checked={rentalEnabledChecked}
                  onChange={(event) => rentalEnabledHandler(event)}
                />
                <label className="rental-rate-checkbox-label" htmlFor="is_leasable">{t('enabled')}</label>
              </Col>
              <Col md={4}>
                <button
                  onClick={applyRentalRate}
                  className='rental-btn-apply'
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
                <ReactTooltip id={'tooltip'}
                  type="dark"
                  effect="solid"
                  clickable={true}
                  place='bottom'
                />
                <button
                  onClick={cancelRentalRate}
                  className='rental-btn-cancel'
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
                <ReactTooltip id={'tooltip'}
                  type="dark"
                  effect="solid"
                  clickable={true}
                  place='bottom'
                />
              </Col>
            </Row>

            <Row className="rental-datagrid">
              <DataGrid
                className='rdg-light rental-datagrid'
                columns={catalog_columns}
                rows={sortedRows}
                rowGetter={(i) => sortedRows[i]}
                rowsCount={sortedRows.length}
                onRowsChange={setSortedRows}
                onHeaderClick={onHeaderClick}
                onGridRowsUpdated={setSortedRows}
                rowClassName={rowClassGetter}
                rowRenderer={RowRenderer}
              />
            </Row>
          </div>
      }
    </>
  )
}

export default Rental