export const FLEET_ENTITY_LIST_REQUEST = 'FLEET_ENTITY_LIST_REQUEST'
export const FLEET_ENTITY_LIST_SUCCESS = 'FLEET_ENTITY_LIST_SUCCESS'
export const FLEET_ENTITY_LIST_FAIL = 'FLEET_ENTITY_LIST_FAIL'

export const FLEET_ENTITY_GET_REQUEST = 'FLEET_ENTITY_GET_REQUEST'
export const FLEET_ENTITY_GET_SUCCESS = 'FLEET_ENTITY_GET_SUCCESS'
export const FLEET_ENTITY_GET_FAIL = 'FLEET_ENTITY_GET_FAIL'

export const FLEET_ENTITY_ADD_REQUEST = 'FLEET_ENTITY_ADD_REQUEST'
export const FLEET_ENTITY_ADD_SUCCESS = 'FLEET_ENTITY_ADD_SUCCESS'
export const FLEET_ENTITY_ADD_FAIL = 'FLEET_ENTITY_ADD_FAIL'

export const FLEET_ENTITY_ADD_BY_FILE_REQUEST = 'FLEET_ENTITY_ADD_BY_FILE_REQUEST'
export const FLEET_ENTITY_ADD_BY_FILE_SUCCESS = 'FLEET_ENTITY_ADD_BY_FILE_SUCCESS'
export const FLEET_ENTITY_ADD_BY_FILE_FAIL = 'FLEET_ENTITY_ADD_BY_FILE_FAIL'

export const FLEET_ENTITY_DELETE_REQUEST = 'FLEET_ENTITY_DELETE_REQUEST'
export const FLEET_ENTITY_DELETE_SUCCESS = 'FLEET_ENTITY_DELETE_SUCCESS'
export const FLEET_ENTITY_DELETE_FAIL = 'FLEET_ENTITY_DELETE_FAIL'

export const FLEET_ENTITY_UPDATE_REQUEST = 'FLEET_ENTITY_UPDATE_REQUEST'
export const FLEET_ENTITY_UPDATE_SUCCESS = 'FLEET_ENTITY_UPDATE_SUCCESS'
export const FLEET_ENTITY_UPDATE_FAIL = 'FLEET_ENTITY_UPDATE_FAIL'

export const RESET_FLEET_ENTITY_ADD_SUCCESS = 'RESET_FLEET_ENTITY_ADD_SUCCESS';
export const RESET_FLEET_ENTITY_DELETE_SUCCESS = 'RESET_FLEET_ENTITY_DELETE_SUCCESS';
export const RESET_FLEET_ENTITY_UPDATE_SUCCESS = 'RESET_FLEET_ENTITY_UPDATE_SUCCESS';
export const RESET_FLEET_ENTITY_ADD_BY_FILE_SUCCESS = 'RESET_FLEET_ENTITY_ADD_BY_FILE_SUCCESS';