import {
    HEADER_CATEGORIES_LIST_REQUEST,
    HEADER_CATEGORIES_LIST_SUCCESS,
    HEADER_CATEGORIES_LIST_FAIL,

    HEADER_CATEGORIES_CREATE_REQUEST,
    HEADER_CATEGORIES_CREATE_SUCCESS,
    HEADER_CATEGORIES_CREATE_FAIL,

    HEADER_CATEGORIES_UPDATE_REQUEST,
    HEADER_CATEGORIES_UPDATE_SUCCESS,
    HEADER_CATEGORIES_UPDATE_FAIL,

    HEADER_CATEGORIES_DELETE_REQUEST,
    HEADER_CATEGORIES_DELETE_SUCCESS,
    HEADER_CATEGORIES_DELETE_FAIL,
}
from '../constants/headerCategorieConstants'

import axios from 'axios'

export const listHeaderCategories = () => async (dispatch, getState) => {
    try {
        dispatch({ type: HEADER_CATEGORIES_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            '/api/header-category'
            )

        dispatch({
            type: HEADER_CATEGORIES_LIST_SUCCESS,
            payload: data.sort((a, b) => (a.position > b.position) ? 1 : -1)
        })
    } catch (error) {
        dispatch({
            type: HEADER_CATEGORIES_LIST_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message :
                error.message,
        })
    }
}

export const createHeaderCategorie = (name, image_url, categories, isOdoo = false, is_imei = false, isProductCategory = false) => async (dispatch, getState) => {
    try {
        dispatch({
            type: HEADER_CATEGORIES_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
                'Content-Type': 'application/json'
            }
        })

        const { data } = await instance.post(
            '/api/header-category/add/', 
            { 'name': name, 'image_url': image_url, 'categories': categories, is_odoo: isOdoo, is_imei:is_imei, is_product_category: isProductCategory },
            
        )

        dispatch({
            type: HEADER_CATEGORIES_CREATE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: HEADER_CATEGORIES_CREATE_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message :
                error.message,
        })
    }
}

export const updateHeaderCategorie = (id, name, image_url, categories, isOdoo = false, isImei = false, isProductCategory = false) => async (dispatch, getState) => {
    try {
        dispatch({
            type: HEADER_CATEGORIES_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
                'Content-Type': 'application/json'
            }
        })

        const { data } = await instance.post(
            '/api/header-category/update/', 
            { 'id': id, 'name': name, 'image_url': image_url, 'categories': categories, is_odoo: isOdoo, is_imei:isImei, is_product_category: isProductCategory },
            
        )

        dispatch({
            type: HEADER_CATEGORIES_UPDATE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: HEADER_CATEGORIES_UPDATE_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message :
                error.message,
        })
    }
}

export const deleteHeaderCategorie = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: HEADER_CATEGORIES_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
                'Content-Type': 'application/json'
            }
        })

        await instance.delete(
            `/api/header-category/del/?id=${id}`,
            
        )

        dispatch({
            type: HEADER_CATEGORIES_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: HEADER_CATEGORIES_DELETE_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message :
                error.message,
        })
    }
}