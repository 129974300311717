import { createPath } from 'react-router-dom'
import{
    CONTACT_ODOO_LIST_REQUEST,
    CONTACT_ODOO_LIST_SUCCESS,
    CONTACT_ODOO_LIST_FAIL,

    CONTACT_ODOO_GET_REQUEST,
    CONTACT_ODOO_GET_SUCCESS,
    CONTACT_ODOO_GET_FAIL,

    CONTACT_ODOO_CREATE_REQUEST,
    CONTACT_ODOO_CREATE_SUCCESS,
    CONTACT_ODOO_CREATE_FAIL,
    CONTACT_ODOO_CREATE_RESET,

    CONTACT_ODOO_UPDATE_REQUEST,
    CONTACT_ODOO_UPDATE_SUCCESS,
    CONTACT_ODOO_UPDATE_FAIL,

    CONTACT_ODOO_DELETE_REQUEST,
    CONTACT_ODOO_DELETE_SUCCESS,
    CONTACT_ODOO_DELETE_FAIL,

    CONTACT_BY_ODOO_ID_REQUEST,
    CONTACT_BY_ODOO_ID_SUCCESS,
    CONTACT_BY_ODOO_ID_FAIL,
}
from '../constants/contactOdooConstants.js'

export const contactOdooListReducer = (state = { contactOdoo: [] }, action) => {
    switch (action.type) {
        case CONTACT_ODOO_LIST_REQUEST:
            return { loading: true, contactOdoo: [] }
        case CONTACT_ODOO_LIST_SUCCESS:
            return { loading: false, contactsOdoo: action.payload }
        case CONTACT_ODOO_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const contactByOdooIdReducer = (state = { contactOdoo: {} }, action) => {
    switch (action.type) {
        case CONTACT_BY_ODOO_ID_REQUEST:
            return { loading: true, ...state }
        case CONTACT_BY_ODOO_ID_SUCCESS:
            return { loading: false, contactByOdoo: action.payload }
        case CONTACT_BY_ODOO_ID_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const contactOdooGetReducer = (state = { contactOdoo: {} }, action) => {
    switch (action.type) {
        case CONTACT_ODOO_GET_REQUEST:
            return { loading: true, ...state }
        case CONTACT_ODOO_GET_SUCCESS:
            return { loading: false, contactOdoo: action.payload }
        case CONTACT_ODOO_GET_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const contactOdooCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTACT_ODOO_CREATE_REQUEST:
            return { loading: true }
        case CONTACT_ODOO_CREATE_SUCCESS:
            return { loading: false, success: true }
        case CONTACT_ODOO_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case CONTACT_ODOO_CREATE_RESET:
            return { success: false }
        default:
            return state
    }
}

export const contactOdooUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTACT_ODOO_UPDATE_REQUEST:
            return { loading: true }
        case CONTACT_ODOO_UPDATE_SUCCESS:
            return { loading: false, success: true }
        case CONTACT_ODOO_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const contactOdooDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTACT_ODOO_DELETE_REQUEST:
            return { loading: true }
        case CONTACT_ODOO_DELETE_SUCCESS:
            return { loading: false, success: true }
        case CONTACT_ODOO_DELETE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
