import { 
    QUOTATION_LIST_REQUEST,
    QUOTATION_LIST_SUCCESS,
    QUOTATION_LIST_FAIL,

    QUOTATION_CREATE_REQUEST, 
    QUOTATION_CREATE_SUCCESS, 
    QUOTATION_CREATE_FAIL 
} from "../constants/quotationConstants";

export const QuotationListReducer = (state ={quotations: [] }, action) => {
    switch (action.type) {
        case QUOTATION_LIST_REQUEST:
            return { loading: true, quotations: [] }
        case QUOTATION_LIST_SUCCESS:
            return { loading: false, quotations: action.payload }
        case QUOTATION_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}