export const STOCK_REQUEST = 'STOCK_REQUEST'
export const STOCK_SUCCESS = 'STOCK_SUCCESS'
export const STOCK_FAIL = 'STOCK_FAIL'

export const STOCK_ALL_REQUEST = 'STOCK_ALL_REQUEST'
export const STOCK_ALL_SUCCESS = 'STOCK_ALL_SUCCESS'
export const STOCK_ALL_FAIL = 'STOCK_ALL_FAIL'

export const UPDATE_STOCK_REQUEST = 'UPDATE_STOCK_REQUEST'
export const UPDATE_STOCK_SUCCESS = 'UPDATE_STOCK_SUCCESS'
export const UPDATE_STOCK_FAIL = 'UPDATE_STOCK_FAIL'
