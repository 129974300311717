import axios from 'axios';
import {
    CATEGORIES_LIST_REQUEST,
    CATEGORIES_LIST_SUCCESS,
    CATEGORIES_LIST_FAIL,

    CATEGORIES_CREATE_REQUEST,
    CATEGORIES_CREATE_SUCCESS,
    CATEGORIES_CREATE_FAIL,

    CATEGORIES_UPDATE_REQUEST,
    CATEGORIES_UPDATE_SUCCESS,
    CATEGORIES_UPDATE_FAIL,

    CATEGORIES_DELETE_REQUEST,
    CATEGORIES_DELETE_SUCCESS,
    CATEGORIES_DELETE_FAIL,

} from "../constants/categoriesConstants";

export const listCategories = () => async (dispatch, getState) => {
    try {
        dispatch({ type: CATEGORIES_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.get(
            'api/category/'
            )

        dispatch({ type: CATEGORIES_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ 
            type: CATEGORIES_LIST_FAIL, 
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message })
    }
}

export const addCategorie  = (name, isImei = false, is_linkable=false, description = '', filters = []) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CATEGORIES_CREATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/category/add/`,
            { 'name': name, 'is_imei': isImei, 'is_linkable': is_linkable, 'description': description,
                'filters': filters },
            )

        dispatch({
            type: CATEGORIES_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CATEGORIES_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateCategories  = (id, name, isImei = false, is_linkable=false, description = '', filters = []) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CATEGORIES_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/category/update/`,
            { 'id': id, 'name': name, is_imei:isImei, 'is_linkable': is_linkable,
             'description': description, 'filters': filters },
            )

        dispatch({
            type: CATEGORIES_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CATEGORIES_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteCategories  = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CATEGORIES_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/category/del/`,
            { 'id': id },
            )

        dispatch({
            type: CATEGORIES_DELETE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CATEGORIES_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}